import _ from 'underscore'

export default {

	namespaced: true,

	modules: {},

	state: {

		data: []

	},

	getters: {

		list: function(state) {

			return _.sortBy(state.data, 'location_name')

		},

		associated: function(state) {

			var associated = {}

			_.each(state.data, function(item) {

				associated[item.location_id] = item

			})

			return associated

		}

	},

	mutations: {

		add: function(state, items) {

			_.each(items, function(item) {

				if (!_.contains(item.location_id, _.pluck(state.data, 'location_id'))) {

					state.data.push(item)

				}

			})

		}

	},

	actions: {}

}