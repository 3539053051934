<template>

<div class="content-list" v-bind:class="{'is-flush': flush, 'is-selecting': selectActive, 'is-fixed': fixed, 'is-full': full, 'is-cards': cards}">

	<div class="content-list-offset is-top" v-observe-visibility="{callback: onTopVisibilityChange, intersection: { rootMargin: '-152px 0px 0px 0px' }}"></div>

	<slot></slot>

	<div class="content-list-offset is-bottom" v-observe-visibility="onBottomVisibilityChange"></div>

</div>

</template>

<script>

export default {

	props: ['flush', 'full', 'cards', 'fixed'],

	data: function() {
	
		return {
			topVisible: false,
			bottomVisible: false
		}

	},

	computed: {

		selectActive: function() {

			return this.$store.getters['service/select/count']

		}

	},

	methods: {
	
		onTopVisibilityChange: function(visible) {

			this.topVisible = visible
			this.$emit('fixed', !this.topVisible)
		
		},
	
		onBottomVisibilityChange: function(visible) {
		
			this.bottomVisible = visible

		}

	}

}

</script>

<style scoped>

.content-list-offset {
	width: 32px;
	height: 32px;
	position: absolute;
	left: 0px;
	pointer-events: none;
}

.content-list-offset.is-top {
	top: -64px;
}

.content-list-offset.is-bottom {
	bottom: -32px;
}

.content-list:not(.is-cards) {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 0px 0px 4px 4px;
}

.content-list.is-fixed {
	margin-top: 32px;
}

.content-list.is-cards {
	display: grid;
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
	grid-gap: 20px;
}

.is-tablet .content-list.is-cards {
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.is-mobile .content-list.is-cards {
	grid-template-columns: minmax(0, 1fr);
	grid-gap: 10px;
	padding: 10px;
}

</style>
