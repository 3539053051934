<template>

<core-panel>

	<template v-slot:head>

		<h1>Publish years</h1>

		<p>The ratings of games by the year in which they were published.</p>

	</template>

	<template v-slot:body>

		<div class="year-wrapper">

			<div class="year-item" :data-rating="ratingBand(year.rating, year.count)" :data-band="band(year.count)" v-for="year in years" :key="year.year" v-on:click="onYearClick($event, year.year)">

			<div class="year-item-bubble">
				<div class="year-item-bubble-rating" v-if="year.count">{{ year.rating / year.count | round(1) }}</div>
				<div class="year-item-bubble-year">{{ year.year }}</div>
			</div>

		</div>

		</div>

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'years',
			years: []
		}

	},

	methods: {

		response: function(json) {

			this.years = json.years

		},

		ratingBand: function(rating, count) {

			return (count) ? Math.floor(rating / count) : 0

		},

		onYearClick: function(e, year) {

			e.stopPropagation()

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsYear',
				year: year
			})

		},

		band: function(count) {

			if (count) {

				var perBand = this.$_.max(this.years, function(item) { return item.count }).count / 4
				var band = Math.ceil(count / perBand)

				return band

			} else {

				return 0

			}

		}

	}

}

</script>

<style scoped>

.year-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.year-item {
	height: 64px;
	text-align: center;
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	cursor: pointer;
}

.year-item-bubble {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 21px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	transition: transform 100ms linear;
}

.year-item-bubble-year {
	font-family: 'Roboto Mono', monospace;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
}

.year-item-bubble-rating {
	font-size: 21px;
	line-height: 24px;
	font-weight: 500;
	display: none;
}

.year-item:hover .year-item-bubble-year {
	display: none;
}

.year-item:hover .year-item-bubble-rating {
	display: block;
}

.year-item[data-rating="10"] .year-item-bubble { background-color: #249563; }
.year-item[data-rating="9"] .year-item-bubble { background-color: #249563; }
.year-item[data-rating="8"] .year-item-bubble { background-color: #2FC482; }
.year-item[data-rating="7"] .year-item-bubble  { background-color: #1d8acd; }
.year-item[data-rating="6"] .year-item-bubble { background-color: #5369a2; }
.year-item[data-rating="5"] .year-item-bubble { background-color: #5369a2; }
.year-item[data-rating="4"] .year-item-bubble { background-color: #df4751; }
.year-item[data-rating="3"] .year-item-bubble { background-color: #df4751; }
.year-item[data-rating="2"] .year-item-bubble { background-color: #db303b; }
.year-item[data-rating="1"] .year-item-bubble { background-color: #db303b; }

.year-item[data-band="0"] .year-item-bubble {
	background-color: transparent;
	color: #ccc;
}

.year-item[data-band="0"] {
	pointer-events: none;
	opacity: 0.5;
}

</style>