import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		session: false,
		data: {}

	},

	getters: {

		key: function(state) {

			return state.data.session

		},

		notifications: function(state) {

			return state.data.notifications

		},

		notification: function(state) {

			return state.data.notification

		},

		upgraded: function(state) {

			return (state.data.user) ? state.data.user.upgraded : false

		},

		loggedin: function(state) {

			return state.data.user

		},

		recent: function(state) {

			return (state.data.recent) ? state.data.recent.slice(0, 10) : []

		},

		groups: function(state) {

			return (state.data.groups) ? state.data.groups : []

		},

		'user/slug': function(state) {

			return (state.data.user) ? state.data.user.slug : false

		},

		'user/name': function(state) {

			return (state.data.user) ? state.data.user.name : false

		},

		'user/id': function(state) {

			return (state.data.user) ? state.data.user.id : false

		},

		'user/date': function(state) {

			return (state.data.user) ? state.data.user.date : {}

		},

		'user/date/sync': function(state) {

			return (state.data.user) ? state.data.user.date.sync : {}

		},

		'user/date/sync/next': function(state) {

			return (state.data.user) ? state.data.user.date.nextSync : {}

		},

		'user/date/sync/deep': function(state) {

			return (state.data.user) ? state.data.user.date.syncDeep : false

		},

		'user/date/sync/full': function(state) {

			return (state.data.user) ? state.data.user.date.syncFull : false

		},

		'user/date/sync/standard': function(state) {

			return (state.data.user) ? state.data.user.date.syncStandard : false

		},

		'user/sync/type': function(state) {

			return (state.data.user) ? state.data.user.syncType : {}

		},

		user: function(state) {

			return (state.data.user) ? state.data.user : {}

		}

	},

	mutations: {

		set: function(state, data) {

			state.data = data || {}

		},

		notification: function(state, value) {

			state.data.notification = value

		},

		notifications: function(state, value) {

			state.data.notifications = value

		},

		'user/update': function(state, data) {

			state.data.user = data

		},

		'groups/add': function(state, data) {

			state.data.groups = _.filter(state.data.groups, function(item) { return item.id !== data.id })

			state.data.groups.push(data)

			state.data.groups = _.sortBy(state.data.groups, function(group) {

				return group.name.toLowerCase()

			})

			state.data.user.count.groups = state.data.groups.length

		},

		'groups/remove': function(state, id) {

			state.data.groups = _.filter(state.data.groups, function(item) { return item.id !== id })

			state.data.user.count.groups = state.data.groups.length

		},

		'recent/add': function(state, data) {

			state.data.recent = _.filter(state.data.recent, function(item) { return !_.isEqual(item, data) })

			state.data.recent.unshift(data)

		},

		'recent/remove': function(state, data) {

			state.data.recent = _.filter(state.data.recent, function(item) { return item.slug !== data.slug || item.type !== data.type })

		},

		'user/sync/type': function(state, data) {

			state.data.user.syncType = data

		}

	},

	actions: {

		verify: function() {

		}

	}

}