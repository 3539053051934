import Store from 'app/store'
import _ from 'underscore'

export default {

	props: ['filter'],

	computed: {

		count: function() {

			var count = 0
			var value

			_.each(this.fields, function(name) {	

				value = (Store.getters['filter/' + this.filter + '/active'][name] === undefined) ? 0 : Store.getters['filter/' + this.filter + '/active'][name]

				if (typeof value === 'boolean') {

					count += (value) ? 1 : 0 

				} else if (name.indexOf('.from') > 0 || name.indexOf('.to') > 0 || value > 100000000) {

					count += (value) ? 1 : 0 

				} else {

					count += parseInt(value)

				}

			}.bind(this))

			return count

		},

		values: function() {

			return Store.getters['filter/' + this.filter]

		}

	},

	methods: {

		value: function(name) {

			return this.filter + '/' + name

		}

	}

}