<template>

<core-card :key="item.id">

	<core-card-head type="game" :id="(item.game) ? item.game.id : fieldId" size="large" :band="ratingBand">

        <core-rating :value="fieldRating" class="card-head-rating" v-if="isUser && fieldRating" />

		<div class="card-body-title-tag" v-if="tag" :data-theme="tagTheme">{{ tag }}</div>

    </core-card-head>

	<core-card-body :grow="true" :padded="true">

		<core-gamename :game="fieldGame" class="card-body-title-text" :id="fieldId" />

		<div class="card-body-title-date" v-if="dateValue">

			<template v-if="!dateTo">
				{{ datePrefix }}
			</template>

			{{ dateValue | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}

			<template v-if="dateTo">
				- 
				{{ dateTo | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}
			</template>

		</div>

		<div class="card-body-title-date" v-if="countNoun">

			{{ count }} <plural :n="count" :t="countNoun" />

		</div>

	</core-card-body>

	<core-card-foot :borderless="true">

        <app-trend v-if="showRevisions" :history="item.revisions.history" class="card-foot-revisions" />

		<app-disparity v-if="showDisparity" :value="disparity" class="card-foot-disparity" />

		<div class="card-foot-metric" v-if="metric !== undefined">{{ metric }}</div>

		<slot></slot>

	</core-card-foot>

</core-card>

</template>

<script>

export default {

	props: ['item', 'date', 'metric', 'dateTo', 'datePrefix', 'showRevisions', 'showDisparity', 'tag', 'tagTheme', 'id', 'rating', 'name', 'count', 'countNoun'],

    computed: {

		fieldGame: function() {
		
			return (this.name) ? { id: this.fieldId, name: this.name } : (this.item.game || this.item)

		},

		fieldRating: function() {
		
			return this.rating || ((this.item.rating) ? this.item.rating.rating : false)

		},

		fieldId: function() {
		
			return this.id || this.item.id

		},

		dateValue: function() {
		
			return (this.date) ? this.date : this.item.date
		
		},

        ratingBand: function() {

			if(!this.fieldRating) return false

			if(this.fieldRating) return Math.floor(this.fieldRating)

			return (this.isUser) ? Math.floor(this.item.rating.rating) : Math.floor(this.item.group.rating_avg)

		},
    
		disparity: function() {
        
            return this.item.rating.rating - this.item.revisions.history[0].value

        }
    
    }

}

</script>

<style scoped>

.card-head-rating {
	position: absolute;
	right: 0px;
	bottom: -3px;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 21px!important;
}

.card-body-title-text {
	font-size: 16px;
	padding-top: 10px;
	font-weight: 400;
	color: #333;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.card-body-title-date {
	font-size: 12px;
	font-weight: 300;
	color: #666;
	margin-top: 2px;
}

.card-body-title-tag {
	font-size: 14px;
	color: #fff;
	padding: 5px 10px;
	border-radius: 0px 0px 4px 4px;
	cursor: pointer;
	font-weight: 400;
	position: absolute;
	top: 0px;
	left: 10px;
	cursor: initial;
	width: auto;
}

.card-body-title-tag[data-theme="green"] {
	background-color: rgba(4, 143, 85, 1);
}

.card-body-title-tag[data-theme="red"] {
	background-color: rgba(51, 149, 181, 1);
}

.card-body-title-tag[data-theme="blue"] {
	background-color: rgba(143, 36, 4, 1);
}

.card-foot-revisions {
	height: 32px;
    margin-left: 10px;
	width: 50%!important;
	justify-content: flex-start!important;
	align-items: flex-end!important;
}

.card-foot-disparity {
	width: 50%!important;
    margin-right: 10px;
	text-align: right;
	line-height: 38px;
}

.card-foot-metric {
	font-size: 32px;
	color: #333;
	font-weight: 500;
	padding: 0px 10px 0px 10px;
}

</style>
