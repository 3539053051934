<template>

<com-item name="collection.overview" :is-overview="3" :size="size" :loading="loading" :is-empty="!active" title="Overview">

	<template v-slot:about>

		<p>A summary of {{ your }} collection.</p>

		<p class="small">A "game" refers to base games only and not expansions.</p>

	</template>

	<template v-slot:stats>

		<com-overview label="Games" type="number" :value="data.total" />

		<com-overview label="Expansions" type="number" :value="data.expansions" />

		<com-overview label="% Turned Over" type="percent" :value="data.prevowned" v-if="isUser" />

		<com-overview label="Avg. Size" type="number" :value="data.collectionAvg" v-if="!isUser" />

		<com-overview label="% Played" type="percent" :value="data.playedPercent" />

		<com-overview label="Avg. Plays" type="number" :value="data.playedAvg" />

		<com-overview label="With 10+ Plays" type="number" :value="data.played10" />

		<com-overview label="% Rated" type="percent" :value="data.ratedPercent" />

		<com-overview label="Avg. Rating" type="rating" :value="data.ratedAvg" />

		<com-overview label="Owned vs Unowned" type="number" :value="data.ratedDifference" :auto-caret="true" :auto-theme="true" v-if="isUser" />

		<com-overview label="Owners vs Non-Owners" type="number" :value="data.ratedDifference" :auto-caret="true" :auto-theme="true" v-if="!isUser" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comOverview from './../common/Overview'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-overview': comOverview
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/collection/overview']

		},

		active: function() {

			return this.data && !this.loading

		}

	}

}

</script>

<style scoped>

</style>