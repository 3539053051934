<template>

<com-item name="worth.plays" :size="size" :is-empty="!active" :loading="loading" title="Plays">

	<template v-slot:about>

		<p>{{ your | capitalise }} collection worth by plays.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-accordian :data="accordian" v-on:click="onAccordianClick" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="playsOptions" :value="playsValue" v-on:change="onPlaysChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {
	
		return {
			accordian: {
				noun: 'game',
				tooltip: 'Click to view games<small>{{value}} {{noun}}</small>',
				values: [],
				slimPercent: 5,
				keys: [],
				subvalues: [],
				format: 'currency',
				labels: [],
				images: [],
				colors: [
					'#9FCBDC',
					'#8ABFD5',
					'#74B2CD',
					'#68ACC9',
					'#5DA7C6',
					'#50A1C2',
					'#439ABE',
					'#3495BA',
					'#2490B6',
					'#0c6f91'
				]
			}
		}
	
	},

	computed: {

		playsOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.PLAYS.TOTAL, text: 'Total value'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.PLAYS.AVERAGE, text: 'Average value'})

			return options

		},

		playsValue: function() {

			return this.$store.getters['filter/insights/worth/plays']

		},

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'plays'

		},

		data: function() {

			return this.$store.getters['page/insights/worth/plays']

		},

		active: function() {

			return this.data.length

		}

	},

	watch: {
	
		data: function() {
		
			this.visualise()	
		
		}

	},

	created: function() {
	
		this.visualise()	

	},

	methods: {

		onPlaysChange: function(value) {

			this.$store.commit('filter/insights/worth/plays', value)

		},

		onAccordianClick: function(band) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthPlays',
				filter: this.$store.getters['filter/insights/worth'],
				band: band
			})

		},
	
		visualise: function() {
		
			for (var i = 1; i <= 10; i++) {

				this.accordian.keys.push(i)
				this.accordian.values.push(0)
				this.accordian.subvalues.push(0)
				this.accordian.images.push(0)
				this.accordian.labels.push('')

			}

			this.$_.each(this.data, function(item) {
			
				this.accordian.labels[item.i - 1] = item.p
				this.accordian.values[item.i - 1] = item.w
				this.accordian.subvalues[item.i - 1] = item.g
				this.accordian.images[item.i - 1] = item.f
			
			}.bind(this))
		
		}

	}

}

</script>

<style scoped>

.is-desktop .plays,
.is-mobile .plays {
	padding: 0px!important;
	border: 0px!important;
	overflow: visible!important;
	border-radius: 0px!important;
}

.wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	height: 264px;
}

.is-mobile .wrapper {
	margin: 4px 0px 4px -4px;
	width: 1000px;
}

.rating-item {
	flex-shrink: 0;
	transition: transform 100ms linear;
	background-color: #fff;
	cursor: pointer;
	z-index: 1;
	overflow: hidden;
	transition: all 300ms linear;
	border-right: 1px solid #ddd;
}

.rating-item:first-child {
	border-radius: 0px 0px 0px 4px;
}

.rating-item:last-child {
	border-radius: 0px 0px 4px 0px;
	border-right: 0px;
}

.is-desktop .rating-item:hover {
	transform: scale(1.05);
	border-radius: 4px;
	z-index: 2;
	background-color: #4881bb;
	border-right: 0px;
}

.rating-item-image {
	position: absolute;
	opacity: 0.1;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
}

.rating-item-number {
	font-size: 32px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.5);
	position: absolute;
	left: 4px;
	top: 4px;
}

.rating-item.is-slim[data-band="7"] .rating-item-number {
	font-size: 15px;
	line-height: 32px;
	width: 100%;
	text-align: center;
	left: 0px;
}

.rating-item.is-slim[data-band="8"] .rating-item-number {
	font-size: 15px;
	line-height: 32px;
	width: 100%;
	text-align: center;
	left: 0px;
}

.rating-item.is-slim[data-band="9"] .rating-item-number {
	font-size: 15px;
	line-height: 32px;
	width: 100%;
	text-align: center;
	left: 0px;
}

.rating-item.is-slim[data-band="10"] .rating-item-number {
	font-size: 15px;
	line-height: 32px;
	width: 100%;
	text-align: center;
	left: 0px;
}

.rating-item-worth {
	font-size: 32px;
	color: #fff;
	font-weight: 500;
	position: absolute;
	left: 0%;
	top: 50%;
	width: 100%;
	text-align: center;
	transform: translateY(-50%);
}

.rating-item.is-slim .rating-item-worth {
	writing-mode: vertical-rl;
	text-orientation: mixed;	
	font-size: 22px;
	transform: translate(-50%, -50%);
	left: 50%;
    width: auto;
}

.rating-item-worth >>> span.currency {
	font-size: 16px;
	opacity: 0.75;
	margin-right: 1px;
}

.rating-item-worth small {
	font-size: 12px;
	display: block;
	position: absolute;
	padding: 0px 4px;
	width: 100%;
	text-align: center;
	bottom: -12px;
	opacity: 0.75;
	font-weight: 500;
}

.rating-item.is-slim .rating-item-worth small {
	display: none;
}

.rating-item.is-slim .rating-item-worth >>> span.currency {
	font-size: 11px;
	margin-bottom: 1px;
}

.rating-item[data-band="10"] { background-color: #0c6f91; }
.rating-item[data-band="9"] { background-color: #2490B6; }
.rating-item[data-band="8"] { background-color: #3495BA; }
.rating-item[data-band="7"] { background-color: #439ABE; }
.rating-item[data-band="6"] { background-color: #50A1C2; }
.rating-item[data-band="5"] { background-color: #5DA7C6; }
.rating-item[data-band="4"] { background-color: #68ACC9; }
.rating-item[data-band="3"] { background-color: #74B2CD; }
.rating-item[data-band="2"] { background-color: #8ABFD5; }
.rating-item[data-band="1"] { background-color: #9FCBDC; }

</style>