<template>
	
<core-list-head :fixed="fixed">

	<core-list-column type="name" :format="{filter: 'plays', sort: 'game.name'}" class="column-game">Game</core-list-column>
	<core-list-column type="stat" :format="{filter: 'plays', sort: 'plays'}">Plays</core-list-column>
	<core-list-column :format="{}" class="column-counts" v-if="window.width >= 460">Counts</core-list-column>
	<core-list-column type="stat" :format="{filter: 'plays', sort: 'plays.winpercent'}" v-if="isUser">Win %</core-list-column>
	<core-list-column type="stat" :format="{filter: 'plays', sort: 'plays.users'}" v-if="!isUser">Users</core-list-column>
	<core-list-column type="date" :format="{filter: 'plays', sort: 'play.date'}" v-if="window.width >= 400">Last play</core-list-column>
	<core-list-column type="stat" :format="{filter: 'plays', sort: 'time'}">Hours</core-list-column>
	<core-list-column-actions />

</core-list-head>

</template>

<script>

export default {

	props: ['fixed'],

	computed: {

	}

}

</script>

<style scoped>

.column-game {
	margin-left: 48px!important;
	padding-left: 8px!important;
}

.is-mobile .column-game {
	margin-left: 0px!important;
	padding-left: 0px!important;
}

.column-counts {
	width: 73px;
	padding: 0px 10px;
	justify-content: center;
}

</style>