export default {

	data: function() {

		return {

			is: {
				valid: false
			},

			feedback: []

		}

	},

	computed: {

		validationFeedback: function() {

			return this.feedback.join(', ')

		}

	},

	watch: {

		value: function() {

			this._validate()

		}

	},

	created: function() {

		this._validate()

	},

	methods: {

		_validate: function() {

			this.feedback = []

			var rules = this.validation

			if (typeof rules !== 'object') {

				rules = {
					rules: true
				}

			}

			this.validate(rules)

			this.is.valid = !this.feedback.length

			this.$emit('validate', {
				name: this.name,
				valid: this.is.valid
			})

		}

	}

}