import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		suggest: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('languages/suggest', params).then(function(json) {

					context.commit('data/languages/add', json.suggestions, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		}
		
	}

}