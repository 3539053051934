<template>

<core-filter-advanced-group title="Mechanics" :count="count">

	<core-filter-input-suggest placeholder="Start typing a mechanic..." :has-require="true" :toggle="true" field="mechanic_name" :filter="value('mechanics')" api="mechanics/suggest" data="mechanics" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['mechanics']
		}
	}

}

</script>