<template>

<core-filter-advanced-group title="Language" :count="count">

	<core-filter-input-range text="Language independence" min="1" max="5" :inverse="true" :labels="language" :filter="value('language/dependency')" step="1" pips="1" decimals="0" :no-text="true" />

	<core-filter-input-range text="Min. votes" min="0" max="100" :filter="value('threshold/language')" :tooltip="true" step="1" pips="10" decimals="0" density="10" />

	<core-filter-input-suggest placeholder="Start typing a language..." field="language_name" :filter="value('languages')" api="languages/suggest" data="languages" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	props: [],

	data: function() {

		return {
			language: {
				5: 'None',
				4: 'A little',
				3: 'Moderate',
				2: 'A lot',
				1: 'All'
			},
			fields: ['language.dependency', 'threshold.language', 'languages']
		}

	}

}

</script>
