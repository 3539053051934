<template>
	
<core-list-cell type="stat" :format="format" class="weight" v-bind:class="{'is-summary': summary}">
				
	{{ value }}

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'summary', 'format'],

	computed: {

		value: function() {

			return (this.item.game.rank) ? this.item.game.rank : '-'

		}

	}

}

</script>

<style scoped>

@media only screen and (max-width: 767px) {

	.weight:not(.is-summary) {
		display: none!important;
	}

	.weight.is-summary {
		font-size: 10px;
		height: 16px;
		width: 48px;
		line-height: 16px;
		text-align: left;
		color: #333;
		font-weight: 400;
	}

}

.weight .fa {
	display: none;
}

@media only screen and (max-width: 767px) {

	.weight.is-summary .fa {
		display: inline-block;
		line-height: 14px;
		color: #666;
		margin-right: 4px;
	}

}
</style>
