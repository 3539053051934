<template>

<com-item name="shelf.recent" :size="size" :loading="loading" :is-empty="!active" title="Recently Played" :is-list="true" :has-more="true" v-on:more="onMoreClick">

	<template v-slot:about>

		<p>The games most recently played from {{ your }} shelf.</p>

	</template>

	<template v-slot:stats>

		<app-game v-for="(item, index) in recent" :item="item" v-bind:key="index" :subtext="subtext(item.date)" :count="item.days" :countText="(item.days === 1) ? 'day' : 'days'" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		recent: function() {

			return this.$store.getters['page/insights/shelf/recent']

		},

		active: function() {

			return this.recent && !this.loading

		}

	},

	methods: {

		subtext: function(date) {

			return 'Played on ' + this.$options.filters.formatDate(date, this.$util.date.format('Do', 'MMMM', 'YYYY'))

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsShelfRecent'
			})

		}

	}

}

</script>

<style scoped>

</style>