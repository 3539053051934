<template>

<core-content-wrapper :empty="is.ready && !totalMaxResults" :noResults="is.ready && totalMaxResults && !totalResults" :loading="!is.ready">

	<core-content-empty v-show="is.ready && !totalMaxResults" title="Oh dear, no users!" text="It seems there are no users." />

	<core-content-empty v-show="is.ready && totalMaxResults && !totalResults" title="Oh dear, no matches!" text="Your filtering skills are too strong, try broadening the settings." />

	<core-filter :loading="is.fetching" :count="totalPseudoResults" :maximum="totalMaxResults" v-show="totalMaxResults" />

	<app-head :fixed="is.fixedHead" v-show="is.ready && totalMaxResults && totalResults" />

	<core-content-list :fixed="is.fixedHead" v-on:fixed="onFixedChange" v-show="is.ready && totalMaxResults && totalResults">

		<app-user v-for="item in content" v-bind:key="item.id" :item="item" />

		<core-content-scrollload v-on:scrolled="onScroll"  v-show="currentPage < totalPages || (is.fetching && is.scrollLoading)" />

	</core-content-list>

	<core-content-select noun="user" />

</core-content-wrapper>

</template>

<script>

import Content from 'core/mixin/List.js'

import Filter from './users/Filter.vue'
import Head from './users/Head.vue'
import User from './users/User.vue'

export default {

	mixins: [Content],

	components: {

		'core-filter': Filter,
		'app-head': Head,
		'app-user': User

	},

	data: function() {

		return {

			contentName: 'users',
			routeName: 'Users',

			selectServices: {
				export: false,
				filter: false,
				tag: false,
				game: false,
				rename: false,
				list: {
					remove: false
				},
				users: {
					remove: false
				}
			}

		}

	},

	created: function() {

		this.selectServices.users.remove = this.isOwner && this.isGroup

	}

}

</script>

<style scoped>

.users-inner {
	padding: 0px;
}

</style>