<template>

<core-config-section>

	<h2>Status Labels <small>Applies to all collections</small></h2>

	<p>If you want to always see the status labels that games have (e.g. For trade) even if not filtering by status, turn this on. The "Own" label won't show if viewing with the default status filter as all games must be owned anyway.</p>

	<div class="inputs">

		<core-filter-input-toggle text="Show labels" on="Yes" off="No" filter="config/collection/status" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>