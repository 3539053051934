<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ cumulative[0].name }}</h1>

		<p>The {{ noun }}'s games that have spent time on the shelf.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in cumulative[0].games" :subtext="subtext(item)" :tags="tags(item)" :item="item" :count="item.count" :countText="(item.count === 1) ? 'day' : 'days'" dark="true" :key="index" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'cumulative',
			cumulative: [{}],
			params: ['cumulative.who', 'cumulative.period', 'specific']
		}

	},

	computed: {

		noun: function() {

			console.log(this.data)

			return (this.data['cumulative.who'] === 'designers') ? 'designer' : 'publisher'

		}

	},

	methods: {

		response: function(json) {

			this.cumulative = json.cumulative

		},

		subtext: function(game) {

			if (!game.to && !game.played) {

				return this.$options.filters.formatDate(game.from, this.$util.date.format('Do', 'MMMM', 'YYYY'))

			} else {

				return [
					this.$options.filters.formatDate(game.from, this.$util.date.format('Do', 'MMM', 'YYYY')),
					'-',
					this.$options.filters.formatDate((game.to) ? game.to : game.played, this.$util.date.format('Do', 'MMM', 'YYYY'))
				].join(' ')

			}

		},

		days: function(game) {

			if (game.to) {

				return this.$options.filters.daysBetween(game.from, game.to)

			} else {

				return this.$options.filters.daysSince(game.from)

			}

		},

		daysText: function(game) {

			return (this.days(game) === 1) ? 'day' : 'days'

		},

		tags: function(game) {

			var tags = []

			if (game.played) {

				tags.push({
					blue: true,
					text: 'Played'
				})

			} else if (game.to) {

				tags.push({
					red: true,
					text: 'Removed'
				})

			} else {

				tags.push({
					green: true,
					text: 'Current'
				})

			}

			return tags

		}

	}

}

</script>