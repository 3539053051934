<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" />.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :subtext="subtext(item)" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'durations',
			games: [],
			params: ['index']
		}

	},

	computed: {

		title: function() {

			return (this.data.index * 10).toString() + ' - ' + ((this.data.index * 10) + 9).toString() + ' minutes'

		}

	},

	methods: {	

		response: function(json) {

			this.games = json.games

		},

		subtext: function(item) {

			return (item.min !== item.max) ? item.min.toString() + ' - ' + item.max.toString() + ' minutes' : item.max.toString() + ' minutes'

		}

	}

}

</script>