<template>

<div class="areas">

    <div class="areas-item">

        <i class="areas-item-icon fa-solid fa-arrows-rotate"></i> 

        <div class="areas-item-value">{{ data.metrics.syncs.total }}</div>

        <div class="areas-item-title">Syncs completed</div>

        <div class="areas-item-metrics" data-size="2">

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Standard</div>
                <div class="areas-item-metrics-item-value">{{ data.metrics.syncs.standard }}</div>
            </div>

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Full</div>
                <div class="areas-item-metrics-item-value">{{ data.metrics.syncs.full }}</div>
            </div>

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Deep</div>
                <div class="areas-item-metrics-item-value">{{ data.metrics.syncs.deep }}</div>
            </div>

        </div>

    </div>

    <div class="areas-item">

        <i class="areas-item-icon fa-solid fa-cloud-arrow-down"></i> 

        <div class="areas-item-value" v-html="size(data.metrics.data.total)"></div>

        <div class="areas-item-title">Data scraped</div>

        <div class="areas-item-metrics" data-size="3">

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Standard</div>
                <div class="areas-item-metrics-item-value" v-html="size(data.metrics.data.standard, data.metrics.syncs.standard)"></div>
                <div class="areas-item-metrics-item-value" v-html="size(data.metrics.data.standard)"></div>
            </div>

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Full</div>
                <div class="areas-item-metrics-item-value" v-html="size(data.metrics.data.full, data.metrics.syncs.full)"></div>
                <div class="areas-item-metrics-item-value" v-html="size(data.metrics.data.full)"></div>
            </div>

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Deep</div>
                <div class="areas-item-metrics-item-value" v-html="size(data.metrics.data.deep, data.metrics.syncs.deep)"></div>
                <div class="areas-item-metrics-item-value" v-html="size(data.metrics.data.deep)"></div>
            </div>

        </div>

    </div>

    <div class="areas-item is-blue">

        <i class="areas-item-icon fa-solid fa-fingerprint"></i> 

        <div class="areas-item-value" v-html="size(data.metrics.space)"></div>

        <div class="areas-item-title">Your geekprint</div>

        <div class="areas-item-text">This is the total size of your geekgroup profile. Includes processed scraped data as well as all internal tracking, features, and insights.</div>

    </div>

    <div class="areas-item">

        <i class="areas-item-icon fa-solid fa-microscope"></i> 

        <div class="areas-item-value" v-html="time(data.metrics.scraping.total)"></div>

        <div class="areas-item-title">Total scraping time</div>

        <div class="areas-item-metrics" data-size="3">

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Standard</div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.scraping.standard, data.metrics.syncs.standard)"></div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.scraping.standard)"></div>
            </div>

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Full</div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.scraping.full, data.metrics.syncs.full)"></div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.scraping.full)"></div>
            </div>

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Deep</div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.scraping.deep, data.metrics.syncs.deep)"></div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.scraping.deep)"></div>
            </div>

        </div>

    </div>

    <div class="areas-item">

        <i class="areas-item-icon fa-solid fa-microchip"></i> 

        <div class="areas-item-value" v-html="time(data.metrics.processing.total)"></div>

        <div class="areas-item-title">Total processing time</div>

        <div class="areas-item-metrics" data-size="3">

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Standard</div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.processing.standard, data.metrics.syncs.standard)"></div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.processing.standard)"></div>
            </div>

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Full</div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.processing.full, data.metrics.syncs.full)"></div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.processing.full)"></div>
            </div>

            <div class="areas-item-metrics-item">
                <div class="areas-item-metrics-item-text">Deep</div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.processing.deep, data.metrics.syncs.deep)"></div>
                <div class="areas-item-metrics-item-value" v-html="time(data.metrics.processing.deep)"></div>
            </div>

        </div>

    </div>

</div>

</template>

<script>

export default {

    props: ['data'],
    
    methods: {

        size: function(value, average) {

            if(average === 0 || value === 0) return '-'

            var size = (value / 1024 / 1024)

            if(average) {

                size = size / average
            
            }

            return size.toFixed((size > 99) ? 0 : 2) + '<span>mb' + ((average) ? '/sync' : '') + '</span>'
        
        },

        time: function(value, average) {

            if(average === 0 || value === 0) return '-'

            var time = (average) ? value / average : value

            if(time < 60) {
            
                return time.toFixed(2) + '<span>s' + ((average) ? '/sync' : '') + '</span>'
            
            } else if(time < 3600) {
            
                return (time / 60).toFixed(2) + '<span>m' + ((average) ? '/sync' : '') + '</span>'
            
            } else {
            
                return (time / 3600).toFixed(2) + '<span>h' + ((average) ? '/sync' : '') + '</span>'

            }
        
        }

    }

}

</script>

<style scoped>

.areas {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 1550px) {

    .areas {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
    }

}

.is-mobile .areas {
    grid-template-columns: 1fr;
}

.areas-item {
    background-color: #4881bb;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #4881bb;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.areas-item.is-blue {
    background-color: #4881bb;
    border-color: #4881bb;
    color: #fff;
    border-radius: 4px;
}

.areas-item-icon {
    font-size: 64px;
    line-height: 64px;
    padding: 20px;
    text-align: center;
    height: 64px;
}

.areas-item-value {
    font-weight: 500;
    font-size: 40px;
    text-align: center;
}

.areas-item-value >>> span {
    opacity: 0.5;
    font-size: 24px;
    margin-left: 2px;
}

.areas-item-title {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin-top: 3px;
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.areas-item-text {
    font-size: 14px;
    line-height: 18px;
    padding: 0px 20px;
    text-align: center;
}

.areas-item.is-blue .areas-item-title {
    border-bottom: 1px solid #4881bb;
}

.areas-item-metrics-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.areas-item-metrics[data-size="3"] .areas-item-metrics-item {
    grid-template-columns: 64px auto 64px;
}

.areas-item-metrics-item:last-child {
    border-bottom: 0px;
}

.areas-item-metrics-item-text {
    font-weight: 400;
}

.areas-item-metrics-item-value {
    text-align: center;
    font-weight: 400;
}

.areas-item-metrics-item-value >>> span {
    opacity: 0.6;
    font-size: 12px;
    margin-left: 1px;
}

.areas-item-metrics-item-value:last-child {
    text-align: right;
}

</style>