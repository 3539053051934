<template>

<core-content-wrapper :empty="is.ready" :noResults="is.ready && !totalResults">

	<core-content-empty v-show="is.ready && !totalResults">

		<h2>Oh dear, no matches!</h2>

		<p>Your filtering skills are too strong, try broadening the settings.</p>

	</core-content-empty>

	<com-filter :loading="is.fetching" :count="totalPseudoResults" :maximum="totalMaxResults" />

	<core-content-list v-show="is.ready && totalResults" :flush="true">

		<div class="inner">

			<div class="activity-list-line"></div>

			<div class="activity-list-items">

				<com-action v-for="(item, index) in content" :showDate="showDate(index)" v-bind:key="item.id" :item="item" />

			</div>

			<core-content-scrollload v-on:scrolled="onScroll" v-show="currentPage < totalPages || (is.fetching && is.scrollLoading)" />

		</div>

	</core-content-list>

</core-content-wrapper>

</template>

<script>

import Content from 'core/mixin/List.js'

import comFilter from './activity/Filter.vue'
import comAction from './activity/Action.vue'

export default {

	mixins: [Content],

	components: {

		'com-filter': comFilter,
		'com-action': comAction

	},

	data: function() {

		return {

			contentName: 'activity',
			routeName: 'Activity'

		}

	},

	created: function() {
	
		this.$pubsub.$on('activity.refresh', this.onDynamicUpdate.bind(this))
	
	},

	beforeDestroy: function() {
	
		this.$pubsub.$off('activity.refresh', this.onDynamicUpdate.bind(this))
	
	},

	methods: {

		showDate: function(index) {

			var format = {
				show: false,
				day: true,
				month: true,
				year: true
			}

			if (index === 0) {

				format.show = true

			} else {

				var current = this.$moment.unix(this.content[index].date).utc()
				var previous = this.$moment.unix(this.content[index - 1].date).utc()

				if (current.format('D/M/Y') !== previous.format('D/M/Y')) {

					format.show = true

					format.month = current.format('M') !== previous.format('M')
					format.day = current.format('D') !== previous.format('D') || format.month
					format.year = true

				}

			}

			return format

		}

	}

}

</script>

<style scoped>

.activity-list-items {
	padding: 15px 0px;
}

.activity-list-line {
	position: absolute;
	left: 215px;
	width: 2px;
	top: 0px;
	height: 100%;
	bottom: 0px;
	background-color: #4881bb;
	z-index: 2;
}

@media only screen and (max-width: 1119px) {

	.activity-list-line {
		left: 200px;
	}

}

@media only screen and (max-width: 767px) {

	.activity-list-line {
		left: 22px;
	}

}

</style>