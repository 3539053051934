<template>

<div class="group" v-bind:class="{'is-expanded': is.expanded}">
	
	<div class="group-title" v-on:click="onExpandClick">

		{{ title }} 

		<div v-show="count" class="group-title-count">{{ count }}</div>

		<i class="group-chevron fa" v-bind:class="{'fa-chevron-down': is.expanded, 'fa-chevron-right': !is.expanded}"></i>

	</div>

	<div class="group-inputs">

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['title', 'count'],

	data: function() {

		return {

			is: {
				expanded: false
			}

		}

	},

	created: function() {

		this.$pubsub.$on('filter.advanced.expand', this.onFilterAdvancedExpand.bind(this))

	},

	beforeDestory: function() {

		this.$pubsub.$off('filter.advanced.expand', this.onFilterAdvancedExpand.bind(this))

	},

	methods: {

		onFilterAdvancedExpand: function(id) {

			if (id !== this._uid) this.is.expanded = false

		},

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

			if (this.is.expanded) {

				this.$pubsub.$emit('filter.advanced.expand', this._uid)

			}

		}

	}

}

</script>

<style scoped>

.group {
	flex-shrink: 0;
	width: 100%;
}

.group:last-child {
	border-bottom: 0px;
}

.group.is-expanded {
	margin-bottom: 0px;
}

.group.is-expanded .group.section {
	border-bottom: 0px;
}

.group.is-expanded >>> .group.section:first-child {
	padding-top: 5px!important;
}

.group-title {
	font-size: 14px;
	height: 30px;
	line-height: 30px;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.75);
	padding: 0px 15px;
	user-select: none;
	cursor: pointer;
}

.group.is-expanded .group-title {
	font-weight: 500;
}

@media only screen and (max-width: 767px) {

	.group-title {
		padding: 0px 8px;
	}

}

.group-title-count {
	position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #2f505a;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    padding: 2px 6px;
}

.group.is-expanded .group-title-count {
	background-color: #fff;
	color: #012B39;
}

.is-desktop .group-title:hover {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.2);
}

.group.is-expanded .group-title {
	margin-bottom: 5px;
	color: #fff;
	font-weight: 500;
}

.group-icon {
	margin-right: 4px;
	width: 24px;
}

.group-chevron {
	position: absolute;
	right: 15px;
	top: 0px;
	line-height: 30px;
	font-size: 12px;
}

@media only screen and (max-width: 767px) {

	.group-chevron {
		right: 8px;
	}

}

.group-inputs {
	display: none;
	width: 100%;
}

@media only screen and (max-width: 767px) {

	.group-inputs {
		padding: 0px 8px;
	}

}

.group.is-expanded .group-inputs {
	display: block;
}

.group-inputs > div:last-child {
	margin-bottom: 0px;
}

.group-inputs > p {
	font-size: 12px;
	font-weight: 400;
	color: #fff;
	line-height: 16px;
	margin-bottom: 15px;	
}

</style>
