<template>

<core-filter-advanced-group title="Tags" :count="count">

	<p v-if="!isOwner && tags.length">The following tags have been made public by the owner of this collection.</p>
	
	<p v-if="!tags.length">There are no tags available for this collection.</p>

	<core-filter-input-toggles :text="tag.text" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :posneg="tag.id" :filter="value('tags')" v-for="tag in tags" :key="tag.id" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {

			fields: ['tags'],

			toggle: {

				icons: [false, false, 'arrows-alt-h', false],
				values: [3, 1, 0, 2],
				labels: ['Mandatory', 'Include', '', 'Exclude']

			}

		}

	},

	computed: {

		tags: function() {

			return this.$store.getters['data/tags/list']

		}

	}

}

</script>