<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" />.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :subtext="subtext(item)" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'lifespan',
			games: [],
			params: ['index']
		}

	},

	computed: {

		title: function() {

			var years = Math.floor(this.data.index / 4)
			var quarter = this.data.index - (years * 4)

			console.log(quarter)

			if (quarter === 0) {

				return years.toString() + ' - ' + years.toString() + ' years, 3 months'

			} else if (quarter === 1) {

				return years.toString() + ' years, 3 - 6 months'

			} else if (quarter === 2) {

				return years.toString() + ' years, 6 - 9 months'

			} else if (quarter === 3) {

				return years.toString() + ' years, 9 months - ' + (years + 1).toString() + ' years'

			}

			return false

		}

	},

	methods: {	

		response: function(json) {

			this.games = json.games

		},

		subtext: function(item) {

			var parts = []
			
			if (item.added) {

				parts.push('Added ' + this.$options.filters.formatDate(item.added, this.$util.date.format('Do', 'MMMM', 'YYYY')))

			}

			if (item.removed) {

				parts.push(((item.added) ? 'removed ' : 'Removed ') + this.$options.filters.formatDate(item.removed, this.$util.date.format('Do', 'MMMM', 'YYYY')))

			}

			return parts.join('')

		}

	}

}

</script>