<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title[data.band] }} independence</h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" />.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'dependencies',
			games: [],
			params: ['band'],
			title: {
				1: 'Complete',
				2: 'High',
				3: 'Medium',
				4: 'Low',
				5: 'None'
			}
		}

	},

	methods: {	

		response: function(json) {

			this.games = json.games

		}

	}

}

</script>