import collection from './filter/collection'
import users from './filter/users'
import activity from './filter/activity'
import plays from './filter/plays'
import lists from './filter/lists'
import list from './filter/list'
import ratings from './filter/ratings'
import insights from './filter/insights'
import tools from './filter/tools'
import config from './filter/config'

export default {

	namespaced: true,

	modules: {
		collection: collection,
		users: users,
		activity: activity,
		plays: plays,
		lists: lists,
		list: list,
		insights: insights,
		tools: tools,
		config: config,
		ratings: ratings
	},

	state: {},

	getters: {

		config: function(state, getters) {

			return getters['config/values']

		},
		
		collection: function(state, getters) {

			return getters['collection/values']

		},

		users: function(state, getters) {

			return getters['users/values']

		},

		ratings: function(state, getters) {

			return getters['ratings/values']

		},

		activity: function(state, getters) {

			return getters['activity/values']

		},

		plays: function(state, getters) {

			return getters['plays/values']

		},

		insights: function(state, getters) {

			return getters['insights/values']

		},

		lists: function(state, getters) {

			return getters['lists/values']

		},

		list: function(state, getters) {

			return getters['list/values']

		}

	},

	mutations: {},

	actions: {}

}