// import _ from 'underscore'
import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		generic: function(context, data) {

			data = data || {}
			data.params = data.params || {}

			return new Promise(function(resolve, reject) {

				API.request(data.url, data.params, true).then(function(json) {

					context.dispatch('filter/insights/' + data.params.section + '/route', null, { root: true })

					context.commit('data/users/add', json.data.users, { root: true })
					context.commit('data/games/add', json.data.games, { root: true })
					
					context.commit('page/insights/' + data.params.section + '/set', json.insights, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})		

		},

		'users/detail': function(context, params) {

			params.filter = context.rootGetters['filter/insights/' + params.section]

			return new Promise(function(resolve, reject) {

				API.request('users/insights', params).then(function(json) {

					context.commit('data/users/add', json.data.users, { root: true })
					context.commit('data/games/add', json.data.games, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'groups/detail': function(context, params) {

			params.filter = context.rootGetters['filter/insights/' + params.section]

			return new Promise(function(resolve, reject) {

				API.request('groups/insights', params).then(function(json) {

					context.commit('data/users/add', json.data.users, { root: true })
					context.commit('data/games/add', json.data.games, { root: true })
					
					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'buddies/detail': function(context, params) {

			params.filter = context.rootGetters['filter/insights/' + params.section]

			return new Promise(function(resolve, reject) {

				API.request('buddies/insights', params).then(function(json) {

					context.commit('data/users/add', json.data.users, { root: true })
					context.commit('data/games/add', json.data.games, { root: true })
					
					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'users': function(context, params) {

			params.filter = context.rootGetters['filter/insights/' + params.section]
			
			return context.dispatch('generic', {
				params: params,
				url: 'users/insights'
			})	
			
		},

		'groups': function(context, params) {

			params.filter = context.rootGetters['filter/insights/' + params.section]

			return context.dispatch('generic', {
				params: params,
				url: 'groups/insights'
			})	
			
		},

		'buddies': function(context, params) {

			params.filter = context.rootGetters['filter/insights/' + params.section]

			return context.dispatch('generic', {
				params: params,
				url: 'buddies/insights'
			})	
			
		}

	}

}