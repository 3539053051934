<template>

<div class="advanced" v-bind:class="{'is-active': active}">

	<core-filter-advanced-section class="advanced-head" v-propagation>
		
		<div class="head-title">{{ (title) ? title : 'Filter' }}</div>

		<div class="head-close" v-tooltip="'Close'" v-on:click.stop="onClose"><i class="fa fa-remove"></i></div>

	</core-filter-advanced-section>

	<div class="advanced-body">

		<core-simplebar class="advanced-content-wrapper" :class="{'is-sorting': hideScroll}">

			<div class="advanced-content" v-propagation>

				<slot name="inputs"></slot>

			</div>

		</core-simplebar>

	</div>

	<core-filter-advanced-section class="advanced-foot" v-propagation v-if="hasButtons">
		
		<slot name="buttons"></slot>

	</core-filter-advanced-section>

</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: ['active', 'title'],

	data: function() {

		return {
			hideScroll: false
		}

	},

	computed: {

		hasButtons: function() {

			if (this.$slots.buttons !== undefined) {

				var exists = false

				this.$_.each(this.$slots.buttons, function(button) {

					if (button.tag) exists = true

				})

				if (exists) return true

			}

			return false

		}

	},

	watch: {

		active: function(n) {

			if (n) this.$pubsub.$emit('panel.opened')
			if (!n) this.$pubsub.$emit('panel.closed')

			if (this.window.is.mobile) {

				if (n) this.$pubsub.$emit('open.dropdown')
				if (!n) this.$pubsub.$emit('close.dropdown')

			}

		}

	},

	created: function() {

		this.$pubsub.$on('open.inline.sort', this.onOpenInlineSort.bind(this))
		this.$pubsub.$on('close.inline.sort', this.onCloseInlineSort.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('open.inline.sort', this.onOpenInlineSort.bind(this))
		this.$pubsub.$off('close.inline.sort', this.onCloseInlineSort.bind(this))

	},

	methods: {

		onOpenInlineSort: function() {

			this.hideScroll = true

		},

		onCloseInlineSort: function() {

			this.hideScroll = false

		},

		onClose: function() {

			this.$emit('clickoutside')

		}

	}

}

</script>

<style scoped>

.advanced-head {
	z-index: 2;
	flex-shrink: 0;
	height: 88px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
    background-color: #19455c;
}

.is-smaller .advanced-head {
	height: 64px;
}

.is-mobile .advanced-head {
	height: 48px;
	padding: 0px 10px;
}

.advanced-head:after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #19455c transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    left: 17px;
    bottom: -10px;
}

.head-title {
	text-align: left;
	font-size: 20px;
	color: #fff;
	font-weight: 500;
	line-height: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.is-mobile .head-title {
	font-size: 16px;
}

.head-close {
	position: absolute;
	right: 20px;
	top: 24px;
	height: 40px;
	width: 40px;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.25);
	color: #fff;
	line-height: 40px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	z-index: 3;
}

.is-smaller .head-close {
	top: 12px;
}

.is-mobile .head-close {
	top: 8px;
	width: 32px;
	height: 32px;
	right: 10px;
	line-height: 32px;
	font-size: 16px;
}

.is-desktop .head-close:hover,
.is-smaller .head-close:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.advanced {
	position: fixed;
	right: -640px;
	top: 64px;
	width: 640px;
	bottom: 0px;
	z-index: 1001;
	transition: right 100ms linear;
	display: flex;
	flex-direction: column;
}

.is-mobile .advanced {
	transition: none;
	width: 100%;
	right: -100%;
	top: 144px;
}

.advanced.is-active {
	right: 0px;
}

.advanced-body {
	width: 640px;
	display: flex;
	flex-grow: 1;
	z-index: 2;
	overflow: hidden;
}

@media only screen and (max-width: 767px) {

	.advanced-body {
		width: 100%;
	}

}

.advanced-content-wrapper {
	width: 100%;
}

.advanced-content-wrapper.is-sorting >>> .simplebar-wrapper {
	z-index: 2;
}

.advanced-content {
	width: 320px;
	margin-left: 320px;
	background-color: #2b5870;
	display: flex;
	flex-grow: 1;
	padding-top: 20px;
	flex-direction: column;
}

.advanced-head {
	margin-left: 320px;
	width: 320px;
	flex-shrink: 0;
	z-index: 1;
}

.advanced-foot {
	margin-left: 320px;
	width: 320px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	z-index: 1;
	padding: 10px 20px!important;
    background-color: #19455c;
}

.advanced-foot >>> .button {
	margin-top: 10px;
}

.advanced-foot >>> .button:first-child {
	margin-top: 0px;
}

@media only screen and (max-width: 767px) {

	.advanced-content {
		margin-left: 0px;
		width: 100%;
		padding-top: 10px;
	}
	
	.advanced-head {
		margin-left: 0px;
		width: 100%;
		padding: 8px 10px 8px 10px!important;
	}
	
	.advanced-foot {
		margin-left: 0px;
		width: 100%;
		padding: 8px 4px 8px 4px!important;
		flex-direction: row;
	}

	.advanced-foot >>> .button {
		margin: 0px 4px;
	}

}

</style>