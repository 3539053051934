<template>

<core-card :item="item" :hasActions="true">

	<core-card-head type="game" :id="item.game.id" size="large">

		<app-avatar-include :id="item.game.id" filter="collection" :card="true" />

		<core-card-head-ratings>

			<app-game-card-rating v-for="(rating, index) in field('rating', true)" :item="item" :format="rating" v-bind:key="index" />

		</core-card-head-ratings>

	</core-card-head>

	<core-card-body :grow="true" :padded="true">

		<app-game-card-name :item="item" :format="field('name')" :filter="filter" />

		<app-game-card-tags :item="item" :format="field('name')" :filter="filter" />

		<app-game-card-owners :item="item" :format="field('name')" :filter="filter" />

	</core-card-body>

	<core-card-foot>

		<app-game-card-count :item="item" :format="field('count')" :filter="filter" />
		<app-game-card-duration :item="item" :format="field('duration')" :filter="filter" />
		<app-game-card-weight :item="item" :format="field('weight')" :filter="filter" />
		<app-game-card-plays :item="item" :format="field('plays')" :filter="filter" />

	</core-card-foot>

</core-card>

</template>

<script>

export default {

	props: ['item', 'fields', 'format', 'filter'],

	methods: {

		field: function(type, multiple) {

			multiple = multiple || false

			return this.$_[(multiple) ? 'where' : 'findWhere'](this.fields, {
				type: type
			})

		}

	}

}

</script>

<style scoped>

</style>