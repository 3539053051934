<template>

<core-content-wrapper :empty="is.ready && !totalMaxResults" :noResults="is.ready && totalMaxResults && !totalResults" :loading="!is.ready">

	<core-content-empty v-show="is.ready && !totalMaxResults" title="Oh dear, no plays!" text="It seems there are no plays." />
	<core-content-empty v-show="is.ready && totalMaxResults && !totalResults" title="Oh dear, no matches!" text="Your filtering skills are too strong, try broadening the settings." />

	<app-filter :loading="is.fetching" :count="totalPseudoResults" :maximum="totalMaxResults" :gameMode="gameMode" v-show="totalMaxResults" v-on:refresh="refresh" />

	<app-play-head :fixed="is.fixedHead" v-if="!gameMode && !isCard" v-show="is.ready && totalMaxResults && totalResults" />
	<app-game-head :fixed="is.fixedHead" v-if="gameMode && !isCard" v-show="is.ready && totalMaxResults && totalResults" />

	<core-content-list :fixed="is.fixedHead && !isCard" v-on:fixed="onFixedChange" v-show="is.ready && totalMaxResults && totalResults" :cards="isCard">

		<template v-if="isCard && !gameMode"><app-play-card v-for="item in content" v-bind:key="item.play.id" :item="item" /></template>
		<template v-if="!isCard && !gameMode"><app-play-list v-for="item in content" v-bind:key="item.play.id" :item="item" /></template>
		<template v-if="isCard && gameMode"><app-game-card v-for="item in content" v-bind:key="item.game.id" :item="item" /></template>
		<template v-if="!isCard && gameMode"><app-game-list v-for="item in content" v-bind:key="item.game.id" :item="item" /></template>

		<core-content-scrollload v-on:scrolled="onScroll" v-show="currentPage < totalPages || (is.fetching && is.scrollLoading)" />

	</core-content-list>

	<core-content-select :noun="selectNoun" />

</core-content-wrapper>

</template>

<script>

import Content from 'core/mixin/List.js'

import Filter from './plays/Filter.vue'

import PlayHead from './plays/PlayHead.vue'
import PlayCard from './plays/PlayCard.vue'
import PlayList from './plays/PlayList.vue'

import GameHead from './plays/GameHead.vue'
import GameCard from './plays/GameCard.vue'
import GameList from './plays/GameList.vue'

export default {

	mixins: [Content],

	components: {

		'app-filter': Filter,

		'app-play-head': PlayHead,
		'app-play-list': PlayList,
		'app-play-card': PlayCard,

		'app-game-head': GameHead,
		'app-game-list': GameList,
		'app-game-card': GameCard

	},

	data: function() {

		return {

			gameMode: 0,
			contentName: 'plays',
			routeName: 'Plays'

		}

	},

	computed: {

		selectNoun: function() {

			return (this.gameMode) ? 'game' : 'play'

		},

		isCard: function() {

			return this.$store.getters['filter/config/plays/layout'] === this.$CONSTANTS.LAYOUT.PLAYS.CARDS

		}

	},

	created: function() {

		this.gameMode = this.$store.getters['filter/plays/play/group']

		this.$on('ready', function() {

			this.gameMode = this.$store.getters['filter/plays/play/group']

		}.bind(this))

	},

	methods: {

		updateSelectServices: function() {

			this.selectServices.log = !this.$store.getters['filter/plays/play/group']

		}

	}

}

</script>

<style scoped>

</style>