<template>

<div class="input" v-bind:class="{'is-active': selected}">

	<div class="input-text input-text-prefix" v-if="text">{{ text }}</div>

	<div class="input-inputs">
			
		<core-datepicker v-on:opened="onOpened" v-on:closed="onClosed" v-on:selected="onDateSelected" v-on:click.native="onClick" :value="date" :full-month-name="true" :placeholder="placeholder" :use-utc="true" class="input-date" input-class="datepicker-input is-from" wrapper-class="datepicker-wrapper" calendar-class="datepicker-calendar is-left"></core-datepicker>

		<div class="input-cancel" v-on:click="onCancel" v-propagation><i class="fa fa-times"></i></div>

	</div>

</div>

</template>

<script>

import Datepicker from 'core/assets/datepicker'

export default {

	components: {
		'core-datepicker': Datepicker
	},

	props: [
		'filter', 'text', 'placeholder'
	],

	data: function() {

		return {
			selected: false
		}

	},

	computed: {

		date: function() {

			var value = this.$store.getters['filter/' + this.filter]

			return (value) ? this.$moment.unix(value).toDate() : null

		}

	},

	methods: {

		onCancel: function() {

			this.$store.commit('filter/' + this.filter, 0)

		},

		onClick: function(e) {

			e.stopPropagation()

		},

		onDateSelected: function(date) {

			this.$store.commit('filter/' + this.filter, this.$moment(date).utc().startOf('day').unix())

		},

		onOpened: function() {

			this.selected = true

		},

		onClosed: function() {

			this.selected = false

		}

	}

}

</script>

<style scoped>

.input {
	position: relative;
	display: flex;
	line-height: 32px;
	padding-bottom: 8px;
}

.input-inputs {
	display: flex;
}

.input-date {
	flex-grow: 1;
    font-weight: 400;
	font-size: 12px;
}

.input-date input::placeholder {
	color: #fff;
}

.input-date.is-active input {
	background-color: red;
}

.input-text {
	text-align: center;
	color: #fff;
	font-weight: 400;
	padding: 0px 9px;
	font-size: 12px;
}

.input-text-prefix {
	white-space: nowrap;
	padding: 0px;
	width: 160px;
	text-align: left;
}

.input-cancel {
	color: rgba(255, 255, 255, 0.5);
	font-weight: 400;
	font-size: 16px;
	position: absolute;
	top: 0px;
	margin-left: -46px;
	width: 32px;
	height: 32px;
	line-height: 34px;
	text-align: center;
	z-index: 2;
	cursor: pointer;
	right: 0px;
}

.is-desktop .input-cancel:hover {
	color: #fff;
}


</style>
