<template>

<com-item name="plays.overview" :size="size" :is-overview="4" :loading="loading" :is-empty="!active" title="Overview">

	<template v-slot:about>

		<p>A summary of {{ your }} plays {{ periodText }}.</p>

	</template>

	<template v-slot:stats>

		<com-overview label="Total Plays" type="number" :value="data.plays" />

		<com-overview label="Different Games" type="number" :value="data.games" />

		<com-overview label="New Games" type="number" :value="data.first" v-if="isYearly" />

		<com-overview label="% Not Owned" type="percent" :value="data.playedunowned" />

		<com-overview label="Avg. Rating" type="rating" :value="data.avgRating" />

		<com-overview label="Played Once" type="number" :value="data.once" />

		<com-overview label="Played 5+ times" type="number" :value="data.played5" />

		<com-overview label="Played 10+ times" type="number" :value="data.played10" />

		<com-overview label="Played 25+ times" type="number" :value="data.played25" />

		<com-overview label="Avg. per Day" type="number" :value="data.avgDay" />

		<com-overview label="Avg. per Week" type="number" :value="data.avgWeek" />

		<com-overview label="Avg. per Month" type="number" :value="data.avgMonth" />

		<com-overview label="Avg. per Year" type="number" :value="data.avgYear" v-if="!isYearly" />

	</template>

</com-item>

</template>

<script>

import comOverview from './../common/Overview'
import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'period'],

	components: {
		'com-item': comItem,
		'com-overview': comOverview
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/plays/overview']

		},

		active: function() {

			return this.data && !this.loading

		},

		isYearly: function() {

			return this.period !== 'all'

		}

	}

}

</script>

<style scoped>

</style>