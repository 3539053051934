<template>

<core-dropdown-item type="divide" />

</template>

<script>

export default {

}

</script>
