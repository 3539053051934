<template>

<core-filter-advanced-group title="Designers" :count="count">

	<core-filter-input-suggest placeholder="Start typing a designer's name..." :toggle="true" field="designer_name" :filter="value('designers')" api="designers/suggest" data="designers" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['designers']
		}
	}

}

</script>