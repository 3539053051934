<template>

<core-dropdown class="account" trigger="manual" v-on:forceClose="is.expanded = false" :expanded="is.expanded" v-click-outside="onOutsideClick">

    <div class="account-button" v-on:click="onToggleClick">
        <core-avatar v-if="loggedin" type="session" class="account-avatar" />
        <div v-if="!loggedin" class="account-avatar"><i class="fa fa-user"></i></div>
        <div class="account-caret"><i class="fa fa-caret-down"></i></div>
    </div>

    <core-dropdown-list v-if="loggedin" class="account-options" theme="head" align="right" type="button" :caret="true">

        <core-dropdown-item v-if="!isViewingSelf" class="account-options-item" type="link" icon="user" text="Go to your profile" v-on:click.native="onProfileClick" />
        <core-dropdown-item class="account-options-item" type="divide" />
        <core-dropdown-item class="account-options-item" type="link" icon="cog" text="Settings" v-on:click.native="onSettingsClick" />
        <core-dropdown-item class="account-options-item" type="link" icon="rotate" text="Syncs" v-on:click.native="onSyncsClick" />
        <core-dropdown-item class="account-options-item" type="link" icon="gift" text="Tips & Perks" v-on:click.native="onPerksClick" />
        <core-dropdown-item class="account-options-item" type="divide" />
        <core-dropdown-item class="account-options-item" type="link" icon="unlock" text="Change Password" v-on:click.native="onPasswordClick" />
        <core-dropdown-item class="account-options-item" type="divide" />
        <core-dropdown-item class="account-options-item" type="link" icon="sign-out-alt" text="Logout" v-on:click.native="onLogoutClick" />

    </core-dropdown-list>

    <core-dropdown-list v-if="!loggedin" class="account-options" theme="head" align="right" type="button" :caret="true">

        <core-dropdown-item class="account-options-item" type="link" icon="sign-in-alt" text="Login" v-on:click.native="onLoginClick" />
        <core-dropdown-item class="account-options-item" type="link" icon="user-plus" text="Register" v-on:click.native="onRegisterClick" />

    </core-dropdown-list>

</core-dropdown>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

    data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

    computed: {
    
        isViewingSelf: function() {
        
            return this.$store.getters['session'].slug === this.$route.params.name && this.isUser
        
        }

    },

    methods: {

        onProfileClick: function() {
        
            this.$router.push({
				name: 'Collection',
				params: {
					name: this.$store.getters['session'].slug,
					type: this.$CONSTANTS.TYPE.USERS
				}
			}).catch(function() {})

        },

        onPerksClick: function() {
        
			this.$router.push({
                name: 'SessionPerks'
            })

            this.is.expanded = false

        },

        onSyncsClick: function() {
        
			this.$router.push({
                name: 'SessionSyncs'
            })

            this.is.expanded = false

        },

        onSettingsClick: function() {
        
			this.$router.push({
                name: 'SessionSettings'
            })

            this.is.expanded = false

        },

        onPasswordClick: function() {
        
			this.$pubsub.$emit('open.modal', 'userPassword')

            this.is.expanded = false

        },

        onLogoutClick: function() {
        
			this.$router.push({name: 'Logout'}).catch(function(e) { console.log(e) })

        },

        onLoginClick: function() {
        
			this.$pubsub.$emit('open.modal', 'userLogin')

            this.is.expanded = false

        },

        onRegisterClick: function() {
        
			this.$pubsub.$emit('open.modal', 'userRegister')

            this.is.expanded = false

        },
    
        onToggleClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		}
    
    }

}

</script>

<style scoped>

.account {
    height: 64px;
    margin-right: 20px;
    margin-left: 10px;
}

.is-mobile .account {
    height: 48px;
    margin-left: 5px;
    margin-right: 10px;
}

.account-button {
    cursor: pointer;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    height: 64px;
}

.is-mobile .account-button {
    height: 48px;
}

.account-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
}

.is-mobile .account-avatar {
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 16px;
}

.account-caret {
    color: #fff;
    margin-left: 10px;
}

.is-mobile .account-caret {
    margin-left: 5px;
}

</style>
