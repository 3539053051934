<template>

<div v-if="layout.show.app" class="tool-foot" v-bind:class="{'has-margin': layout.spacing > 0}" v-bind:style="{paddingRight: margin, paddingBottom: margin}">
	generated with geekgroup.app
</div>

</template>

<script>

export default {

	props: ['layout', 'margin']

}

</script>

<style scoped>

.tool-foot {
	font-size: 10px;
	color: #333;
	text-align: right;
	padding: 4px 0px 4px 4px;
}

.tool-foot.has-margin {
	padding: 0px;
}

</style>
