<template>

<div class="content">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.content {
	padding: 15px;
}

.content >>> *:last-child {
	margin-bottom: 0px;
}

.content >>> h2 {
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 15px;
	text-align: left;
}

.content >>> p {
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	font-weight: 300;
	margin-bottom: 15px;
	text-align: left;
}

.content >>> ul {
	margin-bottom: 15px;
	list-style: square;
	list-style-position: inside;
}

.content >>> li {
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	color: #fff;
}

.content >>> table {
	width: 100%!important;
	margin-bottom: 15px;
}

.content >>> td {
	width: auto!important;
	padding: 0px 4px 15px 0px;
}

.content >>> p a {
	color: #35b8ec;
}

.content >>> p a:hover {
	text-decoration: underline;
}

</style>