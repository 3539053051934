<template>

<div class="suggestions" v-bind:class="{'is-expanded': is.expanded, 'is-suggesting': is.suggesting && changed, 'is-active': is.active, 'is-loading': is.loading}" v-propagation>
	
	<div class="suggestions-head">

		<h1>Suggestions <span class="suggestions-head-count">{{suggestions.length}} <plural t="game" :n="suggestions.length" /></span></h1>

	</div>

	<div class="suggestions-list" v-if="suggestions.length">

		<core-simplebar class="suggestions-list-scroll">

			<div class="suggestions-item" v-for="game in suggestions" v-bind:key="game.id">
				
				<div class="suggestion-item-name">{{ game.name }}</div>
				
				<div class="suggestion-item-options" v-bind:class="{'is-saving': is.saving === game.id}">
					<i class="fa fa-plus-circle" v-on:click="onAddClick($event, game.id)" v-if="!game.exists"></i>
					<i class="fa fa-minus-circle" v-on:click="onRemoveClick($event, game.id)" v-if="game.exists"></i>
				</div>

			</div>

		</core-simplebar>

	</div>

	<div class="suggestions-empty" v-if="!suggestions.length">

		No games found.

	</div>

	<div class="suggestions-foot">

		<core-button :loading="is.saving" v-on:click.native="onAllClick($event)" theme="blue" :disabled="suggestions.length === 0" fluid="yes" class="suggestions-foot-button">Add all games</core-button>

		<core-button v-on:click.native="onCloseClick" theme="blue" fluid="yes" class="suggestions-foot-button" v-if="window.is.mobile">Close suggestions</core-button>

	</div>

</div>

</template>

<script>

export default {

	components: {
		
	},

	data: function() {

		return {
			suggestions: [],
			is: {
				suggesting: false,
				saving: false,
				loading: false,
				active: false,
				expanded: false
			}
		}

	},

	computed: {

		filter: function() {

			return this.$store.getters['filter/list']

		},

		changed: function() {

			return this.$store.getters['filter/list/changed']

		}

	},

	watch: {

		filter: {

			handler: function() {

				if (this.is.active && this.changed) {

					this.is.loading = true

					this.$store.dispatch('api/list/suggest', {
						filter: this.filter
					}).then(function(json) {

						this.suggestions = json.suggestions
						this.is.loading = false
						this.is.suggesting = true

						this.$pubsub.$emit('list.import.suggestions.count', this.suggestions.length)

					}.bind(this), function() {

						this.is.loading = false

					}.bind(this))

				}

			},
			deep: true

		}

	},

	created: function() {

		this.$pubsub.$on('list.import.suggestions', this.onListImportSuggestions.bind(this))
		this.$pubsub.$on('list.import.expand', this.onListImportExpand.bind(this))
		this.$pubsub.$on('list.import.collapse', this.onListImportCollapse.bind(this))
		this.$pubsub.$on('list.refreshed', this.onListRefreshed.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('list.import.suggestions', this.onListImportSuggestions.bind(this))
		this.$pubsub.$off('list.import.expand', this.onListImportExpand.bind(this))
		this.$pubsub.$off('list.import.collapse', this.onListImportCollapse.bind(this))
		this.$pubsub.$off('list.refreshed', this.onListRefreshed.bind(this))

	},

	methods: {

		onListImportSuggestions: function() {

			this.is.expanded = true

		},

		onListImportExpand: function() {

			this.is.active = true

		},

		onListImportCollapse: function() {

			this.suggestions = []
			this.is.active = false
			this.is.suggesting = false

		},

		onListRefreshed: function() {

			this.is.saving = false

		},

		onAllClick: function(e) {

			e.stopPropagation()

			this.add(this.$_.pluck(this.suggestions, 'id'))

		},

		onAddClick: function(e, id) {

			this.is.saving = id

			this.add([id])

		},

		onCloseClick: function() {

			this.is.expanded = false

		},

		add: function(games) {

			this.$store.dispatch('api/list/add', {
				games: games
			}).then(function() {

				this.$pubsub.$emit('list.refresh')

				this.$_.each(this.suggestions, function(item) {

					if (this.$_.contains(games, item.id)) item.exists = true

				}.bind(this))

			}.bind(this), function() {

				this.is.saving = false

			}.bind(this))

		},

		onRemoveClick: function(e, id) {

			this.is.saving = id

			this.$store.dispatch('api/list/remove', {
				games: [id]
			}).then(function() {

				this.$pubsub.$emit('list.refresh')

				this.$_.each(this.suggestions, function(item) {

					if (item.id === id) item.exists = false

				})

				this.is.saving = false

			}.bind(this), function() {

				this.is.saving = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.suggestions {
	position: fixed;
	right: -320px;
	width: 320px;
	background-color: #035874;
	top: 96px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
}

.suggestions.is-active {
	right: 0px;
}

.suggestions.is-active.is-loading,
.suggestions.is-active.is-suggesting {
	right: 320px;
	z-index: 1002;
}

@media only screen and (max-width: 767px) {

	.suggestions {
		right: -100%;
		width: 100%;
	}

	.suggestions.is-active {
		right: -100%;
	}
	
	.suggestions.is-active.is-loading,
	.suggestions.is-active.is-suggesting {
		right: -100%;
	}

	.suggestions.is-active.is-loading.is-expanded,
	.suggestions.is-active.is-suggesting.is-expanded {
		right: 0px;
		z-index: 1002;
	}

}

.suggestions-head {
	height: 40px;
	line-height: 40px;
	flex-shrink: 0;
	border-bottom: 1px solid #046E8F;
}

.suggestions-list {
	flex-grow: 1;
	overflow: hidden;
}

.suggestions-list-scroll {
	height: 100%;
	overflow-x: hidden;
}

.suggestions-foot {
	flex-shrink: 0;
	padding: 7px 15px 15px 15px;
	border-top: 1px solid #046E8F;
}

.suggestions-foot-button {
	margin-top: 8px;
}

@media only screen and (max-width: 767px) {
	
	.suggestions-foot {
		padding: 0px 8px 8px 8px;
	}

}

.suggestions.is-loading {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	pointer-events: none;
	background-image: url(~core/assets/load-transparent.gif);
}

.suggestions.is-loading > * {
	opacity: 0;
}

.suggestions h1 {
	color: #fff;
	font-size: 16px;
	padding-left: 15px;
}

@media only screen and (max-width: 767px) {

	.suggestions h1 {
		padding-left: 8px;
	}

}

.suggestions-head-count {
	color: rgba(255, 255, 255,  0.5);
	font-size: 12px;
	margin-left: 5px;
}

.suggestions-item {
	color: #fff;
	padding: 7px 15px;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.suggestions-item {
		padding: 7px 8px;
	}

}

.is-desktop .suggestions-item:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.suggestion-item-name {
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.suggestion-item-options {
	flex-shrink: 0;
	width: 18px;
}

.suggestion-item-options.is-saving {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	pointer-events: none;
	background-image: url(~core/assets/load-transparent.gif);
}

.suggestion-item-options.is-saving > * {
	opacity: 0;
}

.suggestion-item-options .fa {
	width: 18px;
	line-height: 18px;
	height: 18px;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
}

.suggestions-empty {
	font-size: 14px;
	flex-grow: 1;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	line-height: 18px;
	color: rgba(255, 255, 255, 0.5);
	padding: 0px 15px;
}

</style>