import Store from 'app/store'
import Util from 'app/util'

export default {

	data: function() {

		return {

			is: {
				ready: false,
				fetching: false,
				filterChanged: false,
				scrollLoading: false,
				routeChange: false,
				fixedHead: false
			},

			selectServices: {
				log: false,
				rename: true,
				export: true,
				nesting: false,
				filter: true,
				tag: true,
				game: true,
				list: {
					remove: false
				},
				users: {
					remove: false
				}
			},

			searchKey: '',
			totalResults: 0,
			totalPseudoResults: 0,
			totalNestedResults: 0,
			totalMaxResults: 0,
			totalPages: 0,
			currentPage: 1,
			currentFilter: {},
			routeName: false,
			contentName: '',
			content: []

		}

	},

	computed: {

		filter: function() {

			return Store.getters['filter/' + this.contentName]

		},

		isFiltering: function() {

			return Store.getters['filter/' + this.contentName + '/status/active']

		},

		context: function() {

			return Store.getters['context']

		}

	},

	beforeRouteUpdate: function(to, from, next) {

		if (to.params.name !== from.params.name || to.params.type !== from.params.type) {

			this.is.ready = false

			this.reset(to).then(function() {

				this.is.routeChange = true

				next()	

				this.fetch()

			}.bind(this))

		} else {

			// filter fetch didn't cause route to change
			if (!this.is.fetching) {

				this.is.routeChange = true

				Store.dispatch('filter/' + this.contentName + '/analyse', to.query)

			}

			next()

		}

		if (to.fullPath !== from.fullPath) {

			// eslint-disable-next-line
			ga('set', 'page', to.fullPath)

			// eslint-disable-next-line
			ga('send', 'pageview')

		}

	},

	watch: {

		isOwner: function() {

			this.selectServices.tag = this.isUser && this.isOwner
			this.selectServices.rename = this.isUser && this.isOwner

		},

		filter: {

			handler: function(newValue) {

				if (this.is.ready) {

					// only fetch if something changed
					if (Util.filter.differences(newValue, this.currentFilter, this.contentName)) {

						this.currentPage = 1

						this.fetch()

						this.is.filterChanged = true

					} else {

						if (!this.is.routeChange) {

							Store.dispatch('filter/' + this.contentName + '/route')

						}

						this.currentFilter = Util.filter.autofill(newValue, this.contentName)

						this.is.routeChange = false

						this.$pubsub.$emit('ready')

					}

				}

			},
			deep: true

		}

	},

	created: function() {

		this.$pubsub.$emit('unready')

		this.$pubsub.$on('dynamic.refresh.' + this.contentName, this.onDynamicUpdate.bind(this))

		this.reset(this.$route).then(function() {

			this.fetch()

		}.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('dynamic.refresh.' + this.contentName, this.onDynamicUpdate.bind(this))

	},

	methods: {

		onFixedChange: function(value) {
		
			this.is.fixedHead = value

		},

		onDynamicUpdate: function(e) {

			e = e || {}

			var toPage = false

			if (e.reload) {

				toPage = this.currentPage
				this.currentPage = 1
				this.searchKey = false

			}

			this.fetch(false, false, toPage)

		},

		onScroll: function() {

			if (!this.is.fetching && this.currentPage < this.totalPages && this.$route.name === this.routeName && !this.is.scrollLoading) {

				this.currentPage++

				this.is.scrollLoading = true

				this.fetch()

			}

		},

		reset: function(to) {

			this.is.ready = false

			this.selectServices.tag = this.isUser && this.isOwner
			this.selectServices.rename = this.isUser && this.isOwner
			
			return new Promise(function(resolve) {

				this.currentPage = 1
				this.searchKey = false

				Store.commit('filter/' + this.contentName + '/reset')

				Util.filter.analyse(to, this.contentName).then(function() {

					resolve()

				}, function() {

					Store.dispatch('filter/' + this.contentName + '/analyse', to.query).then(function() {

						resolve() 

					})

				}.bind(this))

			}.bind(this))

		},

		refresh: function() {

			this.currentPage = 1
			this.searchKey = false
			this.fetch()

		},

		fetch: function(type, name, toPage) {

			if (this.updateSelectServices) this.updateSelectServices()

			type = type || this.$route.params.type
			name = name || this.$route.params.name
			toPage = toPage || false

			this.is.fetching = true

			Store.dispatch('api/' + this.contentName + '/' + type, {
				name: name,
				key: (this.currentPage === 1) ? false : this.searchKey,
				toPage: toPage,
				page: this.currentPage
			}).then(function(json) {

				if (this.currentPage === 1 && !json.toPage) this.$pubsub.$emit('content.list.scrolltop')

				// filter changed, not the route, so update route
				if (!this.is.routeChange) {

					this.$store.dispatch('filter/' + this.contentName + '/route')

				}

				this.is.filterChanged = false

				this.searchKey = json.key
				this.totalResults = json.total
				this.totalPseudoResults = json.totalPseudo
				this.totalMaxResults = json.totalMax
				this.totalNestedResults = json.totalNested
				this.totalPages = json.pages

				if (this.currentPage === 1) {

					this.$store.dispatch('service/select/init', {
						key: this.searchKey,
						name: this.contentName,
						count: this.totalResults,
						can: this.selectServices,
						pages: this.totalPages
					})

				} else {

					this.$store.commit('service/select/page', this.currentPage)

				}

				if (json.toPage) {

					this.currentPage = json.toPage
					this.$store.commit('service/select/page', this.currentPage)
					
				}

				this.$emit('ready')

				this.content = Store.getters['page/' + this.contentName]

				this.is.scrollLoading = false
				this.is.routeChange = false

				this.currentFilter = Util.filter.autofill(this.filter, this.contentName)
				
				this.is.ready = true
				this.is.fetching = false

				this.$pubsub.$emit('ready')

			}.bind(this), function(e) {

				if (e.name !== 'AbortError') {

					this.is.scrollLoading = false

				}

			}.bind(this))

		}

	}

}