<template>

<core-dropdown class="input" trigger="manual" v-on:forceClose="is.expanded = false" :expanded="is.expanded" v-click-outside="onOutsideClick">

	<core-button v-on:click.native="onToggleClick" theme="filter" tooltip="More actions" icon="ellipsis-h" :iconOnly="true" class="input-icon" />
	
	<core-dropdown-list :caret="true" class="input-options" align="left" type="button">

		<slot></slot>

		<template v-if="can.tag && !window.is.desktop">

			<core-dropdown-item type="link" v-on:click.native="onTagsClick">Manage tags</core-dropdown-item>

		</template>

		<template v-if="can.export">

			<core-dropdown-item type="divide" />

			<core-dropdown-item type="title">Export</core-dropdown-item>
			<core-dropdown-item type="link" v-on:click.native="onExportCSVClick" :loading="is.exporting">As CSV</core-dropdown-item>
			<core-dropdown-item type="link" v-on:click.native="onExportClipboardClick" :loading="is.copying">To Clipboard</core-dropdown-item>

		</template>

	</core-dropdown-list>

</core-dropdown>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: [
		'filter', 'page'
	],
	
	data: function() {

		return {
			is: {
				expanded: false,
				copying: false,
				exporting: false
			}
		}

	},

	computed: {

		can: function() {

			return this.$store.getters['service/select/can']

		}

	},

	methods: {

		onExportCSVClick: function(e) {

			e.stopPropagation()

			this.is.exporting = true

			this.$store.dispatch('service/select/export/csv').then(function() {

				this.is.exporting = false

			}.bind(this))

		},

		onExportClipboardClick: function(e) {

			e.stopPropagation()

			this.is.copying = true

			this.$store.dispatch('service/select/export/clipboard').then(function() {

				this.is.copying = false

			}.bind(this))

		},

		onTagsClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('filter.panel.tags.open')

			this.onOutsideClick()

		},

		onToggleClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

.input {
	padding: 0px 0px;
}

.input-options {
	right: -1px;
	width: auto;
	top: 48px!important;
	border-radius: 4px;
	padding: 4px 0px;
	z-index: 2000;
}

.is-mobile .input-options {
	top: 144px!important;
	border-radius: 0px;
}

.input:not(.is-inline) .input-options:before {
	display: none;
}

.input-options-item {
	white-space: nowrap;
	padding: 8px 15px 8px 15px;
	cursor: pointer;
	font-size: 12px;
	line-height: 16px;
}

.input-options-item .fa {
	font-size: 12px;
	width: 20px;
}

.is-desktop .input-options-item:hover {
	color: #fff;
	font-weight: 400;
}

.input-options-item.is-active {
	color: #fff;
	font-weight: 600;
}

</style>
