<template>

<core-modal v-on:close="onClose" type="confirm" :hideFoot="is.loading">

	<template v-slot:head>

		Data synchronisation

	</template>

	<template v-slot:default>

		<ul class="tipjar-perks">

			<li class="tipjar-perks-item">

				<i class="fa fa-calendar-check"></i>

				<h2>Last Sync</h2>

				<p>This profile was last synchronised on the <b>{{ prev }}</b>.</p>

			</li>

			<li class="tipjar-perks-item">

				<i class="fa fa-calendar-day"></i>

				<h2>Next Sync</h2>

				<p>This profile will next be synchronised on the <b>{{ next }}</b>.</p>

			</li>

		</ul>

		<p>Profiles are automatically synchronised <b>every week</b>, and registered users that have used the tipjar are synchronised <b>every day</b>.</p>

		<p>Synchronisation detects any collection, rating, and play changes, updates lists, and generates insights.</p>

		<p>Registered users can also decide whether the next automated sync is just checking for recent changes or does a full re-sync of everything, which you might want to do after entering lots of historical play data.</p>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onClose" class="button" v-if="!window.is.desktop">Close</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				loading: false
			}

		}

	},

	computed: {

		next: function() {

			return this.$moment.unix(this.$store.getters['context/date/sync/next']).utc().format('Do MMMM')

		},

		prev: function() {

			return this.$moment.unix(this.$store.getters['context/date/sync']).utc().format('Do MMMM')

		}

	},

	methods: {

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.tipjar-perks {
	display: flex;
	flex-direction: column;
	list-style: none!important;
	margin-bottom: 0px!important;
}

.tipjar-perks-item {
	width: 100%;
    border-radius: 4px;
    background-color: #fff;
    padding: 8px;
	display: flex;
	flex-direction: column;
	padding-left: 64px;
	margin-bottom: 15px;
}

.tipjar-perks-item.is-disabled {
	opacity: 0.25;
}

.tipjar-perks-item p {
    font-size: 12px!important;
    color: #333!important;
	text-align: left;
	margin-bottom: 0px!important;
	flex-grow: 1;
}

.tipjar-perks-item small {
    font-size: 12px!important;
    color: rgba(255, 255, 255, 0.6);
	text-align: left;
	position: absolute;
	bottom: -32px;
	line-height: 32px;
	left: 0px;
	width: 100%;
}

.tipjar-perks-item:last-child {
	margin-right: 0px;
}

.tipjar-perks-item .fa {
	color: #024359;
	display: block;
	position: absolute;
	left: 16px;
	top: 8px;
	font-size: 32px;
}

.tipjar-perks-item h2 {
	font-size: 16px;
	display: block;
	margin-bottom: 4px;
	text-align: left;
	color: #333!important;
}

</style>