<template>

<core-content-wrapper :empty="is.ready && !totalMaxResults" :noResults="is.ready && totalMaxResults && !totalResults" :loading="!is.ready">

	<core-content-empty v-show="is.ready && !totalMaxResults" title="Oh dear, no ratings!" text="It seems there are no ratings." />
	<core-content-empty v-show="is.ready && totalMaxResults && !totalResults" title="Oh dear, no matches!" text="Your filtering skills are too strong, try broadening the settings." />

	<app-filter :loading="is.fetching" :count="totalPseudoResults" :maximum="totalMaxResults" v-show="totalMaxResults" />

	<app-head :fixed="is.fixedHead" v-if="!isCard" v-show="is.ready && totalMaxResults && totalResults" />

	<core-content-list :fixed="is.fixedHead && !isCard" v-on:fixed="onFixedChange" v-show="is.ready && totalMaxResults && totalResults" :cards="isCard" class="ratings-list">

		<template v-if="isCard"><app-rating-card :ready="is.ready" :item="rating" v-for="rating in content" :key="rating.game.id" /></template>
		<template v-if="!isCard"><app-rating-list :ready="is.ready" :item="rating" v-for="rating in content" :key="rating.game.id" /></template>

		<core-content-scrollload v-on:scrolled="onScroll" v-show="currentPage < totalPages || (is.fetching && is.scrollLoading)" />

	</core-content-list>

	<core-content-select noun="rating" />

</core-content-wrapper>

</template>

<script>

import Content from 'core/mixin/List.js'

import Filter from './ratings/Filter.vue'
import Head from './ratings/Head.vue'
import RatingCard from './ratings/Card.vue'
import RatingList from './ratings/List.vue'

export default {

	mixins: [Content],

	components: {

		'app-filter': Filter,
		'app-head': Head,
		'app-rating-card': RatingCard,
		'app-rating-list': RatingList

	},

	data: function() {

		return {

			contentName: 'ratings',
			routeName: 'Ratings'

		}

	},

	computed: {

		isCard: function() {

			return this.$store.getters['filter/config/ratings/layout'] === this.$CONSTANTS.LAYOUT.RATINGS.CARDS

		}

	}

}

</script>

<style scoped>

</style>