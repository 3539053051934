<template>

<core-filter-advanced-group title="General" :count="count">

	<core-filter-input-daterange v-if="range" :filter="value('played/range')" />

	<core-filter-input-toggles v-if="range" :filter="value('played/type')" text="Type" :values="toggle.values" :icons="toggle.icons" :labels="toggle.labels" v-show="values.played.range.from || values.played.range.to" />

	<core-filter-input-toggle v-if="edition" :text="'Played this ' + period + ' only'" on="Yes" off="No" :filter="value('played/sometime/edition')" />

	<core-filter-input-toggle v-if="edition" :text="'First played this ' + period + ' only'" on="Yes" off="No" :filter="value('played/first/edition')" />

	<core-filter-input-range text="Min. plays" min="0" max="100" maxText="Any" :filter="value('threshold/plays')" :tooltip="true" step="1" pips="10" decimals="0" density="10" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	props: ['edition', 'range', 'frequency'],

	data: function() {

		return {

			fields: ['played.type', 'played.range', 'threshold.plays', 'played.sometime.edition', 'played.first.edition'],

			toggle: {
				icons: ['', '', '', ''],
				values: ['sometime', 'first', 'last', 'never'],
				labels: ['Played', 'First play', 'Last play', 'Unplayed']
			}

		}

	},

	computed: {

		period: function() {

			if (this.frequency === this.$CONSTANTS.LISTS.FREQUENCY.MONTHLY) return 'month'
			if (this.frequency === this.$CONSTANTS.LISTS.FREQUENCY.YEARLY) return 'year'
			return ''

		}

	}

}

</script>