<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ typeName | capitalise }}</h1>

		<p>The {{ typeName }} with the most plays. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-thing v-for="(item, index) in types" :item="item" gamesCountProperty="gamesCount" v-bind:key="index" v-on:more="onTypeClick" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'types',
			params: [
				'filter'
			],
			types: []
		}

	},

	computed: {

		typeName: function() {

			return (this.data.filter) ? ((this.data.filter.type === 'category') ? 'categories' : 'mechanics') : ''

		}

	},

	methods: {

		response: function(json) {

			this.types = json.types

		},

		onTypeClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysType',
				id: id,
				filter: this.$store.getters['filter/insights/plays'],
				child: true
			})

		}

	}

}

</script>