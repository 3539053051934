<template>
	
<core-card size="4" :item="item" :hasActions="true">

	<core-card-head type="game" :id="item.game.id" size="large">

		<app-avatar-include :id="item.game.id" filter="plays" :card="true" />

	</core-card-head>

	<core-card-body :grow="true">

		<com-game-name :item="item" />

	</core-card-body>

	<core-card-body :padded="true" :grow="true" v-if="awards.plays || awards.wins">

		<core-award award="Most played with" type="user" :id="awards.plays.id" :winner="awards.plays.name" :ties="awards.plays.ties" :value="awards.plays.value" v-if="awards.plays && isUser" />

		<core-award award="Most plays" type="user" :id="awards.plays.id" :winner="awards.plays.name" :ties="awards.plays.ties" :value="awards.plays.value" v-if="awards.plays && !isUser" />

		<core-award award="Highest win %" :caveat="'Min. ' + awards.wins.min + ' result' + ((awards.wins.min !== 1) ? 's' : '')" type="user" :id="awards.wins.id" :winner="awards.wins.name" :ties="awards.wins.ties" :value="awards.wins.value" :percent="true" v-if="awards.wins" />

	</core-card-body>

	<core-card-buttons>

		<core-card-buttons-button v-on:click.native="onStatsClick" v-if="awards.plays || awards.wins">View more stats</core-card-buttons-button>
		<core-card-buttons-button v-on:click.native="onPlaysClick">View plays</core-card-buttons-button>

	</core-card-buttons>

	<core-card-foot>

		<core-card-foot-stat :value="item.plays.user.all" text="play" :plural="true" v-if="isUser && !item.context" />
		<core-card-foot-stat :value="item.plays.user.context" :subvalue="item.plays.user.all" text="play" :plural="true" v-if="isUser && item.context" />

		<core-card-foot-stat :value="item.plays.group.all" text="play" :plural="true" v-if="!isUser && !item.context" />
		<core-card-foot-stat :value="item.plays.group.context" :subvalue="item.plays.group.all" text="play" :plural="true" v-if="!isUser && item.context" />

		<com-game-counts :item="item" />

		<core-card-foot-progress :value="winPercent" :color="item.game.color" :invalid="winPercent === false" text="win %" v-if="isUser" />

		<core-card-foot-stat :value="contextual.users.played | hashCount" :text="membership(contextual.users.played)" v-if="!isUser" />

		<core-card-foot-date :value="contextual.user.play_last" text="last play" v-if="isUser" />
		<core-card-foot-date :value="contextual.users.play_last" text="last play" v-if="!isUser" />

		<core-card-foot-stat :value="hours(item.plays.user.duration.all)" :invalid="!item.plays.user.duration.all" text="hour" :plural="true" v-if="isUser && !item.context" />
		<core-card-foot-stat :value="hours(item.plays.user.duration.context)" :invalid="!item.plays.user.duration.all" :subvalue="item.plays.user.duration.all" text="hour" :plural="true" v-if="isUser && item.context" />

		<core-card-foot-stat :value="hours(item.plays.group.duration.all)" :invalid="!item.plays.group.duration.all" text="hour" :plural="true" v-if="!isUser && !item.context" />
		<core-card-foot-stat :value="hours(item.plays.group.duration.context)" :invalid="!item.plays.group.duration.all" :subvalue="item.plays.group.duration.all" text="hour" :plural="true" v-if="!isUser && item.context" />

	</core-card-foot>

</core-card>

</template>

<script>

import gameName from './game/Name'
import gameCounts from './game/Counts'

export default {

	components: {

		'com-game-name': gameName,
		'com-game-counts': gameCounts

	},

	props: ['item'],

	data: function() {

		return {

			contextual: false,
			winPercent: 0,
			awards: {
				plays: false,
				highestWin: false
			}

		}

	},

	watch: {

		item: {

			deep: true,

			handler: function() {

				this.onChange()

			}

		}

	},

	created: function() {

		this.onChange()

	},

	methods: {

		hours: function(minutes) {

			return Math.ceil(minutes / 60)

		},

		onChange: function() {

			this.contextual = (this.item.context) ? this.item.context : this.item

			if (this.isUser) {

				if (this.item.record.results) {

					this.winPercent = (100 / this.item.record.results) * this.item.record.wins

				} else {

					this.winPercent = false

				}

			}

			this.awards.plays = false
			this.awards.wins = false

			var plays
			var players = this.$_.sortBy(this.item.players, 'name')

			if (this.isUser) {

				if (this.item.players.length > 1) {

					plays = this.$_.last(this.$_.sortBy(this.$_.filter(players, function(player) { return player.id !== this.$store.getters['context/id'] }.bind(this)), 'plays'))

					this.awards.plays = {
						id: plays.id,
						name: (plays.name) ? plays.name : 'Anonymous',
						value: plays.plays,
						ties: this.$_.filter(players, function(player) {

							return player.plays === plays.plays && player.id !== plays.id && player.id !== this.$store.getters['context/id']

						}.bind(this)).length
					}

				}
					
			} else {

				if (this.contextual.users.totalPlays) {

					var played = []

					this.$_.each(this.contextual.users.played, function(plays, id) {

						played.push({
							id: id,
							plays: plays,
							name: this.$store.getters['data/users/associated'][id].fullname || this.$store.getters['data/users/associated'][id].name
						})

					}.bind(this))

					plays = this.$_.last(this.$_.sortBy(played, 'plays'))

					this.awards.plays = {
						id: plays.id,
						name: plays.name,
						value: plays.plays,
						ties: this.$_.filter(played, function(player) {

							return player.plays === plays.plays && player.id !== plays.id

						}).length
					}

				}

			}

			if (this.item.record.results > 0) {

				var threshold = 5

				if (this.item.record.results <= 2) threshold = 1
				else if (this.item.record.results <= 5) threshold = 2
				else if (this.item.record.results <= 10) threshold = 3
				else if (this.item.record.results <= 15) threshold = 4

				var highestResults = this.$_.reduce(players, function(high, player) {

					if (player.wins) {

						return (high < player.results) ? player.results : high

					} else {

						return high

					}

				}, 0)

				threshold = (highestResults < threshold) ? highestResults : threshold

				if (threshold > 0) {

					var wins = this.$_.last(this.$_.sortBy(this.$_.filter(players, function(player) { return player.results >= threshold && player.wins > 0 }), function(player) {

						return (100 / player.results) * player.wins

					}))

					if (wins) {

						var highestWinPercent = (100 / wins.results) * wins.wins

						var name = (wins.name) ? wins.name : 'Anonymous'

						if (!this.isUser) {

							name = this.$store.getters['data/users/associated'][wins.id].fullname || this.$store.getters['data/users/associated'][wins.id].name

						}

						this.awards.wins = {
							min: threshold,
							id: wins.id,
							name: name,
							value: highestWinPercent,
							ties: this.$_.filter(players, function(player) {

								return (100 / player.results) * player.wins === highestWinPercent && player.id !== wins.id

							}).length
						}
					
					}

				}

			} 

		},

		onStatsClick: function() {

			this.$pubsub.$emit('expand.open', {
				id: this.item.id,
				section: (this.isUser) ? 'opponents' : 'players'
			})

		},

		onPlaysClick: function() {

			var query = {}

			query['games.subset'] = this.item.game.id.toString()

			var filter = this.$store.getters['filter/plays']

			if (filter.played.period !== 'custom') query['played.period'] = filter.played.period
			if (filter.played.range.from) query['played.range.from'] = filter.played.range.from
			if (filter.played.range.to) query['played.range.to'] = filter.played.range.to
			if (filter.play.count.from !== 1) query['play.count.from'] = filter.play.count.from
			if (filter.play.count.to !== 10) query['play.count.to'] = filter.play.count.to
			if (filter.play.duration.from !== 0) query['play.duration.from'] = filter.play.duration.from
			if (filter.play.duration.to !== 360) query['play.duration.to'] = filter.play.duration.to
			if (filter.play.nolocation) query['play.nolocation'] = '1'
			if (filter.play.locations.length) query['play.locations'] = filter.play.locations.join(',')
			if (filter.play.players.required.length) query['play.players.required'] = filter.play.players.required.join(',')
			if (filter.play.players.together) query['play.players.together'] = '1'

			this.$router.push({
				name: 'Plays',
				query: query
			})

		},

		membership: function(value) {

			value = this.$_.keys(value).length

			if (this.isGroup) {

				return (value === 1) ? 'user' : 'users'

			} else {

				return (value === 1) ? 'user' : 'users'

			}

		}

	}

}

</script>

<style scoped>

</style>
