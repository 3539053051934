<template>

<com-item name="plays.types" :is-list="true" :has-more="true" v-on:more="onTypesClick" :size="size" :is-empty="!active" :loading="loading" title="Categories & Mechanics">

	<template v-slot:about>

		<p>What are the most common types of plays.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<app-thing v-for="(item, index) in types" :item="item" v-bind:key="index" v-on:more="onMoreClick(item.id)" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="typeOptions" :value="typeValue" v-on:change="onTypeChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'type'

		},

		types: function() {

			return this.$store.getters['page/insights/plays/types']

		},

		active: function() {

			return this.types.length && !this.loading

		},

		typeOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.TYPE.CATEGORY, text: 'Categories'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.TYPE.MECHANIC, text: 'Mechanics'})

			return options

		},

		typeValue: function() {

			return this.$store.getters['filter/insights/plays/type']

		}

	},

	methods: {

		onTypesClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysTypes',
				filter: this.$store.getters['filter/insights/plays']
			})

		},

		onTypeChange: function(value) {

			this.$store.commit('filter/insights/plays/type', value)

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysType',
				id: id,
				filter: this.$store.getters['filter/insights/plays']
			})

		}

	}

}

</script>

<style scoped>

.listing {
	width: 100%;
	padding: 0px!important;
}

</style>