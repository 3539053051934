<template>

<core-panel>

	<template v-slot:head>

		<h1>Trending</h1>

		<p>The biggest changes in rating over time.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in trending" :item="item.game" :subtext="item.rating.date | formatDate($util.date.format('Do', 'MMMM', 'YYYY'))" v-bind:key="index" :dark="true">

			<div class="trending-stack">

				<app-disparity :value="disparity(item)" class="trending-item-disparity" />

				<app-trend :history="item.revisions.history" class="trending-item-history" :dark="true" />

			</div>

			<core-rating :value="item.rating.rating" class="trending-item-rating" />

		</app-game>

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'trending',
			params: [
				'filter'
			],
			trending: []
		}

	},

	methods: {

		response: function(json) {

			this.trending = json.trending

		},

		disparity: function(item) {

			return item.rating.rating - item.revisions.history[0].value

		}

	}

}

</script>

<style scoped>

.trending-item-history {
	height: 30px!important;
	justify-content: flex-end!important;
	align-items: flex-end!important;
	width: 64px!important;
	flex-shrink: 0;
}

.trending-item-disparity {
	text-align: right;
	line-height: 18px;
	height:18px!important;
	font-size: 14px!important;
	width: 64px!important;
	flex-shrink: 0;
}

.trending-item-rating {
	margin-left: 15px;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 21px!important;
	flex-shrink: 0;
}

.trending-stack {
	display: flex;
	flex-direction: column;
}

</style>