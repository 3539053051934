<template>

<core-modal v-on:close="onClose" type="text" :loading="is.loading" :hideFoot="true">

	<template v-slot:head>

		Privacy policy

	</template>

	<template v-slot:default>

		<div v-html="content"></div>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			content: '',

			is: {
				loading: true
			}

		}

	},

	methods: {

		onClose: function() {

			this.$emit('close')

		}

	},

	created: function() {

		this.$store.dispatch('api/app/privacy').then(function(json) {

			this.content = json.body

			this.is.loading = false

		}.bind(this), function() {

			this.$emit('close')

		}.bind(this))

	}

}

</script>

<style scoped>

</style>