<template>

<div class="summary" v-if="window.is.mobile">

	<app-game-item-field-count :item="item" :format="{filter: 'collection'}" :summary="true" />
	<app-game-item-field-duration :item="item" :format="{filter: 'collection'}" :summary="true" />
	<app-game-item-field-weight :item="item" :format="{filter: 'collection'}" :summary="true" />
	<app-game-item-field-plays :item="item" :format="{filter: 'collection'}" :summary="true" />

</div>

</template>

<script>

export default {

	name: 'Summary',

	props: ['item']

}

</script>

<style scoped>

.summary {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-bottom: 8px;
}

</style>