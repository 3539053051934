<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ designer }}</h1>

		<p>There are <b>{{ games.length }}</b> games in the collection by this designer. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" v-bind:key="index" :isUserRating="isUser" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'designer',
			games: [],
			designer: ''
		}

	},

	methods: {

		response: function(json) {

			this.designer = json.designer
			this.games = json.games

		}

	}

}

</script>