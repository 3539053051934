import users from './data/users'
import groups from './data/groups'
import designers from './data/designers'
import publishers from './data/publishers'
import artists from './data/artists'
import mechanics from './data/mechanics'
import subdomains from './data/subdomains'
import families from './data/families'
import locations from './data/locations'
import categories from './data/categories'
import players from './data/players'
import games from './data/games'
import tags from './data/tags'
import languages from './data/languages'

export default {

	namespaced: true,

	modules: {
		users: users,
		groups: groups,
		designers: designers,
		publishers: publishers,
		artists: artists,
		mechanics: mechanics,
		subdomains: subdomains,
		families: families,
		categories: categories,
		locations: locations,
		players: players,
		games: games,
		tags: tags,
		languages: languages
	},

	state: {},

	getters: {

		users: function(state, getters) {

			return getters['users/associated']

		},

		games: function(state, getters) {

			return getters['games/associated']

		},

		groups: function(state, getters) {

			return getters['groups/associated']

		},

		artists: function(state, getters) {

			return getters['artists/associated']

		},

		tags: function(state, getters) {

			return getters['tags/associated']

		},

		publishers: function(state, getters) {

			return getters['publishers/associated']

		},

		families: function(state, getters) {

			return getters['families/associated']

		},

		designers: function(state, getters) {

			return getters['designers/associated']

		},

		languages: function(state, getters) {

			return getters['languages/associated']

		},

		mechanics: function(state, getters) {

			return getters['mechanics/associated']

		},

		subdomains: function(state, getters) {

			return getters['subdomains/associated']

		},

		categories: function(state, getters) {

			return getters['categories/associated']

		},

		players: function(state, getters) {

			return getters['players/associated']

		},

		locations: function(state, getters) {

			return getters['locations/associated']

		}

	},

	mutations: {},

	actions: {}

}