import store from 'app/store'
import pubsub from 'core/pubsub'

export default {

	add: function(context, e) {

		e = e || {}

		e.override = e.override || {}

		return new Promise(function(resolve) {

			store.dispatch('api/custom/nesting/add', {
				id: e.override.item,
				parent: e.parent
			}).then(function() {

				pubsub.$emit('dynamic.refresh.collection', {
					reload: true
				})

				resolve()

			})

		})

	},

	remove: function(context, e) {

		e = e || {}

		e.override = e.override || {}

		return new Promise(function(resolve) {

			store.dispatch('api/custom/nesting/remove', {
				id: e.override.item
			}).then(function() {

				pubsub.$emit('dynamic.refresh.collection', {
					reload: true
				})

				resolve()

			})

		})

	},

	restore: function(context, e) {

		e = e || {}

		e.override = e.override || {}

		return new Promise(function(resolve) {

			store.dispatch('api/custom/nesting/restore', {
				id: e.override.item
			}).then(function() {

				pubsub.$emit('dynamic.refresh.collection', {
					reload: true
				})

				resolve()

			})

		})

	},

	clear: function(context, e) {

		e = e || {}

		e.override = e.override || {}

		return new Promise(function(resolve) {

			store.dispatch('api/custom/nesting/clear', {
				id: e.override.item
			}).then(function() {

				pubsub.$emit('dynamic.refresh.collection', {
					reload: true
				})

				resolve()

			})

		})

	}

}