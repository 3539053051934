<template>
	
<div class="about" v-if="selected" v-bind:class="{'is-loading': is.loading}">

	<div class="about-image" v-if="!window.is.mobile"><img v-lazy="avatar" /></div>

	<div class="about-text">

		<core-simplebar class="about-text-scroll">

			<div class="about-text-wrapper">

				<div class="about-text-description">

					<div class="about-text-description-edition" v-if="version">

						<b class="about-text-description-head">Game</b>
						<p>{{ about.name }}</p>

						<b class="about-text-description-head">Version</b>
						<p>{{ about.version.name }} ({{ about.version.year }})</p>

					</div>

					<b class="about-text-description-head">Description</b>
					<p v-html="about.description"></p>

				</div>

				<div class="about-credits">

					<div class="about-credits-group" v-if="artists.length">

						<b><plural t="Artist" :n="artists.length" /></b>

						<p v-for="value in artists" v-bind:key="value.id"><a :href="'https://boardgamegeek.com/boardgameartist/' + value.id" target="_blank">{{ value.name }}</a></p>

					</div>

					<div class="about-credits-group" v-if="publishers.length">

						<b><plural t="Publisher" :n="publishers.length" /></b>

						<p v-for="value in publishers" v-bind:key="value.id"><a :href="'https://boardgamegeek.com/boardgamepublisher/' + value.id" target="_blank">{{ value.name }}</a></p>

					</div>

					<div class="about-credits-group" v-if="categories.length">

						<b><plural t="Category" :n="categories.length" /></b>

						<p v-for="value in categories" v-bind:key="value.id">{{ value.name }}</p>

					</div>

					<div class="about-credits-group" v-if="mechanics.length">

						<b><plural t="Mechanic" :n="mechanics.length" /></b>

						<p v-for="value in mechanics" v-bind:key="value.id">{{ value.name }}</p>

					</div>

					<div class="about-credits-group" v-if="families.length">

						<b><plural t="Family" :n="families.length" /></b>

						<p v-for="value in families" v-bind:key="value.id">{{ value.name }}</p>

					</div>

					<div class="about-credits-group" v-if="subdomains.length">

						<b><plural t="Subdomain" :n="subdomains.length" /></b>

						<p v-for="value in subdomains" v-bind:key="value.id">{{ value.name }}</p>

					</div>

				</div>

			</div>

		</core-simplebar>

		<div class="about-stats">

			<div class="about-stats-details">

				<div class="about-stats-details-item">

					<b><plural t="Designer" :n="designers.length" /></b>
					
					<p>
						<span v-for="(value, index) in designers" v-bind:key="value.id">
							<a :href="'https://boardgamegeek.com/boardgamedesigner/' + value.id" target="_blank">{{ value.name }}</a><span v-if="index < designers.length - 1">, </span>
						</span>
						<template v-if="designers.length === 0">Unknown</template>
					</p>

				</div>	

				<div class="about-stats-details-item">

					<b>First published</b>
					
					<p>{{ about.published }}</p>

				</div>	

				<div class="about-stats-details-item" v-if="about.rank">

					<b>BGG Rank</b>
					
					<p><a v-if="about.rank > 0" :href="'https://boardgamegeek.com/browse/boardgame?sort=rank&rankobjecttype=subtype&rankobjectid=1&rank=' + about.rank + '#' + about.rank" target="_blank">{{ about.rank }}</a><template v-if="about.rank === 0">-</template></p>

				</div>	

			</div>

			<core-button theme="blue" :href="url" class="about-stats-button">Open BGG profile page</core-button>

		</div>

	</div>

</div>

</template>

<script>

export default {

	name: 'about',

	props: ['id', 'version', 'selected'],

	data: function() {

		return {
			about: {
				description: '',
				name: '',
				version: {
					name: '',
					year: ''
				}
			},
			is: {
				loading: false
			}
		}

	},

	watch: {

		id: function() {

			this.populate()

		}

	},

	created: function() {

		this.populate()

	},

	computed: {

		designers: function() {

			return this.getNames('designers', 'designer')

		},

		artists: function() {

			return this.getNames('artists', 'artist')

		},

		publishers: function() {

			return this.getNames('publishers', 'publisher')

		},

		categories: function() {

			return this.getNames('categories', 'category')

		},

		mechanics: function() {

			return this.getNames('mechanics', 'mechanic')

		},

		subdomains: function() {

			return this.getNames('subdomains', 'subdomain')

		},

		families: function() {

			return this.getNames('families', 'family')

		},

		url: function() {

			return 'https://boardgamegeek.com/boardgame/' + this.id

		},

		avatar: function() {

			return this.$util.thumbnail.game.box(this.id)

		}

	},

	methods: {

		populate: function() {

			if (this.$store.getters['data/games'][this.id]) {

				if (this.$store.getters['data/games'][this.id].description) {

					this.about = this.$store.getters['data/games'][this.id]

				} else {

					this.fetch()

				}

			} else {

				this.fetch()

			}

		},

		getNames: function(data, field) {

			var names = []

			this.$_.each(this.about[data], function(id) {

				var item = this.$store.getters['data/' + data][id]

				names.push({
					id: item[field + '_id'],
					name: item[field + '_name']
				})

			}.bind(this))

			names = this.$_.sortBy(names, 'name')

			return names

		},

		fetch: function() {

			this.is.loading = true

			this.$store.dispatch('api/games/about', {
				id: this.id,
				version: (this.version) ? this.version.id : false,
				silent: true
			}).then(function() {

				this.about = this.$store.getters['data/games'][this.id]
				this.is.loading = false

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.about {
	padding: 8px 0px;	
	display: flex;
	flex-direction: row;
	overflow: hidden;
	flex-grow: 1;
}

@media only screen and (max-width: 767px) {

	.about {
		flex-direction: column;
		padding: 8px;
	}

}

.about.is-loading {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(~core/assets/load.gif);
}

.about.is-loading > * {
	opacity: 0;
}

.about-image {
	flex-shrink: 0;
	width: 207px;
	padding-right: 15px;
}

.about-image img {
	width: 192px;
	height: 240px;
}

.about-text {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.about-text-scroll {
	width: 100%;
	height: 193px;
	margin-bottom: 7px;
	padding-right: 5px;
}

.is-mobile .about-text-scroll {
	flex-grow: 1;
	flex-basis: 0;
	height: auto;
	padding-right: 0px;
	min-height: 0;
	max-height: 100%;
}

.about-text-wrapper {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.about-text-wrapper {
		flex-direction: column;
	}

}

.about-text-description {
	flex-grow: 1;
	font-size: 14px;
	line-height: 16px;
	font-weight: 300;
	color: #333;
	padding-right: 15px;
}

.is-mobile .about-text-description {
	padding-right: 0px;
}

.about-text-description-edition p {
	margin-bottom: 8px;
}

.about-credits {
	width: 230px;
	flex-shrink: 0;
}

.about-credits-group {
	padding-bottom: 15px;
}

.about-credits a {
	color: #4a7888;
}

.is-desktop .about-credits a:hover {
	text-decoration: underline;
}

.about-credits-group:last-child {
	margin-bottom: 0px;
}

.about-text-description-head,
.about-credits-group b {
	font-weight: 500;
	font-size: 11px;
	line-height: 11px;
	margin-bottom: 5px;
	color: #333;
}

.about-credits-group p {
	font-weight: 300;
	font-size: 13px;
	line-height: 16px;
	color: #333;
}

.about-stats {
	height: 47px;
	padding-top: 7px;
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	border-top: 1px solid #eee;
}

@media only screen and (max-width: 767px) {

	.about-stats {
		flex-direction: column;
		height: auto;
	}

}

.about-stats-details {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.about-stats-details {
		flex-direction: column;
	}

}

.about-stats-details-item {
	width: 25%;
	padding-top: 5px;
}

.about-stats-details-item:first-child {
	width: 50%;
}

@media only screen and (max-width: 767px) {

	.about-stats-details-item:first-child,
	.about-stats-details-item {
		width: 100%;
	}

}

.about-stats-details-item b {
	font-weight: 500;
	font-size: 11px;
	line-height: 11px;
	margin-bottom: 5px;
	color: #333;
}

.about-stats-details-item p {
	font-weight: 300;
	font-size: 13px;
	line-height: 16px;
	color: #333;
}

.about-stats-details-item a {
	color: #4a7888;
}

.is-desktop .about-stats-details-item a:hover {
	text-decoration: underline;
}

.about-stats-button {
	width: 240px;
}

@media only screen and (max-width: 767px) {

	.about-stats-button {
		width: 100%;
		margin-top: 10px;
	}

}

</style>
