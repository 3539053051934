<template>

<core-dropdown-level v-if="can.tag" parent="root" name="tag.add" :level="level" v-on:level="$emit('level', 'root')">

	<core-dropdown-item type="title" v-if="!gameTags.length && !contextTags.length">Tags</core-dropdown-item>

	<core-dropdown-item type="title" v-if="gameTags.length">Game tags</core-dropdown-item>

	<core-dropdown-item v-for="tag in gameTags" :key="tag.id" type="tag" :color="tag.colour" :loading="is.loading === tag.id" :selected="isSelected(tag.id)" v-on:toggle="onTagToggle(tag)">{{ tag.text }}</core-dropdown-item>

	<core-dropdown-item type="title" v-if="contextTags.length">{{ contextTitle }}</core-dropdown-item>

	<core-dropdown-item v-for="tag in contextTags" :key="tag.id" type="tag" :color="tag.colour" :loading="is.loading === tag.id" :selected="isSelected(tag.id)" v-on:toggle="onTagToggle(tag)">{{ tag.text }}</core-dropdown-item>

	<core-dropdown-item type="divide" v-if="gameTags.length || contextTags.length" />

	<core-dropdown-item type="link" v-on:click.native="onCreateClick">Create new tag</core-dropdown-item>
	<core-dropdown-item type="link" v-on:click.native="onTagsClick">Manage tags</core-dropdown-item>

</core-dropdown-level>

</template>

<script>

export default {

	props: ['filter', 'item', 'level'],

	data: function() {

		return {

			is: {
				loading: false
			}

		}

	},

	computed: {

		contextTitle: function() {

			return this.$CONSTANTS.SERVICE.TITLE[this.$store.getters['service/select/name']] + ' tags'

		},

		gameTags: function() {

			return this.$_.where(this.$store.getters['data/tags/list'], {
				context: 0
			})

		},

		contextTags: function() {

			return this.$_.where(this.$store.getters['data/tags/list'], {
				context: this.$CONSTANTS.SERVICE.VALUE[this.$store.getters['service/select/name']]
			})

		},

		can: function() {

			return this.$store.getters['service/select/can']

		}

	},

	methods: {

		isSelected: function(id) {

			return this.$_.contains(this.item.tags, id)

		},

		onCreateClick: function() {

			this.$store.dispatch('service/select/tags/create', {
				override: {
					game: [this.item.game.id],
					item: [this.item.id]
				}
			})

		},

		onTagsClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('filter.panel.tags.open')

			this.$emit('close')

		},

		onTagToggle: function(tag) {

			this.is.loading = tag.id

			this.$store.dispatch('service/select/tags/toggle', {
				tag: tag,
				override: {
					item: this.item.id,
					game: this.item.game.id
				}
			}).then(function() {

				this.is.loading = false

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>