<template>

<div class="bar-right">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.bar-right {
	display: flex;
	justify-content: flex-end;
	flex-shrink: 0;
	height: 40px;
	padding: 24px 20px;
}

.is-smaller .bar-right {
	padding: 12px 20px;
}

.is-tablet .bar-right {
	padding: 12px 10px;
}

.is-mobile .bar-right {
	padding: 8px 10px 8px 0px;
}

</style>
