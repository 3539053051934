import _ from 'underscore'
import router from 'app/router'

export default {

	analyse: function(context, params) {

		return new Promise(function(resolve) {

			context.commit('reset')

			if (params !== undefined) {

				var values

				if (params.text) context.state.data.text = params.text

				if (params['comment.text']) context.state.data.comment.text = params['comment.text']

				if (params['inventory.location.text']) context.state.data.inventory.location.text = params['inventory.location.text']
				if (params['inventory.location.exists']) context.state.data.inventory.location.exists = parseInt(params['inventory.location.exists'])

				if (params.expansions) {

					context.state.data.expansions = parseInt(params.expansions)
						
				}

				if (params.unplayed) {

					context.state.data.unplayed = parseInt(params.unplayed)
						
				}

				if (params.ownersmin) {

					context.state.data.ownersmin = parseInt(params.ownersmin)
						
				}

				if (params.soloplayed) {

					context.state.data.soloplayed = parseInt(params.soloplayed)
						
				}

				if (params.unrated) {

					context.state.data.unrated = parseInt(params.unrated)
						
				}

				if (params.commented) {

					context.state.data.commented = params.commented
						
				}

				if (params.limit) {

					context.state.data.limit = parseInt(params.limit)
						
				}

				if (params.random) {

					context.state.data.random = parseInt(params.random)
						
				}

				if (params['rated.date.user.from'] !== undefined) {

					context.state.data.rated.date.user.from = parseInt(params['rated.date.user.from'])
								
				}

				if (params['rated.date.user.to'] !== undefined) {

					context.state.data.rated.date.user.to = parseInt(params['rated.date.user.to'])
								
				}

				if (params['rated.date.group.from'] !== undefined) {

					context.state.data.rated.date.group.from = parseInt(params['rated.date.group.from'])
								
				}

				if (params['rated.date.group.to'] !== undefined) {

					context.state.data.rated.date.group.to = parseInt(params['rated.date.group.to'])
								
				}

				if (params['played.type'] !== undefined) {

					context.state.data.played.type = params['played.type']
						
				}

				if (params['played.period'] !== undefined) {

					context.state.data.played.period = params['played.period']
						
				}

				if (params['collection.exclude.own'] !== undefined) {

					context.state.data.collection.exclude.own = parseInt(params['collection.exclude.own'])
								
				}

				if (params['collection.exclude.prevowned'] !== undefined) {

					context.state.data.collection.exclude.prevowned = parseInt(params['collection.exclude.prevowned'])
								
				}

				if (params['worth.value.from'] !== undefined) {

					context.state.data.worth.value.from = parseInt(params['worth.value.from'])
								
				}

				if (params['worth.value.to'] !== undefined) {

					context.state.data.worth.value.to = parseInt(params['worth.value.to'])
								
				}

				if (params['worth.play.from'] !== undefined) {

					context.state.data.worth.play.from = parseInt(params['worth.play.from'])
								
				}

				if (params['worth.play.to'] !== undefined) {

					context.state.data.worth.play.to = parseInt(params['worth.play.to'])
								
				}

				if (params['wishlist.priority.from'] !== undefined) {

					context.state.data.wishlist.priority.from = parseInt(params['wishlist.priority.from'])
								
				}

				if (params['wishlist.priority.to'] !== undefined) {

					context.state.data.wishlist.priority.to = parseInt(params['wishlist.priority.to'])
								
				}

				if (params['collection.added.from'] !== undefined) {

					context.state.data.collection.added.from = parseInt(params['collection.added.from'])
								
				}

				if (params['collection.added.to'] !== undefined) {

					context.state.data.collection.added.to = parseInt(params['collection.added.to'])
								
				}

				if (params['played.last.from'] !== undefined) {

					context.state.data.played.last.from = parseInt(params['played.last.from'])
								
				}

				if (params['played.last.to'] !== undefined) {

					context.state.data.played.last.to = parseInt(params['played.last.to'])
								
				}

				if (params['played.first.from'] !== undefined) {

					context.state.data.played.first.from = parseInt(params['played.first.from'])
								
				}

				if (params['played.first.to'] !== undefined) {

					context.state.data.played.first.to = parseInt(params['played.first.to'])
								
				}

				if (params['played.range.from'] !== undefined) {

					context.state.data.played.range.from = parseInt(params['played.range.from'])
								
				}

				if (params['played.range.to'] !== undefined) {

					context.state.data.played.range.to = parseInt(params['played.range.to'])
								
				}

				_.each(['own', 'prevowned', 'fortrade', 'want', 'wanttoplay', 'wanttobuy', 'wishlist', 'preordered', 'user', 'game', 'hasparts', 'wantparts', 'merge'], function(key) {

					if (params['status.' + key] !== undefined) {

						context.state.data.status[key] = parseInt(params['status.' + key])
							
					}

				})

				_.each(['users', 'owners', 'designers', 'languages', 'publishers', 'artists', 'families', 'mechanics', 'subdomains', 'categories'], function(key) {

					if (params[key]) {

						context.state.data[key] = []

						_.each(params[key].split(','), function(value) {

							context.state.data[key].push(value)

						})
							
					}

				})

				_.each(['tags'], function(key) {

					if (params[key]) {

						context.state.data[key] = []

						_.each(params[key].split(','), function(value) {

							context.state.data[key].push(value.toString())

						})
							
					}

				})

				_.each(['type', 'status'], function(key) {

					if (params['trade.' + key]) {

						values = params['trade.' + key]

						context.state.data.trade[key] = values

					}

				})

				if (params['count.exact'] !== undefined) {

					context.state.data.count.exact = parseInt(params['count.exact'])
								
				}

				_.each(['official', 'recommended', 'best'], function(key) {

					if (params['count.' + key]) {

						values = params['count.' + key].split(',')

						context.state.data.count[key] = {
							from: parseInt(values[0]),
							to: parseInt(values[1])
						}

					}

				})
				
				if (params['disparity.user.group']) {

					values = params['disparity.user.group'].split(',')

					context.state.data.disparity.user.group = {
						from: parseFloat(values[0]),
						to: parseFloat(values[1])
					}

				}
				
				if (params['disparity.user.community']) {

					values = params['disparity.user.community'].split(',')

					context.state.data.disparity.user.community = {
						from: parseFloat(values[0]),
						to: parseFloat(values[1])
					}

				}
				
				if (params['disparity.group.community']) {

					values = params['disparity.group.community'].split(',')

					context.state.data.disparity.group.community = {
						from: parseFloat(values[0]),
						to: parseFloat(values[1])
					}

				}

				_.each(['user', 'group', 'community'], function(key) {

					if (params['rating.' + key]) {

						values = params['rating.' + key].split(',')

						context.state.data.rating[key] = {
							from: parseFloat(values[0]),
							to: parseFloat(values[1])
						}

					}

				})

				if (params['games.include']) {

					_.each(params['games.include'].split(','), function(value) {

						context.state.data.games.include.push(parseInt(value))

					})

				}

				if (params['games.exclude']) {

					_.each(params['games.exclude'].split(','), function(value) {

						context.state.data.games.exclude.push(parseInt(value))

					})

				}

				if (params['games.subset']) {

					_.each(params['games.subset'].split(','), function(value) {

						context.state.data.games.subset.push(parseInt(value))

					})

				}

				if (params.weight) {

					values = params.weight.split(',')

					context.state.data.weight = {
						from: parseFloat(values[0]),
						to: parseFloat(values[1])
					}

				}

				if (params.year) {

					values = params.year.split(',')

					context.state.data.year = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params.plays) {

					values = params.plays.split(',')

					context.state.data.plays = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params.comments) {

					values = params.comments.split(',')

					context.state.data.comments = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params.rank) {

					values = params.rank.split(',')

					context.state.data.rank = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params['rated.divisive']) {

					values = params['rated.divisive'].split(',')

					context.state.data.rated.divisive = {
						from: parseFloat(values[0]),
						to: parseFloat(values[1])
					}

				}

				if (params.duration) {

					values = params.duration.split(',')

					context.state.data.duration = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				_.each(['language', 'notrecommended', 'age', 'group', 'community', 'plays', 'owners', 'weight', 'count', 'hours'], function(key) {

					if (params['threshold.' + key]) {

						context.state.data.threshold[key] = parseInt(params['threshold.' + key])

					}

				})

				if (params.sort) {

					context.state.data.sort = params.sort

				}

				if (params.sortReverse) {

					context.state.data.sortReverse = parseInt(params.sortReverse) === 1

				}

				if (params['play.subset']) {

					_.each(params['play.subset'].split(','), function(value) {

						context.state.data.play.subset.push(parseInt(value))

					})

				}

				if (params['play.status']) {

					context.state.data.play.status = parseInt(params['play.status'])

				}

				if (params['play.group']) {

					context.state.data.play.group = parseInt(params['play.group'])

				}

				if (params['play.first']) {

					context.state.data.play.first = parseInt(params['play.first'])

				}

				if (params['play.last']) {

					context.state.data.play.last = parseInt(params['play.last'])

				}

				if (params['play.nolocation']) {

					context.state.data.play.nolocation = parseInt(params['play.nolocation'])

				}

				if (params['durations.absolute']) {

					context.state.data.durations.absolute = parseInt(params['durations.absolute'])

				}

				if (params['play.locations']) {

					_.each(params['play.locations'].split(','), function(value) {

						context.state.data.play.locations.push(parseInt(value))

					})

				}

				if (params['play.count']) {

					values = params['play.count'].split(',')

					context.state.data.play.count = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params['play.users']) {

					values = params['play.users'].split(',')

					context.state.data.play.users = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params['play.winpercent']) {

					values = params['play.winpercent'].split(',')

					context.state.data.play.winpercent = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params['play.duration']) {

					values = params['play.duration'].split(',')

					context.state.data.play.duration = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params['language.dependency']) {

					values = params['language.dependency'].split(',')

					context.state.data.language.dependency = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params['age.official']) {

					values = params['age.official'].split(',')

					context.state.data.age.official = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params['age.community']) {

					values = params['age.community'].split(',')

					context.state.data.age.community = {
						from: parseInt(values[0]),
						to: parseInt(values[1])
					}

				}

				if (params['play.players.required']) {

					_.each(params['play.players.required'].split(','), function(value) {

						context.state.data.play.players.required.push(parseInt(value))

					})

				}

				if (params['play.players.together']) {

					context.state.data.play.players.together = parseInt(params['play.players.together'])

				}

				if (params['play.reports.required']) {

					context.state.data.play.reports.required = parseInt(params['play.reports.required'])

				}

				if (params['play.reports.text']) {

					context.state.data.play.reports.text = params['play.reports.text']

				}

			}

			resolve()

		})

	},

	route: function(context) {

		var urlParams = {}

		if (context.state.data.text) {

			urlParams.text = context.state.data.text

		}

		if (context.state.data.inventory.location.text) {

			urlParams['inventory.location.text'] = context.state.data.inventory.location.text

		}

		if (context.state.data.inventory.location.exists) {

			urlParams['inventory.location.exists'] = context.state.data.inventory.location.exists

		}

		if (context.state.data.comment.text) {

			urlParams['comment.text'] = context.state.data.comment.text

		}

		if (!context.state.data.expansions) {

			urlParams.expansions = 0
					
		}

		if (context.state.data.expansions === 2) {

			urlParams.expansions = 2
					
		}

		if (context.state.data.unplayed) {

			urlParams.unplayed = 1
					
		}

		if (context.state.data.ownersmin) {

			urlParams.ownersmin = 1
					
		}

		if (context.state.data.soloplayed) {

			urlParams.soloplayed = 1
					
		}

		if (context.state.data.unrated) {

			urlParams.unrated = 1
					
		}

		if (context.state.data.commented) {

			urlParams.commented = context.state.data.commented
					
		}

		if (context.state.data.limit) {

			urlParams.limit = context.state.data.limit
					
		}

		if (context.state.data.random) {

			urlParams.random = 1
					
		}

		if (context.state.data.status.own !== 1) {

			urlParams['status.own'] = context.state.data.status.own

		}

		if (context.state.data.rated.date.user.from) {

			urlParams['rated.date.user.from'] = context.state.data.rated.date.user.from

		}

		if (context.state.data.rated.date.user.to) {

			urlParams['rated.date.user.to'] = context.state.data.rated.date.user.to

		}

		if (context.state.data.rated.date.group.from) {

			urlParams['rated.date.group.from'] = context.state.data.rated.date.group.from

		}

		if (context.state.data.rated.date.to) {

			urlParams['rated.date.group.to'] = context.state.data.rated.date.group.to

		}

		if (context.state.data.played.type !== 'sometime') {

			urlParams['played.type'] = context.state.data.played.type

		}

		if (context.state.data.played.period !== 'custom') {

			urlParams['played.period'] = context.state.data.played.period

		}

		if (context.state.data.played.range.from) {

			urlParams['played.range.from'] = context.state.data.played.range.from

		}

		if (context.state.data.played.range.to) {

			urlParams['played.range.to'] = context.state.data.played.range.to

		}

		if (context.state.data.played.first.from) {

			urlParams['played.first.from'] = context.state.data.played.first.from

		}

		if (context.state.data.played.first.to) {

			urlParams['played.first.to'] = context.state.data.played.first.to

		}

		if (context.state.data.played.last.from) {

			urlParams['played.last.from'] = context.state.data.played.last.from

		}

		if (context.state.data.played.last.to) {

			urlParams['played.last.to'] = context.state.data.played.last.to

		}

		if (context.state.data.collection.exclude.own) {

			urlParams['collection.exclude.own'] = context.state.data.collection.exclude.own

		}

		if (context.state.data.collection.exclude.prevowned) {

			urlParams['collection.exclude.prevowned'] = context.state.data.collection.exclude.prevowned

		}

		if (context.state.data.worth.value.from !== 0) {

			urlParams['worth.value.from'] = context.state.data.worth.value.from

		}

		if (context.state.data.worth.value.to !== 200) {

			urlParams['worth.value.to'] = context.state.data.worth.value.to

		}

		if (context.state.data.worth.play.from !== 0) {

			urlParams['worth.play.from'] = context.state.data.worth.play.from

		}

		if (context.state.data.worth.play.to !== 200) {

			urlParams['worth.play.to'] = context.state.data.worth.play.to

		}

		if (context.state.data.wishlist.priority.from !== 1) {

			urlParams['wishlist.priority.from'] = context.state.data.wishlist.priority.from

		}

		if (context.state.data.wishlist.priority.to !== 5) {

			urlParams['wishlist.priority.to'] = context.state.data.wishlist.priority.to

		}

		if (context.state.data.collection.added.from) {

			urlParams['collection.added.from'] = context.state.data.collection.added.from

		}

		if (context.state.data.collection.added.to) {

			urlParams['collection.added.to'] = context.state.data.collection.added.to

		}

		if (context.state.data.trade.type) {

			urlParams['trade.type'] = context.state.data.trade.type

			if (context.state.data.trade.status !== 'want') {

				urlParams['trade.status'] = context.state.data.trade.status

			}

		}

		_.each(['prevowned', 'fortrade', 'want', 'wanttoplay', 'wanttobuy', 'wishlist', 'preordered', 'user', 'game', 'hasparts', 'wantparts', 'merge'], function(key) {

			if (context.state.data.status[key] !== 0) {

				urlParams['status.' + key] = context.state.data.status[key]
							
			}

		})

		_.each(['owners', 'users', 'languages', 'designers', 'publishers', 'artists', 'families', 'mechanics', 'subdomains', 'tags', 'categories'], function(key) {

			if (context.state.data[key].length) {

				urlParams[key] = context.state.data[key].join(',')

			}

		})

		if (context.state.data.count.exact) {

			urlParams['count.exact'] = context.state.data.count.exact

		}

		_.each(['official', 'recommended', 'best'], function(key) {

			if (context.state.data.count[key].from !== 1 || context.state.data.count[key].to !== 10) {

				urlParams['count.' + key] = context.state.data.count[key].from + ',' + context.state.data.count[key].to

			}

		})

		if (context.state.data.disparity.user.group.from !== -10 || context.state.data.disparity.user.group.to !== 10) {

			urlParams['disparity.user.group'] = context.state.data.disparity.user.group.from + ',' + context.state.data.disparity.user.group.to

		}

		if (context.state.data.disparity.user.community.from !== -10 || context.state.data.disparity.user.community.to !== 10) {

			urlParams['disparity.user.community'] = context.state.data.disparity.user.community.from + ',' + context.state.data.disparity.user.community.to

		}

		if (context.state.data.disparity.group.community.from !== -10 || context.state.data.disparity.group.community.to !== 10) {

			urlParams['disparity.group.community'] = context.state.data.disparity.group.community.from + ',' + context.state.data.disparity.group.community.to

		}

		_.each(['user', 'group', 'community'], function(key) {

			if (context.state.data.rating[key].from !== 0 || context.state.data.rating[key].to !== 10) {

				urlParams['rating.' + key] = context.state.data.rating[key].from + ',' + context.state.data.rating[key].to

			}

		})

		if (context.state.data.year.from !== 1970 || context.state.data.year.to !== 2030) {

			urlParams['year'] = context.state.data.year.from + ',' + context.state.data.year.to

		}

		if (context.state.data.weight.from !== 0 || context.state.data.weight.to !== 5) {

			urlParams['weight'] = context.state.data.weight.from + ',' + context.state.data.weight.to

		}

		if (context.state.data.duration.from !== 0 || context.state.data.duration.to !== 360) {

			urlParams['duration'] = context.state.data.duration.from + ',' + context.state.data.duration.to

		}

		if (context.state.data.durations.absolute !== 0) {

			urlParams['durations.absolute'] = context.state.data.durations.absolute

		}

		if (context.state.data.plays.from !== 0 || context.state.data.plays.to !== 100) {

			urlParams['plays'] = context.state.data.plays.from + ',' + context.state.data.plays.to

		}

		if (context.state.data.comments.from !== 0 || context.state.data.comments.to !== 50) {

			urlParams['comments'] = context.state.data.comments.from + ',' + context.state.data.comments.to

		}

		if (context.state.data.rank.from !== 0 || context.state.data.rank.to !== 1000) {

			urlParams['rank'] = context.state.data.rank.from + ',' + context.state.data.rank.to

		}

		if (context.state.data.rated.divisive.from !== 0 || context.state.data.rated.divisive.to !== 2) {

			urlParams['rated.divisive'] = context.state.data.rated.divisive.from + ',' + context.state.data.rated.divisive.to

		}

		if (context.state.data.games.include.length) {

			urlParams['games.include'] = context.state.data.games.include.join(',')

		}

		if (context.state.data.games.exclude.length) {

			urlParams['games.exclude'] = context.state.data.games.exclude.join(',')

		}

		if (context.state.data.games.subset.length) {

			urlParams['games.subset'] = context.state.data.games.subset.join(',')

		}

		_.each(['language', 'notrecommended', 'age', 'group', 'community', 'plays', 'owners', 'weight', 'count', 'hours'], function(key) {

			if (context.state.data.threshold[key] > 0) {

				urlParams['threshold.' + key] = context.state.data.threshold[key]

			}

		})

		if (context.state.data.sort !== context.state.defaultValues.sort) {

			urlParams['sort'] = context.state.data.sort 

		}

		if (context.state.data.sortReverse && (!context.state.defaultValues.sortReverse || context.state.data.sort !== context.state.defaultValues.sort)) {

			urlParams['sortReverse'] = 1

		}

		if (!context.state.data.sortReverse && context.state.defaultValues.sortReverse && context.state.data.sort === context.state.defaultValues.sort) {

			urlParams['sortReverse'] = 0

		}

		if (context.state.data.play.subset.length) {

			urlParams['play.subset'] = context.state.data.play.subset.join(',')

		}

		if (context.state.data.play.status) {

			urlParams['play.status'] = context.state.data.play.status
					
		}

		if (context.state.data.play.first) {

			urlParams['play.first'] = 1
					
		}

		if (context.state.data.play.last) {

			urlParams['play.last'] = 1
					
		}

		if (context.state.data.play.group) {

			urlParams['play.group'] = 1
					
		}

		if (context.state.data.play.nolocation) {

			urlParams['play.nolocation'] = 1

		}

		if (context.state.data.play.locations.length) {

			urlParams['play.locations'] = context.state.data.play.locations.join(',')

		}

		if (context.state.data.play.count.from !== 1 || context.state.data.play.count.to !== 10) {

			urlParams['play.count'] = context.state.data.play.count.from + ',' + context.state.data.play.count.to

		}

		if (context.state.data.play.duration.from !== 0 || context.state.data.play.duration.to !== 360) {

			urlParams['play.duration'] = context.state.data.play.duration.from + ',' + context.state.data.play.duration.to

		}

		if (context.state.data.play.users.from !== 0 || context.state.data.play.users.to !== 100) {

			urlParams['play.users'] = context.state.data.play.users.from + ',' + context.state.data.play.users.to

		}

		if (context.state.data.play.winpercent.from !== 0 || context.state.data.play.winpercent.to !== 100) {

			urlParams['play.winpercent'] = context.state.data.play.winpercent.from + ',' + context.state.data.play.winpercent.to

		}

		if (context.state.data.play.players.required.length) {

			urlParams['play.players.required'] = context.state.data.play.players.required.join(',')

		}

		if (context.state.data.play.players.together) {

			urlParams['play.players.together'] = 1
					
		}

		if (context.state.data.play.reports.required) {

			urlParams['play.reports.required'] = context.state.data.play.reports.required

		}

		if (context.state.data.play.reports.text) {

			urlParams['play.reports.text'] = context.state.data.play.reports.text
					
		}

		if (context.state.data.language.dependency.from !== 1 || context.state.data.language.dependency.to !== 5) {

			urlParams['language.dependency'] = context.state.data.language.dependency.from + ',' + context.state.data.language.dependency.to

		}

		if (context.state.data.age.official.from !== 2 || context.state.data.age.official.to !== 21) {

			urlParams['age.official'] = context.state.data.age.official.from + ',' + context.state.data.age.official.to

		}

		if (context.state.data.age.community.from !== 2 || context.state.data.age.community.to !== 21) {

			urlParams['age.community'] = context.state.data.age.community.from + ',' + context.state.data.age.community.to

		}

		router.push({name: context.state.route, query: urlParams}).catch(function() {})

	}

}