<template>

<div class="pie">

    <canvas ref="background" width="512" height="512" class="pie-chart is-background" />
	
    <canvas ref="foreground" width="512" height="512" class="pie-chart is-foreground" />

</div>

</template>

<script>

import Chart from 'chart.js'
import ChartLabels from 'chartjs-plugin-labels'

export default {

	props: ['data'],

	created: function() {
    
        this.$nextTick(function() {
        
            this.build()

        }.bind(this))
    
    },
    
    watch: {
    
        data: {
        
            deep: true,

            handler: function() {
            
                this.build()
            
            }
        
        }

    },
    
	methods: {

        build: function() {
        
            if (this.data.background) this.buildBackground()
            this.buildForeground()
        
        },

        buildBackground: function() {

            var values = []

            for(var i=0; i<this.data.background.labels.length; i++) {
            
                values.push(1)
            
            }

            // eslint-disable-next-line
			new Chart(this.$refs.background.getContext('2d'), {
				plugins: [ChartLabels],
				type: 'polarArea',
				responsive: true,
				data: {
					labels: this.data.background.labels,
					datasets: [{
						label: '',
						borderColor: '#ffffff',
						backgroundColor: this.data.background.colors,
						borderWidth: 2,
						pointBackgroundColor: '#239a3b',
						pointRadius: 2,
						data: values
					}]
				},
                options: {
					tooltips: {
						enabled: false
					},
					hover: {
						mode: null
					},
					layout: {
						padding: 0
					},
					plugins: {
						labels: {
							render: 'label',
							position: 'border',
							arc: true,
							fontSize: 24,
							fontFamily: 'Roboto, sans-serif',
							fontColor: '#fff'
						}
                    },
					legend: {
						display: false
					},
					title: {
						display: false
					},
					scale: {
						gridLines: {
							color: '#ffffff',
							lineWidth: 1
						},
						scaleLabel: {},
						ticks: {
							beginAtZero: true,
							min: 0,
							suggestedMax: 1,
							stepSize: 1,
							callback: function() {
								return ' '
							}
						},
						angleLines: {
							color: '#ffffff'
						},
						pointLabels: {
							fontSize: 12,
							fontColor: '#666666',
							fontFamily: 'Roboto, sans-serif'
						}
					}
				}
			})
        
        },

        buildForeground: function() {

			var max = 0

            this.$_.each(this.data.foreground.values, function(value) {
            
                max = (value > max) ? value : max
            
            })

            // eslint-disable-next-line
            new Chart(this.$refs.foreground.getContext('2d'), {
				type: 'polarArea',
				responsive: true,
				data: {
					labels: this.data.foreground.labels,
					datasets: [{
						borderColor: '#eeeeee',
						borderWidth: 2,
						pointBackgroundColor: '#239a3b',
						pointRadius: 2,
						backgroundColor: this.data.foreground.colors,
						data: this.data.foreground.values
					}]
				},
				options: {
					plugins: {
						labels: false
					},
					legend: {
						display: false
					},
					title: {
						display: false
					},
					scale: {
						gridLines: {
							color: 'transparent',
						lineWidth: 0
							},
						scaleLabel: {},
						ticks: {
							display: false,
							beginAtZero: true,
							min: 0,
							suggestedMax: max,
							stepSize: max,
							callback: function() {
								return ' '
							}
						},
						angleLines: {
							color: 'transparent'
						},
						pointLabels: {
							fontSize: 12,
							fontColor: '#666666',
                            fontFamily: 'Roboto, sans-serif'
						}
					}
				}
			})

        }

	}

}

</script>

<style scoped>

.pie {
	width: 100%;
	height: 100%;
	user-select: none;
	overflow: hidden;
}

.pie-chart {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
	width: 348px!important;
	height: 348px!important;
}

.pie-chart.is-foreground {
    z-index: 2;
}

</style>