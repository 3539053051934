<template>

<com-item name="plays.hindex" :size="size" :is-empty="!active" :loading="loading" title="h-index" :has-more="true" v-on:more="onMoreClick">

	<template v-slot:about>

		<p>The progression of {{ your }} h-index as {{ you }} logged plays.</p>

		<p>{{ your | capitalise }} h-index is currently <b>{{ context.hindex }}</b>.</p>

	</template>

	<template v-slot:stats>

		<div class="graph-wrapper">
		
			<canvas ref="graph" width="100%" height="280" style="width: 100%; height: 280px;" class="graph"></canvas>

		</div>
		
	</template>

</com-item>

</template>

<script>

import Chart from 'chart.js'
import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			chart: false
		}

	},

	computed: {

		start: function() {

			return this.$store.getters['page/insights/plays/start']

		},

		end: function() {

			return this.$store.getters['page/insights/plays/end']

		},

		hindex: function() {

			return this.$store.getters['page/insights/plays/hindex']

		},

		loading: function() {

			return this.fetching === 'all'

		},

		active: function() {

			return this.$_.keys(this.hindex).length && !this.loading

		}

	},

	watch: {

		hindex: function() {

			this.visualise()

		}

	},

	created: function() {

		Chart.pluginService.register({

			beforeDraw: function (chart) {

				if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {

					var ctx = chart.chart.ctx
					var chartArea = chart.chartArea

					var meta = chart.getDatasetMeta(0)

					for (var i = 0; i < chart.config.options.chartArea.columns.length; i++) {

						var start = meta.data[chart.config.options.chartArea.columns[i]]._x

						var stop

						if (meta.data[chart.config.options.chartArea.columns[i] + 1]) {

							stop = meta.data[chart.config.options.chartArea.columns[i] + 1]._x

						} else {

							stop = start

						}

						var widthMid = Math.ceil((stop - start) / 2)

						stop = (start !== stop) ? stop - widthMid + 3 : stop

						if (chart.config.options.chartArea.columns[i] > 0) {

							start = start - widthMid

						}

						ctx.save()
						ctx.fillStyle = chart.config.options.chartArea.backgroundColor
						ctx.fillRect(start, chartArea.top, stop - start, chartArea.bottom - chartArea.top)

					}
					
					ctx.restore()

				}
			}
		})

		this.$nextTick(function() {
		
			this.visualise()

		}.bind(this))

	},

	methods: {

		visualise: function() {

			if (this.$_.keys(this.hindex).length) {

				if (this.chart) {

					this.chart.destroy()

				}

				var start = this.$moment.unix(this.$store.getters['page/insights/plays/hindexStart']).utc().startOf('month').subtract(1, 'month')
				var end = this.$moment.unix(this.$store.getters['context/date/sync']).utc().startOf('month')

				var values = []
				var months = []
				var pointBackgroundColor = []
				var periodColumns = []

				var periodStart = this.$moment.unix(this.$store.getters['page/insights/plays/start']).utc().startOf('month')
				var periodEnd = this.$moment.unix(this.$store.getters['page/insights/plays/end']).utc().startOf('month')

				var current = this.$moment(start).utc()
				var hindex = 0

				while (current.isSameOrBefore(end)) {

					var stamp = current.format('MM.YY')

					hindex = (this.hindex[stamp]) ? this.hindex[stamp] : hindex

					months.push((this.hindex[stamp]) ? current.format('MMM \'YY') : '')
					values.push(hindex)
					pointBackgroundColor.push((this.hindex[stamp]) ? '#116987' : 'transparent')

					if (current.isSameOrAfter(periodStart) && current.isSameOrBefore(periodEnd) && this.period !== 'all') {

						periodColumns.push(values.length - 1)

					}

					current.add(1, 'month')

				}

				// eslint-disable-next-line
				this.chart = new Chart(this.$refs.graph.getContext('2d'), {
					type: 'line',
					responsive: false,
					data: {
						labels: months,
						datasets: [{
							data: values,
							backgroundColor: 'rgba(17, 105, 135, 0.2)',
							pointBackgroundColor: pointBackgroundColor,
							fill: true,
							borderWidth: 2,
							borderColor: 'rgba(17, 105, 135, 0.6)',
							pointRadius: 4,
							pointHoverRadius: 8,
							pointStyle: 'circle',
							pointBorderWidth: 0,
							pointBorderColor: 'transparent',
							lineTension: 0
						}]
					},
					options: {
						maintainAspectRatio: false,
						chartArea: {
							columns: periodColumns,
							backgroundColor: '#eeeeee'
						},
						legend: {
							display: false
						},
						title: {
							display: false
						},
						scales: {
							gridLines: {
								color: '#eee'
							},
							xAxes: [{
								gridLines: {
									display: false
								},
								ticks: {
									autoSkip: true,
									maxRotation: 90,
									minRotation: 90,
									fontSize: 10,
									fontColor: '#333333'
								}
							}],
							yAxes: [{
								gridLines: {
									display: false
								},
								ticks: {
									suggestedMin: 0,
									suggestedMax: 10
								}
							}]
						}
					}
				})

			}

		},

		onMoreClick: function() {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysHindex'
			})
		
		}

	}

}

</script>

<style scoped>

.graph-wrapper {
	padding: 20px;
}

</style>