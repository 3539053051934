<template>

<div class="textbox" v-bind:class="{[bkgClass]: true}">

	<div class="textbox-wrapper">

		<div v-show="prefix" class="textbox-prefix">{{ prefix }}</div>

		<input 
			:name="name" 
			type="text" 
			ref="text"
			:maxlength="maxlength"
			:placeholder="placeholder" 
			v-bind:value="value" 
			v-on:keyup.enter="$emit('enter')"
			v-on:input="$emit('input', $event.target.value)" 
			class="textbox-input" 
			:disabled="disabled"
			v-bind:class="{
				'is-error': error,
				'is-disabled': disabled
			}"
			autocomplete="off" 
			autocorrect="off" 
			autocapitalize="off" 
			spellcheck="false"
		/>

	</div>

	<div class="textbox-notes" v-if="notes">{{ notes }}</div>

	<div v-show="validation && validationMessage !== 'off' && value" class="textbox-validation">{{ validationFeedback }}</div>

</div>

</template>

<script>

import InputValidation from 'core/mixin/form/input/Validation.js'

export default {

	mixins: [InputValidation],

	props: [
		'name',
		'placeholder', 
		'value',
		'notes',
		'maxlength',
		'prefix',
		'autofocus',
		'disabled',
		'error',
		'validation',
		'validationMessage',
		'background'
	],

	computed: {

		bkgClass: function() {

			if (this.$_.contains(['light', 'dark'], this.background)) return 'bkg-' + this.background

			return 'bkg-light'

		}

	},

	created: function() {

		if (this.autofocus) {

			this.$nextTick(function() {

				this.$refs.text.focus()
			
			}.bind(this))
			
		}

	},

	methods: {

		validate: function(rules) {

			if (rules.exists) {

				if (this.value.trim().length === 0) {

					this.feedback.push('')

				}

			}

			if (rules.max) {

				if (rules.number) {

					if (this.value > rules.max) {
					
						this.feedback.push('too high')
					
					}
				
				} else if (this.value.length > rules.max) {

					this.feedback.push('too long')

				}

			}

			if (rules.min) {

				if (rules.number) {

					if (this.value < rules.min) {
					
						this.feedback.push('too low')
					
					}
				
				} else if (this.value.length < rules.min) {

					this.feedback.push('too long')

				}

			}

			if (rules.number) {

				if (!this.value.match(/^[0-9]+$/i) && this.value.length) {

					this.feedback.push(' numbers only')

				}

			}

			if (rules.money) {

				if (!this.value.match(/^\$?\d+(,\d{3})*(\.\d*)?$/) && this.value.length) {

					this.feedback.push('invalid')

				}

			}

			if (rules.minimum) {

				if (parseFloat(this.value) < rules.minimum) {

					this.feedback.push('$' + rules.minimum.toFixed(2) + ' min')

				}

			}

			if (rules.alphanumeric) {

				if (!this.value.match(/^[a-z0-9]+$/i) && this.value.length) {

					this.feedback.push('letters and numbers only')

				}

			}

		}

	}

}

</script>

<style scoped>

.textbox {
	margin-bottom: 20px;
}

.textbox-wrapper {
	display: flex;
}

.textbox-input {
	height: 40px;
	line-height: 40px;
	font-size: 16px;
	border-radius: 4px;
	font-weight: 400;
	padding: 8px;
	width: 100%;
	flex-grow: 1;
}

@media only screen and (max-width: 767px) {

	.textbox-input {
		font-size: 12px;
	}

}

.textbox.bkg-light .textbox-input {
	background-color: #fff;
	border: 2px solid #fff;
	color: #333;
}

.textbox.bkg-light .textbox-input.is-error {
	border: 2px solid #ce5f5f;
}

.textbox.bkg-light .textbox-input::placeholder {
	color: #666;
}

.textbox.bkg-dark .textbox-input {
	background-color: #3395B5;
	border: 1px solid #3395B5;
	color: #fff;
}

.textbox.bkg-dark .textbox-input:focus {
}

.textbox.bkg-dark .textbox-input.is-error {
	background-color: #ce5f5f;
	border-color: #ce5f5f;
}

.textbox.bkg-dark .textbox-input::placeholder {
	color: rgba(255, 255, 255, 0.25);
}

.textbox-input.is-disabled {
	background-color: #eee!important;
}

.textbox-input.is-disabled::placeholder {
	color: #ccc!important;
}

.textbox-prefix {
	line-height: 36px;
	height: 40px;
	font-size: 14px;
	line-height: 40px;
	border-radius: 4px 0px 0px 4px;
	padding: 0px 8px;
	background-color: rgba(0, 0, 0, 0.2);
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.textbox-prefix {
		font-size: 12px;
	}

}

.textbox-notes {
	font-size: 12px;
	margin-top: 4px;
	color: #fff;	
	padding: 0px 4px;
	font-weight: 400;
}

.textbox-validation {
	position: absolute;
	right: 4px;
	top: 4px;
	color: red;
	font-weight: 400;
	font-size: 11px;
	line-height: 30px;
	height: 30px;
	white-space: nowrap;
	text-align: right;
	padding: 0px 7px;
}

</style>
