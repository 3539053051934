<template>

<com-item name="worth.status" :is-list="true" :size="size" :loading="loading" :is-empty="!active" title="Status" class="status">

	<template v-slot:about>

		<p>The value of {{ your }} entire collection.</p>

	</template>

	<template v-slot:stats>

        <div class="status-wrapper">

            <template v-for="item in statuses">

            <div class="status-item" :key="item.id" :class="{[statusClass(item)]: true, 'is-sub': item.sub}" v-on:click="onStatusClick(item.id)" v-if="item.count">

                <div class="status-item-name">{{ item.name }} ({{ item.count }})</div>

                <div class="status-item-bar">
                    <div class="status-item-bar-percent" :style="{width: statusWidth(item)}"></div>
                </div>

                <div class="status-item-count" v-html="formatcurrency(item.value, 0)" />

            </div>

            </template>

        </div>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

        statuses: function() {
        
            var statuses = []

            this.$_.each(this.status.all, function(status) {
            
                status.name = this.$CONSTANTS.GAME.STATUS.NAME[status.id]
                statuses.push(status)

                if(status.id === 'wishlist') {
                
                    this.$_.each(this.status.wishlist, function(status) {
                    
                        status.name = this.$CONSTANTS.WISHLIST.LABEL[status.id]
                        status.sub = true
                        statuses.push(status)

                    }.bind(this))
                
                }
            
            }.bind(this))

            return statuses
        
        },

		loading: function() {

			return this.fetching === 'all' 

		},

		status: function() {

			return this.$store.getters['page/insights/worth/status']

		},

		active: function() {

			return this.status && !this.loading

		}

	},

	methods: {

		onStatusClick: function(id) {

			var query = {
                sort: '-worth.value'
            }

			if (id !== 'own') {

				query['status.own'] = 0
				query['status.' + id] = 1

			}

			this.$router.push({
				name: 'Collection',
				query: query
			}).catch(function(e) { console.log(e) })

		},

		statusWidth: function(item) {

			var max = this.$_.max(this.status.all, function(status) { return status.value }).value
			var max2 = this.$_.max(this.status.wishlist, function(status) { return status.value }).value

            max = (max < max2) ? max2 : max

			return ((100 / max) * item.value) + '%'

		},

		statusClass: function(item) {

			return 'is-' + item.id

		}

	}

}

</script>

<style scoped>

.status >>> .insights-item-content {
    min-height: auto;
}

.status-wrapper {
	width: 100%;
}

.status-item {
	height: 32px;
	width: 100%;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 0px 15px;
    z-index: 2;
    background-color: #fff;
}

.status-item.is-sub {
    padding-left: 40px;
    z-index: 1;
}

.status-item.is-sub:before {
    display: block;
    content: '';
    top: 50%;
    left: 15px;
    position: absolute;
    height: 1px;
    width: 12px;
    background-color: #ccc;
    margin: 0px 5px 0px 3px;
}

.status-item.is-sub:after {
    display: block;
    content: '';
    bottom: 50%;
    left: 15px;
    position: absolute;
    height: 30px;
    width: 1px;
    background-color: #ccc;
    margin: 0px 5px 0px 3px;
}

.status-item:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.status-item-name {
	width: 150px;
	font-weight: 300;
	color: #333;
	font-size: 14px;
	line-height: 32px;
}

.is-desktop .status-item:hover .status-item-name {
	font-weight: 500;
}

.status-item-count {
	font-weight: 300;
	color: #333;
	line-height: 32px;
	font-size: 14px;
	width: 64px;
	text-align: right;
}

.status-item-bar {
	flex-grow: 1;
	padding: 0px 15px;
	height: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.status-item-bar-percent {
	height: 8px;
	width: 0%;
	border-radius: 4px;
}

.status-item.is-own .status-item-bar-percent {
	background-color: rgba(4, 108, 143, 0.9);
}

.status-item.is-prevowned .status-item-bar-percent {
	background-color: rgba(4, 108, 143, 0.7);
}

.status-item.is-fortrade .status-item-bar-percent {
	background-color: rgba(4, 108, 143, 0.5);
}

.status-item.is-want .status-item-bar-percent {
	background-color: rgba(4, 143, 85, 0.9);
}

.status-item.is-wanttoplay .status-item-bar-percent {
	background-color: rgba(4, 143, 85, 0.7);
}

.status-item.is-wanttobuy .status-item-bar-percent {
	background-color: rgba(4, 143, 85, 0.5);
}

.status-item.is-sub .status-item-bar-percent {
	background-color: rgba(143, 36, 4, 0.5);
}

.status-item.is-wishlist .status-item-bar-percent {
	background-color: rgba(143, 36, 4, 0.7);
}

.status-item.is-preordered .status-item-bar-percent {
	background-color: rgba(143, 36, 4, 0.9);
}

.status-item.is-hasparts .status-item-bar-percent {
	background-color: rgba(4, 108, 143, 0.5);
}

.status-item.is-wantparts .status-item-bar-percent {
	background-color: rgba(4, 143, 85, 0.5);
}

</style>