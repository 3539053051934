import API from 'core/api'
import _ from 'underscore'
import cookies from 'js-cookie'
import CONSTANTS from '../../constants'

export default {

	namespaced: true,

	actions: {

		settings: function(context, params) {

			return API.request('accounts/settings', params)

		},

		reset: function(context, params) {
			
			return new Promise(function(resolve, reject) {

				API.request('accounts/reset', params).then(function(json) {

					cookies.set('token', json.token, {
						expires: 365
					})

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		forgot: function(context, params) {
			
			return API.request('accounts/forgot', params)

		},

		verify: function(context, params) {
			
			return new Promise(function(resolve, reject) {

				function call(syncKey) {

					if(syncKey) params.syncKey = syncKey
				
					API.request('accounts/verify', params).then(function(json) {

						resolve(json)

					}, function(json) {

						if (json.error=== CONSTANTS.STATUS.ERROR.SYNC.INPROGRESS) {

							_.delay(function() { call(json.syncKey) }, 5000)

						} else {

							reject()
							
						}

					})

				}

				call()

			})

		},

		exists: function(context, params) {
			
			return new Promise(function(resolve, reject) {

				function call(syncKey) {

					if(syncKey) params.syncKey = syncKey
				
					API.request('accounts/exists', params).then(function(json) {

						resolve(json)

					}, function(json) {

						if (json.error=== CONSTANTS.STATUS.ERROR.SYNC.INPROGRESS) {

							_.delay(function() { call(json.syncKey) }, 5000)

						} else {

							reject()
							
						}

					})

				}

				call()

			})

		},

		register: function(context, params) {
			
			return API.request('accounts/register', params)

		}

	}

}