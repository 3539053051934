<template>
	
<core-card-foot-item>

	<div class="counts is-empty" v-if="!max">n/a</div>

	<div class="counts" v-if="max">

		<div v-for="(count, index) in counts" :key="index" class="counts-bar" v-tooltip="tooltip(count, index)">

			<div class="counts-bar-percent" :style="{height: count.percent + '%', backgroundColor: color}" :class="{'is-value': count.value}"></div>

		</div>

	</div>

	<core-card-foot-item-label v-if="!inline">counts</core-card-foot-item-label>

</core-card-foot-item>

</template>

<script>

export default {

	props: ['item', 'inline'],

	data: function() {

		return {
			counts: [],
			color: '',
			max: 0
		}

	},

	watch: {

		item: {

			deep: true,

			handler: function() {

				this.onChange()

			}

		}

	},

	created: function() {

		this.onChange()

	},

	methods: {

		tooltip: function(count, index) {

			return count.text + this.$util.plural('player', index + 1) + '<small>' + count.value + this.$util.plural('play', count.value) + '</small>'

		},

		onChange: function() {

			this.counts = []

			this.color = 'rgba(' + ((this.item.game.color !== '0') ? this.item.game.color : '134, 134, 134') + ')'

			this.max = this.$_.max(this.item.counts)

			this.$_.each(this.item.counts, function(value, count) {

				count++
				var text = (count === 6) ? count.toString() + '+' : count.toString()

				this.counts.push({
					percent: (100 / this.max) * value,
					value: value,
					text: text
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.counts {
	height: 32px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.counts.is-empty {
	font-size: 14px;
	font-weight: 400;
	color: #ccc;
	line-height: 32px;
}

.counts-bar {
	height: 32px;
	width: 8px;
	margin-right: 1px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background-color: #efefef;
	border-radius: 4px;
}

.counts-bar:last-child {
	margin-right: 0px;
}

.counts-bar-percent {
	width: 8px;
	border-radius: 4px;
}

.counts-bar-percent.is-value {
	min-height: 8px;
}

</style>
