export default {

	props: ['item', 'section'],

	data: function() {

		return {
			default: false,
			current: false
		}

	},

	created: function() {

		this.current = (this.section) ? this.section : this.default

	},

	methods: {

		isSelected: function(value) {

			return this.current === value

		},

		onTabClick: function(value, autoclose) {

			autoclose = (autoclose === undefined) ? false : autoclose

			if (value === this.current && this.current) {

				if (autoclose) this.$emit('close')

			} else {

				this.current = value

			}

		},

		onCloseClick: function() {

			this.$emit('close')

		}

	}

}