<template>

<div class="tool">

	<app-unsupported v-if="!supported" />

	<core-filter subtitle="Heatmap" :sharing="is.sharing" :downloading="is.downloading" :loading="is.fetching" v-if="supported" v-on:share="onShareClick" v-on:download="onDownloadClick" />

	<div class="heatmap" v-bind:class="{'is-saving': is.fetching, [themeClass]: true, 'has-transparent': layout.empty}" ref="wrapper" v-show="supported">

		<div class="heatmap-status" v-if="is.fetching || !is.ready"></div>

		<div class="heatmap-canvas" ref="canvas" v-bind:style="{padding: layoutMargin}" v-bind:class="{'has-border': layout.border}">

			<app-head :layout="layout" :margin="layoutMargin" :custom="layout.show.dates" :border="layout.border" :square="layout.border">

				<div class="heatmap-head-dates" v-bind:class="{'has-border': layout.border}">
					{{ fromDate | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }} - {{ toDate | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}
				</div>

			</app-head>

			<div class="heatmap-wrapper">

				<div class="heatmap-weekdays" v-if="layout.show.days" ref="days">
					<div class="heatmap-day" v-if="layout.show.months" v-bind:style="{marginBottom: layoutMargin, height: layoutSize, fontSize: layoutFont, lineHeight: layoutSize}">&nbsp;</div>
					<div class="heatmap-day" v-for="(day, index) in weekdays" v-bind:key="index" v-bind:style="{margin: layoutMargin, height: layoutSize, fontSize: layoutFont, lineHeight: layoutSize}">{{ day }}</div>
				</div>

				<div class="heatmap-body">

					<div class="heatmap-months" v-if="layout.show.months" v-bind:style="{height: layoutSize}">

						<div class="heatmap-month" v-for="(month, index) in months" v-bind:key="index" v-bind:offset="month.offset" v-bind:style="{fontSize: layoutFont, height: layoutSize, lineHeight: layoutSize, left: (month.offset * monthSize) + 'px', marginBottom: layoutMargin}">
							{{ month.text }}
						</div>

					</div>

					<div class="heatmap-weeks">

						<div class="heatmap-week" v-for="(week, index) in weeks" v-bind:key="index">

							<div class="heatmap-day" v-for="(day, index) in week.days" v-bind:class="{'is-inactive': day.inactive, 'has-plays': day.plays, [density(day)]: !day.inactive}" v-bind:key="index" v-bind:style="{margin: layoutMargin, width: layoutSize, height: layoutSize, borderRadius: layoutRadius}"></div>

						</div>

					</div>

				</div>

			</div>

			<app-foot :layout="layout" :margin="layoutMargin" />

		</div>

	</div>

</div>

</template>

<script>

import Unsupported from './common/Unsupported'
import Tool from './common/Tool.js'

import Head from './common/Head'
import Foot from './common/Foot'

import Filter from './heatmap/Filter'

import panzoom from 'panzoom'

export default {

	mixins: [Tool],

	components: {

		'core-filter': Filter,
		'app-unsupported': Unsupported,
		'app-head': Head,
		'app-foot': Foot

	},

	data: function() {

		return {

			type: this.$CONSTANTS.INSIGHTS.HEATMAP.PLAYS,
			plays: [],
			months: [],
			weeks: [],
			panSetup: false,
			toolName: 'heatmap',
			fromDate: 0,
			toDate: 0,
			weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

		}

	},

	created: function() {

		this.$store.commit('filter/tools/heatmap/layout/start', this.$store.getters['filter/config/insights/heatmap/start'])

	},

	watch: {

		layoutStart: function() {

			this.visualise()

		}

	},

	computed: {

		monthSize: function() {

			return this.layout.size + (this.layout.spacing * 2)

		},

		layout: function() {

			return this.$store.getters['filter/tools/heatmap/layout']

		},

		supported: function() {

			return this.is.supported.imageExport

		},

		themeClass: function() {

			return 'theme-' + this.layout.theme

		},

		layoutStart: function() {

			return this.layout.start

		},

		layoutRadius: function() {

			return this.layout.corner.toString() + 'px'

		},

		layoutSize: function() {

			return this.layout.size.toString() + 'px'

		},

		layoutMargin: function() {

			return this.layout.spacing.toString() + 'px'

		},

		layoutFont: function() {

			return this.layout.font.toString() + 'px'

		}

	},

	methods: {

		density: function(day) {

			var density

			if (this.filter.type === this.$CONSTANTS.INSIGHTS.HEATMAP.PLAYS) {

				density = day.plays

				if (density > 5) density = 5

			} else if (this.filter.type === this.$CONSTANTS.INSIGHTS.HEATMAP.WEIGHT) {

				density = day.weight

				if (density === 0) density = 0
				else if (density <= 1.5) density = 1
				else if (density <= 2.5) density = 2
				else if (density <= 3.0) density = 3
				else if (density <= 3.5) density = 4
				else if (density <= 5) density = 5

			} else if (this.filter.type === this.$CONSTANTS.INSIGHTS.HEATMAP.WINS) {

				density = (day.plays) ? (100 / day.plays) * day.wins : 0

				if (day.plays === 0) density = 0
				else if (density <= 20) density = 1
				else if (density <= 40) density = 2
				else if (density <= 60) density = 3
				else if (density <= 80) density = 4
				else if (density <= 100) density = 5

			} else if (this.filter.type === this.$CONSTANTS.INSIGHTS.HEATMAP.NEW) {

				density = day.plays

				if (density === 0) density = 0
				else if (density <= 1) density = 1
				else if (density <= 2) density = 3
				else if (density <= 3) density = 5

			}

			return 'density-' + density

		},

		fetched: function(json) {

			this.plays = json.plays

			if (this.layout.trim) {

				this.fromDate = json.firstPlay
				this.toDate = json.lastPlay

			} else {

				this.fromDate = this.filter.date.from
				this.toDate = this.filter.date.to

			}

			this.visualise()

		},
		
		visualise: function() {

			this.weeks = []
			this.months = []

			var j, currentDate, entry

			this.weekdays = (this.layout.start === 'sunday') ? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

			var startDate = this.$moment.unix(this.fromDate).utc().day((this.layout.start === 'sunday') ? 0 : 1)

			currentDate = this.$moment(startDate).utc()

			var currentMonth = (currentDate.date() < 7) ? 0 : currentDate.format('M') 
			var currentMonthOffset = 0

			while (currentDate.isSameOrBefore(this.$moment.unix(this.toDate).utc(), 'day')) {

				if (currentDate.format('M') !== currentMonth) {

					currentMonth = currentDate.format('M')

					this.months.push({
						text: currentDate.format('MMM'),
						offset: currentMonthOffset
					})

				}
			
				currentMonthOffset++

				this.weeks.push({
					days: []
				})

				for (j = 1; j <= 7; j++) {

					entry = this.plays[currentDate.unix()] || {}

					this.weeks[this.weeks.length - 1].days.push({
						unix: currentDate.unix(),
						date: currentDate.format(this.$util.date.format('Do', 'MMMM', ', YYYY')),
						plays: entry.count || 0,
						weight: entry.weight || 0,
						wins: entry.wins || 0,
						inactive: currentDate.isBefore(this.$moment.unix(this.fromDate).utc(), 'day') || currentDate.isAfter(this.$moment.unix(this.toDate).utc(), 'day') || currentDate.isAfter(this.$moment.utc(), 'day')
					})

					currentDate = currentDate.add(1, 'days')

				}

			}

			if (!this.panSetup) {

				panzoom(this.$refs.wrapper, {
					smoothScroll: false,
					maxZoom: 1,
					minZoom: 0.25,
					beforeWheel: function() {
						return true
					},
					filterKey: function() {
						return true
					}
				})

				this.panSetup = false

			}


		}

	}

}

</script>

<style scoped>

.tool {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	height: calc(100vh - 232px);
}

.heatmap {
	border: 1px dashed #ccc;
	padding: 4px;
	background-color: #fff;
}

.heatmap.has-margin {
	padding: 0px;
}

.heatmap-status {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #024359;
	background-image: url(~core/assets/load.gif);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	z-index: 2;
}

.heatmap-empty {
	font-size: 16px;
	font-weight: 400;
	color: #999;
	line-height: 64px;
	position: absolute;
	top: 50%;
	margin-top: -32px;
	left: 0px;
	width: 100%;
	text-align: center;
}

.heatmap-canvas {
	background-color: #fff;
}

.heatmap-canvas.has-border {
	border-radius: 4px;
	border: 1px solid #ddd;
}

.heatmap-month {
	position: absolute;
	top: 0px;
	color: #333;
}

.heatmap-weeks {
	display: flex;
	flex-direction: row;
}

.heatmap-week {
	display: flex;
	flex-direction: column;
}

.heatmap-day {
	transition: background-color 400ms ease-out;
}

.heatmap-day.has-plays {
	cursor: pointer; 
}

.heatmap-body {
	display: flex;
	flex-direction: column;
}

.heatmap-wrapper {
	display: flex;
	flex-direction: row;
}

.heatmap-canvas.has-border .heatmap-wrapper {
	padding: 8px;
}

.heatmap-weekdays {
	padding-right: 16px;
	display: flex;
	flex-direction: column;
}

.heatmap-weekdays .heatmap-day {
	color: #333;
	text-align: left;
}

.heatmap-day.is-inactive {
	background-color: #ffffff!important;
}

.heatmap.theme-blue .heatmap-day.density-0 { background-color: rgba(17, 105, 135, 0.02); }
.heatmap.has-transparent.theme-blue .heatmap-day.density-0 { background-color: rgba(17, 105, 135, 0.0); }
.heatmap.theme-blue .heatmap-day.density-1 { background-color: rgba(17, 105, 135, 0.2); }
.heatmap.theme-blue .heatmap-day.density-2 { background-color: rgba(17, 105, 135, 0.4); }
.heatmap.theme-blue .heatmap-day.density-3 { background-color: rgba(17, 105, 135, 0.6); }
.heatmap.theme-blue .heatmap-day.density-4 { background-color: rgba(17, 105, 135, 0.8); }
.heatmap.theme-blue .heatmap-day.density-5 { background-color: rgba(17, 105, 135, 1); }

.heatmap.theme-red .heatmap-day.density-0 { background-color: rgba(135, 17, 17, 0.02); }
.heatmap.has-transparent.theme-red .heatmap-day.density-0 { background-color: rgba(17, 105, 135, 0.0); }
.heatmap.theme-red .heatmap-day.density-1 { background-color: rgba(135, 17, 17, 0.2); }
.heatmap.theme-red .heatmap-day.density-2 { background-color: rgba(135, 17, 17, 0.4); }
.heatmap.theme-red .heatmap-day.density-3 { background-color: rgba(135, 17, 17, 0.6); }
.heatmap.theme-red .heatmap-day.density-4 { background-color: rgba(135, 17, 17, 0.8); }
.heatmap.theme-red .heatmap-day.density-5 { background-color: rgba(135, 17, 17, 1); }

.heatmap.theme-green .heatmap-day.density-0 { background-color: rgba(17, 135, 17, 0.02); }
.heatmap.has-transparent.theme-green .heatmap-day.density-0 { background-color: rgba(17, 105, 135, 0.0); }
.heatmap.theme-green .heatmap-day.density-1 { background-color: rgba(17, 135, 17, 0.2); }
.heatmap.theme-green .heatmap-day.density-2 { background-color: rgba(17, 135, 17, 0.4); }
.heatmap.theme-green .heatmap-day.density-3 { background-color: rgba(17, 135, 17, 0.6); }
.heatmap.theme-green .heatmap-day.density-4 { background-color: rgba(17, 135, 17, 0.8); }
.heatmap.theme-green .heatmap-day.density-5 { background-color: rgba(17, 135, 17, 1); }

.heatmap.theme-purple .heatmap-day.density-0 { background-color: rgba(104, 17, 135, 0.02); }
.heatmap.has-transparent.theme-purple .heatmap-day.density-0 { background-color: rgba(104, 17, 135, 0.0); }
.heatmap.theme-purple .heatmap-day.density-1 { background-color: rgba(104, 17, 135, 0.2); }
.heatmap.theme-purple .heatmap-day.density-2 { background-color: rgba(104, 17, 135, 0.4); }
.heatmap.theme-purple .heatmap-day.density-3 { background-color: rgba(104, 17, 135, 0.6); }
.heatmap.theme-purple .heatmap-day.density-4 { background-color: rgba(104, 17, 135, 0.8); }
.heatmap.theme-purple .heatmap-day.density-5 { background-color: rgba(104, 17, 135, 1); }

.heatmap-head-dates {
	font-size: 12px;
	font-weight: 300;
	color: #333;
}

.heatmap-head-dates.has-border {
	padding-left: 8px;
	padding-right: 8px;
}

</style>