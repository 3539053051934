import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		generic: function(context, data) {

			data = data || {}
			data.params = data.params || {}

			return new Promise(function(resolve, reject) {

				API.request(data.url, data.params, true).then(function(json) {
					
					if (json.data) {

						if (json.data.games) context.commit('data/games/add', json.data.games, { root: true })

					}

					context.commit('ready', null, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})		

		},

		users: function(context, params) {

			return context.dispatch('generic', {
				params: params,
				url: 'users/tools'
			})	
			
		},

		groups: function(context, params) {

			return context.dispatch('generic', {
				params: params,
				url: 'groups/tools'
			})	
			
		},

		buddies: function(context, params) {

			return context.dispatch('generic', {
				params: params,
				url: 'buddies/tools'
			})	
			
		}

	}

}