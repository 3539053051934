<template>

<core-modal v-on:close="onClose" :game="data.game">

	<template v-slot:head>

		Rename Game

	</template>

	<template v-slot:default>

		<core-form-input-textbox :autofocus="true" maxlength="64" name="text" v-on:validate="validate" placeholder="Enter name" v-model="inputs.text" notes="Maximum of 64 characters." :error="is.error" :disabled="is.loading" :validation="validation.text" />

		<core-form-input-togglelist title="Seen by" name="public" v-on:validate="validate" v-model="inputs.public" :error="is.error" :disabled="is.loading" :validation="validation.public" :options="publicOptions" />

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.saving" :disabled="!is.valid || is.loading">Save</core-button>
		<core-button theme="blue" v-on:click.native="onRestoreClick" :loading="is.restoring" :disabled="!is.valid || is.loading" v-if="hasEdited">Restore</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	props: ['data'],
	
	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				saving: false,
				restoring: false,
				loading: false
			},

			inputs: {
				text: '',
				public: 1
			},

			validation: {
				text: {
					exists: true,
					max: 64
				}
			}

		}

	},

	created: function() {

		this.inputs.text = this.$store.getters['page/' + this.page + '/associated/games/all'][this.data.game].game.name

	},

	computed: {

		page: function() {

			return this.$store.getters['service/select/name']

		},

		hasEdited: function() {

			return this.$store.getters['page/' + this.page + '/associated/games/all'][this.data.game].game.name !== this.$store.getters['page/' + this.page + '/associated/games/all'][this.data.game].game.originalName

		},

		publicOptions: function() {

			return [{
				value: 1,
				title: 'Everyone',
				text: 'Anyone viewing your profile can see this rename instead of the original name.'
			}, {
				value: 0,
				title: 'Only me',
				text: 'Only you can see this rename, everyone else will see the original name.'
			}]

		}

	},

	methods: {

		onRestoreClick: function() {

			this.is.loading = true
			this.is.restoring = true

			this.$store.dispatch('api/custom/rename/delete', {
				data: {
					game: this.data.game
				}
			}).then(function(original) {

				this.$store.commit('data/games/update', {
					id: original.game,
					name: original.text
				})

				this.$store.commit('page/' + this.page + '/games/rename', {
					id: original.game,
					name: original.text
				})

				if (this.page === 'collection') {

					this.$store.commit('page/' + this.page + '/nested/games/rename', {
						id: original.game,
						name: original.text
					})

				}

				this.$emit('close')

			}.bind(this), function() {

				this.is.error = true
				this.is.restoring = false
				this.is.loading = false

			}.bind(this)) 

		},

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true
				this.is.saving = true

				this.$store.dispatch('api/custom/rename/create', {
					data: {
						game: this.data.game,
						text: this.inputs.text,
						public: this.inputs.public
					}
				}).then(function(rename) {

					this.$store.commit('data/games/update', {
						id: rename.game,
						name: rename.text
					})

					this.$store.commit('page/' + this.page + '/games/rename', {
						id: rename.game,
						name: rename.text
					})

					if (this.page === 'collection') {

						this.$store.commit('page/' + this.page + '/nested/games/rename', {
							id: rename.game,
							name: rename.text
						})

					}

					this.$emit('close')

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false
					this.is.saving = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>