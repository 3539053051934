<template>

<com-item name="plays.heatmap" :size="size" :is-empty="!active" :loading="loading" title="Heatmap" class="heatmap" :class="{[typeClass]: true}">

	<template v-slot:about>

		<p>An overview of {{ your }} daily plays {{ periodText }}.</p>

	</template>

	<template v-slot:stats>

		<div class="heatmap-graph" v-show="active">

			<div class="heatmap-wrapper">

				<div class="heatmap-months" v-show="isYearly && active">

					<div class="heatmap-month" v-for="(month, index) in months" v-bind:key="index" v-bind:offset="month.offset">
						{{ month.text }}
					</div>

				</div>

				<div class="heatmap-weeks" v-show="active">

					<div class="heatmap-week is-labels" v-show="isYearly">
						<div class="heatmap-day" v-if="startSunday"><span>Sun</span></div>
						<div class="heatmap-day"><span>Mon</span></div>
						<div class="heatmap-day"><span>Tue</span></div>
						<div class="heatmap-day"><span>Wed</span></div>
						<div class="heatmap-day"><span>Thu</span></div>
						<div class="heatmap-day"><span>Fri</span></div>
						<div class="heatmap-day"><span>Sat</span></div>
						<div class="heatmap-day" v-if="!startSunday"><span>Sun</span></div>
					</div>

					<div class="heatmap-week is-labels" v-show="!isYearly">
						<div v-for="year in years" v-bind:key="year" class="heatmap-day"><span>{{ year }}</span></div>
					</div>

					<div class="heatmap-week" v-for="(week, index) in weeks" v-bind:key="index">

						<div class="heatmap-day" v-tooltip="tooltip(day)" v-for="(day, index) in days(week)" v-bind:class="{'is-inactive': day.inactive, 'has-plays': day.plays, [playDensity(day)]: !day.inactive}" v-bind:key="index" v-on:click="onDayClick(day)">
					
						</div>

					</div>

				</div>

			</div>

		</div>
		
	</template>

	<template v-slot:buttons>

		<com-toggle :options="heatmapOptions" :value="heatmapValue" v-on:change="onHeatmapChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size', 'period'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {

		return {
			startSunday: true,
			months: [],
			weeks: []
		}

	},

	computed: {

		years: function() {

			var years = []

			var min = parseInt(this.$moment.unix(this.start).utc().format('YYYY'))
			var max = parseInt(this.$moment.utc().format('YYYY'))

			for (var i = max; i >= min; i--) {

				years.push(i)

			}

			return years

		},

		isYearly: function() {

			return this.period !== 'all'

		},

		start: function() {

			return this.$store.getters['page/insights/plays/start']

		},

		end: function() {

			return this.$store.getters['page/insights/plays/end']

		},


		type: function() {

			return this.$store.getters['filter/insights/plays/heatmap']

		},

		typeClass: function() {

			if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.PLAYS) return 'type-plays'
			if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.WEIGHT) return 'type-weight'
			if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.WINS) return 'type-wins'
			if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.NEW) return 'type-new'

			return false

		},

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'heatmap'

		},

		data: function() {

			return this.$store.getters['page/insights/plays/plays']

		},

		active: function() {

			return this.$_.keys(this.data).length && !this.loading

		},

		heatmapOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.HEATMAP.PLAYS, text: 'Plays'})
			options.push({value: this.$CONSTANTS.INSIGHTS.HEATMAP.WEIGHT, text: 'Weight'})

			if (this.$store.getters['context/is/user']) options.push({value: this.$CONSTANTS.INSIGHTS.HEATMAP.WINS, text: 'Wins'})
			if (this.$store.getters['context/is/user']) options.push({value: this.$CONSTANTS.INSIGHTS.HEATMAP.NEW, text: 'First plays'})

			return options

		},

		heatmapValue: function() {

			return this.$store.getters['filter/insights/plays/heatmap']

		},

	},

	created: function() {
	
		var i

		for (i = 1; i <= 12; i++) {

			this.months.push({
				text: '',
				offset: 0
			})

		}

		for (i = 1; i <= 53; i++) {

			var days = []

			for (var j = 1; j <= 50; j++) {

				days.push({
					plays: 0,
					date: '',
					inactive: true
				})

			}

			this.weeks.push({
				days: days
			})

		}

		this.visualise()

	},

	watch: {

		data: function() {

			this.visualise()

		}

	},

	methods: {

		onHeatmapChange: function(value) {

			this.$store.commit('filter/insights/plays/heatmap', value)

		},

		tooltip: function(day) {

			if (!day.inactive) {

				var html = []

				if (this.type === 'weight' && day.plays) html.push(this.$options.filters.round(day.weight, 1).toString() + ' avg weight &middot; ')

				if (this.type === 'wins' && day.plays) html.push(day.wins.toString() + this.$util.plural('win', day.wins) + ' &middot; ')

				html.push(day.plays.toString() + this.$util.plural('play', day.play))

				html.push('<small>' + day.date + '</small>')

				return html.join('')

			} else {

				return false

			}

		},

		days: function(week) {

			if (this.period === 'all') {

				var min = parseInt(this.$moment.unix(this.start).utc().format('YYYY'))
				var max = parseInt(this.$moment.utc().format('YYYY'))

				return week.days.slice(0, max - min + 1)

			} else {

				return week.days.slice(0, 7)

			}

		},

		playDensity: function(day) {

			var density

			if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.PLAYS) {

				density = day.plays

				if (this.$store.getters['context/is/group']) {

					var users = this.$store.getters['context/count/users'] / 2

					if (this.period === 'all') {

						density = Math.ceil((density / users) / 3.5)

					} else {

						density = Math.ceil(density / users)

					}

				} else {

					if (this.period === 'all') {

						density = Math.ceil(density / 3.5)

					}

				}

				if (density > 5) density = 5

			} else if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.WEIGHT) {

				density = day.weight

				if (density === 0) density = 0
				else if (density <= 1.5) density = 1
				else if (density <= 2.5) density = 2
				else if (density <= 3.0) density = 3
				else if (density <= 3.5) density = 4
				else if (density <= 5) density = 5

			} else if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.WINS) {

				density = (day.plays) ? (100 / day.plays) * day.wins : 0

				if (day.plays === 0) density = 0
				else if (density <= 20) density = 1
				else if (density <= 40) density = 2
				else if (density <= 60) density = 3
				else if (density <= 80) density = 4
				else if (density <= 100) density = 5

			} else if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.NEW) {

				density = day.plays

				if (density === 0) density = 0
				else if (density <= 1) density = 1
				else if (density <= 2) density = 3
				else if (density <= 3) density = 5

			}

			return 'density-' + density

		},

		onDayClick: function(day) {

			if (day.plays) {

				var query = {
					'played.range.from': (this.period === 'all') ? day.unix[0] : day.unix,
					'played.range.to': (this.period === 'all') ? day.unix[1] : this.$moment.unix(day.unix).utc().endOf('day').unix()
				}

				if (this.type === this.$CONSTANTS.INSIGHTS.HEATMAP.NEW) {

					query['play.first'] = 1

				}

				this.$router.push({
					name: 'Plays',
					query: query
				}).catch(function(e) { console.log(e) })

			}

		},

		visualise: function() {

			this.startSunday = this.$store.getters['filter/config/insights/heatmap/start'] === 'sunday'

			var i, j, currentDate, entry

			if (this.period === 'all') {

				var stamp, endDate

				var min = parseInt(this.$moment.unix(this.start).utc().format('YYYY'))
				var max = parseInt(this.$moment.unix(this.end).utc().format('YYYY'))

				for (i = 1; i <= 53; i++) {

					for (j = max; j >= min && j > max - 50; j--) {

						currentDate = this.$moment.utc().year(j).startOf('year')

						currentDate = currentDate.day((this.startSunday) ? 0 : 1)

						currentDate = currentDate.add('days', (i - 1) * 7)

						stamp = j + '/' + i

						entry = this.data[stamp] || {}

						if (i === 53 || currentDate.isAfter(this.$moment.utc())) {

							this.weeks[i - 1].days[max - j].unix = false
							this.weeks[i - 1].days[max - j].date = false
							this.weeks[i - 1].days[max - j].plays = false
							this.weeks[i - 1].days[max - j].weight = false
							this.weeks[i - 1].days[max - j].wins = false
							this.weeks[i - 1].days[max - j].inactive = true

						} else if (parseInt(currentDate.format('M')) === 12 && parseInt(currentDate.format('D')) >= 23) {

							endDate = this.$moment(currentDate).utc().endOf('month')

							this.weeks[i - 1].days[max - j].unix = [currentDate.unix(), endDate.unix()]
							this.weeks[i - 1].days[max - j].date = currentDate.format(this.$util.date.format('Do', 'MMMM', ', YYYY')) + ' - ' + endDate.format(this.$util.date.format('Do', 'MMMM', ', YYYY'))
							this.weeks[i - 1].days[max - j].plays = entry.count || 0
							this.weeks[i - 1].days[max - j].weight = entry.weight || 0
							this.weeks[i - 1].days[max - j].wins = entry.wins || 0
							this.weeks[i - 1].days[max - j].inactive = false

						} else {

							endDate = this.$moment(currentDate).utc().add(6, 'days')

							this.weeks[i - 1].days[max - j].unix = [currentDate.unix(), endDate.unix()]
							this.weeks[i - 1].days[max - j].date = currentDate.format(this.$util.date.format('Do', 'MMMM', ', YYYY')) + ' - ' + endDate.format(this.$util.date.format('Do', 'MMMM', ', YYYY'))
							this.weeks[i - 1].days[max - j].plays = entry.count || 0
							this.weeks[i - 1].days[max - j].weight = entry.weight || 0
							this.weeks[i - 1].days[max - j].wins = entry.wins || 0
							this.weeks[i - 1].days[max - j].inactive = false

						}

					}

					for (j = min - 1; j > max - 50; j--) {

						this.weeks[i - 1].days[max - j].unix = false
						this.weeks[i - 1].days[max - j].date = false
						this.weeks[i - 1].days[max - j].plays = false
						this.weeks[i - 1].days[max - j].inactive = true

					}

				}

			} else {

				var startDate = this.$moment.unix(this.start).utc().day((this.startSunday) ? 0 : 1)

				currentDate = this.$moment(startDate).utc()

				var currentMonth = (currentDate.date() < 7) ? 0 : currentDate.format('M') 
				var currentMonthIndex = 0
				var currentMonthOffset = 0

				for (i = 1; i <= 12; i++) {

					this.months[i - 1].text = ''
					this.months[i - 1].offset = 0

				}

				for (i = 1; i <= 53; i++) {

					if (currentDate.format('M') !== currentMonth && i < 53) {

						currentMonth = currentDate.format('M')

						this.months[currentMonthIndex].text = currentDate.format('MMM')
						this.months[currentMonthIndex].offset = currentMonthOffset

						currentMonthOffset = -2
						currentMonthIndex++

					} else {

						currentMonthOffset++

					}

					for (j = 1; j <= 7; j++) {

						entry = this.data[currentDate.unix()] || {}

						this.weeks[i - 1].days[j - 1].unix = currentDate.unix()
						this.weeks[i - 1].days[j - 1].date = currentDate.format(this.$util.date.format('Do', 'MMMM', ', YYYY'))
						this.weeks[i - 1].days[j - 1].plays = entry.count || 0
						this.weeks[i - 1].days[j - 1].weight = entry.weight || 0
						this.weeks[i - 1].days[j - 1].wins = entry.wins || 0
						this.weeks[i - 1].days[j - 1].inactive = currentDate.isBefore(this.$moment.unix(this.start).utc(), 'day') || currentDate.isAfter(this.$moment.unix(this.end).utc(), 'day') || currentDate.isAfter(this.$moment.utc(), 'day')

						currentDate = currentDate.add(1, 'days')

					}

				}

			}

		}

	}

}

</script>

<style scoped>

.heatmap >>> .insights-item-content {
	min-height: auto;
	overflow-y: auto;
}

.heatmap-graph {
	width: 100%;
	min-width: 320px;
	padding: 15px;
	min-width: 900px;
}

.heatmap-wrapper {
	width: 100%;
}

.heatmap-months {
	width: 100%;
	padding-left: calc((100% / 54) * 2);
	height: 16px;
}

.heatmap-month {
	font-size: 12px;
	color: #333;
	float: left;
	padding-left: 1px;
	min-width: calc((100% / 54) * 3);
	height: 16px;
}

.heatmap-month:last-child {
	width: calc(100% / 54);
	min-width: auto;
}

.heatmap-month[offset="1"] { margin-left: calc(100% / 54); }
.heatmap-month[offset="2"] { margin-left: calc((100% / 54) * 2); }
.heatmap-month[offset="3"] { margin-left: calc((100% / 54) * 3); }
.heatmap-month[offset="4"] { margin-left: calc((100% / 54) * 4); }

.heatmap-weeks {
	width: 100%;
	display: grid;
    grid-template-columns: repeat(55, minmax(0, 1fr));
}

.heatmap-week {
	display: flex;
	flex-direction: column;
}

.heatmap-week.is-labels {
	grid-column: span 2;
}

.heatmap-day {
	border-radius: 4px;
	border: 1px solid #fff;
	transition: background-color 400ms ease-out;
	width: 100%;
	padding-top: calc(100% - 2px);
}

.heatmap-week.is-labels .heatmap-day {
	padding-top: calc(50% - 2px);
}

.heatmap-day.has-plays {
	cursor: pointer; 
}

.heatmap-week.is-labels .heatmap-day span {
	position: absolute;
	color: #333;
	left: 0px;
	width: 100%;
	top: 50%;
	text-align: left;
	font-size: 10px;
	transform: translateY(-50%);
}

.heatmap-day.is-inactive {
	background-color: #ffffff!important;
}

.heatmap.type-plays .heatmap-day.density-0 { background-color: rgba(17, 105, 135, 0.03); }
.heatmap.type-plays .heatmap-day.density-1 { background-color: rgba(17, 105, 135, 0.2); }
.heatmap.type-plays .heatmap-day.density-2 { background-color: rgba(17, 105, 135, 0.4); }
.heatmap.type-plays .heatmap-day.density-3 { background-color: rgba(17, 105, 135, 0.6); }
.heatmap.type-plays .heatmap-day.density-4 { background-color: rgba(17, 105, 135, 0.8); }
.heatmap.type-plays .heatmap-day.density-5 { background-color: rgba(17, 105, 135, 1); }

.heatmap.type-weight .heatmap-day.density-0 { background-color: rgba(135, 17, 17, 0.03); }
.heatmap.type-weight .heatmap-day.density-1 { background-color: rgba(135, 17, 17, 0.2); }
.heatmap.type-weight .heatmap-day.density-2 { background-color: rgba(135, 17, 17, 0.4); }
.heatmap.type-weight .heatmap-day.density-3 { background-color: rgba(135, 17, 17, 0.6); }
.heatmap.type-weight .heatmap-day.density-4 { background-color: rgba(135, 17, 17, 0.8); }
.heatmap.type-weight .heatmap-day.density-5 { background-color: rgba(135, 17, 17, 1); }

.heatmap.type-wins .heatmap-day.density-0 { background-color: rgba(17, 135, 17, 0.03); }
.heatmap.type-wins .heatmap-day.density-1 { background-color: rgba(17, 135, 17, 0.2); }
.heatmap.type-wins .heatmap-day.density-2 { background-color: rgba(17, 135, 17, 0.4); }
.heatmap.type-wins .heatmap-day.density-3 { background-color: rgba(17, 135, 17, 0.6); }
.heatmap.type-wins .heatmap-day.density-4 { background-color: rgba(17, 135, 17, 0.8); }
.heatmap.type-wins .heatmap-day.density-5 { background-color: rgba(17, 135, 17, 1); }

.heatmap.type-new .heatmap-day.density-0 { background-color: rgba(104, 17, 135, 0.02); }
.heatmap.has-transparent.type-new .heatmap-day.density-0 { background-color: rgba(104, 17, 135, 0.0); }
.heatmap.type-new .heatmap-day.density-1 { background-color: rgba(104, 17, 135, 0.2); }
.heatmap.type-new .heatmap-day.density-2 { background-color: rgba(104, 17, 135, 0.4); }
.heatmap.type-new .heatmap-day.density-3 { background-color: rgba(104, 17, 135, 0.6); }
.heatmap.type-new .heatmap-day.density-4 { background-color: rgba(104, 17, 135, 0.8); }
.heatmap.type-new .heatmap-day.density-5 { background-color: rgba(104, 17, 135, 1); }

</style>