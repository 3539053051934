import Store from 'app/store'
import CONSTANTS from 'app/constants'
import PubSub from 'core/pubsub'
import cookies from 'js-cookie'
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'
import 'whatwg-fetch'
import _ from 'underscore'
import axios from 'axios'

const CancelToken = axios.CancelToken

export default {

	busy: false,
	controller: false,
	signal: false,
	source: false,

	isBusy: function() {

		return this.busy

	},

	request: function(action, params, isSync) {

		if (this.busy && !params.silent) {

			this.controller.abort()

		}

		isSync = isSync || false

		this.controller = new AbortController()
		this.signal = this.controller.signal

		this.busy = true

		var self = this

		return new Promise(function(resolve, reject) {

			var headers = {
				'content-type': 'application/json'
			}

			if (cookies.get('token') !== undefined) headers['Authorization'] = cookies.get('token') 

			window.fetch(CONSTANTS.API + action + '.json', {
				synchronous: isSync,
				method: 'POST',
				headers: headers,
				credentials: 'include',
				signal: self.signal,
				body: JSON.stringify(params)
			}).then(function(response) {

				self.busy = false

				if (!response.ok) {

					response.json().then(function(json) {

						PubSub.$emit('error', json.error)

						reject(json)

					}, function(error) {

						PubSub.$emit('error', error)

						reject({
							error: error
						})

					})

				} else {

					return response.json().then(function(json) {

						Store.dispatch('session/verify', json.session).then(function() {

							if (json.permissions) Store.commit('permissions/set', json.permissions)

							resolve(json)
									
						})

					}, function(error) {

						PubSub.$emit('error', error)

						reject({
							error: error
						})

					})

				}

			}.bind(this), function(error) {

				PubSub.$emit('error', CONSTANTS.ERROR.UNKNOWN)

				reject({
					error: error
				})

			})

		}, function() {

			PubSub.$emit('error', CONSTANTS.ERROR.UNKNOWN)

		})

	},

	get: function(url, params, page) {

		params = params || {}
		page = page || false

		if (page) params['page'] = page

		this.source = CancelToken.source()

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'get', url, params)

			axios.get(CONSTANTS.API + url + '.json', {
				cancelToken: this.source.token,
				headers: {
					'content-type': 'application/json',
					'Cache-Control': 'no-cache',
					'Pragma': 'no-cache',
					'Expires': '0',
				},
				params: params
			}).then(function(response) {

				if (_.isObject(response)) {

					this.response(resolve, reject, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject((json.response) ? json.response : null)

			})

		}.bind(this))

	},

	post: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		this.source = CancelToken.source()

		return new Promise(function(resolve, reject) {

			console.log('api', 'post', url, params)

			axios.post(this.api + url + '.json', params, {
				cancelToken: this.source.token,
				headers: {
					'content-type': 'application/json'
				}
			}).then(function(response) {

				if (_.isObject(response)) {

					this.response(resolve, reject, response)

				} else {

					reject({})

				}

			}.bind(this), function(json) {

				reject(json.response.data.data)

			})

		}.bind(this))

	},

	patch: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'patch', url, params)

			axios.patch(CONSTANTS.API + url + '.json', params, {
				headers: {
					'content-type': 'application/json'
				}
			}).then(function(response) {

				if (_.isObject(response)) {

					this.response(resolve, reject, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response)

			})

		}.bind(this))

	},

	put: function(url, params) {

		params = params || {}

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'put', url, params)

			axios.put(CONSTANTS.API + url + '.json', params, {
				headers: {
					'content-type': 'application/json'
				}
			}).then(function(response) {

				if (_.isObject(response)) {

					this.response(resolve, reject, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response)

			})

		}.bind(this))

	},

	delete: function(url) {

		if (_.isArray(url)) url = url.join('/')

		return new Promise(function(resolve, reject) {

			console.log('api', 'delete', url)

			axios.delete(CONSTANTS.API + url + '.json', {
				headers: {
					'content-type': 'application/json'
				}
			}).then(function(response) {

				if (_.isObject(response)) {

					this.response(resolve, reject, response)

				} else {

					reject(null)

				}

			}.bind(this), function(json) {

				reject(json.response)

			})

		}.bind(this))

	},

	response: function(resolve, reject, response) {

		this.busy = false

		Store.dispatch('session/verify', response.data.session).then(function() {

			if (response.data.permissions) Store.commit('permissions/set', response.data.permissions)

			resolve(response.data.data || response.data)
							
		})

	}

}