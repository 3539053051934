<template>

<core-content-wrapper :empty="is.ready && !totalMaxResults" :noResults="is.ready && totalMaxResults && !totalResults" :loading="!is.ready">

	<core-content-empty v-show="is.ready && !totalMaxResults" title="Oh dear, no games!" text="It seems there are no games in this collection." />
	<core-content-empty v-show="is.ready && totalMaxResults && !totalResults" title="Oh dear, no matches!" text="Your filtering skills are too strong, try broadening the settings." />

	<com-filter :loading="is.fetching" :count="totalPseudoResults" :nested="totalNestedResults" :maximum="totalMaxResults" v-show="totalMaxResults" v-on:refresh="refresh" />
	
	<app-game-head :fixed="is.fixedHead" :columns="columns" v-if="!isGrid" v-show="is.ready && totalMaxResults && totalResults" />

	<core-content-list :fixed="is.fixedHead" v-on:fixed="onFixedChange" v-show="is.ready && totalMaxResults && totalResults" :cards="isGrid">

		<template v-if="!isGrid">

			<app-game-item v-for="item in content" filter="collection" v-bind:key="item.id" :summarised="true" :item="item" :fields="columns" />

		</template>

		<template v-if="isGrid">

			<app-game-card v-for="item in content" filter="collection" v-bind:key="item.id" :summarised="true" :item="item" :fields="columns" />

		</template>

		<core-content-scrollload v-on:scrolled="onScroll" v-show="currentPage < totalPages || (is.fetching && is.scrollLoading)" />

	</core-content-list>

	<core-content-select noun="game" />

</core-content-wrapper>

</template>

<script>

import Content from 'core/mixin/List.js'
import Filter from './collection/Filter.vue'

export default {

	mixins: [Content],

	components: {

		'com-filter': Filter

	},

	data: function() {

		return {

			contentName: 'collection',
			routeName: 'Collection'

		}

	},

	computed: {

		isGrid: function() {

			return this.$store.getters['filter/config/collection/layout'] === this.$CONSTANTS.LAYOUT.COLLECTION.GRID

		},

		columns: function() {

			var columns = []

			columns.push({
				type: 'avatar'
			})

			columns.push({
				type: 'name',
				sort: 'name',
				filter: 'collection',
				labelExpansions: true,
				lastPlayed: !this.isGroupOrBuddies && !this.window.is.desktop,
				showOwners: this.isGroupOrBuddies
			})

			columns.push({
				type: 'count',
				sort: 'count',
				filter: 'collection'
			})

			columns.push({
				type: 'duration',
				sort: 'duration',
				filter: 'collection'
			})

			columns.push({
				type: 'weight',
				sort: 'weight',
				filter: 'collection'
			})

			if (this.window.is.desktop && (this.hasSort('language.dependency') || this.filter.language.dependency.from !== 1 || this.filter.language.dependency.to !== 5 || this.filter.threshold.language > 0)) {

				columns.push({
					type: 'language',
					icon: 'language',
					filter: 'collection',
					sort: 'language.dependency'
				})
			
			}

			if (this.window.is.desktop && (this.hasSort('rank') || this.filter.rank.from !== 0 || this.filter.rank.to !== 1000)) {

				columns.push({
					type: 'bggrank',
					filter: 'collection',
					sort: 'rank'
				})
			
			}

			if (this.window.is.desktop && (this.hasSort('year') || this.filter.year.from !== 1970 || this.filter.year.to !== 2030)) {

				columns.push({
					type: 'published',
					title: 'Year',
					filter: 'collection',
					sort: 'year'
				})
			
			}

			if(!this.window.is.mobile) {

				columns.push({
					type: 'plays',
					sort: 'plays',
					title: 'Plays',
					filter: 'collection',
					context: (this.$store.getters['filter/collection/active']['played.range']) ? 'filter' : false,
					showTotal: this.$store.getters['filter/collection/active']['played.range']
				})
			
			}

			if (this.isUser && this.window.is.desktop) {

				columns.push({
					type: 'date',
					sort: 'played.last',
					filter: 'collection',
					title: 'Last play',
					value: 'play_last'
				})
			
			}

			if (this.window.is.desktop && (this.hasSort('worth.value') || this.filter.worth.value.from !== 0 || this.filter.worth.value.to !== 200)) {

				columns.push({
					key: 'worth.value',
					type: 'worth',
					filter: 'collection',
					title: 'Est. value',
					sort: 'worth.value'
				})
			
			}

			if (this.window.is.desktop && (this.hasSort('worth.play') || this.filter.worth.play.from !== 0 || this.filter.worth.play.to !== 200)) {

				columns.push({
					key: 'worth.play',
					type: 'worth',
					plays: true,
					title: 'Avg. play',
					filter: 'collection',
					sort: 'worth.play'
				})
			
			}

			if (this.isUser || (this.isGroup && this.isMember) || this.isBuddies) {

				columns.push({
					key: 'rating.user',
					type: 'rating',
					context: 'user',
					hideMobile: this.window.width < 375 && !this.isUser,
					sort: 'rating.user',
					filter: 'collection'
				})

			}

			if (this.isGroup) {

				columns.push({
					key: 'rating.group',
					type: 'rating',
					context: 'group',
					sort: 'rating.group',
					filter: 'collection'
				})

			}

			if (this.isBuddies) {

				columns.push({
					key: 'rating.buddies',
					type: 'rating',
					context: 'buddies',
					sort: 'rating.group',
					filter: 'collection'
				})

			}

			columns.push({
				key: 'rating.bgg',
				type: 'rating',
				context: 'bgg',
				sort: 'rating.bgg',
				filter: 'collection'
			})

			return columns

		}

	},

	methods: {

		hasSort: function(name) {
		
			return this.filter.sort === name || this.filter.sort === '-' + name

		},

		updateSelectServices: function() {

			this.selectServices.nesting = this.isUser && this.isSelf

		}

	}

}

</script>

<style scoped>

</style>