<template>
	
<div class="comment" v-if="isOwner || item.rank.comment" v-bind:class="{'has-comment': item.rank.comment}">

	<div class="comment-text" v-if="!is.editing" v-html="text"></div>

	<div class="comment-editor" v-if="is.editing">

		<textarea-autosize type="text" placeholder="Enter comment" v-model="comment" class="comment-editor-input" :disabled="is.saving" />

		<core-button size="tiny" :loading="is.saving" class="comment-editor-input-button" :disabled="!comment" v-on:click.native="onSaveClick">Save</core-button>
		<core-button size="tiny" v-if="item.rank.comment" theme="plain" class="comment-editor-input-button" v-on:click.native="onRemoveClick">Remove</core-button>
		<core-button size="tiny" theme="plaingrey" class="comment-editor-input-button" v-on:click.native="onCancelClick">Cancel</core-button>

	</div>

	<div class="comment-action" v-if="isOwner && !is.editing" v-on:click="onWriteClick">
		<template v-if="item.rank.comment">Edit</template><template v-if="!item.rank.comment">Write</template> comment
	</div>
	
</div>

</template>

<script>

export default {

	props: ['item', 'format'],

	data: function() {

		return {

			is: {
				editing: false,
				saving: false
			},

			comment: ''

		}

	},

	computed: {

		text: function() {

			return this.$options.filters.nl2br(this.item.rank.comment)

		}

	},

	created: function() {

		this.comment = this.item.rank.comment

	},

	methods: {

		save: function() {

			this.is.saving = true

			this.$store.dispatch('api/list/item/comment', {
				game: this.item.game.id,
				comment: this.comment
			}).then(function() {

				this.item.rank.comment = this.comment
				this.is.editing = false
				this.is.saving = false

			}.bind(this), function() {

				this.is.saving = false

			}.bind(this))

		},

		onSaveClick: function() {

			this.save()

		},

		onRemoveClick: function() {

			this.comment = ''
			this.item.rank.comment = this.comment
			this.is.editing = false
			this.save()

		},

		onCancelClick: function() {

			this.is.editing = false

		},

		onWriteClick: function() {

			this.comment = this.item.rank.comment

			this.is.editing = true

		}

	}

}

</script>

<style scoped>

.comment {
	padding: 4px 0px 0px 0px;
	width: 100%;
}

.comment-text {
	width: 100%;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: #333;
}

.comment-editor {
	width: 100%;
}

.comment-editor-input {
	width: 100%;
	margin-bottom: 4px;
	font-size: 14px;
	padding: 8px;
	border: 1px dashed #eee;
	background-color: #fff;
	line-height: 20px;
	font-weight: 400;
	min-height: 22px;
	color: #333;
}

.comment-editor-input-button {
	width: 48px;
}

.comment-editor-input-button.type-plain {
	color: #333!important;
}

.comment-action {
	color: #024359;
	cursor: pointer;
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
}

.comment.has-comment .comment-action {
	margin-top: 4px;
}

.is-desktop .comment-action:hover {
	text-decoration: underline;
}

</style>
