<template>

<com-item name="collection.rarity" :is-list="true" :has-more="true" v-on:more="onRarityClick" :size="size" :is-empty="!active" :loading="loading" title="Rarity">

	<template v-slot:about>

		<p>Which of {{ your }} games are the least or most owned amongst all BGG users.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>
	
		<app-game v-for="(item, index) in rarity" :item="{id: item.id}" :count="item.count" v-bind:key="index" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="rarityOptions" :value="rarityValue" v-on:change="onRarityChange" />
						
	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'rarity'

		},

		rarity: function() {

			return this.$store.getters['page/insights/collection/rarity']

		},

		active: function() {

			return this.rarity.length && !this.loading

		},

		rarityOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.RARITY.MOST, text: 'Least owned'})

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.RARITY.LEAST, text: 'Most owned'})

			return options

		},

		rarityValue: function() {

			return this.$store.getters['filter/insights/collection/rarity']

		}

	},

	methods: {

		onRarityChange: function(value) {

			this.$store.commit('filter/insights/collection/rarity', value)

		},

		onRarityClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionRarity',
				filter: this.$store.getters['filter/insights/collection']
			})

		}
	
	}

}

</script>

<style scoped>

</style>