<template>

<core-modal v-on:close="onClose" icon="caret-left">

	<template v-slot:head>

		Add Member

	</template>

	<template v-slot:default>

		<p>Enter the BGG username of the person you would like to add to your group. They do not need to be registered with geekGroup to be added, however they can still later register themselves.</p>

		<core-form-input-textbox :autofocus="true" name="name" v-on:validate="validate" placeholder="Enter BGG username" v-model="inputs.name" :error="is.error" :disabled="is.loading" :validation="validation.name"></core-form-input-textbox>
			
		<p v-if="is.syncing">Please wait while we sync this user's data, this may take some time...</p>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Confirm</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false,
				syncing: false
			},

			inputs: {
				name: ''
			},

			validation: {
				name: 'exists'
			}

		}

	},

	methods: {

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/groups/add', {
					user: this.inputs.name,
					name: this.$route.params.name
				}).then(function() {

					this.$store.dispatch('reverify').then(function() {

						this.$emit('close')
						window.location.reload()

					}.bind(this))

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')
			this.$pubsub.$emit('open.modal', 'groupEdit')

		}

	}

}

</script>