<template>

<core-filter-advanced-group title="Trades & sales" :count="count" v-if="isGroup && hasMe">

	<p>Match games or parts with other group members where your collection statuses align, for example if you wishlisted a game and another member has it for trade.</p>

	<core-filter-input-toggles :filter="value('trade/type')" text="Show me matches for" :values="type.values" :icons="type.icons" :labels="type.labels" />

	<core-filter-input-toggles v-if="values.trade.type === 'parts'" :filter="value('trade/status')" text="For parts I labelled as" :values="parts.values" :icons="parts.icons" :labels="parts.labels" />

	<core-filter-input-toggles v-if="values.trade.type === 'games'" :filter="value('trade/status')" text="For games I labelled as" :values="games.values" :icons="games.icons" :labels="games.labels" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			type: {
				values: [0, 'games', 'parts'],
				icons: ['', '', ''],
				labels: ['None', 'Games', 'Parts']
			},
			parts: {
				values: ['want', 'have'],
				icons: ['', ''],
				labels: ['Want', 'Have']
			},
			games: {
				values: ['want', 'have'],
				icons: ['', ''],
				labels: ['Want/Wish', 'For Trade']
			},
			fields: ['trade.type', 'trade.status']
		}
	}

}

</script>