<template>

<com-item name="size.sampled" :size="size" :loading="loading" title="Try Before You Buy">

	<template v-slot:about>

		<p>How many times do {{ you }} play a game before it gets added to {{ your }} collection.</p>

		<p class="small">Click a play count to view full list of games.</p>

	</template>

	<template v-slot:stats>

		<com-table :columns="['Plays Before', 'Games', 'Plays After', 'Still Own', 'Ratings']" class="grid">

			<com-row v-for="(sample, index) in data" :key="index">

				<com-cell type="link" :value="sample.name" v-on:click="onClick(index)" />
				<com-cell type="number" :value="sample.games" />
				<com-cell type="number" :value="sample.playavg" />
				<com-cell type="percent" :value="sample.own" />
				<com-cell type="rating" :value="sample.ratingavg" :count="sample.ratings" />

			</com-row>

		</com-table>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comTable from './../common/Table'
import comCell from './../common/table/Cell'
import comRow from './../common/table/Row'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-table': comTable,
		'com-row': comRow,
		'com-cell': comCell
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/size/sampled']

		},

		active: function() {

			return this.data && !this.loading

		}

	},

	methods: {

		onClick: function(index) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsSizeSampled',
				index: index
			})

		}

	}

}

</script>

<style scoped>

.grid >>> .row {
	grid-template-columns: 1fr 80px 80px 80px 80px;
}

</style>