<template>

<core-config-section>

	<h2>Automatic Expansion Nesting <small>Applies to all collections</small></h2>

	<p>If you want to hide expansions but still be able to view them if needed, you can turn on automatic nesting. This will put expansions under their base game and allow you to expand and collapse them. Player counts will also be merged and show you when an expansion changes a base game count. Other filters will only be applied to base games and not their expansions.</p>

	<div class="inputs">

		<core-filter-input-toggle text="Enable automatic nesting" on="Yes" off="No" filter="config/collection/nest" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>