<template>

<core-content-wrapper :loading="!is.ready">

	<core-filter :loading="is.fetching" :filter="insightName" v-on:refresh="onRefresh">

	</core-filter>

	<core-content-page v-if="is.reset && is.fetching !== 'all'">

		<core-content-page-inner v-show="is.ready" :is-grid="true">

			<com-overview :fetching="is.fetching" :your="your" :you="you" :size="3" />
			
			<com-distribution :fetching="is.fetching" :your="your" :you="you" :size="3" />

			<com-rank :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-plays :fetching="is.fetching" :your="your" :you="you" :size="3" />

			<com-year :fetching="is.fetching" :your="your" :you="you" :size="3" />

			<com-disparities :fetching="is.fetching" :your="your" :you="you" :size="3" />

			<com-weights :fetching="is.fetching" :your="your" :you="you" :size="3" />
 
			<com-activity :fetching="is.fetching" :your="your" :you="you" :size="6" />

			<com-types :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-designers :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-publishers :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-trending :fetching="is.fetching" :your="your" :you="you" :size="6" />

		</core-content-page-inner>

	</core-content-page>

</core-content-wrapper>

</template>

<script>

import Section from './common/Section.js'

import comOverview from './ratings/Overview'
import comDistribution from './ratings/Distribution'
import comPlays from './ratings/Plays'
import comRank from './ratings/Rank'
import comDisparities from './ratings/Disparities'
import comYear from './ratings/Year'
import comWeights from './ratings/Weights'
import comActivity from './ratings/Activity'
import comTypes from './ratings/Types'
import comDesigners from './ratings/Designers'
import comPublishers from './ratings/Publishers'
import comTrending from './ratings/Trending'

export default {

	mixins: [Section],

	components: {
		'com-overview': comOverview,
		'com-distribution': comDistribution,
		'com-plays': comPlays,
		'com-rank': comRank,
		'com-disparities': comDisparities,
		'com-year': comYear,
		'com-weights': comWeights,
		'com-activity': comActivity,
		'com-types': comTypes,
		'com-designers': comDesigners,
		'com-publishers': comPublishers,
		'com-trending': comTrending
	},

	data: function() {

		return {

			insightName: 'ratings',

			dynamicInsights: [
				'ranking.band',
				'type',
				'publishers',
				'year',
				'designers',
				'trending.direction'
			]

		}

	}

}

</script>