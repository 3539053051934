<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>{{ description}}</p>

	</template>

	<template v-slot:body>

		<app-user v-for="(item, index) in users" :subtext="subtext(item.applicable)" :item="item" v-bind:key="index" :dark="true">

			<core-progress :percent="item.ratio" />

		</app-user>

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'users',
			params: [
				'compareType'
			],
			users: []
		}

	},

	computed: {

		title: function() {

			return (this.data.compareType === 'most') ? 'Highest win %' : 'Lowest win %'

		},

		description: function() {

			return (this.data.compareType === 'most') ? 'Highest win percentages for users across all plays.' : 'Lowest win percentages for users across all plays.'

		}

	},

	methods: {

		response: function(json) {

			this.users = json.users

		},

		subtext: function(total) {

			return total + ' play' + ((total !== 1) ? 's' : '')

		}

	}

}

</script>