<template>

<core-list-row class="list-row" :id="item.id" :showInfo="window.width < 700">

	<template v-slot:fields>

		<core-list-cell type="avatar" :format="{}">

			<core-avatar type="game" :id="item.game.id" class="list-avatar">

				<app-avatar-include :id="item.game.id" filter="plays" />

			</core-avatar>

		</core-list-cell>

		<core-list-cell type="name" class="list-name" :format="{filter: 'plays', sort: 'game.name'}">

			<div class="list-name-game">
				<core-gamename :game="item.game" :id="item.id" class="list-name-game-name" />
			</div>

			<div class="list-name-location" v-if="item.play.location || item.play.comment">

				<template v-if="item.play.location">{{ item.play.location }}</template>
				<template v-if="item.play.location && item.play.comment"> &middot; </template>
				<div v-if="item.play.comment" v-on:click="onCommentClick">Read report</div>

			</div>

			<app-play-tags :item="item" />

		</core-list-cell>

		<core-list-cell type="date" :format="{filter: 'plays', sort: 'play.date'}">

			<core-date :date="item.play.date" />

		</core-list-cell>

		<core-list-cell type="stat" :empty="!item.play.length" v-if="window.width >= 700" :format="{filter: 'plays', sort: 'play.duration'}">

			{{ item.play.length }}

		</core-list-cell>

		<core-list-cell class="list-players" :empty="!item.players.length" v-if="window.width >= 700" :format="{filter: 'plays', sort: 'player.count'}">

			<app-play-players :item="item" :inline="true" />

		</core-list-cell>

		<core-list-cell-actions :item="item" filter="plays" />

	</template>

	<template v-slot:info>

		<app-play-players :item="item" :inline="true" v-if="item.players.length && window.width < 700" />

	</template>

</core-list-row>

</template>

<script>

import playTags from './play/Tags'
import playPlayers from './play/Players'

export default {

	props: ['item', 'ready'],

	components: {
		'app-play-players': playPlayers,
		'app-play-tags': playTags
	},

	methods: {

		onCommentClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('panel.open', {
				type: 'playsComment',
				game: this.item.game,
				play: this.item.play,
				comment: this.item.play.comment
			})

		}

	}

}

</script>

<style scoped>

.list-avatar {
	width: 48px;
	height: 48px;
}

.list-name {
	min-height: 48px;
}

.list-name-game {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	max-width: 100%;
}

.is-mobile .list-name-game {
	flex-direction: column;
}

.list-name-game-name {
	flex-grow: 1;
	overflow: hidden;
	min-width: 0;
	display: block;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	overflow: hidden;
	color: #333;
	font-weight: 500;
	order: 2;
}

.is-desktop .list-name-game-name:hover {
	text-decoration: underline;
}

.is-smaller .list-name-game-name,
.is-mobile .list-name-game-name {
	font-size: 14px;
}

.is-mobile .list-name-game-name {
	white-space: normal;
	overflow: visible;
}

.list-name-location {
	font-size: 11px;
	margin-top: 2px;
	color: #666;
}

.list-name-location div {
	display: inline;
	cursor: pointer;
	color: #4a7888;
}

.is-desktop .list-name-location div:hover {
	text-decoration: underline;
}

.list-location {
	width: 100px;
	padding: 0px 10px;
}

.list-players {
	width: 236px;
	padding: 0px 10px;
}

</style>