export default {

	namespaced: true,

	state: {

		period: 'custom',
		first: 0,
		group: 1,
		expansions: 1,
		date: {from: 0, to: 0},

		layout: {	
			title: 'Recently played',
			grid: 3,
			thumb: 128,
			corner: 0,
			spacing: 0,
			central: 0,
			nocrop: 0,
			show: {
				plays: {
					all: 0,
					context: 0,
					increment: 0
				},
				players: 0,
				first: 0,
				date: 0,
				game: 0,
				title: 0,
				user: 0,
				app: 0
			}
		},

		sort: 'play.date',
		sortReverse: 1

	},

	getters: {

		active: function(state) {

			return {
				'period': state.period !== 'custom',
				'first': state.first,
				'expansions': state.expansions,
				'group': !state.group,
				'date.from': state.date.from !== 0,
				'date.to': state.date.to !== 0
			}

		},

		values: function(state) {

			return state

		},

		'filter': function(state) {

			return {
				period: state.period,
				first: state.first,
				expansions: state.expansions,
				group: state.group,
				date: state.date,
				layout: {
					grid: state.layout.grid
				},	
				sort: state.sort,
				sortReverse: state.sortReverse
			}

		},

		'layout': function(state) {

			return state.layout

		},

		'layout/title': function(state) {

			return state.layout.title

		},

		'layout/grid': function(state) {

			return state.layout.grid

		},

		'layout/thumb': function(state) {

			return state.layout.thumb

		},

		'layout/corner': function(state) {

			return state.layout.corner

		},

		'layout/spacing': function(state) {

			return state.layout.spacing

		},

		'layout/central': function(state) {

			return state.layout.central

		},

		'layout/nocrop': function(state) {

			return state.layout.nocrop

		},

		'layout/show/user': function(state) {

			return state.layout.show.user

		},

		'layout/show/app': function(state) {

			return state.layout.show.app

		},

		'layout/show/date': function(state) {

			return state.layout.show.date

		},

		'layout/show/first': function(state) {

			return state.layout.show.first

		},

		'layout/show/plays': function(state) {

			return state.layout.show.plays.all || state.layout.show.plays.context || state.layout.show.plays.increment

		},

		'layout/show/plays/all': function(state) {

			return state.layout.show.plays.all

		},

		'layout/show/plays/context': function(state) {

			return state.layout.show.plays.context

		},

		'layout/show/plays/increment': function(state) {

			return state.layout.show.plays.increment

		},

		'layout/show/game': function(state) {

			return state.layout.show.game

		},

		'layout/show/players': function(state) {

			return state.layout.show.players

		},

		'layout/show/title': function(state) {

			return state.layout.show.title

		},

		'first': function(state) {

			return state.first

		},

		'expansions': function(state) {

			return state.expansions

		},

		'group': function(state) {

			return state.group

		},

		'period': function(state) {

			return state.period

		},

		'date': function(state) {

			return state.date

		},

		'date/from': function(state) {

			return state.date.from

		},

		'date/to': function(state) {

			return state.date.to

		},

		sort: function(state) {

			return state.sort

		},

		sortReverse: function(state) {

			return state.sortReverse

		}

	},

	mutations: {

		reset: function(state) {

			state.period = 'custom'
			state.first = 0
			state.group = 1
			state.date = {from: 0, to: 0}
			state.sort = 'play.date'
			state.sortReverse = 1

		},

		'layout/title': function(state, value) {

			state.layout.title = value

		},

		'layout/grid': function(state, value) {

			state.layout.grid = value

		},

		'layout/thumb': function(state, value) {

			state.layout.thumb = value

		},

		'layout/corner': function(state, value) {

			state.layout.corner = value

		},

		'layout/central': function(state, value) {

			state.layout.central = value

		},

		'layout/nocrop': function(state, value) {

			state.layout.nocrop = value

		},

		'layout/spacing': function(state, value) {

			state.layout.spacing = value

		},

		'layout/show/user': function(state, value) {

			state.layout.show.user = value

		},

		'layout/show/app': function(state, value) {

			state.layout.show.app = value

		},

		'layout/show/title': function(state, value) {

			state.layout.show.title = value

		},

		'layout/show/date': function(state, value) {

			state.layout.show.date = value

		},

		'layout/show/first': function(state, value) {

			state.layout.show.first = value

		},

		'layout/show/plays/all': function(state, value) {

			state.layout.show.plays.all = value

		},

		'layout/show/plays/context': function(state, value) {

			state.layout.show.plays.context = value

		},

		'layout/show/plays/increment': function(state, value) {

			state.layout.show.plays.increment = value

		},

		'layout/show/game': function(state, value) {

			state.layout.show.game = value

		},

		'layout/show/players': function(state, value) {

			state.layout.show.players = value

		},

		'first': function(state, value) {

			state.first = (value) ? 1 : 0

		},

		'expansions': function(state, value) {

			state.expansions = (value) ? 1 : 0

		},

		'group': function(state, value) {

			state.group = (value) ? 1 : 0

		},

		'period': function(state, value) {

			state.period = value

		},

		'date/from': function(state, value) {

			state.date.from = value

		},

		'date/to': function(state, value) {

			state.date.to = value

		},

		sort: function(state, id) {

			if (typeof id === 'object') {

				state.sortReverse = id.reverse
				state.sort = id.value

			} else {

				state.sortReverse = (state.sort === id) ? !state.sortReverse : false
				state.sort = id

			}

		}

	}

}