<template>

<core-filter-advanced title="Tags" :active="is.expanded" v-on:clickoutside="onOutsideClick">

	<template v-slot:inputs>

		<div class="tags-section">

			<div class="tags-title">Game tags</div>

			<app-filter-panel-tags-tag :tag="tag" v-for="tag in gameTags" :key="tag.id" />

			<div class="tags-title">{{ contextTitle }}</div>

			<app-filter-panel-tags-tag :tag="tag" v-for="tag in contextTags" :key="tag.id" />

		</div>

	</template>

	<template v-slot:buttons>

		<core-button v-on:click.native="onCreateClick" theme="blue" fluid="yes">Create tag</core-button>
		<core-button v-if="window.is.mobile" v-on:click.native="onOutsideClick" theme="blue" fluid="yes">Close tags</core-button>

	</template>

</core-filter-advanced>

</template>

<script>

export default {

	props: ['type'],

	data: function() {

		return {

			is: {
				expanded: false
			}

		}

	},

	created: function() {

		this.$pubsub.$on('filter.panel.tags.open', this.open.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('filter.panel.tags.open', this.open.bind(this))

	},

	computed: {

		active: function () {
		
			return this.is.expanded

		},

		contextTitle: function() {

			return this.$CONSTANTS.SERVICE.TITLE[this.$store.getters['service/select/name']] + ' tags'

		},

		gameTags: function() {

			return this.$_.where(this.$store.getters['data/tags/list'], {
				context: 0
			})

		},

		contextTags: function() {

			return this.$_.where(this.$store.getters['data/tags/list'], {
				context: this.$CONSTANTS.SERVICE.VALUE[this.$store.getters['service/select/name']]
			})

		}

	},

	methods: {

		open: function() {

			this.is.expanded = true

		},

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onCreateClick: function() {

			this.$store.dispatch('service/select/tags/create')

		}

	}

}

</script>

<style scoped>

.tags-section {
	padding: 0px!important;
}

.tags-title {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	padding: 15px!important;
}

</style>
