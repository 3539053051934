<template>
	
<core-list-cell class="language" :format="format" type="stat">

	<div class="language-band" :class="{[bandClass]: true}">{{ name }}</div>

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format'],

	computed: {

		name: function() {

			if (!this.item.game.language.count) return 'n/a'

			if (this.item.game.language.rating === 1) return 'Complete'
			if (this.item.game.language.rating === 2) return 'High'
			if (this.item.game.language.rating === 3) return 'Medium'
			if (this.item.game.language.rating === 4) return 'Low'
			if (this.item.game.language.rating === 5) return 'None'

			return false

		},

		bandClass: function() {

			if (!this.item.game.language.count) return 'is-empty'

			if (this.item.game.language.rating === 1) return 'is-all'
			if (this.item.game.language.rating === 2) return 'is-high'
			if (this.item.game.language.rating === 3) return 'is-medium'
			if (this.item.game.language.rating === 4) return 'is-low'
			if (this.item.game.language.rating === 5) return 'is-none'
			
			return false

		}

	}

}

</script>

<style scoped>

.language {
	font-size: 14px;
	line-height: 32px;
	font-weight: 300;
}

.language-band.is-empty {
	color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

.language-band.is-all {
	color: #249563;
}

.language-band.is-high {
	color: #2FC482;
}

.language-band.is-medium {
	color: #5369a2;
}

.language-band.is-low {
	color: #df4751;
}

.language-band.is-none {
	color: #db303b;
}

</style>
