<template>

<div class="input">

	<div class="input-text" v-bind:class="{'is-suggesting': suggestionCount, 'is-loading': is.loading}">

		<input type="text" v-on:keyup.enter="onEnterKey" v-on:keyup.up="onUpKey" v-on:keyup.down="onDownKey" v-on:keydown.tab="onTabKey" v-model="text" v-on:keyup="onKeyUp" :placeholder="placeholder" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" />

		<div class="input-suggestions" v-show="suggestionCount">

			<div class="input-suggestions-item" v-bind:class="{'is-selected': index === suggestionIndex}" v-for="(item, index) in options" v-bind:key="item.id" v-on:click.stop="onSuggestionClick(item.id)">
				{{ item.text }}
				<span class="input-suggestions-item-subtitle" v-if="item.subtitle">{{ item.subtitle }}</span>
			</div>

		</div>

	</div>

	<div class="input-selected" v-show="selectedCount">

		<div class="input-selected-item" v-for="item in selected" v-bind:key="item.id">

			<div class="input-selected-item-text">

				{{ item.text }}
				<span class="input-selected-item-subtitle" v-if="item.subtitle">{{ item.subtitle }}</span>

			</div>

			<div class="input-selected-item-toggle" v-if="toggle">
				<core-filter-input-toggle v-if="!hasRequire" on="Exclude" off="Include" :inline="true" :defaultValue="item.toggled" v-on:change="onToggle(item.id, $event)" />
				<core-filter-input-toggles v-if="hasRequire" :labels="['Mandatory', 'Include', 'Exclude']" :values="[2, 0, 1]" :icons="[false, false, false]" :inline="true" :defaultValue="item.toggled" v-on:change="onToggle(item.id, $event)" />
			</div>

			<div class="input-selected-item-remove" v-on:click.stop="onSelectedClick(item.id)">
				<i class="fa fa-minus-circle"></i>
			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: [
		'filter', 'placeholder', 'api', 'data', 'source', 'field', 'hasRequire', 'subtitle', 'toggle', 'exclude'
	],

	data: function() {

		return {
			is: {
				loading: false
			},
			text: '',
			suggestions: {},
			suggestionIndex: 0
		}

	},

	computed: {

		value: function() {

			return this.$store.getters['filter/' + this.filter]

		},

		selected: function() {

			var selected = []

			this.$_.each(this.value, function(id) {

				if (this.data) {

					var correctedId = Math.abs(id)

					var value = 0

					if (id.toString().indexOf('-') === 0) value = 1
					if (id.toString().indexOf('+') === 0) value = 2
					if (id.toString().indexOf(' ') === 0) value = 2

					if (this.$store.getters['data/' + this.data][correctedId]) {

						selected.push({
							id: correctedId,
							toggled: value,
							text: this.$store.getters['data/' + this.data][correctedId][this.field],
							subtitle: (this.subtitle) ? this.$store.getters['data/' + this.data][correctedId][this.subtitle] : false
						})

					}

				} else {

					selected.push({
						id: id,
						text: id,
						subtitle: false
					})

				}

			}.bind(this))

			selected = this.$_.sortBy(selected, 'text')

			return selected

		},

		selectedCount: function() {

			return this.value.length

		},

		options: function() {

			var options = []

			this.$_.each(this.suggestions, function(value, key) {

				options.push({
					id: key,
					text: (this.field) ? value[this.field] : key,
					subtitle: (this.subtitle) ? value[this.subtitle] : ''
				})

			}.bind(this))

			options = this.$_.sortBy(options, function(item) {

				return item.text.length

			})

			return options

		},

		suggestionCount: function() {

			return this.$_.keys(this.suggestions).length

		}

	},

	methods: {

		onToggle: function(id, e) {

			var value = id

			if (e === 2) value = '+' + id.toString()
			if (e === 1 || e === true) value = '-' + id.toString()

			this.$store.commit('filter/' + this.filter, value)

		},

		onEnterKey: function() {

			if (this.suggestionCount) {

				this.onSuggestionClick(this.options[this.suggestionIndex].id)

			}

		},

		onUpKey: function() {

			this.suggestionIndex = (this.suggestionIndex > 0) ? this.suggestionIndex - 1 : 0

		},

		onDownKey: function() {

			this.suggestionIndex = (this.suggestionIndex < this.suggestionCount - 1) ? this.suggestionIndex + 1 : this.suggestionCount - 1

		},

		onTabKey: function(e) {

			e.preventDefault()

			this.onDownKey()

		},

		onKeyUp: function(e) {

			e.preventDefault()

			if (this.$_.contains(['Enter', 'ArrowUp', 'ArrowDown', 'Tab'], e.code)) return false

			if (this.text.trim().length) {

				this.is.loading = true

				this.$store.dispatch('api/' + this.api, {
					source: (this.source) ? this.source : false,
					type: this.$store.getters['context/type'],
					name: this.$store.getters['context/slug'],
					exclude: this.value,
					text: this.text
				}).then(function(json) {

					this.is.loading = false

					this.suggestionIndex = 0
					this.suggestions = json.suggestions

				}.bind(this))

			} else {

				this.suggestions = {}

			}

		},

		onSuggestionClick: function(id) {

			this.$store.commit('filter/' + this.filter, id)

			this.text = ''
			this.suggestions = {}

		},

		onSelectedClick: function(id) {

			this.$store.commit('filter/' + this.filter, {
				id: id,
				remove: true
			})

		}

	}

}

</script>

<style scoped>

.input {
	position: relative;
	padding-bottom: 4px;
}

.input-text {
	height: 32px;
	background-repeat: no-repeat;
	background-position: calc(100% - 8px) 50%;
}

.input-text.is-loading {
	background-image: url(~core/assets/load-transparent.gif);
}

.input-text input {
	width: 100%;
	height: 32px;
	color: #fff;
	border-radius: 4px;
	padding: 0px 8px;
	font-weight: 400;
	font-size: 12px;
	background-color: rgba(255, 255, 255, 0.25);
}

.input-text input::placeholder {
	color: #fff;
}

.input-text.is-suggesting input {
	border-radius: 4px 4px 0px 0px;
}

.input-suggestions {
	position: absolute;
	top: 32px;
	background-color: #4881bb;
	width: 100%;
	z-index: 2;
	padding: 4px 0px;
	border-radius: 0px 0px 4px 4px;
	border-top: 1px solid #3395B5;
	max-height: 130px;
	overflow: auto;
}

.input-suggestions-item {
	padding: 4px 8px;
	color: #fff;
	line-height: 16px;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
}

.input-suggestions-item-subtitle {
	font-size: 10px;
	opacity: 0.5;
	margin-left: 4px;
}

.input-suggestions-item.is-selected,
.is-desktop .input-suggestions-item:hover {
	background-color: rgba(0, 0, 0, .125);
}

.input-selected {
	padding: 8px 0px 0px 0px;
}

.input-selected-item {
	color: #fff;
	padding: 4px 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 4px;
}

.input-selected-item-text {
	flex-grow: 1;
	line-height: 16px;
	font-weight: 400;
	font-size: 12px;
	padding-right: 8px;
}

.input-selected-item-subtitle {
	font-size: 10px;
	opacity: 0.5;
	margin-left: 4px;
}

.input-selected-item-toggle {
	flex-shrink: 0;
	margin-right: 8px;
	height: 24px;   
    min-width: 80px;
}

.input-selected-item-toggle >>> .input-toggle {
	position: relative!important;
	right: auto;
	top: auto;
}

.input-selected-item-remove {
	flex-shrink: 0;
	cursor: pointer;
}

.input-selected-item-remove .fa {
	line-height: 24px;
	opacity: 0.5;
}

.input-selected-item:nth-child(even) {
	background-color: rgba(255, 255, 255, .1);
}

.is-desktop .input-selected-item:hover {
	background-color: rgba(0, 0, 0, .125);
}

.is-desktop .input-selected-item:hover .fa {
	opacity: 1;
}

</style>
