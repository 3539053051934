<template>
	
<core-list-cell :format="format" type="stat" :condensed="condensed">

	{{ value }}

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format', 'index', 'condensed'],

	computed: {

		value: function() {

			return (this.item.game.version) ? this.item.game.version.year : this.item.game.published

		}

	}

}

</script>
