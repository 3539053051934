<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary :title="title" icon="users" :count="count" noun="user" :maximum="maximum" :loading="loading" />

	</core-filter-bar-left>

	<core-filter-bar-right>

		<core-filter-sort page="users" filter="users/sort"></core-filter-sort>

	</core-filter-bar-right>

</core-filter-bar>

</template>

<script>

export default {

	props: ['count', 'loading', 'maximum'],

	data: function() {

		return {

			is: {
				expanded: false
			},

			filterGroups: {},

			filterCounts: {},

			totalFilterCount: 0

		}

	},

	computed: {

		title: function() {
		
			return (this.isGroup) ? 'Members' : 'Buddies'

		},

		filter: function() {

			return this.$store.getters['filter/users']

		}

	},

	watch: {

		filter: {

			handler: function() {

				this.updateCounts()

			},
			deep: true

		}

	},

	created: function() {

		this.updateCounts()

	},

	methods: {

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

		},

		updateCounts: function() {

			this.totalFilterCount = 0

			this.$_.each(this.filterGroups, function(filters, key) {

				var count = 0

				this.$_.each(filters, function(filter) {

					count += this.$store.getters['filter/users/active'][filter]

				})

				this.filterCounts[key] = count

				this.totalFilterCount += count

			}.bind(this))

		},

		onResetClick: function() {

			this.$store.commit('filter/users/reset')

		}

	}

}

</script>

<style scoped>

</style>
