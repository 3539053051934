<template>

<div class="streak" :class="{'is-dark': dark}">

	<div class="streak-info">

		<div class="streak-info-games">

			<core-avatar type="game" :id="game" v-for="(game, index) in games(item.games)" :tooltip="true" v-bind:key="index" class="streak-info-games-item" />

			<div class="streak-info-games-more streak-info-games-item" v-tooltip="'Click to view games'" v-on:click="onMoreClick" v-if="item.games.length > maxGames">+{{ item.games.length - maxGames }}</div>

		</div>

		<div class="streak-info-date">
			<template v-if="item.start">
				{{ item.start | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}<template v-if="item.start !== item.end"> - {{ item.end| formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}</template>
			</template>
			<template v-if="!item.end">
				{{ item.end | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}
			</template>
		</div>

	</div>

	<div class="streak-count">

		<div class="streak-count-number" :data-type="type">

			<div class="streak-count-number-icon">

				<i class="fa fa-plus" v-if="type === 'added'" />
				<i class="fa fa-minus" v-if="type === 'removed'" />

			</div>

			<div class="streak-count-number-total">{{ item.total }}</div>
			
		</div>
		
		<small><plural :n="item.total" t="game" /></small>

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'dark', 'type'],

	computed: {

		maxGames: function() {

			return (this.window.is.smalldesktop || this.window.is.tablet) ? 5 : 9

		}

	},

	methods: {

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsSizeCluster',
				index: this.item.index
			})

		},

		games: function(games) {

			return games.slice(0, this.maxGames)

		}

	}

}

</script>

<style scoped>

.streak {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 64px;
	padding: 8px 15px 8px 15px;
}

@media only screen and (max-width: 767px) {

	.streak {
		padding: 8px 8px;
		height: auto;
	}

}

.streak:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.streak.is-dark:nth-child(odd) {
	background-color: rgba(255, 255, 255, 0.05);
}

.streak-info {
	flex-grow: 1;
	flex-direction: column;
	display: flex;
	justify-content: center;
	min-width: 0;
}

.streak-info-name {
	font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #333;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.streak.is-dark .streak-info-name {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.streak-info-name {
		font-size: 14px;
	}

}

.streak-info-date {
	font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    color: #666;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 2px;
}

.streak.is-dark .streak-info-date {
	color: #fff;
}

.streak-info-date.is-large {
	font-size: 14px;
	line-height: 14px;
}

.streak-info-summary {
	font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    color: #666;
    margin-top: 4px;
}

.streak.is-dark .streak-info-summary {
	color: rgba(255, 255, 255, 0.75);
}

.streak-info-games {
	height: 24px;
	display: flex;
	margin-bottom: 4px;
	flex-direction: row;
}

.streak-info-games-item {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	margin-right: 2px;
	flex-shrink: 0;
}

.streak-info-games-more {
	display: inline-block;
	background-color: #999;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.is-desktop .streak-info-games-more:hover {
	background-color: #666;
}

.streak-count {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	width: 48px;
    flex-shrink: 0;
    height: 48px;
}

.streak-count-number {
	display: flex;
	align-items: center;
    color: #333;
}

.streak-count-number[data-type="added"] {
	color: #2FC482;
}

.streak-count-number[data-type="removed"] {
	color: #df4751;
}

.streak-count-number-icon {
    font-size: 12px;
	margin-right: 2px;
}

.streak-count-number-total {
    font-size: 24px;
    font-weight: 500;
}

.streak.is-dark .streak-count-number-total {
	color: #fff;
}

.streak-count small {
    display: block;
	font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    color: #666;
    margin-top: 2px;
}

.streak.is-dark .streak-count small {
	color: rgba(255, 255, 255, 0.75);
}

@media only screen and (max-width: 767px) {

	.streak-count {
		font-size: 18px;
	}

}

</style>