<template>

<core-content-wrapper :loading="!is.ready">

	<core-filter :loading="is.fetching" :filter="insightName" v-on:refresh="onRefresh">

	</core-filter>

	<core-content-page v-if="is.reset && is.fetching !== 'all'">

		<core-content-page-inner v-show="is.ready" :is-grid="true">

			<com-overview :fetching="is.fetching" :your="your" :you="you" :size="3" />
			
			<com-recent :fetching="is.fetching" :your="your" :you="you" :size="3" />
			
			<com-activity :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-longest :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-after :fetching="is.fetching" :your="your" :you="you" :size="3" />
			
			<com-cumulative :fetching="is.fetching" :your="your" :you="you" :size="3" />

		</core-content-page-inner>

	</core-content-page>

</core-content-wrapper>

</template>

<script>

import Section from './common/Section.js'

import comActivity from './shelf/Activity'
import comLongest from './shelf/Longest'
import comOverview from './shelf/Overview'
import comAfter from './shelf/After'
import comCumulative from './shelf/Cumulative'
import comRecent from './shelf/Recent'

export default {

	mixins: [Section],

	components: {
		'com-longest': comLongest,
		'com-activity': comActivity,
		'com-overview': comOverview,
		'com-after': comAfter,
		'com-cumulative': comCumulative,
		'com-recent': comRecent
	},

	data: function() {

		return {

			insightName: 'shelf',

			dynamicInsights: [
				'longest',
				'cumulative.who',
				'cumulative.period'
			]

		}

	}

}

</script>