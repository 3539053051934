import Vue from 'vue'
import Router from 'vue-router'

import Profile from './components/screen/Profile'
import profileSync from './components/screen/profile/Sync'
import profileCollection from './components/screen/profile/Collection'
import profileActivity from './components/screen/profile/Activity'
import profileUsers from './components/screen/profile/Users'
import profilePlays from './components/screen/profile/Plays'
import profileRatings from './components/screen/profile/Ratings'

import profileInsightsPlays from './components/screen/profile/insights/Plays'
import profileInsightsRatings from './components/screen/profile/insights/Ratings'
import profileInsightsCollection from './components/screen/profile/insights/Collection'
import profileInsightsShelf from './components/screen/profile/insights/Shelf'
import profileInsightsSize from './components/screen/profile/insights/Size'
import profileInsightsMembers from './components/screen/profile/insights/Members'
import profileInsightsRecords from './components/screen/profile/insights/Records'
import profileInsightsWorth from './components/screen/profile/insights/Worth'

import profileLists from './components/screen/profile/Lists'
import profileList from './components/screen/profile/List'

import profileTools from './components/screen/profile/Tools'
import profileToolPlayGrid from './components/screen/profile/tool/PlayGrid'
import profileToolHeatmap from './components/screen/profile/tool/Heatmap'
import profileToolWordCloud from './components/screen/profile/tool/WordCloud'

import Home from './components/screen/Home'
import Verify from './components/screen/Verify'
import Reset from './components/screen/Reset'
import Notfound from './components/screen/404'
import ErrorEncounted from './components/screen/Error'

import sessionPerks from './components/screen/session/Perks'
import sessionSettings from './components/screen/session/Settings'
import sessionSyncs from './components/screen/session/Syncs'

import logoutGuard from './router/logout.js'

import qs from 'qs'

Vue.use(Router)

export default new Router({
	mode: 'history',
	stringifyQuery: function(query) {
		let result = qs.stringify(query, { format: 'RFC1738', arrayFormat: true })
		result = result.replace(/%2C/g, ',')
		result = result.replace(/%2B/g, '+')
		return result ? ('?' + result) : ''
	},
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home,
			meta: {
				title: 'Home',
				isStandalone: true
			}
		},
		{
			path: '/404',
			name: '404',
			component: Notfound,
			meta: {
				title: 'Page not found',
				isStandalone: true
			}
		},
		{
			path: '/error',
			name: 'Error',
			component: ErrorEncounted,
			meta: {
				title: 'Error',
				isStandalone: true
			}
		},
		{
			path: '/logout',
			name: 'Logout',
			component: Home,
			meta: {
				title: 'Logout',
				requireUser: true,
				isStandalone: true
			},
			beforeEnter: logoutGuard
		},
		{
			path: '/verify/:key',
			name: 'Verify',
			component: Verify,
			meta: {
				title: 'Verify',
				requireGuest: true,
				isStandalone: true
			}
		},
		{
			path: '/reset/:key',
			name: 'Reset',
			component: Reset,
			meta: {
				title: 'Reset',
				requireGuest: true,
				isStandalone: true
			}
		},
		{
			path: '/settings',
			name: 'SessionSettings',
			component: sessionSettings,
			meta: {
				title: 'Settings',
				requireUser: true,
				isSession: true
			}
		},
		{
			path: '/syncs',
			name: 'SessionSyncs',
			component: sessionSyncs,
			meta: {
				title: 'Syncs',
				requireUser: true,
				isSession: true
			}
		},
		{
			path: '/perks',
			name: 'SessionPerks',
			component: sessionPerks,
			meta: {
				title: 'Tipjar',
				requireUser: true,
				isSession: true
			}
		},
		{
			path: '/:type/:name',
			component: Profile,
			children: [
				{
					path: '',
					name: 'Default',
					component: profileCollection,
					meta: {
						title: 'Collection',
						search: {
							store: 'filter/collection/text',
							placeholder: 'Search collection...'
						}
					}
				},
				{
					path: 'sync',
					name: 'Sync',
					component: profileSync,
					meta: {
						title: 'Sync',
						isStandalone: true
					}
				},
				{
					path: 'collection',
					name: 'Collection',
					component: profileCollection,
					meta: {
						title: 'Collection',
						search: {
							store: 'filter/collection/text',
							placeholder: 'Search collection...'
						}
					}
				},
				{
					path: 'activity',
					name: 'Activity',
					component: profileActivity,
					meta: {
						title: 'Activity'
					}
				},
				{
					path: 'users',
					name: 'Users',
					component: profileUsers,
					meta: {
						title: 'Users'
					}
				},
				{
					path: 'ratings',
					name: 'Ratings',
					component: profileRatings,
					meta: {
						title: 'Ratings',
						search: {
							store: 'filter/ratings/text',
							placeholder: 'Search ratings...'
						}
					}
				},
				{
					path: 'lists',
					name: 'Lists',
					component: profileLists,
					meta: {
						title: 'Lists'
					}
				},
				{
					path: 'lists/:list/:edition',
					name: 'Edition',
					component: profileList,
					meta: {
						title: 'List'
					}
				},
				{
					path: 'lists/:list',
					name: 'List',
					component: profileList,
					meta: {
						title: 'List'
					}
				},
				{
					path: 'plays',
					name: 'Plays',
					component: profilePlays,
					meta: {
						title: 'Plays',
						search: {
							store: 'filter/plays/text',
							placeholder: 'Search plays...'
						}
					}
				},
				{
					path: 'tools',
					name: 'Tools',
					component: profileTools,
					meta: {
						title: 'Tools'
					}
				},
				{
					path: 'tools/play-grid',
					name: 'ToolsPlayGrid',
					component: profileToolPlayGrid,
					meta: {
						title: 'Play Grid | Tools'
					}
				},
				{
					path: 'tools/wordcloud',
					name: 'ToolsWordCloud',
					component: profileToolWordCloud,
					meta: {
						title: 'Word Cloud | Tools'
					}
				},
				{
					path: 'tools/heatmap',
					name: 'ToolsHeatmap',
					component: profileToolHeatmap,
					meta: {
						title: 'Heatmap | Tools'
					}
				},
				{
					path: 'insights',
					name: 'Insights',
					component: profileInsightsCollection,
					meta: {
						title: 'Collection | Insights',
						section: 'collection',
						subtitle: 'Collection'
					}
				},
				{
					path: 'insights/collection/shelf',
					name: 'InsightsShelf',
					component: profileInsightsShelf,
					meta: {
						title: 'Shelf of Shame | Insights',
						section: 'shelf',
						parenttitle: 'Collection',
						subtitle: 'Shelf of Shame'
					}
				},
				{
					path: 'insights/collection/worth',
					name: 'InsightsWorth',
					component: profileInsightsWorth,
					meta: {
						title: 'Collection Worth | Insights',
						section: 'worth',
						parenttitle: 'Collection',
						subtitle: 'Worth'
					}
				},
				{
					path: 'insights/collection/size',
					name: 'InsightsSize',
					component: profileInsightsSize,
					meta: {
						title: 'Collection Size | Insights',
						section: 'size',
						parenttitle: 'Collection',
						subtitle: 'Size'
					}
				},
				{
					path: 'insights/plays',
					name: 'InsightsPlays',
					component: profileInsightsPlays,
					meta: {
						title: 'Plays | Insights',
						section: 'plays',
						subtitle: 'Plays'
					}
				},
				{
					path: 'insights/collection',
					name: 'InsightsCollection',
					component: profileInsightsCollection,
					meta: {
						title: 'Collection | Insights',
						section: 'collection',
						subtitle: 'Collection'
					}
				},
				{
					path: 'insights/ratings',
					name: 'InsightsRatings',
					component: profileInsightsRatings,
					meta: {
						title: 'Ratings | Insights',
						section: 'ratings',
						subtitle: 'Ratings'
					}
				},
				{
					path: 'insights/members',
					name: 'InsightsMembers',
					component: profileInsightsMembers,
					meta: {
						title: 'Members | Insights',
						section: 'members',
						subtitle: 'Members'
					}
				},
				{
					path: 'insights/plays/streaks-and-records',
					name: 'InsightsRecords',
					component: profileInsightsRecords,
					meta: {
						title: 'Play Streaks & Records | Insights',
						section: 'records',
						parenttitle: 'Plays',
						subtitle: 'Streaks & Records'
					}
				}
			],
			meta: {
				requireVerify: true,
				isProfile: true
			}
		}
	]
})
