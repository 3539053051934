<template>

<com-item name="collection.status" :is-list="true" :size="size" :loading="loading" :is-empty="!active" title="Status">

	<template v-slot:about>

		<p>The value of {{ your }} entire collection.</p>

	</template>

	<template v-slot:stats>

		<div class="status-item" v-for="item in status" :key="item.id" :class="{[statusClass(item)]: true}" v-on:click="onStatusClick(item.id)">

			<div class="status-item-name">{{ $CONSTANTS.GAME.STATUS.NAME[item.id] }}</div>

			<div class="status-item-bar">
				<div class="status-item-bar-percent" :style="{width: statusWidth(item)}"></div>
			</div>

			<div class="status-item-count">{{ item.count }}</div>

		</div>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		status: function() {

			return this.$store.getters['page/insights/collection/status']

		},

		active: function() {

			return this.status && !this.loading

		}

	},

	methods: {

		onStatusClick: function(id) {

			var query = {}

			if (id !== 'own') {

				query['status.own'] = 0
				query['status.' + id] = 1

			}

			this.$router.push({
				name: 'Collection',
				query: query
			}).catch(function(e) { console.log(e) })

		},

		statusWidth: function(item) {

			var max = this.$_.max(this.status, function(status) { return status.count }).count

			return ((100 / max) * item.count) + '%'

		},

		statusClass: function(item) {

			return 'is-' + item.id

		}

	}

}

</script>

<style scoped>

.status {
	padding: 0px!important;
}

.status-wrapper {
	width: 100%;
	height: 320px;
}

.status-item {
	height: 32px;
	width: 100%;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 0px 15px;
}

.status-item:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.status-item-name {
	width: 80px;
	font-weight: 300;
	color: #333;
	font-size: 14px;
	line-height: 32px;
}

.is-desktop .status-item:hover .status-item-name {
	font-weight: 500;
}

.status-item-count {
	font-weight: 300;
	color: #333;
	line-height: 32px;
	font-size: 14px;
	width: 32px;
	text-align: right;
}

.status-item-bar {
	flex-grow: 1;
	padding: 0px 15px;
	height: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.status-item-bar-percent {
	height: 8px;
	width: 0%;
	border-radius: 4px;
}

.status-item.is-own .status-item-bar-percent {
	background-color: rgba(4, 108, 143, 0.9);
}

.status-item.is-prevowned .status-item-bar-percent {
	background-color: rgba(4, 108, 143, 0.7);
}

.status-item.is-fortrade .status-item-bar-percent {
	background-color: rgba(4, 108, 143, 0.5);
}

.status-item.is-want .status-item-bar-percent {
	background-color: rgba(4, 143, 85, 0.9);
}

.status-item.is-wanttoplay .status-item-bar-percent {
	background-color: rgba(4, 143, 85, 0.7);
}

.status-item.is-wanttobuy .status-item-bar-percent {
	background-color: rgba(4, 143, 85, 0.5);
}

.status-item.is-wishlist .status-item-bar-percent {
	background-color: rgba(143, 36, 4, 0.7);
}

.status-item.is-preordered .status-item-bar-percent {
	background-color: rgba(143, 36, 4, 0.9);
}

.status-item.is-hasparts .status-item-bar-percent {
	background-color: rgba(4, 108, 143, 0.5);
}

.status-item.is-wantparts .status-item-bar-percent {
	background-color: rgba(4, 143, 85, 0.5);
}

</style>