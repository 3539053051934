<template>

<div class="suggestions" v-bind:class="{'is-suggesting': is.suggesting && changed, 'is-active': is.active, 'is-loading': is.loading}" v-click-outside="onCloseClick">
	
	<div class="head">

		<div class="head-title">Search</div>

		<div class="head-close" v-tooltip="'Close'" v-on:click.stop="onCloseClick"><i class="fa fa-remove"></i></div>

	</div>

	<div class="suggestions-list" v-if="suggestions.length">

		<core-simplebar class="suggestions-list-scroll">

			<div class="suggestions-item" v-for="game in suggestions" v-bind:key="game.id">
				
				<div class="suggestion-item-name">{{ game.name }}</div>
				
				<div class="suggestion-item-options" v-bind:class="{'is-saving': is.saving === game.id}">
					<i class="fa fa-plus-circle" v-on:click="onAddClick($event, game.id)" v-if="!game.exists"></i>
					<i class="fa fa-minus-circle" v-on:click="onRemoveClick($event, game.id)" v-if="game.exists"></i>
				</div>

			</div>

		</core-simplebar>

	</div>

	<div class="suggestions-empty" v-if="!suggestions.length">

		<span>No games found.</span>

	</div>

	<div class="suggestions-search">

		<core-filter-input-text filter="list/text" ></core-filter-input-text>

	</div>

	<div class="suggestions-foot">

		<core-button v-on:click.native="onCloseClick" theme="blue" fluid="yes" class="suggestions-foot-button" v-if="window.is.mobile">Close search</core-button>

	</div>

</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	components: {
		
	},

	data: function() {

		return {
			suggestions: [],
			is: {
				suggesting: false,
				saving: false,
				loading: false,
				active: false
			}
		}

	},

	computed: {

		active: function() {
		
			return this.is.active

		},

		filter: function() {

			return this.$store.getters['filter/list']

		},

		changed: function() {

			return this.$store.getters['filter/list/changed']

		}

	},

	watch: {

		filter: {

			handler: function() {

				if (this.is.active && this.changed) {

					this.is.loading = true

					this.$store.dispatch('api/list/suggest', {
						filter: this.filter
					}).then(function(json) {

						this.suggestions = json.suggestions
						this.is.loading = false
						this.is.suggesting = true

						this.$pubsub.$emit('list.import.suggestions.count', this.suggestions.length)

					}.bind(this), function() {

						this.is.loading = false

					}.bind(this))

				}

			},
			deep: true

		},

		active: function(n) {

			if (n) this.$pubsub.$emit('panel.opened')
			if (!n) this.$pubsub.$emit('panel.closed')

			if (this.window.is.mobile) {

				if (n) this.$pubsub.$emit('open.dropdown')
				if (!n) this.$pubsub.$emit('close.dropdown')

			}

		}

	},

	created: function() {

		this.$pubsub.$on('list.search.expand', this.onListSearchExpand.bind(this))
		this.$pubsub.$on('list.search.close', this.onListSearchClose.bind(this))
		this.$pubsub.$on('list.refreshed', this.onListRefreshed.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('list.search.expand', this.onListSearchExpand.bind(this))
		this.$pubsub.$off('list.search.close', this.onListSearchClose.bind(this))
		this.$pubsub.$off('list.refreshed', this.onListRefreshed.bind(this))

	},

	methods: {

		onListSearchExpand: function() {

			this.is.active = true

		},

		onListSearchClose: function() {

			this.close()

		},

		onListRefreshed: function() {

			this.is.saving = false

		},

		onAllClick: function() {

			this.add(this.$_.pluck(this.suggestions, 'id'))

			this.$pubsub.$emit('list.import.close')

		},

		onAddClick: function(e, id) {

			this.is.saving = id

			this.add([id])

		},

		onCloseClick: function() {

			this.close()

		},

		close: function() {

			if (this.is.active) {

				this.$store.commit('filter/list/reset')
				this.is.active = false

			}

		},

		add: function(games) {

			this.$store.dispatch('api/list/add', {
				games: games
			}).then(function() {

				this.$pubsub.$emit('list.refresh')

				this.$_.each(this.suggestions, function(item) {

					if (this.$_.contains(games, item.id)) item.exists = true

				}.bind(this))

			}.bind(this), function() {

				this.is.saving = false

			}.bind(this))

		},

		onRemoveClick: function(e, id) {

			this.is.saving = id

			this.$store.dispatch('api/list/remove', {
				games: [id]
			}).then(function() {

				this.$pubsub.$emit('list.refresh')

				this.$_.each(this.suggestions, function(item) {

					if (item.id === id) item.exists = false

				})

				this.is.saving = false

			}.bind(this), function() {

				this.is.saving = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>


.head {
	z-index: 2;
	flex-shrink: 0;
	height: 88px;
	display: flex;
	align-items: center;
	padding: 20px;
	margin-bottom: 20px;
    background-color: #19455c;
}

.is-smaller .head {
	height: 64px;
}

.is-mobile .head {
	height: 48px;
	padding: 0px 10px;
}

.head:after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #19455c transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    left: 17px;
    bottom: -10px;
}

.head-title {
	text-align: left;
	font-size: 20px;
	color: #fff;
	font-weight: 500;
	line-height: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.is-mobile .head-title {
	font-size: 16px;
}

.head-close {
	position: absolute;
	right: 20px;
	top: 24px;
	height: 40px;
	width: 40px;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.25);
	color: #fff;
	line-height: 40px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	z-index: 3;
}

.is-smaller .head-close {
	top: 12px;
}

.is-mobile .head-close {
	top: 8px;
	width: 32px;
	height: 32px;
	right: 10px;
	line-height: 32px;
	font-size: 16px;
}

.is-desktop .head-close:hover,
.is-smaller .head-close:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.suggestions {
	position: fixed;
	right: -320px;
	width: 320px;
	top: 64px;
	background-color: #035874;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	transition: right 100ms linear;
}

.suggestions.is-active {
	right: 0px;
	z-index: 1002;
}

.suggestions-head {
	height: 40px;
	line-height: 40px;
	flex-shrink: 0;
	border-bottom: 1px solid #046E8F;
}

.suggestions-list {
	flex-grow: 1;
	overflow: hidden;
}

.suggestions-list-scroll {
	height: 100%;
	overflow-x: hidden;
}

.suggestions-search {
	flex-shrink: 0;
	padding: 15px;
	border-top: 1px solid #046E8F;
}

@media only screen and (max-width: 767px) {
	
	.suggestions-search {
		padding: 8px 8px 8px 8px;
	}

}

.suggestions-foot {
	flex-shrink: 0;
	padding: 7px 15px 15px 15px;
	border-top: 1px solid #046E8F;
}

.suggestions-foot-button {
	margin-top: 8px;
}

@media only screen and (max-width: 767px) {
	
	.suggestions-foot {
		padding: 0px 8px 8px 8px;
	}

}

.suggestions.is-loading .suggestions-list,
.suggestions.is-loading .suggestions-empty {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	pointer-events: none;
	background-image: url(~core/assets/load-transparent.gif);
}

.suggestions.is-loading .suggestions-list > *,
.suggestions.is-loading .suggestions-empty > * {
	opacity: 0;
}

.suggestions h1 {
	color: #fff;
	font-size: 16px;
	padding-left: 15px;
}

@media only screen and (max-width: 767px) {

	.suggestions h1 {
		padding-left: 8px;
	}

}

.suggestions-head-count {
	color: rgba(255, 255, 255,  0.5);
	font-size: 12px;
	margin-left: 5px;
}

.suggestions-item {
	color: #fff;
	padding: 7px 15px;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.suggestions-item {
		padding: 7px 8px;
	}

}

.is-desktop .suggestions-item:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.suggestion-item-name {
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.suggestion-item-options {
	flex-shrink: 0;
	width: 18px;
}

.suggestion-item-options.is-saving {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	pointer-events: none;
	background-image: url(~core/assets/load-transparent.gif);
}

.suggestion-item-options.is-saving > * {
	opacity: 0;
}

.suggestion-item-options .fa {
	width: 18px;
	line-height: 18px;
	height: 18px;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
}

.suggestions-empty {
	font-size: 14px;
	flex-grow: 1;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	line-height: 18px;
	color: rgba(255, 255, 255, 0.5);
	padding: 0px 15px;
}

</style>