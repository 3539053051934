<template>

<com-item name="ratings.trending" :is-cards="true" :has-more="true" v-on:more="onMoreClick" :size="size" :loading="loading" title="Trending" v-if="active">

	<template v-slot:about>

		<p>{{ your | capitalise }} biggest changes in rating over time.</p>

		<p class="small" v-if="!isGroup">Tracking began from day {{ you }} were first imported into geekGroup.</p>
		
		<p class="small" v-if="isGroup">Tracking began from day group was created.</p>

	</template>

	<template v-slot:stats>

		<com-card :item="item" :date="item.rating.date" v-for="item in data" :key="item.id" :show-revisions="true" :show-disparity="true" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="trendingOptions" :value="trendingValue" v-on:change="onTrendingChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'
import comCard from './../common/Card'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle,
		'com-card': comCard
	},

	computed: {

		trendingOptions: function() {

			var options = []

			options.push({value: 'up', text: 'Trending up'})
			options.push({value: 'down', text: 'Trending down'})

			return options

		},

		trendingValue: function() {

			return this.$store.getters['filter/insights/ratings/trending/direction']

		},

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'trending.direction' 

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/trending']

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {
	
		onTrendingChange: function(value) {

			this.$store.commit('filter/insights/ratings/trending/direction', value)

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsTrending',
				filter: this.$store.getters['filter/insights/ratings']
			})

		}
	
	}

}

</script>

<style scoped>

</style>