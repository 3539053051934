<template>
	
<div class="award">

	<core-avatar :type="type" :id="id" class="award-badge" v-if="id" />
	<div class="award-badge is-guest" v-if="!id"><i class="fa fa-user"></i></div>

	<div class="award-text">

		<div class="award-text-winner">{{ winner }}<span v-if="ties" class="award-text-winner-ties"> +{{ ties }} <plural :n="ties" t="other" /></span></div>
		<div class="award-text-award">{{ award }}</div>
		<div class="award-text-caveat" v-if="caveat">{{ caveat }}</div>

	</div>

	<div class="award-value" v-if="value !== false">

		{{ value | round(0) }}<small v-if="percent">%</small>

	</div>

</div>

</template>

<script>

export default {

	props: ['type', 'id', 'award', 'winner', 'value', 'percent', 'caveat', 'ties']

}

</script>

<style scoped>

.award {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 10px;
}

.award:last-child {
	margin-bottom: 0px;
}

.award-badge {
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	margin-right: 10px;
	border-radius: 24px;
	background-color: #eee;
	font-size: 18px;
}

.award-badge.is-guest {
	line-height: 48px;
	text-align: center;
	background-color: #ccc;
	color: #999;
}

.award-text {
	flex-grow: 1;
	min-height: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.award-text-winner {
	font-weight: 500;
	font-size: 14px;
	color: #333;
	margin-bottom: 2px;
}

.award-text-winner-ties {
	opacity: 0.5;
	font-size: 12px;
}

.award-text-award {
	font-weight: 300;
	font-size: 12px;
	color: #333;
}

.award-text-caveat {
	margin-top: 2px;
	font-size: 10px;
	color: #777;
}

.award-value {
	line-height: 48px;
    font-weight: 500;
    color: #666;
    font-size: 24px
}

.award-value small {
	font-size: 12px;
}

</style>
