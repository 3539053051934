<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ year | abs }}<template v-if="year < 0">BC</template></h1>

		<p>There are <b>{{ games.length }}</b> plays for games published in this year.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="item in games" :item="item" v-bind:key="item.id" :subtext="item.subtext" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'year',
			params: [
				'year'
			],
			games: [],
			year: ''
		}

	},

	methods: {

		response: function(json) {

			this.year = json.year
			this.games = json.games

		}

	}

}

</script>