<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary title="Ratings" icon="star" :count="count" noun="result" :maximum="maximum" :loading="loading" />

	</core-filter-bar-left>

	<core-filter-bar-right>

		<core-filter-sort page="ratings" filter="ratings/sort" />

		<core-filter-layout page="ratings" filter="config/ratings/layout" />
		
		<core-filter-panel filter="ratings" v-on:expandClick="onExpandClick" />

		<core-filter-presets type="ratings" :hideButton="window.width < 400" />
		
		<core-filter-actions type="ratings">

			<core-dropdown-item type="link" v-if="window.width < 400" v-on:click.native.stop="$pubsub.$emit('presets.open')">Presets</core-dropdown-item>

		</core-filter-actions>

	</core-filter-bar-right>

	<core-filter-advanced :active="is.expanded" v-on:clickoutside="onOutsideClick">

		<template v-slot:inputs>

			<core-filter-input-text filter="collection/text" :inline="true" />

			<core-filter-advanced-title>Ratings</core-filter-advanced-title>

			<app-filter-template-ratings-user filter="ratings" />

			<app-filter-template-ratings-group filter="ratings" />

			<app-filter-template-collection-community filter="ratings" />

			<app-filter-template-collection-comments filter="ratings" />

			<app-filter-template-common-tags filter="ratings" />

			<core-filter-advanced-title>Games</core-filter-advanced-title>

			<app-filter-template-games-general filter="ratings" />

			<app-filter-template-games-count filter="ratings" />
			
			<app-filter-template-games-include filter="ratings" />

			<app-filter-template-games-exclude filter="ratings" />

			<core-filter-advanced-title>Collection</core-filter-advanced-title>

			<app-filter-template-collection-general filter="ratings" />

			<core-filter-advanced-title>Plays</core-filter-advanced-title>

			<app-filter-template-ratings-plays filter="ratings" :range="true" />

			<core-filter-advanced-title>Other</core-filter-advanced-title>

			<app-filter-template-common-users filter="ratings" />

			<app-filter-template-common-designers filter="ratings" />
			
			<app-filter-template-common-artists filter="ratings" />
			
			<app-filter-template-common-publishers filter="ratings" />
			
			<app-filter-template-common-categories filter="ratings" />
			
			<app-filter-template-common-mechanics filter="ratings" />
			
			<app-filter-template-common-subdomains filter="ratings" />

			<app-filter-template-common-families filter="ratings" />

		</template>

		<template v-slot:buttons>

			<core-button v-on:click.native="onResetClick" theme="blue" fluid="yes">Reset filter</core-button>

			<core-button v-if="window.is.mobile" v-on:click.native="onOutsideClick" theme="blue" fluid="yes">Close filter</core-button>

		</template>

	</core-filter-advanced>

	<app-filter-panel-tags type="ratings" />

</core-filter-bar>

</template>

<script>

export default {

	props: ['count', 'loading', 'maximum'],

	data: function() {

		return {

			is: {
				expanded: false
			}

		}

	},

	computed: {

		filter: function() {

			return this.$store.getters['filter/ratings']

		}

	},

	methods: {

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded
			
			if (this.is.expanded) {

				this.$pubsub.$emit('filter.opened')

			}

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onResetClick: function() {

			this.$store.commit('filter/ratings/reset', {
				layout: this.filter.layout
			})

		}

	}

}

</script>

<style scoped>

</style>
