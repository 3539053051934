<template>

<com-item :name="'members.divisive.' + type" :has-more="true" v-on:more="onMoreClick" :size="size" :is-list="true" :loading="loading" :is-empty="!active" :title="title[type]">

	<template v-slot:stats>

		<app-game v-for="(item, index) in data" :item="item" ratings="userRatings" v-bind:key="index" :subtext="subtext(item)" v-on:ratings="onRatingsClick(item.id)" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'type', 'context'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			title: {
				most: 'Most divisive games',
				least: 'Least divisive games'
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/members/divisive'][this.type]

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		onMoreClick: function() {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsMembersDivisive',
				compareType: this.type
			})
		
		},

		onRatingsClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsGameRatings',
				id: id
			})

		},

		subtext: function(item) {

			return 'Divisive score of ' + item.diff.toFixed(2)

		}

	}

}

</script>

<style scoped>

</style>