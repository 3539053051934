import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		generic: function(context, data) {

			data = data || {}
			data.params = data.params || {}

			return new Promise(function(resolve, reject) {

				API.request(data.url, data.params, true).then(function(json) {

					// add reference data
					context.commit('data/games/add', json.data.games, { root: true })
					context.commit('data/users/add', json.data.users, { root: true })
					context.commit('data/designers/add', json.data.designers, { root: true })
					context.commit('data/languages/add', json.data.languages, { root: true })
					context.commit('data/families/add', json.data.families, { root: true })
					context.commit('data/publishers/add', json.data.publishers, { root: true })
					context.commit('data/artists/add', json.data.artists, { root: true })
					context.commit('data/mechanics/add', json.data.mechanics, { root: true })
					context.commit('data/subdomains/add', json.data.subdomains, { root: true })
					context.commit('data/categories/add', json.data.categories, { root: true })
				
					// first page, replace lists
					if (json.page === 1 || json.pagesInclusive === 1) {

						context.commit('page/list/list/set', json.list, { root: true })
						context.commit('page/list/stats/set', json.stats, { root: true })
						context.commit('page/list/highlights/set', json.highlights, { root: true })
						context.commit('page/list/edition/set', json.edition, { root: true })
						context.commit('page/list/editions/set', json.editions, { root: true })
						context.commit('page/list/games/set', json.games, { root: true })

					// otherwise paginate
					} else {

						context.commit('page/list/games/append', json.games, { root: true })

					}

					context.commit('ready', null, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})		

		},

		users: function(context, params) {

			return context.dispatch('generic', {
				params: params,
				url: 'users/list'
			})	
			
		},

		groups: function(context, params) {

			return context.dispatch('generic', {
				params: params,
				url: 'groups/list'
			})	
			
		},

		buddies: function(context, params) {

			return context.dispatch('generic', {
				params: params,
				url: 'buddies/list'
			})	
			
		},

		create: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.id = context.rootGetters['context/id']

			return new Promise(function(resolve, reject) {

				API.request('list/create', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		delete: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id

			return new Promise(function(resolve, reject) {

				API.request('list/delete', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		add: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/add', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		remove: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/remove', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		edit: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id

			return new Promise(function(resolve, reject) {

				API.request('list/edit', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		filter: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id

			return new Promise(function(resolve, reject) {

				API.request('list/filter', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		order: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/order', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		suggest: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/suggest', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		columns: function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id

			return new Promise(function(resolve, reject) {

				API.request('list/columns', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'edition/create': function(context, params) {

			params = params || {}

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id

			return new Promise(function(resolve, reject) {

				API.request('list/edition/create', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'edition/delete': function(context, params) {

			params = params || {}

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/edition/delete', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'ranking/pool': function(context, params) {

			params = params || {}

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/ranking/pool', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'ranking/progress': function(context, params) {

			params = params || {}

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/ranking/progress', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'ranking/complete': function(context, params) {

			params = params || {}

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/ranking/complete', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		'item/comment': function(context, params) {

			params.type = context.rootGetters['context/type']
			params.name = context.rootGetters['context/name']
			params.list = context.rootGetters['page/list/list'].id
			params.edition = context.rootGetters['page/list/edition'].id

			return new Promise(function(resolve, reject) {

				API.request('list/item/comment', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		}

	}

}