<template>

<com-item :name="'records.stats.' + type" :has-more="true" v-on:more="onMoreClick" :size="size" :is-list="true" :loading="loading" :is-empty="!active" :title="title[type]">

	<template v-slot:stats>

		<app-streak :hideGames="false" :item="item" :showSummary="false" :label="label" :user="!isUser" v-for="(item, index) in data" v-bind:key="index" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'type', 'label'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			title: {
				day: 'Plays in a day',
				new: '1st plays in a day'
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/records/stats'][this.type]

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		onMoreClick: function() {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsRecordsStats',
				streakType: this.type
			})
		
		}

	}

}

</script>

<style scoped>

</style>