import API from 'core/api'

export default {

	namespaced: true,

	actions: {

		'rename/create': function(context, params) {

			return API.request('custom/rename/create', params)

		},

		'rename/delete': function(context, params) {

			return API.request('custom/rename/delete', params)

		},

		'nesting/remove': function(context, params) {

			return API.request('custom/nesting/remove', params)

		},

		'nesting/restore': function(context, params) {

			return API.request('custom/nesting/restore', params)

		},

		'nesting/clear': function(context, params) {

			return API.request('custom/nesting/clear', params)

		},

		'nesting/add': function(context, params) {

			return API.request('custom/nesting/add', params)

		}

	}

}