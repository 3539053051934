<template>
	
<div class="name">
				
	<div class="name-text">			
		
		<core-gamename :game="item.game" :id="item.id" class="name-text-link" />

	</div>

	<app-tags :item="item" class="name-tags" />

</div>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.name {
	padding: 0px 10px 10px 10px;
}

.name-text {
	flex-grow: 1;
	min-width: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.name-text-link {
	overflow: hidden;
	min-width: 0;
	display: block;
	width: calc(100% - 15px);
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	line-height: 24px;
	cursor: pointer;
	overflow: hidden;
	color: #333;
	font-weight: 500;
}

.is-desktop .name-text-link:hover {
	text-decoration: underline;
}

.name-tags {
	margin-top: 2px;
}

</style>
