import colors from './util/colors'

export default {

	namespaced: true,

	modules: {
		colors: colors
	},

	state: {},

	getters: {

		colors: function(state, getters) {

			return getters['colors/all']

		}

	},

	mutations: {},

	actions: {}

}