<template>
	
<core-list-expand-section :empty="!totalComments" placeholder="No comments have been written yet." v-if="selected" class="comments" v-bind:class="{'is-loading': is.loading}">

	<app-comment v-for="(comment, index) in comments" :hideUser="isUser" v-bind:key="index" :comment="comment" :rating="rating(comment.user)" class="comments-item" />

</core-list-expand-section>

</template>

<script>

export default {

	name: 'comments',

	components: {
		
	},

	props: ['item', 'selected'],

	data: function() {

		return {
			comments: [],
			is: {
				loading: false
			}
		}

	},

	computed: {

		id: function() {

			return this.item.game.id

		},

		totalComments: function() {

			return this.item.users.totalComments || this.item.user.commented

		}

	},

	watch: {

		id: function() {

			this.fetch()

		}

	},

	created: function() {

		this.fetch()

	},

	methods: {

		rating: function(user) {

			return (this.item.users.rated[user] === undefined) ? 0 : this.item.users.rated[user]

		},

		fetch: function() {

			if (this.totalComments) {

				this.is.loading = true

				this.$store.dispatch('api/games/comments', {
					id: this.item.game.id,
					type: this.$route.params.type,
					name: this.$route.params.name,
					silent: true
				}).then(function(json) {

					this.comments = json.comments
					this.is.loading = false

				}.bind(this), function() {

					this.is.loading = false

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.comments {
	padding: 0px!important;
	flex-direction: column;
	flex-grow: 1;
}

.comments.is-loading {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(~core/assets/load.gif);
}

.comments.is-loading > * {
	opacity: 0;
}

.comments-item {
	padding: 8px 0px;
}

.is-mobile .comments-item {
	padding: 8px;
}

.comments-scroll {
	max-height: 257px;
	width: 100%;
}

.is-mobile .comments-scroll {
	min-height: 0;
	max-height: 100%;
	flex-grow: 1;
	flex-basis: 0;
	height: auto;
}

</style>