<template>

<core-filter-advanced-group title="Categories" :count="count">

	<core-filter-input-suggest placeholder="Start typing a category..." :has-require="true" :toggle="true" field="category_name" :filter="value('categories')" api="categories/suggest" data="categories" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['categories']
		}
	}

}

</script>