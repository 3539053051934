<template>

<core-config-section>

	<h2>Contained Games <small>Applies to all collections</small></h2>

	<p>If you want to automatically show the contained games and expansions of any Big Boxes or Special Editions you own. This does not impact your collection filtering, metrics, or insights.</p>

	<div class="inputs">

		<core-filter-input-toggle text="Enable" on="Yes" off="No" filter="config/collection/contains" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>