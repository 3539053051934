import router from 'app/router'
import CONSTANTS from 'app/constants'

export default {

	namespaced: true,

	state: {

		games: CONSTANTS.INSIGHTS.WORTH.GAMES.MOST,
		ratings: CONSTANTS.INSIGHTS.WORTH.RATINGS.TOTAL,
		designers: CONSTANTS.INSIGHTS.WORTH.DESIGNERS.TOTAL,
		publishers: CONSTANTS.INSIGHTS.WORTH.PUBLISHERS.TOTAL,
		plays: CONSTANTS.INSIGHTS.WORTH.PLAYS.TOTAL,
		valuations: {
			expansions: CONSTANTS.INSIGHTS.WORTH.VALUATIONS.GAMES,
			type: CONSTANTS.INSIGHTS.GRAPH.POLAR
		},	
		trending: {
			direction: CONSTANTS.INSIGHTS.WORTH.TRENDING.DIRECTION.UP,
			period: CONSTANTS.INSIGHTS.WORTH.TRENDING.PERIOD.QUARTER
		}

	},

	getters: {

		active: function(state) {

			return {
				games: state.games !== CONSTANTS.INSIGHTS.WORTH.GAMES.MOST,
				ratings: state.ratings !== CONSTANTS.INSIGHTS.WORTH.RATINGS.TOTAL,
				designers: state.designers !== CONSTANTS.INSIGHTS.WORTH.DESIGNERS.TOTAL,
				publishers: state.publishers !== CONSTANTS.INSIGHTS.WORTH.PUBLISHERS.TOTAL,
				plays: state.plays !== CONSTANTS.INSIGHTS.WORTH.PLAYS.TOTAL,
				'valuations.expansions': state.valuations.expansions !== CONSTANTS.INSIGHTS.WORTH.VALUATIONS.GAMES,
				'valuations.type': state.valuations.type !== CONSTANTS.INSIGHTS.GRAPH.POLAR,
				'trending.direction': state.trending.direction !== CONSTANTS.INSIGHTS.WORTH.TRENDING.DIRECTION.UP,
				'trending.period': state.trending.period !== CONSTANTS.INSIGHTS.WORTH.TRENDING.PERIOD.QUARTER
			}

		},

		values: function(state) {

			return {
				games: state.games,
				ratings: state.ratings,
				designers: state.designers,
				publishers: state.publishers,
				plays: state.plays,
				'valuations.type': state.valuations.type,
				'valuations.expansions': state.valuations.expansions,
				'trending.direction': state.trending.direction,
				'trending.period': state.trending.period
			}

		},

		games: function(state) {

			return state.games

		},

		'valuations/type': function(state) {

			return state.valuations.type

		},

		'valuations/expansions': function(state) {

			return state.valuations.expansions

		},

		ratings: function(state) {

			return state.ratings

		},

		designers: function(state) {

			return state.designers

		},

		publishers: function(state) {

			return state.publishers

		},

		plays: function(state) {

			return state.plays

		},

		'trending/period': function(state) {

			return state.trending.period

		},

		'trending/direction': function(state) {

			return state.trending.direction

		}

	},

	mutations: {

		reset: function(state) {

			state.games = CONSTANTS.INSIGHTS.WORTH.GAMES.MOST
			state.ratings = CONSTANTS.INSIGHTS.WORTH.RATINGS.TOTAL
			state.designers = CONSTANTS.INSIGHTS.WORTH.DESIGNERS.TOTAL
			state.publishers = CONSTANTS.INSIGHTS.WORTH.PUBLISHERS.TOTAL
			state.plays = CONSTANTS.INSIGHTS.WORTH.PLAYS.TOTAL
			state.valuations.type = CONSTANTS.INSIGHTS.GRAPH.POLAR
			state.valuations.expansions = CONSTANTS.INSIGHTS.WORTH.VALUATIONS.GAMES
			state.trending.direction = CONSTANTS.INSIGHTS.WORTH.TRENDING.DIRECTION.UP
			state.trending.period = CONSTANTS.INSIGHTS.WORTH.TRENDING.PERIOD.QUARTER

		},

		games: function(state, value) {

			state.games = value

		},

		'valuations/type': function(state, value) {

			state.valuations.type = value

		},

		'valuations/expansions': function(state, value) {

			state.valuations.expansions = value

		},

		ratings: function(state, value) {

			state.ratings = value

		},

		designers: function(state, value) {

			state.designers = value

		},

		publishers: function(state, value) {

			state.publishers = value

		},

		plays: function(state, value) {

			state.plays = value

		},

		'trending/direction': function(state, value) {

			state.trending.direction = value

		},

		'trending/period': function(state, value) {

			state.trending.period = value

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params['games']) context.state.games = params['games']
					if (params['ratings']) context.state.ratings = params['ratings']
					if (params['designers']) context.state.designers = params['designers']
					if (params['publishers']) context.state.publishers = params['publishers']
					if (params['plays']) context.state.plays = params['plays']
					if (params['valuations.type']) context.state.valuations.type = params['valuations.type']
					if (params['valuations.expansions']) context.state.valuations.expansions = params['valuations.expansions']
					if (params['trending.direction']) context.state.trending.direction = params['trending.direction']
					if (params['trending.period']) context.state.trending.period = params['trending.period']

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}

			if (context.state.games !== CONSTANTS.INSIGHTS.WORTH.GAMES.MOST) urlParams['games'] = context.state.games
			if (context.state.ratings !== CONSTANTS.INSIGHTS.WORTH.RATINGS.TOTAL) urlParams['ratings'] = context.state.ratings
			if (context.state.designers !== CONSTANTS.INSIGHTS.WORTH.DESIGNERS.TOTAL) urlParams['designers'] = context.state.designers
			if (context.state.publishers !== CONSTANTS.INSIGHTS.WORTH.PUBLISHERS.TOTAL) urlParams['publishers'] = context.state.publishers
			if (context.state.plays !== CONSTANTS.INSIGHTS.WORTH.PLAYS.TOTAL) urlParams['plays'] = context.state.plays
			if (context.state.valuations.type !== CONSTANTS.INSIGHTS.GRAPH.POLAR) urlParams['valuations.type'] = context.state.valuations.type
			if (context.state.valuations.expansions !== CONSTANTS.INSIGHTS.WORTH.VALUATIONS.GAMES) urlParams['valuations.expansions'] = context.state.valuations.expansions
			if (context.state.trending.direction !== CONSTANTS.INSIGHTS.WORTH.TRENDING.DIRECTION.UP) urlParams['trending.direction'] = context.state.trending.direction
			if (context.state.trending.period !== CONSTANTS.INSIGHTS.WORTH.TRENDING.PERIOD.QUARTER) urlParams['trending.period'] = context.state.trending.period

			router.replace({name: 'InsightsWorth', query: urlParams}).catch(function() {})

		}

	}

}