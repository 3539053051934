<template>
	
<div class="card-buttons" :class="{'is-foot': foot}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['foot']

}

</script>

<style scoped>

.card-buttons {
	padding: 0px 0px 0px 0px;
	border: 1px solid #ddd;
	background-color: #fff;
	border-width: 0px 1px 0px 1px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.card-buttons.is-foot {
	border-width: 0px 1px 1px 1px;
	border-radius: 0px 0px 4px 4px;
}

</style>
