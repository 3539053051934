import Vue from 'vue'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: {}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		start: function(state) {

			return state.data.start || 0

		},

		end: function(state) {

			return state.data.end || 0

		},

		years: function(state) {

			return state.data.years || []

		},

		plays: function(state) {

			return state.data.plays || []

		},

		designers: function(state) {

			return state.data.designers || []

		},

		publishers: function(state) {

			return state.data.publishers || []

		},
		
		rankings: function(state) {

			return state.data.rankings || false

		},

		duration: function(state) {

			return state.data.duration || []

		},

		locations: function(state) {

			return state.data.locations || []

		},

		hindex: function(state) {

			return state.data.hindex || []

		},

		weights: function(state) {
 
			return state.data.weights || {}

		},

		played: function(state) {

			return state.data.played || []

		},

		counts: function(state) {

			return state.data.counts || []

		},

		categories: function(state) {

			return state.data.categories || []

		},

		types: function(state) {

			return state.data.types || []

		},

		overview: function(state) {

			return state.data.overview || []

		},

		hindexStart: function(state) {

			return state.data.hindexStart || 0

		},

		calendar: function(state) {

			return state.data.calendar || 0

		}

	},

	mutations: {

		reset: function(state) {

			state.data = {}

		},

		set: function(state, data) {

			_.each(data, function(value, key) {

				Vue.set(state.data, key, value)

			})

		}

	},

	actions: {}

}