<template>

<div>

	<com-modal :noun="noun" v-if="window.is.desktop" />
	<com-bar :noun="noun" v-if="!window.is.desktop" />

</div>

</template>

<script>

import comModal from './select/Modal'
import comBar from './select/Bar'

export default {

	props: ['noun'],

	components: {
		'com-modal': comModal,
		'com-bar': comBar
	}

}

</script>

<style scoped>

</style>
