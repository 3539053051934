<template>

<core-content-wrapper :loading="!is.ready">

	<core-filter :loading="is.fetching" :filter="insightName" />

	<core-content-page v-if="is.reset && is.fetching !== 'all'">

		<core-content-page-inner v-show="is.ready" :is-grid="true">

			<com-overview :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-status :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-weight :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-rank :fetching="is.fetching" :your="your" :you="you" :size="6" />

			<com-suitability :fetching="is.fetching" :your="your" :you="you" :size="3" />

			<com-bestduration :fetching="is.fetching" :your="your" :you="you" :size="3" />

			<com-rarity :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-counts :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-duration :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-dependency :fetching="is.fetching" :your="your" :you="you" :size="3" />

			<com-year :fetching="is.fetching" :your="your" :you="you" :size="3" />
			
			<com-designers :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-publishers :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-types :fetching="is.fetching" :your="your" :you="you" :size="2" />

		</core-content-page-inner>

	</core-content-page>

</core-content-wrapper>

</template>

<script>

import Section from './common/Section.js'

import comOverview from './collection/Overview'
import comStatus from './collection/Status'
import comRank from './collection/Rank'
import comSuitability from './collection/Suitability'
import comRarity from './collection/Rarity'
import comWeight from './collection/Weight'
import comBestDuration from './collection/BestDuration'
import comYear from './collection/Year'
import comCounts from './collection/Counts'
import comDependency from './collection/Dependency'
import comDuration from './collection/Duration'
import comDesigners from './collection/Designers'
import comPublishers from './collection/Publishers'
import comTypes from './collection/Types'

export default {

	mixins: [Section],

	components: {
		'com-designers': comDesigners,
		'com-publishers': comPublishers,
		'com-rank': comRank,
		'com-year': comYear,
		'com-weight': comWeight,
		'com-counts': comCounts,
		'com-types': comTypes,
		'com-overview': comOverview,
		'com-status': comStatus,
		'com-dependency': comDependency,
		'com-duration': comDuration,
		'com-suitability': comSuitability,
		'com-bestduration': comBestDuration,
		'com-rarity': comRarity
	},

	data: function() {

		return {

			insightName: 'collection',

			dynamicInsights: [
				'ranking.band',
				'type',
				'year',
				'rarity',
				'duration',
				'suitability.duration',
				'suitability.count',
				'bestduration.duration'
			]

		}

	}

}

</script>