import Vue from 'vue'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: {}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		games: function(state) {

			return state.data.games || {
				threshold: 0,
				most: [],
				least: []
			}

		},

		users: function(state) {

			return state.data.users || {
				threshold: 0,
				most: [],
				least: []
			}

		},

		summary: function(state) {

			return state.data.summary || {
				streaks: {
					win: {value: 0, text: ''},
					loss: {value: 0, text: ''},
					play: {value: 0, text: ''},
					drought: {value: 0, text: ''}
				},
				stats: {
					day: {value: 0, text: ''},
					overall: {value: 0, text: ''},
					new: {value: 0, text: ''}
				}
			}

		},

		streaks: function(state) {

			return state.data.streaks || {
				win: [],
				loss: [],
				play: [],
				drought: []
			}

		},

		stats: function(state) {

			return state.data.stats || {
				new: [],
				day: []
			}

		},

		plays: function(state) {

			return state.data.plays || {
				total: 0,
				applicable: 0
			}

		},

		monthly: function(state) {

			return state.data.monthly || {
				start: 0,
				end: 0,
				months: []
			}

		},

		distribution: function(state) {

			return state.data.distribution || {
				counts: [],
				weights: []
			}

		}

	},

	mutations: {

		reset: function(state) {

			state.data = {}

		},

		set: function(state, data) {

			_.each(data, function(value, key) {

				Vue.set(state.data, key, value)

			})

		}

	},

	actions: {}

}