<template>

<com-item name="worth.plays" :size="size" :is-empty="!active" :loading="loading" title="Ratings">

	<template v-slot:about>

		<p>{{ your | capitalise }} collection worth by rating.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-accordian :data="accordian" v-on:click="onAccordianClick" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="ratingsOptions" :value="ratingsValue" v-on:change="onRatingsChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {
	
		return {
			accordian: {
				noun: 'game',
				tooltip: 'Click to view games<small>{{value}} {{noun}}</small>',
				values: [],
				slimPercent: 5,
				subvalues: [],
				format: 'currency',
				labels: [1,2,3,4,5,6,7,8,9,10],
				images: [],
				colors: [
					'#db303b',
					'#db303b',
					'#df4751',
					'#df4751',
					'#5369a2',
					'#5369a2',
					'#1d8acd',
					'#2FC482',
					'#249563',
					'#249563'
				]
			}
		}
	
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'ratings'

		},

		ratingsOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.RATINGS.TOTAL, text: 'Total value'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.RATINGS.AVERAGE, text: 'Average value'})

			return options

		},

		ratingsValue: function() {

			return this.$store.getters['filter/insights/worth/ratings']

		},

		data: function() {

			return this.$store.getters['page/insights/worth/ratings']

		},

		active: function() {

			return this.data.length 

		}

	},

	watch: {
	
		data: function() {
		
			this.visualise()	
		
		}

	},

	created: function() {
	
		this.visualise()	

	},

	methods: {

		onRatingsChange: function(value) {

			this.$store.commit('filter/insights/worth/ratings', value)

		},

		onAccordianClick: function(band) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthRatings',
				filter: this.$store.getters['filter/insights/worth'],
				band: band
			})

		},
	
		visualise: function() {
		
			for (var i = 1; i <= 10; i++) {

				this.accordian.values.push(0)
				this.accordian.subvalues.push(0)
				this.accordian.images.push(0)

			}

			this.$_.each(this.data, function(item) {
			
				this.accordian.values[item.r - 1] = item.w
				this.accordian.subvalues[item.r - 1] = item.g
				this.accordian.images[item.r - 1] = item.f
			
			}.bind(this))
		
		}

	}

}

</script>

<style scoped>

</style>