<template>
	
<div class="actions" :class="{'is-hidden': hidden, 'is-condensed': condensed, 'is-horizontal': horizontal}">

	<div class="actions-check" v-if="!hidden && !condensed">

		<div class="actions-check-box" :class="{'is-active': isSelected}" v-on:click="onSelectToggle" v-tooltip="'Toggle selection'">
			<i class="fa fa-check" v-if="isSelected"></i>
		</div>

	</div>

	<core-dropdown class="actions-dropdown" trigger="manual" v-on:forceClose="is.expanded = false" :expanded="is.expanded" v-click-outside="onOutsideClick" v-if="!hidden" :silentExpand="true">

		<div v-on:click="onToggleClick" class="actions-dropdown-button" v-tooltip="'Actions'">
			<i class="fa fa-ellipsis-v" v-if="!horizontal"></i>
			<i class="fa fa-ellipsis-h" v-if="horizontal"></i>
		</div>

		<core-dropdown-list :caret="true" :levels="true" class="actions-dropdown-options" align="left" type="button">

			<core-list-actions-root v-if="level === 'root'" :item="item" :level="level" :can="can" :filter="filter" v-on:level="onLevelChange" v-on:close="onOutsideClick" />
			<core-list-actions-tag v-if="level === 'tag.add'" :item="item" :level="level" :filter="filter" v-on:level="onLevelChange" v-on:close="onOutsideClick" />
			<core-list-actions-nest v-if="level === 'nesting'" :item="item" :level="level" :filter="filter" v-on:level="onLevelChange" v-on:close="onOutsideClick" />

		</core-dropdown-list>

	</core-dropdown>
	
</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	props: ['item', 'filter', 'hidden', 'can', 'horizontal', 'condensed'],

	directives: {
		clickOutside: vClickOutside.directive
	},
	
	data: function() {

		return {
			level: 'root',
			is: {
				expanded: false
			}
		}

	},

	computed: {

		isSelected: function() {

			return this.$store.getters['service/select'](this.item.id)

		}

	},

	methods: {

		onLevelChange: function(value) {

			this.level = value

		},

		onSelectToggle: function() {

			this.$store.commit('service/select/toggle', this.item.id)

		},

		onToggleClick: function() {

			this.level = 'root'
			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.level = 'root'
			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

.actions {
	width: 24px;
	min-width: 24px;
	height: 48px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-left: 7px;
}

.actions.is-condensed {
	height: 24px;
}

.actions.is-horizontal {
	flex-direction: row;
	flex-shrink: 0;
	width: auto;
	height: 24px;
	margin-left: 0px;
}

.actions.is-hidden {
	height: auto;
}

.actions-check-box {
	border: 2px solid #ccc;
	width: 16px;
	height: 16px;
	margin: 4px;
	border-radius: 4px;
	cursor: pointer;
	user-select: false;
}

.is-desktop .item:hover .actions-check-box:not(.is-active),
.is-desktop .list-row:hover .actions-check-box:not(.is-active) {
	border-color: #999;
}

.is-desktop .grid:hover .actions-check-box:not(.is-active),
.is-desktop .card:hover .actions-check-box:not(.is-active) {
	border-color: #fff;
}

.is-desktop .item:hover .actions-check-box:not(.is-active):hover,
.is-desktop .list-row:hover .actions-check-box:not(.is-active):hover {
	border-color: #666;
}

.is-desktop .grid:hover .actions-check-box:not(.is-active):hover,
.is-desktop .card:hover .actions-check-box:not(.is-active):hover {
	border-color: #fff;
}

.actions-check-box.is-active {
	background-color: #1b6b8f;
	border-color: #1b6b8f;
	font-size: 10px;
	text-align: center;
	line-height: 14px;
	color: #fff;
}

.actions-dropdown-button {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	color: #ccc;
	text-align: center;
	line-height: 24px;
	font-size: 16px;
	cursor: pointer;
	user-select: false;
}

.is-desktop .item:hover .actions-dropdown-button,
.is-desktop .list-row:hover .actions-dropdown-button {
	color: #999;
}

.is-desktop .card:hover .actions-dropdown-button {
	color: #fff;
}

.item.is-selected .actions-dropdown-button,
.list-row.is-selected .actions-dropdown-button,
.is-desktop .list-row.is-selected:hover .actions-dropdown-button,
.is-desktop .item.is-selected:hover .actions-dropdown-button {
	color: #fff;
}

.is-desktop .item:hover .actions-dropdown-button:hover,
.is-desktop .list-row:hover .actions-dropdown-button:hover {
	color: #666;
}

.is-desktop .grid:hover .actions-dropdown-button:hover,
.is-desktop .card:hover .actions-dropdown-button:hover {
	color: #fff;
}

.is-desktop .grid.is-selected .actions-dropdown-button:hover,
.is-desktop .item.is-selected:hover .actions-dropdown-button:hover,
.is-desktop .list-row.is-selected:hover .actions-dropdown-button:hover {
	color: #1b6b8f;
}

.actions-dropdown-options {
	right: -7px;
	width: auto;
	top: 28px!important;
	border-radius: 4px;
	padding: 4px 0px;
	z-index: 2000;
}

.is-mobile .actions-dropdown-options {
	position: fixed;
	top: auto!important;
	bottom: 0px;
	right: 0px;
	z-index: 11;
	border-radius: 0px;
	max-height: calc(100vh - var(--vh-offset, 0px) - 128px);
}

</style>
