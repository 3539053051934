<template>

<div>

	<slot></slot>

	<div class="inline">

		<core-form-input-textbox name="verifyname" v-on:validate="validate" :placeholder="placeholder" v-model="inputs.verifyname" :error="is.error" :disabled="is.success || is.loading" :validation="validation.verifyname" v-on:enter="onVerifyClick" class="input"></core-form-input-textbox>

		<core-button v-on:click.native="onVerifyClick" theme="grey" :loading="is.loading" :success="is.success" :disabled="!is.valid" class="button">{{ buttonText }}</core-button>

	</div>

</div>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false,
				success: false,
				error: false
			},

			inputs: {
				verifyname: ''
			},

			validation: {
				verifyname: { exists: true }
			}
			
		}

	},

	computed: {

		buttonText: function() {

			return 'Search'

		},

		placeholder: function() {

			return (this.loggedin || this.window.is.mobile) ? 'Search username' : 'Enter BGG username'

		}

	},

	methods: {

		onVerifyClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/account/exists', {
					name: this.inputs.verifyname
				}).then(function(data) {

					this.$router.push({
						name: 'Collection',
						params: {
							name: data.name,
							type: this.$CONSTANTS.TYPE.USERS
						}
					})

					this.is.loading = false

					this.inputs.verifyname = ''

					this.$pubsub.$emit('menu.close')

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		}

	}

}

</script>

<style scoped>

.inline {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.input {
	flex-grow: 1;
}

.input >>> input {
	height: 40px;
	line-height: 40px;
	border-radius: 4px 0px 0px 4px!important;
	font-size: 14px!important;
}

.input >>> .textbox-validation {
	display: none!important;
}

.button {
	border-radius: 0px 4px 4px 0px!important;
}

</style>