<template>

<div class="match" :class="{'is-dark': dark}">

	<div class="match-a">

		<core-avatar type="user" :id="item.user_a" class="match-avatar" />

		<div class="match-name">
			<span>{{ name(item.user_a) }}</span>
			<small v-if="username(item.user_a)">{{ username(item.user_a) }}</small>
		</div>

	</div>

	<div class="match-score" :data-band="band(item.average)" v-on:click="onScoreClick" v-tooltip="'Click to view games'">

		{{ score(item.average) }}<small>%</small>

		<div class="match-score-count">{{ item.ratings }}</div>

	</div>

	<div class="match-b">

		<div class="match-name">
			<span>{{ name(item.user_b) }}</span>
			<small v-if="username(item.user_b)">{{ username(item.user_b) }}</small>
		</div>

		<core-avatar type="user" :id="item.user_b" class="match-avatar" />

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'dark'],

	methods: {

		score: function(avg) {

			return (100 - (avg * 10)).toFixed(1)

		},

		band: function(avg) {

			return Math.floor(10 - avg)

		},

		name: function(id) {

			return this.$store.getters['data/users/associated'][id].fullname || this.$store.getters['data/users/associated'][id].name

		},

		username: function(id) {

			return (this.$store.getters['data/users/associated'][id].fullname) ? this.$store.getters['data/users/associated'][id].name : false

		},

		onScoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsMembersMatch',
				user_a: this.item.user_a,
				user_b: this.item.user_b,
				child: this.dark
			})

		}

	}

}

</script>

<style scoped>

.match {
	width: 100%;
	height: 64px;
	flex-direction: row;
	display: flex;
	padding: 8px 15px 8px 15px;
}

@media only screen and (max-width: 767px) {

	.match {
		padding: 8px 8px;
		height: auto;
	}

}

.match:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.match-avatar {
	width: 32px;
	height: 32px;
	margin: 8px 0px;
	border-radius: 16px;
	flex-shrink: 0;
}

.match-name {
	padding: 0px 8px;
	display: flex;
	min-width: 0;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	text-align: left;
	flex-wrap: nowrap;
}

.match-name span {
	display: block;
	font-size: 14px;
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #333;
}

.match.is-dark .match-name span {
	color: #fff;
}

.match-name small {
	display: block;
	margin-top: 2px;
	font-size: 12px;
	font-weight: 300;
}

.match.is-dark .match-name small {
	color: rgba(255, 255, 255, 0.75);
}

.match-a,
.match-b {
	height: 48px;
	width: calc(50% - 24px);
	display: flex;
	flex-direction: row;
}

.match-b {
	justify-content: flex-end;
}

.match-b .match-name {
	text-align: right;
}

.match-score {
	width: 48px;
	text-align: center;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	font-weight: 400;
	cursor: pointer;
	color: #fff;
}

.match-score-count {
	display: block;
	padding: 0px 2px;
	min-width: 14px;
	height: 14px;
	font-weight: 400;
	font-size: 8px;
	text-align: center;
	color: #ffffff;
	line-height: 14px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.match-score small {
	font-size: 10px;
	font-weight: 300;
}

.match-score[data-band="10"] { background-color: #249563; }
.match-score[data-band="9"] { background-color: #249563; }
.match-score[data-band="8"] { background-color: #2FC482; }
.match-score[data-band="7"] { background-color: #1d8acd; }
.match-score[data-band="6"] { background-color: #5369a2; }
.match-score[data-band="5"] { background-color: #5369a2; }
.match-score[data-band="4"] { background-color: #df4751; }
.match-score[data-band="3"] { background-color: #df4751; }
.match-score[data-band="2"] { background-color: #db303b; }
.match-score[data-band="1"] { background-color: #db303b; }
.match-score[data-band="0"] { background-color: #db303b; }

</style>