<template>
	
<core-list-row class="list-row">

	<template v-slot:fields>

		<core-list-cell type="avatar" :format="{}">

			<core-avatar :item="item" type="user" :id="item.id" class="user-avatar">

				<core-flag :id="item.country" class="user-avatar-flag" />

			</core-avatar>

		</core-list-cell>

		<core-list-cell type="name" :format="{filter: 'users', sort: 'name'}">

			<router-link :to="{name: 'Collection', params: {type: 'users', name: item.name}}" class="user-name">{{ name }}</router-link>

			<div class="user-name-username" v-if="item.fullname">{{ item.name }}</div>

		</core-list-cell>

		<core-list-cell type="stat" v-if="!window.is.mobile" :format="{filter: 'users', sort: 'collection'}">

			{{ item.count.collection }}

		</core-list-cell>

		<core-list-cell type="stat" v-if="!window.is.mobile" :format="{filter: 'users', sort: 'ratings'}">

			{{ item.count.ratings }}

		</core-list-cell>

		<core-list-cell type="stat" v-if="!window.is.mobile" :format="{filter: 'users', sort: 'plays'}">

			{{ item.count.plays }}

		</core-list-cell>

		<core-list-cell type="stat" v-if="!window.is.mobile" :format="{filter: 'users', sort: 'hindex'}">

			{{ item.hindex }}

		</core-list-cell>

		<core-list-cell-actions v-if="isGroup" :item="item" filter="users" />

	</template>

</core-list-row>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		hasActions: function() {

			return this.$store.getters['permissions/is/owner'] && this.$store.getters['context/is/group']

		},

		name: function() {

			return this.item.fullname || this.item.name

		},

		canRemove: function() {

			return this.item.id !== this.$store.getters['session/user/id'] && this.$store.getters['permissions/group/remove']

		}
		
	},

	methods: {

		onRemoveClick: function(id) {

			this.$pubsub.$emit('open.modal', {
				id: 'groupRemove',
				user: id
			})

			this.$pubsub.$emit('dropdown.action')
			
		}

	}

}

</script>

<style scoped>

.user {
	width: 100%;
	padding: 0px 7px;
	border-bottom: 1px solid #eee;
}

.user-columns {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	height: 63px;
	padding-bottom: 8px;
	padding-top: 7px;
}

.is-desktop .user:hover {
	background-color: #e9f3f7;
}

.user-column {
	height: 48px;
}

.user-avatar {
	width: 48px;
	min-width: 48px;
	height: 48px;
	border-radius: 24px;
	font-size: 24px;
}

.user-avatar-flag {
	position: absolute;
	width: 12px;
	height: 12px;
	background-position: 50% 50%;
	background-size: 16px 16px;
	border-radius: 6px;
	bottom: 0px;
	right: 0px;
}

.user:last-child {
	border-bottom: 0px;
	margin-bottom: 0px;
}

.user-name {
	overflow: hidden;
	min-width: 0;
	display: block;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	overflow: hidden;
	color: #333;
	font-weight: 500;
}

.is-desktop .user-name:hover {
	text-decoration: underline;
}

@media only screen and (max-width: 767px) {

	.user-name :hover { 
		font-size: 14px;
	}

}

.user-name-username {
	font-size: 12px;
	font-weigt: 400;
	color: #666;
	line-height: 12px;
	margin-top: 2px;
}

</style>
