<template>

<div class="overview-item" :data-span="span" :class="{'is-comparing': isComparing, 'is-rating': type === 'rating', 'is-inset': inset, 'is-inline': inline, 'is-linked': link}" v-tooltip="tooltip" v-on:click="onClick">

	<div class="overview-info" v-if="info">{{ info }}</div>

	<div class="overview-content" :class="{'is-plain': type === 'band'}">

		<div class="overview-icon" v-if="icon"><i :class="{[icon]: true}"></i></div>

		<div class="overview-background" v-if="type === 'rating'">
			<div :data-band="band(value)" />
			<div :data-band="band(compare)" v-if="compare" />
		</div>

		<div class="overview-item-value" :class="{'is-empty': empty}" :data-theme="themeType">

			<div class="overview-item-value-item" :class="{'has-caret': caretType}">

				<template v-if="!empty && caretType">

					<i class="overview-item-value-item-caret is-up fa fa-caret-up" v-if="caretType === 'up'" />
					<i class="overview-item-value-item-caret is-down fa fa-caret-down" v-if="caretType === 'down'" />
					<i class="overview-item-value-item-caret" :class="{[caretType]: true}" v-if="caretType && caretType !== 'up' && caretType !== 'down'" />

				</template>

				<template v-if="type === 'number' && !empty && !isCurrency && canFit"><div class="overview-item-value-item-fit" v-fit>{{ value | prettyNumber }}</div></template>

				<template v-if="type === 'number' && !empty && !isCurrency && !canFit">{{ value | prettyNumber }}</template>
				
				<template v-if="type === 'number' && !empty && isCurrency"><div v-html="formatcurrency(value, 0)" v-fit></div></template>

				<template v-if="type === 'range' && !empty">
				
					<div class="overview-item-value-item-range">
						<div>{{ value[0] | prettyNumber }}</div>
						<div class="caret" />
						<div>{{ value[1] | prettyNumber }}</div>
					</div>
				
				</template>

				<template v-if="empty">n/a</template>

				<template v-if="type === 'template'"><span class="overview-item-value-item-template" v-html="formatValue" /></template>

				<com-progress v-if="type === 'percent'" :percent="value" />

				<com-trend v-if="type === 'trend'" :config="config" :value="value" />

				<com-band v-if="type === 'band'" :config="config" :value="value" />

				<core-rating v-if="type === 'rating'" :value="value" class="overview-item-value-rating" />

				<div v-if="valueLabel" class="overview-item-value-label">{{ valueLabel }}</div>

			</div>

			<div class="overview-item-value-item" v-if="isComparing">

				<template v-if="type === 'number'">{{ compare }}</template>

				<com-progress v-if="type === 'percent'" :percent="compare" />

				<com-trend v-if="type === 'trend'" :config="config" :value="compare" />

				<com-band v-if="type === 'band'" :config="config" :value="compare" />

				<core-rating v-if="type === 'rating'" :value="compare" class="overview-item-value-rating" />

			</div>

		</div>

		<div class="overview-item-compare" v-if="isComparing">
			
			<div class="overview-item-compare-item">{{ labelValue }}</div>
			<div class="overview-item-compare-item">{{ labelCompare }}</div>

		</div>

	</div>

	<div class="overview-item-label">
		
		{{ label }}
		<small v-if="sublabel"><template v-if="sublabel !== true">{{ sublabel }}</template></small>

	</div>

</div>

</template>

<script>

import comTrend from './overview/Trend'
import comBand from './overview/Band'
import comProgress from './overview/Progress'

export default {

	props: ['type', 'label', 'empty', 'sublabel', 'valueLabel', 'inline', 'isCurrency', 'caret', 'icon', 'value', 'theme', 'compare', 'span', 'config', 'info', 'labelValue', 'labelCompare', 'inset', 'autoCaret', 'autoTheme', 'tooltip', 'link'],

	components: {
		'com-trend': comTrend,
		'com-band': comBand,
		'com-progress': comProgress
	},

	computed: {

		canFit: function() {
		
			return !this.valueLabel && !this.caretType

		},

		themeType: function() {
		
			if (this.autoTheme) {
			
				if (this.value > 0) {
				
					return 'green'
				
				} else if (this.value < 0) {
				
					return 'red'
				
				} else {
				
					return false
				
				}
			
			} else {
			
				return this.theme

			}
		
		},

		caretType: function() {
		
			if (this.autoCaret) {
			
				if (this.value > 0) {
				
					return 'up'
				
				} else if (this.value < 0) {
				
					return 'down'
				
				} else {
				
					return false
				
				}
			
			} else {
			
				return this.caret

			}
		
		},

		formatValue: function() {

			return this.value.replace(/\[([a-zA-Z]+)\]/g, '<small>$1</small>')

		},

		isComparing: function() {

			return this.compare !== undefined

		}

	},

	methods: {

		onClick: function() {
		
			if (this.link) this.$router.push(this.link)
		
		},

		band: function(value) {

			return (value === '-' || value === 0) ? '-' : Math.floor(value)

		},

	}

}

</script>

<style scoped>

.overview-item {
	grid-column: span 2;
	height: 100%;
	display: grid;
	min-height: 104px;
	grid-template-rows: 1fr 18px;
}

.overview-item.is-linked {
	cursor: pointer;
}

.overview-item.is-inset {
	height: auto;
	min-height: 64px;
}

.overview-info {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100px;
	background-color: #4881bb;
	border-radius: 4px 4px 0px 0px;
	border-bottom: 1px solid #fff;
	z-index: 2;
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	justify-content: center;
	display: none;
	align-items: center;
}

.overvier-item.is-inset {
	border: 0px;
}

.overview-item.is-inline {
	height: auto;
}

.overview-item:hover .overview-info {
	display: flex;
}

.overview-content {
	width: 100%;
	height: 100%;
    border: 1px solid #ddd;
	border-bottom-width: 0px;
	border-radius: 4px 4px 0px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 1;
	background-color: #fff;
}

.overview-item.is-inset .overview-content {
	border: 0px;
	height: auto;
}

.overview-icon {
	position: absolute;
	left: 8px;
	top: 10px;
	font-size: 16px;
	color: #4881bb;
}

.overview-content.is-plain {
	border: 0px;
}

.overview-item.is-rating .overview-content {
	border: 0px;
}

.overview-item:nth-child(odd) .overview-content {
	background-color: #fff;
}

.overview-item[data-span="1.5"] {
	grid-column: span 3;
}

.overview-background {
	z-index: 1;
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	display: flex;
}

.overview-background div {
	flex-grow: 1;
	flex-shrink: 0;
	border-radius: 4px 4px 0px 0px;
	border-bottom: 1px solid #fff;
}

.overview-item.is-comparing .overview-background div:nth-child(1) {
	border-radius: 4px 0px 0px 0px;
}

.overview-item.is-comparing .overview-background div:nth-child(2) {
	border-radius: 0px 4px 0px 0px;
}

.overview-background div[data-band="10"] { background-color: #249563; }
.overview-background div[data-band="9"] { background-color: #249563; }
.overview-background div[data-band="8"] { background-color: #2FC482; }
.overview-background div[data-band="7"] { background-color: #1d8acd; }
.overview-background div[data-band="6"] { background-color: #5369a2; }
.overview-background div[data-band="5"] { background-color: #5369a2; }
.overview-background div[data-band="4"] { background-color: #df4751; }
.overview-background div[data-band="3"] { background-color: #df4751; }
.overview-background div[data-band="2"] { background-color: #db303b; }
.overview-background div[data-band="1"] { background-color: #db303b; }
.overview-background div[data-band="0"] { background-color: #db303b; }
.overview-background div[data-band="-"] { background-color: #efefef; color: #ccc; }
.overview-background div[data-band="-"] .rating-count { display: none; }

.overview-item-value {
	display: flex;
	flex-direction: row;
	justify-content: center;
	z-index: 2;
}

.overview-item-value-item {
    font-size: 28px;
	line-height: 48px;
    color: #4881bb;
    font-weight: 700;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.overview-item-value-item.has-caret {
	flex-direction: row;
}

.overview-item-value-item-fit {
	position: absolute;
    top: 50%;
    left: 50%;
    margin: 0px;
    transform: translate(-50%, -50%);
}

.overview-item-value-item-template {
	height: 48px;
}

.overview-item.is-inline .overview-item-value-item-template {
	height: 28px;
}

.overview-item-value-item >>> small {
	font-size: 16px;
	opacity: 0.65;
	margin-left: 1px;
}

.overview-item-value >>> span.currency {
	font-size: 14px;
	color: #999;
	margin-right: 2px;
}

.overview-item.is-inline .overview-item-value-item {
	line-height: 28px;
}

.overview-item-value-item-caret {
	font-size: 14px;
	margin-right: 6px;
}

.overview-item-value-item-caret.is-up {
	color: #2FC482;
}

.overview-item-value-item-caret.is-down {
	color: #df4751;
}

.overview-item.is-comparing:not(.is-rating):after { 
	content: '';
	position: absolute;
	left: 50%;
	top: 0px;
	bottom: 1px;
	width: 1px;
	background-color: #4881bb;
	z-index: 2;
}

.overview-item-value-rating {
	width: 48px;
	font-size: 28px!important;
	font-weight: 700!important;
	height: 64px;
}

.overview-item-value-rating >>> .rating-value {
	top: 50%;
	transform: translateY(-50%);
}

.overview-item.is-comparing .overview-item-value-item {
	width: 50%;
}

.overview-item-value[data-theme="green"] .overview-item-value-item,
.overview-item-value.is-up {
	color: #2FC482;
}

.overview-item-value[data-theme="red"] .overview-item-value-item,
.overview-item-value.is-down {
	color: #df4751;
}

.overview-item-value.is-empty[data-theme="green"] .overview-item-value-item,
.overview-item-value.is-empty.is-up,
.overview-item-value.is-empty[data-theme="red"] .overview-item-value-item,
.overview-item-value.is-empty.is-down,
.overview-item-value.is-empty .overview-item-value-item,
.overview-item-value.is-empty {
	color: #eee;
}

.overview-item-compare {
	display: flex;
	flex-direction: row;
	justify-content: center;
	z-index: 2;
}

.overview-item-compare-item {
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: #4881bb;
	margin-top: 4px;
	text-align: center;
	width: 50%;
}

.overview-item.is-rating .overview-item-compare-item {
	color: #fff;
}

.overview-item-label {
	background-color: #4881bb;
	border-radius: 0px 0px 4px 4px;
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	line-height: 18px;
	color: #fff;
	flex-grow: 1;
	z-index: 2;
	white-space: nowrap;
	padding: 0px 8px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.overview-item.is-inset .overview-item-label {
	background-color: #fff;
	color: #333;
	overflow: visible;
	white-space: initial;
	line-height: 14px;
}

.overview-item-label small {
	text-align: center;
    font-size: 10px;
    font-weight: 300;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
    color: #999;
    margin-top: 2px;
    line-height: 12px;
	height: 12px;
	display: block;
}

.overview-item-info {
	color: #588594;
	font-size: 16px;
	position: absolute;
	right: 0px;
	bottom: -24px;
	cursor: pointer;
}

.overview-item-value-item-range {
	font-size: 20px;
	line-height: 20px;
}

.overview-item-value-item-range .caret {
	left: 50%;
	margin-left: -6px;
	margin-top: 3px;
	margin-bottom: 3px;
	opacity: 0.5;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 6px 0 6px;
	border-color: #4881bb transparent transparent transparent;
}

.overview-item-value-label {
	display: block;
    margin-top: -4px;
    font-size: 12px;
    height: 12px;
    font-weight: 300;
    line-height: 12px;
    color: #666;
}

</style>