<template>
	
<core-list-cell :format="format" class="rank">

	<div class="rank-position" v-if="position"><span v-if="position < 10">0</span>{{ position }}</div>

	<div class="rank-change" v-if="!format.onlyPosition && item.rank.position" v-bind:class="{'is-up': item.rank.movement > 0, 'is-down': item.rank.movement < 0, 'is-new': item.rank.new}">

		<div class="rank-change-pill">
			<i class="fa fa-caret-up" v-if="item.rank.movement > 0"></i>
			<i class="fa fa-caret-down" v-if="item.rank.movement < 0"></i>
			<span v-if="item.rank.movement !== 0">{{ item.rank.movement | abs }}</span>
			<span v-if="item.rank.movement === 0 && !item.rank.new">=</span>
			<span v-if="item.rank.new">new</span>
		</div>

	</div>

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format', 'index'],

	computed: {

		position: function() {

			return (this.item.rank.position) ? this.item.rank.position : this.index + 1

		}

	}

}

</script>

<style scoped>

.rank {
	min-width: 48px;
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	text-align: right;
	padding-right: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media only screen and (max-width: 767px) {

	.rank {
		width: 32px;
		min-width: 32px;
	}

}

.rank-position {
	color: #666;
	font-weight: 500;
	font-size: 28px;
}

@media only screen and (max-width: 767px) {

	.rank-position {
		font-size: 18px;
	}

}

.rank-position span {
	opacity: 0.15;
}

.rank-change {
	font-size: 12px;
	color: #999;
}

.rank-change-pill {
	display: inline-block;
	font-weight: 400;
	border-radius: 4px;
	border-radius: 4px;
}

.rank-change.is-up .rank-change-pill {
	color: rgba(48, 181, 117, 0.75);
}

.rank-change.is-down .rank-change-pill {
	color: rgba(181, 84, 49, 0.75);
}

.rank-change.is-new .rank-change-pill {
	color: rgba(51, 149, 181, 0.75);
}

</style>
