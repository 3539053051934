import _ from 'underscore'

export default {

	data: function() {

		return {

			is: {
				valid: false
			},

			valid: {}

		}

	},

	created: function() {

		_.each(this.validation, function(value, key) {

			this.valid[key] = false

		}.bind(this))

		this.is.valid = false

	},

	methods: {

		validate: function(data) {

			this.valid[data.name] = data.valid
			this.checkValid()

		},

		checkValid: function() {

			this.is.valid = _.every(this.valid, function(value) {

				return value

			})

		}

	}

}