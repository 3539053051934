<template>

<core-filter-advanced-group title="Locations" :count="count">

	<core-filter-input-suggest placeholder="Start typing a location..." :toggle="true" field="location_name" :filter="value('play/locations')" api="locations/suggest" data="locations" />

	<core-filter-input-toggle text="Show no location only" on="Yes" off="No" :filter="value('play/nolocation')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['play.locations', 'play.nolocation']
		}
	}

}

</script>