<template>

<div class="history" v-bind:class="{'is-empty': history.length === 1, 'is-dark': dark}">

	<template v-if="history.length > 1">

	<div v-for="(item, index) in history" :key="index" v-bind:style="{height: chartHeight(item.value)}" :data-band="Math.floor(item.value)" v-tooltip="tooltip(item)">

	</div>

	</template>

	<div v-if="history.length === 1" class="history-empty">

		n/a

	</div>

</div>

</template>

<script>

export default {
	
	props: ['history', 'isTrend', 'dark'],

	methods: {

		chartHeight: function(value) {

			return (value * 10).toString() + '%'

		},

		tooltip: function(item) {

			var html = []

			html.push(this.$options.filters.round(item.value, 1, true))

			if (!item.initial && !this.isTrend) html.push('<small>' + this.$options.filters.formatDate(item.date, 'MMMM Do YYYY') + '</small>')

			if (item.initial && !this.isTrend) html.push('<small>Initial sync</small>')

			if (this.isTrend) html.push('<small>' + item.changes.added + ' added &middot; ' + item.changes.updated + ' updates &middot; ' + item.count + ' total</small>')

			return html.join('')

		}

	}

}

</script>

<style scoped>

.history {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
}

.history.is-empty {
	align-items: center;
}

.history > div {
	width: 8px;
	border-radius: 4px;
	margin-right: 1px;
}

.history > div[data-band="10"] { background-color: #249563; }
.history > div[data-band="9"] { background-color: #249563; }
.history > div[data-band="8"] { background-color: #2FC482; }
.history > div[data-band="7"] { background-color: #1d8acd; }
.history > div[data-band="6"] { background-color: #5369a2; }
.history > div[data-band="5"] { background-color: #5369a2; }
.history > div[data-band="4"] { background-color: #df4751; }
.history > div[data-band="3"] { background-color: #df4751; }
.history > div[data-band="2"] { background-color: #db303b; }
.history > div[data-band="1"] { background-color: #db303b; }
.history > div[data-band="0"] { background-color: #db303b; }

.history-empty {
	align-items: center;
	color: #ccc;
	font-weight: 400;
	font-size: 14px;
	width: auto!important;
	border: 0px!important;
}

.history.is-dark .history-empty {
	color: rgba(255, 255, 255, 0.75);
}

</style>