<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ typeName }}</h1>

		<p>There are <b>{{ games.length }}</b> games for this {{ typeParent }}. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :count="item.playsCount" v-bind:key="index" :isUserRating="isUser" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'type',
			params: [
				'filter'
			],
			games: [],
			typeName: ''
		}

	},

	computed: {

		typeParent: function() {

			return (this.data.filter) ? ((this.data.filter.type === 'category') ? 'category' : 'mechanic') : ''

		}

	},

	methods: {

		response: function(json) {

			this.typeName = json.type
			this.games = json.games

		}

	}

}

</script>