<template>

<core-dropdown class="input" v-bind:class="{'is-inline': inline}" trigger="manual" v-on:forceClose="is.expanded = false" :expanded="is.expanded" v-click-outside="onOutsideClick">

	<core-button v-if="inline" v-on:click.native="onToggleClick" theme="filter" class="inline-button">Sorting by <b>{{ text }}</b></core-button>

	<core-button v-if="!window.is.mobile && !inline" v-on:click.native="onToggleClick" :caret="true" tooltip="Change sorting<small>Use shift-click to stack sorting" theme="filter" class="sort-button"><template v-if="!window.is.tablet">Sorting by </template><b>{{ text }}</b></core-button>

	<core-button v-if="window.is.mobile && !inline" v-on:click.native="onToggleClick" theme="filter" :iconOnly="true" icon="sort" />
	
	<core-dropdown-list class="sort-options" theme="filter" align="right" type="button" :caret="true">

		<template v-for="(item, index) in options">

			<div v-bind:key="index" v-if="valid(item)">

				<core-dropdown-item v-if="itemType(item) == 'divide'" :type="itemType(item)" class="sort-options-item" />

				<core-dropdown-item :sortindex="isSorted(item)" v-if="itemType(item) == 'default'" type="action" class="sort-options-item" v-bind:class="{'is-active': isSorted(item)}" v-propagation>

					<div v-if="isSorted(item) && sortingCount > 1" class="sort-options-item-index" v-on:click="onClickOption($event, item)">{{ isSorted(item) }}</div>
					<div v-if="item !== '-'" class="sort-options-item-text" v-on:click="onClickOption($event, item)">{{ optionText(item) }}</div>
					<div v-if="item !== '-'" class="sort-options-item-direction">
						<i class="fa fa-caret-up" v-on:click="onClickOption($event, item, false)" v-bind:class="{'is-active': !isReversed(item) && isSorted(item)}"></i>
						<i class="fa fa-caret-down" v-on:click="onClickOption($event, item, true)" v-bind:class="{'is-active': isReversed(item) && isSorted(item)}"></i>
					</div>

				</core-dropdown-item>

			</div>

		</template>

	</core-dropdown-list>

</core-dropdown>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: [
		'filter', 'page', 'inline', 'sortOptions'
	],
	
	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		sortingCount: function() {
		
			return this.$util.sortcount(this.values)

		},

		buttonType: function() {

			return (this.inline) ? 'plain' : 'filter'

		},

		value: function() {

			return this.$store.getters['filter/' + this.filter]

		},

		values: function() {

			return this.$store.getters['filter/' + this.filter.replace('/sort', '')]

		},

		text: function() {

			var sorts = this.value.replace(/-/g, '').split(',')

			if (sorts.length === 1) {

				var text = this.$CONSTANTS.SORT[(this.isReversed(sorts[0])) ? 'DESC' : 'ASC'][sorts[0]] + ' ' + this.optionText(sorts[0], true)

				return (this.window.is.tablet) ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : text.toLowerCase()
			
			} else {
			
				return sorts.length + ' criteria'
			
			}

		},

		options: function() {

			if (this.page === 'collection') {

				if (this.$store.getters['context/type'] === this.$CONSTANTS.TYPE.GROUPS) {

					return (this.$store.getters['permissions/is/member']) ? this.$CONSTANTS.SORT.COLLECTION['groups.own'] : this.$CONSTANTS.SORT.COLLECTION['groups.other']

				} else if (this.$store.getters['context/type'] === this.$CONSTANTS.TYPE.BUDDIES) {

					return (this.$store.getters['permissions/is/owner']) ? this.$CONSTANTS.SORT.COLLECTION['buddies.own'] : this.$CONSTANTS.SORT.COLLECTION['buddies.other']

				} else {

					return this.$CONSTANTS.SORT.COLLECTION[this.$store.getters['context/type']]

				}

			}

			if (this.page === 'ratings') {

				if (this.$store.getters['context/type'] === this.$CONSTANTS.TYPE.GROUPS) {

					return (this.$store.getters['permissions/is/member']) ? this.$CONSTANTS.SORT.RATINGS['groups.own'] : this.$CONSTANTS.SORT.RATINGS['groups.other']

				} else if (this.$store.getters['context/type'] === this.$CONSTANTS.TYPE.BUDDIES) {

					return (this.$store.getters['permissions/is/owner']) ? this.$CONSTANTS.SORT.RATINGS['buddies.own'] : this.$CONSTANTS.SORT.RATINGS['buddies.other']

				} else {

					return this.$CONSTANTS.SORT.RATINGS[this.$store.getters['context/type']]

				}

			}

			if (this.page === 'plays') {

				if (this.$store.getters['filter/plays/play/group']) {

					if (this.$store.getters['context/type'] === this.$CONSTANTS.TYPE.GROUPS) {

						return (this.$store.getters['permissions/is/member']) ? this.$CONSTANTS.SORT.PLAYS.GAME['groups.own'] : this.$CONSTANTS.SORT.PLAYS.GAME['groups.other']

					} else if (this.$store.getters['context/type'] === this.$CONSTANTS.TYPE.BUDDIES) {

						return (this.$store.getters['permissions/is/owner']) ? this.$CONSTANTS.SORT.PLAYS.GAME['buddies.own'] : this.$CONSTANTS.SORT.PLAYS.GAME['buddies.other']

					} else {

						return this.$CONSTANTS.SORT.PLAYS.GAME[this.$store.getters['context/type']]

					}

				} else {

					return this.$CONSTANTS.SORT.PLAYS.PLAY

				}

			}

			if (this.page === 'users') return this.$CONSTANTS.SORT.USERS

			if (this.page === 'lists') return this.$CONSTANTS.SORT.LISTS

			return this.sortOptions

		}

	},

	watch: {

		is: {

			deep: true,

			handler: function(n) {

				if (this.inline && this.window.is.mobile) {

					this.$pubsub.$emit((n.expanded) ? 'open.inline.sort' : 'close.inline.sort')

				}

			}

		}

	},

	methods: {

		isSorted: function(item) {
		
			return this.$util.sorted(item, this.values) 

		},

		isReversed: function(item) {
		
			return this.$util.sorted(item, this.values, true) 

		},

		valid: function(sort) {

			if (this.$CONSTANTS.SORT.VALID[sort] !== undefined) {

				return this.$CONSTANTS.SORT.VALID[sort](this.values)

			} else {

				return true

			}

		},

		onToggleClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		itemType: function(key) {

			return (key === '-') ? 'divide' : 'default'

		},

		optionText: function(key, ignoreConvert) {

			var text = this.$CONSTANTS.SORT.NAME[key]
			ignoreConvert = ignoreConvert || false

			if (this.page === 'ratings') text = this.$CONSTANTS.SORT.ALTNAME.RATINGS[key] || text
			if (this.page === 'plays') text = this.$CONSTANTS.SORT.ALTNAME.PLAYS[key] || text

			text = text.replace(':user', ((this.hasMe || this.isSelf) && !ignoreConvert) ? 'Your' : 'User')

			return text

		},

		onClickOption: function(e, value, reverse) {

			if (reverse === undefined) {

				reverse = (this.value === value) ? !this.reverse : this.reverse

			}

			this.$store.commit('filter/' + this.filter, {
				value: value,
				reverse: reverse,
				stack: e.shiftKey
			})

			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

.inline-button {
	width: 100%!important;
    text-align: left!important;
    border-left: 0px!important;
    padding: 0px!important;
    height: 24px!important;
    line-height: 24px!important;
    opacity: 1!important;
	margin-left: 0px!important;
    margin-bottom: 10px!important;
	background-color: #497988!important;
	border: 1px solid #024359!important;
	padding: 0px 5px!important;
}

.is-desktop .inline-button:hover {
	background-color: #024359!important;
}

.inline-button >>> .button-content {
	font-size: 12px!important;
}

.inline-button >>> .button-icon {
	background-color: #024359!important;
    height: 24px!important;
    line-height: 24px!important;
    width: 24px!important;
    text-align: center!important;
    border-radius: 4px!important;
}

.is-desktop .inline-button:hover >>> .button-icon {
	background-color: #003142!important;
}

.input.is-inline .sort-options {
    width: auto;
    min-width: auto;
    right: calc(100% + 15px);
    border-radius: 4px 0 0 4px;
    top: -4px!important;
    padding: 4px 0;
    background-color: #035874;
    z-index: 2000;
}

.is-mobile .input.is-inline .sort-options {
	width: 100%;
    top: 144px!important;
    border-radius: 0px!important;
    left: 0px;
    right: 0px;
    min-width: 100%;
    bottom: 0px;
    position: fixed!important;
    z-index: 10000000;
	background-color: #3395B5;
}

.input.is-inline .sort-options:after {
    content: " ";
    position: absolute;
    left: 100%;
    width: 0;
    top: 10px;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent #035874;
}

.sort-options-item {
	white-space: nowrap;
	padding: 0px 0px 0px 15px!important;
	cursor: pointer;
}

.sort-options-item:hover {
	color: #fff;
}

.sort-options-item >>> span {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.sort-options-item {
		padding: 0px 0px 0px 8px!important;
	}

}

.sort-options-item-text {
	height: 24px;
	line-height: 24px;
	vertical-align: middle;
	flex-grow: 1;
	text-overflow: ellipsis;
	overflow: hidden;
	padding-right: 10px;
	white-space: nowrap;
}

@media only screen and (max-width: 767px) {

	.sort-options-item-text {
		height: 32px;
		line-height: 32px;
		width: calc(100% - 64px - 8px);
	}

}

.sort-options-item-direction {
	display: inline-block;
	vertical-align: middle;
	font-size: 0;
	flex-shrink: 0;
	width: 48px;
	height: 24px;
}

@media only screen and (max-width: 767px) {

	.sort-options-item-direction {
		height: 32px;
		width: 64px;
	}

}

.sort-options-item-direction .fa {
	display: inline-block;
	width: 20px;
	height: 24px;
	text-align: center;
	line-height: 24px;
	font-size: 14px;
	opacity: 0.25;
	cursor: pointer;
}

@media only screen and (max-width: 767px) {

	.sort-options-item-direction .fa {
		height: 32px;
		line-height: 32px;
		width: 32px;
	}

}

.sort-options-item-direction .fa.is-active {
	opacity: 1;
}

.is-desktop .sort-options-item-direction .fa:hover {
	opacity: 1;
}

.sort-options-item[sortIndex="1"] {
	background-color: #61c7ff;
}

.sort-options-item[sortIndex="2"] {
	background-color: #8be356;
}

.sort-options-item[sortIndex="3"] {
	background-color: #ed5353;
}

.sort-options-item[sortIndex="4"] {
	background-color: #ed53df;
}

.sort-options-item[sortIndex="5"] {
	background-color: #ff9d3b;
}

.sort-options-item-index {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	font-size: 12px;
	font-weight: 500;
	width: 16px;
	height: 16px;
	margin-top: 4px;
	margin-right: 8px;
	text-align: center;
	line-height: 16px;
}

.is-mobile .sort-options-item-index {
	height: 24px;
	width: 24px;
	line-height: 24px;
}

</style>
