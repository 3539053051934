import _ from 'underscore'
import CONSTANTS from 'app/constants'

export default {
	
	clear: function(state, data) {

		_.each(state.data, function(item) {

			if (_.contains(data.games, item.game.id)) {

				item.tags = []

			} else if (_.contains(data.items, item.id)) {

				item.tags = []

			}

		})

	},

	add: function(state, data) {

		_.each(state.data, function(item) {

			if (data.context === CONSTANTS.TAG.CONTEXT.GAME) {

				if (_.contains(data.index, item.game.id)) {

					if (!_.contains(item.tags, data.tag)) {

						item.tags.push(data.tag)

					}

				}

			} else if (data.context !== CONSTANTS.TAG.CONTEXT.GAME) {

				if (_.contains(data.index, item.id)) {

					if (!_.contains(item.tags, data.tag)) {

						item.tags.push(data.tag)

					}

				}

			}

		})

	},

	remove: function(state, data) {

		_.each(state.data, function(item) {

			if (data.context === CONSTANTS.TAG.CONTEXT.GAME) {

				if (_.contains(data.index, item.game.id)) {

					if (_.contains(item.tags, data.tag)) {

						item.tags.splice(item.tags.indexOf(data.tag), 1)

					}

				}

			} else if (data.context !== CONSTANTS.TAG.CONTEXT.GAME) {

				if (_.contains(data.index, item.id)) {

					if (_.contains(item.tags, data.tag)) {

						item.tags.splice(item.tags.indexOf(data.tag), 1)

					}

				}

			}

		})

	},

	toggle: function(state, data) {

		_.each(state.data, function(item) {

			if (data.context === CONSTANTS.TAG.CONTEXT.GAME) {

				if (_.contains(data.index, item.game.id)) {

					if (_.contains(item.tags, data.tag)) {

						item.tags.splice(item.tags.indexOf(data.tag), 1)

					} else {

						item.tags.push(data.tag)

					}

				}

			} else if (data.context !== CONSTANTS.TAG.CONTEXT.GAME) {

				if (_.contains(data.index, item.id)) {

					if (_.contains(item.tags, data.tag)) {

						item.tags.splice(item.tags.indexOf(data.tag), 1)

					} else {

						item.tags.push(data.tag)

					}

				}

			}

		})

	}

}