<template>
	
<core-list-cell :format="format" type="rating" class="rating" :condensed="condensed" v-bind:class="{'is-clickable': showIndividualRatings, 'is-condensed': condensed}" v-if="!format.hideMobile || !window.is.mobile" >

	<div class="rating-score" :data-band="band" v-on:mouseenter="is.hover = true" v-on:mouseleave="is.hover = false">
			
		<div class="rating-value" v-on:click="onRatingsClick">

			<template v-if="average">{{ average }}</template>
			<template v-if="!average">-</template>
			
		</div>

		<div v-if="count && format.context !== 'user' && !condensed" class="rating-count">{{ formatCount(count) }}</div>

		<div v-if="showIndividualRatings && !forceHide && is.hover" class="rating-users" v-bind:class="{'is-transparent': format.transparent}">

			<div v-for="rating in ratings" v-bind:key="rating.user" class="rating-users-member">
					
				<core-avatar class="rating-users-member-avatar" :id="rating.user" type="user" tooltip="1"></core-avatar>
					
				<div class="rating-users-member-rating" :data-band="Math.floor(rating.value)">

					{{ rating.value }}

				</div>

			</div>

			<div v-on:click="onRatingsClick" v-show="totalRatings > maxUserRatings" class="rating-users-member rating-users-member-more" v-tooltip="'View all'" v-propagation>

				+{{totalRatings - maxUserRatings}} 

			</div>

		</div>

	</div>

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format', 'forceHide', 'condensed'],

	data: function() {

		return {
			average: '',
			count: 0,
			band: false,
			ratings: [],
			maxUserRatings: 8,
			is: {
				hover: false
			}
		}

	},

	computed: {

		showIndividualRatings: function() {

			return (this.format.context === 'group' || this.format.context === 'buddies') && this.item.group.rating_count

		},

		totalRatings: function() {

			return this.$_.keys(this.item.users.rated).length

		},

		members: function() {

			return this.$store.getters['data/users/associated']

		}

	},

	watch: {

		item: {
			handler: function() {

				this.updateVars()

			},
			deep: true
		}

	},

	created: function() {

		this.updateVars()

	},

	methods: {

		updateVars: function() {

			this.ratings = []

			if (this.format.context === 'group' || this.format.context === 'buddies') {

				this.$_.each(this.item.users.rated, function(value, key) {

					this.ratings.push({
						user: key,
						value: value
					})

				}.bind(this))

				if (this.ratings.length > this.maxUserRatings) {

					this.ratings = this.ratings.slice(0, this.maxUserRatings)

				}

				this.ratings = this.$_.sortBy(this.ratings, 'value')

			} 

			if (this.format.context === 'user') this.band = this.item.user.rating_band
			if (this.format.context === 'bgg') this.band = this.item.game.rating_band
			if (this.format.context === 'group') this.band = this.item.group.rating_band
			if (this.format.context === 'buddies') this.band = this.item.group.rating_band

			if (this.format.context === 'bgg') this.count = this.item.game.rating_count
			if (this.format.context === 'group') this.count = this.item.group.rating_count
			if (this.format.context === 'buddies') this.count = this.item.group.rating_count

			if (this.format.context === 'user') this.average = this.item.user.rating_avg
			if (this.format.context === 'bgg') this.average = this.item.game.rating_avg
			if (this.format.context === 'group') this.average = this.item.group.rating_avg
			if (this.format.context === 'buddies') this.average = this.item.group.rating_avg

		},

		formatCount: function(count) {

			if (count < 1000) {

				return count

			} else {

				return Math.floor(count / 1000).toString() + 'k'

			}

		},

		onRatingsClick: function() {

			if (this.format.context === 'group' || this.format.context === 'buddies') {

				this.$pubsub.$emit('expand.open', {
					id: this.item.game.id,
					section: 'ratings'
				})
				
				this.$emit('ratings')

			}

		}

	}

}

</script>

<style scoped>

.rating.is-clickable .rating-value {
	cursor: pointer;
}

.rating-score {
	position: relative;
	display: inline-block;
	width: 48px;
	height: 48px;
}

.rating.is-condensed .rating-score {
	height: 24px;
}

.rating-value {
	width: 48px;
	height: 48px;
	line-height: 48px;
	text-align: center;
	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	user-select: none;
}

.rating.is-condensed .rating-value {
	height: 24px;
	line-height: 24px;
	font-size: 14px;
}

.rating-score:nth-child(1) {
	margin-right: 7px;
}

.rating-count {
	display: block;
	padding: 0px 2px;
	min-width: 14px;
	height: 14px;
	font-weight: 400;
	font-size: 8px;
	text-align: center;
	color: #ffffff;
	line-height: 14px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.rating-comment {
	position: absolute;
	right: 1px;
	top: 1px;
	color: #fff;
	font-size: 12px;
	opacity: 0.8;
}

.rating-users {
	display: block;
	position: absolute;
	white-space: nowrap;
	background-color: #e9f3f7;
	right: 48px;
	padding-right: 7px;
	top: 0px;
	height: 48px;
	z-index: 10;
	padding-left: 32px;
}

.rating-users.is-transparent {
	background-color: transparent;
}

.rating-users-member {
	display: inline-block;
	margin-right: 1px;
	height: 48px;
	width: 24px;
	font-size: 0;
	vertical-align: top;
}

.rating-users-member-more {
	background-color: #999;
	color: #fff;
	font-size: 10px;
	margin-top: 24px;
	height: 24px;
	min-width: 24px;
	padding: 0px 4px;
	line-height: 24px;
	font-weight: 400;
	text-align: center;
	font-size: 12px;
	width: auto;
	cursor: pointer;
}

.is-desktop .rating-users-member-more:hover {
	background-color: #666;
}

.rating-users-member-avatar {
	display: block;
	width: 24px;
	height: 24px;
	font-size: 12px;
}

.rating-users-member-rating {
	display: block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	color: #fff;
	font-size: 12px;
	z-index: 2;
}

.rating-users-member-rating[data-band="10"] { background-color: #249563; }
.rating-users-member-rating[data-band="9"] { background-color: #249563; }
.rating-users-member-rating[data-band="8"] { background-color: #2FC482; }
.rating-users-member-rating[data-band="7"] { background-color: #1d8acd; }
.rating-users-member-rating[data-band="6"] { background-color: #5369a2; }
.rating-users-member-rating[data-band="5"] { background-color: #5369a2; }
.rating-users-member-rating[data-band="4"] { background-color: #df4751; }
.rating-users-member-rating[data-band="3"] { background-color: #df4751; }
.rating-users-member-rating[data-band="2"] { background-color: #db303b; }
.rating-users-member-rating[data-band="1"] { background-color: #db303b; }

.rating-score[data-band="10"] { background-color: #249563; }
.rating-score[data-band="9"] { background-color: #249563; }
.rating-score[data-band="8"] { background-color: #2FC482; }
.rating-score[data-band="7"] { background-color: #1d8acd; }
.rating-score[data-band="6"] { background-color: #5369a2; }
.rating-score[data-band="5"] { background-color: #5369a2; }
.rating-score[data-band="4"] { background-color: #df4751; }
.rating-score[data-band="3"] { background-color: #df4751; }
.rating-score[data-band="2"] { background-color: #db303b; }
.rating-score[data-band="1"] { background-color: #db303b; }
.rating-score[data-band="0"] { background-color: #efefef; color: #ccc; }
.rating-score[data-band="-"] { background-color: #efefef; color: #ccc; }

.rating-score[data-band="-"] .rating-count { display: none; }

</style>
