<template>

<com-item :name="'records.streaks.' + type" :has-more="true" v-on:more="onMoreClick" :size="size" :is-list="true" :loading="loading" :is-empty="!active" :title="title[type]">

	<template v-slot:stats>

		<app-streak :hideGames="hideGames" :item="item" :showSummary="showSummary" :user="!isUser" :label="label" v-for="(item, index) in data" v-bind:key="index" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'type', 'hideGames', 'showSummary', 'label'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			title: {
				win: 'Win streaks',
				loss: 'Loss streaks',
				play: 'Play streaks',
				drought: 'Play droughts'
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/records/streaks'][this.type]

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		onMoreClick: function() {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsRecordsStreaks',
				streakType: this.type
			})
		
		}

	}

}

</script>

<style scoped>

</style>