<template>

<core-filter-advanced-group :title="title" :count="count" v-if="isUser || hasMe">

	<core-filter-input-daterange :filter="value('rated/date/user')" />

	<core-filter-input-range text="Rating" min="0" max="10" :tooltip="true" :filter="value('rating/user')" step="0.1" pips="1" decimals="1" density="9" :toggle="true" />

	<core-filter-input-range text="Group disparity" v-if="isGroup && hasMe" :min="-10" :max="10" :tooltip="true" :filter="value('disparity/user/group')" decimals="0" step="1" pips="2" density="10" :toggle="true" />
	<core-filter-input-range text="Buddies disparity" v-if="isBuddies" :min="-10" :max="10" :tooltip="true" :filter="value('disparity/user/group')" decimals="0" step="1" pips="2" density="10" :toggle="true" />

	<core-filter-input-range text="BGG disparity" :min="-10" :max="10" :tooltip="true" :filter="value('disparity/user/community')" decimals="0" step="1" pips="2" density="10" :toggle="true" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['rated.date.user', 'rating.user', 'disparity.user.group', 'disparity.user.community']
		}
	},

	computed: {

		title: function() {

			return ((this.isSelf || this.hasMe) ? 'Your' : 'User') + ' ratings'

		}

	}

}

</script>