import Store from 'app/store'
import CONSTANTS from 'app/constants'
import PubSub from 'core/pubsub'
import _ from 'underscore'

function checkStatus(to, from, next) {

	PubSub.$emit('unready')
	PubSub.$emit('menu.close')
	PubSub.$emit('close.modals')
	PubSub.$emit('panel.close')
	PubSub.$emit('switch.collapse')

	// logged in and requires guest
	if (Store.getters['session/loggedin'] && to.matched.some(record => record.meta.requireGuest)) {

		next({
			name: 'Collection',
			params: {
				type: CONSTANTS.TYPE.USERS,
				name: Store.getters['session'].name
			}
		})

	// not logged in and requires login
	} else if (!Store.getters['session/loggedin'] && to.matched.some(record => record.meta.requireUser)) {

		next({
			name: 'Home'
		})

	} else {

		if (to.matched.some(record => record.meta.requireVerify)) {

			if (!_.contains([CONSTANTS.TYPE.USERS, CONSTANTS.TYPE.GROUPS, CONSTANTS.TYPE.BUDDIES], to.params.type)) {

				next({
					name: '404'
				})

			} else {

				// do we need to verify
				if (

					// post-sync, re-verify
					(
						from.name === 'Sync' && to.params.name === from.params.name && to.params.type === from.params.type
					) || 
					// change of context
					(
						(to.params.name !== from.params.name || to.params.type !== from.params.type) && 
						(Store.getters['context/type'] !== to.params.type || Store.getters['context/slug'] !== to.params.name)
					)

				) {

					PubSub.$emit('verifying')

					// check context has been verified
					Store.dispatch('verify', {
						type: to.params.type,
						name: to.params.name
					}).then(function(json) {

						PubSub.$emit('unready')
						PubSub.$emit('verified')

						// is a sync required (first time load for user or buddies) 
						if (json.requireSync && to.params.type !== CONSTANTS.TYPE.GROUPS && to.params.name !== 'Sync') {

							next({
								name: 'Sync',
								params: to.params
							})

						} else {

							next()

						}

					}, function() {

						next({
							name: '404'
						})

					})

				} else {

					next()

				}

			}

		} else {

			next()

		}

	}

}

export default function handler(to, from, next) {

	// eslint-disable-next-line
	ga('set', 'page', to.fullPath)

	// eslint-disable-next-line
	ga('send', 'pageview')

	if (!Store.getters['session/key']) {

		Store.dispatch('api/session/check').then(function() {

			checkStatus(to, from, next)

		}, function() {

			PubSub.$emit('error', CONSTANTS.ERROR.UNKNOWN)

		})

	} else {

		checkStatus(to, from, next)

	}
	
}