<template>

<div class="tool-unsupported">

	<h2>Oh dear, incompatible browser!</h2>

	<p>Sorry, but this interactive tool is currently only supported by recent versions of Chrome, Firefox, and Safari. IE/Edge is currently not available.</p>

</div>

</template>

<script>

export default {}

</script>

<style scoped>

.tool-unsupported {
	text-align: center;
	color: #333;
	max-width: 640px;
	border: 1px dashed #ccc;
	padding: 15px;
	background-color: #fff;
}

.tool-unsupported h2 {
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 15px;
}

.tool-unsupported p {
	font-size: 16px;
	line-height: 20px;
}

</style>
