<template>

<div class="input" v-bind:class="{[bkgClass]: background, 'is-inset': inset, 'is-inline': inline}">

	<div class="input-texts">

		<div class="input-text" v-if="text">{{ text }}</div>
		<div class="input-subtext" v-if="subtext">{{ subtext }}</div>

	</div>

	<input type="text" ref="input" v-model="model" v-on:keyup="onKeyPress" :placeholder="placeholderText" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" />

</div>

</template>

<script>

export default {

	props: [
		'filter', 'background', 'placeholder', 'inset', 'inline', 'text', 'subtext'
	],

	data: function() {

		return {
			model: ''
		}

	},

	computed: {

		placeholderText: function() {

			return this.placeholder || 'Search...'

		},

		bkgClass: function() {

			return (this.background) ? 'bkg-' + this.background : 'bkg-dark'

		},

		value: function() {

			return this.$store.getters['filter/' + this.filter]

		}

	},

	created: function() {

		this.model = this.value

		this.$nextTick(function() {
			
			this.$emit('ready', this.$refs.input)

		}.bind(this))

	},

	watch: {

		value: function(newVal) {

			this.model = newVal

		}

	},

	methods: {

		onKeyPress: function() {

			this.$store.commit('filter/' + this.filter, this.model)

		}

	}

}

</script>

<style scoped>

.input {
	position: relative;
	height: 40px;
	padding: 4px 0px;
}

.input-text {
	color: #fff;
	font-weight: 400;
	font-size: 12px;
}

.input-subtext {
	color: #fff;
	font-weight: 400;
	font-size: 12px;
	opacity: 0.5;
	margin: 2px 0px 8px 0px;
}

.input.is-inline {
	padding: 8px;
	height: auto;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	margin-bottom: 8px;
}

.input.is-inset {
	margin-bottom: 8px;
	height: auto;
}

.input input {
	width: 100%;
	height: 32px;
	color: #fff;
	border-radius: 4px;
	padding: 0px 8px;
	font-weight: 400;
	font-size: 12px;
	background-color: #3395B5;
}

.input.is-inline input,
.input.is-inset input {
	background-color: rgba(255, 255, 255, 0.25);
}

.input input::placeholder {
	color: #fff;
}

</style>
