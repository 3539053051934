<template>

<div class="table">

	<div class="table-head row">

        <div v-for="(column, index) in columns" :key="index" class="table-head-cell">{{ column }}</div>

    </div>

    <slot></slot>

</div>

</template>

<script>

export default {

	props: ['layout', 'columns']

}

</script>

<style scoped>

.table {
    height: 100%;
    display: grid;
    grid-template-rows: 40px 1fr 1fr 1fr 1fr;
}

.table-head {
	height: 40px;
    display: grid;
	font-size: 12px;
	font-weight: 500;
	color: #333;
	border-bottom: 1px solid #ddd;
}

.table-head-cell {
	padding: 0px 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.table-head-cell:first-child {
    justify-content: flex-start;
    padding-left: 15px;
}

.table-head-cell:last-child {
    padding-right: 15px;
}

</style>