<template>

<core-list-expand :id="item.id" :game="item.game.id" :name="item.game.name" v-on:tab="onTabClick" v-on:close="onCloseClick" :selected="current">

	<template v-slot:tabs>

		<core-list-expand-tab value="about" text="About" v-on:click="onTabClick('about')" :selected="isSelected('about')" v-if="!isUser || (isUser && filter.play.group)" />

		<core-list-expand-tab value="ratings" text="Ratings" :count="ratings" v-on:click="onTabClick('ratings')" :selected="isSelected('ratings')" v-if="isGroupOrBuddies" />

		<core-list-expand-tab value="comments" text="Comments" :count="comments" v-on:click="onTabClick('comments')" :selected="isSelected('comments')" v-if="isGroupOrBuddies" />

		<core-list-expand-tab value="plays" text="Plays" :count="plays" v-on:click="onTabClick('plays')" :selected="isSelected('plays')" v-if="isGroupOrBuddies" />

		<core-list-expand-tab value="opponents" text="Players" :count="opponents" v-on:click="onTabClick('opponents')" :selected="isSelected('opponents')" v-if="isUser && filter.play.group" />

		<core-list-expand-tab value="players" text="Players" :count="players" v-on:click="onTabClick('players')" :selected="isSelected('players')" v-if="!isUser && filter.play.group" />

	</template>

	<template v-slot:sections>

		<app-expand-about :id="item.game.id" :version="item.game.version" :selected="isSelected('about')" />
		<app-expand-ratings :item="item" :selected="isSelected('ratings')" />
		<app-expand-comments :item="item" :selected="isSelected('comments')" />
		<app-expand-plays :item="item" :selected="isSelected('plays')" />
		<app-expand-opponents :item="item" :selected="isSelected('opponents')" />
		<app-expand-players :item="item" :selected="isSelected('players')" />

	</template>

</core-list-expand>

</template>

<script>

import Expand from 'core/mixin/list/Expand.js'

export default {

	mixins: [Expand],

	data: function() {

		return {
			default: 'about'
		}

	},

	computed: {

		id: function() {

			return this.item.id

		},

		filter: function() {

			return this.$store.getters['filter/plays']

		},

		plays: function() {

			return this.item.users.totalPlays

		},

		ratings: function() {

			return this.$_.keys(this.item.users.rated).length

		},

		comments: function() {

			return this.item.users.totalComments

		},

		opponents: function() {

			return this.item.players.length

		},

		players: function() {

			return this.$_.keys(this.item.users.played).length

		}

	}

}

</script>