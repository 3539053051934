import router from 'app/router'

export default {

	namespaced: true,

	state: {},

	getters: {

		active: function() {

			return {}

		},

		values: function(state) {

			return state

		}

	},

	mutations: {

		reset: function() {

		}

	},

	actions: {

		analyse: function(context) {

			return new Promise(function(resolve) {

				context.commit('reset')

				resolve()

			})

		},

		route: function() {

			var urlParams = {}

			router.replace({name: 'InsightsMembers', query: urlParams}).catch(function() {})

		}

	}

}