<template>

<div class="progress" ref="progress" :class="{'is-dark': dark}">

	<div class="progress-value">{{ percent | round }}<small>%</small></div>

</div>

</template>

<script>

import ProgressBar from 'progressbar.js'

export default {

	props: ['percent', 'dark'],

	mounted: function() {

		this.progress = new ProgressBar.Circle(this.$refs.progress, {
			strokeWidth: 6,
			color: '#4881bb',
			trailColor: '#d0e8f0',
			trailWidth: 2,
			duration: 2000,
			easing: 'easeInOut',
			svgStyle: {
				position: 'absolute',
				left: '0px',
				top: '0px'
			}
		})

		var percent = (1 / 100) * this.percent

		percent = (percent > 100) ? 100 : percent

		this.progress.animate(percent)

	}

}

</script>

<style scoped>

.progress {
	width: 72px;
	height: 72px;
}

.progress-value {
	font-size: 24px;
	font-weight: 700;
	color: #4881bb;
	line-height: 74px;
	text-align: center;
	width: 72px;
	height: 72px;
}

.progress-value small {
	font-size: 10px;
	color: #ccc;
	margin-left: 1px;
}

</style>
