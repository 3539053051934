import BaseConfig from 'core/constants'
import deepmerge from 'deepmerge'

export default deepmerge(BaseConfig, {

	MONTH: {
		1: 'January',
		2: 'February',
		3: 'March',
		4: 'April',
		5: 'May',
		6: 'June',
		7: 'July',
		8: 'August',
		9: 'September',
		10: 'October',
		11: 'November',
		12: 'December'
	},

	DAY: {
		1: 'Sunday',
		2: 'Monday',
		3: 'Tuesday',
		4: 'Wednesday',
		5: 'Thursday',
		6: 'Friday',
		7: 'Saturday',
	},

	DAY_SHORT: {
		1: 'Sun',
		2: 'Mon',
		3: 'Tue',
		4: 'Wed',
		5: 'Thu',
		6: 'Fri',
		7: 'Sat',
	},

	TAG: {
		CONTEXT: {
			GAME: 0,
			COLLECTION: 1,
			RATING: 2,
			PLAY: 3
		}
	},

	SERVICE: {
		NAME: {
			COLLECTION: 'collection',
			PLAYS: 'plays',
			RATINGS: 'ratings'
		},
		TITLE: {
			'collection': 'Collection',
			'plays': 'Plays',
			'ratings': 'Ratings'
		},
		VALUE: {
			'collection': 1,
			'ratings': 2,
			'plays': 3
		},
		SELECT: {
			MODE: {
				NONE: 0,
				ALL: 1
			}
		}
	},

	ACTIVITY: {
		TYPE: {
			RATING: 2,
			COLLECTION: 3,
			INITIATED: 4,
			REGISTERED: 5,
			PLAY: 6,
			GROUP: 7,
			MEMBER: 8,
			HINDEX: 9,
			LIST: 10,
			EDITION: 11,
			COMMENT: 12,
			BUDDY: 13,
			BUDDIES: 14,
			STATUS: 15,
			TAG: 20,
			TAG_ATTACH: 21
		},
		CONTEXT: {
			ADDED: 1,
			UPDATED: 2,
			REMOVED: 3
		}
	},

	CONFIG: {
		TYPE: {
			INSIGHTS: {
				RECORDS: 1
			},
			LISTS: {
				UNPLAYED: 2
			}
		},
		NAME: {
			1: 'insights/records',
			2: 'lists/unplayed'
		}
	},

	PRESET: {
		TYPE: {
			COLLECTION: 1,
			PLAYS: 2,
			ACTIVITY: 3,
			LISTS: 4,
			RATINGS: 5
		},
		ID: {
			'collection': 1,
			'plays': 2,
			'activity': 3,
			'lists': 4,
			'ratings': 5
		},
		NAME: {
			1: 'collection',
			2: 'plays',
			3: 'activity',
			4: 'lists',
			5: 'ratings'
		},
		TITLE: {
			1: 'Collection',
			2: 'Play',
			3: 'Activity',
			4: 'List',
			5: 'Ratings'
		}
	},

	SOURCE: {
		USER: 1,
		GROUP: 2,
		BUDDIES: 3
	},

	SYNC: {
		STANDARD: 1,
		FULL: 2,
		DEEP: 3
	},

	LISTS: {
		TEMPLATE: {
			UNPLAYED: 3
		},
		RANKING: {
			IMPORT: 1,
			BUILD: 2,
			COMPLETE: 3
		},
		TYPE: {
			AUTOMATED: 1,
			MANUAL: 2,
			RANKING: 3
		},
		TYPE_ICON: {
			1: 'fa-cogs',
			2: 'fa-user',
			3: 'fa-balance-scale'
		},
		TYPE_NAME: {
			1: 'Automated list',
			2: 'Manual list',
			3: 'Ranking list'
		},
		TYPE_SHORTNAME: {
			1: 'Automated',
			2: 'Manual',
			3: 'Ranking'
		},
		FREQUENCY: {
			MONTHLY: 1,
			YEARLY: 2,
			LIVE: 3,
			ADHOC: 4
		},
		FREQUENCY_NAME: {
			1: 'Monthly',
			2: 'Yearly',
			3: 'Live',
			4: 'Ad-hoc'
		}
	},

	INSIGHTS: {
		GRAPH: {
			POLAR: 'polar',
			BAR: 'bar'
		},
		COLLECTION: {
			RARITY: {
				MOST: 'most',
				LEAST: 'least'
			},
			TYPE: {
				CATEGORY: 'category',
				MECHANIC: 'mechanic'
			},
			YEAR: {
				VERSION: 'version',
				FIRST: 'first'
			},
			DURATION: {
				RANGE: 'range',
				MIN: 'min',
				MAX: 'max',
				MID: 'mid',
				COUNT: 'count'
			},
			COUNT: {
				ANY: 'any',
				P1: '1',
				P2: '2',
				P3: '3',
				P4: '4',
				P5: '5',
				P6: '6'
			}
		},
		WORTH: {
			GAMES: {
				MOST: 'most',
				LEAST: 'least',
				LOW: 'lowest',
				HIGH: 'highest'
			},
			RATINGS: {
				TOTAL: 'total',
				AVERAGE: 'average'
			},
			DESIGNERS: {
				TOTAL: 'total',
				AVERAGE: 'average'
			},
			PUBLISHERS: {
				TOTAL: 'total',
				AVERAGE: 'average'
			},
			TRENDING: {
				DIRECTION: {
					UP: 'up',
					DOWN: 'down'
				},
				PERIOD: {
					QUARTER: '3',
					HALF: '6',
					YEAR: '12',
					OWNED: 'owned'
				}
			},
			VALUATIONS: {
				GAMES: 'games',
				EXPANSIONS: 'expansions',
				ALL: 'all'
			},
			PLAYS: {
				TOTAL: 'total',
				AVERAGE: 'average'
			}
		},
		SHELF: {
			LONGEST: {
				CURRENT: 'current',
				ALL: 'all'
			},
			CUMULATIVE: {
				DESIGNERS: 'designers',
				PUBLISHERS: 'publishers'
			}
		},
		RATINGS: {
			DESIGNERS: {
				BEST: 'best',
				MOST: 'most'
			},
			PUBLISHERS: {
				BEST: 'best',
				MOST: 'most'
			}
		},
		HEATMAP: {
			PLAYS: 'plays',
			WINS: 'wins',
			WEIGHT: 'weight',
			NEW: 'new'
		},
		SECTION: {
			PLAYS: 'plays',
			OPPONENTS: 'plays.opponents',
			RECORDS: 'plays.records',
			COLLECTION: 'collection',
			RATINGS: 'ratings',
			MEMBERS: 'members'
		},
		SECTION_OPTIONS: {
			'plays': 'Plays',
			'plays.opponents': 'Opponents',
			'plays.records': 'Records',
			'collection': 'Collection',
			'ratings': 'Ratings',
			'members': 'Members'
		},
		PERIOD: {
			ALLTIME: 'all',
			CURRENT: 'current'
		},
		PERIOD_OPTIONS: {
			'all': 'All time',
			'current': 'Last 12 months'
		}
	},

	LANG: {
		TIP: {
			URL: 'Ability to customise group URL is a perk for using the tip jar.'
		}
	},

	GROUP: {
		MEMBERSHIP: {
			OPEN: 1,
			CLOSED: 2
		},
		MEMBERSHIP_OPTIONS: [
			{value: 1, title: 'Open', text: 'Anyone can add themselves to the group.'},
			{value: 2, title: 'Closed', text: 'Only you, the group owner, can add new members.'}
		]
	},

	LAYOUT: {
		PLAYS: {
			LIST: 1,
			CARDS: 0
		},
		RATINGS: {
			LIST: 1,
			CARDS: 0
		},
		COLLECTION: {
			CONDENSED: 2,
			LIST: 0,
			GRID: 1
		},
		LISTS: {
			CARDS: 0,
			LIST: 1
		},
		OPTIONS: {
			COLLECTION: [
				{ value: 0, name: 'Standard', icon: 'th-list' },
				{ value: 1, name: 'Cards', icon: 'th-large' }				
			],
			LISTS: [
				{ value: 0, name: 'Cards', icon: 'th-large' },	
				{ value: 1, name: 'List', icon: 'th-list' }				
			],
			PLAYS: [
				{ value: 0, name: 'Cards', icon: 'th-large' },
				{ value: 1, name: 'List', icon: 'th-list' }		
			],
			RATINGS: [
				{ value: 0, name: 'Cards', icon: 'th-large' },	
				{ value: 1, name: 'List', icon: 'th-list' }		
			]
		}
	},

	CURRENCY: {
		'USD': {
			code: 'USD',
			name: 'US Dollar',
			sign: '$',
			position: 'b',
			round: false
		},
		'AUD': {
			code: 'AUD',
			name: 'Australian Dollar',
			sign: '$',
			position: 'b',
			round: false
		},
		'GBP': {
			code: 'GBP',
			name: 'British Pound',
			sign: '£',
			position: 'b',
			round: false
		},
		'CAD': {
			code: 'CAD',
			name: 'Canadian Dollar',
			sign: '$',
			position: 'b',
			round: false
		},
		'CNY': {
			code: 'CNY',
			name: 'Chinese Yuan',
			sign: '¥',
			position: 'b',
			round: true
		},
		'EUR': {
			code: 'EUR',
			name: 'Euro',
			sign: '€',
			position: 'b',
			round: false
		},
		'JPY': {
			code: 'JPY',
			name: 'Japanese Yen',
			sign: '¥',
			position: 'b',
			round: true
		},
		'BRL': {
			code: 'BRL',
			name: 'Brazilian Real',
			sign: '$',
			position: 'b',
			round: false
		},
		'BGN': {
			code: 'BGN',
			name: 'Bulgarian Lev',
			sign: 'Лв',
			position: 'b',
			round: false
		}
	},

	SORT: {
		LISTS: ['name'],
		USERS: ['firstname', 'lastname', 'username', '-', 'collection', 'ratings', 'plays', 'hindex'],
		RATINGS: {
			'users': ['name', '-', 'rating.user', 'rating.date', '-', 'rating.revisions', 'rating.up', 'rating.down', '-', 'rating.gap.bgg', '-', 'plays', 'played.last'],
			'groups.own': ['name', '-', 'rating.date', '-', 'rating.user', 'rating.gap.user', '-', 'rating.group', 'votes.group', 'comments', 'rating.divisive', '-', 'rating.gap.bgg', '-', 'plays'],
			'groups.other': ['name', '-', 'rating.date', '-', 'rating.group', 'votes.group', 'comments', 'rating.divisive', '-', 'rating.gap.bgg', '-', 'plays'],
			'buddies.own': ['name', '-', 'rating.date', '-', 'rating.user', 'rating.gap.user', '-', 'rating.group', 'votes.group', 'comments', 'rating.divisive', '-', 'rating.gap.bgg', '-', 'plays'],
			'buddies.other': ['name', '-', 'rating.date', '-', 'rating.user', 'rating.gap.user', '-', 'rating.group', 'votes.group', 'comments', 'rating.divisive', '-', 'rating.gap.bgg', '-', 'plays']
		},
		COLLECTION: {
			'users': ['name', 'count', 'duration', 'weight', 'year', 'language.dependency', 'age.official', 'age.community', '-', 'collection.added', 'worth.value', 'worth.play', 'inventory.location', '-', 'plays', 'played.first', 'played.last', 'time', '-', 'rating.user', '-', 'rating.bgg', 'votes.bgg', 'rating.gap.bgg', 'rank', '-', 'wishlist.priority'],
			'groups.own': ['name', 'owners', 'count', 'duration', 'weight', 'year', 'language.dependency', 'age.official', 'age.community', '-', 'worth.value', '-', 'plays', '-', 'rating.user', '-', 'rating.group', 'votes.group', 'comments', 'rating.divisive', '-', 'rating.bgg', 'votes.bgg', 'rating.gap.bgg', 'rank', '-', 'status.prevowned', 'status.fortrade', 'status.want', 'status.wanttoplay', 'status.wanttobuy', 'status.wishlist', 'status.preordered', 'status.hasparts', 'status.wantparts'],
			'groups.other': ['name', 'owners', 'count', 'duration', 'weight', 'year', 'language.dependency', 'age.official', 'age.community', '-', 'worth.value', '-', 'plays', '-', 'rating.group', 'votes.group', 'comments', 'rating.divisive', '-', 'rating.bgg', 'votes.bgg', 'rating.gap.bgg', 'rank', '-', 'status.prevowned', 'status.fortrade', 'status.want', 'status.wanttoplay', 'status.wanttobuy', 'status.wishlist', 'status.preordered', 'status.hasparts', 'status.wantparts'],
			'buddies.own': ['name', 'owners', 'count', 'duration', 'weight', 'year', 'language.dependency', 'age.official', 'age.community', '-', 'worth.value', '-', 'plays', '-', 'rating.user', '-', 'rating.group', 'votes.group', 'comments', 'rating.divisive', '-', 'rating.bgg', 'votes.bgg', 'rating.gap.bgg', 'rank', '-', 'status.prevowned', 'status.fortrade', 'status.want', 'status.wanttoplay', 'status.wanttobuy', 'status.wishlist', 'status.preordered', 'status.hasparts', 'status.wantparts'],
			'buddies.other': ['name', 'owners', 'count', 'duration', 'weight', 'year', 'language.dependency', 'age.official', 'age.community', '-', 'worth.value', '-', 'plays', '-', 'rating.user', '-', 'rating.group', 'votes.group', 'comments', 'rating.divisive', '-', 'rating.bgg', 'votes.bgg', 'rating.gap.bgg', 'rank', '-', 'status.prevowned', 'status.fortrade', 'status.want', 'status.wanttoplay', 'status.wanttobuy', 'status.wishlist', 'status.preordered', 'status.hasparts', 'status.wantparts']
		},
		PLAYS: {
			PLAY: ['play.date', 'name', 'player.count', 'play.duration', 'play.location'],
			GAME: {
				'users': ['play.date', 'name', 'plays.all', 'plays.context', 'plays.winpercent', 'time'],
				'groups.own': ['play.date', 'name', 'plays.all', 'plays.context', 'plays.users', 'time'],
				'groups.other': ['play.date', 'name', 'plays.all', 'plays.context', 'plays.users', 'time'],
				'buddies.own': ['play.date', 'name', 'plays.all', 'plays.context', 'plays.users', 'time'],
				'buddies.other': ['play.date', 'name', 'plays.all', 'plays.context', 'plays.users', 'time']
			}
		},
		ALTNAME: {
			PLAYS: {
				'name': 'Game name'
			},
			RATINGS: {
				'name': 'Game name'
			}
		},
		VALID: {
			'plays.context': function(filter) {

				if (filter.played) {

					return filter.played.range.from || filter.played.range.to

				} else if (filter.date) {

					return filter.date.from || filter.date.to

				}

			}
		}
	}

})