<template>

<com-item name="size.compare" :size="size" :loading="loading" :stacked="true" title="Peers" :is-column="true">

	<template v-slot:about>

		<p>See how {{ your }} collection size compares to others.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-percentile :data="graph" class="compare-graph" />

		<div class="compare-metrics">

			<com-overview :inline="true" :inset="true" type="template" :value="compare.metrics.percentile | nth(true)" label="Percentile" />
			
			<com-overview :inline="true" :inset="true" :theme="(compare.metrics.median >= 0) ? 'green': 'red'" type="number" :value="compare.metrics.median | abs" label="vs Median" :caret="(compare.metrics.median >= 0) ? 'up': 'down'" />

		</div>

	</template>

</com-item>

</template>

<script>

import comOverview from './../common/Overview'
import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-overview': comOverview
	},

	data: function() {

		return {
			graph: {
				plain: true,
				datasets: [
					{ type: 'bar', data: [], highlight: false, backgroundColor: '#4881bb', tooltip: '{{value}} user{{value.plural}} <small>{{x.label}} {{x.sublabel}}</small>' }
				],
				axis: {
					x: {
						labels: []
					},
					y: {
						min: 10,
						points: 10
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		compare: function() {

			return this.$store.getters['page/insights/size/compare']

		},

		active: function() {

			return this.compare.histogram.data.length && !this.loading

		}

	},

	watch: {

		compare: function() {

			this.visualise()

		}

	},

	created: function() {

		this.visualise()

	},

	methods: {

		visualise: function() {

			this.graph.datasets[0].data = []
			this.graph.axis.x.labels = []

			this.graph.datasets[0].highlight = this.compare.histogram.position

			for (var i = 1; i <= this.compare.histogram.data.length; i++) {

				this.graph.axis.x.labels.push({
					label: this.compare.histogram.labels[i - 1],
					sublabel: ''
				})

				this.graph.datasets[0].data.push(this.compare.histogram.data[i - 1])

			}

		}
		
	}

}

</script>

<style scoped>

.compare-graph {
	width: 100%;
	padding: 0px 2px 0px 2px;
	flex-grow: 1;
}

.compare-metrics {
	height: 80px;
	flex-shrink: 0;
	border-top: 1px solid #ddd;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
}

</style>