<template>

<com-item name="plays.rank" :size="size" :is-blank="true" :loading="loading" :stacked="true" title="Rankings">

	<template v-slot:about>

		<p>How {{ your }} plays are distributed in the BGG rankings.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<com-rank :loading="loading">

			<template v-slot:bands>

				<com-band 
					v-for="(count, index) in bands" 
					:key="index" 
					:index="index"
					:count="count"
					:spread="{max: maxBand, noun: 'game'}"
					:is-selected="currentBand === index" 
					v-on:click="onBandClick(index)" 
				/>

			</template>

			<template v-slot:games>

				<com-game 
					v-for="game in own"
					:key="game.rank"
					:game="game"
				/>

			</template>

			<template v-slot:metrics>

				<com-overview label="Plays in Top 100" type="percent" :value="stats.top100" />

				<com-overview label="Plays in Top 1,000" type="percent" :value="stats.top1000" />

				<com-overview label="% Plays Ranked" type="percent" :value="stats.ranked" />

				<com-overview label="Highest Ranked Play" type="number" :value="stats.highest" />

				<com-overview label="Lowest Ranked Play" type="number" :value="stats.lowest" />

				<com-overview label="Longest Rank Streak" type="range" :value="stats.longest" />

				<com-overview label="Levels with Plays" type="number" :value="stats.levels" />

				<com-overview label="Level with Most Plays" type="range" :value="stats.levelmost" />

				<com-overview label="First Level with No Plays" type="range" :value="stats.levelnone" :empty="!stats.levelnone[0]" />

			</template>

		</com-rank>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comRank from './../common/Rank'
import comBand from './../common/rank/Band'
import comGame from './../common/rank/Game'
import comOverview from './../common/Overview'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-rank': comRank,
		'com-band': comBand,
		'com-game': comGame,
		'com-overview': comOverview
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'ranking.band' 

		},

		maxBand: function() {
		
			return this.$_.max(this.bands)
		
		},

		currentBand: function() {

			return this.$store.getters['filter/insights/plays/ranking/band']

		},

		own: function() {

			return this.data.own

		},

		bands: function() {

			return this.data.bands

		},

		average: function() {

			return this.data.average

		},

		stats: function() {

			return this.data.stats

		},

		data: function() {

			return this.$store.getters['page/insights/plays/rankings']

		},

		active: function() {

			return this.data && !this.loading

		}

	},

	methods: {
	
		onBandClick: function(value) {
		
			this.$store.commit('filter/insights/plays/ranking/band', value)
		
		}
	
	}

}

</script>

<style scoped>

</style>