<template>
	
<core-card-foot-stat :value="value" :subvalue="subvalue" text="play" :plural="true" />

</template>

<script>

export default {

	props: ['format', 'item', 'filter'],

	computed: {

		value: function() {

			if (this.format.context === 'filter') {

				if (this.item.context.user || this.item.context.users) {

					return (this.isUser) ? this.item.context.user.plays : this.item.context.users.totalPlays

				} else {

					return 0

				}

			} else {

				return (this.isUser) ? this.item.user.plays : this.item.users.totalPlays

			}

		},

		subvalue: function() {

			if (this.format.context === 'filter') {

				if (this.item.context.user || this.item.context.users) {

					if (this.format.showTotal) {

						return (this.isUser) ? this.item.user.plays : this.item.users.totalPlays

					}

				} 

			}

			return false

		}

	}

}

</script>

<style scoped>

</style>
