<template>

<core-panel>

	<template v-slot:head>

		<h1>Publishers</h1>

		<p v-if="filterValue === 'total'">The publishers with the highest total value of games. Excludes expansions.</p>
		<p v-if="filterValue === 'average'">The publishers with the highest average value of games. Minimum two games. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-person v-for="(item, index) in publishers" :hideCount="true" :item="item" type="publisher" v-bind:key="index" v-on:more="onMoreClick" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'publishers',
			publishers: []
		}

	},

	computed: {

		filterValue: function() {

			return (this.data.filter) ? this.data.filter.publishers : 'total'

		}

	},

	methods: {

		response: function(json) {

			this.publishers = json.publishers

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthPublisher',
				id: id,
				child: true
			})

		}

	}

}

</script>