<template>

<core-panel>

	<template v-slot:head>

		<h1>Disparities</h1>

		<p>The biggest rating disparities between {{ you }} and BGG. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in disparities" :item="item.game" v-bind:key="index" :subtext="subtext(item)" :dark="true">

			<app-game-item-field-rating :item="item" :format="{context: ratingContext, transparent: true}" class="played-item-rating" v-on:ratings="onMoreClick(item.game.id)" />
		
			<app-game-item-field-rating :item="item" :format="{context: 'bgg'}" class="played-item-rating" />

		</app-game>

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'disparities',
			disparities: []
		}

	},

	computed: {

		ratingContext: function() {

			return (this.$store.getters['context/is/user']) ? 'user' : 'group'

		},

		you: function() {

			if (this.isUser) {

				return (this.$store.getters['permissions/is/userself']) ? 'you' : 'them'

			} else {

				return 'the group'

			}

		}

	},

	methods: {

		subtext: function(item) {

			var difference = 0

			if (this.$store.getters['context/is/user']) {

				difference = Math.abs(item.user.rating_avg - item.game.rating_avg).toFixed(1)

			} else {

				difference = Math.abs(item.group.rating_avg - item.game.rating_avg).toFixed(1)

			}

			return 'Rating disparity of ' + difference

		},

		response: function(json) {

			this.disparities = json.disparities

		},

		onMoreClick: function() {

		}

	}

}

</script>