<template>
	
<core-list-cell :format="format" type="name" class="name" v-bind:class="{'type-user': isUser, 'is-condensed': condensed}">

	<core-gamename :game="item.game" class="name-link" />

	<div v-if="version && isUser && isBase" class="name-edition">{{ version }}</div> 

	<div v-if="isUser && isBase && format.lastPlayed && !window.is.mobile" class="name-last-played">{{ lastPlayed }}</div>

	<app-game-item-field-name-status :format="format" :item="item" :filter="filter" :hideExpansion="condensed" />

	<app-game-item-field-name-owners :format="format" :item="item" v-if="!isUser && format.showOwners && statuses === 1 && !condensed" />

	<app-game-item-field-name-comment :format="format" :item="item" v-if="format.showComment && item.rank && window.width >= 480 && !condensed" />

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format', 'filter', 'condensed'],

	computed: {

		version: function() {

			if (this.item.game.version) {

				var version = ''

				/*
				if (this.item.game.version.localised && this.item.game.name !== this.item.game.version.localised) {

					version += this.item.game.name + ' / '

				}
				*/
				
				version += this.item.game.version.name + ((this.item.game.version.year) ? ' (' + this.item.game.version.year + ')' : '')

				return version

			} else {

				return false

			}

		},

		isBase: function() {

			return this.item.game.type === this.$CONSTANTS.GAME.TYPE.BASE

		},

		lastPlayed: function() {

			return (this.item.user.play_last) ? 'Last played on ' + this.$moment.unix(this.item.user.play_last).format(this.$util.date.format('Do', 'MMMM', 'YYYY')) : 'No plays logged.'

		},

		statuses: function() {

			return this.$store.getters['filter/' + this.filter + '/status/active'].length

		}

	}

}

</script>

<style scoped>

.name {
	flex-grow: 1;
	padding-left: 15px;
	min-height: 48px;
	min-width: 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: center;
	text-align: left;
	flex-direction: column;
}

.name.is-condensed {
	min-height: 24px;
}

.is-mobile .name.is-condensed {
	height: auto;
	padding-bottom: 4px;
}

.name-link {
	overflow: hidden;
	min-width: 0;
	display: block;
	max-width: calc(100% - 15px);
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	overflow: hidden;
	color: #333;
	font-weight: 500;
}

.name.is-condensed .name-link {
	font-size: 14px;
	line-height: 14px;
}

.is-mobile .name.is-condensed .name-link {
	font-weight: 400;
	font-size: 12px;
	line-height: 24px;
}

.name-edition {
	color: #666;
	font-size: 11px;
	margin-top: 2px;
	line-height: 11px;
	font-weight: 400;
}

.name.is-condensed .name-edition {
	font-size: 10px;
}

.name-last-played {
	font-size: 11px;
	margin-top: 2px;
	color: #666;
}

.name.is-condensed .name-last-played {
	font-size: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1119px) {

	.name {
		padding-left: 10px;
	}

	.name-link { 
		width: calc(100% - 10px);
		font-size: 14px;
		line-height: 16px;
	}

}

@media only screen and (max-width: 767px) {

	.name {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 0px;
		justify-content: center;
		min-width: 110px;
	}

	.name-link { 
		font-size: 14px;
		line-height: 16px;
		padding: 0px;
		max-height: 48px;
		overflow: hidden;
		max-width: 100%;
		white-space: normal;
	}

	.is-mobile .name.is-condensed .name-link {
		white-space: nowrap;
	}

	.name-expansion {
		margin-top: 4px;
	}

}

.is-desktop .name-link:hover {
	text-decoration: underline;
}

</style>
