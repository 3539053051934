<template>
	
<core-card-foot-item>

	<core-progress :percent="value" v-if="value || !invalid" class="card-foot-progress-percent" />
	<div class="card-foot-progress-value is-invalid" v-if="!value && invalid">n/a</div>

	<core-card-foot-item-label>

		{{ text }}

	</core-card-foot-item-label>

</core-card-foot-item>

</template>

<script>

export default {

	props: ['value', 'text', 'invalid']

}

</script>

<style scoped>

.card-foot-progress-value {
	line-height: 32px;
	font-weight: 500;
	height: 32px;
	color: #666;
	font-size: 32px;
}

.card-foot-progress-value.is-invalid {
	color: #ccc;
    font-weight: 400;
    font-size: 14px
}

.card-foot-progress-percent {
	width: 32px!important;
	height: 32px!important;
}

.card-foot-progress-percent >>> .progress-value {
	width: 24px!important;
	height: 24px!important;
	line-height: 26px!important;
	font-size: 11px!important;
}

</style>
