<template>

<div class="heatmap">

	<div class="heatmap-axis-x">

		<div class="heatmap-axis-item" v-on:click="onClick(false, index)" v-on:mouseenter="onEnter(false, index)" v-on:mouseleave="onLeave" :class="{'is-active': index === currentCol}" v-for="(label, index) in data.axis.x.labels" :key="index">{{ label }}<small v-if="data.axis.x.sublabels">{{ data.axis.x.sublabels[index] }}</small></div>

	</div>

	<div class="heatmap-axis-y">

		<div class="heatmap-axis-item" v-on:click="onClick(index, false)" v-on:mouseenter="onEnter(index, false)" v-on:mouseleave="onLeave" :class="{'is-active': index === currentRow}" v-for="(label, index) in data.axis.y.labels" :key="index"><div><span>{{ label }}</span><small v-if="data.axis.y.sublabels">{{ data.axis.y.sublabels[index] }}</small></div></div>

	</div>

	<div class="heatmap-data">

		<div class="heatmap-data-grid">

			<div class="heatmap-data-grid-row" v-for="(label, row) in data.axis.y.labels" :key="row">
				<div class="heatmap-data-grid-column" :data-band="band(heat[col][row])" v-on:click="onClick(row, col)" v-on:mouseenter="onEnter(row, col)" v-on:mouseleave="onLeave" :class="{'is-empty': !heat[col][row], 'is-active': (currentCol === col && (currentRow > row || currentRow === false)) || ((currentCol > col || currentCol === false) && currentRow === row), 'is-occupied': gridOccupied(row, col)}" v-tooltip="gridTooltip(row, col)" v-for="(label, col) in data.axis.x.labels" :key="col">

					{{ heat[col][row] }}

				</div>
			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['data'],

	data: function() {

		return {
			currentRow: false,
			currentCol: false
		}

	},

	computed: {

		segmentX: function() {

			return 100 / this.data.axis.x.labels.length

		},

		segmentY: function() {

			return 100 / this.data.axis.y.labels.length

		},

		heat: function() {

			var heat = []

			this.$_.each(this.data.axis.x.thresholds, function() {

				var col = []

				this.$_.each(this.data.axis.y.thresholds, function() {

					col.push(0)

				}.bind(this))

				heat.push(col)

			}.bind(this))

			this.$_.each(this.data.datasets, function(dataset) {

				this.$_.each(dataset.data, function(item) {

					var x = this.$_.findIndex(this.data.axis.x.thresholds, function(value) {

						return value > item.x

					}) - 1

					var y = this.$_.findIndex(this.data.axis.y.thresholds, function(value) {

						return value > item.y

					}) - 1

					if (x === -1) x = 0
					if (y === -1) y = 0

					if (x === -2) x = this.data.axis.x.thresholds.length - 1
					if (y === -2) y = this.data.axis.y.thresholds.length - 1

					heat[x][y]++

				}.bind(this))

			}.bind(this))

			return heat

		}

	},

	methods: {

		band: function(count) {

			if (count) {

				var perBand = this.$_.max(this.$_.flatten(this.heat), function(value) { return value })  / 4
				var band = Math.ceil(count / perBand)

				return band

			} else {

				return 0

			}

		},

		onClick: function(r, c) {

			this.$emit('click', {
				x: c,
				y: r
			})

		},

		onEnter: function(r, c) {

			this.currentRow = r
			this.currentCol = c
				
		},

		onLeave: function() {

			this.currentRow = false
			this.currentCol = false

		},

		gridOccupied: function(r, c) {

			return this.heat[c][r]

		},

		gridTooltip: function(r, c) {

			var occupied = this.gridOccupied(r, c)

			if (occupied) {

				return occupied.toString() + ' game' + ((occupied === 1) ? '' : 's') + ' <small>Click to view</small>'

			} else {

				return 'No games'

			}

		}

	}

}

</script>

<style scoped>

.heatmap {
	width: 100%;
	height: 100%;
	user-select: none;
	overflow: hidden;
}

.heatmap-axis-y {
	width: 32px;
	position: absolute;
	left: 0px;
	bottom: 32px;
	top: 0px;
	writing-mode: vertical-rl;
	text-orientation: mixed;
	display: flex;
	flex-direction: row-reverse;
	z-index: 2;
}

.heatmap-axis-x {
	height: 32px;
	position: absolute;
	left: 32px;
	bottom: 0px;
	right: 0px;
	display: flex;
	z-index: 2;
}

.heatmap-axis-item {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	transition: background-color 100ms linear;
}

.heatmap-axis-x .heatmap-axis-item {
	text-align: center;
	color: #333;
	font-size: 12px;
    font-weight: 400;
	border-top: 1px solid #ddd;
	padding: 0px 5px;
}

.heatmap-axis-x .heatmap-axis-item:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.heatmap-axis-x .heatmap-axis-item.is-active {
	background-color: #4881bb;
}

.heatmap-axis-y .heatmap-axis-item {
	text-align: center;
	padding: 5px 0px;
	color: #333;
	font-size: 11px;
    font-weight: 400;
	border-right: 1px solid #ddd;
}

.heatmap-axis-item small {
	font-size: 9px;
	color: #999;
	display: block;
}

.heatmap-axis-y .heatmap-axis-item:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.heatmap-axis-y .heatmap-axis-item.is-active {
	background-color: #4881bb;
}

.heatmap-axis-item:hover,
.heatmap-axis-x .heatmap-axis-item:hover,
.heatmap-axis-y .heatmap-axis-item:hover {
	background-color: rgba(12, 137, 178, 0.5);
}

.heatmap-axis-y .heatmap-axis-item div {
	display: block;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	transform: rotate(-180deg);
	justify-content: center;
	align-items: center;
}

.heatmap-data {
	width: 100%;
	height: 100%;
	padding: 0px 0px 32px 32px;
	z-index: 1;
}

.heatmap-data-grid {
	display: flex;
	flex-direction: column-reverse;
	position: absolute;
	z-index: 1;
	left: 32px;
	right: 0px;
	top: 0px;
	bottom: 32px;
}

.heatmap-data-grid-row {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
}

.heatmap-data-grid-column {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	cursor: pointer;
	transition: background-color 100ms linear;
	align-items: center;
	justify-content: center;
	font-size: 21px;
    line-height: 24px;
    font-weight: 500;
	color: #fff;
}

.heatmap-data-grid-column.is-empty {
	color: #ccc;
	pointer-events: none;
}

.heatmap-data-grid-column[data-band="1"] {
	background-color: rgba(17, 105, 135, 0.4);
}

.heatmap-data-grid-column[data-band="2"] {
	background-color: rgba(17, 105, 135, 0.6);
}

.heatmap-data-grid-column[data-band="3"] {
	background-color: rgba(17, 105, 135, 0.8);
}

.heatmap-data-grid-column[data-band="4"] {
	background-color: rgba(17, 105, 135, 1);
}

/*
.heatmap-data-grid-row:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.heatmap-data-grid-column:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}
*/

.heatmap-data-grid-column.is-active {
	background-color: #4881bb;
}

.heatmap-data-grid-column:hover {
	background-color: rgba(12, 137, 178, 0.5);
}

.heatmap-data-points {
	position: absolute;
	left: 32px;
	right: 0px;
	top: 0px;
	bottom: 32px;
	z-index: 2;
	pointer-events: none;
}

.heatmap-data-points-item {
	position: absolute;
	width: 8px;
	height: 8px;
	pointer-events: auto;
	border-radius: 50%;
	background-color: rgb(33, 126, 168);
	cursor: pointer;
	transform: translate(-50%, 50%);
	mix-blend-mode: multiply;
	transition: all 100ms linear;
	opacity: 1;
}

.list-enter-active, .list-leave-active {
	transition: all 100ms;
}

.list-enter, .list-leave-to {
	opacity: 0;
}

</style>