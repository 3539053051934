<template>

<com-item name="ratings.designers" :is-list="true" :has-more="true" v-on:more="onMoreClick" :size="size" :loading="loading" title="Designers">

	<template v-slot:about>

		<p>Which designers have received the highest average rating.</p>

		<p class="small">Minimum of {{ (threshold * 2) + 1 }} <plural t="rating" :v="(threshold * 2) + 1" /> to qualify. Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<app-person v-for="(item, index) in data" :item="item" type="designer" :hideCount="true" gamesCountProperty="gamesCount" v-bind:key="index" v-on:more="onDesignerClick(item.id)" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="designersOptions" :value="designersValue" v-on:change="onDesignersChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		designersOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.RATINGS.DESIGNERS.BEST, text: 'Highest average'})
			options.push({value: this.$CONSTANTS.INSIGHTS.RATINGS.DESIGNERS.MOST, text: 'Most ratings'})

			return options

		},

		designersValue: function() {

			return this.$store.getters['filter/insights/ratings/designers']

		},

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'designers' 

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/designers']

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		onDesignersChange: function(value) {

			this.$store.commit('filter/insights/ratings/designers', value)

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsDesigners'
			})

		},

		onDesignerClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsDesigner',
				id: id
			})
		}
	
	}

}

</script>

<style scoped>

</style>