<template>

<div class="content-page">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.content-page {
	
}

</style>
