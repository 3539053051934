<template>

<com-item :name="'members.compare.' + type" :has-more="true" v-on:more="onMoreClick" :size="size" :is-list="true" :loading="loading" :is-empty="!active" :title="title[type]">

	<template v-slot:stats>

		<app-match v-for="(item, index) in data" :item="item" v-bind:key="index" />

	</template>

	<template v-slot:buttons>

		<core-button v-on:click.native="onFindClick" tooltip="Find member" v-propagation icon="user-magnifying-glass" :iconOnly="true" />
		<core-button v-on:click.native="onOwnClick" v-if="isMember" tooltip="View your matches" v-propagation icon="users fa-duetone" :iconOnly="true" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'type', 'context'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			title: {
				most: 'Best matched',
				least: 'Worst matched'
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/members/compare'][this.context][this.type]

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		onFindClick: function() {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsMembersList',
				compareType: this.type,
				onSelect: function(id) {

					this.$pubsub.$emit('panel.open', {
						type: 'insightsMembersCompare',
						compareType: this.type,
						user: id,
						child: true
					})

				}.bind(this)
			})

		},

		onOwnClick: function() {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsMembersCompare',
				compareType: this.type,
				user: this.$store.getters['session/user/id']
			})

		},

		onMoreClick: function() {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsMembersCompare',
				compareType: this.type
			})
		
		}

	}

}

</script>

<style scoped>

</style>