import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		create: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('presets/create', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		delete: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('presets/delete', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		edit: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('presets/edit', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		save: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('presets/save', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		default: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('presets/default', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		}

	}

}