<template>
	
<div class="include" v-if="isIncluded" :class="{'is-card': card}" v-tooltip="'Always included <small>Click to cancel</small>'" v-on:click="onIncludeClick($event)">

	<i class="fa fa-thumbtack"></i>

</div>

</template>

<script>

export default {

	props: ['id', 'filter', 'card'],

	computed: {

		isIncluded: function() {

			return this.$_.contains(this.$store.getters['filter/' + this.filter + '/games/include'], this.id)

		}

	},

	methods: {

		onIncludeClick: function(e) {

			e.stopPropagation()

			this.$store.commit('filter/' + this.filter + '/games/include', this.id)

		}

	}

}

</script>

<style scoped>

.include {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.8);
	color: #4a7888;
	font-size: 24px;
	border-radius: 0px;
	cursor: pointer;
}

.include.is-card {
	width: 48px;
	height: 48px;
	border-radius: 4px 0px 0px 0px;
}

</style>
