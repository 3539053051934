<template>

<div class="head outer" v-bind:class="{'is-offset': offset, 'is-fixed': fixed}" :style="{marginLeft: indent}">

	<div class="head-inner inner">

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['offset', 'fixed'],

	computed: {

		indent: function() {
		
			return (this.fixed) ? this.window.menuIndentContent : false
		
		}

	}

}

</script>

<style scoped>

.head {
	background-color: #fff;
	border-radius: 4px 4px 0px 0px;
	border: 1px solid #ddd;
	border-bottom: 0px;
	transition: margin-left 100ms linear;
}

.head.is-fixed {
	position: fixed;
	top: 152px;
	left: 360px;
	width: calc(100% - 400px);
	z-index: 1000;
	border-radius: 0px;
	border-left-color: #fff;
	border-right-color: #fff;
	border-top: 0px;
	border-bottom: 1px solid #ddd;
}

.is-smaller .head.is-fixed {
	top: 128px;
	left: 0px;
	width: calc(100% - 20px);
}

.head.is-fixed:before {
	content: '';
	position: absolute;
	display: block;
	height: 32px;
	left: -360px;
	right: -40px;
	background-color: #fff;
	border-bottom: 1px solid #ddd;
	z-index: 1;
}

.is-smaller .head.is-fixed:before {
	right: -20px;
	left: -340px;
}

.head-offset {
	position: absolute;
	top: -32px;
	height: 32px;
	background-color: red;
	width: 32px;
}

@media only screen and (max-width: 640px) {

	.head.is-offset {
		display: inline-flex;
		width: auto;
		min-width: 100%;
	}

}

.head.is-mobile {
	top: 136px;
}

.head.is-inline {
	position: relative;
	top: auto;
	left: auto;
	z-index: 1;
}

.head-inner {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	padding: 0px 7px;
	height: 32px;
	z-index: 2;
}

@media only screen and (max-width: 1119px) {

	.head-inner {
		padding: 0px 7px;
	}

}

</style>