<template>

<div class="empty" v-bind:class="{'is-loading': loading}">
	<span>{{ text }}</span>
	<i class="empty-icon fa fa-spinner fa-spin" v-if="loading" />
</div>

</template>

<script>

export default {

	props: ['text', 'loading']

}

</script>

<style scoped>

.empty {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 24px;
	font-weight: 500;
	color: #ddd;
	min-height: 240px;
	height: 100%;
}

@media only screen and (max-width: 767px) {

	.empty {
		min-height: 120px;
		font-size: 20px;
	}

}

.empty.is-loading span {
	visibility: hidden;
}

.empty-icon {
	color: #ffc563;
}

</style>