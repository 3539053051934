<template>
	
<div class="action" v-bind:class="{'is-padded': window.is.mobile && showDate.show}">

	<div class="action-date" v-bind:class="{'is-active': showDate.show}">

		<div class="action-date-month" v-if="showDate.month || (window.is.mobile && showDate.show)">
			{{ month }} 
			<div class="action-date-year" v-if="showDate.year || (window.is.mobile && showDate.show)">{{ year }}</div>
		</div>
		<div class="action-date-day" v-if="showDate.day">{{ day }}</div>

	</div>

	<div class="action-point">

		<div class="action-point-icon"><i class="fa" v-bind:class="{[pointIcon]: true}"></i></div>

	</div>

	<div class="action-detail">

		<core-avatar type="user" :id="item.user" class="action-detail-user-avatar" v-if="!isUser"></core-avatar>

		<router-link v-if="!isUser" :to="{name: 'Collection', params: {type: 'users', name: user.name}}" class="action-detail-user-name">{{ userName(user) }}</router-link>

		<div class="action-detail-description" v-if="is.type.comment && is.context.added">
			commented on <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> <div class="action-detail-description-quote"><span v-html="item.comment"></span></div>
		</div>

		<div class="action-detail-description" v-if="is.type.comment && is.context.updated">
			updated their comment on <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> <div class="action-detail-description-quote"><span v-html="item.comment"></span></div>
		</div>

		<div class="action-detail-description" v-if="is.type.comment && is.context.removed">
			removed their comment on <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> 
		</div>

		<div class="action-detail-description" v-if="is.type.rating && is.context.added">
			gave <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> a rating of {{ formatRating(item.rating) }}
		</div>

		<div class="action-detail-description" v-if="is.type.rating && is.context.updated">
			updated <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> with a rating of {{ formatRating(item.rating) }} 
			<span v-if="item.rating !== item.previous" v-bind:class="{'is-positive': item.rating > item.previous, 'is-negative': item.rating < item.previous}">
			<i class="fa" v-bind:class="{'fa-caret-up': item.rating > item.previous, 'fa-caret-down': item.rating < item.previous}"></i> {{ formatRating(item.rating - item.previous) }}
			</span>
		</div>

		<div class="action-detail-description" v-if="is.type.edition && is.context.added">
			added a new edition to the list <b>{{ item.name }}</b>
		</div>

		<div class="action-detail-description" v-if="is.type.list && is.context.added">
			created the list <b>{{ item.name }}</b>
		</div>

		<div class="action-detail-description" v-if="is.type.list && is.context.removed">
			removed the list <b>{{ item.name }}</b>
		</div>

		<div class="action-detail-description" v-if="is.type.rating && is.context.removed">
			removed <core-gamelink :id="game.id">{{ game.name }}</core-gamelink>'s rating
		</div>

		<div class="action-detail-description" v-if="is.type.tag && is.context.added">
			created the tag <span class="user-tag" :style="{backgroundColor: item.colour}">{{ item.text }}</span>
		</div>

		<div class="action-detail-description" v-if="is.type.tag && is.context.removed">
			deleted the tag <span class="user-tag" :style="{backgroundColor: item.colour}">{{ item.text }}</span>
		</div>

		<div class="action-detail-description" v-if="is.type.tagAttach && is.context.added">
			added the tag <span class="user-tag" :style="{backgroundColor: item.colour}">{{ item.text }}</span> to {{ tagAction }} <b>{{ game.name }}</b>
		</div>

		<div class="action-detail-description" v-if="is.type.tagAttach && is.context.removed">
			removed the tag <span class="user-tag" :style="{backgroundColor: item.colour}">{{ item.text }}</span> from {{ tagAction }} <b>{{ game.name }}</b>
		</div>

		<div class="action-detail-description" v-if="is.type.status && is.context.added">
			labelled <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> as <b>{{ statusText }}</b>
		</div>

		<div class="action-detail-description" v-if="is.type.status && is.context.removed">
			no longer labels <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> as <b>{{ statusText }}</b>
		</div>

		<div class="action-detail-description" v-if="is.type.collection && is.context.added">
			added <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> to their collection
		</div>

		<div class="action-detail-description" v-if="is.type.collection && is.context.removed">
			removed <core-gamelink :id="game.id">{{ game.name }}</core-gamelink> from their collection
		</div>

		<div class="action-detail-description" v-if="is.type.initiated">
			profile first imported from BGG
		</div>

		<div class="action-detail-description" v-if="is.type.registered">
			registered with geekGroup
		</div>

		<div class="action-detail-description" v-if="is.type.play">
			played <core-gamelink :id="game.id">{{ game.name }}</core-gamelink>
		</div>

		<div class="action-detail-description" v-if="is.type.group">
			created the group
		</div>

		<div class="action-detail-description" v-if="is.type.buddies">
			synchronised their GeekBuddies
		</div>

		<div class="action-detail-description" v-if="is.type.member && is.context.added">
			joined the group
		</div>

		<div class="action-detail-description" v-if="is.type.member && is.context.removed">
			left the group
		</div>

		<div class="action-detail-description" v-if="is.type.buddy && is.context.added">
			became a buddy
		</div>

		<div class="action-detail-description" v-if="is.type.buddy && is.context.removed">
			is no longer a buddy
		</div>

		<div class="action-detail-description" v-if="is.type.hindex">
			increased their h-index to <b>{{ item.hindex }}</b>
		</div>

	</div>

	<div class="action-avatars">

		<core-rating class="action-avatars-rating" :value="item.rating" v-if="item.rating"></core-rating>
		<core-avatar class="action-avatars-game" type="game" :id="item.game" v-if="item.game"></core-avatar>

	</div>

	<div class="action-actions" v-if="isSelf">

		<div class="action-actions-item is-delete" v-tooltip="'Remove entry'" v-on:click="onDeleteClick(item)"><i class="fa fa-remove" /></div>

	</div>

</div>

</template>

<script>

export default {

	components: {},

	props: ['item', 'showDate'],

	data: function() {

		return {

			date: false,

			is: {
				type: {
					rating: false,
					collection: false,
					play: false,
					member: false,
					buddy: false,
					buddies: false,
					registered: false,
					initiated: false,
					list: false,
					edition: false,
					comment: false,
					tag: false,
					tagAttach: false
				},
				context: {
					added: false,
					updated: false,
					removed: false
				}
			}

		}

	},

	computed: {

		tagAction: function() {

			if (this.item.collection) return 'their collection entry for'
			if (this.item.rating) return 'their rating of'
			if (this.item.play) return 'a play of'

			return 'the game'

		},

		statusText: function() {

			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.PREVOWNED) return 'previously owned'
			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.FORTRADE) return 'for trade'
			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.WANT) return 'wanted'
			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.WANTTOPLAY) return 'want to play'
			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.WANTTOBUY) return 'want to buy'
			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.PREORDERED) return 'pre-ordered'
			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.WISHLIST) return 'on their wishlist'
			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.HASPARTS) return 'having parts'
			if (this.item.status === this.$CONSTANTS.GAME.STATUS.ID.WANTPARTS) return 'wanting parts'

			return false

		},

		pointIcon: function() {

			if (this.is.type.registered) return 'fa-key'
			if (this.is.type.initiated) return 'fa-cloud-download-alt'
			if (this.is.type.rating) return 'fa-star'
			if (this.is.type.play) return 'fa-dice'
			if (this.is.type.group) return 'fa-users'
			if (this.is.type.buddies) return 'fa-users'
			if (this.is.type.hindex) return 'fa-level-up-alt'
			if (this.is.type.comment) return 'fa-comment'
			if (this.is.type.status) return 'fa-tag'

			if (this.is.type.member && this.is.context.added) return 'fa-user-plus'
			if (this.is.type.member && this.is.context.removed) return 'fa-user-minus'

			if (this.is.type.buddy && this.is.context.added) return 'fa-user-plus'
			if (this.is.type.buddy && this.is.context.removed) return 'fa-user-minus'

			if (this.is.type.collection && this.is.context.added) return 'fa-plus'
			if (this.is.type.collection && this.is.context.removed) return 'fa-minus'

			if (this.is.type.list && this.is.context.added) return 'fa-clipboard-list'
			if (this.is.type.list && this.is.context.removed) return 'fa-clipboard-list'

			if (this.is.type.tag) return 'fa-user-tag'
			if (this.is.type.tagAttach) return 'fa-user-tag'

			if (this.is.type.edition && this.is.context.added) return 'fa-clipboard-list'

			return false

		},

		collection: function() {

			return this.$store.getters['data/games'][this.item.collection]

		},

		game: function() {

			return this.$store.getters['data/games'][this.item.game]

		},

		user: function() {

			return this.$store.getters['data/users'][this.item.user]

		},

		day: function() {

			return this.date.format('DD')

		},

		month: function() {

			return this.date.format('MMMM')

		},

		year: function() {

			return this.date.format('YYYY')

		}

	},

	watch: {

		item: function() {

			this.update()

		}

	},

	created: function() {

		this.update()

	},

	methods: {

		onDeleteClick: function(item) {
		
			this.$pubsub.$emit('open.modal', {
				id: 'activityDelete',
				activity: item.id,
				type: item.type
			})

		},

		userName: function(user) {

			return user.fullname || user.name

		},

		update: function() {

			this.date = this.$moment.unix(this.item.date).utc()

			this.is.type.registered = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.REGISTERED
			this.is.type.initiated = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.INITIATED
			this.is.type.rating = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.RATING
			this.is.type.collection = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.COLLECTION
			this.is.type.play = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.PLAY
			this.is.type.group = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.GROUP
			this.is.type.buddies = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.BUDDIES
			this.is.type.member = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.MEMBER
			this.is.type.buddy = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.BUDDY
			this.is.type.hindex = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.HINDEX
			this.is.type.list = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.LIST
			this.is.type.edition = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.EDITION
			this.is.type.comment = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.COMMENT
			this.is.type.status = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.STATUS
			this.is.type.tag = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.TAG
			this.is.type.tagAttach = this.item.type === this.$CONSTANTS.ACTIVITY.TYPE.TAG_ATTACH

			this.is.context.added = this.item.context === this.$CONSTANTS.ACTIVITY.CONTEXT.ADDED
			this.is.context.updated = this.item.context === this.$CONSTANTS.ACTIVITY.CONTEXT.UPDATED
			this.is.context.removed = this.item.context === this.$CONSTANTS.ACTIVITY.CONTEXT.REMOVED

		},

		formatRating: function(rating) {

			return Math.abs(rating).toFixed(1)

		}

	}

}

</script>

<style scoped>

.action {
	width: 100%;
	text-align: center;
	display: flex;
	text-align: left;
}

.action.is-padded {
	margin-top: 46px;
}

.action-point {
	width: 2px;
	min-width: 2px;
	height: 100%;
}

@media only screen and (max-width: 767px) {

	.action-point {
		margin-left: 22px;
		top: 6px;
	}

}

.action-point-icon {
	position: absolute;
	width: 32px;
	height: 32px;
	top: 16px;
	margin-left: -15px;
	border-radius: 16px;
	line-height: 28px;
	text-align: center;
	color: #4881bb;
	background-color: #fff;
	border: 2px solid #4881bb;
	z-index: 3;
	font-size: 15px;
}

@media only screen and (max-width: 767px) {

	.action-point-icon {
		top: 6px;
	}

}

.action-date {
	width: 200px;
	min-width: 200px;
	color: #4881bb;
	text-transform: uppercase;
	display: flex;
	flex-direction: row;
	text-align: right;
	visibility: hidden;
	align-items: top;
	padding-top: 16px;
	justify-content: flex-end;
	padding-right: 32px;
}

@media only screen and (max-width: 767px) {

	.action-date {
		flex-direction: row-reverse;
		padding: 8px 10px 8px 24px;
		left: 22px;
		width: 120px;
		min-width: 120px;
		top: -46px;
		position: absolute;
		color: #4881bb;
	}

}

.action-date.is-active {
	visibility: visible;
}

.action-date-year {
	font-size: 14px;
	font-weight: 400;
	color: #999;
	opacity: 0.5;
}

.action-date-month {
	padding-top: 3px;
	color: #666;
	font-size: 16px;
	padding-right: 10px;
	font-weight: 400;
}

.action-date-day {
	width: 40px;
	min-width: 40px;
	text-align: right;
	font-weight: 700;
	font-size: 36px;
}

@media only screen and (max-width: 767px) {

	.action-date-year {
		font-size: 14px;
		text-align: left;
	}

	.action-date-month {
		padding-top: 2px;
		font-size: 14px;
		text-align: left;
		padding-left: 2px;
	}

	.action-date-day {
		width: 32px;
		height: 32px;
		min-width: 32px;
		border-radius: 16px;
		text-align: center;
		line-height: 32px;
		font-weight: 400;
		font-size: 18px;
		background-color: #4881bb;
		color: #fff;
		position: absolute;
		left: -15px;
		z-index: 2;
	}

}

.action-spacer {
	width: 64px;
	min-width: 64px;
}

.action-detail {
	flex-grow: 1;
	padding: 20px 0px 20px 30px;
	z-index: 1;
	min-height: 65px;
	display: flex;
	height: auto;
	line-height: 18px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: top;
	border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 767px) {

	.action-detail {
		padding: 16px 0px 10px 25px;
		border-bottom: 0px;
		flex-wrap: wrap;
	}

}

.action-detail-user-avatar {
	width: 24px;
	flex-shrink: 0;
	height: 24px;
	margin-right: 5px;
	border-radius: 12px;
}

.action-detail-user-name {
	font-weight: bold;
	color: #333;
	padding-right: 5px;
	font-size: 14px;
	cursor: pointer;
}

@media only screen and (max-width: 767px) {

	.action-detail-user-name {
		padding-top: 2px;
	}

}

.is-desktop .action-detail-user-name:hover {
	text-decoration: underline;
}

.action-detail-description {
	line-height: 18px;
	font-size: 14px;
	color: #333;
}

@media only screen and (max-width: 767px) {

	.action-detail-description {
		padding: 2px 0px 0px 0px;
	}

}

.action-detail-description-quote {
	display: block;
	font-size: 12px;
	font-style: italic;
	line-height: 14px;
	font-weight: 700;
	margin-top: 4px;
	color: #999;
}

.action-detail-description-rating {
	display: inline-block;
	border-radius: 4px;
	line-height: 16px;
    color: #fff;
    padding: 2px 4px;
    min-width: 16px;
    text-align: center;
}

.action-detail-description .is-positive {
	color: green;
	font-size: 10px;
}

.action-detail-description .is-negative {
	color: red;
	font-size: 10px;
}

.action-avatars {
	display: flex;
	flex-wrap: nowrap;	
	padding: 8px 7px;
	border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 767px) {

	.action-avatars {
		padding: 8px 7px;
		border-bottom: 0px;
	}

}

.action-avatars-game {
	width: 48px;
	height: 48px;
	line-height: 48px;
	min-width: 40px;
	margin-left: 1px;
}

.action-avatars-rating {
	width: 48px;
	min-width: 48px;
	height: 48px;
	margin-left: 1px;
	line-height: 48px;
}

.is-desktop .action:hover .action-detail,
.is-desktop .action:hover .action-avatars,
.is-desktop .action:hover .action-actions {
	background-color: #e9f3f7;
}

.action:last-child .action-detail,
.action:last-child .action-avatars {
	border-bottom: 0px;
}

.user-tag {
	font-size: 12px;
	color: #fff;
	padding: 2px 4px;
	border-radius: 4px;
	font-weight: 400;
	display: inline;
	cursor: initial;
	vertical-align: middle;
}

.action-actions {
	padding: 0px 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #eee;
}

.action-actions-item {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	color: #4881bb;
	font-size: 16px;
	line-height: 24px;
	cursor: pointer;
	text-align: center;
}

.is-desktop .action-actions-item:hover {
	background-color: #4881bb;
	color: #fff;
}

</style>
