<template>

<div class="rank" :class="{'is-loading': loading}">

	<div class="rank-bands">

        <core-simplebar class="rank-bands-scroll">

            <slot name="bands"></slot>

        </core-simplebar>

	</div>

	<div class="rank-games">

		<slot name="games"></slot>

	</div>

	<div class="rank-metrics">

		<slot name="metrics"></slot>

	</div>

</div>

</template>

<script>

export default {

    props: ['loading']

}

</script>

<style scoped>

.rank {
	display: grid;
    grid-gap: 5px;
	height: 320px;
    grid-template-columns: minmax(0, 1fr) 320px minmax(0, 1fr);
    grid-template-rows: 1fr;
}

.is-tablet .rank {
	grid-template-columns: 1fr 320px;
	height: auto;
	grid-template-rows: 320px 320px;
}

.is-mobile .rank {
	grid-template-columns: minmax(0, 1fr);
	height: auto;
	grid-template-rows: 320px 320px 320px;
}

.rank-bands {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.rank-bands-scroll {
	height: 320px;
	width: 100%;
	overflow: auto;
}

.rank-bands-scroll .simplebar-content-wrapper {
	padding-right: 0px!important;
}

.rank-games {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    overflow: hidden;
}

@keyframes fade { 
	0% { opacity: 1; }
	25% { opacity: 0.25; }
	50% { opacity: 1; }
	100% { opacity: 1; }
}

.rank.is-loading .rank-games >>> .game {
	opacity: 1;
	animation: fade 2000ms ease-in-out;
	animation-iteration-count: infinite;
}

.rank-metrics {
	height: 322px;
    display: grid;
    grid-gap: 5px;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.is-tablet .rank-metrics {
	grid-column: span 2;
}

.rank.is-loading .rank-games >>> .game:nth-child(1) { animation-delay: 100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(2) { animation-delay: 200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(3) { animation-delay: 300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(4) { animation-delay: 400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(5) { animation-delay: 500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(6) { animation-delay: 600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(7) { animation-delay: 700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(8) { animation-delay: 800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(9) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(10) { animation-delay: 1000ms; }

.rank.is-loading .rank-games >>> .game:nth-child(11) { animation-delay: 200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(12) { animation-delay: 300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(13) { animation-delay: 400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(14) { animation-delay: 500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(15) { animation-delay: 600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(16) { animation-delay: 700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(17) { animation-delay: 800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(18) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(19) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(20) { animation-delay: 1100ms; }

.rank.is-loading .rank-games >>> .game:nth-child(21) { animation-delay: 300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(22) { animation-delay: 400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(23) { animation-delay: 500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(24) { animation-delay: 600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(25) { animation-delay: 700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(26) { animation-delay: 800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(27) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(28) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(29) { animation-delay: 1100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(30) { animation-delay: 1200ms; }

.rank.is-loading .rank-games >>> .game:nth-child(31) { animation-delay: 400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(32) { animation-delay: 500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(33) { animation-delay: 600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(34) { animation-delay: 700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(35) { animation-delay: 800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(36) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(37) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(38) { animation-delay: 1100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(39) { animation-delay: 1200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(40) { animation-delay: 1300ms; }

.rank.is-loading .rank-games >>> .game:nth-child(41) { animation-delay: 500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(42) { animation-delay: 600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(43) { animation-delay: 700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(44) { animation-delay: 800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(45) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(46) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(47) { animation-delay: 1100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(48) { animation-delay: 1200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(49) { animation-delay: 1300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(50) { animation-delay: 1400ms; }

.rank.is-loading .rank-games >>> .game:nth-child(51) { animation-delay: 600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(52) { animation-delay: 700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(53) { animation-delay: 800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(54) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(55) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(56) { animation-delay: 1100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(57) { animation-delay: 1200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(58) { animation-delay: 1300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(59) { animation-delay: 1400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(60) { animation-delay: 1500ms; }

.rank.is-loading .rank-games >>> .game:nth-child(61) { animation-delay: 700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(62) { animation-delay: 800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(63) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(64) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(65) { animation-delay: 1100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(66) { animation-delay: 1200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(67) { animation-delay: 1300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(68) { animation-delay: 1400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(69) { animation-delay: 1500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(70) { animation-delay: 1600ms; }

.rank.is-loading .rank-games >>> .game:nth-child(71) { animation-delay: 800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(72) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(73) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(74) { animation-delay: 1100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(75) { animation-delay: 1200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(76) { animation-delay: 1300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(77) { animation-delay: 1400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(78) { animation-delay: 1500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(79) { animation-delay: 1600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(80) { animation-delay: 1700ms; }

.rank.is-loading .rank-games >>> .game:nth-child(81) { animation-delay: 900ms; }
.rank.is-loading .rank-games >>> .game:nth-child(82) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(83) { animation-delay: 1100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(84) { animation-delay: 1200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(85) { animation-delay: 1300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(86) { animation-delay: 1400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(87) { animation-delay: 1500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(88) { animation-delay: 1600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(89) { animation-delay: 1700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(90) { animation-delay: 1800ms; }

.rank.is-loading .rank-games >>> .game:nth-child(91) { animation-delay: 1000ms; }
.rank.is-loading .rank-games >>> .game:nth-child(92) { animation-delay: 1100ms; }
.rank.is-loading .rank-games >>> .game:nth-child(93) { animation-delay: 1200ms; }
.rank.is-loading .rank-games >>> .game:nth-child(94) { animation-delay: 1300ms; }
.rank.is-loading .rank-games >>> .game:nth-child(95) { animation-delay: 1400ms; }
.rank.is-loading .rank-games >>> .game:nth-child(96) { animation-delay: 1500ms; }
.rank.is-loading .rank-games >>> .game:nth-child(97) { animation-delay: 1600ms; }
.rank.is-loading .rank-games >>> .game:nth-child(98) { animation-delay: 1700ms; }
.rank.is-loading .rank-games >>> .game:nth-child(99) { animation-delay: 1800ms; }
.rank.is-loading .rank-games >>> .game:nth-child(100) { animation-delay: 1900ms; }

</style>