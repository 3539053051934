import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		suggest: function(context, params) {

			return new Promise(function(resolve, reject) {

				params.type = context.rootGetters['context/type']
				params.name = context.rootGetters['context/slug']

				API.request('players/suggest', params).then(function(json) {

					context.commit('data/players/add', json.suggestions, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		}
		
	}

}