export default {

	beforeCreate: function() {

		const options = this.$options

		if (options.moment) {
			this.$moment = options.moment
		} else if (options.parent && options.parent.$moment) {
			this.$moment = options.parent.$moment
		}

		if (options._) {
			this.$_ = options._
		} else if (options.parent && options.parent.$_) {
			this.$_ = options.parent.$_
		}

		if (options.api) {
			this.$api = options.api
		} else if (options.parent && options.parent.$api) {
			this.$api = options.parent.$api
		}

		if (options.pubsub) {
			this.$pubsub = options.pubsub
		} else if (options.parent && options.parent.$pubsub) {
			this.$pubsub = options.parent.$pubsub
		}

		if (options.CONSTANTS) {
			this.$CONSTANTS = options.CONSTANTS
		} else if (options.parent && options.parent.$CONSTANTS) {
			this.$CONSTANTS = options.parent.$CONSTANTS
		}

		if (options.util) {
			this.$util = options.util
		} else if (options.parent && options.parent.$util) {
			this.$util = options.parent.$util
		}
      
	}

}