<template>

<core-filter-advanced-group title="General" :count="count">

	<core-filter-input-toggles :filter="value('played/period')" text="Period" :values="type.values" :icons="type.icons" :labels="type.labels" />

	<core-filter-input-daterange :filter="value('played/range')" v-if="values.played.period === 'custom'" />

	<core-filter-input-toggle text="New to me" on="Yes" off="No" :filter="value('play/first')" v-if="grouped" />

	<core-filter-input-toggle text="First plays only" on="Yes" off="No" :filter="value('play/first')" v-if="!grouped" />

	<core-filter-input-toggle text="Last plays only" on="Yes" off="No" :filter="value('play/last')" v-if="!grouped" />

	<core-filter-input-toggle text="Include expansion plays" on="Yes" off="No" :filter="value('expansions')" />

	<core-filter-input-range text="Duration" subtext="(minutes)" min="0" max="360" :filter="value('play/duration')" step="30" decimals="0" density="10" />

	<core-filter-input-range text="No. of players" min="1" max="10" :filter="value('play/count')" step="1" decimals="0" density="10" :toggle="true" />

	<core-filter-input-toggles text="Status" :icons="status.icons" :labels="status.labels" :values="status.values" :filter="value('play/status')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	props: ['grouped'],

	data: function() {

		return {
			fields: ['played.period', 'played.range', 'play.first', 'play.last', 'expansions', 'play.duration', 'play.count', 'play.status'],
			type: {
				labels: ['Custom', 'This month', 'Last month'],
				icons: ['', '', '', ''],
				values: ['custom', 'month.current', 'month.last']
			},
			status: {
				icons: [false, false, false],
				values: [0, 1, 2],
				labels: ['All', 'Completed', 'Incompleted']
			}
		}
	}

}

</script>