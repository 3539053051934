<template>

<div class="item" v-bind:class="{'is-expanded': is.expanded, 'is-selected': selected, 'is-condensed': condensed, 'is-scrollable': scrollable}">

	<div class="item-fields">

		<core-list-cell-drag v-if="draggable" />

		<template v-for="(field, column) in fields">

			<app-game-item-field-rank v-if="field.type === 'rank'" :index="index" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-avatar v-if="field.type === 'avatar'" :filter="filter" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-name v-if="field.type === 'name'" :item="item" :filter="filter" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-plays v-if="field.type === 'plays'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-weight v-if="field.type === 'weight'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-duration v-if="field.type === 'duration'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-count v-if="field.type === 'count'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-rating v-if="field.type === 'rating'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-date v-if="field.type === 'date'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-bggrank v-if="field.type === 'bggrank'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-worth v-if="field.type === 'worth'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-language v-if="field.type === 'language'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-owners v-if="field.type === 'owners'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-hours v-if="field.type === 'hours'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />
			<app-game-item-field-published v-if="field.type === 'published'" :item="item" :format="field" v-bind:key="column.key || column.type" :condensed="condensed" />

		</template>

		<core-list-cell-actions :item="item" :filter="filter" :can="can" :condensed="condensed" v-if="!noactions" />

	</div>

	<app-game-item-field-name-comment :item="item" class="item-comment" v-if="hasComment && window.width < 480" />

	<app-game-item-summary :item="item" class="item-summary" v-if="summarised" />

	<app-game-item-nested :item="item" :columns="fields" />
	<app-game-item-contained :item="item" :columns="fields" />

	<slot></slot>

</div>

</template>

<script>

export default {

	name: 'Item',

	props: ['item', 'fields', 'summarised', 'draggable', 'can', 'scrollable', 'filter', 'index', 'condensed', 'noactions'],

	data: function() {

		return {

			is: {
				expanded: false
			}

		}

	},

	computed: {

		hasComment: function() {

			var field = this.$_.findWhere(this.fields, {
				type: 'name'
			})

			if (field) {

				return field.showComment && this.item.rank

			} else {

				return false

			}

		},

		selected: function() {

			return this.$store.getters['service/select'](this.item.id)

		}

	}

}

</script>

<style scoped>

.item {
	width: 100%;
	padding: 0px 7px;
	border-bottom: 1px solid #eee;
}

.item.is-selected {
	border-color: #fff;
}

.item:last-child {
	border-bottom: 0px;
}

.item.is-condensed {
	border-bottom: 0px;
	padding: 4px 0px;
}

@media only screen and (max-width: 640px) {

	.item.is-scrollable {
		width: auto;
		min-width: 100%;
		display: inline-flex;
		flex-direction: column;
	}

}

.item.is-expanded {
	padding-bottom: 7px;
	border-bottom: 0px;
	width: 100%;
	display: block;
}

.is-desktop .item:not(.is-selected):hover {
	background-color: #e9f3f7;
}

.item.is-selected {
	background-color: #d4f2ff;
}

.is-desktop .item.is-selected:hover {
	background-color: #c6e5f3;
}

.is-desktop .item:not(.is-selected).is-condensed:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.item-fields {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	min-height: 63px;
	padding-bottom: 8px;
	padding-top: 7px;
}

.item.is-condensed .item-fields {
	min-height: 24px;
	padding: 0px;
}

@media only screen and (max-width: 640px) {

	.item.is-scrollable .item-fields {
		width: auto;
		min-width: 100%;
		display: inline-flex;
	}

}

.item-comment {
	padding: 0px 0px 8px 0px!important;
}

</style>