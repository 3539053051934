<template>

<div class="accordian">

	<div v-on:click="onSegmentClick(segment.key)" v-for="(segment, index) in segments" :key="index" class="accordian-segment" :style="segment.style" :class="{'is-veryslim': segment.percent < 10, 'is-slim': segment.percent < slimPercent}" v-tooltip="segment.tooltip">

		<div v-if="segment.image" class="accordian-segment-image" :style="{backgroundImage: 'url(' + $util.thumbnail.game.large(segment.image) + ')'}" />

		<div v-if="!data.labelAsValue" class="accordian-segment-label" v-fit>{{ segment.label }}</div>

		<div class="accordian-segment-value">
            
            <div v-if="isCurrency" v-html="formatcurrency(segment.value, 0)" v-fit></div>
            
            <div v-if="!isCurrency && !data.labelAsValue" v-fit>{{ segment.value }}</div> 
            <div v-if="!isCurrency && data.labelAsValue" v-fit>{{ segment.label }}</div> 
            
            <small>
                <template v-if="segment.subvalue">{{ segment.subvalue }} </template>
                <template v-if="data.labelAsValue">{{ segment.value }} </template>
                <plural v-if="segment.subvalue" :n="segment.subvalue" :t="noun" />
                <plural v-if="data.labelAsValue" :n="segment.value" :t="noun" />
            </small>
        
        </div>

    </div>

</div>

</template>

<script>

export default {

	props: ['data'],

    data: function() {
    
        return {
            minPercent: 5
        }

    },

    computed: {

		slimPercent: function() {

			return this.data.slimPercent || 10

		},

        noun: function() {
        
            return this.data.noun
        
        },

        isCurrency: function() {
        
            return this.data.format === 'currency'
        
        },

		total: function() {

			return this.$_.reduce(this.data.values, function(total, value) {

				return total + parseInt(value)

			}, 0)

		},

        segments: function() {

            var segments = {}
			var offset = 0

            this.$_.each(this.data.values, function(value, index) {
            
                if(value) {

                    var key = (this.data.keys) ? this.data.keys[index] : this.data.labels[index]

                    var percent = ((100 / this.total) * value).toFixed(1)

                    if(percent < this.minPercent) {

                        offset += this.minPercent - percent
                        percent = this.minPercent

                    }

                    var tooltip = this.data.tooltip

                    tooltip = tooltip.replace('{{value}}', (this.data.subvalues) ? this.data.subvalues[index] : value),
                    tooltip = tooltip.replace('{{noun}}', (value === 1) ? this.data.noun : this.data.noun + 's')

                    segments[key] = {
                        value: value,
						key: key,
                        label: this.data.labels[index],
                        image: this.data.images[index] || false,
						subvalue: (this.data.subvalues) ? this.data.subvalues[index] : false,
                        percent: percent,
                        tooltip: tooltip,
                        style: {
                            width: (this.window.is.mobile) ? false : 'calc(' + percent + '%)',
                            height: (this.window.is.mobile) ? 'calc(' + percent + '%)' : false,
                            backgroundColor: this.data.colors[index]
                        }
                    }
                
                }

            }.bind(this))

			var max = this.$_.max(segments, function(segment) {

				return parseFloat(segment.percent)

			})

            if (offset > 0) {

				segments[max.key].percent -= offset
				segments[max.key].style[(this.window.is.mobile) ? 'height' : 'width'] = 'calc(' + segments[max.key].percent + '% - 1px)'

			}

			var total = this.$_.reduce(segments, function(total, segment) {

				return total + parseFloat(segment.percent)

			}, 0)

			if (total > 100) {

				var difference = total - 100

				segments[max.key].percent -= difference
				segments[max.key].style[(this.window.is.mobile) ? 'height' : 'width'] = 'calc(' + segments[max.key].percent + '% - 1px)'
			
			}

            return segments
        
        }

    },

    methods: {
    
        onSegmentClick: function(value) {
        
            this.$emit('click', value)
        
        }
    
    }

}

</script>

<style scoped>

.accordian {
	width: 100%;
	height: 100%;
	user-select: none;
    display: flex;
}

.is-mobile .accordian {
	flex-direction: column;
	height: 800px;
}

.accordian-segment {
	transition: transform 100ms linear;
	background-color: #fff;
	cursor: pointer;
	z-index: 1;
    flex-shrink: 0;
	overflow: hidden;
	transition: all 300ms linear;
	border-right: 1px solid #ddd;
}

.is-mobile .accordian-segment {
	border-right: 0px;
	border-bottom: 1px solid #ddd;
}

.accordian-segment:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}   

.is-mobile .accordian-segment:first-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
}   

.accordian-segment:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
	border-right: 0px;
}   

.is-mobile .accordian-segment:last-child {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
	border-bottom: 0px;
}   

.is-desktop .accordian-segment:hover {
	transform: scale(1.05);
	border-radius: 4px;
	z-index: 2;
	background-color: #4881bb;
	border-right: 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.accordian-segment-image {
	position: absolute;
	opacity: 0.1;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
}

.accordian-segment-label {
	font-size: 32px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.4);
	position: absolute;
	left: 4px;
	top: 4px;
}

.is-not-mobile .accordian-segment.is-slim .accordian-segment-label {
    left: 0px;
    right: 0px;
    text-align: center;
}

.accordian-segment-value {
	font-size: 32px;
	color: #fff;
	font-weight: 500;
	position: absolute;
	left: 0%;
	top: 50%;
	width: 100%;
	text-align: center;
	transform: translateY(-50%);
}

.is-not-mobile .accordian-segment.is-slim .accordian-segment-value {
	writing-mode: vertical-rl;
	text-orientation: mixed;	
	font-size: 26px;
	transform: translate(-50%, -50%);
	left: 50%;
    width: auto;
}

.accordian-segment-value >>> span.currency {
	font-size: 16px;
	opacity: 0.75;
	margin-right: 1px;
}

.accordian-segment-value small {
	font-size: 12px;
	display: block;
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: -12px;
	opacity: 0.75;
	font-weight: 500;
}

.is-not-mobile .accordian-segment.is-slim .accordian-segment-value small {
	position: relative;
	bottom: auto;
}

.accordian-segment.is-veryslim .accordian-segment-value small {
	display: none;
}

.is-not-mobile .accordian-segment.is-slim .accordian-segment-value >>> span.currency {
	font-size: 11px;
	margin-bottom: 1px;
}

</style>