<template>

<core-modal v-on:close="onClose" type="confirm">

	<template v-slot:head>

		Remove from Group

	</template>

	<template v-slot:default>

		<p v-if="data.users.length > 1">Are you sure you wish to remove these {{ data.users.length }} users from the group?</p>
		<p v-if="data.users.length === 1">Are you sure you wish to remove this user from the group?</p>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading">Yes</core-button>
		<core-button theme="plain" v-on:click.native="onClose">No</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

	props: ['data'],

	data: function() {

		return {

			is: {
				loading: false
			}

		}

	},

	methods: {

		onConfirmClick: function() {

			this.is.loading = true

			this.$store.dispatch('api/groups/remove', {
				name: this.$route.params.name,
				users: this.data.users
			}).then(function() {

				this.$store.dispatch('reverify').then(function() {

					this.$emit('close')
					this.$pubsub.$emit('dynamic.refresh.users')

				}.bind(this))

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this)) 

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>