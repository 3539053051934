<template>

<a :href="url" target="_blank" class="game">

	<slot></slot>

</a>

</template>

<script>

export default {

	name: 'gameLink',

	props: ['id'],

	computed: {

		url: function() {

			return 'https://boardgamegeek.com/boardgame/' + this.id

		}

	}

}

</script>

<style scoped>

.game {
	font-weight: 700;
}

.is-desktop .game:hover {
	text-decoration: underline;
}

</style>
