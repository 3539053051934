import store from 'app/store'

export default {

	exclude: function(context, e) {

		e = e || {}
		e.action = e.action || 'add'
			
		var index = (e.override === undefined) ? context.getters['all/games'] : [e.override.game]

		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {

				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded'],
						type: 'games'
					}).then(function(json) {

						internalResolve(json.index)

					})

				} else {

					internalResolve(index)

				}

			}).then(function(index) {

				context.commit('filter/' + context.getters['name'] + '/games/exclude/add', index, { root: true })

				resolve()

			})

		})

	},

	include: function(context, e) {

		e = e || {}
		e.action = e.action || 'add'
			
		var index = (e.override === undefined) ? context.getters['all/games'] : [e.override.game]

		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {
		
				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded'],
						type: 'games'
					}).then(function(json) {

						internalResolve(json.index)

					})

				} else {

					internalResolve(index)

				}

			}).then(function(index) {

				context.commit('filter/' + context.getters['name'] + '/games/include' + ((e.allowInvert) ? '' : '/' + e.action), index, { root: true })

				resolve()

			})

		})

	},

	includeAdd: function(context, e) {

		e = e || {}
		e.action = 'add'

		return context.dispatch('filter/include', e)

	},

	includeRemove: function(context, e) {

		e = e || {}
		e.action = 'remove'

		return context.dispatch('filter/include', e)

	},

	subset: function(context, e) {

		e = e || {}
			
		var index = (e.override === undefined) ? context.getters['all/games'] : [e.override.game]

		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {
		
				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded'],
						type: 'games'
					}).then(function(json) {

						internalResolve(json.index)

					})

				} else {

					internalResolve(index)

				}

			}).then(function(index) {

				context.commit('filter/' + context.getters['name'] + '/games/subset/set', index, { root: true })

				resolve()

			})

		})

	}

}