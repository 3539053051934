import Vue from 'vue'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: {}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		designers: function(state) {

			return state.data.designers || []

		},

		overview: function(state) {

			return state.data.overview || false

		},

		publishers: function(state) {

			return state.data.publishers || []

		},

		shelf: function(state) {

			return state.data.shelf || []

		},

		rarity: function(state) {

			return state.data.rarity || []

		},

		years: function(state) {

			return state.data.years || []

		},

		dependencies: function(state) {

			return state.data.dependencies || []

		},

		suitability: function(state) {

			return state.data.suitability || []

		},

		bestduration: function(state) {

			return state.data.bestduration || []

		},

		durations: function(state) {

			return state.data.durations || []

		},

		moreYears: function(state) {

			return state.data.moreYears || false

		},

		rankings: function(state) {

			return state.data.rankings || false

		},

		weights: function(state) {

			return state.data.weights || []

		},

		counts: function(state) {

			return state.data.counts || []

		},

		status: function(state) {

			return state.data.status || []

		},

		types: function(state) {

			return state.data.types || []

		}

	},

	mutations: {

		reset: function(state) {

			state.data = {}

		},

		set: function(state, data) {

			_.each(data, function(value, key) {

				Vue.set(state.data, key, value)

			})

		}

	},

	actions: {}

}