import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		generic: function(context, data) {

			data = data || {}
			data.params = data.params || {}

			if (data.params.page) {

				data.params.knownUsers = context.rootGetters['data/users/index']

			}

			return new Promise(function(resolve, reject) {

				API.request(data.url, data.params, true).then(function(json) {

					// add reference data
					context.commit('data/games/add', json.data.games, { root: true })
					context.commit('data/users/add', json.data.users, { root: true })
					context.commit('data/designers/add', json.data.designers, { root: true })
					context.commit('data/languages/add', json.data.languages, { root: true })
					context.commit('data/families/add', json.data.families, { root: true })
					context.commit('data/publishers/add', json.data.publishers, { root: true })
					context.commit('data/artists/add', json.data.artists, { root: true })
					context.commit('data/mechanics/add', json.data.mechanics, { root: true })
					context.commit('data/subdomains/add', json.data.subdomains, { root: true })
					context.commit('data/categories/add', json.data.categories, { root: true })
				
					// first page, replace collection
					if (json.page === 1) {

						context.commit('page/collection/set', json.collection, { root: true })
						context.commit('page/collection/nested/set', json.data.nested, { root: true })
						context.commit('page/collection/contained/set', json.data.contains, { root: true })
						context.commit('data/tags/set', json.data.tags, { root: true })

					// otherwise paginate
					} else {

						context.commit('page/collection/append', json.collection, { root: true })
						context.commit('page/collection/nested/append', json.data.nested, { root: true })
						context.commit('page/collection/contained/append', json.data.contains, { root: true })

					}

					context.commit('ready', null, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})		

		},

		users: function(context, params) {

			params.filter = context.rootGetters['filter/collection']

			return context.dispatch('generic', {
				params: params,
				url: 'users/collection'
			})	
			
		},

		groups: function(context, params) {

			params.filter = context.rootGetters['filter/collection']

			return context.dispatch('generic', {
				params: params,
				url: 'groups/collection'
			})	
			
		},

		buddies: function(context, params) {

			params.filter = context.rootGetters['filter/collection']

			return context.dispatch('generic', {
				params: params,
				url: 'buddies/collection'
			})	
			
		}

	}

}