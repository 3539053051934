<template>

<div class="summary" v-bind:class="{'is-loading': loading, 'is-small': nested && hidden}">

	<div class="summary-icon" v-if="icon"><i class="fa" :class="'fa-' + icon"></i></div>

	<div class="summary-text">
	
		<div class="summary-title" v-if="title && !loading">
			{{ title }}
			<div class="summary-parenttitle" v-if="parenttitle">{{ parenttitle }}</div>
			<div class="summary-subtitle" v-if="subtitle">{{ subtitle }}</div>
		</div>

		<div class="summary-tags" v-show="!loading">

			<div class="summary-tags-item is-primary" :class="{'with-nested': nested}" v-if="count">
				<div class="summary-tags-item-count">{{ count | prettyNumber }}</div>
				<plural :n="count" :t="noun" v-if="!window.is.mobile" />
			</div>
				
			<div class="summary-tags-item is-nested" v-if="nested">
				<div class="summary-tags-item-count">{{ nested | prettyNumber }}</div>
				<plural :n="(noNestedPlural) ? 1 : nested" :t="nestedNoun" v-if="!window.is.mobile" />
			</div>
				
			<div class="summary-tags-item is-filtered" v-if="hidden">
				<div class="summary-tags-item-count">{{ hidden | prettyNumber }}</div>
				<span v-if="!window.is.mobile">filtered out</span>
			</div>
				
			<div class="summary-tags-item is-unsaved" v-if="unsaved">
				Unsaved
			</div>

		</div>

		<div class="summary-loading" v-if="loading">
			<i class="fa fa-spinner fa-spin"></i>
		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['count', 'maximum', 'nested', 'icon', 'loading', 'title', 'parenttitle', 'subtitle', 'noun', 'noNestedPlural', 'nestedNoun', 'unsaved'],

	computed: {

		hidden: function() {

			return this.maximum - this.count - this.nested

		}

	}

}

</script>

<style scoped>

.summary {
	font-weight: 400;
	color: #fff;
	display: flex;
	flex-direction: row;
	height: 88px;
	align-items: center;
	padding: 0px 20px;
}

.is-smaller .summary {
	height: 64px;
}

.is-mobile .summary {
	height: 48px;
}

.is-tablet .summary {
	padding: 0px 10px 0px 20px;
}

.is-mobile .summary {
	padding: 0px 0px 0px 10px;
}

.summary-icon {
	font-size: 48px;
	margin-right: 20px;
}

.is-smaller .summary-icon {
	font-size: 32px;
}

.is-mobile .summary-icon {
	font-size: 16px;
	width: 32px;
	text-align: center;
	line-height: 32px;
	height: 32px;
	margin-right: 10px;
	display: block;
	border-radius: 50%;
	flex-shrink: 0;
	background: rgba(0, 0, 0, 0.15);
}

.summary-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.summary-title {
	font-size: 20px;
	font-weight: 500;
	color: #fff;
	display: flex;
}

.is-mobile .summary-title {
	font-size: 16px;
}

.summary-parenttitle {
	color: #fff;
	margin-left: 5px;
}

.summary-parenttitle:before {
	content: '/';
	color: rgba(255, 255, 255, 0.5);
	margin-right: 5px;
}

.summary-subtitle {
	color: #ffc563;
	margin-left: 5px;
}

.summary-subtitle:before {
	content: '/';
	color: rgba(255, 255, 255, 0.5);
	margin-right: 5px;
}

.summary-tags {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 4px;
	align-items: center;
	justify-content: flex-start;
}

.is-small .summary-tags {
	margin-top: 2px;
}

.summary-tags-item {
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	margin-right: 10px;
	display: flex;
	letter-spacing: 1px;
	align-items: center;
}

@media only screen and (max-width: 360px) {

	.summary-tags-item {
		letter-spacing: 0px;
	}

}

.is-mobile .summary-tags-item {
	font-size: 12px;
	margin-right: 2px;
}

.summary-tags-item-count {
	min-width: 20px;
	text-align: center;
	border-radius: 4px;
	margin-right: 5px;
	padding: 0px 5px;
	font-size: 14px;
	line-height: 20px;
	color: #fff;
	height: 20px;
}

.is-mobile .summary-tags-item-count {
	margin-right: 1px;
	min-width: 16px;
	padding: 0px 3px;
	font-size: 12px;
	height: 16px;
	line-height: 16px;
}

.summary-tags-item.is-primary .summary-tags-item-count {
	background-color: #19455c;
}

.summary-tags-item.is-nested .summary-tags-item-count {
	background-color: #80a3b0;
}

.summary-tags-item.is-filtered .summary-tags-item-count {
	background-color: #a64949;
}

.summary-tags-item.is-unsaved {
	background-color: #ff6868;
	color: #fff;
}

.is-mobile .summary-tags-item.is-filtered {
	font-size: 10px;
	height: 12px;
}

.is-mobile .summary-tags-item.is-primary.with-nested {
	border-radius: 4px;
	margin-bottom: 1px;
}

.is-mobile .summary-tags-item.is-nested {
	border-left: 0px;
	border-radius: 4px;
}

.summary-loading {
	font-size: 24px;
	color: #fff;
}

</style>
