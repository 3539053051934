<template>

<div class="menu">

    <template v-if="$route.meta.isSession">

        <com-item text="Settings" icon="cog" route="SessionSettings" />
        <com-item text="Syncs" icon="rotate" route="SessionSyncs" />
        <com-item text="Tips & Perks" icon="gift" route="SessionPerks" />

    </template>

    <template v-if="!$route.meta.isSession">

        <com-item text="Collection" icon="rectangle-vertical-history" route="Collection" :count="contextCount.collection" />
        <com-item text="Ratings" icon="star" route="Ratings" :count="contextCount.ratings" />
        <com-item text="Plays" icon="dice-three" route="Plays" :count="contextCount.plays" />
        <com-item text="Lists" icon="list-ol" route="Lists" :count="contextCount.lists" />
        <com-item text="Members" icon="users" route="Users" v-if="isGroup" :count="contextCount.users" />
        <com-item text="Buddies" icon="users" route="Users" v-if="isBuddies" :count="contextCount.users" />
        <com-item text="Insights" icon="chart-pie" route="Insights" />

        <template v-if="!isMinimised">

            <com-item text="Collection" route="InsightsCollection" :indent="1" :gap="0" />
            <com-item text="Size" :first="true" route="InsightsSize" :indent="2" v-if="isUser" />
            <com-item :text="shelfName" route="InsightsShelf" :indent="2" v-if="isUser" />
            <com-item text="Worth" route="InsightsWorth" :indent="2" v-if="isUser" />
            <com-item text="Ratings" route="InsightsRatings" :indent="1" :gap="(isUser) ? 4 : 1" />
            <com-item text="Plays" route="InsightsPlays" :indent="1" />
            <com-item text="Streaks & Records" :first="true" route="InsightsRecords" :indent="2" />
            <com-item text="Members" route="InsightsMembers" :indent="1" v-if="isGroup" :gap="4" />
            <com-item text="Buddies" route="InsightsMembers" :indent="1" v-if="isBuddies" :gap="4" />

        </template>

        <com-item text="Activity" icon="calendar" route="Activity" />
        <com-item text="Tools" icon="tools" route="Tools" v-show="isUser" />

    </template>

</div>

</template>

<script>

import comItem from './menu/Item'

export default {

    props: ['isMinimised'],

    components: {
        'com-item': comItem
    },

    computed: {
    
        contextCount: function() {

			return this.$store.getters['context'].count

		},

        shelfName: function() {

			return (this.$store.getters['session/config'].lists.unplayed.type === 'shame') ? 'Shelf of Shame' : 'Shelf of Opportunity'

		}
    
    }

}

</script>

<style scoped>

.menu {
    padding: 20px 0px;
    flex-grow: 1;
}

.is-minimised .menu {
    padding: 0px;
}

</style>
