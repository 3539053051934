<template>

<div class="groups" v-tooltip="'My groups & buddies'" v-on:click="onClick">

    <div class="groups-icon"><i class="fa-solid fa-people-group"></i></div>

</div>

</template>

<script>

export default {

    methods: {
    
        onClick: function(e) {

            e.stopPropagation()
        
			this.$pubsub.$emit('open.modal', 'userGroups')
        
        }
    
    }

}

</script>

<style scoped>

.groups {
    height: 64px;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.is-mobile .groups {
    height: 48px;
    margin-left: 5px;
}

.groups-icon {
    color: rgba(255, 255, 255, 1);
    background-color: #1e3648;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
}

.is-mobile .groups-icon {
    height: 32px;
    width: 32px;
    line-height: 32px;
}

</style>
