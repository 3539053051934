<template>

<core-modal v-on:close="onClose" icon="caret-left">

	<template v-slot:head>

		Create Group

	</template>

	<template v-slot:default>

		<core-form-input-textbox :autofocus="true" maxlength="25" name="name" v-on:validate="validate" placeholder="Enter group name" v-model="inputs.name" :error="is.error" :disabled="is.loading" :validation="validation.name" />

		<core-form-input-textbox maxlength="17" :prefix="slugPrefix" name="slug" v-on:validate="validate" placeholder="Enter group url" v-model="inputs.slug" :error="is.error" :disabled="!canSlug" :validation="validation.slug" :notes="slugNotes" />

		<core-form-input-section title="Avatar" />

		<core-form-input-avatar name="avatar" v-on:validate="validate" v-model="inputs.avatar" :error="is.error" :disabled="is.loading" :validation="validation.avatar" />

		<core-form-input-togglelist title="Membership" name="membership" v-on:validate="validate" v-model="inputs.membership" :error="is.error" :disabled="is.loading" :validation="validation.membership" :options="membershipOptions" />

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Confirm</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				name: '',
				slug: (this.$store.getters['session/upgraded']) ? '' : 'random',
				membership: this.$CONSTANTS.GROUP.MEMBERSHIP.OPEN,
				avatar: false
			},

			validation: {
				name: {
					exists: true,
					max: 24
				},
				membership: {
					exists: true
				},
				slug: {
					exists: this.$store.getters['session/upgraded'],
					max: 16,
					alphanumeric: true
				}
			}

		}

	},

	computed: {

		canSlug: function() {

			return this.$store.getters['session/upgraded']

		},

		slugPrefix: function() {

			return 'geekgroup.app/'

		},

		slugNotes: function() {

			return (this.canSlug) ? '' : this.$CONSTANTS.LANG.TIP.URL

		},

		membershipOptions: function() {

			return this.$CONSTANTS.GROUP.MEMBERSHIP_OPTIONS

		}

	},

	methods: {

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/groups/create', {
					data: {
						name: this.inputs.name,
						membership: this.inputs.membership,
						avatar: this.inputs.avatar,
						slug: this.inputs.slug
					}
				}).then(function(json) {

					this.$store.commit('session/groups/add', json)

					this.$router.push({
						name: 'Collection',
						params: {
							type: this.$CONSTANTS.TYPE.GROUPS,
							name: json.slug
						}
					}).catch(function(e) { console.log(e) })

					this.$emit('close')

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.inputs {
	display: flex;
}

@media only screen and (max-width: 767px) {

	.inputs {
		display: block;
	}

}

.inputs-left {
	flex-grow: 1;
	margin-right: 15px;
}

@media only screen and (max-width: 767px) {

	.inputs-left {
		margin: 0px;
	}

}

.inputs-right {
	width: 91px;
}

@media only screen and (max-width: 767px) {

	.inputs-right {
		margin: 0px auto;
	}

}

</style>