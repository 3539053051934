<template>

<core-panel>

	<template v-slot:head>

		<h1>Designers</h1>

		<p>The designers with the most games in the collection. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-person v-for="(item, index) in designers" :item="item" type="designer" v-bind:key="index" v-on:more="onMoreClick" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],
	
	data: function() {

		return {
			detail: 'designers',
			designers: []
		}

	},

	methods: {

		response: function(json) {

			this.designers = json.designers

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionDesigner',
				id: id,
				child: true
			})

		}

	}

}

</script>

<style scoped>

</style>