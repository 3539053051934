<template>

<core-list-column :format="format" type="stat" class="column">Owners</core-list-column>

</template>

<script>

export default {

	props: ['format']

}

</script>

<style scoped>

.column {
	width: 90px;
	flex-shrink: 0;
	text-align: center;
}

@media only screen and (max-width: 767px) {

	.column {
		display: none!important;
	}

}

</style>