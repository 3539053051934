export default {

	API: '',
	ASSETS: '',

	THROTTLE: {
		DELAY: 5000
	},

	TYPE: {
		USERS: 'users',
		USER: 'users',
		GROUPS: 'groups',
		GROUP: 'groups',
		BUDDIES: 'buddies'
	},

	SORT: {
		NAME: {
			'inventory.location': 'Inventory location',
			'collection.added': 'Date added',
			'age.official': 'Official minimum age',
			'age.community': 'Community minimum age',
			'date': 'Date',
			'play.date': 'Date',
			'firstname': 'Firstname',
			'lastname': 'Lastname',
			'username': 'Username',
			'name': 'Name',
			'game.name': 'Game name',
			'games': 'Games',
			'collection': 'Collection',
			'ratings': 'Ratings',
			'rating.date': 'Date',
			'rating.revisions': 'Changes',
			'rating.up': 'Increased rating',
			'rating.down': 'Decreased rating',
			'owners': 'Owners',
			'rating.user': ':user rating',
			'comments': 'Group comments',
			'rating.group': 'Group rating',
			'votes.group': 'Group votes',
			'rating.divisive': 'Group divisive',
			'rating.bgg': 'BGG rating',
			'votes.bgg': 'BGG votes',
			'rating.gap': 'BGG rating disparity',
			'rating.gap.bgg': 'BGG rating disparity',
			'rating.gap.user': ':user rating disparity',
			'count': 'Player count',
			'player.count': 'Player count',
			'duration': 'Duration',
			'play.duration': 'Duration',
			'play.location': 'Location',
			'weight': 'Weight',
			'plays': 'Plays',
			'hindex': 'h-index',
			'played.first': 'First played',
			'played.first.all': 'First played all-time',
			'played.first.context': 'First played during date range',
			'played.last': 'Last played',
			'played.last.all': 'Last played all-time',
			'played.last.context': 'Last played during date range',
			'plays.users': 'Users played',
			'plays.winpercent': ':user win %',
			'rank': 'BGG rank',
			'plays.all': 'Plays all-time',
			'plays.context': 'Plays during date range',
			'time': 'Hours played',
			'status.prevowned': 'Previously owned',
			'status.fortrade': 'For trade',
			'status.want': 'Want',
			'status.wanttoplay': 'Want to play',
			'status.wanttobuy': 'Want to buy',
			'status.wishlist': 'Wishlist',
			'status.preordered': 'Pre-ordered',
			'status.hasparts': 'Has parts',
			'status.wantparts': 'Want parts',
			'language.dependency': 'Language independence',
			'year': 'Published year',
			'wishlist.priority': 'Wishlist priority',
			'worth.value': 'Estimated value',
			'worth.play': 'Avg. play value'
		},
		ASC: {
			'inventory.location': '',
			'collection.added': 'earliest',
			'age.official': 'youngest',
			'age.community': 'youngest',
			'date': 'least recent',
			'play.date': 'least recent',
			'name': '',
			'firstname': '',
			'lastname': '',
			'username': '',
			'game.name': '',
			'games': 'fewest',
			'collection': 'smallest',
			'ratings': 'fewest',
			'rating.date': 'least recent',
			'rating.revisions': 'fewest',
			'rating.up': 'smallest',
			'rating.down': 'smallest',
			'owners': 'fewest',
			'comments': 'fewest',
			'rating.user': 'lowest',
			'rating.group': 'lowest',
			'votes.group': 'lowest',
			'rating.bgg': 'lowest',
			'votes.bgg': 'lowest',
			'rating.gap': 'smallest',
			'rating.gap.bgg': 'smallest',
			'rating.gap.user': 'smallest',
			'rating.divisive': 'least',
			'count': 'lowest',
			'player.count': 'lowest',
			'duration': 'shortest',
			'play.duration': 'shortest',
			'play.location': '',
			'weight': 'lightest',
			'plays': 'fewest',
			'hindex': 'lowest',
			'played.first': 'longest since',
			'played.first.all': 'longest since',
			'played.first.context': 'longest since',
			'played.last': 'longest since',
			'played.last.all': 'longest since',
			'played.last.context': 'longest since',
			'plays.users': 'fewest',
			'plays.winpercent': 'lowest',
			'rank': 'highest',
			'plays.all': 'fewest',
			'plays.context': 'fewest',
			'time': 'least',
			'status.prevowned': 'fewest',
			'status.fortrade': 'fewest',
			'status.want': 'fewest',
			'status.wanttoplay': 'fewest',
			'status.wanttobuy': 'fewest',
			'status.wishlist': 'fewest',
			'status.preordered': 'fewest',
			'status.hasparts': 'fewest',
			'status.wantparts': 'fewest',
			'language.dependency': 'lowest',
			'year': 'earliest',
			'wishlist.priority': 'lowest',
			'worth.value': 'lowest',
			'worth.play': 'lowest'
		},
		DESC: {
			'inventory.location': 'reverse',
			'collection.added': 'most recent',
			'age.official': 'oldest',
			'age.community': 'oldest',
			'date': 'most recent',
			'play.date': 'most recent',
			'name': 'reverse',
			'firstname': 'reverse',
			'lastname': 'reverse',
			'username': 'reverse',
			'game.name': 'reverse',
			'games': 'most',
			'collection': 'largest',
			'ratings': 'most',
			'rating.date': 'most recent',
			'rating.revisions': 'most',
			'rating.up': 'biggest',
			'rating.down': 'biggest',
			'owners': 'most',
			'comments': 'most',
			'rating.user': 'highest',
			'rating.group': 'highest',
			'votes.group': 'highest',
			'rating.bgg': 'highest',
			'votes.bgg': 'highest',
			'rating.gap': 'biggest',
			'rating.gap.bgg': 'biggest',
			'rating.gap.user': 'biggest',
			'rating.divisive': 'most',
			'count': 'highest',
			'player.count': 'highest',
			'duration': 'longest',
			'play.duration': 'longest',
			'play.location': 'reverse',
			'weight': 'heaviest',
			'plays': 'most',
			'hindex': 'highest',
			'played.first': 'most recently',
			'played.last': 'most recently',
			'plays.users': 'most',
			'plays.winpercent': 'highest',
			'rank': 'lowest',
			'plays.all': 'most',
			'plays.context': 'most',
			'time': 'most',
			'status.prevowned': 'most',
			'status.fortrade': 'most',
			'status.want': 'most',
			'status.wanttoplay': 'most',
			'status.wanttobuy': 'most',
			'status.wishlist': 'most',
			'status.preordered': 'most',
			'status.hasparts': 'most',
			'status.wantparts': 'most',
			'language.dependency': 'highest',
			'year': 'most recent',
			'wishlist.priority': 'highest',
			'worth.value': 'highest',
			'worth.play': 'highest'
		}
	},

	ERROR: {
		100: 'Invalid username',
		101: 'User not found',
		102: 'User already registered',
		103: 'Already in group',
		104: 'Not in group',
		105: 'User not found or no GeekBuddies',
		106: 'Response invalid',
		107: 'Response throttled',
		108: 'Response queued',
		109: 'Unknown error',
		110: 'Verify key invalid',
		111: 'Already logged in',
		112: 'Already logged out',
		113: 'Group not found',
		114: 'Sync not allowed',
		115: 'Sync required',
		116: 'Unauthorised request',
		117: 'Invalid request',
		118: 'Group URL taken',
		119: 'Not registered',
		120: 'List not found',
		121: 'List edition not found',
		122: 'Sync already in progress',
		123: 'Not found',
		124: 'Incorrect username or password',
		125: 'Failed to load data from BGG'
	},

	STATUS: {
		ERROR: {
			UNKNOWN: 109,
			RESPONSE: {
				INVALID: 106,
				THROTTLED: 107,
				QUEUED: 108
			},
			SYNC: {
				NOTALLOWED: 114,
				REQUIRED: 115,
				INPROGRESS: 122,
				FAILED: 125
			},
			USER: {
				INVALID: 100,
				NOTFOUND: 101,
				NOBUDDIES: 105,
				INCORRECT: 124
			},
			GROUP: {
				TAKEN: 118
			},
			LIST: {
				NOTFOUND: 120,
				EDITION: {
					NOTFOUND: 121
				}
			}
		}
	},

	WISHLIST: {
		PRIORITY: {
			MUST: 1,
			LOVE: 2,
			LIKE: 3,
			MAYBE: 4,
			DONT: 5
		},
		LABEL: {
			1: 'Must have',
			2: 'Love to have',
			3: 'Like to have',
			4: 'Thinking about it',
			5: 'Don\'t buy this'
		},
		SHORT: {
			1: 'Must',
			2: 'Love',
			3: 'Like',
			4: 'Maybe',
			5: 'Don\'t'
		}
	},

	GAME: {

		STATUS: {

			ID: {
				OWN: 1,
				PREVOWNED: 2,
				FORTRADE: 3,
				WANT: 4,
				WANTTOPLAY: 5,
				WANTTOBUY: 6,
				WISHLIST: 7,
				PREORDERED: 8,
				HASPARTS: 9,
				WANTPARTS: 10
			},

			FIELD: [
				'own',
				'prevowned',
				'fortrade',
				'want',
				'wanttoplay',
				'wanttobuy',
				'wishlist',
				'preordered',
				'hasparts',
				'wantparts'
			],

			NAME: {
				'own': 'Own',
				'prevowned': 'Prev. owned',
				'fortrade': 'For trade',
				'want': 'Want',
				'wanttoplay': 'Want play',
				'wanttobuy': 'Want buy',
				'wishlist': 'Wishlist',
				'preordered': 'Pre-ordered',
				'hasparts': 'Has parts',
				'wantparts': 'Want parts'
			},

			USERS: {
				'own': 'Owners',
				'prevowned': 'Prev. owners',
				'fortrade': 'For trade',
				'want': 'Want',
				'wanttoplay': 'Want to play',
				'wanttobuy': 'Want to buy',
				'wishlist': 'Wishlist',
				'preordered': 'Pre-ordered',
				'hasparts': 'Has parts',
				'wantparts': 'Want parts'
			}

		},

		TYPE: {

			BASE: 1,
			EXPANSION: 2

		}

	},

	VERSION: {
		BUILD: '',
		DATE: ''
	},

	COUNTRY: {

		0: '',
		1: 'Abkhazia',
		2: 'Adjara',
		3: 'Afghanistan',
		4: 'Akrotiri and Dhekelia',
		5: 'Åland',
		6: 'Albania',
		7: 'Algeria',
		8: 'American Samoa',
		9: 'Andorra',
		10: 'Angola',
		11: 'Anguilla',
		12: 'Antarctica',
		13: 'Antigua and Barbuda',
		14: 'APO/FPO',
		15: 'Argentina',
		16: 'Armenia',
		17: 'Aruba',
		18: 'Ascension Island',
		19: 'Australia',
		20: 'Austria',
		21: 'Azerbaijan',
		22: 'Bahamas',
		23: 'Bahrain',
		24: 'Bangladesh',
		25: 'Barbados',
		26: 'Belarus',
		27: 'Belgium',
		28: 'Belize',
		29: 'Benin',
		30: 'Bermuda',
		31: 'Bhutan',
		32: 'Bolivia',
		33: 'Bonaire',
		34: 'Bosnia and Herzegovina',
		35: 'Botswana',
		36: 'Bouvet Island',
		37: 'Brazil',
		38: 'British Indian Ocean Territory',
		39: 'British Virgin Islands',
		40: 'Brunei',
		41: 'Bulgaria',
		42: 'Burkina Faso',
		43: 'Burundi',
		44: 'Cambodia',
		45: 'Cameroon',
		46: 'Canada',
		47: 'Cape Verde',
		48: 'Cayman Islands',
		49: 'Central African Republic',
		50: 'Chad',
		51: 'Chile',
		52: 'China',
		53: 'Christmas Island',
		54: 'Cocos [Keeling] Islands',
		55: 'Colombia',
		56: 'Comoros',
		57: 'Cook Islands',
		58: 'Costa Rica',
		59: 'Croatia',
		60: 'Cuba',
		61: 'Curacao',
		62: 'Cyprus',
		63: 'Czech Republic',
		64: 'Democratic Republic of the Congo',
		65: 'Denmark',
		66: 'Djibouti',
		67: 'Dominica',
		68: 'Dominican Republic',
		69: 'East Timor',
		70: 'Ecuador',
		71: 'Egypt',
		72: 'El Salvador',
		73: 'England',
		74: 'Equatorial Guinea',
		75: 'Eritrea',
		76: 'Estonia',
		77: 'Ethiopia',
		78: 'Falkland Islands',
		79: 'Faroe Islands',
		80: 'Fiji',
		81: 'Finland',
		82: 'France',
		83: 'French Guiana',
		84: 'French Polynesia',
		85: 'French Southern Territories',
		86: 'Gabon',
		87: 'Gambia',
		88: 'Georgia',
		89: 'Germany',
		90: 'Ghana',
		91: 'Gibraltar',
		92: 'Greece',
		93: 'Greenland',
		94: 'Grenada',
		95: 'Guadeloupe',
		96: 'Guam',
		97: 'Guatemala',
		98: 'Guernsey',
		99: 'Guinea',
		100: 'Guinea-Bissau',
		101: 'Guyana',
		102: 'Haiti',
		103: 'Heard Island and McDonald Islands',
		104: 'Honduras',
		105: 'Hong Kong',
		106: 'Hungary',
		107: 'Iceland',
		108: 'India',
		109: 'Indonesia',
		110: 'Iran',
		111: 'Iraq',
		112: 'Ireland',
		113: 'Isle of Man',
		114: 'Israel',
		115: 'Italy',
		116: 'Ivory Coast',
		117: 'Jamaica',
		118: 'Japan',
		119: 'Jersey',
		120: 'Jordan',
		121: 'Kazakhstan',
		122: 'Kenya',
		123: 'Kiribati',
		124: 'Kosovo',
		125: 'Kuwait',
		126: 'Kyrgyzstan',
		127: 'Laos',
		128: 'Latvia',
		129: 'Lebanon',
		130: 'Lesotho',
		131: 'Liberia',
		132: 'Libya',
		133: 'Liechtenstein',
		134: 'Lithuania',
		135: 'Luxembourg',
		136: 'Macao',
		137: 'Macedonia',
		138: 'Madagascar',
		139: 'Malawi',
		140: 'Malaysia',
		141: 'Maldives',
		142: 'Mali',
		143: 'Malta',
		144: 'Marshall Islands',
		145: 'Martinique',
		146: 'Mauritania',
		147: 'Mauritius',
		148: 'Mayotte',
		149: 'Mexico',
		150: 'Micronesia',
		151: 'Moldova',
		152: 'Monaco',
		153: 'Mongolia',
		154: 'Montenegro',
		155: 'Montserrat',
		156: 'Morocco',
		157: 'Mozambique',
		158: 'Myanmar [Burma]',
		159: 'Nagorno-Karabakh',
		160: 'Namibia',
		161: 'Nauru',
		162: 'Nepal',
		163: 'Netherlands',
		164: 'Netherlands Antilles',
		165: 'New Caledonia',
		166: 'New Zealand',
		167: 'Nicaragua',
		168: 'Niger',
		169: 'Nigeria',
		170: 'Niue',
		171: 'Norfolk Island',
		172: 'North Korea',
		173: 'Northern Mariana Islands',
		174: 'Norway',
		175: 'Oman',
		176: 'Other-Africa',
		177: 'Other-Asia',
		178: 'Other-Eastern Europe',
		179: 'Other-Middle East',
		180: 'Other-South Pacific',
		181: 'Pakistan',
		182: 'Palau',
		183: 'Palestine',
		184: 'Panama',
		185: 'Papua New Guinea',
		186: 'Paraguay',
		187: 'Peru',
		188: 'Philippines',
		189: 'Pitcairn Islands',
		190: 'Poland',
		191: 'Portugal',
		192: 'Puerto Rico',
		193: 'Qatar',
		194: 'Republic of the Congo',
		195: 'Réunion',
		196: 'Romania',
		197: 'Russia',
		198: 'Rwanda',
		199: 'Saint Barthélemy',
		200: 'Saint Helena',
		201: 'Saint Kitts and Nevis',
		202: 'Saint Lucia',
		203: 'Saint Martin',
		204: 'Saint Pierre and Miquelon',
		205: 'Saint Vincent and the Grenadines',
		206: 'Samoa',
		207: 'San Marino',
		208: 'São Tomé and Príncipe',
		209: 'Saudi Arabia',
		210: 'Scotland',
		211: 'Senegal',
		212: 'Serbia',
		213: 'Seychelles',
		214: 'Sierra Leone',
		215: 'Singapore',
		216: 'Sint Maarten',
		217: 'Slovakia',
		218: 'Slovenia',
		219: 'Solomon Islands',
		220: 'Somalia',
		221: 'South Africa',
		222: 'South Georgia and the South Sandwich Islands',
		223: 'South Korea',
		224: 'South Sudan',
		225: 'Spain',
		226: 'Sri Lanka',
		227: 'Sudan',
		228: 'Suriname',
		229: 'Svalbard and Jan Mayen',
		230: 'Swaziland',
		231: 'Sweden',
		232: 'Switzerland',
		233: 'Syria',
		234: 'Taiwan',
		235: 'Tajikistan',
		236: 'Tanzania',
		237: 'Thailand',
		238: 'Togo',
		239: 'Tokelau',
		240: 'Tonga',
		241: 'Trinidad and Tobago',
		242: 'Tristan da Cunha',
		243: 'Tunisia',
		244: 'Turkey',
		245: 'Turkmenistan',
		246: 'Turks and Caicos Islands',
		247: 'Tuvalu',
		248: 'Uganda',
		249: 'Ukraine',
		250: 'United Arab Emirates',
		251: 'United Kingdom',
		252: 'United States',
		253: 'Uruguay',
		254: 'U.S. Minor Outlying Islands',
		255: 'U.S. Virgin Islands',
		256: 'Uzbekistan',
		257: 'Vanuatu',
		258: 'Vatican City',
		259: 'Venezuela',
		260: 'Vietnam',
		261: 'Wales',
		262: 'Wallis and Futuna',
		263: 'Western Sahara',
		264: 'Yemen',
		265: 'Zambia',
		266: 'Zimbabwe'

	}

}