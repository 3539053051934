<template>

<div class="tool">

	<app-unsupported v-if="!supported" />

	<core-filter subtitle="Word cloud" :sharing="is.sharing" :downloading="is.downloading" :loading="is.fetching" v-if="supported" v-on:share="onShareClick" v-on:download="onDownloadClick" />

	<div class="wordcloud" v-bind:class="{'is-saving': is.fetching, 'has-transparent': layout.empty}" ref="wrapper" v-show="supported">

		<div class="wordcloud-status" v-if="is.fetching || !is.ready"></div>

		<div class="wordcloud-canvas" ref="canvas">

			<app-head :layout="layout" />

			<div class="wordcloud-wrapper">

				<canvas ref="cloud" width="520" height="520" style="width: 520px; height: 520px;"></canvas>

			</div>

			<app-foot :layout="layout" />

		</div>

	</div>

</div>

</template>

<script>

import Unsupported from './common/Unsupported'
import Tool from './common/Tool.js'

import Head from './common/Head'
import Foot from './common/Foot'

import Filter from './wordcloud/Filter'
import WordCloud from 'core/assets/wordcloud.js'

import panzoom from 'panzoom'

export default {

	mixins: [Tool],

	components: {

		'core-filter': Filter,
		'app-unsupported': Unsupported,
		'app-head': Head,
		'app-foot': Foot

	},

	data: function() {

		return {
			toolName: 'wordcloud',
			words: [],
			min: 0,
			max: 0,
			panSetup: false,
			range: 0,
			backgroundColors: [
				'rgba(17, 105, 135, 0.2)',
				'rgba(17, 105, 135, 0.4)',
				'rgba(17, 105, 135, 0.6)',
				'rgba(17, 105, 135, 0.8)',
				'rgba(17, 105, 135, 1)'
			],
			weightFactors: [
				14,
				20,
				30,
				40,
				60
			]
		}

	},

	computed: {

		layout: function() {

			return this.$store.getters['filter/tools/wordcloud/layout']

		},

		supported: function() {

			return this.is.supported.imageExport

		}

	},

	watch: {

		layout: {

			handler: function() {

				this.visualise()

			},

			deep: true

		}

	},

	methods: {

		visualise: function() {

			if (this.is.ready && this.words.length) {

				// eslint-disable-next-line
				WordCloud(this.$refs.cloud, { 
					list: this.words,
					fontFamily: 'Roboto',
					fontWeight: 400,
					drawOutOfBound: false,
					rotateRatio: (this.layout.rotate) ? 0.5 : 0,
					rotationSteps: (this.layout.angle) ? 2 : 0,
					classes: 'wordcloud-word',
					gridSize: 10,
					shape: 'square',
					minSize: 1,
					minFontSize: 10,
					shuffle: true,
					wait: 0,
					weightFactor: function (size) {
						var index = Math.ceil(Math.floor((100 / this.range) * (size - this.min)) / 20) - 1
						index = (index > 4) ? 4 : index
						index = (index < 0) ? 0 : index
						return this.weightFactors[index]
					}.bind(this),
					color: function (word, weight) {
						var index = Math.ceil(Math.floor((100 / this.range) * (weight - this.min)) / 20) - 1
						index = (index > 4) ? 4 : index
						index = (index < 0) ? 0 : index
						return this.backgroundColors[index]
					}.bind(this)
				})

			}

		},

		fetched: function(json) {

			this.words = []

			this.max = 0
			this.min = 100000000

			this.$_.each(json.words, function(word) {

				this.words.push([
					word.text,
					word.value
				])

				if (word.value > this.max) this.max = word.value
				if (word.value < this.min) this.min = word.value

			}.bind(this))

			this.range = this.max - this.min

			this.visualise()

			if (!this.panSetup) {

				panzoom(this.$refs.wrapper, {
					smoothScroll: false,
					maxZoom: 1,
					minZoom: 0.25,
					beforeWheel: function() {
						return true
					},
					filterKey: function() {
						return true
					}
				})

				this.panSetup = false

			}

		}

	}

}

</script>

<style scoped>

.tool {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 232px);
}

.wordcloud {
	border: 1px dashed #ccc;
	padding: 4px;
	background-color: #fff;
}

.wordcloud-status {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #024359;
	background-image: url(~core/assets/load.gif);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	z-index: 2;
}

.wordcloud-empty {
	font-size: 16px;
	font-weight: 400;
	color: #999;
	line-height: 64px;
	position: absolute;
	top: 50%;
	margin-top: -32px;
	left: 0px;
	width: 100%;
	text-align: center;
}

.wordcloud-canvas {
	background-color: #fff;
}

.wordcloud.is-saving canvas {
	opacity: 0;
}

.wordcloud-word {
	padding: 4px 10px;
}

</style>