import { render, staticRenderFns } from "./Plays.vue?vue&type=template&id=baeb55d0&scoped=true&"
import script from "./Plays.vue?vue&type=script&lang=js&"
export * from "./Plays.vue?vue&type=script&lang=js&"
import style0 from "./Plays.vue?vue&type=style&index=0&id=baeb55d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baeb55d0",
  null
  
)

export default component.exports