<template>

<div class="divisive" v-tooltip="tooltip">

	<div class="divisive-name" :class="{[bandClass]: true}">{{ name }}</div>

</div>

</template>

<script>

export default {

	props: ['score', 'count'],

	computed: {

		tooltip: function() {

			return (this.count > 1) ? 'Divisive score of ' + this.score.toString() : false

		},

		bandClass: function() {

			if (this.score === 0 && this.count < 2) return 'is-empty'
			if (this.score <= 0.4) return 'is-vlow'
			if (this.score <= 0.8) return 'is-low'
			if (this.score <= 1.2) return 'is-medium'
			if (this.score <= 1.6) return 'is-high'
			if (this.score > 1.6) return 'is-vhigh'

			return false

		},

		name: function() {

			if (this.score === 0 && this.count < 2) return 'n/a'
			if (this.score <= 0.4) return 'Very Low'
			if (this.score <= 0.8) return 'Low'
			if (this.score <= 1.2) return 'Medium'
			if (this.score <= 1.6) return 'High'
			if (this.score > 1.6) return 'Very High'

			return false

		}

	}

}

</script>

<style scoped>

.divisive {
	font-size: 20px;
	line-height: 32px;
}

.divisive-name.is-empty {
	color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

.divisive-name.is-vlow {
	color: #249563;
}

.divisive-name.is-low {
	color: #2FC482;
}

.divisive-name.is-medium {
	color: #5369a2;
}

.divisive-name.is-high {
	color: #df4751;
}

.divisive-name.is-vhigh {
	color: #db303b;
}

</style>