<template>
	
<core-list-expand-section :empty="!expansions.length && !extra" placeholder="No expansions for this game." v-if="selected" class="expansions">

	<div class="expansions-section" v-if="expansions.length">

		<div class="expansions-section-title">Own<span>{{ expansions.length }}</span></div>

		<div class="expansions-section-games">

			<app-game-item v-for="subitem in expansions" filter="collection" v-bind:key="subitem.id" :summarised="true" :item="subitem" :fields="columns" :noactions="true" />

		</div>

	</div>

	<div class="expansions-section" v-if="extra">

		<div class="expansions-section-title">Don't own<span>{{ extra - expansions.length }}</span></div>

		<div class="expansions-section-games is-empty" v-if="is.loading">

			<i class="fa fa-spinner fa-spin"></i>

		</div>

		<div class="expansions-section-games" v-if="!is.loading">

			<app-game-item v-for="subitem in missing" v-bind:key="subitem.id" :summarised="true" :item="subitem" :fields="columns" :noactions="true" />

		</div>

	</div>

</core-list-expand-section>

</template>

<script>

export default {

	name: 'expansions',

	props: ['id', 'item', 'selected', 'extra'],

	data: function() {

		return {
			is: {
				loading: false
			},
			missing: []
		}

	},

	watch: {

		id: function() {

			this.populate()

		}

	},

	created: function() {

		this.populate()

	},

	computed: {

		columns: function() {

			var columns = []

			columns.push({
				type: 'avatar'
			})

			columns.push({
				type: 'name',
				sort: 'name',
				filter: 'collection',
				labelExpansions: !this.isGroupOrBuddies,
				lastPlayed: !this.isGroupOrBuddies && !this.window.is.desktop,
				showOwners: this.isGroupOrBuddies
			})

			columns.push({
				type: 'count',
				sort: 'count',
				filter: 'collection'
			})

			columns.push({
				type: 'duration',
				sort: 'duration',
				filter: 'collection'
			})

			columns.push({
				type: 'weight',
				sort: 'weight',
				filter: 'collection'
			})

			if (this.isUser || (this.isGroup && this.isMember) || this.isBuddies) {

				columns.push({
					type: 'rating',
					context: 'user',
					hideMobile: this.window.width < 375 && !this.isUser,
					sort: 'rating.user',
					filter: 'collection'
				})

			}

			if (this.isGroup) {

				columns.push({
					type: 'rating',
					context: 'group',
					sort: 'rating.group',
					filter: 'collection'
				})

			}

			if (this.isBuddies) {

				columns.push({
					type: 'rating',
					context: 'buddies',
					sort: 'rating.group',
					filter: 'collection'
				})

			}

			columns.push({
				type: 'rating',
				context: 'bgg',
				sort: 'rating.bgg',
				filter: 'collection'
			})

			return columns

		},

		existing: function() {
		
			var existing = []

			this.$_.each(this.expansions, function(item) {

				existing.push(item.game.id)

			}.bind(this))

			return existing

		},

		expansions: function() {

			var expansions = []

			this.$_.each(this.item.nested, function(id) {

				expansions.push(this.$store.getters['page/collection/nested/associated/collection'][id])

			}.bind(this))

			return expansions

		}

	},

	methods: {
	
		populate: function() {
		
			this.is.loading = true

			this.$store.dispatch('api/games/expansions', {
				id: this.item.game.id,
				type: this.$store.getters['context/type'],
				owner: this.$store.getters['context/id'],
				exclude: this.existing,
				silent: true
			}).then(function(json) {

				this.missing = json.expansions

				this.is.loading = false

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))
		
		}
	
	}

}

</script>

<style scoped>

.expansions {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 0px!important;
}

.is-mobile .expansions {
	padding: 0px!important;
}

.expansions-section-title {
	padding: 10px 10px;
	font-size: 14px;
	font-weight: 500;
	color: #333;
	display: flex;
	align-items: center;
}

.expansions-section-title span {
	display: inline-block;
	background-color: #666;
	color: #fff;
	font-size: 14px;
	padding: 2px 4px;
	margin-left: 5px;
	border-radius: 4px;
}

.expansions-section-games {
	border: 1px solid #ddd;
	border-radius: 10px;
}

.is-mobile .expansions-section-games {
	border: 0px;
	border-radius: 0px;
}

.expansions-section-games.is-empty {
	padding: 20px;
	text-align: center;
	color: #ccc;
	font-size: 24px;
}

</style>