<template>

<div class="streak" :class="{'is-dark': dark, 'is-link': !nolink}">

	<core-avatar v-if="user" type="user" :id="item.user" class="item-user-avatar" />

	<div class="streak-info">

		<div class="streak-info-name" v-if="user">{{ username }}</div>

		<div class="streak-info-games" v-if="!hideGames && !user">

			<core-avatar type="game" :id="game" v-for="(game, index) in games(item.games)" :tooltip="true" v-bind:key="index" class="streak-info-games-item" />

			<router-link v-if="!nolink && item.games.length > maxGames" :to="plays(item.plays)" v-tooltip="'Click to view all'" class="streak-info-games-more streak-info-games-item">+{{ item.games.length - maxGames }}</router-link>
			<div v-if="nolink && item.games.length > maxGames" v-tooltip="'Click to view all'" v-on:click="$emit('more')" class="streak-info-games-more streak-info-games-item">+{{ item.games.length - maxGames }}</div>

		</div>

		<router-link v-if="!nolink" :to="plays(item.plays)" class="streak-info-date" v-bind:class="{'is-large': hideGames && !user}">
			<template v-if="item.date.first">
				{{ item.date.first | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}<template v-if="item.date.first !== item.date.last"> - {{ item.date.last | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}</template>
			</template>
			<template v-if="!item.date.first">
				{{ item.date | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}
			</template>
		</router-link>

		<div v-if="nolink" class="streak-info-date" v-bind:class="{'is-large': hideGames && !user}">
			<template v-if="item.date.first">
				{{ item.date.first | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}<template v-if="item.date.first !== item.date.last"> - {{ item.date.last | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}</template>
			</template>
			<template v-if="!item.date.first">
				{{ item.date | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}
			</template>
		</div>

		<div v-if="showSummary" class="streak-info-summary">
			{{ item.plays.length }} <plural :n="item.plays.length" t="play" /> over {{ item.count }} <plural :n="item.count" t="day" /> 
		</div>

	</div>

	<router-link v-if="!nolink" :to="plays(item.plays)" class="streak-count">

		<span>{{ item.count }}</span>

		<small v-if="label"><plural :n="item.count" :t="label" /></small>

	</router-link>

	<div v-if="nolink" class="streak-count">

		<span>{{ item.count }}</span>

		<small v-if="label"><plural :n="item.count" :t="label" /></small>

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'hideGames', 'nolink', 'showSummary', 'label', 'user', 'dark'],

	computed: {

		maxGames: function() {

			return (this.window.is.smalldesktop || this.window.is.tablet) ? 5 : 8

		},

		username: function() {

			return this.$store.getters['data/users'][this.item.user].name

		}

	},

	methods: {

		plays: function(plays) {

			return {
				name: 'Plays',
				query: {
					'play.subset': plays.join(',')
				}
			}

		},

		games: function(games) {

			return games.slice(0, this.maxGames)

		}

	}

}

</script>

<style scoped>

.item-user-avatar {
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	margin-right: 8px;
}

.streak {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 64px;
	padding: 8px 15px 8px 15px;
}

@media only screen and (max-width: 767px) {

	.streak {
		padding: 8px 8px;
		height: auto;
	}

}

.streak:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.streak.is-dark:nth-child(odd) {
	background-color: rgba(255, 255, 255, 0.05);
}

.streak-info {
	flex-grow: 1;
	flex-direction: column;
	display: flex;
	justify-content: center;
	min-width: 0;
}

.streak-info-name {
	font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #333;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.streak.is-dark .streak-info-name {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.streak-info-name {
		font-size: 14px;
	}

}

.streak-info-date {
	font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    color: #666;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 2px;
}

.streak.is-dark .streak-info-date {
	color: #fff;
}

.streak-info-date.is-large {
	font-size: 14px;
	line-height: 14px;
}

.streak-info-summary {
	font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    color: #666;
    margin-top: 4px;
}

.streak.is-dark .streak-info-summary {
	color: rgba(255, 255, 255, 0.75);
}

.is-desktop .streak.is-link .streak-info-date:hover {
	text-decoration: underline;
}

.streak-info-games {
	height: 24px;
	display: flex;
	margin-bottom: 4px;
	flex-direction: row;
}

.streak-info-games-item {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	margin-right: 2px;
	flex-shrink: 0;
}

.streak-info-games-more {
	display: inline-block;
	background-color: #999;
	color: #fff;
	font-size: 10px;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.is-desktop .streak.is-dark .streak-info-games-more {
	background-color: #fff;
	color: #012B39;
	font-size: 12px;
	font-weight: bold;
}

.is-desktop .streak-info-games-more:hover {
	background-color: #666;
}

.is-desktop .streak.is-dark .streak-info-games-more:hover {
	background-color: #ccc;
}

.streak-count {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	width: 48px;
    flex-shrink: 0;
    height: 48px;
}

.streak-count > span {
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
    color: #333;
    display: block;
}

.streak.is-dark .streak-count > span {
	color: #fff;
}

.streak-count small {
    display: block;
	font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    color: #666;
    margin-top: 2px;
}

.streak.is-dark .streak-count small {
	color: rgba(255, 255, 255, 0.75);
}

.is-desktop .streak.is-link .streak-count:hover > span {
	text-decoration: underline;
}

@media only screen and (max-width: 767px) {

	.streak-count {
		font-size: 18px;
	}

}

</style>