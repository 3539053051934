<template>

<core-filter-advanced-group title="Worth" :count="count">

	<core-filter-input-range :modifier="modifier" text="Estimated value" :subtext="sign" min="0" max="200" maxText="Any" :tooltip="true" :filter="value('worth/value')" step="1" pips="20" decimals="0" density="9" />
	<core-filter-input-range :modifier="modifier" v-if="isUser" text="Avg. play value" :subtext="sign" min="0" max="200" maxText="Any" :tooltip="true" :filter="value('worth/play')" step="1" pips="20" decimals="0" density="9" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {

			fields: ['worth.value', 'worth.play']

		}

	},

	computed: {

		code: function() {

			return this.$store.getters['filter/config/other/currency']

		},

		sign: function() {

			return this.$CONSTANTS.CURRENCY[this.code].code + ' - ' + this.$CONSTANTS.CURRENCY[this.code].sign

		},

		modifier: function() {

			return this.$store.getters['filter/config/other/exchange']

		}

	}

}

</script>