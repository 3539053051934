<template>

<com-item name="ratings.types" :is-list="true" :has-more="true" v-on:more="onMoreClick" :size="size" :loading="loading" title="Categories & Mechanics">

	<template v-slot:about>

		<p>Which types of games have received the highest average rating.</p>

		<p class="small">Minimum of {{ (threshold * 2) + 1 }} <plural t="rating" :v="(threshold * 2) + 1" /> to qualify. Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<app-thing v-for="(item, index) in data" :item="item" gamesCountProperty="gamesCount" v-bind:key="index" v-on:more="onTypeClick(item.id)" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="typeOptions" :value="typeValue" v-on:change="onTypeChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		typeOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.TYPE.CATEGORY, text: 'Categories'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.TYPE.MECHANIC, text: 'Mechanics'})

			return options

		},

		typeValue: function() {

			return this.$store.getters['filter/insights/ratings/type']

		},

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'type' 

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/types']

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {
	
		onTypeChange: function(value) {

			this.$store.commit('filter/insights/ratings/type', value)

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsTypes',
				filter: this.$store.getters['filter/insights/ratings']
			})

		},

		onTypeClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsType',
				id: id,
				filter: this.$store.getters['filter/insights/ratings']
			})

		}
	
	}

}

</script>

<style scoped>

</style>