<template>

<div class="cell" :data-type="type" v-on:click="$emit('click')">

    <template v-if="isPlainValue">{{ value }}</template>

    <core-progress v-if="isPercent" :percent="value" />

    <core-rating v-if="isRating" class="cell-rating" :value="value" :count="count" />

</div>

</template>

<script>

export default {

    props: ['type', 'value', 'count'],

    computed: {

        isPlainValue: function() {
        
            return this.$_.contains(['text','link', 'number'], this.type)
        
        },

        isRating: function() {
        
            return this.type === 'rating'
        
        },

        isPercent: function() {
        
            return this.type === 'percent'
        
        }
    
    }

}

</script>

<style scoped>

.cell {
	padding: 0px 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.cell:first-child {
	padding-left: 15px;
}

.cell:last-child {
	padding-right: 15px;
}

.cell[data-type="link"],
.cell[data-type="text"] {
	text-align: left;
	justify-content: flex-start;
    font-size: 16px;
    font-weight: 400;
    color: #333;
}

.cell[data-type="number"] {
    color: #4881bb;
    font-size: 16px;
    font-weight: 500;
}

.cell[data-type="rating"] .rating {
	width: 48px;
	height: 48px;
	line-height: 48px;
}

.is-desktop .cell[data-type="link"]:hover {
	cursor: pointer;
	text-decoration: underline;
}

</style>