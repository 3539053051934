<template>

<div class="dropdown-item" v-on:click="onClick($event)" v-bind:class="{[typeClass]: true, 'is-loading': loading, 'has-icon': icon, 'is-back': back, 'is-active': active, 'is-text': textOnly === 'yes', 'is-disabled': disabled}">

	<span v-if="!text"><slot></slot></span>
	<span v-if="text">{{ text }}</span>

	<small v-show="subtitle">({{ subtitle }})</small>

	<div class="dropdown-item-caret" v-if="type === 'level' && !back"><i class="fa fa-caret-right"></i></div>
	<div class="dropdown-item-caret is-back" v-if="type === 'level' && back"><i class="fa fa-caret-left"></i></div>

	<div class="dropdown-item-tag-color" :style="{backgroundColor: color}" v-if="type === 'tag' || type === 'tagToggle'"></div>
	<div class="dropdown-item-tag-check" :class="{'is-selected': selected}" v-if="type === 'tag'"><i class="fa fa-check"></i></div>

	<div class="dropdown-item-tag-toggle" v-if="type === 'tagToggle'">

		<div class="dropdown-item-tag-toggle-item" v-on:click.stop="$emit('add')"><i class="fa fa-plus-square"></i></div>
		<div class="dropdown-item-tag-toggle-item" v-on:click.stop="$emit('remove')"><i class="fa fa-minus-square"></i></div>

	</div>

	<div v-show="icon" class="dropdown-item-icon"><i class="fa" v-bind:class="{[iconClass]: icon}"></i></div>

</div>

</template>

<script>

export default {

	props: [
		'type',
		'active',
		'params',
		'textOnly',
		'to',
		'color',
		'loading',
		'disabled',
		'toggle',
		'selected',
		'subtitle',
		'icon',
		'back',
		'text',
		'level'
	],

	computed: {

		typeClass: function() {

			if (this.$_.contains(['title', 'divide', 'section', 'tag', 'tagToggle', 'level', 'filter', 'space', 'text', 'link', 'action'], this.type)) return 'type-' + this.type

			return 'type-default'

		},

		iconClass: function() {

			return 'fa-' + this.icon

		}

	},

	methods: {

		onClick: function(e) {

			if (this.type === 'level') {

				e.stopPropagation()

				this.$emit('level', this.level)

			} else if (this.type === 'tag') {

				e.stopPropagation()

				this.$emit('toggle', !this.selected)

			}

		}

	}
	
}

</script>

<style scoped>

.dropdown-item {
	white-space: nowrap;
	font-size: 14px;
	line-height: 16px;
	color: #fff;
	font-weight: 400;
	padding: 4px 15px;
	display: block;
	width: 100%;
}

.theme-head .dropdown-item {
	padding: 7px 20px;
	font-weight: 500;
}

.dropdown-item.type-action {
	color: rgba(255, 255, 255, 0.5);
}

.dropdown-item.is-loading {
	background-position: 16px 50%;
	background-repeat: no-repeat;
	pointer-events: none;
	background-image: url(~core/assets/load-transparent.gif);
}

.dropdown-item.is-loading > * {
	visibility: hidden;
}

.dropdown-item.type-level,
.dropdown-item.type-action,
.dropdown-item.type-tag,
.dropdown-item.type-link {
	cursor: pointer;
	text-align: left!important;
}

@media only screen and (max-width: 767px) {

	.dropdown-item {
		padding: 6px 8px;
	}

}

.dropdown-item > span {
	width: 100%;
}

.dropdown-item.type-link > span {
	white-space: wrap;
}

.dropdown-item.is-back > span,
.dropdown-item.type-tag > span,
.dropdown-item.type-tagToggle > span {
	padding-left: 24px;
}

.dropdown-item small {
	font-size: 10px;
	margin-left: 2px;
}

.dropdown-item.is-active {
	font-weight: 500;
	color: #fff;
}

.dropdown-item.type-space {
	height: 9px;
	padding: 0px 15px;
}

@media only screen and (max-width: 767px) {

	.dropdown-item.type-space {
		height: 4px;
		padding: 0px 8px;
	}

}

.dropdown-item.is-disabled {
	pointer-events: none;
	color: rgba(255, 255, 255, 0.25);
}

.is-desktop .dropdown-item.type-level:hover,
.is-desktop .dropdown-item.type-action:hover,
.is-desktop .dropdown-item.type-tag:hover,
.is-desktop .dropdown-item.type-tagToggle:hover,
.is-desktop .dropdown-item.type-link:hover {
	background-color: #316293;
	color: #fff;
	font-weight: 500;
}

.is-desktop .theme-head .dropdown-item.type-level:hover,
.is-desktop .theme-head .dropdown-item.type-action:hover,
.is-desktop .theme-head .dropdown-item.type-tag:hover,
.is-desktop .theme-head .dropdown-item.type-tagToggle:hover,
.is-desktop .theme-head .dropdown-item.type-link:hover {
	background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-item.type-divide {
	height: 1px;
	padding: 0px 15px;
	background-color: rgba(0, 0, 0, 0.2);
	margin: 4px 0px;
}

@media only screen and (max-width: 767px) {

	.dropdown-item.type-divide {
		padding: 0px 8px;
	}

}

.dropdown-item.type-title {
	padding: 4px 15px;
	white-space: nowrap;
	font-size: 10px;
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.dropdown-item.type-title {
		padding: 4px 8px;
	}

}

.dropdown-item.type-section {
	margin: 0px 15px 4px 15px;
	padding: 4px 0px 8px 0px;
	white-space: nowrap;
	font-size: 14px;
	font-weight: 500;
	color: #fff;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 767px) {

	.dropdown-item.type-section {
		margin: 0px 8px 4px 8px;
	}

}

.dropdown-item.has-icon {
	padding-left: 40px;
}

.dropdown-item-icon {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 16px;
}

.dropdown-item.type-filter {
	width: 290px;
}

.dropdown-item.type-filter >>> .input-text {
	font-size: 14px;
}

.dropdown-item-caret {
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -8px;
}

.dropdown-item-tag-check {
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -8px;
	color: rgba(0, 0, 0, 0.1);
}

.dropdown-item .dropdown-item-tag-check.is-selected {
	color: #fff;
}

.dropdown-item.type-tagToggle {
	padding-right: 70px;
}

.dropdown-item-tag-toggle {
	position: absolute;
	right: 10px;
	top: 50%;
	display: flex;
	flex-direction: row;
	margin-top: -12px;
}

.dropdown-item-tag-toggle-item {
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	color: #fff;
}

.dropdown-item-caret.is-back {
	position: absolute;
	left: 15px;
}

.dropdown-item-tag-color {
	position: absolute;
	left: 15px;
	top: 50%;
	margin-top: -8px;
	width: 16px;
	height: 16px;
	border-radius: 4px;
}

.is-desktop .type .dropdown-item-tag-color {
	left: 52px;
}

</style>
