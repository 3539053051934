<template>

<core-modal v-on:close="onClose" :nopadding="true" :noheader="true" icon="caret-left">

	<template v-slot:head>

	</template>

	<template v-slot:default>

		<div class="notification-image" :style="{backgroundImage: 'url(' + data.notification.image + ')'}" />

		<div class="notification-title">

			{{ data.notification.name }}
			<small>{{ data.notification.date | formatDate('MMMM Do YYYY') }}</small>

		</div>

		<div class="notification-tags" v-if="isUpdate">
				
			<div class="notification-tags-item is-additions" v-if="data.notification.data.additions">Additions <span>{{ data.notification.data.additions }}</span></div>
			<div class="notification-tags-item is-fixes" v-if="data.notification.data.fixes">Fixes <span>{{ data.notification.data.fixes }}</span></div>

		</div>

		<div class="notification-content" v-html="data.notification.content" />

        <core-button :href="data.notification.url" target="_blank" theme="yellow" class="notification-button" v-if="data.notification.url">Read more</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

    props: ['data'],

    computed: {
    
        isUpdate: function() {
        
            return this.data.notification.type === 1
        
        }

    },

	methods: {

		onClose: function() {

			this.$emit('close')
            this.$pubsub.$emit('open.modal', 'userNotifications')

		}

	}

}

</script>

<style scoped>

.notification-title {
	color: #fff;
	font-size: 20px;
	font-weight: 500;
    padding: 20px 15px 0px 15px;
}

.notification-title small {
	font-size: 16px;
	font-weight: 300;
	display: block;
	margin-top: 4px;
}

.notification-tags {
    display: flex;
	margin-top: 2px;
    padding: 20px 15px 0px 15px;
}

.notification-tags-item {
	border-radius: 4px;
	font-size: 12px;
	font-weight: 400;
	color: #fff;
	margin-right: 5px;
	padding: 3px 5px;
}

.notification-tags-item span {
	padding-left: 5px;
	margin-left: 3px;
	border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.notification-tags-item.is-additions {
	background-color: #40b95a;
}

.notification-tags-item.is-fixes {
	background-color: #42a4fa;
}

.notification-image {
    background-position: 50% 0%;
    background-size: cover;
    height: 160px;
}

.notification-content {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 15px;
}

.notification-content >>> p {
    margin-bottom: 20px;
}

.notification-content >>> p:last-child {
    margin-bottom: 0px;
}

.notification-button {
    width: calc(100% - 30px);
    margin: 0px 15px;
	font-size: 16px!important;
}

</style>