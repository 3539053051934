import Util from 'app/util'

import getters from './games/getters.js'
import mutations from './games/mutations.js'
import actions from './games/actions.js'

export default {

	namespaced: true,

	state: {

		name: 'collection',

		route: 'Collection',

		data: Util.filter.templates.collection(),

		defaultValues: Util.filter.templates.collection(),

		preset: 0,

		changed: false

	},
	
	getters: getters,
	mutations: mutations,
	actions: actions

}