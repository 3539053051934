<template>

<div class="input" v-bind:class="{'is-changed-min': isChangedMin, 'is-changed-max': isChangedMax}">

	<span class="input-text" v-if="text">{{ text }} <small v-if="subtext">{{ subtext }}</small></span>

	<div class="input-toggle">
		<div class="input-toggle-item" :class="{'is-active': is.toggled === 'range'}" v-on:click="onToggleClick('range')" v-if="isRange">range</div>
		<div class="input-toggle-item" :class="{'is-active': is.toggled === 'range'}" v-on:click="onToggleClick('range')" v-if="!isRange">slider</div>
		<div class="input-toggle-item" :class="{'is-active': is.toggled === 'point'}" v-on:click="onToggleClick('point')" v-if="isRange">point</div>
		<div class="input-toggle-item" :class="{'is-active': is.toggled === 'custom'}" v-on:click="onToggleClick('custom')" v-if="!noText">text</div>
	</div>

	<div ref="inputRange" class="input-slider" v-show="is.toggled === 'range'"></div>
	<div ref="inputSingular" class="input-slider" v-show="is.toggled === 'point'"></div>

	<div class="input-text" v-if="is.toggled === 'custom'" :class="{'is-range': isRange}">

		<input type="text" v-on:keydown="onKeyDown" v-model="model.from" ref="from" :placeholder="(isSingular) ? 'Enter value' : 'Enter from'" class="input-text-custom" />
		<input type="text" v-on:keydown="onKeyDown" v-model="model.to" placeholder="Enter to" class="input-text-custom" v-if="isRange" />

	</div>
		
</div>

</template>

<script>

import noUiSlider from 'nouislider'
import wNumb from 'wnumb'

export default {

	props: ['text', 'subtext', 'modifier', 'input', 'inverse', 'noText', 'step', 'decimals', 'density', 'labels', 'min', 'max', 'filter', 'pips', 'tooltip', 'maxText', 'minText', 'manualValue', 'prefix', 'toggle'],

	data: function() {

		return {
			is: {
				toggled: 'range'
			},
			slider: {
				active: false,
				range: false,
				singular: false
			},
			model: {
				from: '',
				to: ''
			},
			updateValue: false,
			currentValue: false
		}

	},

	computed: {

		isRange: function() {
		
			return typeof this.value === 'object'
		
		},

		isSingular: function() {
		
			return !this.isRange

		},

		tooltips: function() {

			if (this.tooltip) {

				if (typeof this.value === 'object') {

					return [
						{
							to: function(value) {

								var modifiedValue = (this.modifier) ? value * this.modifier : value
								
								return wNumb({decimals: (parseInt(this.decimals)) ? 1 : 0}).to(modifiedValue)

							}.bind(this),
							from: function(value) {

								return value

							}
						},
						wNumb({decimals: (parseInt(this.decimals)) ? 1 : 0})
					]

				} else {

					return [wNumb({decimals: (parseInt(this.decimals)) ? 1 : 0})]

				}

			} else {

				return false

			}

		},

		isChangedMin: function() {

			if (typeof this.value === 'object') {

				return parseFloat(this.min) !== parseFloat(this.updateValue[0])

			} else {

				return parseFloat(this.min) !== parseFloat(this.updateValue)

			}

		},

		isChangedMax: function() {

			if (typeof this.value === 'object') {

				return parseFloat(this.max) !== parseFloat(this.updateValue[1])

			} else {

				return false

			}

		},

		value: function() {

			if (this.filter) {

				var value = this.$store.getters['filter/' + this.filter]

				if (typeof value === 'object') {

					return [parseFloat(this.$store.getters['filter/' + this.filter].from), parseFloat(this.$store.getters['filter/' + this.filter].to)]

				} else {

					return parseFloat(value)

				}

			} else {

				return parseFloat(this.manualValue)

			}

		}

	},

	watch: {

		model: {
		
			deep: true,

			handler: function(n) {
			
				this.onChange([n.from, n.to], true)
			
			}
		
		},

		value: function() {

			if (this.value !== this.currentValue) {

				if (this.slider.active) this.slider.active.set((this.is.toggled === 'point') ? this.value[0] : this.value)
				this.currentValue = this.value
				this.updateValue = this.value

			}

		}

	},

	methods: {

		onKeyDown: function(e) {
		
			if(!this.$_.contains([0,1,2,3,4,5,6,7,8,9,'0','1','2','3','4','5','6','7','8','9','Tab','.','Backspace'], e.key)) {
			
				e.preventDefault()
			
			}

		},

		onToggleClick: function(value) {

			if (this.is.toggled !== value) {

				this.slider.active = (value === 'point') ? this.slider.singular : this.slider.range
				this.slider.active.set((value === 'point') ? this.value[0] : this.value)
				this.is.toggled = value
				this.onChange((this.is.toggled === 'poiint') ? this.value[0] : this.value, true)

				if (this.is.toggled === 'custom') {

					if (this.isRange) {
					
						this.model.from = this.value[0]
						this.model.to = this.value[1]

					} else {

						if (!isNaN(this.value)) this.model.from = this.value		
					
					}
					
					this.$nextTick(function() {
					
						this.$refs.from.focus()

					}.bind(this))

				}

			}

		},

		pip: function(value) {

			if (!this.pips) return 1

			return (value % this.pips) ? -1 : 1

		},

		pipTo: function(value) {

			var text

			if (this.maxText) {

				var modifiedValue = (this.modifier) ? value * this.modifier : value

				if (modifiedValue > 1000) {

					text = Math.round(modifiedValue / 100).toString() + 'k'

				} else {

					text = (parseFloat(value) === parseFloat(this.max)) ? this.maxText : wNumb({
						decimals: parseInt(this.decimals)
					}).to(modifiedValue)

				}


			} else if (this.labels) {

				text = this.labels[value]

			} else {

				text = wNumb({
					decimals: parseInt(this.decimals)
				}).to(value)

			}

			return (this.prefix) ? this.prefix + text : text

		},

		pipFrom: function(value) {

			var text

			if (this.minText) {

				text = (parseFloat(value) === parseFloat(this.min)) ? this.minText : wNumb({
					decimals: parseInt(this.decimals)
				}).from(value)

			} else if (this.labels) {

				text = this.labels[value]

			} else {

				text = wNumb({
					decimals: parseInt(this.decimals)
				}).from(value)

			}

			return (this.prefix) ? this.prefix + text : text

		},

		onUpdate: function(value) {

			this.updateValue = (this.is.toggled === 'point') ? [value, value] : value

		},

		onChange: function(value, force) {

			force = force || false

			var passValue

			if (this.is.toggled === 'point') {

				this.updateValue = [value, value]

				passValue = {
					from: parseFloat(value),
					to: parseFloat(value)
				}

			} else {

				this.updateValue = value

				passValue = (typeof this.value === 'object') ? {
					from: parseFloat(value[0]),
					to: parseFloat(value[1])
				} : parseFloat(value[0])

			}

			if (this.filter) {

				if (typeof this.value === 'object') {

					if (parseFloat(this.value[0]) !== parseFloat(value[0]) || parseFloat(this.value[1]) !== parseFloat(value[1]) || force) {

						this.$store.commit('filter/' + this.filter, passValue)

					}

				} else {

					if (parseFloat(this.value[0]) !== parseFloat(value[0])) {

						this.$store.commit('filter/' + this.filter, passValue)

					}

				}

			} else {

				this.$emit('change', passValue)

			}

		}

	},

	mounted: function() {

		this.currentValue = this.value
		this.updateValue = this.value

		if (this.isRange) {
				
			this.model.from = this.value[0]
			this.model.to = this.value[1]

		} else {

			if (!isNaN(this.value)) this.model.from = this.value
				
		}

		var tooltips = this.tooltips

		if (tooltips && !this.filter) {

			tooltips = {
				to: function(value) {

					var text = wNumb({
						decimals: parseInt(this.decimals)
					}).to(value)

					return (this.prefix) ? this.prefix + text : text

				}.bind(this),
				from: function(value) {

					var text = wNumb({
						decimals: parseInt(this.decimals)
					}).to(value)

					return (this.prefix) ? this.prefix + text : text

				}.bind(this)
			}

		}

		noUiSlider.create(this.$refs.inputRange, {
			input: this.input,
			step: parseFloat(this.step),
			start: this.value,
			range: {
				min: parseFloat(this.min),
				max: parseFloat(this.max)
			},
			tooltips: tooltips,
			margin: 0,
			connect: true,
			direction: (this.inverse) ? 'rtl' : 'ltr',
			behaviour: 'tap-drag',
			pips: {
				mode: 'steps',
				density: parseInt(this.density),
				filter: this.pip,
				format: {
					to: this.pipTo.bind(this),
					from: this.pipFrom.bind(this)
				}
			}
		})

		this.slider.range = this.$refs.inputRange.noUiSlider

		this.slider.range.on('update', this.onUpdate.bind(this))
		this.slider.range.on('change', this.onChange.bind(this))

		if (this.isRange) {

			noUiSlider.create(this.$refs.inputSingular, {
				input: this.input,
				step: parseFloat(this.step),
				start: this.value[0],
				range: {
					min: parseFloat(this.min),
					max: parseFloat(this.max)
				},
				tooltips: (tooltips) ? [tooltips[0]] : false,
				margin: 0,
				connect: true,
				direction: (this.inverse) ? 'rtl' : 'ltr',
				behaviour: 'tap-drag',
				pips: {
					mode: 'steps',
					density: parseInt(this.density),
					filter: this.pip,
					format: {
						to: this.pipTo.bind(this),
						from: this.pipFrom.bind(this)
					}
				}
			})

			this.slider.singular = this.$refs.inputSingular.noUiSlider

			this.slider.singular.on('update', this.onUpdate.bind(this))
			this.slider.singular.on('change', this.onChange.bind(this))

		}

		this.slider.active = this.slider.range

	}

}

</script>

<style scoped>

.input {
	margin-top: 4px;
	width: 100%;
	height: 48px;
	margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {

	.input {
		height: 64px;
	}

}

.input-text {
	font-size: 12px;
	display: block;
	color: #fff;
	font-weight: 400;
	padding-bottom: 8px;
}

.input-text small {
	font-size: 10px;
	opacity: 0.75;
	font-weight: 300;
}

.input-slider {
	width: 100%;
	margin: 0px 0px 0px 0px;
	position: relative;
	height: 8px;
}

@media only screen and (max-width: 767px) {

	.input-slider {
		width: calc(100% - 30px);
		margin: 5px 15px 0px 15px;
		position: relative;
		height: 16px;
	}

}

.input-toggle {
	position: absolute;
	right: 0px;
	top: -1px;
	display: flex;
	flex-direction: row;
	overflow: hidden;
}

.input-toggle-item {
	font-size: 10px;
	padding: 2px 6px;
	font-weight: 400;
	color: #023544;
	background-color: rgba(255, 255, 255, 0.25);
	margin-left: 1px;
	cursor: pointer;
}

.is-desktop .input-toggle-item:not(.is-active):hover {
	font-weight: 500;
	color: #fff;
}

.input-toggle-item:first-child {
	border-radius: 4px 0px 0px 4px;
}

.input-toggle-item:last-child {
	border-radius: 0px 4px 4px 0px;
}

.input-toggle-item.is-active {
	background-color: #023544;
	color: #fff;
}

.input-text {
	display: grid;
	grid-template-columns: 1fr;
}

.input-text.is-range {
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}

.input-text-custom {
	height: 24px;
	font-size: 12px;
	width: 64px;
	font-weight: 400;
	color: #fff;
	width: 100%;
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0.25);
	text-align: center;
}

.input-text-custom::placeholder {
	color: rgba(255, 255, 255, 0.75);
	font-weight: 300;
}

</style>
