<template>

<div class="input" v-bind:class="{'is-from-active': selected.from, 'is-to-active': selected.to, 'is-stacked': subtext}">

	<div class="input-texts">

		<div class="input-text" v-if="text">{{ text }}</div>
		<div class="input-subtext" v-if="subtext">{{ subtext }}</div>

	</div>

	<div class="input-inputs">

		<core-datepicker v-on:opened="onOpened('from')" v-on:closed="onClosed('from')" v-on:selected="onDateFromSelected" v-on:click.native="onClick" :value="from" :full-month-name="true" :use-utc="true" placeholder="Any date" class="input-date" input-class="datepicker-input is-from" wrapper-class="datepicker-wrapper" calendar-class="datepicker-calendar is-left" />

		<div class="input-cancel is-left" v-if="from" v-on:click="onCancel('from')" v-propagation><i class="fa fa-times"></i></div>

		<div class="input-divide">to</div>

		<div class="input-cancel is-right" v-if="to" v-on:click="onCancel('to')" v-propagation><i class="fa fa-times"></i></div>

		<core-datepicker v-on:opened="onOpened('to')" v-on:closed="onClosed('to')" v-on:click.native="onClick" v-on:selected="onDateToSelected" :value="to" :full-month-name="true" :use-utc="true" placeholder="Any date" class="input-date" input-class="datepicker-input is-to" wrapper-class="datepicker-wrapper" calendar-class="datepicker-calendar is-right" />

	</div>

</div>

</template>

<script>

import Datepicker from 'core/assets/datepicker'

export default {

	components: {
		'core-datepicker': Datepicker
	},

	props: [
		'filter', 'text', 'subtext'
	],

	data: function() {

		return {
			selected: {
				from: false,
				to: false
			}
		}

	},

	computed: {

		from: function() {

			var value = this.$store.getters['filter/' + this.filter + '/from']

			return (value) ? this.$moment.unix(value).toDate() : null

		},

		to: function() {

			var value = this.$store.getters['filter/' + this.filter + '/to']

			return (value) ? this.$moment.unix(value).toDate() : null

		}

	},

	methods: {

		onClick: function(e) {

			e.stopPropagation()

		},

		onDateFromSelected: function(date) {

			this.$store.commit('filter/' + this.filter + '/from', this.$moment(date).utc().startOf('day').unix())

		},

		onCancel: function(side) {

			this.$store.commit('filter/' + this.filter + '/' + side, 0)

		},

		onDateToSelected: function(date) {

			this.$store.commit('filter/' + this.filter + '/to', this.$moment(date).utc().endOf('day').unix())

		},

		onOpened: function(side) {

			this.selected[side] = true

		},

		onClosed: function(side) {

			this.selected[side] = false

		}

	}

}

</script>

<style scoped>

.input {
	position: relative;
	display: flex;
	padding-bottom: 8px;
}

.input.is-stacked {
	flex-direction: column;
}

.input-texts {
	display: flex;
	flex-direction: column;
}

.input-inputs {
	display: flex;
}

.input-date {
	flex-grow: 1;
    font-weight: 400;
	font-size: 12px;
}

.input-date input::placeholder {
	color: #fff;
}

.input-date.is-active input {
	background-color: red;
}

.input-text {
	color: #fff;
	font-weight: 400;
	font-size: 12px;
}

.input-divide {
	padding: 0px 9px;
	line-height: 32px;
	color: #fff;
	font-weight: 400;
	font-size: 12px;
}

.input:not(.is-stacked) .input-text {
	white-space: nowrap;
	padding: 0px;
	line-height: 32px;
	width: 160px;
	text-align: left;
}

.input-subtext {
	color: #fff;
	font-weight: 400;
	font-size: 12px;
	opacity: 0.5;
	margin: 2px 0px 8px 0px;
}

.input-cancel {
	color: rgba(255, 255, 255, 0.5);
	font-weight: 400;
	font-size: 16px;
	position: absolute;
	top: 0px;
	margin-left: -46px;
	width: 32px;
	height: 32px;
	line-height: 34px;
	text-align: center;
	z-index: 2;
	cursor: pointer;
}

.is-desktop .input-cancel:hover {
	color: #fff;
}

.input-cancel.is-left {
	left: 50%;
	margin-left: -46px;
}

.input-cancel.is-right {
	right: 0px;
}

</style>
