<template>

<core-panel>

	<template v-slot:head>

		<h1>Ratings</h1>

		<p>The <b>{{ users.length }}</b> group ratings for <b>{{ game }}</b>.</p>

	</template>

	<template v-slot:body>

		<app-user v-for="u in users" :item="user(u.id)" :rating="u.rating" :notLinked="true" v-bind:key="u.id" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'ratings',
			params: [
				'id'
			],
			users: [],
			game: ''
		}

	},

	methods: {

		user: function(id) {

			return this.$store.getters['data/users/associated'][id]

		},

		response: function(json) {

			this.users = json.users
			this.game = json.game

		}

	}

}

</script>