<template>
	
<core-list-expand-section :empty="!players.length" placeholder="No users have played this." v-if="selected" class="players">

	<vue-good-table :columns="columns" :rows="players" styleClass="vgt-table striped condensed" :sort-options="{ enabled: true, initialSortBy: {field: 'primaryname', type: 'asc'} }">

		<template slot="table-row" slot-scope="props">

			<div v-if="props.column.field == 'primaryname'" class="player">

				<core-avatar type="user" :id="props.row.id" class="player-avatar" />

				<div class="player-name">
					{{ props.row.primaryname }}
					<small v-if="props.row.secondaryname">{{ props.row.secondaryname }}</small>
				</div>

			</div>

		</template>

	</vue-good-table>

</core-list-expand-section>

</template>

<script>

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

export default {

	name: 'opponents',

	props: ['item', 'format', 'selected'],

	components: {
		VueGoodTable
	},

	data: function() {

		return {
			columns: [
				{ label: 'User', field: 'primaryname' },
				{ label: 'Plays', field: 'plays', type: 'number', width: '80px' },
				{ label: 'Results', field: 'results', type: 'number', width: '80px' },
				{ label: 'Wins', field: 'wins', type: 'number', width: '80px' },
				{ label: 'Win %', field: 'percent', type: 'number', width: '80px' }
			]
		}

	},

	computed: {

		players: function() {

			var players = []

			this.$_.each(this.item.users.played, function(plays, id) {

				var player = {}

				var user = this.$store.getters['data/users/associated'][id]

				player.id = id
				player.primaryname = user.fullname || user.name
				player.secondaryname = (user.fullname) ? user.name : ''
				player.plays = plays
				player.results = (this.item.players[id]) ? this.item.players[id].results : 0
				player.wins = (this.item.players[id]) ? this.item.players[id].wins : 0
				player.percent = (player.results) ? ((100 / player.results) * player.wins).toFixed(1) : ''

				players.push(player)

			}.bind(this))

			return players

		}

	}

}

</script>

<style scoped>

.opponents {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 0px!important;
}

.is-mobile .opponents {
	padding: 0px!important;
}

.vgt-wrap {
	width: 100%;
}

.vgt-wrap >>> table {
	border: 0px!important;
}

.vgt-wrap >>> thead th {
	font-weight: 500!important;
	font-size: 14px!important;
	padding: 5px 2px!important;
	line-height: 22px!important;
	border-bottom: 1px solid #eee!important;
	background: #fff!important;
	color: #333!important;
	text-align: center!important;
}

.vgt-wrap >>> thead th:hover {
	background: #eee!important;
}

.vgt-wrap >>> tbody td {
	font-weight: 400!important;
	font-size: 14px!important;
	padding: 5px 2px!important;
	line-height: 22px!important;
	border: 0px!important;
	color: #333!important;
	text-align: center!important;
}

.vgt-wrap >>> thead th:first-child,
.vgt-wrap >>> tbody td:first-child {
	text-align: left!important;
}

.is-mobile .vgt-wrap >>> thead th:first-child,
.is-mobile .vgt-wrap >>> tbody td:first-child {
	padding-left: 8px!important;
	min-width: 160px!important;
}

.is-mobile .vgt-wrap >>> thead th:last-child,
.is-mobile .vgt-wrap >>> tbody td:last-child {
	padding-right: 8px!important;
}

.player {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.player-avatar {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.player-name {
	font-size: 14px;
	line-height: 14px;
}

.player-name small {
	font-size: 11px;
	line-height: 11px;
	display: block;
	color: #666;
}

</style>