<template>
	
<core-list-expand-section :empty="!ratings.length" :placeholder="'No ' + type + ' have rated this game yet.'" v-if="selected" class="ratings">

	<div v-for="rating in ratings" v-bind:key="rating.user" class="ratings-item">
					
		<core-avatar class="ratings-item-avatar" :id="rating.user" type="user" tooltip="1"></core-avatar>
					
		<div class="ratings-item-value" :data-band="Math.floor(rating.value)">

			{{ rating.value }}

		</div>

	</div>

</core-list-expand-section>

</template>

<script>

export default {

	name: 'ratings',

	props: ['item', 'selected'],

	computed: {

		ratings: function() {

			var sorted = []

			this.$_.each(this.item.users.rated, function(value, key) {

				sorted.push({
					user: key,
					value: value
				})

			})

			return this.$_.sortBy(sorted, 'value')

		},

		type: function() {

			if (this.$store.getters['context/is/group']) return 'members'
			if (this.$store.getters['context/is/buddies']) return 'buddies'

			return false

		}

	}

}

</script>

<style scoped>

.ratings {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.ratings-item {
	display: inline-block;
	margin-right: 1px;
	margin-bottom: 1px;
	height: 64px;
	width: 32px;
	font-size: 0;
	vertical-align: top;
}

.ratings-item-avatar {
	display: block;
	width: 32px;
	font-size: 12px;
	height: 32px;
}

.ratings-item-value {
	display: block;
	width: 32px;
	height: 32px;
	line-height: 32px;
	text-align: center;
	color: #fff;
	font-size: 14px;
	z-index: 2;
}

.ratings-item-value[data-band="10"] { background-color: #249563; }
.ratings-item-value[data-band="9"] { background-color: #249563; }
.ratings-item-value[data-band="8"] { background-color: #2FC482; }
.ratings-item-value[data-band="7"] { background-color: #1d8acd; }
.ratings-item-value[data-band="6"] { background-color: #5369a2; }
.ratings-item-value[data-band="5"] { background-color: #5369a2; }
.ratings-item-value[data-band="4"] { background-color: #df4751; }
.ratings-item-value[data-band="3"] { background-color: #df4751; }
.ratings-item-value[data-band="2"] { background-color: #db303b; }
.ratings-item-value[data-band="1"] { background-color: #db303b; }
.ratings-item-value[data-band="0"] { background-color: #db303b; }

</style>