<template>

<core-list-cell :format="format" type="stat" class="count">
					
	<app-game-item-field-name-owners :format="format" :item="item" v-if="!isUser" :max="5" class="owners" />

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format']

}

</script>

<style scoped>

.owners {
	margin-top: 0px!important;
	display: flex!important;
	flex-direction: row;
	flex-wrap: wrap;
	height: auto!important;
}

</style>
