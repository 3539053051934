<template>
	
<core-card-foot-item>

	<core-card-foot-item-value class="count-wrapper">

		<app-game-item-field-count :item="item" :format="format"  />

	</core-card-foot-item-value>

	<core-card-foot-item-label>

		Players

	</core-card-foot-item-label>

</core-card-foot-item>

</template>

<script>

export default {

	props: ['format', 'item', 'filter']

}

</script>

<style scoped>

.count-wrapper >>> .count-official.with-community {
	padding-top: 0px;
}

.count-wrapper >>> .count-official {
	color: #666;
	font-weight: 500;
	font-size: 14px;
}

.count-wrapper >>> .count-official:not(.with-community) {
	height: 32px;
	line-height: 32px;
	font-size: 24px;
}

.count-wrapper >>> .count-community {
	margin-top: 2px;
}

</style>
