<template>

<app-page>

    <core-content-wrapper :loading="is.loading">

        <core-filter-bar v-if="!is.loading">

            <core-filter-bar-left>

                <core-filter-summary title="Syncs" icon="rotate" />

            </core-filter-bar-left>

            <core-filter-bar-right>

                <core-button tooltip="Click to run sync" :disabled="is.saving" theme="filter" text="Run sync" icon="rotate" v-on:click.native="onRunClick" />
                <core-button tooltip="Click to save setting changes" :loading="is.saving" :disabled="!isChanged || is.saving" theme="filter" text="Save settings" icon="floppy-disk" v-on:click.native="onSaveClick" />

            </core-filter-bar-right>

        </core-filter-bar>

        <core-content-page v-if="!is.loading" class="syncs">

            <com-about :data="data" v-on:change="onSettingsChange" />

            <com-areas :data="data" />

            <com-history :data="data" />

        </core-content-page>

    </core-content-wrapper>
		
</app-page>

</template>

<script>

import Vue from 'vue'

import comAbout from './syncs/About'
import comAreas from './syncs/Areas'
import comHistory from './syncs/History'

export default {

    components: {
        'com-about': comAbout,
        'com-areas': comAreas,
        'com-history': comHistory
    },

    data: function() {
    
        return {
            is: {
                loading: true,
                saving: false
            },
            settings: {},
            data: {}
        }
    
    },

    created: function() {
    
        this.load()
    
    },

    computed: {
    
        isChanged: function() {
        
            return !this.$_.isEqual(this.settings, this.data.settings)
        
        }
    
    },

    methods: {
    
        load: function() {
        
            this.$api.get('session/syncs').then(function(json) {

                this.onDataLoaded(json)

                this.is.loading = false
            
            }.bind(this))
        
        },

        onRunClick: function() {
        
            this.$pubsub.$emit('open.modal', 'userSync')

        },

        onDataLoaded: function(json) {
        
            Vue.set(this, 'data', json)
            Vue.set(this, 'settings', this.$util.copy(json.settings))
        
        },

        onSettingsChange: function(value) {
        
            Vue.set(this, 'settings', value)

        },

        onSaveClick: function() {
        
            this.is.saving = true

            var params = this.$util.copy(this.settings)

            if (!params.zone) params.zone = Intl.DateTimeFormat().resolvedOptions().timeZone
        
            this.$api.put('session/syncs', params).then(function(json) {

                this.onDataLoaded(json)

                this.$notify({
                    message: 'Settings successfully saved.'
                })

                this.is.saving = false
            
            }.bind(this))
        
        }

    }

}

</script>

<style scoped>

.is-mobile .syncs {
    padding: 10px;
}

</style>