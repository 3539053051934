<template>

<core-content-wrapper :loading="!is.ready">

	<core-filter :loading="is.fetching" :filter="insightName" v-on:refresh="onRefresh">

	</core-filter>

	<core-content-page v-if="is.reset && is.fetching !== 'all'">

		<core-content-page-inner v-show="is.ready" :is-grid="true">

			<com-overview :fetching="is.fetching" :your="your" :you="you" :period="period" :size="3" />

			<com-calendar :fetching="is.fetching" :your="your" :you="you" :period="period" :size="3" />

			<com-heatmap :fetching="is.fetching" :your="your" :you="you" :period="period" :size="6" />

			<com-played :fetching="is.fetching" :your="your" :you="you" :size="6" />

			<com-weight :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-counts :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-duration :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-rank :fetching="is.fetching" :your="your" :you="you" :size="6" />

			<com-year :fetching="is.fetching" :your="your" :you="you" :size="3" />

			<com-year :fetching="is.fetching" :your="your" :you="you" :size="3" />
			
			<com-designers :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-publishers :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-types :fetching="is.fetching" :your="your" :you="you" :size="2" />

			<com-hindex v-if="isUser" :fetching="is.fetching" :your="your" :you="you" :size="4" />
			
			<com-locations v-if="isUser" :fetching="is.fetching" :your="your" :you="you" :size="2" />

		</core-content-page-inner>

	</core-content-page>

</core-content-wrapper>

</template>

<script>

import Section from './common/Section.js'

import comOverview from './plays/Overview'
import comWeight from './plays/Weight'
import comCounts from './plays/Counts'
import comHeatmap from './plays/Heatmap'
import comYear from './plays/Year'
import comRank from './plays/Rank'
import comDuration from './plays/Duration'
import comDesigners from './plays/Designers'
import comPublishers from './plays/Publishers'
import comTypes from './plays/Types'
import comLocations from './plays/Locations'
import comPlayed from './plays/Played'
import comHindex from './plays/hIndex'
import comCalendar from './plays/Calendar'

export default {

	mixins: [Section],

	components: {
		'com-designers': comDesigners,
		'com-publishers': comPublishers,
		'com-calendar': comCalendar,
		'com-overview': comOverview,
		'com-weight': comWeight,
		'com-counts': comCounts,
		'com-heatmap': comHeatmap,
		'com-rank': comRank,
		'com-year': comYear,
		'com-duration': comDuration,
		'com-types': comTypes,
		'com-locations': comLocations,
		'com-played': comPlayed,
		'com-hindex': comHindex
	},

	data: function() {

		return {

			insightName: 'plays',

			dynamicInsights: [
				'year',
				'ranking.band',
				'type',
				'heatmap',
				'calendar'
			]

		}

	}

}

</script>