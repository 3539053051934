<template>

<div class="band" :style="style">

	<div class="band-name">{{ name }}</div>
	<div class="band-score" v-if="config.subtitles">{{ subtitle }}</div>

</div>

</template>

<script>

export default {

	props: ['value', 'config'],

	computed: {

		style: function() {

			var style = {
				backgroundColor: false
			}

			this.$_.each(this.config.colors, function(hex, threshold) {

				if (threshold <= this.value) style.backgroundColor = hex

			}.bind(this))

			return style

		},

		name: function() {

			var name = 0

			this.$_.each(this.config.bands, function(band, threshold) {

				if (threshold <= this.value) name = band

			}.bind(this))

			return name

		},

		subtitle: function() {

			var name = 0

			this.$_.each(this.config.subtitles, function(subtitle, threshold) {

				if (threshold <= this.value) name = subtitle

			}.bind(this))

			return name

		}

	}

}

</script>

<style scoped>

.band {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100px;
	border-bottom: 1px solid #fff;
	border-radius: 4px 4px 0px 0px;
	justify-content: center;
	align-items: center;
}

.band-name {
	color: #fff;
	font-size: 28px;
	line-height: 28px;
	padding: 0px 10px;
}

.band-score {
	font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    margin-top: 8px;
    text-align: center;
}

</style>