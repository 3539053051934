import store from 'app/store'
import pubsub from 'core/pubsub'

export default {

	remove: function(context, e) {

		e = e || {}

		var index = (e.override === undefined) ? context.getters['all'] : [e.override.item]

		return new Promise(function() {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {

				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded']
					}).then(function(json) {

						internalResolve(json.index)

					})

				} else {

					internalResolve(index)

				}

			}).then(function(index) {

				pubsub.$emit('open.modal', {
					id: 'groupRemove',
					users: index
				})

			})

		})

	}

}