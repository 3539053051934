<template>

<div class="widget" :class="{'is-scroll': scroll, 'is-loading': loading, 'is-slim': isSlim}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['scroll', 'loading', 'isSlim']

}

</script>

<style scoped>

.widget {
	width: 100%;
	height: 100%;
	background-color: #fff;
	padding: 15px;
}

.widget.is-slim {
	padding: 0px;
}

.widget.is-scroll {
	padding: 0px;
	display: flex;
	flex-direction: column;
	overflow-x: auto!important;
	overflow-y: hidden!important;
}

.widget.is-loading {
	background-image: url(~@/core/assets/load.gif);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.widget.is-loading > * {
	visibility: hidden;
}

</style>