import _ from 'underscore'
import CONSTANTS from 'app/constants'

import tags from './select/tags.js'
import filter from './select/filter.js'
import game from './select/game.js'
import exporting from './select/export.js'
import list from './select/list.js'
import users from './select/users.js'
import nesting from './select/nesting.js'

export default {

	namespaced: true,

	modules: {},

	state: {

		data: {
			key: '',
			selected: [],
			mode: CONSTANTS.SERVICE.SELECT.MODE.NONE,
			name: '',
			count: 0,
			page: 0,
			pages: 0,
			can: {
				rename: false,
				tag: false,
				filter: false,
				nesting: false,
				export: false,
				game: false,
				list: {
					move: false,
					remove: false
				},
				users: {
					remove: false
				}
			}
		}

	},

	getters: {

		can: function(state) {

			return state.data.can

		},

		selected: function(state) {

			return function(id) {

				return (state.data.mode === CONSTANTS.SERVICE.SELECT.MODE.NONE) ? _.contains(state.data.selected, id) : !_.contains(state.data.selected, id)

			}

		},

		excluded: function(state) {

			return (state.data.mode === CONSTANTS.SERVICE.SELECT.MODE.ALL) ? state.data.selected : []

		},

		all: function(state, getters, rootState, rootGetters) {

			var all = []

			if (state.data.mode === CONSTANTS.SERVICE.SELECT.MODE.NONE) {

				all = state.data.selected

			} else if (state.data.page === state.data.pages) {

				_.each(rootGetters['page/' + state.data.name + '/list'], function(value) {

					if (!_.contains(state.data.selected, value.id)) all.push(value.id)

				})

			} 

			return all

		},

		'all/games': function(state, getters, rootState, rootGetters) {

			var all = []

			if (state.data.mode === CONSTANTS.SERVICE.SELECT.MODE.NONE) {

				_.each(state.data.selected, function(value) {

					all.push(rootGetters['page/' + state.data.name + '/associated/raw'][value].game.id)

				})

			} else if (state.data.page === state.data.pages) {

				_.each(rootGetters['page/' + state.data.name + '/list'], function(value) {

					if (!_.contains(state.data.selected, value.id)) all.push(value.game.id)

				})

			} 

			return all

		},

		count: function(state) {

			return (state.data.mode === CONSTANTS.SERVICE.SELECT.MODE.NONE) ? state.data.selected.length : state.data.count - state.data.selected.length

		},

		'unselected/count': function(state) {

			return (state.data.mode === CONSTANTS.SERVICE.SELECT.MODE.ALL) ? state.data.selected.length : 0

		},

		key: function(state) {

			return state.data.key

		},

		mode: function(state) {

			return state.data.mode

		},

		name: function(state) {

			return state.data.name

		},

		page: function(state) {

			return state.data.page

		},

		pages: function(state) {

			return state.data.pages

		},

		external: function(state) {

			return state.data.mode === CONSTANTS.SERVICE.SELECT.MODE.ALL && state.data.page < state.data.pages

		}

	},

	mutations: {

		page: function(state, value) {

			state.data.page = value

		},

		toggle: function(state, id) {

			if (_.contains(state.data.selected, id)) {

				state.data.selected.splice(state.data.selected.indexOf(id), 1)

			} else {

				state.data.selected.push(id)

			} 

		}

	},

	actions: {

		init: function(context, e) {

			context.state.data.key = e.key
			context.state.data.name = e.name
			context.state.data.count = e.count
			context.state.data.can = e.can
			context.state.data.pages = e.pages
			context.state.data.page = 1
			context.state.data.selected = []
			context.state.data.mode = CONSTANTS.SERVICE.SELECT.MODE.NONE

		},

		'selection/all': function(context) {

			context.state.data.selected = []
			context.state.data.mode = CONSTANTS.SERVICE.SELECT.MODE.ALL

		},

		'selection/none': function(context) {

			context.state.data.selected = []
			context.state.data.mode = CONSTANTS.SERVICE.SELECT.MODE.NONE

		},

		'selection/invert': function(context) {

			context.state.data.mode = (context.state.data.mode === CONSTANTS.SERVICE.SELECT.MODE.NONE) ? CONSTANTS.SERVICE.SELECT.MODE.ALL : CONSTANTS.SERVICE.SELECT.MODE.NONE

		},

		'nesting/remove': nesting.remove,
		'nesting/restore': nesting.restore,
		'nesting/clear': nesting.clear,
		'nesting/add': nesting.add,

		'users/remove': users.remove,

		'list/move': list.move,
		'list/remove': list.remove,

		'export/csv': exporting.csv,
		'export/clipboard': exporting.clipboard,

		'filter/subset': filter.subset,
		'filter/exclude': filter.exclude,
		'filter/include': filter.include,
		'filter/include/add': filter.includeAdd,
		'filter/include/remove': filter.includeRemove,

		'tags/clear': tags.clear,
		'tags/toggle': tags.toggle,
		'tags/add': tags.add,
		'tags/remove': tags.remove,
		'tags/create': tags.create,

		'game/rename': game.rename

	}

}