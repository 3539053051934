<template>
	
<div v-if="tags.length" class="tags">

	<div v-for="tag in tags" :key="tag.id" class="tags-item" :style="{backgroundColor: tag.colour}">
		<div class="tags-item-text">{{ tag.text }}</div>
	</div>

</div>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		tags: function() {

			var tags = []

			this.$_.each(this.$store.getters['data/tags/list'], function(tag) {

				if (this.$_.contains(this.item.tags, tag.id)) {

					tags.push(tag)

				}

			}.bind(this))

			return tags

		}

	}

}

</script>

<style scoped>

.tags {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.tags-item {
	font-size: 10px;
	color: #fff;
	margin-right: 4px;
	margin-bottom: 4px;
	padding: 2px 4px;
	border-radius: 4px;
	line-height: 10px;
	cursor: pointer;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	cursor: initial;
}

</style>
