<template>

<div ref="canvas" class="insights-item" :data-theme="theme" :data-overview="isOverview" :data-size="size" :class="{'is-cards': isCards, 'is-list': isList, 'is-overview': isOverview, 'is-blank': isBlank || isOverview || isCards, 'any-height': anyHeight, 'is-detatched': true, 'is-loading': loading, 'is-empty': isEmpty}">

	<div class="insights-item-head">

		<div class="insights-item-head-title">{{ title }}</div>

		<div class="insights-item-head-actions">

			<slot name="buttons"></slot>

			<core-button v-on:click.native="$emit('more')" tooltip="View all" v-propagation icon="arrow-up-right" :iconOnly="true" v-if="hasMore" />

			<com-toggle :options="shareOptions" icon="fa-ellipsis" :loading="is.downloading || is.sharing" v-on:click="onShareClick" :theme="(theme === 'blue') ? 'blue': 'white'" v-if="is.compatible" />

		</div>

	</div>

	<div class="insights-item-content">

		<com-empty v-if="isEmpty && !isCards && !loading" :loading="loading && !isList" text="No data." />
		
		<div class="insights-item-loading" v-if="loading && !isCards && !isList">
		
			<i class="empty-icon fa fa-spinner fa-spin" />
		
		</div>

		<slot name="stats"></slot>

		<div class="insights-item-content-card-empty" v-for="card in emptyCards" :key="card">
		
			<i class="fa fa-empty-set"></i>
		
		</div>

	</div>

</div>

</template>

<script>

import domtoimage from 'dom-to-image-more'
import comEmpty from './Empty'
import comToggle from './Toggle'

export default {

	props: ['theme', 'title', 'loading', 'anyHeight', 'isBlank', 'isOverview', 'emptyCards', 'isDetatched', 'isList', 'isCards', 'isEmpty', 'size', 'hasMore'],

	components: {
		'com-empty': comEmpty,
		'com-toggle': comToggle
	},

	data: function() {

		return {

			is: {
				sharing: false,
				downloading: false,
				compatible: false
			},

			shareOptions: [
				{ value: 1, text: 'Share as image' },
				{ value: 2, text: 'Download as image' }
			]

		}

	},

	computed: {

		hasButtons: function() {

			if (this.$slots.buttons) {

				var exists = false

				this.$_.each(this.$slots.buttons, function(button) {

					if (button.tag !== undefined) exists = true

				})

				if (exists) return true

			}

			return false

		}

	},

	created: function() {

		if (document.documentMode || /Edge\//.test(navigator.userAgent)) {
	
			this.is.compatible = false

		}

	},

	methods: {

		onShareClick: function (value) {

			if(value === 1) {

				this.is.sharing = true

				this.generateImage()
			
			} else {

				this.is.downloading = true

				this.generateImage()
				
			}

		},

		generateImage: function() {

			var node = this.$refs.canvas

			domtoimage.toPng(node, {
				filter: function(node) {

					return !this.$_.contains([
						'item-head-actions'
					], node.className)

				}.bind(this)
			}).then(function(dataUrl) {
						
				this.saveImage({
					image: dataUrl,
					width: node.clientWidth,
					height: node.clientHeight
				})

			}.bind(this)).catch(function() {

				this.is.downloading = false
				this.is.sharing = false

			}.bind(this))

		},

		saveImage: function(data) {

			var type = this.$route.params.type
			var name = this.$route.params.name

			this.$store.dispatch('api/share/save', {
				ownerType: type,
				ownerSlug: name,
				type: 'insight.' + this.name,
				data: data
			}).then(function(json) {

				if (this.is.sharing) {

					this.$pubsub.$emit('sharing', json)

				} else {

					window.location.href = json.download

				}

				this.is.sharing = false
				this.is.downloading = false

			}.bind(this), function() {

				this.is.sharing = false
				this.is.downloading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.insights-item {
	display: flex;
	flex-shrink: 0;
	min-width: 0;
	flex-direction: column;
}

.is-desktop .insights-item:hover:before {
	content: '';
	position: absolute;
	left: -20px;
	top: -10px;
	width: calc(100% + 40px);
	height: calc(100% + 30px);
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}

.insights-item:not(.is-overview):not(.is-detatched) {
	border-radius: 4px;
	background-color: #fff;
	border: 1px solid #ddd;
}

.insights-item[data-size="1"] {
	grid-column: span 1;
}

.insights-item[data-size="2"] {
	grid-column: span 2;
}

.insights-item[data-size="3"] {
	grid-column: span 3;
}

.insights-item[data-size="4"] {
	grid-column: span 4;
}

.insights-item[data-size="5"] {
	grid-column: span 5;
}

.insights-item[data-size="6"] {
	grid-column: span 6;
}

.is-tablet .insights-item[data-size="1"],
.is-tablet .insights-item[data-size="2"],
.is-tablet .insights-item[data-size="3"],
.is-tablet .insights-item[data-size="4"],
.is-tablet .insights-item[data-size="5"],
.is-tablet .insights-item[data-size="6"] {
	grid-column: span 6;
}

.is-mobile .insights-item[data-size="1"],
.is-mobile .insights-item[data-size="2"],
.is-mobile .insights-item[data-size="3"],
.is-mobile .insights-item[data-size="4"],
.is-mobile .insights-item[data-size="5"],
.is-mobile .insights-item[data-size="6"] {
	grid-column: span 6;
}

.insights-item[data-theme="blue"] {
	background-color: #4881bb;
	border-radius: 4px;
}

.insights-item-head-buttons,
.insights-item-head-text-share {
	display: none;
}

.insights-item-head {
	width: 100%;
	display: flex;
	flex-shrink: 0;
	height: 40px;
	user-select: none;
	align-items: center;
	margin-bottom: 5px;
}

.insights-item.is-detatched .insights-item-head,
.insights-item.is-overview .insights-item-head {
	border-radius: 4px;
}

.insights-item[data-theme="blue"] .insights-item-head {
	background-color: transparent;
	border: 0px;
}

.insights-item[data-theme="blue"] .insights-item-head {
	border-bottom: 1px solid #4881bb;
}

.insights-item-head-title {
	flex-grow: 1;
	color: #343434;
	font-size: 20px;
	font-weight: 500;
}

.insights-item[data-theme="blue"] .insights-item-head-title {
	color: #fff;
	text-align: center;
}

.insights-item-head-actions {
	display: flex;
}

.insights-item-head-actions >>> .button.theme-plain {
	background-color: #fff;
	color: #333;
	margin-left: 4px;
}

.is-desktop .insights-item-head-actions >>> .button.theme-plain:hover {
    background-color: #eee;
}

.insights-item-head-actions >>> .button.theme-plain .button-icon {
	font-size: 24px!important;
}

.is-desktop .insights-item-head-actions >>> .button.theme-plain:hover .button-icon {
	color: #333!important;
}

.insights-item-head-actions >>> .button.theme-blue {
	background-color: #4881bb;
	color: #fff;
	min-width: 40px;
	margin-left: 4px;
}

.is-desktop .insights-item-head-actions >>> .button.theme-blue:hover {
    background-color: #244d76!important;
}

.insights-item-content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	min-height: 322px;
	overflow-x: auto;
	overflow-y: hidden;
}

.is-mobile .insights-item.is-empty .insights-item-content {
	min-height: 100px;
}

.insights-item.any-height .insights-item-content {
	min-height: 1px;
}

.insights-item:not(.is-blank) .insights-item-content {
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #fff;
}

.insights-item[data-theme="blue"] .insights-item-content {
	margin-top: 0px;
	background-color: transparent;
	border-radius: 0px;
	border: 0px;
}

.insights-item.is-overview .insights-item-content {
	flex-grow: 1;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	align-content: start;
	align-items: center;
	grid-gap: 5px;
}

.insights-item.is-overview[data-overview="3"] .insights-item-content {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.insights-item.is-overview[data-overview="3x4"] .insights-item-content {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
}

.insights-item.is-overview[data-overview="4x4"] .insights-item-content {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.insights-item.is-overview[data-overview="4"] .insights-item-content {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.is-mobile .insights-item.is-overview[data-overview="4"] .insights-item-content {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.insights-item.is-overview[data-overview="5"] .insights-item-content {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.insights-item.is-overview[data-overview="6"] .insights-item-content {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.insights-item.is-overview[data-overview="7"] .insights-item-content {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	min-height: 160px;
}

.insights-item-content >>> .widget {
	background-color: transparent;
}

.insights-item.is-cards .insights-item-content {
	display: grid;
	grid-gap: 5px;
	min-height: 1px;
	grid-template-columns: repeat(5, minmax(0, 1fr));
}

.is-mobile .insights-item.is-cards .insights-item-content {
	grid-template-columns: repeat(1, minmax(0, 1fr));
}

@keyframes fade { 
	0% { opacity: 1; }
	25% { opacity: 0.25; }
	50% { opacity: 1; }
	100% { opacity: 1; }
}

.insights-item.is-loading >>> .accordian-segment,
.insights-item.is-cards.is-loading >>> .card,
.insights-item.is-list.is-loading >>> .item {
	opacity: 1;
	animation: fade 2000ms ease-in-out;
	animation-iteration-count: infinite;
}

.insights-item.is-list.is-loading >>> .item:nth-child(1),
.insights-item.is-cards.is-loading >>> .card:nth-child(1) {
	animation-delay: 300ms;
}

.insights-item.is-list.is-loading >>> .item:nth-child(2),
.insights-item.is-cards.is-loading >>> .card:nth-child(2) {
	animation-delay: 600ms;
}

.insights-item.is-list.is-loading >>> .item:nth-child(3),
.insights-item.is-cards.is-loading >>> .card:nth-child(3) {
	animation-delay: 900ms;
}

.insights-item.is-list.is-loading >>> .item:nth-child(4),
.insights-item.is-cards.is-loading >>> .card:nth-child(4) {
	animation-delay: 1200ms;
}

.insights-item.is-list.is-loading >>> .item:nth-child(5),
.insights-item.is-cards.is-loading >>> .card:nth-child(5) {
	animation-delay: 1500ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(1) {
	animation-delay: 150ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(2) {
	animation-delay: 300ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(3) {
	animation-delay: 450ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(4) {
	animation-delay: 600ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(5) {
	animation-delay: 750ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(6) {
	animation-delay: 900ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(7) {
	animation-delay: 1150ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(8) {
	animation-delay: 1400ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(9) {
	animation-delay: 1550ms;
}

.insights-item.is-loading >>> .accordian-segment:nth-child(10) {
	animation-delay: 1700ms;
}

.insights-item-content-card-empty {
	border-top: 1px solid #ddd;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 64px;
	color: #eee;
}

.insights-item.is-list .insights-item-content .insights-item {
	border-radius: 0px;
	border: 0px;
	padding: 10px 20px;
}

.insights-item-loading {
	color: #ffc563;
	font-size: 32px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

}

</style>