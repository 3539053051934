<template>

<core-panel>

	<template v-slot:head>

		<h1>Games</h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" />.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :playsAsSubtext="true" :item="item" v-bind:key="index" :isUserRating="isUser" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'games',
			games: []
		}

	},

	methods: {

		response: function(json) {

			this.games = json.games

		}

	}

}

</script>