<template>

<div class="colourpicker" v-bind:class="{'is-disabled': disabled}">

	<div v-if="title" class="colourpicker-title"><span>{{ title }}</span></div>

	<div class="colourpicker-defaults">

		<div class="colourpicker-defaults-item" v-for="(color, index) in defaults" :key="index" :style="{backgroundColor: color}" :class="{'is-selected': selected === color}" v-on:click="onColorClick(color)">

			<i class="fa fa-check" v-if="selected === color"></i>

		</div>

	</div>

</div>

</template>

<script>

import InputValidation from 'core/mixin/form/input/Validation.js'

export default {

	mixins: [InputValidation],

	props: [
		'title',
		'name',
		'value',
		'disabled',
		'error',
		'validation'
	],

	data: function() {

		return {
			selected: false,
			defaults: [
			
				'#134677',
				'#3974ad',
				'#4986c1',
				'#78aadc',
			
				'#136712',
				'#239022',
				'#37a536',
				'#65ca63',
			
				'#802314',
				'#a53220',
				'#b74533',
				'#c76859',
			
				'#6c20a5',
				'#9c56d0',
				'#d68532',
				'#c1986e',
			
				'#000000',
				'#333333',
				'#666666',
				'#999999'

			]
		}

	},

	created: function() {

		this.selected = (this.value) ? this.value : '#134677'

	},

	methods: {

		onColorClick: function(value) {

			this.selected = value
			this.$emit('input', value)

		},

		validate: function(rules) {

			if (rules.exists) {

				if (this.value === false) {

					this.feedback.push('required')

				}

			}

		}

	}

}

</script>

<style scoped>

.colourpicker {
	margin-bottom: 15px;
	overflow: hidden;
}

.colourpicker.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.colourpicker-title {
	color: #fff;
	font-size: 16px;
	height: 8px;
	font-weight: 500;
	margin-bottom: 22px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.colourpicker-title span {
	background-color: #2b5870;
	min-width: 80px;
	padding-right: 15px;
	display: inline-block;
}

.colourpicker-defaults {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.colourpicker-defaults-item {
	width: calc(25% - 4px);
	height: 32px;
	line-height: 32px;
	margin: 0px 5px 5px 0px;
	border-radius: 4px;
	text-align: center;
	font-size: 14px;
	cursor: pointer;
	color: #fff;
	font-weight: 400px;
}

.colourpicker-defaults-item:nth-child(4n) {
	margin-right: 0px;
}

</style>
