<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>{{ description}}</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :user="!isUser" :subtext="subtext(item.user, item.total)" :item="item" v-bind:key="index" :dark="true">

			<core-progress :percent="item.ratio" :dark="true" />

		</app-game>

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'games',
			params: [
				'compareType'
			],
			games: []
		}

	},

	computed: {

		title: function() {

			return (this.data.compareType === 'most') ? 'Highest win %' : 'Lowest win %'

		},

		description: function() {

			return (this.data.compareType === 'most') ? 'Highest win percentages for a single game.' : 'Lowest win percentages for a single game.'

		}

	},

	methods: {

		response: function(json) {

			this.games = json.games

		},

		subtext: function(user, total) {

			return ((!this.isUser) ? this.$store.getters['data/users'][user].name + ', ' : '') + total + ' play' + ((total !== 1) ? 's' : '')

		}

	}

}

</script>