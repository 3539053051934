<template>

<div class="panel" :class="{'is-session': $route.meta.isSession}" v-bind:style="{'marginLeft': window.menuIndentContent}">

    <com-context v-if="!$route.meta.isSession" :is-minimised="window.menuMinimised" />
    <com-session v-if="$route.meta.isSession" :is-minimised="window.menuMinimised" />

    <com-menu :is-minimised="window.menuMinimised" />

    <div class="links">

		<div class="links-item"><span v-on:click="onPageClick('appAbout', $event)">about</span></div>
		<div class="links-item"><span v-on:click="onPageClick('appPrivacy', $event)">privacy</span></div>
		<div class="links-item"><span v-on:click="onPageClick('appTerms', $event)">terms</span></div>
		<div class="links-item is-bgg"><small>raw data from</small> <a target="_blank" href="https://www.boardgamegeek.com">boardgamegeek.com</a></div>

	</div>

</div>

</template>

<script>

import comContext from './panel/Context'
import comSession from './panel/Session'
import comMenu from './panel/Menu'

export default {

    components: {
        'com-context': comContext,
        'com-session': comSession,
        'com-menu': comMenu
    },

	methods: {

		onPageClick: function(name, e) {

			e.stopPropagation()

			this.$pubsub.$emit('open.modal', name)

		}

	}

}

</script>

<style scoped>

.panel {
    background-color: #2b5870;
    position: fixed;
    top: 64px;
    bottom: 0px;
    left: 0px;
    width: 320px;
    z-index: 10000;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
	transition: margin-left 100ms linear;
}

.is-smaller .panel {
	left: -320px;
}

.is-mobile .panel {
	left: -100%;
	width: 100%;
	transition: none;
	top: 48px;
}

.panel.is-minimised {
    width: 48px;
}

.links {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
    padding: 15px;
}

.links-item {
	color: #ffffff;
	font-weight: 400;
	font-size: 12px;
	padding: 0px 5px;
	flex-shrink: 0;
}

.links-item.is-bgg {
	text-align: right;
	flex-grow: 1;
	padding-right: 24px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAmCAMAAADtLHsqAAAAhFBMVEUAAAD/UQD/UQD/UAD/VwD/UQD/UQD/UQD/UQD/UQD/UAD/WAD/UQD/UQD/UAD/UQD/UgD/UAD/UgD/UQD/UQD/UAD/UQD/UAD/UAD/UQD/UAD/UQD/UQD/UAD/UQD/UQD/UAD/UAD/UAD/UAD/UAD/TwD/RwD/UQD/UQD/UQD/UQD/UQDHSOVYAAAAK3RSTlMAj+5vBcP6rUMoFAjnyYJeLyIb+eTi2M+5o6KblIp+d2RcVU40LQz36dWxGXtUPwAAAN9JREFUOMt1k9cWgjAQRBNARATEjvRu2f//P5MAShnu49wTmF0IwxxTHeZ29ibztMzbQ2AS0WMhrFAjRTPNd8mVeoLJC3SX/li/3Cg/ZxrhDsLnDk3Ry2grDuh3QkhlEoSLx12xktvYYFUJFWLlC+Vh1QqVQ6OptUF1V58Gj6WWtDKW5ILHEhw1pBJhGocgMWM3WsFinDAXg+0Ik66u92kLVUClfrY9LF9LBYu4ysAihRSwiGP06rBQnoxRkfE1iWcqkiEocsu6M/MiL14P2aTIeVvtZ6IrsslthvC4v8i+Ki9rOlCl3nQAAAAASUVORK5CYII=');
	background-position: 100% 50%;
	background-repeat: no-repeat;
	background-size: auto 24px;
}

.links-item.is-bgg small {
	opacity: 0.75;
	display: block;
}

.links-item span,
.links-item a {
	cursor: pointer;
}

.links-item span:hover,
.links-item a:hover {
	text-decoration: underline;
}

</style>
