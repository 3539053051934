<template>

<core-list-expand :id="item.game.id" :game="item.game.id" :name="item.game.name" v-on:tab="onTabClick" v-on:close="onCloseClick" :selected="current">

	<template v-slot:tabs>

		<core-list-expand-tab value="about" text="About" v-on:click="onTabClick('about')" :selected="isSelected('about')" />
		<core-list-expand-tab value="contains" text="Contains" :count="contains" v-on:click="onTabClick('contains')" :selected="isSelected('contains')" v-if="contains" />
		<core-list-expand-tab value="expansions" text="Expansions" :count="expansions" :subcount="missing" v-on:click="onTabClick('expansions')" :selected="isSelected('expansions')" v-if="expansions || missing" />
		<core-list-expand-tab value="owners" :text="ownersText" :count="owners" v-on:click="onTabClick('owners')" :selected="isSelected('owners')" v-if="isGroupOrBuddies" />
		<core-list-expand-tab value="ratings" text="Ratings" :count="ratings" v-on:click="onTabClick('ratings')" :selected="isSelected('ratings')" v-if="isGroupOrBuddies" />
		<core-list-expand-tab value="comments" text="Comments" :count="comments" v-on:click="onTabClick('comments')" :selected="isSelected('comments')" v-if="isGroupOrBuddies" />
		<core-list-expand-tab value="comments" text="Comment" v-on:click="onTabClick('comments')" :selected="isSelected('comments')" v-if="isUser && commented" />
		<core-list-expand-tab value="plays" text="Plays" :count="plays" v-on:click="onTabClick('plays')" :selected="isSelected('plays')" v-if="isGroupOrBuddies" />
		<core-list-expand-tab value="trade" text="Trades & Sales" :count="trades" v-on:click="onTabClick('trade')" :selected="isSelected('trade')" v-if="isGroup" />
		<core-list-expand-tab value="parts" text="Parts" :count="parts" v-on:click="onTabClick('parts')" :selected="isSelected('parts')" v-if="isGroupOrBuddies || parts" />

	</template>

	<template v-slot:sections>

		<app-expand-about :id="item.game.id" :version="item.game.version" :format="format.about" :selected="isSelected('about')" />
		<app-expand-expansions :id="item.game.id" :item="item" :format="format" :extra="missing" :selected="isSelected('expansions')" />
		<app-expand-contains :item="item" :format="format" :selected="isSelected('contains')" />
		<app-expand-owners :item="item" :format="format.owners" :count="owners" :selected="isSelected('owners')" />
		<app-expand-plays :item="item" :format="format.plays" :selected="isSelected('plays')" />
		<app-expand-ratings :item="item" :format="format.ratings" :selected="isSelected('ratings')" />
		<app-expand-comments :item="item" :format="format.comments" :selected="isSelected('comments')" />
		<app-expand-trade :item="item" :format="format.trade" :count="trades" :selected="isSelected('trade')" />
		<app-expand-parts :item="item" :format="format.parts" :selected="isSelected('parts')" />

	</template>

</core-list-expand>

</template>

<script>

import Expand from 'core/mixin/list/Expand.js'

export default {

	mixins: [Expand],

	data: function() {

		return {
			default: 'about'
		}

	},

	computed: {

		id: function() {

			return this.item.game.id

		},

		format: function() {

			return {
				plays: {
					context: this.$store.getters['filter/collection/active']['played.range']
				}
			}

		},

		plays: function() {

			return (this.format.plays.context) ? this.item.context.users.totalPlays : this.item.users.totalPlays

		},

		ratings: function() {

			return this.$_.keys(this.item.users.rated).length

		},

		comments: function() {

			return this.item.users.totalComments

		},

		contains: function() {
		
			return this.item.game.contains.length

		},

		expansions: function() {

			return this.item.nested.length

		},

		missing: function() {

			return this.item.game.expansions

		},

		parts: function() {

			return this.item.users.parts.length

		},

		trades: function() {

			var trades = 0

			this.$_.each(['fortrade', 'want', 'wanttobuy', 'wishlist'], function(status) {

				trades += this.item.users.status[status].length

			}.bind(this))

			return trades

		},

		commented: function() {

			return this.item.user.commented

		},

		statuses: function() {

			return this.$store.getters['filter/collection/status/active']

		},

		ownersText: function() {

			if (this.statuses.length > 1) {

				return 'Users'

			} else {

				return this.$CONSTANTS.GAME.STATUS.USERS[this.statuses[0]]

			}

		},

		owners: function() {

			var users = []

			this.$_.each(this.$CONSTANTS.GAME.STATUS.FIELD, function(field) {

				if (this.$_.contains(this.statuses, field)) {

					this.$_.each(this.item.users.status[field], function(id) {

						if (!this.$_.contains(users, id)) {

							users.push(id)

						}

					}.bind(this))

				}

			}.bind(this))

			return users.length

		}

	}

}

</script>