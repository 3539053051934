<template>

<div>

	<div class="head">

		<div class="head-title"><slot></slot></div>

	</div>

	<div class="head nopadding" v-if="game">

		<div class="head-game" :style="{backgroundImage: gameAvatar}"></div>

	</div>

</div>

</template>

<script>

export default {

	props: ['game'],

	computed: {

		gameAvatar: function() {

			return 'url(' + this.$util.thumbnail.game.generate(this.game, 'large') + ')'

		}

	}

}

</script>

<style scoped>

.head {
	z-index: 2;
	flex-shrink: 0;
	height: 88px;
	display: flex;
	align-items: center;
	padding: 20px;
    background-color: #19455c;
}

.is-smaller .head {
	height: 64px;
}

.is-mobile .head {
	height: 48px;
	padding: 0px 10px;
}

.head:not(.nopadding):after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #19455c transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    left: 17px;
    bottom: -10px;
}

.head.nopadding {
	padding: 0px;
	z-index: 1;
	height: auto;
	flex-direction: column;
}

.head-title {
	text-align: left;
	font-size: 20px;
	color: #fff;
	font-weight: 500;
	line-height: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.head.nopadding .head-title {
	padding: 10px 20px;
	width: 100%;
}

.is-mobile .head-title {
	padding-right: 64px;
	font-size: 16px;
}

.head-game {
	width: 100%;
	height: 160px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	flex-shrink: 0;
	border-bottom: 5px solid transparent;
}

</style>