<template>

<core-modal v-on:close="onClose" type="confirm">

	<template v-slot:head>

		Leave Group

	</template>

	<template v-slot:default>

		<p>Are you sure you wish to leave this group?</p>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading">Yes</core-button>
		<core-button theme="plain" v-on:click.native="onClose">No</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				loading: false
			}

		}

	},

	methods: {

		onConfirmClick: function() {

			this.is.loading = true

			this.$store.dispatch('api/groups/leave', {
				name: this.$route.params.name
			}).then(function() {

				window.location.reload()

			}, function() {

				this.is.loading = false

			}.bind(this)) 

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>