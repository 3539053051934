<template>

<simplebar ref="simplebar" force-visible>

	<slot></slot>

</simplebar>

</template>

<script>

import 'simplebar-core/dist/simplebar.css'
import simplebar from 'simplebar-vue'

export default {

	props: ['scrollendOffset', 'trackScroll', 'eventName'],

	components: {
		'simplebar': simplebar
	},

	created: function() {

		if (this.trackScroll) {

			this.$nextTick(function() {

				this.$refs.simplebar.scrollElement.addEventListener('scroll', this.onScroll.bind(this))

			}.bind(this))
		
		}

		if (this.eventName) {

			this.$pubsub.$on(this.eventName + '.scrolltop', this.onScrollTop.bind(this))

		}

	},

	beforeDestroy: function() {

		if (this.trackScroll) {

			this.$refs.simplebar.scrollElement.removeEventListener('scroll', this.onScroll.bind(this))

		}

		if (this.eventName) {

			this.$pubsub.$off(this.eventName + '.scrolltop', this.onScrollTop.bind(this))

		}

	},

	methods: {

		onScroll: function() {

			var contentHeight = this.$refs.simplebar.contentElement.offsetHeight
			var scrollHeight = this.$refs.simplebar.scrollElement.offsetHeight
			var scrollPositionY = this.$refs.simplebar.scrollElement.scrollTop
			var scrollPositionX = this.$refs.simplebar.scrollElement.scrollLeft

			this.$emit('scroll', {
				x: scrollPositionX,
				y: scrollPositionY
			})

			if (scrollPositionY + scrollHeight >= contentHeight - this.scrollendOffset) this.$emit('scrollend')

		},

		onScrollTop: function() {

			if (this.$refs.simplebar) {
			
				if (this.$refs.simplebar.scrollElement) this.$refs.simplebar.scrollElement.scrollTop = 0

			}

		}

	}

}

</script>

<style scoped>

</style>
