import store from 'app/store'
import pubsub from 'core/pubsub'
import CONSTANTS from 'app/constants'
import _ from 'underscore'

export default {

	clear: function(context, e) {

		e = e || {}

		var index = (e.override === undefined) ? context.getters['all'] : (_.isArray(e.override.item)) ? e.override.item : [e.override.item]
		var games = (e.override === undefined) ? context.getters['all/games'] : (_.isArray(e.override.game)) ? e.override.game : [e.override.game]

		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {

				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded'],
						type: 'both'
					}).then(function(json) {

						internalResolve(json)

					})

				} else {

					internalResolve({
						index: index,
						games: games
					})

				}

			}).then(function(data) {

				store.dispatch('api/tags/clear', {
					filter: context.getters['name'],
					items: data.index,
					games: data.games
				}).then(function(json) {

					store.commit('page/' + context.getters['name'] + '/tags/clear', {
						items: json.items,
						games: json.games
					})

					resolve()

				})

			})

		})

	},

	toggle: function(context, e) {

		e = e || {}
		e.action = e.action || 'toggle'

		var index = (e.override === undefined) ? context.getters[(e.tag.context === CONSTANTS.TAG.CONTEXT.GAME) ? 'all/games' : 'all'] : (_.isArray(e.override[(e.tag.context === CONSTANTS.TAG.CONTEXT.GAME) ? 'game' : 'item'])) ? e.override[(e.tag.context === CONSTANTS.TAG.CONTEXT.GAME) ? 'game' : 'item'] : [e.override[(e.tag.context === CONSTANTS.TAG.CONTEXT.GAME) ? 'game' : 'item']]

		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {

				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded'],
						type: (e.tag.context === CONSTANTS.TAG.CONTEXT.GAME) ? 'games' : 'results'
					}).then(function(json) {

						internalResolve(json.index)

					})

				} else {

					internalResolve(index)

				}

			}).then(function(index) {

				store.dispatch('api/tags/' + e.action, {
					tag: e.tag.id,
					index: index
				}).then(function(json) {

					store.commit('page/' + context.getters['name'] + '/tags/' + e.action, {
						tag: e.tag.id,
						index: json.index,
						context: e.tag.context
					})

					resolve()

				})

			})

		})

	},

	add: function(context, e) {

		e = e || {}
		e.action = 'add'

		return context.dispatch('tags/toggle', e)

	},

	remove: function(context, e) {

		e = e || {}
		e.action = 'remove'

		return context.dispatch('tags/toggle', e)

	},

	create: function(context, e) {

		e = e || {}

		var index = (e.override === undefined) ? context.getters['all'] : (_.isArray(e.override.item)) ? e.override.item : [e.override.item]
		var games = (e.override === undefined) ? context.getters['all/games'] : (_.isArray(e.override.game)) ? e.override.game : [e.override.game]

		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {

				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded'],
						type: 'both'
					}).then(function(json) {

						internalResolve(json)

					})

				} else {

					internalResolve({
						index: index,
						games: games
					})

				}

			}).then(function(data) {

				pubsub.$emit('open.modal', {
					id: 'tagCreate',
					filter: context.getters['name'],
					gameIndex: data.games,
					itemIndex: data.index
				})

				resolve()

			})

		})

	}

}