import Util from 'app/util'
import router from 'app/router'
import _ from 'underscore'

var defaultState = Util.filter.templates.lists()

export default {

	namespaced: true,

	state: {

		filter: defaultState,

		preset: 0

	},

	getters: {

		preset: function(state) {

			return state.preset

		},

		count: function(state, getters) {

			var total = 0

			_.each(getters['active'], function(count, key) {

				if (key.indexOf('layout') !== 0) {

					if (typeof count === 'boolean') {

						total += (count) ? 1 : 0 

					} else {

						total += parseInt(count)

					}
					
				}

			})

			return total

		},

		active: function(state) {

			return {
				'text': (state.filter.text.trim()) ? 1 : 0,
				'layout': state.filter.layout !== 0
			}

		},

		values: function(state) {

			return state.filter

		},

		text: function(state) {

			return state.filter.text

		},

		layout: function(state) {

			return state.filter.layout

		},

		sort: function(state) {

			return state.filter.sort

		},

		sortReverse: function(state) {

			return state.filter.sortReverse

		}

	},

	mutations: {

		set: function(state, data) {

			state.filter = Util.filter.autofill(data, 'lists')

		},

		preset: function(state, id) {

			state.preset = id

		},

		reset: function(state) {

			state.filter = Util.filter.autofill({}, 'lists')

			state.preset = 0

		},

		sort: function(state, id) {

			if (typeof id === 'object') {

				state.filter.sortReverse = id.reverse
				state.filter.sort = id.value

			} else {

				state.filter.sortReverse = (state.filter.sort === id) ? !state.filter.sortReverse : 0
				state.filter.sort = id

			}

		},

		text: function(state, text) {

			state.filter.text = text

		},

		layout: function(state, value) {

			state.filter.layout = parseInt(value)

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params.text) context.state.filter.text = params.text

					if (params.layout) context.state.filter.layout = parseInt(params.layout)
						
					if (params.sort) context.state.filter.sort = params.sort

					if (params.sortReverse) context.state.filter.sortReverse = parseInt(params.sortReverse) === 1

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}

			if (context.state.filter.text) urlParams.text = context.state.filter.text

			if (context.state.filter.layout) urlParams.layout = context.state.filter.layout
					
			if (context.state.filter.sort !== 'name') urlParams['sort'] = context.state.filter.sort 

			if (context.state.filter.sortReverse) urlParams['sortReverse'] = 1

			router.push({name: 'Lists', query: urlParams}).catch(function() {})

		}

	}

}