<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>There are <b>{{ games.length }}</b> games. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="{id: item.id}" v-bind:key="index" :count="item.count" :dark="true" />
	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'rarity',
			params: [
				'filter'
			],
			games: []
		}

	},

	computed: {

		title: function() {

			return (this.data.filter.rarity === 'most') ? 'Least owned' : 'Most owned'

		}

	},

	methods: {

		response: function(json) {

			this.games = json.rarity

		}

	}

}

</script>