<template>

<core-card size="3" :item="item" :hasActions="true">

	<core-card-head type="game" :id="item.game.id" size="large" class="card-head" :data-band="ratingBand">

		<app-avatar-include :id="item.game.id" filter="ratings" :card="true" />

		<core-rating :value="item.rating.rating" class="card-head-value" v-if="isUser" />

		<core-rating :value="item.user.rating_avg" class="card-head-user-value" v-if="!isUser && hasMe && item.user.rating_avg !== '-'" />

		<core-rating :value="item.group.rating_avg" :ratings="item.users.rated" :count="item.group.rating_count" class="card-head-value" v-if="!isUser" :canClick="true" v-on:more="onViewAllClick" />

	</core-card-head>

	<core-card-body class="card-body" :grow="true" :padded="true">

		<div class="card-body-title">

			<core-gamename :game="item.game" class="card-body-title-text" :id="item.id" />

			<app-item-change :date="item.rating.date" :revisions="item.revisions" :showDate="isUser" :showChange="isUser" :datePrefix="datePrefix" />

		</div>

		<div class="card-body-ratings" v-if="!isUser">

			<app-item-recent v-for="rating in item.recent" :key="rating.user" :rating="rating" :game="item.game" :ready="ready" />

		</div>

		<div class="card-body-comment" v-if="isUser">

			<div class="card-body-comment-text" v-bind:class="{'is-empty': !item.comment}">

				<template v-if="item.comment">

					<div ref="comment" v-html="item.comment.text"></div>

					<div class="card-body-comment-text-fade" v-if="longComment"></div>

				</template>

			</div>

		</div>

		<app-tags :item="item" class="card-body-tags" />

	</core-card-body>

	<core-card-buttons v-if="item.group.rating_count > 3 || longComment">

		<core-card-buttons-button v-on:click.native="onViewAllClick($event)" v-if="!isUser && item.group.rating_count > 3" :count="item.group.rating_count">View all ratings</core-card-buttons-button>

		<core-card-buttons-button v-on:click.native="onReadMoreClick($event)" v-if="longComment && isUser">Read full comment</core-card-buttons-button>

	</core-card-buttons>

	<core-card-foot>

		<core-card-foot-item v-if="isUser">

			<div class="card-stats-item-history-wrapper">

				<app-item-history :history="history" class="card-stats-item-history" />

			</div>

			<core-card-foot-item-label>history</core-card-foot-item-label>

		</core-card-foot-item>

		<core-card-foot-item v-if="!isUser">

			<div class="card-stats-item-history-wrapper">

				<app-item-history :history="history" :isTrend="true" class="card-stats-item-history" />

			</div>

			<core-card-foot-item-label>trend</core-card-foot-item-label>

		</core-card-foot-item>

		<core-card-foot-stat :value="item.users.totalPlays" text="play" :plural="true" />

		<core-card-foot-item v-if="!isUser">

			<core-divisive :score="item.users.divisiveness" :count="item.users.totalRatings" />

			<core-card-foot-item-label>divisiveness</core-card-foot-item-label>

		</core-card-foot-item>

		<core-card-foot-date :value="playDate" text="last play" v-if="isUser" />

		<core-card-foot-item>

			<app-item-disparity :value="item.rating.rating" :average="item.game.rating_avg" class="card-stats-item-disparity" v-if="isUser" />
			<app-item-disparity :value="item.group.rating_avg" :average="item.game.rating_avg" class="card-stats-item-disparity" v-if="!isUser" />

			<core-card-foot-item-label>vs BGG</core-card-foot-item-label>

		</core-card-foot-item>

	</core-card-foot>

</core-card>

</template>

<script>

import itemHistory from './item/History'
import itemDisparity from './item/Disparity'
import itemChange from './item/Change'
import itemRecent from './item/Recent'

export default {

	props: ['item', 'ready'],

	components: {
		'app-item-history': itemHistory,
		'app-item-disparity': itemDisparity,
		'app-item-change': itemChange,
		'app-item-recent': itemRecent
	},

	data: function() {

		return {

			longComment: false

		}

	},

	watch: {

		ready: function(n) {

			if (n) this.checkComment()

		},

		window: {

			handler: function(n, o) {

				if (n.width !== o.width) {

					this.checkComment()

				}

			},

			deep: true

		}

	},

	mounted: function() {

		this.checkComment()

	},

	computed: {

		history: function() {

			if (this.isUser && this.item.revisions) {

				return (this.item.revisions) ? this.item.revisions.history : []

			} else {

				return this.item.trend || []

			}

		},

		datePrefix: function() {

			return (this.isUser || !this.item.rating.date) ? '' : 'Most recently rated on '

		},

		ratingBand: function() {

			return (this.isUser) ? Math.floor(this.item.rating.rating) : Math.floor(this.item.group.rating_avg)

		},

		playDate: function() {

			return this.item.user.play_last

		},

		ratingDate: function() {

			return (this.item.rating.date) ? this.$moment.unix(this.item.rating.date).utc().format(this.$util.date.format('Do', 'MMMM', 'YYYY')) : 'Initial sync'

		},

		commentDate: function() {

			return this.$moment.unix(this.item.comment.date).utc().format(this.$util.date.format('Do', 'MMMM', 'YYYY'))

		},

		gameAvatar: function() {

			return 'url(' + this.$util.thumbnail.game.generate(this.item.game.id, 'large') + ')'

		}

	},

	methods: {

		checkComment: function() {

			if (this.item.comment) {

				this.$nextTick(function() {
					if (this.$refs.comment) this.longComment = this.$refs.comment.offsetHeight > 90
				}.bind(this))

			}

		},

		onReadMoreClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('panel.open', {
				type: 'ratingsComment',
				game: this.item.game,
				rating: this.item.rating,
				comment: this.item.comment
			})
		},

		onViewAllClick: function(e) {

			if (e) e.stopPropagation()

			this.$pubsub.$emit('expand.open', {
				id: this.item.id,
				section: 'ratings'
			})

		}

	}

}

</script>

<style scoped>

.card-head {
	border-bottom: 5px solid transparent;
}

.card-head[data-band="10"] { border-color: #249563; }
.card-head[data-band="9"] { border-color: #249563; }
.card-head[data-band="8"] { border-color: #2FC482; }
.card-head[data-band="7"] { border-color: #1d8acd; }
.card-head[data-band="6"] { border-color: #5369a2; }
.card-head[data-band="5"] { border-color: #5369a2; }
.card-head[data-band="4"] { border-color: #df4751; }
.card-head[data-band="3"] { border-color: #df4751; }
.card-head[data-band="2"] { border-color: #db303b; }
.card-head[data-band="1"] { border-color: #db303b; }
.card-head[data-band="0"] { border-color: #db303b; }

.card-head-value {
	position: absolute;
	right: 0px;
	bottom: -5px;
	width: 64px;
	height: 64px;
	line-height: 64px;
	font-size: 24px!important;
}

.card-head-value >>> .rating-count {
	height: 16px;
	line-height: 16px;
	min-width: 16px;
	font-size: 10px;
}

.card-head-user-value {
	position: absolute;
	right: 65px;
	bottom: 0px;
	width: 64px;
	height: 59px;
	line-height: 64px;
	font-size: 24px!important;
}

.card-body {
	padding: 10px;
}

.card-body-title {
	margin-bottom: 10px;
	margin-top: 5px;
}

.card-body-title-wrapper {
	display: flex;
	flex-direction: row;
	width: calc(100% + 5px);
}

.card-body-title small {
	font-size: 12px;
	font-weight: 400;
	color: #666;
	display: block;
}

.card-body-title-text {
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
	color: #333;
	flex-grow: 1;
	flex-basis: 0;
	cursor: pointer;
}

.is-desktop .card-body-title-text:hover {
	text-decoration: underline;
}

.is-mobile .card-body-title-text {
	font-size: 14px;
}	

.card-body-tags {
	justify-content: flex-end;
	margin-top: 10px;
}

.card-body-comment-text {
	font-size: 14px;
	color: #666;
	line-height: 18px;
	max-height: 90px;
	overflow: hidden;
}

.is-mobile .card-body-comment-text {
	font-size: 14px;
	line-height: 16px;
}	

.card-body-comment-text.is-empty {
	opacity: 0.6;
}

.card-body-comment-text.is-empty:before {
	content: 'No comment written.'
}

.card-body-comment-text-fade {
	position: absolute; 
	bottom: 0; 
	left: 0;
	width: 100%; 
	text-align: center; 
	margin: 0; padding: 30px 0; 
	background-image: linear-gradient(to bottom, transparent, #fff);
}

.card.is-selected .card-body-comment-text-fade {
	background-image: linear-gradient(to bottom, transparent, #d4f2ff);
}

.card-body-comment-info {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
}

.card-body-comment-info-date {
	font-size: 11px;
	font-weight: 300;
	color: #c7c7c7;
	flex-grow: 1;
}

.card-body-comment-info-more {
	flex-grow: 0;
	font-size: 12px;
	color: #4881bb;
	font-weight: 400;
	cursor: pointer;
}

.is-desktop .card-body-comment-info-more:hover {
	text-decoration: underline;
}

.card-stats-item.is-up {
	color: #2FC482;
}

.card-stats-item.is-down {
	color: #df4751;
}

.card-stats-item-history-wrapper {
	height: 32px;
	width: 100%;
}

.card-stats-item-history {
	height: 32px;
}

.card-stats-item-disparity {
	line-height: 32px;
}

</style>