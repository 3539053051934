import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {
	
		notifications: []

	},

	getters: {
	
		notifications: function(state) {
		
			return state.notifications
		
		}
	
	},

	mutations: {
	
		notifications: function(state, value) {
		
			state.notifications = value
		
		}

	},

	actions: {

		syncs: function(context, params) {

			params = params || {}
			params.silent = 1

			return new Promise(function(resolve, reject) {

				API.get('app/syncs', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		about: function(context, params) {

			params = params || {}
			params.silent = 1

			return new Promise(function(resolve, reject) {

				API.get('app/about', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		terms: function(context, params) {

			params = params || {}
			params.silent = 1

			return new Promise(function(resolve, reject) {

				API.get('app/terms', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		privacy: function(context, params) {

			params = params || {}
			params.silent = 1

			return new Promise(function(resolve, reject) {

				API.get('app/privacy', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		help: function(context, params) {

			params = params || {}
			params.silent = 1

			return new Promise(function(resolve, reject) {

				API.get('app/help', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		devlog: function(context, params) {

			params = params || {}
			params.silent = 1

			return new Promise(function(resolve, reject) {

				API.get('app/devlog', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		notifications: function(context, params) {

			params = params || {}
			params.silent = 1

			return new Promise(function(resolve, reject) {

				API.get('app/notifications', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		}
		
	}

}