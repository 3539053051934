import Vue from 'vue'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: {}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		activity: function(state) {

			return state.data.activity || []

		},

		compare: function(state) {

			return state.data.compare || {
				group: {
					most: [],
					least: []
				},
				user: {
					most: [],
					least: []
				}
			}

		},

		divisive: function(state) {

			return state.data.divisive || {
				most: [],
				least: []
			}

		}

	},

	mutations: {

		reset: function(state) {

			state.data = {}

		},

		set: function(state, data) {

			_.each(data, function(value, key) {

				Vue.set(state.data, key, value)

			})

		}

	},

	actions: {}

}