<template>

<core-config-section :locked="!loggedin">

	<h2>h-index Expansions</h2>

	<p>Your h-index counts logged plays of expansions towards the score, you can toggle whether to exclude expansions or not. Your h-index score will update automatically.</p>

	<div class="inputs">

		<core-filter-input-toggle text="Exclude expansions" on="Yes" off="No" filter="config/insights/hindex/expansions" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>