<template>

<div class="wrapper">

	<com-head v-if="!$route.meta.isStandalone" />
	<com-panel v-if="!$route.meta.isStandalone" />

	<com-content>

		<slot></slot>

	</com-content>

</div>

</template>

<script>

import comHead from './../page/Head'
import comPanel from './../page/Panel'
import comContent from './../page/Content'

export default {
	
	props: ['centralise', 'loading', 'background'],
	
	components: {
		'com-head': comHead,
		'com-panel': comPanel,
		'com-content': comContent
	}

}

</script>

<style scoped>

</style>
