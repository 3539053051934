<template>

<core-list-cell type="stat" :format="format" class="count" :condensed="condensed" v-bind:class="{'is-summary': summary, 'is-condensed': condensed}">
					
	<div class="count-official" v-bind:class="{'with-community': item.game.players_community.recommended.length > 1}" v-if="!condensed">
		<i class="fa fa-users"></i>
		<span v-show="item.game.players_min === 0">-</span>
		<span v-show="item.game.players_min > 0">{{ item.game.players_min }}<template v-if="expansionMin"> ({{ expansionMin }})</template></span>
		<span v-show="item.game.players_min !== item.game.players_max && item.game.players_max > 0"> - {{ item.game.players_max }}<template v-if="expansionMax"> ({{ expansionMax }})</template></span>
	</div>

	<div class="count-community" :class="{'is-expandable': is.expandable, 'is-expanded': is.expanded}" v-show="item.game.players_community.recommended.length > 1 || summary || condensed">

		<div class="count-community-inner is-preview" v-if="is.expandable">

			<template v-for="(range, key) in community">

				<div v-if="key < 2" v-tooltip.bottom="tooltip(range)" v-bind:key="key" v-bind:class="{'official': range.type === 'official', 'best': range.type === 'best', 'recommended': range.type === 'recommended', 'expansion': range.type === 'expansion'}">

					{{ range.min }}<span v-if="range.max !== range.min && range.max > 0"> - {{ range.max }}</span>

				</div>

			</template>

		</div>

		<div class="count-community-inner is-full" ref="counts" v-click-outside="onOutsideClick">

			<div v-for="(range, key) in community" v-tooltip.bottom="tooltip(range)" v-bind:key="key" v-bind:class="{'official': range.type === 'official', 'best': range.type === 'best', 'recommended': range.type === 'recommended', 'expansion': range.type === 'expansion'}">

				{{ range.min }}<span v-if="range.max !== range.min && range.max > 0"> - {{ range.max }}</span>

			</div>

		</div>

		<div class="count-community-expand" v-tooltip="'Click to view full details'" v-if="is.expandable" v-on:click.stop="is.expanded = !is.expanded"><i class="fa fa-caret-down" /></div>

	</div>

</core-list-cell>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: ['item', 'summary', 'format', 'condensed'],

	data: function() {

		return {
			is: {
				expandable: false,
				expanded: false			
			}
		}

	},

	computed: {

		expansionMin: function() {

			var expansionMin = false

			this.$_.each(this.item.nested, function(id) {

				if (this.$store.getters['page/collection/nested/associated/collection'][id].game.players_min < this.item.game.players_min) expansionMin = this.$store.getters['page/collection/nested/associated/collection'][id].game.players_min

			}.bind(this))

			return expansionMin

		},

		expansionMax: function() {

			var expansionMax = false

			this.$_.each(this.item.nested, function(id) {

				if (this.$store.getters['page/collection/nested/associated/collection'][id].game.players_max > this.item.game.players_max) expansionMax = this.$store.getters['page/collection/nested/associated/collection'][id].game.players_max

			}.bind(this))

			return expansionMax

		},

		absoluteMin: function() {

			var min = this.item.game.players_min

			this.$_.each(this.item.game.players_community.best, function(count) {

				if (count < min) min = count

			})

			this.$_.each(this.item.game.players_community.recommended, function(count) {

				if (count < min) min = count

			})

			return min

		},

		absoluteMax: function() {

			var max = this.item.game.players_max

			this.$_.each(this.item.game.players_community.best, function(count) {

				if (count > max) max = count

			})

			this.$_.each(this.item.game.players_community.recommended, function(count) {

				if (count > max) max = count

			})

			return max

		},

		community: function() {

			var community = []
			var current = false

			var min = (this.expansionMin) ? this.expansionMin : this.absoluteMin
			var max = (this.expansionMax) ? this.expansionMax : this.absoluteMax

			for (var i = min; i <= max; i++) {

				if (i < this.absoluteMin || i > this.absoluteMax) {

					if (current === 'expansion') {

						community[community.length - 1].max = i

					} else {

						community.push({
							type: 'expansion',
							min: i,
							max: i
						})

						current = 'expansion'

					}

				} else {

					if (this.item.game.players_community.best.includes(i)) {

						if (current === 'best') {

							community[community.length - 1].max = i

						} else {

							community.push({
								type: 'best',
								min: i,
								max: i
							})

							current = 'best'

						}

					} else if (this.item.game.players_community.recommended.includes(i)) {

						if (current === 'recommended') {

							community[community.length - 1].max = i

						} else {

							community.push({
								type: 'recommended',
								min: i,
								max: i
							})

							current = 'recommended'

						}

					} else if (this.summary || this.condensed) {

						if (current === 'official') {

							community[community.length - 1].max = i

						} else {

							community.push({
								type: 'official',
								min: i,
								max: i
							})

							current = 'official'

						}

					}

				}

			}

			return community

		}

	},

	methods: {

		onOutsideClick: function() {

			this.is.expanded = false

		},

		tooltip: function(range) {

			var tooltip = []

			if (range.type === 'official') tooltip.push('Official')
			if (range.type === 'best') tooltip.push('Best')
			if (range.type === 'recommended') tooltip.push('Recommended')
			if (range.type === 'expansion') tooltip.push('Expansion')

			var total, best, rec, notrec

			for (var i = range.min; i <= range.max; i++) {

				if (this.item.game.players_community.votes[i]) {

					total = this.item.game.players_community.votes[i].b + this.item.game.players_community.votes[i].r + this.item.game.players_community.votes[i].n

					best = ((100 / total) * this.item.game.players_community.votes[i].b).toFixed(1)
					rec = ((100 / total) * this.item.game.players_community.votes[i].r).toFixed(1)
					notrec = ((100 / total) * this.item.game.players_community.votes[i].n).toFixed(1)

					tooltip.push('<div class="counts"><div>' + i.toString() + ' player' + ((i === 1) ? '' : 's') + '</div><div>' + best + '%</div><div>' + rec + '%</div><div>' + notrec + '%</div></div>')
				
				}

			}

			return tooltip.join('')

		}

	},

	created: function() {

		this.$nextTick(function() {

			if (this.$refs.counts.clientHeight > 17) {

				this.is.expandable = true

			}

		}.bind(this))

	}

}

</script>

<style scoped>

.count >>> .cell-subwrapper {
	flex-direction: column!important;
}

.is-mobile .count >>> .cell-subwrapper {
	flex-direction: row!important;
}

.count.is-condensed >>> .cell-subwrapper {
	flex-direction: row!important;
}

.card .count {
	justify-content: center;
	width: 100%!important;
}

@media only screen and (max-width: 767px) {

	.item .count:not(.is-summary) {
		display: none!important;
	}

	.item .count {
		width: auto;
		display: inline-flex;
		flex-grow: 1;
		flex-shrink: 0;
		flex-direction: row!important;
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

}

.count-official {
	font-size: 14px;
	text-align: center;
	line-height: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 1119px) {

	.item .count-official {
		font-size: 12px;
	}

}

@media only screen and (max-width: 767px) {

	.item .count-official {
		height: 16px;
		line-height: 16px;
		padding: 0px;
		font-size: 10px;
		text-align: left;
		padding-left: 0px;
		padding-right: 4px;
	}

	.item .count-official span {
		display: none;
	}

}

.count-official .fa {
	display: none;
}

.count-official.with-community {
	padding: 8px 0px 0px 0px;
}

.count.is-condensed .count-official.with-community {
	padding: 0px;
}

@media only screen and (max-width: 767px) {

	.item .count-official .fa {
		display: inline-block;
		line-height: 14px;
		color: #666;
		padding-right: 0px;
	}

	.item .count-official.with-community {
		padding: 0px;
		padding-left: 0px;
	}

	.item .count-official {
		padding-right: 0px;
	}

	.item .fa {
		width: 48px;
		margin-right: 10px;
		text-align: right;
	}

}

.count-community {
	text-align: center;
	height: 16px;
	margin-top: 4px;
	font-size: 0;
	overflow: hidden;
}

.count-community.is-expandable {
	overflow: visible;
	width: 100%;
}

.count-community.is-expanded .count-community-inner.is-preview {
	opacity: 0.25;
}

.count-community.is-expandable .count-community-inner.is-full {
	display: none;
}

.count-community.is-expanded .count-community-inner.is-full {
	display: block;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 4px;
	width: 240px;
	left: 50%;
	top: 16px;
	transform: translateX(-50%);
	position: absolute;
	flex-wrap: wrap;
	display: flex;
	justify-content: center;
	z-index: 2;
}

.is-cards .count-community.is-expanded .count-community-inner.is-full {
	left: calc(100% - 16px);
	transform: none;
}

.count-community-expand {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 16px;
	font-size: 12px;
	height: 16px;
	line-height: 16px;
	color: #333;
	cursor: pointer;
}

.is-cards .count-community-expand {
	right: -4px;
}

.count.is-condensed .count-community {
	margin: 0px;
}

@media only screen and (max-width: 767px) {

	.item .count-community {
		margin: 0px;
		text-align: left;
		width: auto;
		display: flex;
		flex-grow: 1;
		min-width: 48px;
	}

}

.count-community-inner div {
	vertical-align: middle;
	font-size: 10px;
	position: relative;
	color: #fff;
	height: 16px;
	min-width: 16px;
	line-height: 16px;
	text-align: center;
	margin-right: 1px;
	padding: 0px 3px;
	color: #eee;
	font-weight: 400;
	display: none;
	margin-bottom: 1px;
}

@media only screen and (max-width: 767px) {

	.item .count-community-inner div {
		font-size: 10px;
		height: 16px;
		min-width: 16px;
		line-height: 16px;
		margin-right: 1px;
	}

}

.count-community-inner div.official {
	color: #666;
	background-color: #eee;
	display: inline-block;
}

.count-community-inner div.recommended {
	color: #fff;
	background-color: #2FC482;
	display: inline-block;
}

.count-community-inner div.best {
	color: #fff;
	background-color: #249563;
	display: inline-block;
}

.count-community-inner div.expansion {
	color: rgb(145, 136, 136);
	background-color: rgba(51, 149, 181, 0.75);
	display: inline-block;
}

.count-community-inner div .tooltip {
	background-color: rgba(0, 0, 0, 1);
	font-size: 10px;
	color: #fff;
	padding: 2px 8px;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	top: 20px;
	display: none;
}

.is-desktop .count-community-inner div:hover .tooltip {
	display: block;
}

.count-community-inner div .tooltip:after {
	top: -8px;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: #000000;
	border-width: 4px;
	margin-left: -4px;
}

</style>
