<template>
	
<div v-if="statuses.length > 1 || tags.length || isExpansion || hasLocation || isWishlist || item.nested.length || item.game.contains.length" class="status">

	<div v-for="(label, index) in status" :key="index" v-tooltip="tooltip(label)" class="status-item" :class="{['is-' + label.type]: true, 'is-clickable': !isUser || label.type === 'wantparts' || label.type === 'hasparts' || label.type === 'nested' || label.type === 'contained'}" v-on:click="onStatusClick(label.type)" v-propagation>
		<div class="status-item-text">{{ label.text }}</div>
		<div class="status-item-count" v-if="(!isUser || label.type === 'nested' || label.type === 'contained' || label.type === 'wishlist') && label.count">{{ label.count }}</div>
	</div>

	<app-tags :item="item" />

</div>

</template>

<script>

export default {

	props: ['item', 'format', 'filter', 'hideExpansion'],

	computed: {

		isExpansion: function() {

			return (this.hideExpansion) ? false : this.item.game.type === this.$CONSTANTS.GAME.TYPE.EXPANSION

		},

		isWishlist: function() {

			return this.isUser && this.$_.contains(this.statuses, 'wishlist')

		},

		hasLocation: function() {
		
			return (this.item.collection) ? this.item.collection.location : false

		},

		tags: function() {

			var tags = []

			this.$_.each(this.$store.getters['data/tags/list'], function(tag) {

				if (this.$_.contains(this.item.tags, tag.id)) {

					tags.push(tag)

				}

			}.bind(this))

			return tags

		},

		statuses: function() {

			var applicable = []

			if (this.$store.getters['filter/config/' + this.filter + '/status']) {

				applicable = this.$CONSTANTS.GAME.STATUS.FIELD

				if (this.$store.getters['filter/' + this.filter + '/status/active'].length === 1 && this.$store.getters['filter/' + this.filter + '/status/active'][0] === 'own') {

					applicable = this.$CONSTANTS.GAME.STATUS.FIELD.slice(1)

				}

			} else {

				applicable = this.$_.filter(this.$store.getters['filter/' + this.filter + '/status/active'], function(name) {

					return this.$_.contains(this.$CONSTANTS.GAME.STATUS.FIELD, name)

				}.bind(this)) || []

			}

			return applicable

		},

		status: function() {

			var status = []

			if (this.hasLocation) {

				status.push({
					text: this.item.collection.location,
					type: 'location',
					tooltip: 'Inventory location'
				})
			
			}

			if (this.statuses.length > 1 || this.isWishlist) {

				this.$_.each(this.$CONSTANTS.GAME.STATUS.FIELD, function(field) {

					if (this.$_.contains(this.statuses, field)) {

						if (this.item.users.status[field].length) {

							if (this.statuses.length === 1 && this.isWishlist) {

								status.push({
									text: (this.item.user.wishlist.priority) ? this.$CONSTANTS.WISHLIST.LABEL[this.item.user.wishlist.priority] : 'Wishlist',
									type: field,
									count: 0
								})

							} else {

								status.push({
									text: this.$CONSTANTS.GAME.STATUS.NAME[field],
									type: field,
									count: (this.isUser) ? this.$CONSTANTS.WISHLIST.LABEL[this.item.user.wishlist.priority] : this.item.users.status[field].length
								})

							}

						}

					}

				}.bind(this))

			}

			if (this.isExpansion && this.format.labelExpansions) {

				status.push({
					text: 'Expansion',
					type: 'expansion',
					count: false
				})

			} 

			if (this.item.game.contains.length) {

				status.push({
					text: 'Contains',
					type: 'contained',
					count: this.item.game.contains.length
				})

			}

			if (this.item.nested.length) {

				var hasNonExpansion = false
				var hasExpansion = false

				this.$_.each(this.item.nested, function(id) {

					if (this.$store.getters['page/collection/associated/collection/all'][id].game.type === this.$CONSTANTS.GAME.TYPE.BASE) hasNonExpansion = true
					if (this.$store.getters['page/collection/associated/collection/all'][id].game.type === this.$CONSTANTS.GAME.TYPE.EXPANSION) hasExpansion = true

				}.bind(this))

				var text = []

				if (hasExpansion) text.push('Expansions')
				if (hasNonExpansion) text.push('Nested')

				status.push({
					text: text.join(' & '),
					type: 'nested',
					count: this.item.nested.length
				})

			}

			return status

		}

	},

	methods: {

		tooltip: function(label) {

			if (label.tooltip) return label.tooltip
			if (!this.isUser || label.type === 'wantparts' || label.type === 'hasparts') return 'Click to see details'
			if (label.type === 'nested') return 'Click to see nested games'
			if (label.type === 'contained') return 'Click to see contained games'
			if (label.type === 'wishlist' && this.isUser) {

				if (this.item.user.wishlist.text) return this.item.user.wishlist.text + '<small>Wishlist comment</small>'

			}

			return false

		},

		onStatusClick: function(type) {

			if (type !== 'expansion') {

				if (type === 'nested') {

					if (this.$store.getters['filter/config/' + this.filter + '/layout'] === this.$CONSTANTS.LAYOUT.COLLECTION.GRID) {

						this.$pubsub.$emit('expand.open', {
							id: this.item.game.id,
							section: 'expansions'
						})

					} else {

						this.$pubsub.$emit('nesting.open.' + this.item.id)

					}
			
				} else if (type === 'contained') {

					if (this.$store.getters['filter/config/' + this.filter + '/layout'] === this.$CONSTANTS.LAYOUT.COLLECTION.GRID) {

						this.$pubsub.$emit('expand.open', {
							id: this.item.game.id,
							section: 'contains'
						})

					} else {

						this.$pubsub.$emit('contained.open.' + this.item.id)

					}
			
				} else if (!this.isUser && type !== 'wantparts' && type !== 'hasparts') {

					this.$pubsub.$emit('expand.open', {
						id: this.item.game.id,
						section: 'owners'
					})

				} else if (type === 'wantparts' || type === 'hasparts') {

					this.$pubsub.$emit('expand.open', {
						id: this.item.game.id,
						section: 'parts'
					})
			
				} 

			}

		}

	}

}

</script>

<style scoped>

.status {
	display: flex;
	flex-direction: row;
	margin-top: 2px;
}

.status-item {
	font-size: 10px;
	color: #fff;
	margin-right: 4px;
	padding: 2px 4px;
	border-radius: 4px;
	line-height: 10px;
	cursor: pointer;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	cursor: initial;
}

.status-item.is-clickable {
	cursor: pointer;
}

.status-item-count {
	flex-grow: 0;
	margin-left: 4px;
	padding-left: 4px;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.status-item.is-own {
	background-color: rgba(4, 108, 143, 0.9);
}

.status-item.is-prevowned {
	background-color: rgba(4, 108, 143, 0.7);
}

.status-item.is-fortrade {
	background-color: rgba(4, 108, 143, 0.5);
}

.status-item.is-want {
	background-color: rgba(4, 143, 85, 0.9);
}

.status-item.is-wanttoplay {
	background-color: rgba(4, 143, 85, 0.7);
}

.status-item.is-wanttobuy {
	background-color: rgba(4, 143, 85, 0.5);
}

.status-item.is-wishlist {
	background-color: rgba(143, 36, 4, 0.7);
}

.status-item.is-preordered {
	background-color: rgba(143, 36, 4, 0.9);
}

.status-item.is-hasparts {
	background-color: rgba(4, 108, 143, 0.5);
}

.status-item.is-wantparts {
	background-color: rgba(4, 143, 85, 0.5);
}

.status-item.is-expansion {
	background-color: rgba(51, 149, 181, 0.75);
}

.status-item.is-nested {
	background-color: rgba(51, 149, 181, 0.75);
}

.status-item.is-contained {
	background-color: rgba(51, 149, 181, 0.75);
}

.status-item.is-location {
	background-color: #666;
}

</style>
