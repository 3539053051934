<template>

<com-item name="plays.weights" :size="size" :is-empty="!active" :loading="loading" title="Weights">

	<template v-slot:about>

		<p>See how {{ your }} plays break down in weight {{ periodText }}.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-segments :data="polar" v-if="isPolar" />
		<app-visualisation-bars :data="bars" v-if="!isPolar" />
		
	</template>

	<template v-slot:buttons>

		<com-toggle :options="weightOptions" :value="weightValue" v-on:change="onWeightChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {

		return {
			polar: {
				areas: [
					{title: 'Light', subtitle: '1.0 - 2.0'},
					{title: 'Light-Medium', subtitle: '2.0 - 3.0'},
					{title: 'Medium', subtitle: '3.0 - 4.0'},
					{title: 'Heavy', subtitle: '4.0 - 5.0'}
				],
				clickable: false,
				size: 16,
				shades: [
					'rgba(17, 105, 135, 0.2)',
					'rgba(17, 105, 135, 0.4)',
					'rgba(17, 105, 135, 0.6)',
					'rgba(17, 105, 135, 0.8)',
					'rgba(17, 105, 135, 1)'
				],
				segments: [],
				dataset: {
					data: []
				}
			},
			bars: {
				datasets: [
					{ type: 'bar', data: [], backgroundColor: '#4881bb', tooltip: '{{value}} play{{value.plural}} <small>Weight between {{x.tooltip}}</small>' }
				],
				axis: {
					x: {
						labels: [],
						long: true
					},
					y: {
						min: 10,
						points: 10
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		isPolar: function() {

			return this.$store.getters['filter/insights/plays/weight'] === 'polar'

		},

		weights: function() {

			return this.$store.getters['page/insights/plays/weights']

		},

		active: function() {

			return this.weights.length && !this.loading

		},

		weightOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.GRAPH.POLAR, text: 'Polar chart'})
			options.push({value: this.$CONSTANTS.INSIGHTS.GRAPH.BAR, text: 'Bar chart'})

			return options

		},

		weightValue: function() {

			return this.$store.getters['filter/insights/plays/weight']

		}

	},

	watch: {

		weights: function() {

			this.visualise()

		}

	},

	created: function() {

		var range

		for (var i=1; i <= 16; i++) {

			if (i == 16) {

				range = '{{value}} play{{value.plural}} ' + (((i - 1) * 0.25) + 1).toFixed(2) + ' and higher'

			} else {

				range = '{{value}} play{{value.plural}} between ' + (((i - 1) * 0.25) + 1).toFixed(2) + ' and ' + (((i - 1) * 0.25) + 1 + 0.24).toFixed(2)

			}

			this.polar.segments.push({
				label: range
			})

			range = (((i - 1) * 0.25) + 1).toFixed(2) 

			this.bars.axis.x.labels.push({
				label: range + ' - ' + (((i - 1) * 0.25) + 1.24).toFixed(2) ,
				tooltip: range + ' and ' + (((i - 1) * 0.25) + 1.24).toFixed(2) 
			})

		}

		this.visualise()

	},

	methods: {

		visualise: function() {

			this.polar.dataset.data = this.weights
			this.bars.datasets[0].data = this.weights

		},

		onWeightChange: function(value) {

			this.$store.commit('filter/insights/plays/weight', value)

		}

	}

}

</script>

<style scoped>

</style>