<template>

<core-modal v-on:close="onClose" type="confirm" icon="caret-left">

	<template v-slot:head>

		Delete Group

	</template>

	<template v-slot:default>

		<p>Are you sure you wish to delete this group?</p>

	</template>

	<template v-slot:foot>

		<core-button theme="red" v-on:click.native="onConfirmClick" :loading="is.loading">Yes</core-button>
		<core-button theme="blue" v-on:click.native="onClose">No</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				loading: false
			}

		}

	},

	methods: {

		onConfirmClick: function() {

			this.is.loading = true

			this.$store.dispatch('api/groups/delete', {
				name: this.$route.params.name
			}).then(function(json) {

				this.$store.commit('session/groups/remove', json.id)

				this.$store.commit('session/recent/remove', {
					slug: json.slug,
					type: this.$CONSTANTS.TYPE.GROUPS
				})

				this.$router.push({
					name: 'Collection',
					params: {
						name: this.$store.getters['session/user/slug'],
						type: this.$CONSTANTS.TYPE.USERS
					}
				}).catch(function(e) { console.log(e) })

				this.$emit('close')

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this)) 

		},

		onClose: function() {

			this.$emit('close')
			this.$pubsub.$emit('open.modal', 'groupEdit')

		}

	}

}

</script>

<style scoped>

</style>