import select from './service/select'

export default {

	namespaced: true,
	
	modules: {
		select: select
	},

	getters: {

		select: function(state, getters) {

			return getters['select/selected']

		}

	}

}