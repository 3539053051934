<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>{{ description}}</p>

	</template>

	<template v-slot:body>

		<app-match v-for="(item, index) in compare" :item="item" v-bind:key="index" :dark="true" />

	</template>

	<template v-slot:empty>

		No matches found, either {{ you }} do not {{ have }} any {{ type }} matches or {{ you }} {{ have }} not rated enough of the same games as other members in the group.

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'compare',
			params: [
				'compareType',
				'user'
			],
			compare: [],
			username: ''
		}

	},

	computed: {

		title: function() {

			if (this.data.user) {

				if (this.data.user === this.$store.getters['session/user/id']) {

					return (this.data.compareType === 'most') ? 'Your best matches' : 'Your worst matches'

				} else {

					return (this.data.compareType === 'most') ? this.username + '\'s best matches' : this.username + '\'s worst matches'

				}

			} else {

				return (this.data.compareType === 'most') ? 'Best matched' : 'Worst matched'

			}

		},

		description: function() {

			if (this.data.user) {

				if (this.data.user === this.$store.getters['session/user/id']) {

					return (this.data.compareType === 'most') ? 'Your best matches according to the similarity of game ratings.' : 'Your worst matches according to the similarity of game ratings.'

				} else {

					return (this.data.compareType === 'most') ? this.username + '\'s best matches according to the similarity of game ratings.' : this.username + '\'s worst matches according to the similarity of game ratings.'

				}

			} else {

				return (this.data.compareType === 'most') ? 'The members who are the best matched according to the similarity of their game ratings.' : 'The members who are the worst matched according to the similarity of their game ratings.'

			}

		},

		you: function() {

			return (this.$store.getters['session/user/id'] === this.data.user) ? 'you' : 'this member'

		},

		have: function() {

			return (this.$store.getters['session/user/id'] === this.data.user) ? 'have' : 'has'

		},

		type: function() {

			return (this.data.compareType === 'most') ? 'best' : 'worst'

		}

	},

	methods: {

		response: function(json) {

			this.compare = json.compare
			this.username = json.username || ''

		}

	}

}

</script>