<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ publisher }}</h1>

		<p>There are <b>{{ games.length }}</b> games in the collection by this publisher. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :isUserRating="isUser" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'publisher',
			games: [],
			publisher: ''
		}

	},

	methods: {

		response: function(json) {

			this.publisher = json.publisher
			this.games = json.games

		}

	}

}

</script>