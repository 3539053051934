<template>

<core-content-wrapper :loading="!is.ready">

	<core-filter :loading="is.fetching" :filter="insightName" v-on:refresh="onRefresh">

	</core-filter>

	<core-content-page v-if="is.reset && is.fetching !== 'all'">

		<core-content-page-inner v-show="is.ready" :is-grid="true">

			<com-compare :fetching="is.fetching" :your="your" :you="you" :size="3" type="most" context="group" />

			<com-compare :fetching="is.fetching" :your="your" :you="you" :size="3" type="least" context="group" />

			<com-size :fetching="is.fetching" :your="your" :you="you" :size="6" v-if="isGroup" />

			<com-divisive :fetching="is.fetching" :your="your" :you="you" :size="3" type="most" />

			<com-divisive :fetching="is.fetching" :your="your" :you="you" :size="3" type="least" />

		</core-content-page-inner>

	</core-content-page>

</core-content-wrapper>

</template>

<script>

import Section from './common/Section.js'

import comCompare from './members/Compare'
import comDivisive from './members/Divisive'
import comSize from './members/Size'

export default {

	mixins: [Section],

	components: {
		'com-compare': comCompare,
		'com-divisive': comDivisive,
		'com-size': comSize
	},

	data: function() {

		return {

			insightName: 'members',

			dynamicInsights: []

		}

	}

}

</script>