<template>
	
<core-list-head :fixed="fixed">

	<core-list-column type="name" :format="{filter: 'users', sort: 'name'}" class="column-name">Name</core-list-column>
	<core-list-column type="stat" :format="{filter: 'users', sort: 'collection'}" v-if="!window.is.mobile">Collection</core-list-column>
	<core-list-column type="stat" :format="{filter: 'users', sort: 'ratings'}" v-if="!window.is.mobile">Ratings</core-list-column>
	<core-list-column type="stat" :format="{filter: 'users', sort: 'plays'}" v-if="!window.is.mobile">Plays</core-list-column>
	<core-list-column type="stat" :format="{filter: 'users', sort: 'hindex'}" v-if="!window.is.mobile">h-index</core-list-column>
	<core-list-column-actions v-if="isGroup" />

</core-list-head>

</template>

<script>

export default {

	props: ['fixed'],

	computed: {

		hasActions: function() {

			return this.$store.getters['permissions/is/owner'] && this.$store.getters['context/is/group']

		}

	}

}

</script>

<style scoped>

.column-name {
	margin-left: 48px!important;
	padding-left: 8px!important;
}

.is-mobile .column-name {
	margin-left: 0px!important;
	padding-left: 0px!important;
}

</style>
