<template>

<div class="history">

    <div class="history-row is-groups">

        <div class="is-left">Recent Syncs</div>
        <div class="has-column">Timeline</div>
        <div class="has-column">Metrics</div>
        <div class="has-column">Changes</div>

    </div>

    <div class="history-row is-head">

        <div class="is-left">Date</div>
        <div>Trigger</div>
        <div>Type</div>
        <div class="has-column">Queued</div>
        <div>Scraped</div>
        <div>Processed</div>
        <div class="has-column">Scraping</div>
        <div>Processing</div>
        <div>Data</div>
        <div class="has-column">Collection</div>
        <div>Plays</div>
        <div>Ratings</div>

    </div>

    <div class="history-row" v-for="(item, index) in data.history" :key="index">

        <div class="is-left"><span class="history-failed" v-if="!item.success">Failed</span>{{ item.date | localDate('MMMM Do Y') }}</div>
        <div v-if="item.auto">Auto</div>
        <div v-if="!item.auto">User</div>
        <div>{{ item.type }}</div>
        <div class="has-column">{{ item.timeline.queued | localDate('h:mma') }}</div>
        <div>{{ item.timeline.scraped | localDate('h:mma') }}</div>
        <div>{{ item.timeline.processed | localDate('h:mma')  }}</div>
        <div class="has-column">{{ time(item.metrics.scraping) }}</div>
        <div>{{ time(item.metrics.processing) }}</div>
        <div>{{ size(item.metrics.size) }}</div>
        <div class="history-changes" v-for="(count, type) in item.changes" :key="type" :class="{'has-column': type === 'collection'}">
            <div class="is-empty" v-if="!convertCount(count.add) && !convertCount(count.remove) && !convertCount(count.change)">None</div>
            <div class="is-add" v-tooltip="'Added'" v-if="convertCount(count.add)">{{ convertCount(count.add) }}</div>
            <div class="is-remove" v-tooltip="'Removed'" v-if="convertCount(count.remove)">{{ convertCount(count.remove) }}</div>
            <div class="is-change" v-tooltip="'Updated'" v-if="convertCount(count.change)">{{ convertCount(count.change) }}</div>
        </div>

    </div>

</div>

</template>

<script>

export default {

    props: ['data'],
    
    methods: {

        convertCount: function(value) {
        
            if(this.$_.isObject(value)) {
            
                return this.$_.keys(value).length
            
            } else {
            
                return value
            
            }

        },

        size: function(value) {

            if(value === 0) return '-'

            var size = (value / 1024 / 1024)

            return size.toFixed((size > 99) ? 0 : 2) + 'mb'
        
        },

        time: function(value) {

            if(value === 0) return '-'

            var time = value

            if(time < 60) {
            
                return time.toFixed(2) + 's'
            
            } else if(time < 3600) {
            
                return (time / 60).toFixed(2) + 'm'
            
            } else {
            
                return (time / 3600).toFixed(2) + 'h'

            }
        
        }

    }

}

</script>

<style scoped>

.history {
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
    overflow-x: scroll;
    user-select: none;
}

.history::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 6px;
}

.history::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.history-row {
    width: 100%;
    min-width: 1280px;
    display: grid;
    font-size: 14px;
    padding: 0px 5px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    grid-template-columns: auto 80px 80px 100px 100px 100px 100px 100px 100px 100px 100px 100px;
}

.history-row:last-child {
    border-radius: 0px 0px 4px 4px;
}

.history-row.is-groups {
    grid-template-columns: auto 300px 300px 300px;
    border-bottom: 0px solid #fff;
    border-radius: 4px 4px 0px 0px;
    background-color: #4881bb;
}

.history-row.is-head {
    border-bottom: 0px solid #fff;
    background-color: #4881bb;
}

.history-row > div {
    padding: 0px 5px;
    min-height: 32px;
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.history-row.is-groups > div {
    font-weight: 500;
    text-align: center;
    min-height: 0px;
    padding-top: 10px;
    padding-bottom: 2px;
    font-size: 16px;
    color: #fff;
}

.history-row.is-head > div {
    font-weight: 400;
    text-align: center;
    color: #fff;
}

.history-row > div.has-column {
    border-left: 1px solid #ddd;
}
.history-row > div.is-left {
    justify-content: flex-start;
}

.history-changes > div:not(.is-empty) {
    border-radius: 4px;
    min-width: 20px;
    line-height: 20px;
    text-align: center;
    padding: 0px 4px;
    color: #fff;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
}

.history-changes > div.is-empty {
    color: #999;
    font-style: italic;
}

.history-changes > div.is-add {
    background-color: #2F9B68;
}

.history-changes > div.is-remove {
    background-color: #9A3D25;
}

.history-changes > div.is-change {
    background-color: #4881bb;
}

.history-failed {
    background-color: #9A3D25;
    color: #fff;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 4px;
}

</style>