<template>

<router-link :to="{ name: to, params: params }" class="dropdown-link" v-bind:class="{'is-disabled': disabled, 'is-active': active, 'is-text': textOnly === 'yes'}">

	<span><slot></slot></span>

	<small v-show="subtitle">({{ subtitle }})</small>

	<i v-show="icon" class="fa" v-bind:class="{[iconClass]: icon}"></i>
		
</router-link>

</template>

<script>

export default {

	props: [
		'active',
		'params',
		'disabled',
		'textOnly',
		'to',
		'subtitle',
		'icon'
	],

	computed: {

		iconClass: function() {

			return 'fa-' + this.icon

		}

	}
	
}

</script>

<style scoped>

.dropdown-link {
	white-space: nowrap;
	font-size: 14px;
	line-height: 16px;
	color: #fff;
	font-weight: 400;
	cursor: pointer;
	display: block;
	padding: 4px 15px;
	width: 100%;
}

.dropdown-link.is-disabled {
	pointer-events: none;
	color: rgba(255, 255, 255, 0.25);
}

@media only screen and (max-width: 767px) {

	.dropdown-link {
		padding: 6px 8px;
		color: rgba(255, 255, 255, 1);
	}

}

.dropdown-link > span {
	width: 100%;
}

.dropdown-link small {
	font-size: 10px;
	margin-left: 2px;
}

.is-desktop .dropdown-link:hover {
	background-color: #4a7888;
}

.dropdown-link.is-active {
	font-weight: 500;
	color: #fff;
}

</style>
