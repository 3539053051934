<template>

<com-item name="ratings.activity" :size="size" :loading="loading" title="Activity">

	<template v-slot:about>

		<p>{{ your | capitalise }} recent activity in rating and re-rating games over the past 12 months.</p>

		<p class="small" v-if="!isGroup">Tracking began from day {{ you }} were first imported into geekGroup.</p>

		<p class="small" v-if="isGroup">Tracking began from day group was created.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-bars :data="graph" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			graph: {
				datasets: [
					{ type: 'bar', data: [], yAxis: 'right', backgroundColor: '#3bce5a', tooltip: '{{value}} <small>New rating{{value.plural}}</small>' },
					{ type: 'bar', data: [], yAxis: 'right', backgroundColor: '#8FC0D4', tooltip: '{{value}} <small>Updated rating{{value.plural}}</small>' },
					{ type: 'point', data: [], yAxis: 'left', backgroundColor: '#217EA8', tooltip: '{{value}} {{change}} <small>Game{{value.plural}} rated</small>', lineColor: '#217EA8', shadeColor: '#d1f1ff' }
				],
				axis: {
					x: {
						labels: [],
						type: 'month'
					},
					y: {
						left: {
							type: 'scale',
							min: 100,
							points: 5
						},
						right: {
							type: 'scale',
							min: 10,
							points: 5
						}
					}
				}
			}
		}
	
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/activity']

		},

		active: function() {

			return this.$_.keys(this.data).length && !this.loading

		}

	},

	created: function() {
	
		this.visualise()	

	},

	watch: {

		activity: function() {

			this.visualise()

		}

	},

	methods: {

		visualise: function() {

			if (this.$_.keys(this.data).length) {

				this.graph.axis.x.labels = []
				this.graph.datasets[0].data = []
				this.graph.datasets[1].data = []
				this.graph.datasets[2].data = []

				this.$_.each(this.data, function(activity) {

					this.graph.axis.x.labels.push(activity.month)
					this.graph.datasets[0].data.push(activity.added)
					this.graph.datasets[1].data.push(activity.updated)
					this.graph.datasets[2].data.push(activity.total)

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

</style>