<template>

<div v-if="active">

	<com-collection v-if="isCollection || isList" :section="section" :item="item" v-on:close="onClose" />
	<com-plays v-if="isPlays" :section="section" :item="item" v-on:close="onClose" />
	<com-ratings v-if="isRatings" :section="section" :item="item" v-on:close="onClose" />

</div>

</template>

<script>

import comCollection from './collection/Default'
import comPlays from './plays/Default'
import comRatings from './ratings/Default'

export default {

	components: {
		'com-collection': comCollection,
		'com-plays': comPlays,
		'com-ratings': comRatings
	},

	data: function() {

		return {

			active: false,
			section: false,
			item: false

		}

	},

	computed: {

		isList: function() {

			return this.$_.contains(['List', 'Edition'], this.$route.name)

		},

		isCollection: function() {

			return this.$_.contains(['Collection'], this.$route.name)

		},

		isPlays: function() {

			return this.$_.contains(['Plays'], this.$route.name)

		},

		isRatings: function() {

			return this.$_.contains(['Ratings'], this.$route.name)

		}

	},

	created: function() {

		this.$pubsub.$on('expand.open', this.onOpen.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('expand.open', this.onOpen.bind(this))

	},

	methods: {

		onOpen: function(e) {

			this.active = (e.id !== this.active || e.section !== this.section) ? e.id : false
			this.section = e.section || false

			if (this.active) {

				if (this.isCollection) this.item = this.$store.getters['page/collection/associated/all'][this.active]
				if (this.isRatings) this.item = this.$store.getters['page/ratings/associated'][this.active]
				if (this.isPlays) this.item = this.$store.getters['page/plays/associated'][this.active]
				if (this.isList) this.item = this.$store.getters['page/list/associated'][this.active]

			}

		},

		onClose: function() {

			this.active = false

		}

	}

}

</script>