import 'core/assets/reset.css'
import 'core/assets/simplebar.css'
import 'core/assets/tooltip.css'
import 'core/assets/nouislider.css'
import 'core/assets/datepicker.css'
import 'core/assets/es6-promise.auto.js'

import Vue from 'vue'
import _ from 'underscore'
import moment from 'moment'

import VTooltip from 'v-tooltip'

import directivePropagation from 'core/directive/propagation'
import directiveFit from 'core/directive/fit'
import mixinWindow from 'core/mixin/Window'
import mixinInjection from 'core/mixin/Injection'

Vue.filter('round', function (value, decimals, force) {

	force = force || false
	value = parseFloat(value)

	return ((value % 1 !== 0 || force) && value !== undefined) ? value.toFixed(decimals) : value

})

Vue.filter('nth', function (value, template) {

	template = template || false

	var remainder = value % 10
	var nth = 'th'

	if (remainder === 1 && value !== 11) nth = 'st'
	if (remainder === 2 && value !== 12) nth = 'nd'
	if (remainder === 3 && value !== 13) nth = 'rd'

	if (template) nth = '[' + nth + ']'

	return value.toString() + nth

})

Vue.filter('hours', function (value) {

	return Math.ceil(value / 60)

})

Vue.filter('hashCount', function (value) {

	return _.keys(value).length

})

Vue.filter('abs', function (value) {

	return Math.abs(value)

})

Vue.filter('nl2br', function (value) {

	return (value) ? value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2') : value

})

Vue.filter('capitalise', function (value) {

	return value.charAt(0).toUpperCase() + value.slice(1)

})

Vue.filter('fromNow', function (value, plain) {

	return (value) ? moment.unix(value).local().fromNow(plain || false) : ''

})

Vue.filter('daysSince', function (value) {

	return (value) ? moment().utc().diff(moment.unix(value).utc(), 'days') : ''

})

Vue.filter('daysBetween', function (from, to) {

	return (from) ? Math.abs(moment.unix(to).utc().diff(moment.unix(from).utc(), 'days')) : ''

})

Vue.filter('formatDate', function (value, format) {

	return (value) ? moment.unix(value).utc().format(format) : ''

})

Vue.filter('localDate', function (value, format) {

	return (value) ? moment.unix(value).utc().local().format(format) : ''

})

Vue.filter('prettyNumber', function (value) {

	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

})

Vue.directive('propagation', directivePropagation)
Vue.directive('fit', directiveFit)

Vue.mixin(mixinWindow)
Vue.mixin(mixinInjection)

Vue.use(VTooltip)