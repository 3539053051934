<template>

<app-page centralise="yes">

	<div class="verify" v-bind:class="{'is-loading': is.verifying}">

		<div class="verify-content" v-show="is.verified">

			<h2>Hello {{username}}!</h2>

			<p>Enter your new password to complete the password reset, you will then be logged in automatically.</p>

			<core-form-input-password name="password1" v-on:validate="validate" placeholder="Enter password" v-model="inputs.password1" :error="is.error" :disabled="is.completing" :validation="validation.password1"></core-form-input-password>

			<core-form-input-password name="password2" v-on:validate="validate" placeholder="Confirm password" v-model="inputs.password2" :match="inputs.password1" :error="is.error" :disabled="is.completing" :validation="validation.password2"></core-form-input-password>

			<core-button v-on:click.native="onCompleteClick" :loading="is.completing" :disabled="!is.valid">Complete</core-button>

		</div>

		<div class="verify-content" v-show="!is.verified">

			<h2>Invalid key</h2>

			<p>Sorry, the reset key provided was not found or is no longer valid.</p>

			<p>If you clicked this link from a GeekMail then it's possible you have already reset your password for this account.</p>

			<core-button v-on:click.native="onCancelClick">Continue</core-button>

		</div>

	</div>

</app-page>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			username: '',

			is: {
				completing: false,
				verifying: true,
				verified: false,
				error: false
			},

			inputs: {
				password1: '',
				password2: ''
			},

			validation: {
				password1: { format: true },
				password2: { match: true }
			}

		}

	},
	
	created: function() {

		this.$store.dispatch('api/account/forgot', {
			key: this.$route.params.key
		}).then(function(data) {

			this.is.verified = true
			this.username = data.username
			this.is.verifying = false

		}.bind(this), function() {

			this.is.verifying = false

		}.bind(this))

	},

	methods: {

		onCompleteClick: function() {

			if (this.is.valid) {

				this.is.completing = true

				this.$store.dispatch('api/account/reset', {
					key: this.$route.params.key,
					password: this.inputs.password1
				}).then(function(json) {

					this.$router.push({
						name: 'Collection',
						params: {
							type: 'users',
							name: json.username
						}
					}).catch(function() { this.$router.go() })

				}.bind(this), function() {

					this.is.completing = false
					this.is.error = true

				}.bind(this))

			}

		},

		onCancelClick: function() {

			this.$router.push({ name: 'Home' }).catch(function(e) { console.log(e) })

		}

	}

}

</script>

<style scoped>

.verify {
	width: 424px;
	padding: 15px;
}

.verify.is-loading {
	background-image: url(~core/assets/load.gif);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.verify.is-loading .verify-content {
	visibility: hidden;
	pointer-events: none;
}

.verify h2 {
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 15px;
	color: #fff;
}

.verify p {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 15px;
	color: #fff;
}

.verify p.footnote {
	font-size: 12px;
}

</style>