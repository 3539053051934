import _ from 'underscore'
import Util from 'app/util'

export default {

	preset: function(state) {

		return state.preset

	},

	changed: function(state) {

		return Util.filter.differences(state.data, state.defaultValues, state.name)

	},

	'active/count': function(state, getters) {

		var total = 0

		var exclude = [
			'rated.date.user',
			'rated.date.group',
			'play.group',
			'played.range',
			'played.first',
			'played.last',
			'played.sometime'
		]

		_.each(getters['active'], function(count, key) {

			if (!_.contains(exclude, key)) {

				if (typeof count === 'boolean') {

					total += (count) ? 1 : 0 

				} else if (key.indexOf('.from') > 0 || key.indexOf('.to') > 0 || count > 100000000) {

					total += (count) ? 1 : 0 

				} else {

					total += (count) ? parseInt(count) : 0

				}
					
			}

		})

		return total

	},

	active: function(state) {

		return {
			'text': (state.data.text.trim()) ? 1 : 0,
			'comment.text': (state.data.comment.text.trim()) ? 1 : 0,
			'owners': state.data.owners.length,
			'users': state.data.users.length,
			'expansions': state.data.expansions !== 1,
			'unplayed': state.data.unplayed,
			'soloplayed': state.data.soloplayed,
			'ownersmin': state.data.ownersmin,
			'unrated': state.data.unrated,
			'commented': state.data.commented,
			'limit': state.data.limit !== 0,
			'random': state.data.random,
			'collection.added': state.data.collection.added.from || state.data.collection.added.to,
			'collection.exclude.own': state.data.collection.exclude.own || state.data.collection.exclude.own,
			'collection.exclude.prevowned': state.data.collection.exclude.prevowned || state.data.collection.exclude.prevowned,
			'status.merge': state.data.status.merge !== 0,
			'status.user': state.data.status.user !== 0,
			'status.game': state.data.status.game !== 0,
			'status.own': state.data.status.own !== 1,
			'status.prevowned': state.data.status.prevowned !== 0,
			'status.fortrade': state.data.status.fortrade !== 0,
			'status.want': state.data.status.want !== 0,
			'status.wanttoplay': state.data.status.wanttoplay !== 0,
			'status.wanttobuy': state.data.status.wanttobuy !== 0,
			'status.wishlist': state.data.status.wishlist !== 0,
			'status.preordered': state.data.status.preordered !== 0,
			'status.hasparts': state.data.status.hasparts !== 0,
			'status.wantparts': state.data.status.wantparts !== 0,
			'source.all': state.data.source.all,
			'source.plays': state.data.source.plays,
			'source.ratings': state.data.source.ratings,
			'source.collection': state.data.source.collection,
			'language.dependency': state.data.language.dependency.from !== 1 || state.data.language.dependency.to !== 5,
			'age.official': state.data.age.official.from !== 2 || state.data.age.official.to !== 21,
			'age.community': state.data.age.community.from !== 2 || state.data.age.community.to !== 21,
			'count.exact': state.data.count.exact !== 0,
			'count.official': state.data.count.official.from !== 1 || state.data.count.official.to !== 10,
			'count.recommended': state.data.count.recommended.from !== 1 || state.data.count.recommended.to !== 10,
			'count.best': state.data.count.best.from !== 1 || state.data.count.best.to !== 10,
			'rating.user': state.data.rating.user.from !== 0 || state.data.rating.user.to !== 10,
			'rating.group': state.data.rating.group.from !== 0 || state.data.rating.group.to !== 10,
			'rating.community': state.data.rating.community.from !== 0 || state.data.rating.community.to !== 10,
			'disparity.user.group': state.data.disparity.user.group.from !== -10 || state.data.disparity.user.group.to !== 10,
			'disparity.user.community': state.data.disparity.user.community.from !== -10 || state.data.disparity.user.community.to !== 10,
			'disparity.group.community': state.data.disparity.group.community.from !== -10 || state.data.disparity.group.community.to !== 10,
			'year': state.data.year.from !== 1970 || state.data.year.to !== 2030,
			'weight': state.data.weight.from !== 0 || state.data.weight.to !== 5,
			'duration': state.data.duration.from !== 0 || state.data.duration.to !== 360,
			'durations.absolute': state.data.durations.absolute,
			'plays': state.data.plays.from !== 0 || state.data.plays.to !== 100,
			'wishlist.priority': state.data.wishlist.priority.from !== 1 || state.data.wishlist.priority.to !== 5,
			'rated.date.user': state.data.rated.date.user.from || state.data.rated.date.user.to,
			'rated.date.user.from': state.data.rated.date.user.from,
			'rated.date.user.to': state.data.rated.date.user.to,
			'rated.date.group': state.data.rated.date.group.from || state.data.rated.date.group.to,
			'rated.date.group.from': state.data.rated.date.group.from,
			'rated.date.group.to': state.data.rated.date.group.to,
			'played.type': state.data.played.type !== 'sometime',
			'played.period': state.data.played.period !== 'custom',
			'played.range': state.data.played.range.from || state.data.played.range.to,
			'played.range.from': state.data.played.range.from,
			'played.range.to': state.data.played.range.to,
			'played.first': state.data.played.first.to || state.data.played.first.from || state.data.played.first.edition,
			'played.first.from': state.data.played.first.from,
			'played.first.to': state.data.played.first.to,
			'played.first.edition': state.data.played.first.edition,
			'played.last': state.data.played.last.to || state.data.played.last.from || state.data.played.last.edition,
			'played.last.from': state.data.played.last.from,
			'played.last.to': state.data.played.last.to,
			'played.last.edition': state.data.played.last.edition,
			'played.sometime': state.data.played.sometime.to || state.data.played.sometime.from || state.data.played.sometime.edition,
			'played.sometime.from': state.data.played.sometime.from,
			'played.sometime.to': state.data.played.sometime.to,
			'played.sometime.edition': state.data.played.sometime.edition,
			'play.subset': state.data.play.subset.length,
			'play.group': state.data.play.group,
			'play.status': state.data.play.status,
			'play.first': state.data.play.first,
			'play.last': state.data.play.last,
			'play.nolocation': state.data.play.nolocation,
			'play.locations': state.data.play.locations.length,
			'play.users': state.data.play.users.from !== 0 || state.data.play.users.to !== 100,
			'play.winpercent': state.data.play.winpercent.from !== 0 || state.data.play.winpercent.to !== 100,
			'play.count': state.data.play.count.from !== 1 || state.data.play.count.to !== 10,
			'play.duration': state.data.play.duration.from !== 0 || state.data.play.duration.to !== 360,
			'play.players.required': state.data.play.players.required.length,
			'play.players.together': state.data.play.players.together,
			'play.reports.required': state.data.play.reports.required,
			'play.reports.text': (state.data.play.reports.text.trim()) ? 1 : 0,
			'comments': state.data.comments.from !== 0 || state.data.comments.to !== 50,
			'rank': state.data.rank.from !== 0 || state.data.rank.to !== 1000,
			'rated.divisive': state.data.rated.divisive.from !== 0 || state.data.rated.divisive.to !== 2,
			'threshold.group': state.data.threshold.group !== 0,
			'threshold.notrecommended': state.data.threshold.notrecommended !== 0,
			'threshold.community': state.data.threshold.community !== 0,
			'threshold.plays': state.data.threshold.plays !== 0,
			'threshold.owners': state.data.threshold.owners !== 0,
			'threshold.weight': state.data.threshold.weight !== 0,
			'threshold.count': state.data.threshold.count !== 0,
			'threshold.language': state.data.threshold.language !== 0,
			'threshold.hours': state.data.threshold.hours !== 0,
			'threshold.age': state.data.threshold.age !== 0,
			'trade.type': state.data.trade.type !== 0,
			'trade.status': state.data.trade.status !== 'want',
			'games.exclude': state.data.games.exclude.length,
			'games.include': state.data.games.include.length,
			'games.subset': state.data.games.subset.length,
			'designers': state.data.designers.length,
			'languages': state.data.languages.length,
			'publishers': state.data.publishers.length,
			'artists': state.data.artists.length,
			'families': state.data.families.length,
			'categories': state.data.categories.length,
			'mechanics': state.data.mechanics.length,
			'subdomains': state.data.subdomains.length,
			'tags': state.data.tags.length,
			'inventory.location.text': state.data.inventory.location.text,
			'inventory.location.exists': state.data.inventory.location.exists,
			'worth.value':  state.data.worth.value.from !== 0 || state.data.worth.value.to !== 200,
			'worth.play':  state.data.worth.play.from !== 0 || state.data.worth.play.to !== 200,
		}

	},

	values: function(state) {

		return state.data

	},

	text: function(state) {

		return state.data.text

	},

	'inventory/location/exists': function(state) {

		return state.data.inventory.location.exists

	},

	'inventory/location/text': function(state) {

		return state.data.inventory.location.text

	},

	'comment/text': function(state) {

		return state.data.comment.text

	},

	owners: function(state) {

		return state.data.owners

	},

	users: function(state) {

		return state.data.users

	},

	'worth/value': function(state) {

		return state.data.worth.value

	},

	'worth/play': function(state) {

		return state.data.worth.play

	},

	'trade/type': function(state) {

		return state.data.trade.type

	},

	'trade/status': function(state) {

		return state.data.trade.status

	},

	status: function(state) {

		return state.data.status

	},

	'status/active': function(state) {

		var keys = []

		_.each(state.data.status, function(value, key) { 

			if (value === 1) keys.push(key)

		})

		return keys

	},

	'status/merge': function(state) {

		return state.data.status.merge

	},

	'status/user': function(state) {

		return state.data.status.user

	},

	'status/game': function(state) {

		return state.data.status.game

	},

	'status/own': function(state) {

		return state.data.status.own

	},

	'status/prevowned': function(state) {

		return state.data.status.prevowned

	},

	'status/fortrade': function(state) {

		return state.data.status.fortrade

	},

	'status/want': function(state) {

		return state.data.status.want

	},

	'status/wanttoplay': function(state) {

		return state.data.status.wanttoplay

	},

	'status/wanttobuy': function(state) {

		return state.data.status.wanttobuy

	},

	'status/wishlist': function(state) {

		return state.data.status.wishlist

	},

	'status/preordered': function(state) {

		return state.data.status.preordered

	},

	'status/hasparts': function(state) {

		return state.data.status.hasparts

	},

	'status/wantparts': function(state) {

		return state.data.status.wantparts

	},

	'disparity/user/group': function(state) {

		return state.data.disparity.user.group

	},

	'disparity/user/community': function(state) {

		return state.data.disparity.user.community

	},

	'disparity/group/community': function(state) {

		return state.data.disparity.group.community

	},

	'rating/user': function(state) {

		return state.data.rating.user

	},

	'rating/group': function(state) {

		return state.data.rating.group

	},

	'rating/community': function(state) {

		return state.data.rating.community

	},

	'count/exact': function(state) {

		return state.data.count.exact

	},

	'count/official': function(state) {

		return state.data.count.official

	},

	'count/recommended': function(state) {

		return state.data.count.recommended

	},

	'count/best': function(state) {

		return state.data.count.best

	},

	'wishlist/priority': function(state) {

		return state.data.wishlist.priority

	},

	'language/dependency': function(state) {

		return state.data.language.dependency

	},

	'age/official': function(state) {

		return state.data.age.official

	},

	'age/community': function(state) {

		return state.data.age.community

	},

	'threshold/notrecommended': function(state) {

		return state.data.threshold.notrecommended

	},

	'threshold/group': function(state) {

		return state.data.threshold.group

	},

	'threshold/community': function(state) {

		return state.data.threshold.community

	},

	'threshold/plays': function(state) {

		return state.data.threshold.plays

	},

	'threshold/hours': function(state) {

		return state.data.threshold.hours

	},

	'threshold/age': function(state) {

		return state.data.threshold.age

	},

	'threshold/owners': function(state) {

		return state.data.threshold.owners

	},

	'threshold/weight': function(state) {

		return state.data.threshold.weight

	},

	'threshold/count': function(state) {

		return state.data.threshold.count

	},

	'threshold/language': function(state) {

		return state.data.threshold.language

	},

	duration: function(state) {

		return state.data.duration

	},

	'durations/absolute': function(state) {

		return state.data.durations.absolute

	},

	'source/all': function(state) {

		return state.data.source.all

	},

	'source/plays': function(state) {

		return state.data.source.plays

	},

	'source/ratings': function(state) {

		return state.data.source.ratings

	},

	'source/collection': function(state) {

		return state.data.source.collection

	},

	plays: function(state) {

		return state.data.plays

	},

	'rated/date/user/from': function(state) {

		return state.data.rated.date.user.from

	},

	'rated/date/user/to': function(state) {

		return state.data.rated.date.user.to

	},

	'rated/date/group/from': function(state) {

		return state.data.rated.date.group.from

	},

	'rated/date/group/to': function(state) {

		return state.data.rated.date.group.to

	},

	'played/type': function(state) {

		return state.data.played.type

	},

	'played/period': function(state) {

		return state.data.played.period

	},

	'played/range/from': function(state) {

		return state.data.played.range.from

	},

	'played/range/to': function(state) {

		return state.data.played.range.to

	},

	'played/first/from': function(state) {

		return state.data.played.first.from

	},

	'played/first/to': function(state) {

		return state.data.played.first.to

	},

	'played/first/edition': function(state) {

		return state.data.played.first.edition

	},

	'played/last/from': function(state) {

		return state.data.played.last.from

	},

	'played/last/to': function(state) {

		return state.data.played.last.to

	},

	'played/last/edition': function(state) {

		return state.data.played.last.edition

	},

	'played/sometime/from': function(state) {

		return state.data.played.sometime.from

	},

	'played/sometime/to': function(state) {

		return state.data.played.sometime.to

	},

	'played/sometime/edition': function(state) {

		return state.data.played.sometime.edition

	},

	'collection/exclude/own': function(state) {

		return state.data.collection.exclude.own

	},

	'collection/exclude/prevowned': function(state) {

		return state.data.collection.exclude.prevowned

	},

	'collection/added/from': function(state) {

		return state.data.collection.added.from

	},

	'collection/added/to': function(state) {

		return state.data.collection.added.to

	},

	comments: function(state) {

		return state.data.comments

	},

	rank: function(state) {

		return state.data.rank

	},

	'rated/divisive': function(state) {

		return state.data.rated.divisive

	},

	weight: function(state) {

		return state.data.weight

	},

	year: function(state) {

		return state.data.year

	},

	unplayed: function(state) {

		return state.data.unplayed

	},

	ownersmin: function(state) {

		return state.data.ownersmin

	},

	soloplayed: function(state) {

		return state.data.soloplayed

	},

	unrated: function(state) {

		return state.data.unrated

	},

	commented: function(state) {

		return state.data.commented

	},

	limit: function(state) {

		return state.data.limit

	},

	random: function(state) {

		return state.data.random

	},

	expansions: function(state) {

		return state.data.expansions

	},

	designers: function(state) {

		return state.data.designers

	},

	languages: function(state) {

		return state.data.languages

	},

	publishers: function(state) {

		return state.data.publishers

	},

	'games/include': function(state) {

		return state.data.games.include

	},

	'games/exclude': function(state) {

		return state.data.games.exclude

	},

	'games/subset': function(state) {

		return state.data.games.subset

	},

	artists: function(state) {

		return state.data.artists

	},

	families: function(state) {

		return state.data.families

	},

	categories: function(state) {

		return state.data.categories

	},

	subdomains: function(state) {

		return state.data.subdomains

	},

	mechanics: function(state) {

		return state.data.mechanics

	},

	tags: function(state) {

		return state.data.tags

	},

	sort: function(state) {

		return state.data.sort

	},

	sortReverse: function(state) {

		return state.data.sortReverse

	},

	'play/status': function(state) {

		return state.data.play.status

	},

	'play/subset': function(state) {

		return state.data.play.subset

	},

	'play/group': function(state) {

		return state.data.play.group

	},

	'play/first': function(state) {

		return state.data.play.first

	},

	'play/last': function(state) {

		return state.data.play.last

	},

	'play/nolocation': function(state) {

		return state.data.play.nolocation

	},

	'play/locations': function(state) {

		return state.data.play.locations

	},

	'play/count': function(state) {

		return state.data.play.count

	},

	'play/duration': function(state) {

		return state.data.play.duration

	},

	'play/users': function(state) {

		return state.data.play.users

	},

	'play/winpercent': function(state) {

		return state.data.play.winpercent

	},

	'play/players/required': function(state) {

		return state.data.play.players.required

	},

	'play/players/together': function(state) {

		return state.data.play.players.together

	},

	'play/reports/required': function(state) {

		return state.data.play.reports.required

	},

	'play/reports/text': function(state) {

		return state.data.play.reports.text

	}

}