<template>

<core-config-section>

	<h2>Date format <small>Applies to entire app</small></h2>

	<p class="about">You can set the default date order for whenever a date is shown.</p>

	<div class="inputs">

		<core-filter-input-toggles :stacked="true" filter="config/other/date" :values="toggle.values" :icons="toggle.icons" :labels="toggle.labels" />

	</div>

</core-config-section>

</template>

<script>

export default {

	data: function() {
		
		return {
			toggle: {
				icons: [false, false, false],
				values: ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD'],
				labels: ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD']
			}
		}

	}

}

</script>