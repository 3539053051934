<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Edit List

	</template>

	<template v-slot:default>

		<div class="inputs">

			<core-form-input-textbox :autofocus="true" maxlength="32" name="name" v-on:validate="validate" placeholder="Enter name" v-model="inputs.name" :error="is.error" :disabled="is.loading" :validation="validation.name"></core-form-input-textbox>

			<core-form-input-textarea name="description" v-on:validate="validate" placeholder="Enter description" v-model="inputs.description" :error="is.error" :disabled="is.loading" :validation="validation.description"></core-form-input-textarea>

		</div>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Save changes</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				name: this.$store.getters['page/list/list'].name,
				description: this.$store.getters['page/list/list'].description
			},

			validation: {
				name: {
					exists: true,
					max: 32
				},
				description: {
					exists: true
				}
			}

		}

	},

	methods: {

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/list/edit', {
					data: {
						name: this.inputs.name,
						description: this.inputs.description
					}
				}).then(function(json) {

					this.$store.commit('page/list/list/set', json)

					this.$emit('close')

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>