<template>

<com-item name="size.summary" :size="size" :loading="loading" theme="blue" title="Total">

	<template v-slot:about>

		<p>The overall size of {{ your }} collection.</p>

	</template>

	<template v-slot:stats>

		<core-widget class="summary">

			<com-empty v-if="!active" :loading="loading" text="No collection data" />

			<div class="summary-total" v-if="active" v-fit v-html="summary.total.current" />

			<div class="summary-change" v-if="active">

				<div class="summary-change-title"><span>Trend</span></div>

				<div class="summary-change-items">

					<div class="summary-change-item" v-if="summary.total['1m']!== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total['1m']> 0, 'is-down': summary.total['1m']< 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total['1m']> 0, 'fa-caret-down': summary.total['1m'] < 0}"></i><div v-if="summary.total['1m']!== false">{{ summary.total['1m']| abs }}</div><div v-if="summary.total['1m']=== false">-</div></div>
						<div class="summary-change-item-text">vs 1 month ago</div>
						
					</div>

					<div class="summary-change-item" v-if="summary.total['3m']!== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total['3m']> 0, 'is-down': summary.total['3m']< 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total['3m']> 0, 'fa-caret-down': summary.total['3m'] < 0}"></i><div v-if="summary.total['3m']!== false">{{ summary.total['3m']| abs }}</div><div v-if="summary.total['3m']=== false">-</div></div>
						<div class="summary-change-item-text">vs 3 months ago</div>
						
					</div>

					<div class="summary-change-item" v-if="summary.total['6m']!== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total['6m']> 0, 'is-down': summary.total['6m']< 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total['6m']> 0, 'fa-caret-down': summary.total['6m']< 0}"></i><div v-if="summary.total['6m']!== false">{{ summary.total['6m']| abs }}</div><div v-if="summary.total['6m']=== false">-</div></div>
						<div class="summary-change-item-text">vs 6 months ago</div>
												
					</div>

				</div>

				<div class="summary-change-items">

					<div class="summary-change-item" v-if="summary.total['1y']!== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total['1y']> 0, 'is-down': summary.total['1y']< 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total['1y']> 0, 'fa-caret-down': summary.total['1y']< 0}"></i><div v-if="summary.total['1y']!== false">{{ summary.total['1y']| abs }}</div><div v-if="summary.total['1y']=== false">-</div></div>
						<div class="summary-change-item-text">vs 1 year ago</div>
						
					</div>

					<div class="summary-change-item" v-if="summary.total['2y']!== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total['2y']> 0, 'is-down': summary.total['2y']< 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total['2y']> 0, 'fa-caret-down': summary.total['2y']< 0}"></i><div v-if="summary.total['2y']!== false">{{ summary.total['2y']| abs }}</div><div v-if="summary.total['2y']=== false">-</div></div>
						<div class="summary-change-item-text">vs 2 years ago</div>
						
					</div>

					<div class="summary-change-item" v-if="summary.total['3y']!== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total['3y']> 0, 'is-down': summary.total['3y']< 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total['3y']> 0, 'fa-caret-down': summary.total['3y']< 0}"></i><div v-if="summary.total['3y']!== false">{{ summary.total['3y']| abs }}</div><div v-if="summary.total['3y']=== false">-</div></div>
						<div class="summary-change-item-text">vs 3 years ago</div>
												
					</div>

				</div>

			</div>
		
		</core-widget>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comEmpty from './../common/Empty'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-empty': comEmpty,
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		summary: function() {

			return this.$store.getters['page/insights/size/summary']

		},

		active: function() {

			return this.summary && !this.loading

		}

	}

}

</script>

<style scoped>

.summary {
	padding: 0px!important;
	border: 0px!important;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.is-mobile .summary {
	height: 100%;
}

.summary-total {
	display: flex;
	margin: 5px 0px 25px 0px;
	align-items: baseline;
	justify-content: center;
	color: #fff;
	font-size: 100px;
	font-weight: 700;
	width: 100%;
	text-align: center;
}

.summary-total small {
	font-size: 12px;
	text-align: center;
	font-weight: 400;
	position: absolute;
	bottom: -16px;
	display: block;
}

.summary-total >>> span.currency {
	font-size: 48px;
	margin-right: 0px;
}

.summary-change {
	flex-shrink: 0;
	width: 100%;
	padding: 0px 10px 10px 10px;
	margin-bottom: 10px;
}

.summary-change-items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
}

.summary-change-item {
	width: 33.3%;
	display: flex;
	flex-direction: column;
}

.summary-change-item-value {
	align-items: center;
	display: flex;
	color: #fff;
	height: 64px;
	font-weight: 500;
	font-size: 28px;
	justify-content: center;
}

.summary-change-item-value.is-up {
	color: #5acf69;
}

.summary-change-item-value.is-down {
	color: #9c150e;
}

.summary-change-item-value .fa {
	font-size: 14px;
	margin-right: 6px;
}

.summary-change-item-value >>> span.currency {
	font-size: 14px;
}

.summary-change-item-value > div {
	height: 64px;
	display: flex;
	align-items: center;
}

.summary-change-item-text {
	height: 16px;
	flex-shrink: 0;
	color: #fff;
	font-size: 12px;
	text-align: center;
	font-weight: 400;
}

.summary-change-title {
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
	color: #fff;
	text-align: center;
}

.summary-change-title span {
	background-color: #4881bb;
	display: inline-block;
	padding: 0px 20px;
	position: relative;
	z-index: 2;
}

.summary-change-title:after {
	content: ' ';
	display: block;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	right: 0px;
	top: 7px;
	left: 0px;
	z-index: 1;
}

</style>