<template>

<core-filter-advanced-group title="Collection status" :count="count">

	<core-filter-input-toggle text="Own" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/own')" />
	<core-filter-input-toggle text="Previously Owned" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/prevowned')" />
	<core-filter-input-toggle text="For Trade" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/fortrade')" />
	<core-filter-input-toggle text="Want" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/want')" />
	<core-filter-input-toggle text="Want to play" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/wanttoplay')" />
	<core-filter-input-toggle text="Want to buy" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/wanttobuy')" />
	<core-filter-input-toggle text="Wishlist" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/wishlist')" />
	<core-filter-input-toggle text="Pre-ordered" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/preordered')" />
	<core-filter-input-toggle text="Has parts" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/hasparts')" />
	<core-filter-input-toggle text="Wants parts" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/wantparts')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['hide.status.own', 'hide.status.prevowned', 'hide.status.fortrade', 'hide.status.want', 'hide.status.wanttoplay', 'hide.status.wanttobuy', 'hide.status.wishlist', 'hide.status.preordered', 'hide.status.hasparts', 'hide.status.wantparts']
		}
	}

}

</script>