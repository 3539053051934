<template>

<div class="item" :class="{'is-dark': dark}">

	<div class="item-name">

		<div v-on:click="onMoreClick(item.id, $event)">{{ item.name }}</div>

	</div>

	<core-ratings v-if="item.games" :games="item.games" :max="3" :count="gamesCount(item)" class="item-games" v-on:more="onMoreClick(item.id)" />

	<div v-if="item.count && !hideCount" class="item-count" v-fit>{{ item.count }}</div>

	<div v-if="item.worth" class="item-worth" v-html="formatcurrency(item.worth, 0)" v-fit></div>

	<core-rating v-if="item.rating" :value="item.rating" :count="item.count" class="item-rating" />

</div>

</template>

<script>

export default {

	props: ['item', 'type', 'hideCount', 'gamesCountProperty', 'dark'],

	computed: {

		fieldGamesCount: function() {

			return 'count'

		}

	},

	methods: {

		gamesCount: function(item) {

			var field = (item.gamesCount) ? 'gamesCount' : (this.gamesCountProperty || 'count')

			return item[field]

		},

		onMoreClick: function(id, e) {

			if (e) e.stopPropagation()

			this.$emit('more', id)

		}

	}

}

</script>

<style scoped>

.item {
	width: 100%;
	height: 64px;
	display: flex;
	padding: 8px 15px 8px 15px;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.item {
		padding: 8px 8px;
		height: auto;
	}

}

.item:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.item.is-dark:nth-child(odd) {
	background-color: rgba(255, 255, 255, 0.05);
}

.item-name {
	padding: 0px;
	flex-grow: 1;
	min-width: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.item-name div {
	font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #333;
    cursor: pointer;
    display: block;
    text-overflow: ellipsis;
}

.item.is-dark .item-name div {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.item-name div {
		font-size: 14px;
	}

}

.is-desktop .item-name div:hover {
	text-decoration: underline;
}

.item-rating {
	flex-shrink: 0;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 18px;
	font-weight: 400;
}

.item-count {
	width: 48px;
    flex-shrink: 0;
    text-align: right;
    font-size: 24px;
    line-height: 48px;
    font-weight: 400;
    color: #333;
}

.item.is-dark .item-count {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.item-count {
		font-size: 18px;
	}

}

.item-worth {
	width: 70px;
    flex-shrink: 0;
    text-align: right;
    font-size: 24px;
    line-height: 48px;
    font-weight: 400;
    color: #333;
}

.item-worth >>> span {
	font-size: 12px;
}

.item.is-dark .item-worth {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.item-worth {
		font-size: 18px;
	}

}

.item-games {
	flex-shrink: 0;
	width: 128px;
}

</style>