<template>
	
<div class="card-foot" :class="{'is-borderless': borderless}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['borderless']

}

</script>

<style scoped>

.card-foot {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	padding: 7px 0px;
	border: 1px solid #ddd;
	border-radius: 0px 0px 4px 4px;
	background-color: #fff;
	border-width: 1px 1px 1px 1px;
	justify-content: spaced-evenly;
}

.card-foot.is-borderless {
	border-top: 0px;
}

</style>
