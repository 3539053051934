import presets from './session/presets'
import config from './session/config'

export default {

	modules: {
		presets: presets,
		config: config
	},

	getters: {

		presets: function(state, getters) {

			return getters['presets/all']

		},

		config: function(state, getters) {

			return getters['config/all']

		}

	}

}