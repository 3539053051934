<template>

<div class="expand">

	<div class="expand-head">

		<div class="inner expand-head-inner">

			<div v-lazy:background-image="avatar" class="expand-head-avatar" />

			<div class="expand-head-name">{{ name }}</div>

			<div class="expand-head-tabs" v-if="!hasDropdown">

				<slot name="tabs"></slot>

			</div>

			<core-list-expand-dropdown v-on:change="onChange" class="expand-head-dropdown" :options="dropdown" :text="currentText" :value="currentTab" v-if="hasDropdown && dropdown.length" />

			<div class="expand-head-close" v-on:click="onCloseClick"><i class="fa fa-times"></i></div>

		</div>

	</div>

	<div class="expand-sections">

		<div class="inner expand-sections-inner">

			<slot name="sections"></slot>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['id', 'game', 'name', 'selected'],

	data: function() {

		return {

			dropdown: [],
			currentTab: '',
			currentText: ''

		}

	},

	computed: {

		avatar: function() {

			return this.$util.thumbnail.game.small(this.game)

		},

		hasDropdown: function() {

			return (this.dropdown.length > 2 && this.window.width < 800) || (this.window.width < 480)

		}

	},

	watch: {

		selected: function() {

			this.$_.each(this.$slots.tabs, function(node) {

				if (node.tag !== undefined) {

					this.currentTab = (node.componentOptions.propsData.selected) ? node.componentOptions.propsData.value : this.currentTab
					this.currentText = (node.componentOptions.propsData.selected) ? node.componentOptions.propsData.text : this.currentText

				}

			}.bind(this))

		}

	},

	mounted: function() {

		this.dropdown = []

		this.$_.each(this.$slots.tabs, function(node) {

			if (node.tag !== undefined) {

				this.dropdown.push({
					value: node.componentOptions.propsData.value,
					text: node.componentOptions.propsData.text
				})

				this.currentTab = (node.componentOptions.propsData.selected) ? node.componentOptions.propsData.value : this.currentTab
				this.currentText = (node.componentOptions.propsData.selected) ? node.componentOptions.propsData.text : this.currentText

			}

		}.bind(this))

	},

	methods: {

		onCloseClick: function(e) {

			e.stopPropagation()

			this.$emit('close')

		},

		onChange: function(section) {

			this.$emit('tab', section)

		}

	}

}

</script>

<style scoped>

.expand {
	position: fixed;
	z-index: 100000;
	left: 0px;
	bottom: 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	box-shadow: 0px -5px 21px -13px rgba(0,0,0,0.75);
}

.is-mobile .expand {
	top: 0px;
	box-shadow: none;
}

.expand-head {
	width: 100%;
	padding: 4px 0px;
	background-color: #fff;
	border-top: 1px solid #eee;
	height: 40px;
	flex-shrink: 0;
}

.is-mobile .expand-head {
	border-top: 0px solid #eee;
	padding: 0px;
	height: 56px;
}

.expand-head-inner {
	display: flex;
	flex-direction: row;
	padding: 0px 8px;
}

.is-mobile .expand-head-inner {
	padding: 0px;
}

.expand-head-avatar {
	width: 32px;
	height: 32px;
	flex-shrink: 0;
	margin-right: 8px;
	border-radius: 4px;
	background-size: cover;
	background-position: 50% 50%;
}

.is-mobile .expand-head-avatar {
	width: 48px;
	height: 48px;
	margin: 4px 8px 4px 4px;
}

.expand-head-name {
	font-size: 16px;
	line-height: 32px;
	font-weight: 500;
	color: #333;
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 1;
	max-width: calc(100% - 8px);
}

.is-mobile .expand-head-name { 
	margin: 4px 0px;
	font-size: 14px;
	line-height: 48px;
}

.expand-head-tabs {
	display: flex;
}

.is-mobile .expand-head-tabs { 
	margin: 4px 0px;
}

.expand-head-dropdown {
	border-left: 1px solid #eee;
	white-space: nowrap;
	flex-shrink: 0;
}

.expand-head-close {
	padding-right: 0px;
    width: 32px;
	flex-shrink: 0;
    height: 32px;
    line-height: 34px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    margin-left: 8px;
}

.is-mobile .expand-head-close {
	border-left: 1px solid #eee;
	height: 56px;
	width: 56px;
	line-height: 56px;
	font-size: 24px;
    margin-left: 0px;
}

.expand-sections {
	background-color: #fff;
	z-index: 1;
	height: 264px;
	border-top: 1px solid #eee;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.expand-sections-inner {
	padding: 0px 8px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.is-mobile .expand-sections-inner {
	padding: 0px;
}

.is-mobile .expand-head-dropdown >>> .button {
	height: 56px;
}

.is-mobile .expand-head-dropdown >>> .dropdown-list {
	top: 57px;
}

</style>