<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ band }}</h1>

		<p>There are <b>{{ games.length }}</b> <plural :t="gameType" :n="games.length" />. {{ expansions }}</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" v-bind:key="index" :isUserRating="isUser" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'valuations',
			games: [],
			params: ['band']
		}

	},

	computed: {

		expansions: function() {

			if (this.$store.getters['filter/insights/worth/valuations'] === 'games') {

				return 'Excludes expansions.'

			} else if (this.$store.getters['filter/insights/worth/valuations'] === 'all') {

				return 'Includes expansions.'

			} else {

				return ''

			}

		},

		gameType: function() {

			if (this.$store.getters['filter/insights/worth/valuations'] === 'expansions') {

				return 'expansion'

			} else {

				return 'game'

			}

		},

		band: function() {

			if (this.data.band === 15) {

				return this.convert((this.data.band * 8) + 1) + ' or higher'

			} else {

				return this.convert((this.data.band * 8) + 1) + ' to $' + this.convert((this.data.band + 1) * 8)

			}

		}

	},

	methods: {

		convert: function(value) {

			return this.$CONSTANTS.CURRENCY[this.$store.getters['session/config'].other.currency].sign + this.formatcurrency(value, 0, true).toString()

		},

		response: function(json) {

			this.games = json.games

		}

	}

}

</script>