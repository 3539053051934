import router from 'app/router'

export default {

	namespaced: true,

	state: {
		
		activity: {
			expansions: 1,
			period: 0
		},

		lifespan: {
			expansions: 1,
			period: 0
		},
		
		changes: {
			type: 'added'
		}

	},

	getters: {

		active: function(state) {

			return {
				'activity.expansions': !state.activity.expansions,
				'activity.period': state.activity.period,
				'lifespan.expansions': !state.lifespan.expansions,
				'lifespan.period': state.lifespan.period,
				'changes.type': state.changes.type !== 'added'
			}

		},

		values: function(state) {

			return {
				'activity.expansions': state.activity.expansions,
				'activity.period': state.activity.period,
				'lifespan.expansions': state.lifespan.expansions,
				'lifespan.period': state.lifespan.period,
				'changes.type': state.changes.type
			}

		},

		'activity/expansions': function(state) {

			return state.activity.expansions

		},

		'activity/period': function(state) {

			return state.activity.period

		},

		'lifespan/expansions': function(state) {

			return state.lifespan.expansions

		},

		'lifespan/period': function(state) {

			return state.lifespan.period

		},

		'changes/type': function(state) {

			return state.changes.type

		}

	},

	mutations: {

		reset: function(state) {

			state.activity.expansions = 1
			state.activity.period = 0
			state.lifespan.expansions = 1
			state.lifespan.period = 0
			state.changes.type = 'added'

		},

		'activity/expansions': function(state, value) {

			state.activity.expansions = parseInt(value)

		},

		'activity/period': function(state, value) {

			state.activity.period = parseInt(value)

		},

		'lifespan/expansions': function(state, value) {

			state.lifespan.expansions = parseInt(value)

		},

		'lifespan/period': function(state, value) {

			state.lifespan.period = parseInt(value)

		},

		'changes/type': function(state, value) {

			state.changes.type = value

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params['activity.expansions']) context.state.activity.expansions = parseInt(params['activity.expansions'])
					if (params['activity.period']) context.state.activity.period = parseInt(params['activity.period'])
					if (params['lifespan.expansions']) context.state.lifespan.expansions = parseInt(params['lifespan.expansions'])
					if (params['lifespan.period']) context.state.lifespan.period = parseInt(params['lifespan.period'])
					if (params['changes.type']) context.state.changes.type = params['changes.type']

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}

			if (context.state.activity.expansions !== 1) urlParams['activity.expansions'] = context.state.activity.expansions
			if (context.state.activity.period) urlParams['activity.period'] = context.state.activity.period
			if (context.state.lifespan.expansions !== 1) urlParams['lifespan.expansions'] = context.state.lifespan.expansions
			if (context.state.lifespan.period) urlParams['lifespan.period'] = context.state.lifespan.period
			if (context.state.changes.type !== 'added') urlParams['changes.type'] = context.state.changes.type

			router.replace({name: 'InsightsSize', query: urlParams}).catch(function() {})

		}

	}

}