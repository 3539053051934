<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ publisher }}</h1>

		<p>There are <b>{{count}}</b> <plural t="play" :n="count" /> for this publisher with <b>{{games.length}}</b> <plural t="game" :n="games.length" />. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :isUserRating="isUser" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'publisher',
			games: [],
			publisher: '',
			count: 0
		}

	},

	methods: {

		response: function(json) {

			this.count = json.count
			this.publisher = json.publisher
			this.games = json.games

		}

	}

}

</script>