<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>{{ description}}</p>

	</template>

	<template v-slot:body>

		<app-streak :hideGames="hideGames" :item="item" :user="!isUser" :showSummary="showSummary" :label="label" v-for="(item, index) in streaks" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'streaks',
			params: [
				'streakType'
			],
			streaks: [],
			titles: {
				win: 'Win streaks',
				loss: 'Loss streaks',
				play: 'Play streaks',
				drought: 'Play droughts'
			},
			labels: {
				win: 'play',
				loss: 'play',
				play: 'day',
				drought: 'day'
			},
			descriptions: {
				win: 'Longest streaks of consecutive wins for plays that have a recorded winner.',
				loss: 'Longest streaks of consecutive losses for plays that have a recorded winner.',
				play: 'Longest streaks of consecutive days with at least one play.',
				drought: 'Longest streaks of consecutive days without a play.'
			}
		}

	},

	computed: {

		hideGames: function() {

			return this.data.streakType === 'play' || this.data.streakType === 'drought'

		},

		showSummary: function() {

			return this.data.streakType === 'play' || this.data.streakType === 'drought'

		},

		label: function() {

			return this.labels[this.data.streakType]

		},

		title: function() {

			return this.titles[this.data.streakType]

		},

		description: function() {

			return this.descriptions[this.data.streakType]

		}

	},

	methods: {

		response: function(json) {

			this.streaks = json.streaks

		}

	}

}

</script>