<template>

<core-filter-advanced :active="active" v-on:clickoutside="$emit('close')" v-propagation>

	<template v-slot:inputs>

		<core-filter-advanced-group title="Filter">

			<core-filter-input-toggles filter="tools/playgrid/period" text="Period" :values="type.values" :icons="type.icons" :labels="type.labels" />

			<core-filter-input-daterange filter="tools/playgrid/date" v-if="filter.period === 'custom'" />

			<core-filter-input-toggle text="First plays only" on="Yes" off="No" filter="tools/playgrid/first" />

			<core-filter-input-toggle text="Include expansion plays" on="Yes" off="No" filter="tools/playgrid/expansions" />

			<core-filter-input-toggle text="Don't show duplicates" on="Yes" off="No" filter="tools/playgrid/group" v-if="!filter.first" />

			<core-filter-sort :sortOptions="sort" filter="tools/playgrid/sort" class="sort" :inline="true" />

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Layout">

			<core-filter-input-range text="Grid" subtext="per row" min="2" max="10" maxText="Auto" filter="tools/playgrid/layout/grid" step="1" pips="1" decimals="0" />

			<core-filter-input-range text="Thumbs" subtext="pixels" min="64" max="256" filter="tools/playgrid/layout/thumb" step="1" pips="16" :tooltip="true" decimals="0" />

			<core-filter-input-range text="Corners" subtext="pixels" min="0" max="128" filter="tools/playgrid/layout/corner" step="1" pips="16" :tooltip="true" decimals="0" />

			<core-filter-input-range text="Spacing" subtext="pixels" min="0" max="10" filter="tools/playgrid/layout/spacing" step="2" pips="2" decimals="0" />

			<core-filter-input-toggle text="Centralise details" on="Yes" off="No" filter="tools/playgrid/layout/central" />

			<core-filter-input-toggle text="Don't crop game images" on="Yes" off="No" filter="tools/playgrid/layout/nocrop" />

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Details">

			<core-filter-input-toggle text="Game name" on="Yes" off="No" filter="tools/playgrid/layout/show/game" />

			<core-filter-input-toggle :text="dateText" on="Yes" off="No" filter="tools/playgrid/layout/show/date" />

			<core-filter-input-toggle text="Label first plays" on="Yes" off="No" filter="tools/playgrid/layout/show/first" v-if="!filter.first" />

			<core-filter-input-toggle text="Nth play" on="Yes" off="No" filter="tools/playgrid/layout/show/plays/increment" v-if="!filter.group" />

			<core-filter-input-toggle text="Date range plays" on="Yes" off="No" filter="tools/playgrid/layout/show/plays/context" v-if="filter.date.from !== 0 || filter.date.to !== 0" />

			<core-filter-input-toggle text="All-time plays" on="Yes" off="No" filter="tools/playgrid/layout/show/plays/all" />

			<core-filter-input-toggle text="Player count" on="Yes" off="No" filter="tools/playgrid/layout/show/players" v-if="!filter.group" />

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Extras">

			<core-filter-input-toggle text="Show title" on="Yes" off="No" filter="tools/playgrid/layout/show/title" />

			<core-filter-input-text filter="tools/playgrid/layout/title"  class="title" v-if="filter.layout.show.title" />

			<core-filter-input-toggle text="Show user" on="Yes" off="No" filter="tools/playgrid/layout/show/user" />

			<core-filter-input-toggle text="Show geekGroup credit" on="Yes" off="No" filter="tools/playgrid/layout/show/app" />

		</core-filter-advanced-group>

	</template>

	<template v-slot:buttons>

		<core-button v-if="window.is.mobile" v-on:click.native="$emit('close')" theme="blue" fluid="yes">Close filter</core-button>

	</template>

</core-filter-advanced>

</template>

<script>

export default {

	props: ['active'],

	data: function() {

		return {
			type: {
				labels: ['Custom', 'This month', 'Last month'],
				icons: ['', '', '', ''],
				values: ['custom', 'month.current', 'month.last']
			}
		}

	},

	computed: {

		filter: function() {

			return this.$store.getters['filter/tools/playgrid']

		},

		dateText: function() {

			return (this.filter.group) ? 'Most recent date played' : 'Date played'

		},

		sort: function() {

			return ['play.date', 'game.name', 'plays.all', 'plays.context']

		}

	},

	methods: {

		onResetClick: function() {

			this.$store.commit('filter/tools/playgrid/reset')

		}

	}

}

</script>

<style scoped>

.section {
	padding: 15px 15px 7px 15px!important;
}

.title,
.sort {
	padding-top: 0px!important;
}

</style>
