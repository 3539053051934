<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary :title="($route.name === 'InsightsShelf') ? shelfName : $route.meta.subtitle" icon="pie-chart" v-if="window.is.mobile" />

		<core-filter-summary title="Insights" :parenttitle="$route.meta.parenttitle" :subtitle="($route.name === 'InsightsShelf') ? shelfName : $route.meta.subtitle" icon="pie-chart" v-if="!window.is.mobile" />

	</core-filter-bar-left>

	<core-filter-bar-right>

		<slot></slot>

		<core-button v-on:click.native="onHelpShelfClick" theme="filter" tooltip="About insights" icon="info-circle" :iconOnly="true" v-if="$route.name === 'InsightsShelf'"></core-button>

		<core-button v-on:click.native="onHelpWorthClick" theme="filter" tooltip="About insights" icon="info-circle" :iconOnly="true" v-if="$route.name === 'InsightsWorth'"></core-button>

		<core-filter-period :filter="filter" v-if="$route.name === 'InsightsPlays'" />

		<com-config-records v-on:refresh="$emit('refresh')" v-if="$route.name === 'InsightsRecords'" />

		<com-config-plays v-on:refresh="$emit('refresh')" v-if="$route.name === 'InsightsPlays'" />

		<com-config-shelf v-on:refresh="$emit('refresh')" v-if="$route.name === 'InsightsShelf'" />

		<com-config-worth v-on:refresh="$emit('refresh')" v-if="$route.name === 'InsightsWorth'" />

	</core-filter-bar-right>

</core-filter-bar>

</template>

<script>

import filterPeriod from './filter/Period'

import configRecords from './config/Records'
import configPlays from './config/Plays'
import configShelf from './config/Shelf'
import configWorth from './config/Worth'

export default {

	props: ['loading', 'filter'],

	components: {
		'core-filter-period': filterPeriod,
		'com-config-records': configRecords,
		'com-config-plays': configPlays,
		'com-config-shelf': configShelf,
		'com-config-worth': configWorth
	},

	computed: {

		shelfName: function() {

			return (this.$store.getters['session/config'].lists.unplayed.type === 'shame') ? 'Shelf of Shame' : 'Shelf of Opportunity'

		}

	},

	methods: {

		onHelpShelfClick: function() {

			this.$pubsub.$emit('open.modal', 'helpShelf')

		},

		onHelpWorthClick: function() {

			this.$pubsub.$emit('open.modal', 'helpWorth')

		}		

	}

}

</script>

<style scoped>

.submenu-list {
	display: flex;
	margin-left: -10px;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.submenu-list {
		display: none;
	}

}

.submenu-list-item {
	font-size: 14px;
	color: rgba(255, 255, 255, 0.9);
	height: 18px;
	font-weight: 400;
	cursor: pointer;
}

.submenu-list-item a {
	display: block;
	height: 18px;
	padding: 0px 10px;
}

.submenu-list-item:hover {
	color: rgba(255, 255, 255, 1);
}

.submenu-list-item.is-active {
	font-weight: bold;
	color: #fff;
	border-radius: 4px;
}

.submenu-list-item.is-active:hover {
	text-decoration: none;
}

.submenu-list-item.is-active:after {
	content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #fff transparent;
}

.submenu-group {
	display: flex;
	flex-direction: column;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	padding: 0px 10px;
}

.submenu-group:last-child {
	border-right: 0px;
}

.submenu-group-title {
	font-size: 11px;
	letter-spacing: 1px;
	font-weight: 400;
	padding: 6px 10px 2px 10px;
	color: rgba(255, 255, 255, 0.5);
}

.submenu-group-items {
	display: flex;
	flex-direction: row;
}

</style>
