import pubsub from 'core/pubsub'

export default {

	rename: function(context, e) {

		e = e || {}

		e.override = e.override || {}

		return new Promise(function(resolve) {

			pubsub.$emit('open.modal', {
				id: 'gameRename',
				game: e.override.game
			})

			resolve()

		})

	}

}