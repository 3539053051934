import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		about: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('games/about', params).then(function(json) {

					context.commit('data/games/add', [json.game], { root: true })
					context.commit('data/designers/add', json.data.designers, { root: true })
					context.commit('data/languages/add', json.data.languages, { root: true })
					context.commit('data/publishers/add', json.data.publishers, { root: true })
					context.commit('data/artists/add', json.data.artists, { root: true })
					context.commit('data/mechanics/add', json.data.mechanics, { root: true })
					context.commit('data/subdomains/add', json.data.subdomains, { root: true })
					context.commit('data/categories/add', json.data.categories, { root: true })
					context.commit('data/families/add', json.data.families, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		expansions: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('games/expansions', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		comments: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('games/comments', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		suggest: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('games/suggest', params).then(function(json) {

					context.commit('data/games/add', json.suggestions, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		}
		
	}

}