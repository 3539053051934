<template>

<div class="change" v-bind:class="{'is-reverse': reverse}">

	<template v-if="showChange && revisions">

		<span class="change-tag" v-if="revisions.count" v-bind:class="{'is-up': revisions.change > 0, 'is-down': revisions.change < 0}">
			<i class="fa" v-bind:class="{'fa-caret-up': revisions.change > 0, 'fa-caret-down': revisions.change < 0}"></i> 
			{{ revisions.change | abs | round(1, true) }}
		</span>

		<span class="change-tag" v-if="!revisions.count && !revisions.history[0].initial">
			New
		</span>

		<span v-tooltip="'Rating from initial profile sync'" class="change-tag is-initial" v-if="!revisions.count && revisions.history[0].initial">
			Initial
		</span>

	</template>

	<span class="change-date" v-if="showDate">{{ datePrefix }}{{ ratingDate }}</span>

</div>

</template>

<script>

export default {

	props: ['revisions', 'date', 'showChange', 'reverse', 'datePrefix', 'showDate'],

	computed: {

		ratingDate: function() {

			return (this.date) ? this.$moment.unix(this.date).utc().format(this.$util.date.format('Do', 'MMMM', 'YYYY')) : ''

		}

	}

}

</script>

<style scoped>

.change {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 2px;
}

.change.is-reverse {
	flex-direction: row-reverse;
	margin-top: 1px;
}

.change-date {
	font-size: 11px;
	font-weight: 300;
	color: #666;
}

.change-tag {
	font-size: 10px;
	line-height: 10px;
	border-radius: 4px;
	padding: 2px 4px;
	font-weight: 400;
	color: #fff;
	margin-right: 4px;
	background-color: #1d8acd;
	width: 26px;
	text-align: center;
}

.change.is-reverse .change-tag {
	margin-right: 0px;
	margin-left: 4px;
}

.change-tag.is-up {
	background-color: #2FC482;
}

.change-tag.is-down {
	background-color: #df4751;
}

.change-tag.is-initial {
	background-color: #666;
}

</style>