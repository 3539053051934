<template>

<div class="avatar" v-tooltip="tooltipText" v-on:click="onClick" v-bind:style="{backgroundImage: backgroundImage, backgroundColor: backgroundColor}" v-bind:class="{'is-clickable': this.type === 'user'}">

	<div class="avatar-initial" v-if="backgroundImage === 'none' || !backgroundImage">{{ initial }}</div>

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['type', 'id', 'tooltip', 'tooltipSmall', 'size', 'overrideClick'],

	data: function() {

		return {
			source: false,
			initial: '',
			primaryname: '',
			secondaryname: '',
			slug: '',
			backgroundColor: false,
			backgroundImage: false
		}

	},

	created: function() {

		if (this.type === 'context') {

			this.$watch(function() {

				return this.$store.getters['context/id']

			}, function() {

				this.onChange()

			})

		} else {

			this.$watch(function() {

				return this.id

			}, function() {

				this.onChange()

			})

		}

		this.onChange()

	},

	computed: {

		tooltipText: function() {

			if (this.tooltip) {

				var html = []

				html.push(this.primaryname)

				if (this.secondaryname) html.push('<small>' + this.secondaryname + '</small>')
				if (this.tooltipSmall) html.push('<small>' + this.tooltipSmall + '</small>')

				return html.join('')

			} else {

				return false

			}

		}

	},

	methods: {

		onChange: function() {

			if (this.type === 'context') this.source = this.$store.getters['context']
			if (this.type === 'session' && this.loggedin) this.source = this.$store.getters['session']
			if (this.type === 'game') this.source = this.$store.getters['data/games'][this.id]
			if (this.type === 'user') this.source = this.$store.getters['data/users'][this.id]
			if (this.type === 'group') this.source = this.$store.getters['data/groups'][this.id]
			if (this.type === 'session' && !this.loggedin) {

				this.source = {
					name: 'guest',
					avatar: ''
				}

			}

			this.initial = ''
			this.primaryname = ''
			this.secondaryname = ''
			this.slug = ''
			this.backgroundColor = false
			this.backgroundImage = false

			if (this.source) {

				if (this.type === 'user') {

					this.primaryname = this.source.fullname || this.source.name
					this.secondaryname = (this.source.fullname) ? this.source.name : ''

				} else {

					this.primaryname = this.source.name

				}

				this.initial = this.primaryname.charAt(0)

				this.slug = this.source.slug

				if (this.source.avatar && this.type !== 'game') {

					if (this.source.avatar) {

						if (this.type === 'user' || this.type === 'session' || (this.type === 'context' && this.$store.getters['context/type'] === 'users')) {

							this.backgroundImage = this.$CONSTANTS.ASSETS + '/api/users/avatar/' + this.source.id

						} else {

							this.backgroundImage = this.source.avatar

						}

					}

				} else {

					if (this.type === 'game') {

						this.backgroundImage = this.$util.thumbnail.game[(this.size) ? this.size : 'small'](this.source || this.id)

					}

				}

				if (!this.backgroundImage && this.type !== 'game') {

					var type

					if (this.type === 'context') type = this.$store.getters['context/type']
					if (this.type === 'session') type = this.$CONSTANTS.TYPE.USERS
					if (this.type === 'user') type = this.$CONSTANTS.TYPE.USERS

					if (this.source && !this.$store.getters['util/colors/' + type][this.slug]) {

						this.$store.commit('util/colors/assign', {
							type: type,
							name: this.slug,
							initial: this.initial
						})

					}

					this.backgroundColor = (this.source) ? this.$store.getters['util/colors/' + type][this.slug] : ''

				}

				if (this.backgroundImage) {

					this.backgroundImage = 'url(' + this.backgroundImage + ')'

				} else {

					this.backgroundImage = 'none'

				}

			}

		},

		onClick: function(e) {

			if (this.type === 'user' && !this.overrideClick) {

				this.$router.push({
					name: 'Collection',
					params: {
						type: 'users',
						name: this.slug
					}
				}).catch(function() { })

			} else if (this.type === 'game' && e.shiftKey) {

				this.$util.bgg.open.game(this.id)

			}

		}

	}

}

</script>

<style scoped>

.avatar {
	position: relative;
	background-position: 50% 50%;
	background-size: cover;
	background-color: #fff;
	font-weight: 500;
}

.avatar.is-clickable {
	cursor: pointer;
}

.avatar-initial {
	position: absolute;
	left: 50%;
	top: 50%;
	text-transform: uppercase;
	transform: translate(-50%, -50%);
	color: #fff;
}

</style>
