<template>

<core-list-column :format="format" class="column"></core-list-column>

</template>

<script>

export default {

	props: ['format']

}

</script>

<style scoped>

.column {
	width: 48px;
	flex-shrink: 0;
}

@media only screen and (max-width: 767px) {

	.column {
		display: none!important;
	}

}

</style>