<template>

<div id="app" v-bind:class="{'is-verifying': is.verifying, 'is-ready': is.ready, 'is-not-mobile': !window.is.mobile, 'is-mobile': window.is.mobile, 'is-smaller': window.is.smalldesktop || is.tablet, 'is-tablet': window.is.tablet, 'is-smaller': window.is.tablet || window.is.smalldesktop, 'is-small': window.is.tablet || window.is.smalldesktop || window.is.mobile, 'is-desktop': window.is.desktop}">

	<router-view></router-view> 

	<com-panel />
	<com-modal />
	<com-expand />

	<com-update />

</div>

</template>

<script>

import comPanel from './components/panel/Include'
import comModal from './components/modal/Include'
import comExpand from './components/expand/Include'
import comUpdate from './components/Update'

import axios from 'axios'
import cookies from 'js-cookie'

export default {

	name: 'App',

	components: {
		'com-panel': comPanel,
		'com-modal': comModal,
		'com-expand': comExpand,
		'com-update': comUpdate
	},

	data: function() {

		return {

			lastError: false,

			is: {
				error: false,
				ready: false,
				verifying: false
			}

		}

	},

	computed: {

		error: function() {

			return (this.$CONSTANTS.ERROR[this.lastError] !== undefined) ? this.$CONSTANTS.ERROR[this.lastError] : 'Sorry, an unknown error was encountered.'

		}

	},

	watch: {
	
		$path: function() {

			this.updateTitle()
		
		}

	},

	methods: {
	
		updateTitle: function() {

			var title = []

			if (this.$route.meta.title) title.push(this.$route.meta.title.toLowerCase())

			if (this.$store.getters['context']) {
			
				title.push((this.$store.getters['context'].fullname || this.$store.getters['context'].name ).toLowerCase())
			
			}

			title.push('geekgroup')

			title.push('rocket fuel for bgg collections')
		
			document.title = title.join(' | ')
		
		}

	},

	created: function() {

		this.updateTitle()

		if (cookies.get('token') !== undefined) axios.defaults.headers.common['Authorization'] = cookies.get('token') 

		this.$pubsub.$on('verifying', function() {

			this.is.verifying = true

		}.bind(this))

		this.$pubsub.$on('verified', function() {

			this.is.verifying = false

		}.bind(this))

		this.$pubsub.$on('ready', function() {

			this.is.ready = true
			document.body.classList.add('is-ready')

		}.bind(this))

		this.$pubsub.$on('unready', function() {

			this.is.ready = false
			document.body.classList.remove('is-ready')

		}.bind(this))

		this.$pubsub.$on('error', function(code) {

			if (
				code && 
				code !== this.$CONSTANTS.STATUS.ERROR.RESPONSE.QUEUED && 
				code !== this.$CONSTANTS.STATUS.ERROR.RESPONSE.THROTTLED && 
				code !== this.$CONSTANTS.STATUS.ERROR.SYNC.INPROGRESS && 
				code !== this.$CONSTANTS.STATUS.ERROR.SYNC.NOTALLOWED && 
				code !== this.$CONSTANTS.STATUS.ERROR.GROUP.TAKEN 
			) {

				this.$notify({
					message: this.error,
					type: 'error'
				})

			}

		}.bind(this))

	}

}

</script>

<style>

body {
	display: block;
	min-height: calc(100vh - var(--vh-offset, 0px));
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	overflow: hidden;
	background-color: #fff;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

body.is-ready {
	background-image: none!important;
}

#app {
	position: unset;
	transition: opacity 100ms linear;
	opacity: 1;
	min-height: calc(100vh - var(--vh-offset, 0px));
}

#app.is-ready {
	background-color: #fff;
}

#app.is-verifying {
	opacity: 0.5;
}

.error {
	position: fixed;
	top: -44px;
	left: 0px;
	width: 100%;
	text-align: center;
	background-color: #d06f6f;
	z-index: 10000;
	padding: 10px 15px;
	color: #fff;
	font-weight: 400;
	font-size: 18px;
	transition: all 100ms linear;
}

.error.is-active {
	top: 0px;
}

</style>