<template>

<core-dropdown-level v-if="can.nesting" parent="root" name="nesting" :level="level" v-on:level="$emit('level', 'root')">

	<core-dropdown-group class="nesting-search" :class="{'is-suggesting': suggestions.length}">

		<input type="text" v-model="text" :disabled="is.loading" class="nesting-search-input" placeholder="Search game to nest under..." />

	</core-dropdown-group>

	<core-dropdown-item v-for="suggestion in suggestions" :key="suggestion.id" type="link" :loading="is.loading === suggestion.id" v-on:click.native="onSuggestionClick(suggestion.id)">{{ suggestion.name }}</core-dropdown-item>

</core-dropdown-level>

</template>

<script>

export default {

	props: ['filter', 'item', 'level'],

	data: function() {

		return {
			text: '',
			is: {
				loading: false
			},
			suggestions: []
		}

	},

	computed: {

		can: function() {

			return this.$store.getters['service/select/can']

		}

	},

	watch: {

		text: function() {

			this.onTextChange()

		}

	},

	methods: {

		onSuggestionClick: function(id) {

			this.is.loading = id

			this.$store.dispatch('service/select/nesting/add', {
				override: {
					item: this.item.id
				},
				parent: id
			}).then(function() {

				this.is.loading = false

			}.bind(this))

		},

		onTextChange: function() {

			if (this.text === '') {

				this.suggestions = []

			} else {

				var exclude = [this.item.id]

				if (this.item.collection.nesting.child) {

					this.$_.each(this.item.collection.nesting.official, function(id) {

						exclude.push(id)

					})

				}

				this.$store.dispatch('api/games/suggest', {
					source: 'collection',
					results: 'collection',
					excludeNested: true,
					text: this.text,
					excludeCollection: exclude,
					limit: 10
				}).then(function(json) {

					this.suggestions = []

					this.$_.each(json.suggestions, function(game) {

						this.suggestions.push(game)

					}.bind(this))

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.nesting-search {
	padding: 0px 4px 0px 4px!important;
}

.nesting-search.is-suggesting {
	padding-bottom: 4px!important;
}

.nesting-search-input {
	width: 100%;
    height: 32px;
    color: #fff;
    border-radius: 4px;
    padding: 0px 11px;
    font-weight: 400;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.25);
}

.nesting-search-input::placeholder {
    color: #fff;
    font-weight: 400;
}

</style>