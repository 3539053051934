import domtoimage from 'dom-to-image-more'

export default {

	data: function() {

		return {

			is: {
				sharing: false,
				downloading: false,
				ready: false,
				fetching: false,
				filterChanged: false,
				supported: {
					imageExport: true
				}
			},

			share: {
				url: false,
				download: false,
				twitter: false,
				facebook: false
			}

		}

	},

	computed: {

		filter: function() {

			return this.$store.getters['filter/tools/' + this.toolName + '/filter']

		},

		context: function() {

			return this.$store.getters['context']

		}

	},

	beforeRouteUpdate: function(to, from, next) {

		if (to.params.name !== from.params.name || to.params.type !== from.params.type) {

			next()	

			this.reset()

		} else {

			next()

		}

		// eslint-disable-next-line
		ga('set', 'page', to.fullPath)

		// eslint-disable-next-line
		ga('send', 'pageview')

	},

	beforeRouteEnter: function(to, from, next) {

		next(function(vm) {

			if (vm.filterChanged) {

				vm.fetch()

			}

		})

	},

	watch: {

		context: {

			handler: function(newValue, oldValue) {

				if (newValue.type === oldValue.type && newValue.name === oldValue.name) {

					this.fetch()

				}

			},
			deep: true

		},

		filter: {

			handler: function() {

				this.fetch()
				this.is.filterChanged = true

			},
			deep: true

		}

	},

	created: function() {

		if (document.documentMode || /Edge\//.test(navigator.userAgent)) {
	
			this.is.supported.imageExport = false

		}

		this.reset()
		this.fetch()

	},

	methods: {

		onCustomiseClick: function() {

			this.$pubsub.$emit('tools.' + this.toolName + '.customise')

		},

		onDownloadClick: function() {

			this.is.downloading = true

			this.generateImage()

		},

		onShareClick: function() {

			this.is.sharing = true

			this.generateImage()

		},

		generateImage: function() {

			var node = this.$refs.canvas

			domtoimage.toPng(node).then(function(dataUrl) {

				this.saveImage({
					image: dataUrl,
					width: node.clientWidth,
					height: node.clientHeight
				})

			}.bind(this)).catch(function() {

				this.is.downloading = false
				this.is.sharing = false

			}.bind(this))

		},

		saveImage: function(data) {

			var type = this.$route.params.type
			var name = this.$route.params.name

			this.$store.dispatch('api/share/save', {
				ownerType: type,
				ownerSlug: name,
				type: 'tool.' + this.toolName,
				data: data
			}).then(function(json) {

				if (this.is.sharing) {

					this.$pubsub.$emit('sharing', json)

				} else {

					window.location.href = json.download

				}

				this.is.sharing = false
				this.is.downloading = false

			}.bind(this), function() {

				this.is.sharing = false
				this.is.downloading = false

			}.bind(this))

		},

		reset: function() {

			this.is.ready = false	
			this.$store.commit('filter/tools/' + this.toolName + '/reset')

		},

		fetch: function(type, name) {

			if (this.supported) {

				type = type || this.$route.params.type
				name = name || this.$route.params.name

				this.is.fetching = true

				this.$store.dispatch('api/tools/' + type, {
					name: name,
					tool: this.toolName,
					filter: this.$store.getters['filter/tools/' + this.toolName]
				}).then(function(json) {

					this.is.filterChanged = false
					this.is.ready = true
					this.is.fetching = false

					this.fetched(json)

					this.$pubsub.$emit('ready')

				}.bind(this), function() {

					this.is.fetching = false

				}.bind(this))

			}

		}

	}

}