<template>
	
<core-card size="4" :item="item" :hasActions="true">

	<core-card-head type="game" :id="item.game.id" size="large">

		<app-avatar-include :id="item.game.id" filter="plays" :card="true" />

	</core-card-head>

	<core-card-body :foot="!item.play.comment">

		<com-play-name :item="item" />
		<com-play-stats :item="item" />
		<com-play-players :item="item" />

	</core-card-body>

	<core-card-buttons v-if="item.play.comment" :foot="true">

		<core-card-buttons-button v-on:click.native="onCommentClick">Read report</core-card-buttons-button>

	</core-card-buttons>

</core-card>

</template>

<script>

import playName from './play/Name'
import playPlayers from './play/Players'
import playStats from './play/Stats'

export default {

	components: {

		'com-play-name': playName,
		'com-play-stats': playStats,
		'com-play-players': playPlayers

	},

	props: ['item'],

	methods: {

		onCommentClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('panel.open', {
				type: 'playsComment',
				game: this.item.game,
				play: this.item.play,
				comment: this.item.play.comment
			})

		}

	}

}

</script>

<style scoped>

</style>
