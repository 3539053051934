import _ from 'underscore'
import Util from 'app/util'

export default {

	set: function(state, data) {

		state.data = Util.filter.autofill(data, state.name)

	},

	preset: function(state, id) {

		state.preset = id

	},

	reset: function(state, defaultValues) {

		defaultValues = defaultValues || state.defaultValues

		state.defaultValues = Util.filter.autofill(defaultValues, state.name)
		state.data = Util.filter.autofill(defaultValues, state.name)

		state.preset = 0
		state.changed = false

	},

	sort: function(state, id) {

		var reverse = false

		if (typeof id === 'object') {

			var parts = state.data.sort.split(',')

			if (id.stack) {

				if (state.data.sortReverse && parts.length === 1) {
				
					parts[0] = '-' + parts[0]
				
				}

				if (_.contains(parts, id.value)) {

					parts[parts.indexOf(id.value)] = '-' + id.value
				
				} else if (_.contains(parts, '-' + id.value)) {

					parts[parts.indexOf('-' + id.value)] = id.value
				
				} else {

					parts.push((id.reverse) ? '-' + id.value : id.value)
				
				}

				state.data.sort = parts.join(',')

			} else {

				if (id.reverse !== undefined) {
				
					reverse = id.reverse

				} else if (state.data.sort === id.value && !state.data.sortReverse) {
				
					reverse = true
				
				} 

				state.data.sort = (reverse) ? '-' + id.value : id.value
			
			}

		} else {

			if(state.data.sort === id) reverse = !state.data.sortReverse
			state.data.sort = (reverse) ? '-' + id : id

		}

		state.data.sortReverse = false

	},

	owners: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.owners, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.owners.splice(index, 1)

		if (!remove) state.data.owners.push(id)

	},

	users: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.users, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.users.splice(index, 1)

		if (!remove) state.data.users.push(id)

	},

	languages: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.languages, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.languages.splice(index, 1)

		if (!remove) state.data.languages.push(id)

	},

	designers: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.designers, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.designers.splice(index, 1)

		if (!remove) state.data.designers.push(id)

	},

	publishers: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.publishers, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.publishers.splice(index, 1)

		if (!remove) state.data.publishers.push(id)

	},

	artists: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.artists, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.artists.splice(index, 1)

		if (!remove) state.data.artists.push(id)

	},

	families: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.families, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.families.splice(index, 1)

		if (!remove) state.data.families.push(id)

	},

	categories: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.categories, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.categories.splice(index, 1)

		if (!remove) state.data.categories.push(id)

	},

	subdomains: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.subdomains, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.subdomains.splice(index, 1)

		if (!remove) state.data.subdomains.push(id)

	},

	mechanics: function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.mechanics, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.mechanics.splice(index, 1)

		if (!remove) state.data.mechanics.push(id)

	},

	tags: function(state, data) {

		var index = _.findIndex(state.data.tags, function(value) {

			return Math.abs(value) === Math.abs(data.id)

		})

		if (index >= 0) state.data.tags.splice(index, 1)

		if (data.value) {

			state.data.tags.push(data.value)

		}

	},

	'games/include': function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		console.log(id)

		var index = _.findIndex(state.data.games.include, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.games.include.splice(index, 1)

		if (!remove) state.data.games.include.push(id)

		if (!remove) {
			
			state.data.games.include.push(id)

			if (_.contains(state.data.games.exclude, id)) {

				state.data.games.exclude.splice(state.data.games.exclude.indexOf(id), 1)

			}

		}

		/*
		var ids = (typeof id === 'object') ? id : [id]

		_.each(ids, function(id) {

			if (_.contains(state.data.games.include, id)) {

				state.data.games.include.splice(state.data.games.include.indexOf(id), 1)

			} else {

				state.data.games.include.push(id)

				if (_.contains(state.data.games.exclude, id)) {

					state.data.games.exclude.splice(state.data.games.exclude.indexOf(id), 1)

				}

			}

		})
		*/

	},

	'games/subset/set': function(state, id) {

		state.data.games.subset = []

		_.each(id, function(id) {

			state.data.games.subset.push(id)

		})

	},

	'games/include/add': function(state, id) {

		var ids = (typeof id === 'object') ? id : [id]

		_.each(ids, function(id) {

			if (!_.contains(state.data.games.include, id)) {

				state.data.games.include.push(id)

			}

			if (_.contains(state.data.games.exclude, id)) {

				state.data.games.exclude.splice(state.data.games.exclude.indexOf(id), 1)

			}

		})

	},

	'games/include/remove': function(state, id) {

		var ids = (typeof id === 'object') ? id : [id]

		_.each(ids, function(id) {

			if (_.contains(state.data.games.include, id)) {

				state.data.games.include.splice(state.data.games.include.indexOf(id), 1)

			}

		})

	},

	'games/exclude': function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.games.exclude, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.games.exclude.splice(index, 1)

		if (!remove) {
			
			state.data.games.exclude.push(id)

			if (_.contains(state.data.games.include, id)) {

				state.data.games.include.splice(state.data.games.include.indexOf(id), 1)

			}

		}

		/*
		var ids = (typeof id === 'object') ? id : [id]

		_.each(ids, function(id) {

			if (_.contains(state.data.games.exclude, id)) {

				state.data.games.exclude.splice(state.data.games.exclude.indexOf(id), 1)

			} else {

				state.data.games.exclude.push(id)

				if (_.contains(state.data.games.include, id)) {

					state.data.games.include.splice(state.data.games.include.indexOf(id), 1)

				}

			}

		})*/

	},

	'games/exclude/add': function(state, id) {

		var ids = (typeof id === 'object') ? id : [id]

		_.each(ids, function(id) {

			if (!_.contains(state.data.games.exclude, id)) {

				state.data.games.exclude.push(id)

			}

			if (_.contains(state.data.games.include, id)) {

				state.data.games.include.splice(state.data.games.include.indexOf(id), 1)

			}

		})

	},

	'games/subset': function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.games.subset, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.games.subset.splice(index, 1)

		if (!remove) state.data.games.subset.push(id)

	},

	'play/subset': function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.play.subset, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.play.subset.splice(index, 1)

		if (!remove) state.data.play.subset.push(id)

	},

	'play/locations': function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.play.locations, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.play.locations.splice(index, 1)

		if (!remove) state.data.play.locations.push(id)

	},

	'play/players/required': function(state, id) {

		var remove = (_.isObject(id)) ? id.remove : false
		id = (_.isObject(id)) ? id.id : id

		var index = _.findIndex(state.data.play.players.required, function(value) {

			return Math.abs(value) === Math.abs(id)

		})

		if (index >= 0) state.data.play.players.required.splice(index, 1)

		if (!remove) state.data.play.players.required.push(id)

	},

	'source/all': function(state, value) {

		state.data.source.all = value

	},

	'source/plays': function(state, value) {

		state.data.source.plays = value

	},

	'source/ratings': function(state, value) {

		state.data.source.ratings = value

	},

	'source/collection': function(state, value) {

		state.data.source.collection = value

	},

	text: function(state, text) {

		state.data.text = text

	},

	'comment/text': function(state, text) {

		state.data.comment.text = text

	},

	ownersmin: function(state, value) {

		state.data.ownersmin = value ? 1 : 0

	},

	unplayed: function(state, value) {

		state.data.unplayed = value ? 1 : 0

	},

	soloplayed: function(state, value) {

		state.data.soloplayed = value ? 1 : 0

	},

	unrated: function(state, value) {

		state.data.unrated = value

	},

	'inventory/location/exists': function(state, value) {

		state.data.inventory.location.exists = value

	},

	'inventory/location/text': function(state, value) {

		state.data.inventory.location.text = value

	},

	commented: function(state, value) {

		state.data.commented = value

	},

	limit: function(state, value) {

		state.data.limit = value

	},

	random: function(state, value) {

		state.data.random = value ? 1 : 0

	},

	expansions: function(state, value) {

		state.data.expansions = value

	},

	'wishlist/priority': function(state, data) {

		state.data.wishlist.priority = {
			from: data.from,
			to: data.to
		}

	},

	'count/exact': function(state, value) {

		state.data.count.exact = value ? 1 : 0

	},

	'count/official': function(state, data) {

		state.data.count.official = {
			from: data.from,
			to: data.to
		}

	},

	'count/best': function(state, data) {

		state.data.count.best = {
			from: data.from,
			to: data.to
		}

	},

	'count/recommended': function(state, data) {

		state.data.count.recommended = {
			from: data.from,
			to: data.to
		}

	},

	'worth/value': function(state, data) {

		state.data.worth.value = {
			from: data.from,
			to: data.to
		}

	},

	'worth/play': function(state, data) {

		state.data.worth.play = {
			from: data.from,
			to: data.to
		}

	},

	'trade/type': function(state, value) {

		state.data.trade.type = (value === '0') ? 0 : value

	},

	'trade/status': function(state, value) {

		state.data.trade.status = value

	},

	'status/merge': function(state, value) {

		state.data.status.merge = value ? 1 : 0

	},

	'status/user': function(state, value) {

		state.data.status.user = value ? 1 : 0

	},

	'status/game': function(state, value) {

		state.data.status.game = value ? 1 : 0

	},

	'status/own': function(state, value) {

		state.data.status.own = parseInt(value)

	},

	'status/prevowned': function(state, value) {

		state.data.status.prevowned = parseInt(value)

	},

	'status/fortrade': function(state, value) {

		state.data.status.fortrade = parseInt(value)

	},

	'status/want': function(state, value) {

		state.data.status.want = parseInt(value)

	},

	'status/wanttoplay': function(state, value) {

		state.data.status.wanttoplay = parseInt(value)

	},

	'status/wanttobuy': function(state, value) {

		state.data.status.wanttobuy = parseInt(value)

	},

	'status/wishlist': function(state, value) {

		state.data.status.wishlist = parseInt(value)

	},

	'status/preordered': function(state, value) {

		state.data.status.preordered = parseInt(value)

	},

	'status/hasparts': function(state, value) {

		state.data.status.hasparts = parseInt(value)

	},

	'status/wantparts': function(state, value) {

		state.data.status.wantparts = parseInt(value)

	},

	'disparity/user/group': function(state, data) {

		state.data.disparity.user.group = {
			from: data.from,
			to: data.to
		}

	},

	'disparity/user/community': function(state, data) {

		state.data.disparity.user.community = {
			from: data.from,
			to: data.to
		}

	},

	'disparity/group/community': function(state, data) {

		state.data.disparity.group.community = {
			from: data.from,
			to: data.to
		}

	},

	'rating/user': function(state, data) {

		state.data.rating.user = {
			from: data.from,
			to: data.to
		}

	},

	'rating/group': function(state, data) {

		state.data.rating.group = {
			from: data.from,
			to: data.to
		}

	},

	'rating/community': function(state, data) {

		state.data.rating.community = {
			from: data.from,
			to: data.to
		}

	},

	weight: function(state, data) {

		state.data.weight = {
			from: data.from,
			to: data.to
		}

	},

	year: function(state, data) {

		state.data.year = {
			from: data.from,
			to: data.to
		}

	},

	duration: function(state, data) {

		state.data.duration = {
			from: data.from,
			to: data.to
		}

	},

	'durations/absolute': function(state, value) {
		
		state.data.durations.absolute = (value) ? 1 : 0

	},

	plays: function(state, data) {

		state.data.plays = {
			from: data.from,
			to: data.to
		}

	},

	'collection/exclude/own': function(state, value) {

		state.data.collection.exclude.own = (value) ? 1 : 0

	},

	'collection/exclude/prevowned': function(state, value) {

		state.data.collection.exclude.prevowned = (value) ? 1 : 0

	},

	'collection/added/from': function(state, value) {

		state.data.collection.added.from = value

	},

	'collection/added/to': function(state, value) {

		state.data.collection.added.to = value

	},

	'rated/date/user/from': function(state, value) {

		state.data.rated.date.user.from = value

	},

	'rated/date/user/to': function(state, value) {

		state.data.rated.date.user.to = value

	},

	'rated/date/group/from': function(state, value) {

		state.data.rated.date.group.from = value

	},

	'rated/date/group/to': function(state, value) {

		state.data.rated.date.group.to = value

	},

	'played/type': function(state, value) {

		state.data.played.type = value

	},

	'played/period': function(state, value) {

		state.data.played.period = value

	},

	'played/range/from': function(state, value) {

		state.data.played.range.from = value

	},

	'played/range/to': function(state, value) {

		state.data.played.range.to = value

	},

	'played/first/from': function(state, value) {

		state.data.played.first.from = value

	},

	'played/first/to': function(state, value) {

		state.data.played.first.to = value

	},

	'played/first/edition': function(state, value) {

		state.data.played.first.edition = value

	},

	'played/last/from': function(state, value) {

		state.data.played.last.from = value

	},

	'played/last/to': function(state, value) {

		state.data.played.last.to = value

	},

	'played/last/edition': function(state, value) {

		state.data.played.last.edition = value

	},

	'played/sometime/from': function(state, value) {

		state.data.played.sometime.from = value

	},

	'played/sometime/to': function(state, value) {

		state.data.played.sometime.to = value

	},

	'played/sometime/edition': function(state, value) {

		state.data.played.sometime.edition = value

	},

	comments: function(state, data) {

		state.data.comments = {
			from: data.from,
			to: data.to
		}

	},

	rank: function(state, data) {

		state.data.rank = {
			from: data.from,
			to: data.to
		}

	},

	'language/dependency': function(state, data) {

		state.data.language.dependency = {
			from: data.from,
			to: data.to
		}

	},

	'age/official': function(state, data) {

		state.data.age.official = {
			from: data.from,
			to: data.to
		}

	},

	'age/community': function(state, data) {

		state.data.age.community = {
			from: data.from,
			to: data.to
		}

	},

	'rated/divisive': function(state, data) {

		state.data.rated.divisive = {
			from: data.from,
			to: data.to
		}

	},

	'threshold/notrecommended': function(state, value) {

		state.data.threshold.notrecommended = parseInt(value)

	},

	'threshold/age': function(state, value) {

		state.data.threshold.age = parseInt(value)

	},

	'threshold/language': function(state, value) {

		state.data.threshold.language = parseInt(value)

	},

	'threshold/community': function(state, value) {

		state.data.threshold.community = parseInt(value)

	},

	'threshold/group': function(state, value) {

		state.data.threshold.group = parseInt(value)

	},

	'threshold/plays': function(state, value) {

		state.data.threshold.plays = parseInt(value)

	},

	'threshold/owners': function(state, value) {

		state.data.threshold.owners = parseInt(value)

	},

	'threshold/weight': function(state, value) {

		state.data.threshold.weight = parseInt(value)

	},

	'threshold/hours': function(state, value) {

		state.data.threshold.hours = parseInt(value)

	},

	'threshold/count': function(state, value) {

		state.data.threshold.count = parseInt(value)

	},

	'play/status': function(state, value) {

		state.data.play.status = parseInt(value)

	},

	'play/nolocation': function(state, value) {

		state.data.play.nolocation = value ? 1 : 0

	},

	'play/group': function(state, value) {

		state.data.play.group = value ? 1 : 0

	},

	'play/first': function(state, value) {

		state.data.play.first = value ? 1 : 0

	},

	'play/last': function(state, value) {

		state.data.play.last = value ? 1 : 0

	},

	'play/count': function(state, data) {

		state.data.play.count = {
			from: data.from,
			to: data.to
		}

	},

	'play/users': function(state, data) {

		state.data.play.users = {
			from: data.from,
			to: data.to
		}

	},

	'play/winpercent': function(state, data) {

		state.data.play.winpercent = {
			from: data.from,
			to: data.to
		}

	},

	'play/duration': function(state, data) {

		state.data.play.duration = {
			from: data.from,
			to: data.to
		}

	},

	'play/players/together': function(state, value) {

		state.data.play.players.together = value ? 1 : 0

	},

	'play/reports/required': function(state, value) {

		state.data.play.reports.required = value

	},

	'play/reports/text': function(state, value) {

		state.data.play.reports.text = value

	}

}