<template>

<div class="segments">

	<div class="segments-areas">

		<div v-for="(area, index) in data.areas" :key="index" class="segments-areas-item">

			<div class="segments-areas-item-title">{{ area.title }}</div>
			<div class="segments-areas-item-subtitle" v-if="area.subtitle">{{ area.subtitle }}</div>

		</div>

	</div>

	<div class="segments-dataset">

		<div v-on:click="onSegmentClick(index)" v-on:mouseenter="activeSegment = index" v-on:mouseleave="activeSegment = false" v-for="(point, index) in data.dataset.data" :style="segmentPosition(index)" :key="index" class="segments-dataset-item" :class="{'is-clickable': data.clickable && data.dataset.data[index]}">
			<div class="segments-dataset-item-value" :style="segmentValue(index)"></div>
			<div class="segments-dataset-item-tooltip" v-tooltip="{trigger: 'manual', placement: (index < data.size / 4 || index >= data.size - (data.size / 4) ? 'bottom' : 'top'), show: activeSegment === index, content: segmentTooltip(index)}"></div>
		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['data'],

	data: function() {

		return {
			activeSegment: false
		}

	},

	computed: {

		styles: function() {

			return [
				{
					'--offset': 0,
					'--value': 25
				}
			]

		},

		segmentSize: function() {

			return 360 / this.data.size 

		}

	},

	methods: {

		onSegmentClick: function(index) {

			if (this.data.clickable) this.$emit('click', index)

		},

		segmentTooltip: function(index) {

			var tooltip = this.data.segments[index].label

			tooltip = tooltip.replace('{{value}}', this.data.dataset.data[index].toString())
			tooltip = tooltip.replace('{{value.plural}}', (this.data.dataset.data[index] === 1) ? '' : 's')

			return tooltip

		},

		segmentValue: function(index) {

			var size = (80 / this.$_.max(this.data.dataset.data)) * this.data.dataset.data[index]
			if (this.data.dataset.data[index]) size += 20

			var shade = Math.ceil(size / (100 / this.data.shades.length)) - 1
			shade = (shade < 0) ? 0 : shade
			shade = (shade > this.data.shades.length - 1) ? this.data.shades.length - 1 : shade

			return {
				width: size + '%',
				height: size + '%',
				backgroundColor: (this.data.dataset.backgroundColor) ? this.data.dataset.backgroundColor[index] : this.data.shades[shade]
			}

		},

		segmentPosition: function(index) {

			return {
				transform: 'rotate(' + ((index * this.segmentSize) + (this.segmentSize / 2)) + 'deg)'
			}

		}

	}

}

</script>

<style scoped>

.segments {
	width: 100%;
	height: 100%;
	user-select: none;
}

.segments-areas {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.segments-areas-item {
	position: absolute;
	width: 50%;
	height: 50%;
	padding: 15px;
	flex-direction: column;
	display: flex;
}

.segments-areas-item:nth-child(1) {
	left: 50%;
	top: 0%;
	border-bottom: 1px dashed #eee;
	align-items: flex-end;
}

.segments-areas-item:nth-child(2) {
	left: 50%;
	top: 50%;
	justify-content: flex-end;
	align-items: flex-end;
}

.segments-areas-item:nth-child(3) {
	left: 0%;
	top: 50%;
	border-right: 1px dashed #eee;
	align-items: flex-start;
	justify-content: flex-end;
}

.segments-areas-item:nth-child(4) {
	left: 0%;
	top: 0%;
	border-right: 1px dashed #eee;
	border-bottom: 1px dashed #eee;
}

.segments-areas-item-title {
	font-size: 16px;
	font-weight: 300;
	color: #333;
}

.segments-areas-item-subtitle {
	font-size: 12px;
	font-weight: 300;
	display: block;
	margin-top: 2px;
	color: #999;
}

.segments-dataset {
	position: absolute;
	z-index: 2;
	left: 50%;
	top: 50%;
	margin-left: -144px;
	margin-top: -144px;
	width: 288px;
	height: 288px;
}

.segments-dataset-item {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	clip-path: polygon(60% 0, 41% 0, 50% 50%);
	transform-origin: 50% 50%;
	transition: all 100ms linear;
}

.segments-dataset-item.is-clickable {
	cursor: pointer;
}

.segments-dataset-item:hover {
	background-color: rgba(12, 137, 178, 0.25);
}

.segments-dataset-item-value {
	position: absolute;
	border-radius: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 300ms linear;
}

.segments-dataset-item-tooltip {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -1px;
	margin-top: -1px;
	width: 2px;
	height: 2px;
}

</style>