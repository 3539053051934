<template>

<div class="dropdown-level">

	<slot></slot>

	<core-dropdown-item type="divide" v-if="parent" />

	<core-dropdown-item v-if="parent" type="level" :back="true" v-on:level="$emit('level', parent)">Back</core-dropdown-item>

</div>

</template>

<script>

export default {

	props: ['name', 'parent', 'level']

}

</script>

<style scoped>

.dropdown-level > .dropdown-item.type-divide:first-child,
.dropdown-level > .dropdown-item.type-divide:last-child {
	display: none;
}

</style>
