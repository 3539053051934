import Vue from 'vue'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: {}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		weights: function(state) {

			return state.data.weights || {
				counts: [],
				bands: []
			}

		},

		designers: function(state) {

			return state.data.designers || []

		},
		
		rankings: function(state) {

			return state.data.rankings || false

		},

		publishers: function(state) {

			return state.data.publishers || []

		},

		activity: function(state) {

			return state.data.activity || []

		},

		distribution: function(state) {

			return state.data.distribution || []

		},

		years: function(state) {

			return state.data.years || []

		},

		moreYears: function(state) {

			return state.data.moreYears || false

		},

		disparities: function(state) {

			return state.data.disparities || []

		},

		types: function(state) {

			return state.data.types || []

		},

		trending: function(state) {

			return state.data.trending || []

		},

		overview: function(state) {

			return state.data.overview || false

		},

		plays: function(state) {

			return state.data.plays || []

		}

	},

	mutations: {

		reset: function(state) {

			state.data = {}

		},

		set: function(state, data) {

			_.each(data, function(value, key) {

				Vue.set(state.data, key, value)

			})

		}

	},

	actions: {}

}