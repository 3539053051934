<template>

<core-panel v-if="is.ready">

	<template v-slot:head>

		Comment

	</template>

	<template v-slot:body>

		<div class="comment-head" :style="{backgroundImage: gameAvatar}" :data-band="ratingBand">

			<core-rating :value="data.rating.rating" class="comment-head-value" />

		</div>

		<div class="comment-user">

			<core-avatar type="user" :id="data.rating.user" class="comment-user-avatar" />

			<div class="comment-user-name">

				<div class="comment-user-name-big">{{ realname }}</div>
				<div class="comment-user-name-small" v-if="realname !== username">{{ username }}</div>

			</div>

		</div>

		<p class="comment-text" v-html="comment"></p>

		<div class="comment-info">

			<template v-if="data.rating.date">Rated on {{ data.rating.date | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}</template> 
			<template v-if="!data.rating.date">Initial sync</template> 
			&middot; Commented on {{ data.comment.date | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}

		</div>

	</template>

</core-panel>

</template>

<script>

import Panel from './../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			static: true,
			params: {
				game: false,
				rating: false,
				comment: false
			}
		}

	},

	computed: {

		user: function() {

			return this.$store.getters['data/users/associated'][this.data.rating.user]

		},

		realname: function() {

			return this.user.fullname || this.user.name

		},

		username: function() {

			return this.user.name

		},

		comment: function() {

			return this.data.comment.text

		},

		ratingBand: function() {

			return Math.floor(this.data.rating.rating)

		},

		gameAvatar: function() {

			return 'url(' + this.$util.thumbnail.game.generate(this.data.game.id, 'large') + ')'

		}

	}

}

</script>

<style scoped>

.comment-text {
	font-size: 16px;
	color: #fff;
	line-height: 20px;
	padding: 0px 20px;
}

.comment-head {
	width: 100%;
	height: 160px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	flex-shrink: 0;
	margin-bottom: 20px;
	border-bottom: 5px solid transparent;
}

.comment-head[data-band="10"] { border-color: #249563; }
.comment-head[data-band="9"] { border-color: #249563; }
.comment-head[data-band="8"] { border-color: #2FC482; }
.comment-head[data-band="7"] { border-color: #1d8acd; }
.comment-head[data-band="6"] { border-color: #5369a2; }
.comment-head[data-band="5"] { border-color: #5369a2; }
.comment-head[data-band="4"] { border-color: #df4751; }
.comment-head[data-band="3"] { border-color: #df4751; }
.comment-head[data-band="2"] { border-color: #db303b; }
.comment-head[data-band="1"] { border-color: #db303b; }
.comment-head[data-band="0"] { border-color: #db303b; }

.comment-head-value {
	position: absolute;
	right: 0px;
	bottom: -5px;
	width: 64px;
	height: 64px;
	line-height: 64px;
	font-size: 24px!important;
}

.comment-head-value >>> .rating-count {
	height: 16px;
	line-height: 16px;
	min-width: 16px;
	font-size: 10px;
}

.comment-user {
	display: flex;
	flex-direction: row;
	padding: 0px 20px 20px 20px;
}

.comment-user-avatar {
	width: 48px;
	height: 48px;
	border-radius: 24px;
	flex-shrink: 0;
	margin-right: 20px;
}

.comment-user-name {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.comment-user-name-big {
	width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    color: #fff;
    display: block;
    margin-top: 0px;
    font-size: 20px;
    line-height: 24px;
}

.comment-user-name-small {
	font-size: 12px;
    font-weight: 400;
    color: #fff;
}

.comment-info {
	padding: 20px;
	font-size: 12px;
	color: rgba(255, 255, 255, 0.75);
}

</style>