<template>

<com-item name="collection.dependency" :size="size" :is-blank="true" :is-empty="!active" :loading="loading" title="Language Independence">

	<template v-slot:about>

		<p>See how {{ your }} collection breaks down by language independence.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>
	
		<app-visualisation-accordian :data="accordian" v-on:click="onAccordianClick" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			accordian: {
				noun: 'game',
				labelAsValue: true,
				slimPercent: 25,
				tooltip: 'Click to view games<small>{{value}} {{noun}}</small>',
				values: [],
				labels: [
					'None',
					'Low',
					'Medium',
					'High',
					'Complete'
				],
				keys: [5,4,3,2,1],
				images: [],
				colors: [
					'#9FCBDC',
					'#74B2CD',
					'#5DA7C6',
					'#439ABE',
					'#0c6f91'
				]
			}
		}
		

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/collection/dependencies']

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	created: function() {
	
		this.visualise()	

	},

	methods: {

		onAccordianClick: function(value) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionDependency',
				band: value
			})

		},
	
		visualise: function() {

			this.$_.each(this.data, function(data) {
			
				this.accordian.values.push(data.g)
				this.accordian.images.push(data.f)
			
			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>