<template>

<core-filter-advanced :active="is.expanded" v-on:clickoutside="onOutsideClick" class="import">

	<template v-slot:inputs>

		<core-filter-advanced-title>Setup</core-filter-advanced-title>

		<app-filter-template-common-source filter="list" />

		<core-filter-advanced-title>Games</core-filter-advanced-title>

		<app-filter-template-games-general filter="list" />

		<app-filter-template-games-count filter="list" />

		<app-filter-template-games-language filter="list" />

		<app-filter-template-games-age filter="list" />

		<core-filter-advanced-title>Collection</core-filter-advanced-title>

		<app-filter-template-collection-general filter="list" />
		
		<app-filter-template-collection-status filter="list" v-if="isSourceCollection" />

		<app-filter-template-collection-owners filter="list" v-if="isSourceCollection" />

		<core-filter-advanced-title>Ratings</core-filter-advanced-title>

		<app-filter-template-collection-user filter="list" />
			
		<app-filter-template-collection-group filter="list" />

		<app-filter-template-collection-community filter="list" />
			
		<app-filter-template-collection-comments filter="list" />

		<core-filter-advanced-title>Plays</core-filter-advanced-title>

		<app-filter-template-collection-plays filter="list" />
			
		<core-filter-advanced-title>Other</core-filter-advanced-title>
			
		<app-filter-template-common-designers filter="list" />
			
		<app-filter-template-common-artists filter="list" />
			
		<app-filter-template-common-publishers filter="list" />
			
		<app-filter-template-common-categories filter="list" />
			
		<app-filter-template-common-mechanics filter="list" />
			
		<app-filter-template-common-subdomains filter="list" />

		<app-filter-template-common-families filter="list" />

	</template>

	<template v-slot:buttons>

		<core-button v-if="window.is.mobile" v-on:click.native="onSuggestionsClick" theme="blue" :disabled="!is.suggesting" fluid="yes">View candidates ({{is.suggesting}})</core-button>

		<core-button v-if="window.is.mobile" v-on:click.native="onOutsideClick" theme="blue" fluid="yes">Close import</core-button>

	</template>

</core-filter-advanced>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				expanded: false,
				suggesting: 0
			}

		}

	},

	computed: {

		isSourceCollection: function() {

			return this.$store.getters['filter/list/source/collection']

		},

		list: function() {

			return this.$store.getters['page/list/list']

		},

		filter: function() {

			return this.$store.getters['filter/list']

		},

		changed: function() {

			return this.$store.getters['filter/list/changed']

		},

		isLive: function() {

			return this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.LIVE

		}

	},

	created: function() {

		this.$pubsub.$on('list.import.suggestions.count', this.onListImportSuggestionsCount.bind(this))
		this.$pubsub.$on('list.import.expand', this.onListImportExpand.bind(this))
		this.$pubsub.$on('list.import.close', this.onListImportClose.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('list.import.suggestions.count', this.onListImportSuggestionsCount.bind(this))
		this.$pubsub.$off('list.import.expand', this.onListImportExpand.bind(this))
		this.$pubsub.$off('list.import.close', this.onListImportClose.bind(this))

	},

	methods: {

		onListImportSuggestionsCount: function(count) {

			this.is.suggesting = count

		},

		onListImportExpand: function() {

			this.is.expanded = true

		},

		onListImportClose: function() {

			this.close()

		},

		onSuggestionsClick: function() {

			this.$pubsub.$emit('list.import.suggestions')

		},

		close: function() {

			if (this.is.expanded) {

				this.$pubsub.$emit('list.import.collapse')
				this.$store.commit('filter/list/reset')
				this.is.expanded = false
				this.is.suggesting = 0
			
			}

		},

		onOutsideClick: function() {

			this.close()

		}

	}

}

</script>

<style scoped>

.import h1 {
	color: #fff;
	font-size: 16px;
	padding-bottom: 8px;
	padding-left: 15px;
	padding-top: 8px;
}

@media only screen and (max-width: 767px) {

	.import h1 {
		padding-left: 8px;
	}

}

</style>