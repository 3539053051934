<template>
	
<core-list-cell :format="format" type="stat" class="worth" :condensed="condensed" :empty="!primaryValue">
				
	<div class="worth-primary" v-html="formatcurrency(primaryValue)" v-if="primaryValue"></div>

	<div class="worth-secondary" v-tooltip="'Estimated value without specific version'" v-html="formatcurrency(secondaryValue)" v-if="secondaryValue && secondaryValue !== primaryValue"></div>

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format', 'condensed'],

	computed: {

		primaryValue: function() {

			if (this.format.plays) {

				if (this.item.user.plays) {

					return (this.item.game.version) ? this.item.game.version.worth.value  / this.item.user.plays : this.item.game.worth.value / this.item.user.plays

				} else {

					return 0

				}

			} else {

				return (this.item.game.version) ? this.item.game.version.worth.value : this.item.game.worth.value

			}

		},

		secondaryValue: function() {

			/* if (this.format.plays) {

				if (this.item.user.plays) {

					return this.item.game.worth.value / this.item.user.plays

				} else {

					return 0

				}

			} else {

				return this.item.game.worth.value

			}*/

			return false

		}

	}

}

</script>

<style scoped>

.worth {
	/* flex-direction: column!important; */
}

.worth >>> span.currency {
	font-size: 11px;
	color: #666;
}

.worth-secondary {
	font-size: 11px;
	opacity: 0.5;
	margin-top: 4px;
}

</style>
