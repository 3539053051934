import collection from './api/collection'
import activity from './api/activity'
import insights from './api/insights'
import plays from './api/plays'
import lists from './api/lists'
import list from './api/list'
import ratings from './api/ratings'

import session from './api/session'
import account from './api/account'

import users from './api/users'
import groups from './api/groups'
import buddies from './api/buddies'

import app from './api/app'
import presets from './api/presets'
import tags from './api/tags'
import select from './api/select'
import config from './api/config'
import tools from './api/tools'
import custom from './api/custom'
import exporting from './api/export'
import actions from './api/actions'

import designers from './api/designers'
import languages from './api/languages'
import families from './api/families'
import players from './api/players'
import publishers from './api/publishers'
import games from './api/games'
import artists from './api/artists'
import mechanics from './api/mechanics'
import subdomains from './api/subdomains'
import locations from './api/locations'
import misc from './api/misc'
import categories from './api/categories'
import share from './api/share'

export default {

	namespaced: true,

	modules: {

		collection: collection,
		activity: activity,
		plays: plays,
		insights: insights,
		lists: lists,
		list: list,
		tools: tools,
		ratings: ratings,
		
		session: session,
		account: account,

		users: users,
		groups: groups,
		buddies: buddies,

		app: app,
		presets: presets,
		tags: tags,
		select: select,
		config: config,
		custom: custom,
		export: exporting,
		actions: actions,
		
		families: families,
		designers: designers,
		players: players,
		publishers: publishers,
		artists: artists,
		games: games,
		mechanics: mechanics,
		subdomains: subdomains,
		languages: languages,
		locations: locations,
		misc: misc,
		categories: categories,
		share: share

	},

	state: {},

	getters: {},

	mutations: {},

	actions: {}

}