<template>
	
<div class="card-buttons-button">

	<div class="card-buttons-button-text">

		<slot></slot>

	</div>

	<span class="card-buttons-button-count" v-if="count">{{ count }}</span>

	<i class="fa fa-caret-right"></i>

</div>

</template>

<script>

export default {

	props: ['count']
	
}

</script>

<style scoped>

.card-buttons-button {
	border-top: 1px solid #ddd;
	line-height: 32px;
	color: #333;
	font-weight: 300;
	text-align: left;
	font-size: 14px;
	padding: 0px 10px;
	cursor: pointer;
}

.card-buttons-button-count {
	position: absolute;
	right: 36px;
	top: 6px;
	background-color: #999;
	color: #fff;
	font-size: 12px;
	display: block;
	height: 20px;
	line-height: 20px;
	text-align: center;
	font-weight: 500;
	min-width: 20px;
	border-radius: 4px;
}

.card-buttons-button .fa {
	position: absolute;
	right: 10px;
	top: 0px;
	line-height: 32px;
	font-size: 16px;
	color: #333;
}

.is-desktop .card-buttons-button:hover {
	background-color: #eee;
}

</style>
