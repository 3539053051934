<template>

<com-item name="ratings.overview" :size="size" :is-overview="4" :loading="loading" :is-empty="!active" title="Overview">

	<template v-slot:about>

		<p>A summary of {{ your }} ratings.</p>

	</template>

	<template v-slot:stats>

		<com-overview label="Games Rated" type="number" :value="data.total" />

		<com-overview label="Avg. Rating" type="rating" :value="data.average" />

		<com-overview label="% of Collection Rated" type="percent" :value="data.collectionRated" />

		<com-overview label="% of Played Rated" type="percent" :value="data.playRated" />

		<com-overview label="Avg. Plays Before Rated" type="number" :value="data.playFirst" />

		<com-overview label="Avg. First Rating" type="rating" :value="data.averageFirst" />

		<com-overview label="Avg. Plays Before Updated" type="number" :value="data.playChange" />

		<com-overview label="Avg. Second Rating" type="rating" :value="data.averageSecond" />

		<com-overview label="% of Ratings Updated" type="percent" :value="data.changePercent" />

		<com-overview label="Rating Increases" type="number" :value="data.changeUp" />

		<com-overview label="Rating Decreases" type="number" :value="data.changeDown" />

		<com-overview label="Avg. Rating Change" type="number" :value="data.changeAvg" :auto-caret="true" :auto-theme="true" />

	</template>

</com-item>

</template>

<script>

import comOverview from './../common/Overview'
import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-overview': comOverview
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/overview']

		},

		active: function() {

			return this.data && !this.loading

		}

	}

}

</script>

<style scoped>

</style>