<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary title="Tools" icon="tools" :count="count" noun="tool"></core-filter-summary>

	</core-filter-bar-left>

</core-filter-bar>

</template>

<script>

export default {

	props: ['count']

}

</script>

<style scoped>

</style>
