<template>

<core-filter-advanced-group title="BGG ratings" :count="count">

	<core-filter-input-range text="Min. votes" min="0" max="1000" :filter="value('threshold/community')" step="25" pips="100" decimals="0" density="10" :tooltip="true" />

	<core-filter-input-range text="Rating" min="0" max="10" :tooltip="true" :filter="value('rating/community')" step="0.1" pips="1" decimals="1" density="9" :toggle="true" />

	<core-filter-input-range text="Rank" min="0" max="1000" :tooltip="true" :filter="value('rank')" step="25" pips="100" decimals="0" density="10" maxText="Any" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['threshold.community', 'rating.community', 'rank']
		}
	}

}

</script>