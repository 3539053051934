<template>

<div class="row" :data-size="size" :class="{'is-wrap': wrap}">

	<slot></slot>	

</div>

</template>

<script>

export default {

	name: 'row',

	props: ['grow', 'shrink', 'widget', 'wrap', 'size']

}

</script>

<style scoped>

.row {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 1fr 1fr;
}

.row[data-size="3"] {
	grid-template-columns: 1fr 1fr 1fr;
}

.is-mobile .row,
.is-mobile .row[data-size="3"] {
	grid-template-columns: 1fr;
}

</style>