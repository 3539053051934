<template>

<core-list-column :format="format" type="stat" class="column"><i class="fa fa-weight-hanging"></i></core-list-column>

</template>

<script>

export default {

	props: ['format']

}

</script>

<style scoped>

.column {
	width: 90px;
	flex-shrink: 0;
	text-align: center;
}

@media only screen and (min-width: 769px) and (max-width: 1119px) {

	.column {
		width: 60px;
	}

}

@media only screen and (max-width: 767px) {

	.column {
		display: none!important;
	}

}

</style>