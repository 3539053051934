<template>

<com-item name="shelf.size" :size="size" :loading="loading" title="Shelf Size" :is-empty="!active">

	<template v-slot:about>

		<p>{{ your | capitalise }} changes in shelf size over the past 12 months.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-bars :data="graph" v-if="active" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			graph: {
				datasets: [
					{ type: 'bar', data: [], yAxis: 'left', backgroundColor: '#3bce5a', tooltip: '{{value}} <small>Game{{value.plural}} added</small>' },
					{ type: 'bar', data: [], yAxis: 'left', backgroundColor: '#68B1C8', tooltip: '{{value}} <small>Game{{value.plural}} played</small>' },
					{ type: 'bar', data: [], yAxis: 'left', backgroundColor: '#e27570', tooltip: '{{value}} <small>Game{{value.plural}} removed</small>' },
					{ type: 'point', data: [], yAxis: 'left', backgroundColor: '#217EA8', tooltip: '{{value}} {{change}} <small>Shelf size</small>', lineColor: '#217EA8', shadeColor: '#d1f1ff' },
					{ type: 'point', data: [], yAxis: 'right', dataType: 'percent', backgroundColor: '#ffa621', tooltip: '{{value}} {{change}} <small>Shelf % of collection</small>', lineColor: '#ffa621', shadeColor: '#ffc773' }
				],
				axis: {
					x: {
						labels: [],
						type: 'month'
					},
					y: {
						left: {
							type: 'scale',
							min: 10,
							points: 5
						},
						right: {
							type: 'scale',
							min: 100,
							points: 5
						}
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'activity.expansions' || this.fetching === 'activity.period'

		},

		activity: function() {

			return this.$store.getters['page/insights/shelf/activity']

		},

		active: function() {

			return this.$_.keys(this.activity).length && !this.loading

		}

	},

	watch: {

		activity: function() {

			this.visualise()

		}

	},

	created: function() {

		this.visualise()

	},

	methods: {

		visualise: function() {

			if (this.$_.keys(this.activity).length) {

				this.graph.axis.x.labels = []
				this.graph.datasets[0].data = []
				this.graph.datasets[1].data = []
				this.graph.datasets[2].data = []
				this.graph.datasets[3].data = []
				this.graph.datasets[4].data = []

				this.$_.each(this.activity, function(activity) {

					this.graph.axis.x.labels.push(activity.month)
					this.graph.datasets[0].data.push(activity.added)
					this.graph.datasets[1].data.push(activity.played)
					this.graph.datasets[2].data.push(activity.removed)
					this.graph.datasets[3].data.push(activity.size)
					this.graph.datasets[4].data.push(activity.percent)

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

</style>