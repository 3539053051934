<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Register

	</template>

	<template v-slot:default>

		<p>Register to manage your data. Enter your BGG username below and you will automatically receive a GeekMail with a link to complete your registration.</p>

        <core-form-input-textbox name="verifyname" v-on:validate="validate" placeholder="Enter BGG username" v-model="inputs.verifyname" :error="is.error" :disabled="is.success || is.loading" :validation="validation.verifyname" v-on:enter="onVerifyClick"></core-form-input-textbox>
        
        <div class="buttons">
            <core-button v-on:click.native="onVerifyClick" :loading="is.loading" :success="is.success" :disabled="!is.valid">{{ buttonText }}</core-button>
		</div>

        <h2>Why register?</h2>

		<p>You can already view your data, but by registering you can also;</p>

		<ul>
			<li>Create and assign custom tags</li>
			<li>Create and manage custom lists</li>
			<li>Save and load filter presets</li>
			<li>Create and join groups with other BGG users</li>
			<li>View your GeekBuddies combined collection</li>
			<li>Support the app by using the tipjar and get perks</li>
			<li>And much more!</li>
		</ul>

		<p>It takes just a few seconds!</p>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false,
				success: false,
				error: false
			},

			inputs: {
				verifyname: ''
			},

			validation: {
				verifyname: { exists: true }
			}

		}

	},

	computed: {

		buttonText: function() {

			return (this.is.success) ? 'GeekMail sent' : 'Verify'

		}

	},

	methods: {

		onVerifyClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/account/verify', {
					name: this.inputs.verifyname
				}).then(function() {

					this.is.error = false
					this.is.success = true
					this.is.loading = false

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.buttons {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
}

</style>