<template>

<core-filter-advanced-group title="Inventory" :count="count" v-if="isSelf">

	<core-filter-input-text text="Location" subtext="Use a Deep Sync to get your inventory location data for your collection. You can choose to make this data public too in your Settings." placeholder="Search locations..." :filter="value('inventory/location/text')" class="text" :inset="true" />

	<core-filter-input-toggles text="Has location" :values="exists.values" :icons="exists.icons" :labels="exists.labels" :filter="value('inventory/location/exists')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['inventory.location.text', 'inventory.location.exists'],
			exists: {
				labels: ['Yes', 'No', 'All'],
				icons: ['', '', '', ''],
				values: [1, 2, 0]
			}
		}
	}

}

</script>

<style scoped>

.text {
	padding: 0px!important;
}

</style>