<template>
	
<div class="row" v-bind:class="{'is-info': info, 'is-selected': isSelected}">

	<div class="row-fields">

		<slot name="fields"></slot>

	</div>

	<div class="row-info" v-if="window.is.mobile || showInfo">

		<slot name="info"></slot>

	</div>

	<div class="row-expand">

		<slot name="expand"></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['id', 'info', 'showInfo'],

	computed: {

		isSelected: function() {

			return this.$store.getters['service/select'](this.id)

		}

	}

}

</script>

<style scoped>

.row {
	width: 100%;
	padding: 0px 7px;
	border-bottom: 1px solid #eee;
}

.row.is-selected {
	border-color: #fff;
	background-color: #d4f2ff;
}

.is-desktop .row:hover {
	background-color: #e9f3f7;
}

.is-desktop .row.is-selected:hover {
	background-color: #c6e5f3;
}

.row-fields {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	min-height: 63px;
	padding-bottom: 8px;
	padding-top: 7px;
}

.row-info {
	width: 100%;
	display: flex;
	flex-direction: column;
}

</style>
