<template>

<core-filter-advanced-group title="Users" :count="count" v-if="(isGroup || isBuddies) && users.length > 1">

	<p>Use this filter to only see data from a subset of users, excluding all games, plays, and ratings by other users in the group.</p>

	<core-filter-input-suggest v-if="users.length > 12" placeholder="Start typing a username..." field="name" :filter="value('users')" api="users/suggest" data="users" />

	<core-filter-input-users v-if="users.length <= 12 && dataUsers.length" text="Required users" :filter="value('users')" :users="users" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['users']
		}
	},

	computed: {

		dataUsers: function() {

			return this.$store.getters['data/users/index']

		},

		users: function() {

			return this.$store.getters['context/users']

		}

	}

}

</script>