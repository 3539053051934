<template>

<div class="context" :class="{'is-inline': isInline}" v-tooltip="'Click to return to profile'" v-on:click="onClick">

    <core-avatar type="session" class="context-avatar" :tooltip="false">
        <core-flag :id="country" class="context-flag" v-tooltip="countryName" v-if="!isInline" />
    </core-avatar>

    <div class="context-info" v-if="!isInline || window.is.mobile">
        <div class="context-name">{{ realname }}</div>
        <div class="context-username">@{{ username }}</div>
    </div>

    <core-button v-on:click.native="onMenuOpenClick" theme="darkblue" icon="bars" class="context-action" v-if="!window.is.desktop && isInline" />

    <core-button v-on:click.native="onMenuCloseClick" theme="darkblue" icon="xmark" class="context-action" v-if="!window.is.desktop && !isInline" />

</div>

</template>

<script>

export default {

    props: ['isInline'],

    computed: {

		username: function() {

			return this.$store.getters['session'].name 

		},

		realname: function() {

			return this.$store.getters['session'].fullname || this.$store.getters['session'].name 

		},

		country: function() {

			return this.$store.getters['session'].country

		},

		countryName: function() {

			return this.$CONSTANTS.COUNTRY[this.$store.getters['session'].country]

		}

	},

    methods: {

        onClick: function() {
        
            this.$router.push({
				name: 'Collection',
				params: {
					name: this.$store.getters['session'].slug,
					type: this.$CONSTANTS.TYPE.USERS
				}
			}).catch(function() {})

        },

        onMenuOpenClick: function() {
        
            this.$pubsub.$emit('menu.open')
        
        },

        onMenuCloseClick: function() {
        
            this.$pubsub.$emit('menu.close')

        }

    }
        
}

</script>

<style scoped>

.context {
    padding: 20px;
    background-color: #19455c;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.is-desktop .context:hover {
    background-color: #133749;
}

.is-mobile .context {
    padding: 10px 10px;
    height: auto;
}

.is-minimised .context {
    padding: 0px;
}

.is-smaller .context.is-inline {
    position: fixed;
    height: 64px;
    top: 64px;
    left: 0px;
    width: 142px;
    padding: 0px 20px 0px 14px;
    z-index: 100;
	transition: margin-left 100ms linear;
}

.is-mobile .context.is-inline {
    position: fixed;
    height: 48px;
    top: 48px;
    left: 0px;
    padding: 0px 10px;
    width: 100%;
    z-index: 100;
}

.context:not(.is-inline):after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #19455c transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    left: 27px;
    bottom: -10px;
}

.is-desktop .context:not(.is-inline):hover:after {
    border-color: #133749 transparent transparent transparent;
}

.is-minimised .context:after {
    display: none;
}

.context-avatar {
    width: 48px;
    height: 48px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 50%;
}

.is-smaller .context.is-inline .context-avatar {
    width: 40px;
    height: 40px;
    margin: 0px 4px 0px 14px;
}

.is-mobile .context.is-inline .context-avatar {
    width: 32px;
    height: 32px;
    margin: 0px 4px 0px 0px;
}

.is-minimised .context-avatar {
    border-radius: 0px;
}

.is-mobile .context-avatar {
    margin-right: 10px;
}

.context-info {
    margin-left: 20px;
    flex-grow: 1;
}

.is-mobile .context-info {
    margin-left: 6px;
}

.is-minimised .context-info {
    display: none;
}

.context-name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
}

.is-mobile .context-name {
    font-size: 16px;
    letter-spacing: 0px;
}

.context-username {
    font-size: 14px;
    font-size: 400;
    letter-spacing: 1px;
    margin-top: 4px;
    color: #fff;
}

.is-smaller .context-username {
    margin-top: 4px;
    font-size: 14px;
}

.is-mobile .context-username {
    margin-top: 2px;
    font-size: 12px;
}

.context-flag {
    width: 16px;
    height: 16px;
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    right: -3px;
    bottom: -3px;
}

.is-minimised .context-flag {
    display: none;
}

.context-action {
    width: 40px;
    margin-left: 5px;
    flex-shrink: 0;
}

.context-action >>> .button-icon {
    margin-left: 0px;
}

.is-mobile .context-action {
    width: 32px;
    min-width: 32px;
    height: 32px;
}

</style>
