import Store from 'app/store'
import Vue from "vue"

export default {

	computed: {

		$path: function() {
		
			return this.$route.path

		},

		isProfile: function() {

			return this.$route.name !== 'Home'

		},

		isSelf: function() {

			return (Store.getters['session/user/id'] === Store.getters['context/id']) && Store.getters['context/is/user']

		},

		isUser: function() {

			return Store.getters['context/is/user']

		},

		isGroup: function() {

			return Store.getters['context/is/group']

		},

		isBuddies: function() {

			return Store.getters['context/is/buddies']

		},

		isGroupOrBuddies: function() {

			return Store.getters['context/is/buddies'] || Store.getters['context/is/group']

		},

		hasMe: function() {

			return Store.getters['permissions/is/member'] || Store.getters['permissions/is/owner']

		},

		isOwner: function() {

			return Store.getters['permissions/is/owner']

		},

		isMember: function() {

			return Store.getters['permissions/is/member']

		},

		loggedin: function() {

			return Store.getters['session/loggedin']

		}

	},

	methods: {

		$notify: function(config) {

			if (this.$_.isArray(config.message)) config.message = config.message.join(' ')

			config.message = config.message.replace(' .', '.')

			Vue.$toast.open(config)

		},

		formatcurrency: function(value, decimals, pure) {

			return this.$options.filters.currency(Math.abs(value), decimals, pure)

		},

		$filter: function(name, value, param) {

			return this.$options.filters[name](value, param)

		}

	}

}