<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary title="Activity" icon="calendar" :count="count" :maximum="maximum" noun="result" :loading="loading" />

	</core-filter-bar-left>

	<core-filter-bar-right>

		<core-filter-panel filter="activity" v-on:expandClick="onExpandClick" />

		<core-filter-presets type="activity" />

	</core-filter-bar-right>

	<core-filter-advanced :active="is.expanded" v-on:clickoutside="onOutsideClick">

		<template v-slot:inputs>

			<app-filter-template-activity-general filter="activity" />

			<app-filter-template-activity-types filter="activity" />

			<app-filter-template-activity-status filter="activity" v-if="!filter.hide.status.added || !filter.hide.status.removed" />

			<app-filter-template-common-members filter="activity" />

		</template>

		<template v-slot:buttons>
	
			<core-button v-on:click.native="onResetClick" theme="blue" fluid="yes">Reset filter</core-button>

			<core-button v-if="window.is.mobile" v-on:click.native="onOutsideClick" theme="blue" fluid="yes">Close filter</core-button>

		</template>

	</core-filter-advanced>

</core-filter-bar>

</template>

<script>

export default {

	props: ['count', 'loading', 'maximum'],

	data: function() {

		return {

			is: {
				expanded: false
			}

		}

	},

	computed: {

		filter: function() {

			return this.$store.getters['filter/activity']

		}

	},

	methods: {

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onResetClick: function() {

			this.$store.commit('filter/activity/reset')

		}

	}

}

</script>

<style scoped>

</style>
