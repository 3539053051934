<template>
	
<div class="card" :class="{'is-selected': isSelected, 'has-actions': hasActions}">

	<slot></slot>
	
	<core-list-cell-actions v-if="hasActions" :item="item" filter="plays" :horizontal="true" class="card-actions" />

</div>

</template>

<script>

export default {

	props: ['item', 'size', 'hasActions'],

	computed: {

		sizeClass: function() {

			return 'size-' + this.size

		},

		isSelected: function() {

			return (this.hasActions) ? this.$store.getters['service/select'](this.item.id) : false

		}

	}

}

</script>

<style scoped>

.card {
	display: flex;
	flex-direction: column;
	grid-column: span 1;
}

.card-actions {
	position: absolute;
	top: 0px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 0px 4px 0px 4px;
}

.is-desktop .card:hover .card-actions {
	background-color: rgba(0, 0, 0, 0.65);
}

</style>
