<template>
	
<div class="cell" :sortindex="(sortingCount > 1 || format.sort !== 'name' || isSortedReverse) ? isSorted : 0" v-bind:class="{[typeClass]: type, 'is-empty': empty, 'is-condensed': condensed}">
	
	<div class="cell-subwrapper"><slot name="default" v-if="!empty"></slot></div>

	<template v-if="empty">n/a</template>

	<core-list-cell-actions v-if="type === 'actions'">

		<slot name="actions"></slot>

	</core-list-cell-actions>

</div>

</template>

<script>

export default {

	props: ['type', 'format', 'empty', 'condensed'],

	computed: {
	
		sortingCount: function() {
		
			return this.$util.sortcount((this.format.filter) ? this.$store.getters['filter/' + this.format.filter] : false)

		},

		isSorted: function() {

			return this.$util.sorted(this.format.sort, (this.format.filter) ? this.$store.getters['filter/' + this.format.filter] : false) 

		},

		isSortedReverse: function() {

			return this.$util.sorted(this.format.sort, (this.format.filter) ? this.$store.getters['filter/' + this.format.filter] : false, true) 

		},

		typeClass: function() {

			return (this.type) ? 'type-' + this.type : false

		}

	}

}

</script>

<style scoped>

.cell {
	font-size: 14px;
}

.cell > * {
	z-index: 1;
}

@media only screen and (max-width: 1119px) {

	.cell {
		font-size: 14px;
	}

}

.cell-subwrapper {
	width: 100%;
}

.cell.type-stat {
	width: 80px;
	flex-shrink: 0;
	max-height: 48px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.cell.type-stat .cell-subwrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

}

.cell.type-stat.is-condensed {
	height: 24px;
}

.is-mobile .cell.type-stat {
	width: 64px;
}

.cell.type-avatar {
	width: 48px;
	flex-shrink: 0;
	text-align: center;
}

.cell.type-avatar .cell-subwrapper {
	text-align: center;
}

.cell.type-rating {
	width: 64px;
	flex-shrink: 0;
	text-align: center;
	padding: 0px 8px;
}

.cell.type-rating .cell-subwrapper {
	text-align: center;
}

.is-mobile .cell.type-rating {
	width: 48px;
	padding: 0px;
}

.is-mobile .cell.type-rating {
	margin-left: 1px;
}

.cell.type-date {
	width: 80px;
	flex-shrink: 0;
	line-height: 48px;
	display: flex;
	height: 48px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cell.type-date .cell-subwrapper {
	display: flex;
	height: 48px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cell.type-date.is-condensed {
	height: 24px;
	line-height: 24px;
}

.is-mobile .cell.type-date {
	width: 64px;
}

.cell.type-name {
	flex-grow: 1;
	padding-left: 8px;
	padding-right: 15px;
	min-height: 48px;
	min-width: 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: center;
	text-align: left;
	flex-direction: column;
}

.cell.type-name.is-condensed {
	min-height: 24px;
}

.cell.type-actions {
	width: 48px;
}

.cell.type-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: left;
	line-height: 48px;
}

.cell.type-text.is-condensed {
	line-height: 24px;
}

.cell.is-empty {
	color: #ccc;
	line-height: 48px;
}

.cell.is-empty.is-condensed {
	line-height: 24px;
}

.cell:not([sortIndex="0"]):before {
	display: block;
	content: '';
	position: absolute;
	top: -8px;
	bottom: -8px;
	left: 0px;
	right: 0px;
	z-index: 0;
}

.cell[sortIndex="1"]:before {
	background-color: #eff9fe;
}

.cell[sortIndex="2"]:before {
	background-color: #f3fbee;
}

.cell[sortIndex="3"]:before {
	background-color: #fcefef;
}

.cell[sortIndex="4"]:before {
	background-color: #fff1fe;
}

.cell[sortIndex="5"]:before {
	background-color: #ffe6cd;
}

</style>
