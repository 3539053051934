<template>

<core-content-wrapper class="sync">

	<div class="sync-message">

		<div class="sync-logo" />

		<h2>Syncing data</h2>

		<p v-if="isUser">Hi there! As this is the first time this profile has ever been viewed, please wait as we sync the collection, ratings, plays, and other related data. It might take a while...</p>

		<p v-if="isBuddies && !is.nobuddies">Hi there! As this is either the first time your GeekBuddies has been viewed, or new buddies have been added recently, please wait while we sync the collection, ratings, plays, and other related data. It might take a long time depending on how many buddies have never been synced before, you can close this page and the sync will still continue.</p>

		<p v-if="isBuddies && is.nobuddies">Sorry, you do not have any GeekBuddies.</p>

		<core-button v-if="is.nobuddies" theme="yellow" v-on:click.native="onClick">Go to home</core-button>

		<div class="sync-progress" v-if="is.syncing">
			<div class="sync-progress-bar" v-bind:style="{'width': progress}">{{ progress }}</div>
			<div class="sync-progress-message">Synchroned <b>{{ buddies.completed }}</b> <small> of {{ buddies.total }}</small></div>
		</div>

		<p v-if="is.failed"><b>Something went wrong, it is possible BGG or its API is currently offline. Please try again later.</b></p>

		<core-button v-if="is.failed" theme="yellow" v-on:click.native="onClick">Go to home</core-button>

	</div>

</core-content-wrapper>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				failed: false,
				syncing: false,
				nobuddies: false
			},
			buddies: {
				total: 0,
				completed: 0
			}
		}

	},

	computed: {

		progress: function() {

			return (this.buddies.total) ? ((100 / this.buddies.total) * this.buddies.completed).toFixed(0).toString() + '%' : '0%'

		}

	},

	created: function() {

		this.initiate()

	},

	methods: {

		initiate: function() {

			this.is.failed = false

			this.$store.dispatch('api/' + this.$route.params.type + '/sync', {
				type: this.$route.params.type,
				name: this.$route.params.name
			}).then(function(json) {

				if (this.isBuddies) {

					this.is.syncing = true
					this.buddies.total = json.total
					this.buddies.completed = json.completed
					
				}

				this.$router.push({
					name: 'Collection',
					params: {
						type: this.$route.params.type,
						name: this.$route.params.name
					}
				})

			}.bind(this), function(json) {

				this.is.syncing = false

				if (json.error === this.$CONSTANTS.STATUS.ERROR.USER.NOBUDDIES) {

					this.is.nobuddies = true

				} else {

					this.is.failed = true

				}

			}.bind(this))

		},

		onClick: function() {

			this.$router.push({
				name: 'Home'
			}).catch(function(e) { console.log(e) })

		}

	}

}

</script>

<style scoped>

.sync {
	display: flex;
	justify-content: center;
	align-items: center;
}

.sync-logo {
	margin: 0px auto 32px auto;
    width: 128px;
    height: 128px;
    background-size: auto 112px;
    background-position: calc(50% - 1px) calc(50% + 1px);
    background-repeat: no-repeat;
    background-image: url(~@/monty/assets/logo.svg);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transform: scale(1);
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.9);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.sync-message {
	width: 480px;
	text-align: center;
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.sync-message {
		width: 100%;
		padding: 0px 8px;
		text-align: center;
		color: #fff;
	}

}

.sync-message h2 {
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 20px;
}

.sync-message p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 20px;
}

.sync-message b {
	font-weight: 700;
}

@media only screen and (max-width: 767px) {

	.sync-message p {
		font-size: 14px;
		line-height: 18px;
	}

}

.sync-progress {
	width: 100%;
	height: 32px;
	background-color: #fff;
	border-radius: 6px;
	margin-bottom: 20px;
}

.sync-progress-bar {
	height: 32px;
	background-color: #024359;
	border-radius: 4px;
	line-height: 32px;
	font-weight: 400;
	text-align: right;
	font-size: 12px;
	padding: 0px 10px;
	min-width: 40px;
	transition: width 300ms linear;
}

.sync-progress-message {
	position: absolute;
	width: 100%;
	text-align: center;
	top: 48px;
	font-size: 12px;
	font-weight: 400;
	color: #fff;
	left: 0px;
}

.sync-progress-message b {
	font-weight: 700;
}

.sync-progress-message small {
	font-size: 10px;
	display: block;
	opacity: 0.5;
	margin-top: 4px;
}

</style>