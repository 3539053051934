<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Create Edition

	</template>

	<template v-slot:default>

		<div class="inputs">

			<core-form-input-togglelist title="Comments" name="comments" v-on:validate="validate" v-model="inputs.comments" :error="is.error" :disabled="is.loading" :options="commentOptions" />

		</div>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Confirm</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

    props: ['data'],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				comments: 1
			}

		}

	},

	computed: {

		commentOptions: function() {

			return [
				{value: 1, title: 'Keep Comments', text: 'Copy all existing comments from the current edition to the new edition'},
				{value: 0, title: 'Clear Comments', text: 'Have no existing comments on the new edition'}
			]

		}

	},

	methods: {

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/list/edition/create', {
					data: {
						comments: this.inputs.comments
					}
				}).then(function(json) {

					this.$router.push({
                        name: 'Edition',
                        params: {
                            list: this.data.list.slug,
                            edition: json.slug
                        }
                    }).catch(function(e) { console.log(e) })

					this.$emit('close')

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>