<template>
	
<core-list-expand-section :empty="!count" placeholder="No members want to trade, buy, or sell this game." v-if="selected" class="owners">

	<div class="owners-version" v-if="owners(0).length">

		<div class="owners-version-title" v-if="versions.length">Version unspecified</div>

		<div class="owners-version-members">

			<div class="owners-item" v-for="member in owners(0)" v-bind:key="member.id" v-bind:class="{'is-labelled': statuses.length > 1}">
				<core-avatar class="owners-item-avatar" type="user" :id="member.id" tooltip="1"></core-avatar>
				<div class="owners-item-status" v-if="statuses.length > 1">
					<div class="owners-item-status-label" v-for="(label, index) in labels(member.id)" v-bind:key="index" v-bind:class="{['is-' + label.type]: true}">{{ label.text }}</div>
				</div>
			</div>

		</div>

	</div>

	<template v-for="version in versions">

	<div class="owners-version" v-bind:key="version.id" v-if="owners(version.id).length">

		<div class="owners-version-title">{{ version.name }}<template v-if="version.year"> ({{ version.year}})</template></div>

		<div class="owners-version-members">

			<div class="owners-item" v-for="member in owners(version.id)" v-bind:key="member.id" v-bind:class="{'is-labelled': statuses.length > 1}">
				<core-avatar class="owners-item-avatar" type="user" :id="member.id" tooltip="1"></core-avatar>
				<div class="owners-item-status" v-if="statuses.length > 1">
					<div class="owners-item-status-label" v-for="(label, index) in labels(member.id)" v-bind:key="index" v-bind:class="{['is-' + label.type]: true}">{{ label.text }}</div>
				</div>
			</div>

		</div>

	</div>

	</template>

</core-list-expand-section>

</template>

<script>

export default {

	name: 'trade',

	props: ['item', 'format', 'count', 'selected'],

	computed: {

		statuses: function() {

			return [
				'fortrade',
				'want',
				'wanttobuy',
				'wishlist'
			]

		},

		versions: function() {

			return this.item.versions

		}

	},

	methods: {

		labels: function(id) {

			var labels = []

			this.$_.each(this.$CONSTANTS.GAME.STATUS.FIELD, function(field) {

				if (this.$_.contains(this.statuses, field)) {

					if (this.$_.contains(this.item.users.status[field], id)) {

						labels.push({
							text: this.$CONSTANTS.GAME.STATUS.NAME[field],
							type: field
						})

					}

				}

			}.bind(this))

			return labels

		},

		owners: function(version) {

			var owners = []
			var ownersAdded = []

			this.$_.each(this.$CONSTANTS.GAME.STATUS.FIELD, function(field) {

				if (this.$_.contains(this.statuses, field)) {

					this.$_.each(this.item.users.status[field], function(id) {

						if (!this.$_.contains(ownersAdded, id)) {

							if (this.item.users.versions[id] === version) {

								owners.push(this.$store.getters['data/users/associated'][id])
								ownersAdded.push(id)

							}

						}

					}.bind(this))

				}

			}.bind(this))

			owners = this.$_.sortBy(owners, 'sortname')

			return owners

		}

	}

}

</script>

<style scoped>

.owners {
	padding: 0px 8px!important;
	flex-direction: column!important;
}

.owners-version {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 9px;
	border-bottom: 1px solid #eee;
}

.owners-version:last-child {
	border-bottom: 0px;
}

.owners-version-title {
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 8px;
}

.owners-version-members {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.owners-item {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 32px;
	margin: 0px 1px 1px 0px;
}

.owners-item.is-labelled {
	width: 97px;
	margin: 0px 9px 9px 0px;
}

.owners-item-avatar {
	width: 32px;
	height: 32px;
	font-size: 12px;
	flex-shrink: 0;
}

.owners-item-status {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.owners-item-status-label {
	font-size: 10px;
	color: #fff;
	margin-left: 1px;
	margin-bottom: 1px;
	padding: 0px 4px;
	border-radius: 0px;
	line-height: 16px;
	cursor: pointer;
	font-weight: 400;
	display: flex;
	flex-direction: row;
}

.owners-item-status-label.is-own {
	background-color: rgba(4, 108, 143, 0.9);
}

.owners-item-status-label.is-prevowned {
	background-color: rgba(4, 108, 143, 0.7);
}

.owners-item-status-label.is-fortrade {
	background-color: rgba(4, 108, 143, 0.5);
}

.owners-item-status-label.is-want {
	background-color: rgba(4, 143, 85, 0.9);
}

.owners-item-status-label.is-wanttoplay {
	background-color: rgba(4, 143, 85, 0.7);
}

.owners-item-status-label.is-wanttobuy {
	background-color: rgba(4, 143, 85, 0.5);
}

.owners-item-status-label.is-wishlist {
	background-color: rgba(143, 36, 4, 0.7);
}

.owners-item-status-label.is-preordered {
	background-color: rgba(143, 36, 4, 0.9);
}

.owners-item-status-label.is-hasparts {
	background-color: rgba(4, 108, 143, 0.5);
}

.owners-item-status-label.is-wantparts {
	background-color: rgba(4, 143, 85, 0.5);
}

</style>
