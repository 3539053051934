<template>

<core-modal v-on:close="onClose" type="text" :loading="is.loading">

	<template v-slot:head>

		Data Updates

	</template>

	<template v-slot:default>

		<div v-html="content"></div>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			content: '',

			is: {
				loading: true
			}

		}

	},

	methods: {

		onClose: function() {

			this.$emit('close')

		}

	},

	created: function() {

		this.$store.dispatch('api/app/syncs').then(function(json) {

			this.content = json.body

			this.is.loading = false

		}.bind(this), function() {

			this.$emit('close')

		}.bind(this))

	}

}

</script>

<style scoped>

.about-foot {
	width: 100%;
}

.about-foot h2 {
	font-size: 14px;
	font-weight: 500;
	text-align: left;
	line-height: 18px;
	color: #fff;
	margin-bottom: 4px;
}

.about-foot p {
	font-size: 14px;
	color: #fff;
	line-height: 18px;
	text-align: left;
	font-weight: 400;
	margin-bottom: 10px;
}

.about-buttons-contact {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.about-buttons-contact-item {
	width: 40px;
	height: 40px;
	margin: 0px 10px 0px 0px;
	color: #fff;
	line-height: 40px;
	text-align: center;
}

.about-buttons-contact-item .fa,
.about-buttons-contact-item .fab {
	font-size: 32px;
	margin-top: 4px;
}

@media only screen and (max-width: 767px) {

	.about-buttons-contact-item .fa,
	.about-buttons-contact-item .fab {
		font-size: 24px;
		margin-top: 8px;
	}

}

.about-buttons-contact-item.is-facebook {
	color: #3b5998;
}

.about-buttons-contact-item.is-twitter {
	color: #1da1f2;
}

.about-buttons-contact-item.is-bgg {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAmCAMAAADtLHsqAAAAhFBMVEUAAAD/UQD/UQD/UAD/VwD/UQD/UQD/UQD/UQD/UQD/UAD/WAD/UQD/UQD/UAD/UQD/UgD/UAD/UgD/UQD/UQD/UAD/UQD/UAD/UAD/UQD/UAD/UQD/UQD/UAD/UQD/UQD/UAD/UAD/UAD/UAD/UAD/TwD/RwD/UQD/UQD/UQD/UQD/UQDHSOVYAAAAK3RSTlMAj+5vBcP6rUMoFAjnyYJeLyIb+eTi2M+5o6KblIp+d2RcVU40LQz36dWxGXtUPwAAAN9JREFUOMt1k9cWgjAQRBNARATEjvRu2f//P5MAShnu49wTmF0IwxxTHeZ29ibztMzbQ2AS0WMhrFAjRTPNd8mVeoLJC3SX/li/3Cg/ZxrhDsLnDk3Ry2grDuh3QkhlEoSLx12xktvYYFUJFWLlC+Vh1QqVQ6OptUF1V58Gj6WWtDKW5ILHEhw1pBJhGocgMWM3WsFinDAXg+0Ik66u92kLVUClfrY9LF9LBYu4ysAihRSwiGP06rBQnoxRkfE1iWcqkiEocsu6M/MiL14P2aTIeVvtZ6IrsslthvC4v8i+Ki9rOlCl3nQAAAAASUVORK5CYII=');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: auto 32px;
}

</style>