<template>

<div class="cell" v-bind:style="{width: size, margin: margin, minHeight: size}" v-bind:class="{'has-details': hasDetails}">

	<div v-if="item" class="cell-thumb" v-bind:style="{backgroundImage: avatar, width: size, height: size, borderRadius: radius}" v-bind:class="{'has-details': hasDetails}">

		<template v-if="item.play"><div class="cell-first" v-if="layout.show.first && item.play.date === item.user.play_first" v-bind:class="{'is-central': layout.central}">New</div></template>
		<template v-if="!item.play"><div class="cell-first" v-if="layout.show.first && item.user.play_last === item.user.play_first" v-bind:class="{'is-central': layout.central}">New</div></template>

	</div>

	<div class="cell-details" v-if="item && hasDetails" v-bind:style="{marginTop: margin}" v-bind:class="{'is-central': layout.central}">

		<div v-if="layout.show.game" class="cell-details-name">
			{{ item.game.name }}
		</div>

		<div v-if="layout.show.date && item.play" class="cell-details-date">{{ item.play.date | formatDate($util.date.format('DD', 'MMM', 'YYYY')) }}</div>
		<div v-if="layout.show.date && !item.play" class="cell-details-date">{{ item.user.play_last | formatDate($util.date.format('DD', 'MMM', 'YYYY')) }}</div>

		<div v-if="layout.show.plays" class="cell-details-plays">

			<div v-if="layout.show.plays.increment">
				{{ item.plays.user.increment | nth }} play
				<template v-if="layout.show.plays.all">({{ item.plays.user.all }} total)</template>
			</div>

			<div v-if="!layout.show.plays.increment && layout.show.plays.context">
				{{ item.plays.user.context }} <plural :n="item.plays.user.context" t="play" />
				<template v-if="layout.show.plays.all">({{ item.plays.user.all }} total)</template>
			</div>

			<div v-if="!layout.show.plays.increment && !layout.show.plays.context && layout.show.plays.all">
				{{ item.plays.user.all }} <plural :n="item.plays.user.all" t="play" />
			</div>

		</div>

		<div v-if="layout.show.players && item.players.length">{{ item.players.length }} <plural :n="item.players.length" t="player" /></div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'layout'],

	computed: {

		hasDetails: function() {

			return this.layout.show.date || this.layout.show.players || this.layout.show.plays.all || this.layout.show.plays.increment || this.layout.show.plays.context || this.layout.show.game

		},

		avatar: function() {

			return (this.item) ? 'url(' + this.$util.thumbnail.game.medium({
				id: this.item.game.id
			}, {
				nocrop: this.layout.nocrop
			}) + ')' : ''

		},

		size: function() {

			return this.layout.thumb.toString() + 'px'

		},

		radius: function() {

			return this.layout.corner.toString() + 'px'

		},

		margin: function() {

			return (this.layout.spacing / 2).toString() + 'px'

		},

		games: function() {

			return this.$store.getters['data/games/associated']

		}

	}

}

</script>

<style scoped>

.cell {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
}

.cell-thumb {
	background-color: #fff;
	background-size: cover;
	background-position: 50% 50%;
	transition: all 300ms ease-in-out;
}

.cell-details {
	display: flex;
	width: 100%;
	flex-direction: column;
}

.cell-details > div {
	width: 100%;
	text-align: left;
	font-size: 11px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	line-height: 15px;
	color: #333;
	font-weight: 400;
}

.cell-details.is-central > div {
	text-align: center;
}

.cell-first {
	position: absolute;
	right: 0px;
	top: 0px;
	padding: 2px 4px;
	color: #fff;
	background-color: #3395B5;
	font-size: 12px;
	font-weight: 400;
}

.cell-details-plays {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.cell-details.is-central .cell-details-plays {
	justify-content: center;
}

.cell-details-plays div {
	padding: 0px 2px 0px 0px;
}

.cell-details-plays div span.all {
	color: #666!important;
}

div.cell-details-name {
	font-weight: 700;
}

</style>
