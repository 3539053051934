<template>
	
<core-list-head :fixed="fixed">

	<core-list-column type="name" :format="{filter: 'plays', sort: 'game.name'}" class="column-game">Game</core-list-column>
	<core-list-column type="date" :format="{filter: 'plays', sort: 'play.date'}">Date</core-list-column>
	<core-list-column type="stat" :format="{filter: 'plays', sort: 'play.duration'}" v-if="window.width >= 700">Duration</core-list-column>
	<core-list-column :format="{filter: 'plays', sort: 'player.count'}" class="column-players" v-if="window.width >= 700">Players</core-list-column>
	<core-list-column-actions />

</core-list-head>

</template>

<script>

export default {

	props: ['fixed'],

	computed: {

	}

}

</script>

<style scoped>

.column-game {
	margin-left: 48px!important;
	padding-left: 8px!important;
}

.is-mobile .column-game {
	margin-left: 0px!important;
	padding-left: 0px!important;
}

.column-players {
	width: 236px;
	padding-left: 10px;
}

.column-location {
	width: 100px;
	padding: 0px 10px;
}

</style>