import moment from 'moment'
import _ from 'underscore'

var range = {
	from: moment.utc().subtract(1, 'years').startOf('day').unix(),
	to: moment.utc().startOf('day').unix()
}

export default {

	namespaced: true,

	state: {

		date: {from: range.from, to: range.to},
		type: 'plays',
		players: {
			required: [],
			together: false
		},
		locations: [],
		games: [],
		duration: {from: 0, to: 360},
		count: {from: 1, to: 10},

		layout: {	
			start: 'sunday',
			border: 1,
			title: 'Heatmap',
			size: 16,
			theme: 'blue',
			font: 12,
			trim: 0,
			corner: 4,
			spacing: 1,
			empty: 0,
			show: {
				days: 1,
				months: 1,
				title: 1,
				user: 1,
				app: 0,
				dates: 1
			}
		}

	},

	getters: {

		active: function(state) {

			return {
				'type': state.type !== 'plays',
				'date.from': state.date.from !== 0,
				'date.to': state.date.to !== 0,
				'count': state.count.from !== 1 || state.count.to !== 10,
				'duration': state.duration.from !== 0 || state.duration.to !== 360,
				'players.required': state.players.required.length,
				'players.together': state.players.together,
				'locations': state.locations.length,
				'games': state.games.length
			}

		},

		values: function(state) {

			return state

		},

		count: function(state) {

			return state.count

		},

		duration: function(state) {

			return state.duration

		},

		'players/required': function(state) {

			return state.players.required

		},

		'players/together': function(state) {

			return state.players.together

		},

		locations: function(state) {

			return state.locations

		},

		games: function(state) {

			return state.games

		},

		'filter': function(state) {

			return {
				date: state.date,
				type: state.type,
				locations: state.locations,
				games: state.games,
				players: state.players,
				duration: state.duration,
				count: state.count,
				layout: {
					trim: state.layout.trim
				}
			}

		},

		'layout': function(state) {

			return state.layout

		},

		'layout/title': function(state) {

			return state.layout.title

		},

		'layout/grid': function(state) {

			return state.layout.grid

		},

		'layout/trim': function(state) {

			return state.layout.trim

		},

		'layout/border': function(state) {

			return state.layout.border

		},

		'layout/size': function(state) {

			return state.layout.size

		},

		'layout/empty': function(state) {

			return state.layout.empty

		},

		'layout/theme': function(state) {

			return state.layout.theme

		},

		'layout/font': function(state) {

			return state.layout.font

		},

		'layout/start': function(state) {

			return state.layout.start

		},

		'layout/corner': function(state) {

			return state.layout.corner

		},

		'layout/spacing': function(state) {

			return state.layout.spacing

		},

		'layout/show/user': function(state) {

			return state.layout.show.user

		},

		'layout/show/app': function(state) {

			return state.layout.show.app

		},

		'layout/show/title': function(state) {

			return state.layout.show.title

		},

		'layout/show/dates': function(state) {

			return state.layout.show.dates

		},

		'layout/show/days': function(state) {

			return state.layout.show.days

		},

		'layout/show/months': function(state) {

			return state.layout.show.months

		},

		'type': function(state) {

			return state.type

		},

		'date': function(state) {

			return state.date

		},

		'date/from': function(state) {

			return state.date.from

		},

		'date/to': function(state) {

			return state.date.to

		}

	},

	mutations: {

		reset: function(state) {

			state.date = {from: range.from, to: range.to}
			state.type = 'plays'
			state.players = {
				together: false,
				required: []
			}
			state.games = []
			state.locations = []
			state.count = {from: 1, to: 10}
			state.duration = {from: 0, to: 360}

		},

		count: function(state, data) {

			state.count = {
				from: data.from,
				to: data.to
			}

		},

		duration: function(state, data) {

			state.duration = {
				from: data.from,
				to: data.to
			}

		},

		locations: function(state, id) {

			if (_.contains(state.locations, id)) {

				state.locations.splice(state.locations.indexOf(id), 1)

			} else {

				state.locations.push(id)

			}

		},

		games: function(state, id) {

			if (_.contains(state.games, id)) {

				state.games.splice(state.games.indexOf(id), 1)

			} else {

				state.games.push(id)

			}

		},

		'layout/title': function(state, value) {

			state.layout.title = value

		},

		'layout/size': function(state, value) {

			state.layout.size = value

		},

		'layout/start': function(state, value) {

			state.layout.start = value

		},

		'layout/empty': function(state, value) {

			state.layout.empty = value

		},

		'layout/trim': function(state, value) {

			state.layout.trim = value

		},

		'layout/border': function(state, value) {

			state.layout.border = value

		},

		'layout/font': function(state, value) {

			state.layout.font = value

		},

		'layout/theme': function(state, value) {

			state.layout.theme = value

		},

		'layout/corner': function(state, value) {

			state.layout.corner = value

		},

		'layout/spacing': function(state, value) {

			state.layout.spacing = value

		},

		'layout/show/user': function(state, value) {

			state.layout.show.user = value

		},

		'layout/show/app': function(state, value) {

			state.layout.show.app = value

		},

		'layout/show/days': function(state, value) {

			state.layout.show.days = value

		},

		'layout/show/months': function(state, value) {

			state.layout.show.months = value

		},

		'layout/show/title': function(state, value) {

			state.layout.show.title = value

		},

		'layout/show/dates': function(state, value) {

			state.layout.show.dates = value

		},

		'type': function(state, value) {

			state.type = value

		},

		'date/from': function(state, value) {

			state.date.from = value

		},

		'date/to': function(state, value) {

			state.date.to = value

		}

	}

}