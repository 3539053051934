<template>

<core-dropdown trigger="manual" v-on:forceClose="is.expanded = false" :expanded="is.expanded" v-click-outside="onOutsideClick">

	<core-button v-on:click.native="onToggleClick" theme="expand" :caret="true">{{ text }}</core-button>
	
	<core-dropdown-list class="input-options" theme="expand" :caret="true" align="left" type="button">

		<core-dropdown-item v-for="option in options" :key="option.value" type="action" v-bind:class="{'is-active': value === option.value}" v-on:click.native="onOptionClick(option.value)">{{ option.text }}</core-dropdown-item>

	</core-dropdown-list>

</core-dropdown>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: [
		'text', 'options', 'value'
	],
	
	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	methods: {

		onToggleClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onOptionClick: function(value) {

			this.$emit('change', value)
			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

</style>