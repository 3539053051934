import store from 'app/store'
import copy from 'clipboard-copy'

export default {

	csv: function(context, e) {

		e = e || {}
		
		var index = (e.override === undefined) ? context.getters['all'] : [e.override.item]

		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {
		
				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded']
					}).then(function(json) {

						internalResolve(json.index)

					})

				} else {

					internalResolve(index)

				}

			}).then(function(index) {

				store.dispatch('api/export/csv', {
					key: context.getters['key'],
					index: index
				}).then(function(json) {

					window.location.href = json.download

					resolve()

				})

			})

		})

	},

	clipboard: function(context, e) {

		e = e || {}

		var index = (e.override === undefined) ? context.getters['all'] : [e.override.item]
		
		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {
		
				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded']
					}).then(function(json) {

						internalResolve(json.index)

					})

				} else {

					internalResolve(index)

				}

			}).then(function(index) {

				store.dispatch('api/export/clipboard', {
					key: context.getters['key'],
					index: index
				}).then(function(json) {

					copy(json.clipboard)

					resolve()

				})

			})

		})
		
	}

}