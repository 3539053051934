<template>

<com-item name="ratings.distribution" :size="size" :is-blank="true" :loading="loading" title="Distribution">

	<template v-slot:about>

		<p>How {{ your }} ratings spread on the 1-10 scale.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-accordian :data="accordian" v-on:click="onAccordianClick" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			accordian: {
				noun: 'game',
				tooltip: 'Click to view games<small>{{value}} {{noun}}</small>',
				values: [],
				labels: [1,2,3,4,5,6,7,8,9,10],
				images: [],
				colors: [
					'#db303b',
					'#db303b',
					'#df4751',
					'#df4751',
					'#5369a2',
					'#5369a2',
					'#1d8acd',
					'#2FC482',
					'#249563',
					'#249563'
				]
			}
		}
	
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/distribution']

		},

		active: function() {

			return this.$_.keys(this.data).length && !this.loading

		}

	},

	created: function() {
	
		this.visualise()	

	},

	methods: {

		onAccordianClick: function(value) {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsDistribution',
				rating: value
			})

		},
	
		visualise: function() {
		
			for (var i = 1; i <= 10; i++) {

				this.accordian.values.push((this.data[i]) ? this.data[i].value : 0)
				this.accordian.images.push((this.data[i]) ? this.data[i].game : 0)

			}
		
		}
	
	}

}

</script>

<style scoped>

</style>