import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		columns: {
			rank: false,
			avatar: false,
			name: false,
			count: false,
			duration: false,
			weight: false,
			bggrank: false,
			language: false,
			owners: false,
			plays: {
				total: false,
				filter: false,
				hours: false
			},
			date: {
				play: {
					first: {
						any: false,
						filter: false
					},
					last: {
						any: false,
						filter: false
					}
				}
			},
			rating: {
				user: false,
				group: false,
				bgg: false
			}
		},
		defaultValues: {},
		changed: false

	},

	getters: {

		changed: function(state) {

			return !_.isEqual(state.columns, state.defaultValues)

		},

		values: function(state, getters, rootState, rootGetters) {

			var columns = []

			if (state.columns.rank) columns.push({filter: 'list', sort: 'list.rank', type: 'rank', onlyPosition: !rootGetters['page/list/list'].ranked})
			if (state.columns.avatar) columns.push({type: 'avatar'})
			if (state.columns.name) columns.push({filter: 'list', sort: 'name', type: 'name', showComment: true})
			if (state.columns.count) columns.push({filter: 'list', sort: 'count', type: 'count'})
			if (state.columns.duration) columns.push({filter: 'list', sort: 'duration', type: 'duration'})
			if (state.columns.weight) columns.push({filter: 'list', sort: 'weight', type: 'weight'})
			if (state.columns.language) columns.push({filter: 'list', sort: 'language.dependency', type: 'language', icon: 'language'})
			if (state.columns.bggrank) columns.push({filter: 'list', sort: 'rank', type: 'bggrank'})
			if (state.columns.owners) columns.push({filter: 'list', sort: 'owners', type: 'owners'})

			if (state.columns.date.play.first.any) columns.push({filter: 'list', sort: 'played.first.all', type: 'date', value: 'play_first', title: 'First played'})
			if (state.columns.date.play.first.filter) columns.push({filter: 'list', sort: 'played.first.context', type: 'date', context: 'filter', value: 'play_first', title: 'First played this period'})
			if (state.columns.date.play.last.any) columns.push({filter: 'list', sort: 'played.last.all', type: 'date', value: 'play_last', title: 'Last played'})
			if (state.columns.date.play.last.filter) columns.push({filter: 'list', sort: 'played.last.context', type: 'date', context: 'filter', value: 'play_last', title: 'Last played this period'})

			if (state.columns.plays.total) columns.push({filter: 'list', sort: 'plays.all', type: 'plays'})
			if (state.columns.plays.filter) columns.push({filter: 'list', sort: 'plays.context', type: 'plays', context: 'filter'})
			if (state.columns.plays.hours) columns.push({filter: 'list', sort: 'time', type: 'hours', title: 'Hours'})

			if (state.columns.rating.user) columns.push({filter: 'list', sort: 'rating.user', type: 'rating', context: 'user'})
			if (state.columns.rating.group) columns.push({filter: 'list', sort: 'rating.group', type: 'rating', context: 'group'})
			if (state.columns.rating.bgg) columns.push({filter: 'list', sort: 'rating.bgg', type: 'rating', context: 'bgg'})

			return columns

		},

		rank: function(state) {

			return state.columns.rank

		},

		name: function(state) {

			return state.columns.name

		},

		avatar: function(state) {

			return state.columns.avatar

		},

		count: function(state) {

			return state.columns.count

		},

		duration: function(state) {

			return state.columns.duration

		},

		weight: function(state) {

			return state.columns.weight

		},

		language: function(state) {

			return state.columns.language

		},

		owners: function(state) {

			return state.columns.owners

		},

		bggrank: function(state) {

			return state.columns.bggrank

		},

		'plays/hours': function(state) {

			return state.columns.plays.hours

		},

		'plays/total': function(state) {

			return state.columns.plays.total

		},

		'plays/filter': function(state) {

			return state.columns.plays.filter

		},

		'date/play/first/any': function(state) {

			return state.columns.date.play.first.any

		},

		'date/play/first/filter': function(state) {

			return state.columns.date.play.first.filter

		},

		'date/play/last/any': function(state) {

			return state.columns.date.play.last.any

		},

		'date/play/last/filter': function(state) {

			return state.columns.date.play.last.filter

		},

		'rating/user': function(state) {

			return state.columns.rating.user

		},

		'rating/group': function(state) {

			return state.columns.rating.group

		},

		'rating/bgg': function(state) {

			return state.columns.rating.bgg

		}

	},

	mutations: {

		reset: function(state, defaultValues) {

			state.defaultValues = JSON.parse(JSON.stringify(state.columns))

			state.defaultValues.rank = false
			state.defaultValues.avatar = false
			state.defaultValues.name = false
			state.defaultValues.count = false
			state.defaultValues.duration = false
			state.defaultValues.weight = false
			state.defaultValues.language = false
			state.defaultValues.owners = false
			state.defaultValues.bggrank = false
			state.defaultValues.plays.total = false
			state.defaultValues.plays.hours = false
			state.defaultValues.plays.filter = false
			state.defaultValues.date.play.first.any = false
			state.defaultValues.date.play.first.filter = false
			state.defaultValues.date.play.last.any = false
			state.defaultValues.date.play.last.filter = false
			state.defaultValues.rating.user = false
			state.defaultValues.rating.group = false
			state.defaultValues.rating.bgg = false

			_.each(defaultValues, function(item) {

				if (item.type === 'rank') state.defaultValues.rank = true
				if (item.type === 'avatar') state.defaultValues.avatar = true
				if (item.type === 'name') state.defaultValues.name = true
				if (item.type === 'count') state.defaultValues.count = true
				if (item.type === 'duration') state.defaultValues.duration = true
				if (item.type === 'weight') state.defaultValues.weight = true
				if (item.type === 'language') state.defaultValues.language = true
				if (item.type === 'owners') state.defaultValues.owners = true
				if (item.type === 'bggrank') state.defaultValues.bggrank = true
				if (item.type === 'plays' && !item.context) state.defaultValues.plays.total = true
				if (item.type === 'plays' && item.context === 'filter') state.defaultValues.plays.filter = true
				if (item.type === 'hours') state.defaultValues.plays.hours = true
				if (item.type === 'date' && item.value === 'play_first' && !item.context) state.defaultValues.date.play.first.any = true
				if (item.type === 'date' && item.value === 'play_first' && item.context === 'filter') state.defaultValues.date.play.first.filter = true
				if (item.type === 'date' && item.value === 'play_last' && !item.context) state.defaultValues.date.play.last.any = true
				if (item.type === 'date' && item.value === 'play_last' && item.context === 'filter') state.defaultValues.date.play.last.filter = true
				if (item.type === 'rating' && item.context === 'user') state.defaultValues.rating.user = true
				if (item.type === 'rating' && item.context === 'group') state.defaultValues.rating.group = true
				if (item.type === 'rating' && item.context === 'bgg') state.defaultValues.rating.bgg = true

			})

			state.columns = JSON.parse(JSON.stringify(state.defaultValues))

			state.changed = false

		},

		rank: function(state, value) {

			state.columns.rank = value

		},

		name: function(state, value) {

			state.columns.name = value

		},

		avatar: function(state, value) {

			state.columns.avatar = value

		},

		count: function(state, value) {

			state.columns.count = value

		},

		duration: function(state, value) {

			state.columns.duration = value

		},

		owners: function(state, value) {

			state.columns.owners = value

		},

		weight: function(state, value) {

			state.columns.weight = value

		},

		language: function(state, value) {

			state.columns.language = value

		},

		bggrank: function(state, value) {

			state.columns.bggrank = value

		},

		'plays/total': function(state, value) {

			state.columns.plays.total = value

		},

		'plays/hours': function(state, value) {

			state.columns.plays.hours = value

		},

		'plays/filter': function(state, value) {

			state.columns.plays.filter = value

		},

		'date/play/first/any': function(state, value) {

			state.columns.date.play.first.any = value

		},

		'date/play/first/filter': function(state, value) {

			state.columns.date.play.first.filter = value

		},

		'date/play/last/any': function(state, value) {

			state.columns.date.play.last.any = value

		},

		'date/play/last/filter': function(state, value) {

			state.columns.date.play.last.filter = value

		},

		'rating/user': function(state, value) {

			state.columns.rating.user = value

		},

		'rating/group': function(state, value) {

			state.columns.rating.group = value

		},

		'rating/bgg': function(state, value) {

			state.columns.rating.bgg = value

		}

	}

}