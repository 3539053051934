<template>

<core-config-section :locked="!loggedin">

	<h2>Incomplete plays</h2>

	<p>Plays marked as incomplete are normally ignored when your profile is synchronised, you can toggle whether to include them in future syncs. Your plays will update next time your profile syncs.</p>

	<div class="inputs">

		<core-filter-input-toggle text="Sync incomplete plays" on="Yes" off="No" filter="config/sync/plays/incomplete" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>