import 'app/assets/tooltip.css'

import Vue from 'vue'

import VueLazyload from 'vue-lazyload'
import Vuelidate from 'vuelidate'
import VueTextareaAutosize from 'vue-textarea-autosize'
import VueClipboard from 'vue-clipboard2'
import VueCookies from 'vue-cookies'

import Chart from 'chart.js'
import ChartLabels from 'chartjs-plugin-labels'
import ChartDatalabels from 'chartjs-plugin-datalabels'

import mixinShortcuts from 'app/mixin/Shortcuts'
import Guard from 'app/router/guard' 
import router from 'app/router'

import store from './store'
import CONSTANTS from './constants'

Vue.use(VueLazyload)
Vue.use(Vuelidate)
Vue.use(VueTextareaAutosize)
Vue.use(VueClipboard)
Vue.use(VueCookies)

Chart.plugins.unregister(ChartLabels)
Chart.plugins.unregister(ChartDatalabels)

router.beforeEach(Guard)

Vue.filter('currency', function (value, decimals, pure) {

	decimals = (decimals === undefined) ? 2 : decimals
	pure = pure || false

	var currency = CONSTANTS.CURRENCY[store.getters['session/config'].other.currency]
	var finalValue = value * store.getters['session/config'].other.exchange

	finalValue = finalValue.toFixed(decimals)

	if (currency.round && decimals) finalValue = Math.round(finalValue)

	var html = []

	if (currency.position === 'a') html.push(finalValue)

	if (!pure) html.push('<span class="currency is-' + ((html.length) ? 'after' : 'before') + '">' + currency.sign + '</span>')

	if (currency.position === 'b') html.push(finalValue)

	return (pure) ? parseFloat(html.join('')) : html.join('')

})

Vue.mixin(mixinShortcuts)