<template>

<div class="filter-panel">

	<core-button v-on:click.native="$emit('expandClick')" v-propagation tooltip="Change filter" icon="filter" :iconOnly="true" theme="filter" v-bind:class="{'is-preset': loggedin}" />

	<div v-if="totalFilterCount" class="filter-panel-count">{{ totalFilterCount }}</div>

</div>

</template>

<script>

export default {

	props: ['filter'],

	computed: {

		totalFilterCount: function() {

			return this.$store.getters['filter/' + this.filter + '/active/count']

		}

	}

}

</script>

<style scoped>

.filter-panel {
	position: relative;
	display: flex;
	flex-direction: row;
}

.filter-panel-count {
	position: absolute;
	right: -6px;
	top: -6px;
	z-index: 1;
	background-color: #ffc563;
	border-radius: 4px;
	color: #333;
	font-size: 12px;
	min-width: 16px;
	height: 16px;
	padding: 0px 4px;
	text-align: center;
	font-weight: 500;
	line-height: 16px;
}

</style>