<template>

<div class="recent">

	<div class="recent-rating">

		<core-avatar type="user" :id="rating.user" :tooltip="true" class="recent-rating-avatar" />

		<core-rating type="user" :value="recent.value" class="recent-rating-value" />

		<div class="recent-rating-comment" v-bind:class="{'is-empty': !comment, 'is-long': longComment}" v-on:click="onCommentClick" v-tooltip="tooltip">

			<div class="recent-rating-comment-body">

				<div ref="comment" v-html="comment"></div>

			</div>

			<div class="recent-rating-comment-caret"></div>

			<div class="recent-rating-comment-fade" v-if="longComment"></div>

		</div>

	</div>

	<div class="recent-about" v-if="!hideAbout">

		<app-item-change :date="recent.date" :revisions="rating" :showChange="true" :showDate="true" :reverse="true" />

	</div>

</div>

</template>

<script>

import itemChange from './Change'

export default {

	props: ['rating', 'game', 'hideAbout', 'ready'],

	components: {
		'app-item-change': itemChange
	},

	data: function() {

		return {

			longComment: false

		}

	},

	watch: {

		ready: function(n) {

			if (n) this.checkComment()

		},

		window: {

			handler: function(n, o) {

				if (n.width !== o.width) {

					this.checkComment()

				}

			},

			deep: true

		}

	},

	mounted: function() {

		this.checkComment()

	},

	computed: {

		tooltip: function() {

			return (this.longComment) ? 'Click to expand' : false

		},

		recent: function() {

			return this.$_.last(this.rating.history)

		},

		comment: function() {

			return (this.rating.comment) ? this.rating.comment.text : ''

		}

	},

	methods: {

		onCommentClick: function(e) {

			e.stopPropagation()

			if (this.longComment) {

				this.$pubsub.$emit('panel.open', {
					type: 'ratingsComment',
					game: this.game,
					rating: this.rating,
					comment: this.rating.comment
				})
			
			}

		},

		checkComment: function() {

			if (this.rating.comment) {

				this.$nextTick(function() {
					if (this.$refs.comment) this.longComment = this.$refs.comment.offsetHeight > 80
				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.recent {
	margin-bottom: 8px;
	display: flex;
	flex-direction: column;
}

.recent:last-child {
	margin-bottom: 0px;
}

.recent-rating {
	display: flex;
	flex-direction: row;
}

.recent-rating-avatar {
	width: 32px;
	height: 32px;
	margin-right: 1px;
	flex-shrink: 0;
}

.recent-rating-value {
	width: 32px;
	height: 32px;
	line-height: 32px;
	margin-right: 15px;
	flex-shrink: 0;
	font-size: 14px!important;
}

.recent-rating-comment {
	background-color: #eee;
	flex-grow: 1;
	border-radius: 4px;
	padding: 8px;
}

.recent-rating-comment.is-long {
	cursor: pointer;
}

.recent-rating-comment-body {
	max-height: 80px;
	overflow: hidden;
	line-height: 16px;
	color: #333;
	font-weight: 300;
	font-size: 12px;
}

.recent-rating-comment-caret {
	position: absolute;
	left: -8px;
	top: 8px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 8px 0;
	border-color: transparent #eeeeee transparent transparent;
}

.recent-rating-comment-fade {
	position: absolute; 
	bottom: 0; 
	left: 0;
	width: 100%; 
	text-align: center; 
	margin: 0; padding: 30px 0; 
	border-radius: 0px 0px 4px 4px;
	background-image: linear-gradient(to bottom, transparent, #eee);
}

.recent-rating-comment.is-empty:before {
	content: 'No comment written.';
	font-size: 12px;
	line-height: 16px;
	color: #333;
}

.recent-rating-comment.is-empty {
	opacity: 0.6;
}

.recent-about {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

</style>