<template>

<div class="comment">

	<core-avatar v-if="!hideUser" type="user" :tooltip="true" :id="comment.user" class="comment-avatar" />
	<core-rating v-if="!hideUser" type="user" :value="rating" class="comment-rating" />

	<div class="comment-text">

		<div class="comment-text-body" :class="{'is-hiding': hideUser}">

			<p v-html="comment.text"></p>

		</div>

		<small>Written on {{ comment.date | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}<template v-if="!hideUser"> by {{ name }}</template></small>

	</div>

</div>

</template>

<script>

export default {

	props: ['comment', 'rating', 'hideUser'],

	computed: {

		name: function() {

			return this.$store.getters['data/users/associated'][this.comment.user].fullname

		}

	}

}

</script>

<style scoped>

.comment {
	display: flex;
	flex-direction: row;
}

.comment:last-child {
	border-bottom: 0px;
}

.comment-avatar {
	flex-shrink: 0;
	width: 48px;
	height: 48px;
	margin-right: 1px;
	font-size: 12px;
}

.comment-rating {
	flex-shrink: 0;
	width: 48px;
	line-height: 48px;
	height: 48px;
	margin-right: 15px;
}

.comment-text {	
	flex-grow: 1;
}

.comment-text-body {	
	background-color: #eee;
	border-radius: 4px;
	min-height: 48px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.comment-text-body:not(.is-hiding):before {
	content: ' ';
	position: absolute;
	left: -8px;
	top: 16px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 8px 0;
	border-color: transparent #eeeeee transparent transparent;
}

.comment-text p {
	font-size: 14px;
	line-height: 16px;
	color: #333;
	font-weight: 300;
}

.comment-text small {
	font-size: 10px;
	text-align: right;
	margin-top: 4px;
	display: block;
	line-height: 16px;
	color: #666;
	font-weight: 300;
}

</style>
