<template>

<core-list-column :format="{}" class="column">

	<div class="actions-check-box" :class="{'is-active': isSelected}" v-on:click="onSelectToggle" v-tooltip="tooltip">
		<i class="fa fa-check" v-if="isSelected"></i>
	</div>

</core-list-column>

</template>

<script>

export default {

	computed: {

		isSelected: function() {

			return this.$store.getters['service/select/mode'] === this.$CONSTANTS.SERVICE.SELECT.MODE.ALL && !this.$store.getters['service/select/unselected/count']

		},

		tooltip: function() {

			return (this.isSelected) ? 'Click to deselect all' : 'Click to select all'

		}

	},

	methods: {

		onSelectToggle: function() {

			if (this.isSelected) {

				this.$store.dispatch('service/select/selection/none')

			} else {

				this.$store.dispatch('service/select/selection/all')

			}

		}

	}

}

</script>

<style scoped>

.column {
	width: 31px;
	flex-shrink: 0;
	text-align: center;
}

.actions-check-box {
	border: 2px solid #eee;
	width: 16px;
	height: 16px;
	margin: 4px;
	border-radius: 4px;
	cursor: pointer;
	margin-left: 11px;
}

.is-desktop .actions-check-box:not(.is-active):hover {
	border-color: #666;
}

.actions-check-box.is-active {
	background-color: #1b6b8f;
	border-color: #1b6b8f;
	font-size: 10px;
	text-align: center;
	line-height: 14px;
	color: #fff;
}

</style>