<template>

<div class="input select" :data-theme="theme" v-click-outside="onOutsideClick">

	<div class="select-value" v-on:click="onValueClick">
		{{ valueText }}
        <i class="select-value-caret fa fa-caret-down" />
	</div>

	<div class="select-options" v-if="is.expanded && !window.is.mobile">
        <div class="select-options-item" v-for="(item, index) in options" :key="index" v-on:click="onItemClick(index)">{{ item }}</div>
	</div>

    <select class="select-mobile" v-if="window.is.mobile" v-model="mobile">
        <option v-for="(item, index) in options" :key="index" :value="index" :selected="index === value">{{ item }}</option>
    </select>

</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	props: [
		'options', 'theme', 'value', 'placeholder'
	],

	directives: {
		clickOutside: vClickOutside.directive
	},

    data: function() {
    
        return {
            is: {
                expanded: false
            },
            mobile: 0
        }

    },

	computed: {

        valueText: function() {
        
            return (this.options[this.value]) ? this.options[this.value] : this.placeholder
        
        }

	},

    watch: {
    
        mobile: function(n) {
        
            this.onItemClick(n)

        }
    
    },

	methods: {

        onItemClick: function(value) {
        
            this.$emit('input', parseInt(value))
            this.is.expanded = false

        },

        onOutsideClick: function() {
        
            this.is.expanded = false

        },

        onValueClick: function() {
        
            this.is.expanded = !this.is.expanded
        
        }

	}

}

</script>

<style scoped>

.select {
    height: 40px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    user-select: none;
}

.select-value {
    width: 100%;
    padding: 0px 20px 0px 10px;
    font-weight: 400;
    height: 38px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 16px;
}

.select:hover {
    border-color: #666;
}

.select-value-caret {
    position: absolute;
    right: 10px;
    top: 0px;
    line-height: 38px;
}

.select-options {
    position: absolute;
    top: 40px;
    right: 0px;
    padding: 5px 0px;
    background-color: #4881bb;
    border-radius: 4px;
    z-index: 100;
    width: 200px;
}

.select-options:before {
    position: absolute;
    right: 5px;
    content: '';
    top: -5px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #4881BB transparent;
    transform: rotate(0deg);
}

.select-options-item {
    font-size: 14px;
    line-height: 18px;
    padding: 3px 10px;
    cursor: pointer;
    color: #fff;
    font-weight: 400;
}

.select-options-item:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.select-mobile {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 38px;
    opacity: 0;
}

</style>
