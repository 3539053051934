<template>

<core-panel>

	<template v-slot:head>

		<h1>Familiar faces</h1>

		<p>The {{ noun }} who have spent the most cumulative time on the shelf.</p>

	</template>

	<template v-slot:body>

		<app-person v-for="(item, index) in cumulative" :item="item" gamesCountProperty="gamesCount" :dark="true" :key="index" v-on:more="onMoreClick(item.id)" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'cumulative',
			cumulative: [],
			params: ['cumulative.who', 'cumulative.period']
		}

	},

	computed: {

		noun: function() {

			return this.data.cumulative

		}

	},

	methods: {

		response: function(json) {

			this.cumulative = json.cumulative

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsShelfPerson',
				'cumulative.who': this.$store.getters['filter/insights/shelf/cumulative/who'],
				'cumulative.period': this.$store.getters['filter/insights/shelf/cumulative/period'],
				specific: id,
				child: true
			})

		}

	}

}

</script>