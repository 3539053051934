import playgrid from './tools/playgrid'
import heatmap from './tools/heatmap'
import wordcloud from './tools/wordcloud'

export default {

	namespaced: true,

	modules: {
		playgrid: playgrid,
		heatmap: heatmap,
		wordcloud: wordcloud
	},

	state: {},

	getters: {

		playgrid: function(state, getters) {

			return getters['playgrid/values']

		},

		heatmap: function(state, getters) {

			return getters['heatmap/values']

		},

		wordcloud: function(state, getters) {

			return getters['wordcloud/values']

		}

	},

	mutations: {},

	actions: {}

}