<template>

<core-content-wrapper :loading="!is.ready">

	<core-filter :loading="is.fetching" :filter="insightName" v-on:refresh="onRefresh">

	</core-filter>

	<core-content-page v-if="is.reset && is.fetching !== 'all'">

		<core-content-page-inner v-show="is.ready" :is-grid="true">

			<com-overview :fetching="is.fetching" :your="your" :you="you" :size="2" v-if="isUser" />

			<com-users :fetching="is.fetching" :your="your" :you="you" :size="3" type="most" v-if="!isUser" />

			<com-users :fetching="is.fetching" :your="your" :you="you" :size="3" type="least" v-if="!isUser" />

			<com-games :fetching="is.fetching" :your="your" :you="you" :size="(isUser) ? 2 : 3" type="most" />

			<com-games :fetching="is.fetching" :your="your" :you="you" :size="(isUser) ? 2 : 3" type="least" />

			<com-monthly :fetching="is.fetching" :your="your" :you="you" :size="6" v-if="isUser" />

			<com-streaks :fetching="is.fetching" :your="your" :you="you" :size="(isUser) ? 2 : 3" type="win" />

			<com-streaks :fetching="is.fetching" :your="your" :you="you" :size="(isUser) ? 2 : 3" type="loss" />

			<com-streaks :fetching="is.fetching" :your="your" :you="you" :size="(isUser) ? 2 : 3" type="play" :hideGames="true" :showSummary="true" label="day" />

			<com-distribution :fetching="is.fetching" :your="your" :you="you" :size="3" type="weights" v-if="isUser" />

			<com-distribution :fetching="is.fetching" :your="your" :you="you" :size="3" type="counts" v-if="isUser" />

			<com-streaks :fetching="is.fetching" :your="your" :you="you" :size="(isUser) ? 2 : 3" type="drought" :hideGames="true" :showSummary="true" label="day" />

			<com-stats :fetching="is.fetching" :your="your" :you="you" :size="2" type="day"  label="play" v-if="isUser" />

			<com-stats :fetching="is.fetching" :your="your" :you="you" :size="2" type="new" label="play" v-if="isUser" />

		</core-content-page-inner>

	</core-content-page>

</core-content-wrapper>

</template>

<script>

import Section from './common/Section.js'

import comOverview from './records/Overview'
import comGames from './records/Games'
import comStreaks from './records/Streaks'
import comDistribution from './records/Distribution'
import comStats from './records/Stats'
import comMonthly from './records/Monthly'
import comUsers from './records/Users'

export default {

	mixins: [Section],

	components: {
		'com-overview': comOverview,
		'com-games': comGames,
		'com-streaks': comStreaks,
		'com-distribution': comDistribution,
		'com-stats': comStats,
		'com-monthly': comMonthly,
		'com-users': comUsers
	},

	data: function() {

		return {

			insightName: 'records',

			dynamicInsights: [
			]

		}

	}

}

</script>