<template>

<core-filter-advanced-group title="Comments" :count="count">

	<core-filter-input-text placeholder="Search comments..." :filter="value('comment/text')" class="text" :inset="true" />

	<core-filter-input-toggles text="Has comment" :values="comments.values" :icons="comments.icons" :labels="comments.labels" :filter="value('commented')" v-if="isUser" />

	<core-filter-input-range v-if="isGroup || isBuddies" text="Comments" min="0" max="50" maxText="Any" :filter="value('comments')" :tooltip="true" step="1" pips="5" decimals="0" density="10" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],
	
	data: function() {

		return {
			fields: ['commented', 'comment.text', 'comments'],
			comments: {
				labels: ['Yes', 'No', 'Either'],
				icons: ['', '', '', ''],
				values: ['yes', 'no', 0]
			}
		}
	}

}

</script>

<style scoped>

.text {
	padding: 0px!important;
}

</style>