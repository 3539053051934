import API from 'core/api'
import _ from 'underscore'
import CONSTANTS from '../../constants'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		create: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('groups/create', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		delete: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('groups/delete', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		edit: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('groups/edit', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		verify: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('groups/verify', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		add: function(context, params) {

			return new Promise(function(resolve, reject) {

				function call(syncKey) {

					if(syncKey) params.syncKey = syncKey
				
					API.request('groups/add', params).then(function(json) {

						resolve(json)

					}, function(json) {

						if (json.error === CONSTANTS.STATUS.ERROR.SYNC.INPROGRESS) {

							_.delay(function() { call(json.syncKey) }, 5000)

						} else {

							reject(json)
							
						}

					})

				}

				call()

			})

		},

		remove: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('groups/remove', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		leave: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('groups/leave', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		join: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('groups/join', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		}

	}

}