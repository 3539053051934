<template>

<com-item name="collection.counts" :size="size" :is-empty="!active" :loading="loading" title="Counts">

	<template v-slot:about>

		<p>See how {{ your }} collection breaks down by count, both official and community voted.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>
	
		<app-visualisation-bars :data="graph" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			graph: {
				datasets: [
					{ type: 'bar', data: [], backgroundColor: '#4881bb', tooltip: '{{value}} game{{value.plural}} <small>Official {{x.label}} {{x.sublabel}}</small>' },
					{ type: 'bar', data: [], backgroundColor: '#2FC482', tooltip: '{{value}} game{{value.plural}} <small>Recommended {{x.label}} {{x.sublabel}}</small>' },
					{ type: 'bar', data: [], backgroundColor: '#249563', tooltip: '{{value}} game{{value.plural}} <small>Best {{x.label}} {{x.sublabel}}</small>' }
				],
				axis: {
					x: {
						labels: [{
							label: '1',
							sublabel: 'player'
						}, {
							label: '2',
							sublabel: 'players'
						}, {
							label: '3',
							sublabel: 'players'
						}, {
							label: '4',
							sublabel: 'players'
						}, {
							label: '5',
							sublabel: 'players'
						}, {
							label: '6',
							sublabel: 'players'
						}]
					},
					y: {
						min: 10,
						points: 10
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		active: function() {

			return this.$_.keys(this.counts).length && !this.loading

		},

		counts: function() {

			return this.$store.getters['page/insights/collection/counts']

		}

	},

	watch: {

		counts: function() {

			this.visualise()

		}

	},

	created: function() {
	
		this.visualise()

	},

	methods: {

		visualise: function() {

			if (this.$_.keys(this.counts).length) {

				this.graph.datasets[0].data = []
				this.graph.datasets[1].data = []
				this.graph.datasets[2].data = []

				for (var i = 1; i <= 6; i++) {

					if (this.counts[i]) {

						this.graph.datasets[0].data.push(this.counts[i].o)
						this.graph.datasets[1].data.push(this.counts[i].r)
						this.graph.datasets[2].data.push(this.counts[i].b)

					} else {

						this.graph.datasets[0].data.push(0)
						this.graph.datasets[1].data.push(0)
						this.graph.datasets[2].data.push(0)

					}

				}

			}

		}
		
	}

}

</script>

<style scoped>

</style>