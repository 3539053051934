<template>

<core-config-section :locked="!loggedin">

	<h2>Date range</h2>

	<p class="about">If you rather the statistics began from a specific date you can set the initial cut-off date before which all plays will be ignored.</p>

	<div class="inputs">

		<core-filter-input-date text="Start date" placeholder="None set" filter="config/insights/records/start" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>