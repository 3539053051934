<template>
	
<div class="tags">

	<app-game-item-field-name-status :format="format" :item="item" :filter="filter" />

</div>

</template>

<script>

export default {

	props: ['format', 'item', 'filter']

}

</script>

<style scoped>

.tags {
	
}

</style>
