<template>

<div class="disparity" v-bind:class="{'is-up': value > 0, 'is-down': value < 0}">

	<span v-if="value < 0"><i class="fa fa-caret-down"></i></span><span v-if="value > 0"><i class="fa fa-caret-up"></i></span>{{ value | abs | round(1, true) }}

</div>

</template>

<script>

export default {
	
	props: ['value']

}

</script>

<style scoped>

.disparity {
	height: 32px;
	font-size: 32px;
	font-weight: 500;
	color: #666;
}

.disparity.is-up {
	color: #2FC482;
}

.disparity.is-down {
	color: #df4751;
}

.disparity .fa {
	font-size: 16px;
}

</style>