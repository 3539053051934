<template>

<com-item name="shelf.cumulative" :is-list="true" :size="size" :loading="loading" title="Familiar Faces" :has-more="true" v-on:more="onMoreClick">

	<template v-slot:about>

		<p>Those whose games have spent the most cumulative time on {{ your }} shelf.</p>

	</template>

	<template v-slot:stats>

		<app-person v-for="(item, index) in data" :hideCount="true" gamesCountProperty="gamesCount" :item="item" :type="cumulativeTargetValue" v-bind:key="index" v-on:more="onPersonClick(item.id)" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="cumulativeTargetOptions" :value="cumulativeTargetValue" v-on:change="onCumulativeTargetChange" />

		<com-toggle :options="cumulativePeriodOptions" :value="cumulativePeriodValue" v-on:change="onCumulativePeriodChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'
 
		},

		data: function() {

			return this.$store.getters['page/insights/shelf/cumulative']

		},

		active: function() {

			return this.data.length && !this.loading

		},

		cumulativeTargetOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.SHELF.CUMULATIVE.DESIGNERS, text: 'Designers'})
			options.push({value: this.$CONSTANTS.INSIGHTS.SHELF.CUMULATIVE.PUBLISHERS, text: 'Publishers'})

			return options

		},

		cumulativeTargetValue: function() {

			return this.$store.getters['filter/insights/shelf/cumulative/who']

		},

		cumulativePeriodOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.SHELF.LONGEST.CURRENT, text: 'Current'})
			options.push({value: this.$CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL, text: 'All time'})

			return options

		},

		cumulativePeriodValue: function() {

			return this.$store.getters['filter/insights/shelf/cumulative/period']

		}

	},

	methods: {

		onCumulativeTargetChange: function(value) {

			this.$store.commit('filter/insights/shelf/cumulative/who', value)

		},

		onCumulativePeriodChange: function(value) {

			this.$store.commit('filter/insights/shelf/cumulative/period', value)

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsShelfCumulative',
				target: this.$store.getters['filter/insights/shelf/cumulative/who'],
				period: this.$store.getters['filter/insights/shelf/cumulative/period']
			})

		},

		onPersonClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsShelfPerson',
				cumulative: this.$store.getters['filter/insights/shelf/cumulative'],
				specific: id
			})

		}

	}

}

</script>

<style scoped>

.games-item {
	display: flex; 
	flex-direction: column;
}

.games-item-head {
	width: 100%;
	flex-shrink: 0;
	border-bottom: 3px solid transparent;
	max-height: 180px;
}

.games-item-head[data-band="10"] { border-color: #249563; }
.games-item-head[data-band="9"] { border-color: #249563; }
.games-item-head[data-band="8"] { border-color: #2FC482; }
.games-item-head[data-band="7"] { border-color: #1d8acd; }
.games-item-head[data-band="6"] { border-color: #5369a2; }
.games-item-head[data-band="5"] { border-color: #5369a2; }
.games-item-head[data-band="4"] { border-color: #df4751; }
.games-item-head[data-band="3"] { border-color: #df4751; }
.games-item-head[data-band="2"] { border-color: #db303b; }
.games-item-head[data-band="1"] { border-color: #db303b; }
.games-item-head[data-band="0"] { border-color: transparent; }

.games-item-head:before {
	content: "";
    display: block;
    padding-top: 100%;
}

.games-item-head-value {
	position: absolute;
	right: 0px;
	bottom: -3px;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 21px!important;
}

.games-item-content {
	border-right: 1px solid #ddd;
	border-top: 0px;
	flex-grow: 1;
	padding: 7px;
	display: flex;
	flex-direction: column;
}

.games-item:last-child .games-item-content {
	border-right: 0px;
}

.games-item-content-title {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 7px;
}

.games-item-content-title-text {
	font-size: 16px;
	font-weight: 400;
	color: #333;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.games-item-content-title-date {
	font-size: 11px;
	font-weight: 300;
	color: #666;
	margin-top: 2px;
}

.games-item-content-title-tag {
	font-size: 10px;
	color: #fff;
	margin-top: 2px;
	padding: 2px 4px;
	border-radius: 4px;
	line-height: 10px;
	cursor: pointer;
	font-weight: 400;
	display: inline-block;
	cursor: initial;
	width: auto;
}

.games-item-content-title-tag.is-active {
	background-color: rgba(4, 143, 85, 0.9);
}

.games-item-content-title-tag.is-played {
	background-color: rgba(51, 149, 181, 0.75);
}

.games-item-content-title-tag.is-removed {
	background-color: rgba(143, 36, 4, 0.9);
}

.games-item-content-stats {
	height: 32px;
	display: flex;
	flex-direction: row;
}

.games-item-content-stats-plays {
	font-size: 32px;
	color: #333;
	font-weight: 500;
}

.games-item-content-stats-plays >>> span.currency {
	font-size: 18px;
	margin-right: 1px;
	opacity: 0.75;
}

.games-item-content-stats-plays small {
	font-size: 16px;
	font-weight: 400;
	opacity: 0.5;
	margin-left: 4px;
}

</style>