<template>

<core-config-section :locked="!loggedin">

	<h2>Rated</h2>

	<p>If you want to include games that you have rated but not logged a play for.</p>

	<div class="inputs">

		<core-filter-input-toggle text="Include" on="Yes" off="No" filter="config/lists/unplayed/rated" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>