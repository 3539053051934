import API from 'core/api'

export default {

	namespaced: true,

	actions: {

		create: function(context, params) {

			return API.request('tags/create', params)

		},

		add: function(context, params) {

			return API.request('tags/add', params)

		},

		remove: function(context, params) {

			return API.request('tags/remove', params)

		},

		toggle: function(context, params) {

			return API.request('tags/toggle', params)

		},

		clear: function(context, params) {

			return API.request('tags/clear', params)

		},

		update: function(context, params) {

			return API.request('tags/update', params)

		},

		delete: function(context, params) {

			return API.request('tags/delete', params)

		}

	}

}