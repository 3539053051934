<template>

<com-item name="worth.plays" :size="size" :has-more="true" v-on:more="onMoreClick" :empty-cards="empty" :is-cards="true" :is-empty="!active" :loading="loading" title="Trending" v-if="active">

	<template v-slot:about>

		<p v-if="trendingDirectionValue === $CONSTANTS.INSIGHTS.WORTH.TRENDING.DIRECTION.UP">{{ your | capitalise }} games that are trending up in value.</p>
		<p v-if="trendingDirectionValue === $CONSTANTS.INSIGHTS.WORTH.TRENDING.DIRECTION.DOWN">{{ your | capitalise }} games that are trending down in value.</p>

	</template>

	<template v-slot:stats>

		<com-card :item="item" v-for="item in games" :key="item.g" :id="item.g" :rating="item.r" :name="$store.getters['data/games/associated'][item.g].name" :count="item.p" count-noun="play">

			<div class="games-item-content-stats-plays" v-html="formatcurrency(item.w, 0)"></div>

			<app-disparity :value="item.d" class="trending-item-content-stats-disparity" />
			
		</com-card>

	</template>

	<template v-slot:buttons>

		<com-toggle :options="trendingDirectionOptions" :value="trendingDirectionValue" v-on:change="onTrendingDirectionChange" />
		<com-toggle :options="trendingPeriodOptions" :value="trendingPeriodValue" v-on:change="onTrendingPeriodChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'
import comCard from './../common/Card'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle,
		'com-card': comCard
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'trending.direction' || this.fetching === 'trending.period'

		},

		trendingDirectionOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.TRENDING.DIRECTION.UP, text: 'Trending up'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.TRENDING.DIRECTION.DOWN, text: 'Trending down'})

			return options

		},

		trendingDirectionValue: function() {

			return this.$store.getters['filter/insights/worth/trending/direction']

		},

		trendingPeriodOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.TRENDING.PERIOD.QUARTER, text: 'Over 3 months'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.TRENDING.PERIOD.HALF, text: 'Over 6 months'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.TRENDING.PERIOD.YEAR, text: 'Over 12 months'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.TRENDING.PERIOD.OWNED, text: 'Since owned'})

			return options

		},

		trendingPeriodValue: function() {

			return this.$store.getters['filter/insights/worth/trending/period']

		}, 

		games: function() {

			return this.$store.getters['page/insights/worth/trending']

		},

		empty: function() {

			var empty = []

			for (var i = this.games.length; i < 5; i++) {

				empty.push(true)

			}

			return empty.length

		},

		active: function() {

			return this.games.length && !this.loading

		}

	},

	methods: {
	
		onTrendingDirectionChange: function(value) {

			this.$store.commit('filter/insights/worth/trending/direction', value)

		},

		onTrendingPeriodChange: function(value) {

			this.$store.commit('filter/insights/worth/trending/period', value)

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthTrending',
				filter: this.$store.getters['filter/insights/worth']
			})

		}
	
	}

}

</script>

<style scoped>

.games-item-content-stats-plays {
	font-size: 32px;
	line-height: 38px;
	color: #333;
	font-weight: 500;
	width: 50%;
	padding-left: 10px;
}

.games-item-content-stats-plays >>> span.currency {
	font-size: 18px;
	margin-right: 1px;
	opacity: 0.75;
}

.games-item-content-stats-plays small {
	font-size: 16px;
	font-weight: 400;
	opacity: 0.5;
}

.trending-item-content-stats-disparity {
	width: 50%!important;
	text-align: right;
	line-height: 38px;
	padding-right: 10px;
}

</style>