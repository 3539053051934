import _ from 'underscore'

import Store from 'app/store'
import CONSTANTS from 'app/constants'

export default {

	plural: function(text, count) {

		var special = text.substr(-2) === 'ty' || text.substr(-2) === 'ry'
		var suffix = (special) ? 'ies' : 's'
		var transformed = (special && count !== 1) ? text.substr(0, text.length - 1) : text

		return ' ' + transformed + ((count !== 1) ? suffix : '')

	},

	url: {

		user: function(username) {

			return 'https://boardgamegeek.com/user/' + username

		}

	},

	thumbnail: {

		flag: function(id) {

			return [CONSTANTS.ASSETS, 'files/flags', id].join('/') + '.svg'

		},

		game: {

			generate: function(game, size, params) {

				size = size || 'small'
				params = params || {}
				game = (typeof game === 'object') ? game : {id: game}

				var url = [CONSTANTS.ASSETS, 'api/games/avatar', game.id]

				if (size) url.push(size)

				var urlParams = []
				
				_.each(params, function(value, key) {

					if (value) urlParams.push(key)

				})

				if (game.version) {

					urlParams.push('version')

				}

				if (urlParams.length) url.push(urlParams.join(','))

				if (game.version) {

					url.push(game.version.id)

				}

				return url.join('/').replace('//api', '/api')

			},

			small: function(game, params) {

				return this.generate(game, 'small', params)

			},

			medium: function(game, params) {

				return this.generate(game, 'medium', params)

			},

			large: function(game, params) {

				return this.generate(game, 'large', params)

			},

			box: function(game, params) {

				return this.generate(game, 'box', params)

			}

		}

	},

	filter: {

		analyse: function(route, template) {

			var self = this

			return new Promise(function(resolve, reject) {

				if (Store.getters['loggedin']) {

					var presets = Store.getters['session/presets/' + template]

					if (presets.length) {

						if (_.keys(route.query).length === 0) {

							var defaultFilter = _.findWhere(presets, {
								default: 1
							})

							if (defaultFilter) {

								Store.commit('filter/' + template + '/preset', defaultFilter.id)
								Store.commit('filter/' + template + '/set', defaultFilter.filter)

							} 

							resolve()

						} else {

							Store.dispatch('filter/' + template + '/analyse', route.query).then(function() {

								var activeFilter = Store.getters['filter/' + template]

								Store.commit('filter/' + template + '/preset', 0)

								_.each(presets, function(preset) {

									if (self.compare(activeFilter, preset.filter, template)) {

										Store.commit('filter/' + template + '/preset', preset.id)

									}

								})

								resolve()

							})

						}

					} else {

						reject(new Error('No presets saved'))

					}

				} else {

					reject(new Error('Not logged in'))

				}

			})

		},

		compare: function(a, b, template) {

			a = this.autofill(a, template)
			b = this.autofill(b, template)

			return _.isEqual(a, b)

		},

		differences: function(a, b, template, dontAutoFill, ignore) {

			dontAutoFill = dontAutoFill || false
			ignore = ignore || []

			if (!dontAutoFill) {

				a = this.autofill(a, template)
				b = this.autofill(b, template)

			}

			var differences = 0

			_.each(a, function(value, key) {

				if (!_.contains(ignore, key)) {

					if (typeof value === 'object') {

						if (Array.isArray(value)) {

							if (value.join(',') !== b[key].join(',')) differences++

						} else {

							differences += this.differences(value, b[key], template, true)

						}

					} else {

						if (value !== b[key]) differences++

					}
					
				}

			}.bind(this))

			return differences

		},

		templates: {},

		autofill: function(data, template) {

			data = JSON.parse(JSON.stringify(data))

			return this.templates[template](data)

		}

	}

}