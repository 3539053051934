<template>

<div class="nested" v-if="item.nested.length" :class="{'is-active': is.active}">

	<app-game-item v-for="subitem in nested" filter="collection" v-bind:key="subitem.id" :summarised="true" :condensed="true" :item="subitem" :fields="columns">

		<div class="nested-horizontal"></div>

	</app-game-item>

	<div class="nested-vertical"></div>

</div>

</template>

<script>

export default {

	props: ['item', 'columns'],

	data: function() {

		return {
			is: {
				active: false
			}
		}

	},

	computed: {

		nested: function() {

			var nested = []

			this.$_.each(this.item.nested, function(id) {

				nested.push(this.$store.getters['page/collection/nested/associated/collection'][id])

			}.bind(this))

			nested = this.$_.sortBy(nested, function(item) {

				return item.game.name

			})

			return nested

		}

	},

	created: function() {

		this.$pubsub.$on('nesting.open.' + this.item.id, this.onToggle.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('nesting.open.' + this.item.id, this.onToggle.bind(this))

	},

	methods: {

		onToggle: function() {

			this.is.active = !this.is.active

		}

	}

}

</script>

<style scoped>

.nested {
	padding-bottom: 3px;
	margin-top: -4px;
	display: none;
}

.nested.is-active {
	display: block;
}

.nested-horizontal {
	position: absolute;
	height: 1px;
	width: 16px;
	left: 8px;
	top: 16px;
	border-bottom: 1px dotted #ccc;
}

.nested-vertical {
	position: absolute;
	border-left: 1px dotted #ccc;
	top: -4px;
	bottom: 20px;
	left: 8px;
	width: 1px;
}

.is-mobile .nested-vertical {
	top: -28px;
	bottom: 48px;
}

</style>