<template>

<core-filter-advanced-section class="section" :class="{'is-locked': locked}">

	<slot></slot>

</core-filter-advanced-section>

</template>

<script>

export default {

	props: ['locked']
	
}

</script>

<style scoped>

.section {
	padding: 20px 20px 7px 20px!important;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.section.is-locked {
	opacity: 0.5;
	pointer-events: none;
}

.section:last-child {
	border-bottom: 0px;
}

</style>