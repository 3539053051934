<template>
	
<div class="owned" v-if="owners.length">

	<core-avatar v-for="member in owners" class="owned-member" type="user" v-bind:key="member.id" :id="member.id" :tooltip="true" />

	<div class="owned-more owned-member" v-if="totalOwners > maxlength" v-on:click="onOwnersClick">+{{ totalOwners - maxlength }}</div>

</div>

</template>

<script>

export default {

	props: ['item', 'format', 'max'],

	data: function() {

		return {
			owners: []
		}

	},

	computed: {

		maxlength: function() {

			return (this.max) ? this.max : ((this.window.is.desktop) ? 10 : 5)

		},

		totalOwners: function() {

			return this.item.users.status.own.length

		},

		type: function() {

			return this.$store.getters['context/type']

		},

		statuses: function() {

			return this.$store.getters['filter/collection/status/active']

		}

	},

	created: function() {

		this.owners = []

		if (this.type === this.$CONSTANTS.TYPE.GROUPS || this.type === this.$CONSTANTS.TYPE.BUDDIES) {

			this.$_.each(this.item.users.status[this.statuses[0]], function(id) {

				if (this.$store.getters['data/users/associated'][id]) this.owners.push(this.$store.getters['data/users/associated'][id])

			}.bind(this))

			this.owners = this.$_.sortBy(this.owners, 'sortname')

			if (this.owners.length > this.maxlength) {

				this.owners = this.owners.slice(0, this.maxlength)

			}

		}

	},

	methods: {

		onOwnersClick: function() {

			this.$pubsub.$emit('game.' + this.item.game.id + '.expand.owners')

		}

	}

}

</script>

<style scoped>

.owned {
	margin-top: 2px;
	height: 24px;
	display: block;
	width: 100%;
}

@media only screen and (max-width: 767px) {

	.owned {
		display: none;
	}

}

.owned-member {
	width: 24px;
	height: 24px;
	display: inline-block;
	border-radius: 12px;
	margin-right: 2px;
	vertical-align: top;
	font-size: 12px;
}

.owned-more {
	display: inline-block;
	background-color: #999;
	color: #fff;
	font-size: 10px;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.is-desktop .owned-more:hover {
	background-color: #666;
}

.owned-all {
	display: none;
}

</style>
