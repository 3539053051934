<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary title="Lists" icon="list-ol" :count="count" :maximum="maximum" noun="list" :loading="loading" />

	</core-filter-bar-left>

	<core-filter-bar-right>

		<core-button v-if="canAdd" v-on:click.native="onCreateClick" theme="filter" icon="plus-circle">Create list</core-button>

	</core-filter-bar-right>

</core-filter-bar>

</template>

<script>

export default {

	props: ['loading', 'count', 'maximum'],

	computed: {

		canAdd: function() {

			return this.$store.getters['permissions/list/create']

		}

	},

	methods: {

		onCreateClick: function() {

			this.$pubsub.$emit('open.modal', 'listCreate')

		}

	}

}

</script>

<style scoped>

</style>
