<template>

<app-page centralise="yes">

	<div class="fail">

		<h1>Something broke!</h1>

		<p>Sorry, there was an error that caused the app to crash.</p>

		<core-button v-on:click.native="onClick" theme="blue">Go to home</core-button>

	</div>

</app-page>

</template>

<script>

export default {

	methods: {

		onClick: function() {

			this.$router.push({
				name: 'Home'
			}).catch(function(e) { console.log(e) })

		}

	}

}

</script>

<style scoped>

.fail {
	text-align: center;
}

.fail h1 {
	font-size: 48px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 15px;
}

.fail p {
	font-size: 14px;
	color: #fff;
	margin-bottom: 15px;
}

</style>