<template>
	
<div class="name">
				
	<div class="name-text">			

		<core-gamename :game="item.game" :id="item.id" class="name-text-link" />

		<com-tags :item="item" />

	</div>

	<core-date :date="item.play.date" class="name-date" />

</div>

</template>

<script>

import comTags from './Tags'

export default {

	props: ['item'],

	components: {
		'com-tags': comTags
	}

}

</script>

<style scoped>

.name {
	display: flex;
	flex-direction: row;
	padding: 0px 10px 10px 10px;
}

.name-text {
	flex-grow: 1;
	min-width: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.name-text-link {
	overflow: hidden;
	min-width: 0;
	display: block;
	width: calc(100% - 15px);
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	cursor: pointer;
	overflow: hidden;
	color: #333;
	font-weight: 500;
}

.is-desktop .name-text-link:hover {
	text-decoration: underline;
}

.name-date {
	flex-shrink: 0;
	height: 32px;
}

</style>
