import Vue from 'vue'
import CONSTANTS from 'app/constants'
import router from 'app/router'

export default {

	namespaced: true,

	state: {

		ranking: {
			band: 0
		},

		designers: CONSTANTS.INSIGHTS.RATINGS.DESIGNERS.BEST,
		publishers: CONSTANTS.INSIGHTS.RATINGS.PUBLISHERS.BEST,
		type: 'category',
		weight: 'polar',
		year: 'version',
		'trending.direction': 'up'

	},

	getters: {

		active: function(state) {

			return {
				'ranking.band': state.ranking.band,
				'designers': state.designers,
				'publishers': state.publishers,
				'type': state.type !== 'category',
				'weight': state.weight !== 'polar',
				'trending.direction': state['trending.direction'] !== 'up',
				'year': state.year !== 'version'
			}

		},

		values: function(state) {

			return {
				'ranking.band': state.ranking.band,
				'designers': state.designers,
				'publishers': state.publishers,
				'type': state.type,
				'weight': state.weight,
				'trending.direction': state['trending.direction'] ,
				'year': state.year
			}

		},

		'ranking/band': function(state) {

			return state.ranking.band

		},

		designers: function(state) {

			return state['designers']

		},

		publishers: function(state) {

			return state['publishers']

		},

		weight: function(state) {

			return state.weight

		},

		type: function(state) {

			return state.type

		},

		'trending/direction': function(state) {

			return state['trending.direction']

		},

		year: function(state) {

			return state.year

		}

	},

	mutations: {

		reset: function(state) {

			state.ranking.band = 0
			state.type = 'category'
			state['trending.direction'] = 'up'
			state.year = 'version'
			Vue.set(state, 'designers', CONSTANTS.INSIGHTS.RATINGS.DESIGNERS.BEST)
			Vue.set(state, 'publishers', CONSTANTS.INSIGHTS.RATINGS.PUBLISHERS.BEST)
			state.weight = 'polar'

		},

		'ranking/band': function(state, value) {

			state.ranking.band = parseInt(value)

		},

		'designers': function(state, value) {

			Vue.set(state, 'designers', value)

		},

		'publishers': function(state, value) {

			Vue.set(state, 'publishers', value)

		},

		year: function(state, value) {

			state.year = value

		},

		weight: function(state, value) {

			state.weight = value

		},

		type: function(state, value) {

			state.type = value

		},

		'trending/direction': function(state, value) {

			state['trending.direction'] = value

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params['ranking.band']) context.state.ranking.band = parseInt(params['ranking.band'])
					if (params['designers']) context.state['designers'] = params['designers']
					if (params['year']) context.state.year = params['year']
					if (params['publishers']) context.state['publishers'] = params['publishers']
					if (params['trending.direction']) context.state['trending.direction'] = params['trending.direction']
					if (params['type']) context.state.type = params['type']
					if (params['weight']) context.state.weight = params['weight']

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}
			
			if (context.state.ranking.band !== 0) urlParams['ranking.band'] = context.state.ranking.band
			if (context.state['designers'] !== 'best') urlParams['designers'] = context.state['designers']
			if (context.state['publishers'] !== 'best') urlParams['publishers'] = context.state['publishers']
			if (context.state.year !== 'version') urlParams['year'] = context.state.year
			if (context.state['trending.direction'] !== 'up') urlParams['trending.direction'] = context.state['trending.direction']
			if (context.state.type !== 'category') urlParams['type'] = context.state.type
			if (context.state.weight !== 'polar') urlParams['weight'] = context.state.weight

			router.replace({name: 'InsightsRatings', query: urlParams}).catch(function() {})

		}

	}

}