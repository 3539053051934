<template>

<div class="dropdown" v-on:mouseover="onMouseEnter" v-on:mouseleave="onMouseLeave" v-bind:class="{'is-expanded': is.expanded || expanded}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['trigger', 'expanded', 'silentExpand'],

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		isExpanded: function() {

			return this.is.expanded || this.expanded

		}

	},

	watch: {

		isExpanded: function(newValue, oldValue) {

			if (!this.silentExpand) {

				if (newValue !== oldValue) this.$pubsub.$emit((newValue) ? 'open.dropdown' : 'close.dropdown', this._uid)

			}

		},

		$route: function() {

			if (this.trigger === 'manual') {

				this.$emit('forceClose')

			} else {

				this.is.expanded = false

			}

		}

	},

	created: function() {

		this.$pubsub.$on('filter.opened', this.onFilterOpened.bind(this))
		this.$pubsub.$on('dropdown.action', this.onDropdownAction.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('filter.opened', this.onFilterOpened.bind(this))
		this.$pubsub.$off('dropdown.action', this.onDropdownAction.bind(this))

	},

	methods: {

		onFilterOpened: function() {

			this.$emit('forceClose')

		},

		onDropdownAction: function() {

			this.$emit('forceClose')

		},

		onMouseEnter: function() {

			if (this.trigger !== 'manual') this.is.expanded = true

		},

		onMouseLeave: function() {

			if (this.trigger !== 'manual') this.is.expanded = false

		}

	}
	
}

</script>

<style scoped>

.dropdown.is-expanded .dropdown-list {
	display: block;
}

.dropdown.is-expanded .dropdown-list.is-grouped {
	display: flex;
}

@media only screen and (max-width: 767px) {

	.dropdown.is-expanded .dropdown-list.is-grouped {
		display: block;
	}

}

</style>
