<template>

<div class="context" :class="{'is-inline': isInline}" v-bind:style="{'marginLeft': (isInline && window.menuIndentContent !== '320px') ? window.menuIndentContent : 0}">

    <core-avatar type="context" class="context-avatar">
        <core-flag :id="country" class="context-flag" v-tooltip="countryName" v-if="!isInline" />
    </core-avatar>

    <div class="context-info" v-if="!isInline || window.is.mobile">
        <div class="context-name">{{ realname }}</div>
        <div class="context-username" v-if="isUser">@{{ username }}</div>
        <div class="context-username" v-if="isGroup">Group</div>
        <div class="context-username" v-if="isBuddies">Buddies</div>
        <div class="context-updated" v-if="isUser && !isInline" v-tooltip="'Click to learn more about data updates'" v-on:click="onSyncClick"><span>Updated {{ lastSync | fromNow }}</span></div>
    </div>

    <core-button v-on:click.native="onManageClick" theme="darkblue" icon="pencil" :iconOnly="true" tooltip="Manage group" class="context-action" v-if="canDoSomething" />
        
    <core-button v-on:click.native="onJoinClick" theme="darkblue" icon="user-plus" class="context-action" tooltip="Join group" v-if="canJoin" />
        
    <core-button v-on:click.native="onLeaveClick" theme="darkblue" icon="user-minus" class="context-action" tooltip="Leave group" v-if="canLeave" />

    <core-button v-on:click.native="onMenuOpenClick" theme="darkblue" icon="bars" class="context-action" v-if="!window.is.desktop && isInline" />
    
    <core-button v-on:click.native="onMenuCloseClick" theme="darkblue" icon="xmark" class="context-action" v-if="!window.is.desktop && !isInline" />
    
</div>

</template>

<script>

export default {

    props: ['isInline'],

    computed: {

        canDoSomething: function() {

			return this.canDelete || this.canAdd || this.canEdit

		},

		canJoin: function() {

			return this.$store.getters['permissions/group/join']

		},

		canLeave: function() {

			return this.$store.getters['permissions/group/leave'] 

		},

		canDelete: function() {

			return this.$store.getters['permissions/group/delete']

		},

		canAdd: function() {

			return this.$store.getters['permissions/group/add']

		},

		canEdit: function() {

			return this.$store.getters['permissions/group/edit']

		},

		username: function() {

			return this.$store.getters['context'].name 

		},

		realname: function() {

			return this.$store.getters['context'].fullname || this.$store.getters['context'].name 

		},

		country: function() {

			return this.$store.getters['context'].country

		},

        lastSync: function() {
        
            return this.$store.getters['context'].date.sync

        },

		countryName: function() {

			return this.$CONSTANTS.COUNTRY[this.$store.getters['context'].country]

		}

	},

    methods: {

        onMenuOpenClick: function() {
        
            this.$pubsub.$emit('menu.open')
        
        },

        onMenuCloseClick: function() {
        
            this.$pubsub.$emit('menu.close')
        
        },
    
        onSyncClick: function() {
        
            this.$pubsub.$emit('open.modal', 'appSyncs')
        
        },

		onManageClick: function() {

			this.$pubsub.$emit('open.modal', 'groupEdit')

		},

		onLeaveClick: function() {

			this.$pubsub.$emit('open.modal', 'groupLeave')

		},

		onJoinClick: function() {

			if (this.loggedin) {

				this.$pubsub.$emit('open.modal', 'groupJoin')

			} else {

				this.$pubsub.$emit('open.modal', 'userLogin')

			}

		}
    
    }

}

</script>

<style scoped>

.context {
    padding: 0px 20px;
    height: 88px;
    flex-shrink: 0;
    background-color: #19455c;
    display: flex;
    align-items: center;
}

.is-mobile .context {
    padding: 10px 10px;
    height: auto;
}

.is-smaller .context.is-inline {
    position: fixed;
    height: 64px;
    top: 64px;
    left: 0px;
    width: 142px;
    padding: 0px 20px 0px 14px;
    z-index: 100;
	transition: margin-left 100ms linear;
}

.is-mobile .context.is-inline {
    position: fixed;
    height: 48px;
    top: 48px;
    left: 0px;
    padding: 0px 10px;
    width: 100%;
    z-index: 100;
}

.is-minimised .context {
    padding: 0px;
}

.context:not(.is-inline):after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #19455c transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    left: 27px;
    bottom: -10px;
}

.is-minimised .context:after {
    display: none;
}

.context-avatar {
    width: 48px;
    height: 48px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 50%;
}

.is-smaller .context.is-inline .context-avatar {
    width: 40px;
    height: 40px;
    margin: 0px 4px 0px 14px;
}

.is-mobile .context.is-inline .context-avatar {
    width: 32px;
    height: 32px;
    margin: 0px 4px 0px 0px;
}

.is-minimised .context-avatar {
    border-radius: 0px;
}

.is-mobile .context-avatar {
    margin-right: 10px;
}

.context-action {
    width: 40px;
    margin-left: 5px;
    flex-shrink: 0;
}

.context-action >>> .button-icon {
    margin-left: 0px;
}

.is-mobile .context-action {
    width: 32px;
    min-width: 32px;
    height: 32px;
}

.context-info {
    margin-left: 20px;
    flex-grow: 1;
}

.is-mobile .context-info {
    margin-left: 6px;
}

.is-minimised .context-info {
    display: none;
}

.context-name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
}

.is-mobile .context-name {
    font-size: 16px;
    letter-spacing: 0px;
}

.context-username {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 2px;
    color: #fff;
}

.is-smaller .context-username {
    margin-top: 4px;
    font-size: 14px;
}

.is-mobile .context-username {
    margin-top: 2px;
    font-size: 12px;
}

.context-updated {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 4px;
    color: #fff;
    opacity: 0.5;
}

.context-updated span {
    border-bottom: 1px dashed transparent;
}

.context-updated:hover {
    opacity: 0.8;
    cursor: pointer;
}

.context-updated:hover span {
    border-bottom: 1px dashed #fff;
}

.context-flag {
    width: 16px;
    height: 16px;
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    right: -3px;
    bottom: -3px;
}

.is-minimised .context-flag {
    display: none;
}

</style>
