<template>

<core-list-column :format="format" class="column">Game</core-list-column>

</template>

<script>

export default {

	props: ['format']

}

</script>

<style scoped>

.column {
	flex-grow: 1; 
	padding-left: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1119px) {

	.column {
		padding-left: 10px;
	}

}

@media only screen and (max-width: 767px) {

	.column {
		padding-left: 0px;
		min-width: 158px;
	}

}

</style>