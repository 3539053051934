<template>

<core-filter-config v-on:expand="onExpandClick" v-on:close="onCloseClick" v-on:save="onSaveClick" v-if="active" :state="is" :loginRequired="true" :selfOnly="true">

	<app-config-template-sync-plays-incomplete />

	<app-config-template-insights-hindex-expansions />
	
	<app-config-template-insights-heatmap-start />

</core-filter-config>

</template>

<script>

import Config from 'core/mixin/Config.js'

export default {

	mixins: [Config],

	data: function() {

		return {

			name: 'insights.plays'
			
		}

	},

	computed: {

		active: function() {

			return this.$route.name === 'InsightsPlays' 

		}

	},

	methods: {

		onAfterSave: function() {

			this.$emit('refresh')

		}

	}

}

</script>