<template>

<div v-click-outside="onOutsideClick">

	<core-button v-on:click.stop.native="onButtonClick" tooltip="Manage tags" theme="filter" icon="tags" :iconOnly="true" v-if="!hideButton" />

</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: ['state', 'loginRequired', 'selfOnly', 'hideButton'],

	methods: {

		onButtonClick: function() {

			this.$pubsub.$emit('filter.panel.tags.open')

		},

		onOutsideClick: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>
