<template>

<div class="toggle-wrapper" v-tooltip="(current.icon) ? current.text + '<small>Click to change</small>' : 'Click to change'" :data-theme="theme" :class="{'has-icon': icon, 'is-loading': loading}" v-click-outside="onOutsideClick">

	<div class="toggle-current" v-on:click="onExpandClick($event)">
		<template v-if="!icon && !current.icon">{{ current.text}} <i class="fa fa-caret-down"></i></template>
		<template v-if="!icon && current.icon"><i class="toggle-current-icon fa" :class="'fa-' + current.icon"></i></template>
		<i class="fa-solid" :class="icon" v-if="icon && !loading" /><i class="fa-solid fa-spin fa-spinner" v-if="icon && loading" />
	</div>

	<div class="toggle-list" v-if="is.expanded && !loading">

		<core-button v-for="option in options" v-bind:key="option.value" class="toggle-item" :class="{'is-active': value === option.value && !icon}" v-on:click.native="onOptionClick(option.value)">
			{{ option.text }} 
		</core-button>

	</div>

</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: ['options', 'value', 'icon', 'loading', 'theme'],

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		current: function() {

			var text = ''

			this.$_.each(this.options, function(option) {

				if (option.value === this.value) text = option

			}.bind(this))

			return text

		},

		iconClass: function() {

			return (this.is.expanded) ? 'check' : 'caret-right'

		}

	},

	methods: {

		onExpandClick: function(e) {

			e.stopPropagation()

			this.is.expanded = true

		},

		onOptionClick: function(value) {

			this.$emit('click', value)
			this.$emit('change', value)
			this.is.expanded = false

		},

		onOutsideClick: function() {

			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

.toggle-wrapper {
	height: 40px;
	position: relative;
	margin-left: 4px;
}

.toggle-current {
	padding: 0px 10px;
	font-weight: 400;
	width: 100%!important;
    text-align: left!important;
    line-height: 16px;
    font-size: 14px!important;
    color: #fff;
	min-width: 40px;
	height: 40px;
    cursor: pointer;
	display: flex;
	align-items: center;
	background-color: #4881bb;
	user-select: none;
	border-radius: 4px;
}

.toggle-wrapper[data-theme="white"] .toggle-current {
	background-color: #fff;
	color: #333;
}

.toggle-wrapper.is-loading .toggle-current {
	pointer-events: none;
	color: #ffc563;
}

.toggle-wrapper[data-theme="blue"] .toggle-wrapper.is-loading .toggle-current {
	color: #fff;
}

.toggle-wrapper.has-icon .toggle-current {
    font-size: 20px!important;
}

.toggle-current-icon {
	font-size: 20px;
}

.is-mobile .toggle-current {
	padding: 0px 8px;
}

.is-desktop .toggle-current:hover {
    background-color: #244d76;
}

.is-desktop .toggle-wrapper[data-theme="white"] .toggle-current:hover {
	background-color: #eee;
}

.toggle-current .fa-caret-down {
    font-size: 12px;
    margin-left: 8px;
}

.toggle-list {
	position: absolute;
	background-color: #4881bb;
	z-index: 1000;
	border-radius: 4px;
	padding: 4px 0px;
	top: 46px;
	right: 0px;
}

.toggle-wrapper[data-theme="blue"] .toggle-list {
	background-color: #fff;
}

.toggle-list:after {
	content: ' ';
    position: absolute;
    top: -6px;
    right: 14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #4881bb transparent;
}

.toggle-wrapper[data-theme="blue"] .toggle-list:after {
    border-color: transparent transparent #fff transparent;
}

.is-mobile .toggle-list {
	left: 0px;
	top: 40px;
	border-radius: 0px 0px 4px 4px;
}

.is-mobile .toggle-list:after {
    top: -6px;
    left: 15px;
    margin-top: 0px;
    border-width: 0px 6px 6px 6px;
    border-color: transparent transparent #035874 transparent;
}

.toggle-item {
	width: 100%!important;
    text-align: left!important;
    font-size: 14px!important;
	background-color: transparent!important;
	height: 24px!important;
	line-height: 24px!important;
	white-space: nowrap;
	border-radius: 0px!important;
	margin-left: 0px!important;
}

.toggle-wrapper[data-theme="blue"] .toggle-item {
	color: #333!important;
}

.toggle-wrapper[data-theme="blue"] .toggle-item:hover {
	color: #fff!important;
	background-color: #4881bb!important;
}

.toggle-item.is-active {
	font-weight: 700!important;
}

.is-stacked .toggle-item,
.is-mobile .toggle-item {
    padding: 0px 15px!important;
}

.is-desktop .toggle-item:hover {
	background-color: rgba(0, 0, 0, 0.25)!important;
}

.toggle.is-expanded .toggle-item >>> .button-icon {
	opacity: 0.25;
}

.toggle.is-expanded .toggle-item:hover >>> .button-icon {
	opacity: 1;
}

</style>