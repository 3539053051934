<template>

<div class="dropdown-group">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.dropdown-group {
	padding: 8px 0px 12px 0px;
}

@media only screen and (max-width: 479px) {

	.dropdown-group {
		padding: 0px;
	}

}

</style>
