import Vue from 'vue'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: {}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		summary: function(state) {

			return state.data.summary || false

		},

		valuations: function(state) {

			return state.data.valuations || []

		},

		overview: function(state) {

			return state.data.overview || {}

		},

		ratings: function(state) {

			return state.data.ratings || []

		},

		designers: function(state) {

			return state.data.designers || []

		},

		publishers: function(state) {

			return state.data.publishers || []

		},

		trending: function(state) {

			return state.data.trending || []

		},

		plays: function(state) {

			return state.data.plays || []

		},

		history: function(state) {

			return state.data.history || []

		},

		status: function(state) {

			return state.data.status || []

		},

		total: function(state) {

			return state.data.total || 0

		},

		games: function(state) {

			return state.data.games || []

		}

	},

	mutations: {

		reset: function(state) {

			state.data = {}

		},

		set: function(state, data) {

			_.each(data, function(value, key) {

				Vue.set(state.data, key, value)

			})

		}

	},

	actions: {}

}