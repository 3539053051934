<template>

<com-item name="worth.valuations" :size="size" :is-empty="!active" :loading="loading" title="Valuations">

	<template v-slot:about>

		<p>The spread of valuations across {{ your }} collection.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-segments :data="polar" v-on:click="onClick" v-if="isPolar" />
		<app-visualisation-bars :data="bars" v-on:click="onClick" v-if="!isPolar" />
		
	</template>

	<template v-slot:buttons>

		<com-toggle :options="graphOptions" :value="graphValue" v-on:change="onValuationsGraphChange" />
		<com-toggle :options="valuationsOptions" :value="valuationsValue" v-on:change="onValuationsChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		graphOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.GRAPH.POLAR, text: 'Polar chart', icon: 'pie-chart'})
			options.push({value: this.$CONSTANTS.INSIGHTS.GRAPH.BAR, text: 'Bar chart', icon: 'bar-chart'})

			return options

		},

		graphValue: function() {

			return this.$store.getters['filter/insights/worth/valuations/type']

		},

		valuationsOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.VALUATIONS.GAMES, text: 'Exclude expansions', icon: ' fa-duotone fa-puzzle-piece'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.VALUATIONS.ALL, text: 'Include expansions', icon: 'puzzle'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.VALUATIONS.EXPANSIONS, text: 'Only expansions', icon: 'puzzle-piece'})

			return options

		},

		valuationsValue: function() {

			return this.$store.getters['filter/insights/worth/valuations/expansions']

		},

		isPolar: function() {

			return this.$store.getters['filter/insights/worth/valuations/type'] === 'polar'

		},

		polar: function() {

			var segments = []

			var range

			for (var i=1; i <= 16; i++) {

				range = '{{value}} game{{value.plural}} between ' + this.convert(((i - 1) * 8) + 1) + ' and ' + this.convert(i * 8)

				if (i === 16) range = '{{value}} game{{value.plural}} ' + this.convert(((i - 1) * 8) + 1) + ' and higher'

				segments.push({
					label: range + '<small>Click to view games</small>'
				})

			}

			return {
				areas: [
					{title: 'Low', subtitle: this.convert(0) + ' - ' + this.convert(32)},
					{title: 'Low-Mid', subtitle:  this.convert(32) + ' - ' + this.convert(64)},
					{title: 'Mid-High', subtitle:  this.convert(64) + ' - ' + this.convert(96)},
					{title: 'High', subtitle:  this.convert(96) + ' or higher'}
				],
				clickable: true,
				size: 16,
				shades: [
					'rgba(17, 105, 135, 0.2)',
					'rgba(17, 105, 135, 0.4)',
					'rgba(17, 105, 135, 0.6)',
					'rgba(17, 105, 135, 0.8)',
					'rgba(17, 105, 135, 1)'
				],
				segments: segments,
				dataset: {
					data: this.valuations
				}
			}

		},

		bars: function() {

			var range
			var labels = []

			for (var i=1; i <= 16; i++) {

				range = this.convert(((i - 1) * 8) + 1) + ' - ' + this.convert(i * 8)

				if (i === 16) range = this.convert(((i - 1) * 8) + 1) + '+'

				labels.push({
					label: range
				})

			}

			return {
				datasets: [
					{ type: 'bar', data: this.valuations, backgroundColor: '#4881bb', tooltip: '{{value}} game{{value.plural}} <small>Value between {{x.label}}</small>' }
				],
				axis: {
					x: {
						labels: labels,
						long: true
					},
					y: {
						min: 10,
						points: 10
					}
				}
			}

		},

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'valuations' 

		},

		valuations: function() {

			return this.$store.getters['page/insights/worth/valuations']

		},

		active: function() {

			return this.valuations.length && !this.loading

		}

	},
	
	methods: {
	
		onValuationsGraphChange: function(value) {

			this.$store.commit('filter/insights/worth/valuations/type', value)

		},

		onValuationsChange: function(value) {

			this.$store.commit('filter/insights/worth/valuations/expansions', value)

		},

		convert: function(value) {

			return this.$CONSTANTS.CURRENCY[this.$store.getters['session/config'].other.currency].sign + this.formatcurrency(value, 0, true).toString()

		},

		onClick: function(band) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthValuations',
				band: band
			})

		}

	}

}

</script>

<style scoped>

</style>