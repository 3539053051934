<template>

<com-item name="records.monthly" :size="size" :loading="loading" :is-empty="!active" title="Activity">

	<template v-slot:stats>

		<app-visualisation-bars :data="graph" v-if="active" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'type'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			graph: {
				datasets: [
					{ type: 'bar', data: [], yAxis: 'left', backgroundColor: '#4881bb', tooltip: '{{value}} <small>Play{{value.plural}}</small>' },
					{ type: 'bar', data: [], yAxis: 'left', backgroundColor: '#3bce5a', tooltip: '{{value}} <small>Win{{value.plural}}</small>' }
				],
				axis: {
					x: {
						labels: [],
						type: 'month'
					},
					y: {
						left: {
							type: 'scale',
							min: 10,
							points: 5
						},
						right: false
					}
				}
			}
		}

	},

	created: function() {
	
		this.visualise()	

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/records/monthly']

		},

		active: function() {

			return this.$_.keys(this.data.months).length && !this.loading

		}

	},

	methods: {
	
		visualise: function() {

			if (this.$_.keys(this.data.months).length) {

				this.graph.axis.x.labels = []
				this.graph.datasets[0].data = []
				this.graph.datasets[1].data = []

				var end = this.$moment.unix(this.data.end).utc().startOf('month')

				var current = this.$moment.unix(this.data.start).utc().startOf('month')

				var stamp, monthWin, monthPlays, month

				while (current.isSameOrBefore(end)) {

					stamp = current.format('MM.YYYY')

					monthWin = 0
					monthPlays = 0

					if (this.data.months[stamp]) {

						month = this.data.months[stamp]
						monthWin = month.wins
						monthPlays = month.plays

					} 

					this.graph.axis.x.labels.push(current.format('MM.YY'))

					this.graph.datasets[0].data.push(monthPlays)
					this.graph.datasets[1].data.push(monthWin)

					current.add(1, 'month')

				}

			}

		}
	
	}

}

</script>

<style scoped>

</style>