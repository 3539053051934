import _ from 'underscore'
import CONSTANTS from 'app/constants'

export default {

	namespaced: true,

	modules: {},

	state: {

		data: {
			presets: []
		}

	},

	getters: {

		all: function(state) {

			return (state.data.presets) ? state.data.presets : []

		},

		collection: function(state) {

			var presets = []

			_.each(state.data.presets || [], function(preset) {

				if (preset.type === CONSTANTS.PRESET.TYPE.COLLECTION) presets.push(preset)

			})

			return presets

		},

		plays: function(state) {

			var presets = []

			_.each(state.data.presets || [], function(preset) {

				if (preset.type === CONSTANTS.PRESET.TYPE.PLAYS) presets.push(preset)

			})

			return presets

		},

		ratings: function(state) {

			var presets = []

			_.each(state.data.presets || [], function(preset) {

				if (preset.type === CONSTANTS.PRESET.TYPE.RATINGS) presets.push(preset)

			})

			return presets

		},

		lists: function(state) {

			var presets = []

			_.each(state.data.presets || [], function(preset) {

				if (preset.type === CONSTANTS.PRESET.TYPE.LISTS) presets.push(preset)

			})

			return presets

		},

		activity: function(state) {

			var presets = []

			_.each(state.data.presets || [], function(preset) {

				if (preset.type === CONSTANTS.PRESET.TYPE.ACTIVITY) presets.push(preset)

			})

			return presets

		},

		associated: function(state) {

			var associated = []

			_.each(state.data.presets || [], function(preset) {

				associated[preset.id] = preset

			})

			return associated

		}

	},

	mutations: {

		set: function(state, data) {

			state.data.presets = data

		}

	}

}