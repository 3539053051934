<template>

<core-content-wrapper :loading="!is.ready">

	<core-filter :loading="is.fetching" :filter="insightName" />

	<core-content-page v-if="is.reset && is.fetching !== 'all'">

		<core-content-page-inner v-show="is.ready" :is-grid="true">

			<com-summary :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-valuations :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-overview :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-games :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-history :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-plays :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-trending :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-ratings :fetching="is.fetching" :your="your" :you="you" :size="6" />

			<com-status :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-designers :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-publishers :fetching="is.fetching" :your="your" :you="you" :size="2" />

		</core-content-page-inner>

	</core-content-page>

</core-content-wrapper>

</template>

<script>

import Section from './common/Section.js'

import comSummary from './worth/Summary'
import comRatings from './worth/Ratings'
import comPlays from './worth/Plays'
import comGames from './worth/Games'
import comValuations from './worth/Valuations'
import comHistory from './worth/History'
import comTrending from './worth/Trending'
import comDesigners from './worth/Designers'
import comPublishers from './worth/Publishers'
import comOverview from './worth/Overview'
import comStatus from './worth/Status'

export default {

	mixins: [Section],

	components: {
		'com-summary': comSummary,
		'com-ratings': comRatings,
		'com-plays': comPlays,
		'com-games': comGames,
		'com-valuations': comValuations,
		'com-history': comHistory,
		'com-trending': comTrending,
		'com-designers': comDesigners,
		'com-status': comStatus,
		'com-publishers': comPublishers,
		'com-overview': comOverview
	},

	data: function() {

		return {

			insightName: 'worth',

			dynamicInsights: [
				'games',
				'trending.direction',
				'trending.period',
				'valuations',
				'ratings',
				'plays',
				'designers',
				'publishers'
			]

		}

	}

}

</script>