<template>

<div class="textbox" v-bind:class="{[bkgClass]: true}">

	<input 
		:name="name"
		ref="text"
		type="password" 
		:autocomplete="(newpassword) ? 'new-password' : 'on'"
		:placeholder="placeholder" 
		v-bind:value="value" 
		v-on:keyup.enter="$emit('enter')"
		v-on:input="$emit('input', $event.target.value)" 
		class="textbox-input" 
		:disabled="disabled"
		v-bind:class="{
			'is-error': error,
			'is-disabled': disabled
		}"
	/>

	<div v-if="error && errorMessage" class="textbox-error">{{ errorMessage }}</div>

	<div v-show="validation && validationMessage !== 'off'" class="textbox-validation">{{ validationFeedback }}</div>

</div>

</template>

<script>

import InputValidation from 'core/mixin/form/input/Validation.js'

export default {

	mixins: [InputValidation],

	props: [
		'name',
		'placeholder', 
		'value',
		'autofocus',
		'newpassword',
		'match',
		'errorMessage',
		'disabled',
		'error',
		'validation',
		'validationMessage',
		'background'
	],

	computed: {

		bkgClass: function() {

			if (this.$_.contains(['light', 'dark'], this.background)) return 'bkg-' + this.background

			return 'bkg-light'

		}

	},

	created: function() {

		if (this.autofocus) {

			this.$nextTick(function() {

				this.$refs.text.focus()
			
			}.bind(this))
			
		}

	},

	methods: {

		validate: function(rules) {

			if (rules.format) {

				if (this.value.length > 0) {

					if (this.value.length < 8) {

						this.feedback.push('too short')

					}

					if (!this.value.match(/[a-zA-Z]/)) {

						this.feedback.push('must contain at least 1 letter')

					}

					if (!this.value.match(/[0-9]/)) {

						this.feedback.push('must contain at least 1 number')

					}

				} else {

					this.feedback.push('required')

				}

			}

			if (rules.exists) {

				if (this.value.trim().length === 0) {

					this.feedback.push('')

				}

			}

			if (rules.match) {

				if (this.value.length > 0) {

					if (this.value !== this.match) {

						this.feedback.push('does not match')

					}

				} else {

					this.feedback.push('required')

				}

			}

		}

	}

}

</script>

<style scoped>

.textbox {
	margin-bottom: 20px;
}

.textbox-input {
	height: 40px;
	line-height: 40px;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 400;
	padding: 8px;
	width: 100%;
}

@media only screen and (max-width: 767px) {

	.textbox-input {
		font-size: 12px;
	}

}

.textbox.bkg-light .textbox-input {
	background-color: #fff;
	border: 2px solid #fff;
	color: #333;
}

.textbox-input.bkg-light.is-error {
	border: 1px solid #ce5f5f;
}

.textbox.bkg-light .textbox-input::placeholder {
	color: #666;
}

.textbox.bkg-dark .textbox-input {
	background-color: #3395B5;
	color: #fff;
}

.textbox-input.bkg-dark:focus {
}

.textbox-input.bkg-dark.is-error {
}

.textbox.bkg-dark .textbox-input::placeholder {
	color: rgba(255, 255, 255, 0.25);
}

.textbox-input.is-disabled {
	opacity: 0.6;
	background-color: #eee;
}

.textbox-validation {
	position: absolute;
	right: 4px;
	top: 4px;
	color: #ce5f5f;
	font-size: 11px;
	line-height: 30px;
	height: 30px;
	white-space: nowrap;
	text-align: right;
	padding: 0px 7px;
}

.textbox-error {
	padding: 5px 8px;
	background-color: #d06f6f;
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

.textbox-error:before {
	content: ' ';
	position: absolute;
	left: 8px;
	top: -8px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 8px 8px 8px;
	border-color: transparent transparent #d06f6f transparent;
}

</style>
