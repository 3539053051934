<template>

<core-panel>

	<template v-slot:head>

		<h1>Publish years</h1>

		<p>The years in which played games were published.</p>

	</template>

	<template v-slot:body>

		<div class="year-wrapper">

			<div class="year-item" :data-band="band(year.count)" v-for="year in years" :key="year.year" v-on:click="onYearClick($event, year.year)">

				<div class="year-item-count">{{ year.count }}</div>
				<div class="year-item-year">{{ year.year | abs }}<template v-if="year.year < 0">BC</template></div>

			</div>

		</div>

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'years',
			years: []
		}

	},

	methods: {

		response: function(json) {

			this.years = json.years

		},

		onYearClick: function(e, year) {

			e.stopPropagation()

			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysYear',
				year: year,
				child: true
			})

		},

		band: function(count) {

			if (count) {

				var perBand = this.$_.max(this.years, function(item) { return item.count }).count / 4
				var band = Math.ceil(count / perBand)

				return band

			} else {

				return 0

			}

		}

	}

}

</script>

<style scoped>

.year-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.year-item {
	height: 64px;
	text-align: center;
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	cursor: pointer;
}

.year-item[data-band="0"] {
	background-color: rgba(17, 105, 135, 1);
	color: rgba(255, 255, 255, 0.25);
	pointer-events: none;
}

.year-item[data-band="1"] {
	background-color: rgba(17, 105, 135, 0.7);
}

.year-item[data-band="2"] {
	background-color: rgba(17, 105, 135, 0.4);
}

.year-item[data-band="3"] {
	background-color: rgba(17, 105, 135, 0.1);
}

.year-item[data-band="4"] {
	background-color: #03212e;
}

.is-desktop .year-item:hover {
	background-color: #03212e;
}

.year-item-count {
	font-size: 21px;
	line-height: 24px;
	font-weight: 500;
}

.year-item-year {
	font-size: 12px;
	line-height: 14px;
	font-weight: 400;
	font-family: 'Roboto Mono', monospace;
}

</style>