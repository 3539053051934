<template>

<div class="item" :class="{'is-dark': dark}">

	<core-avatar v-if="user" type="user" :id="item.user" class="item-user-avatar" />

	<core-avatar type="game" :id="item.id" class="item-avatar" />

	<div class="item-name">

		<a :href="'https://boardgamegeek.com/boardgame/' + item.id" target="_blank">{{ name }}</a>

		<div class="item-name-subtext" v-if="subtext">{{ subtext }}</div>
		<div class="item-name-subtext" v-if="playsAsSubtext">{{ item.plays }} <plural t="play" :n="item.plays" /></div>

		<div class="item-name-tags" v-if="tags">

			<div class="item-name-tags-item" v-for="(tag, index) in tags" :key="index" :class="{'is-green': tag.green, 'is-blue': tag.blue, 'is-red': tag.red}">{{ tag.text }}</div>

		</div>

	</div>

	<core-rating v-if="item.rating" :value="item.rating" :count="item.count" :hideCount="hideCount" :ratings="item[ratingsField]" class="item-rating" v-on:more="$emit('ratings')" :showIndividualRatings="true" :transparent="true" />

	<div v-if="item.plays && !playsAsSubtext" class="item-count">{{ item.plays }}<small v-if="showPlaysText">play<template v-if="item.plays !== 1">s</template></small></div>
	<div v-if="item.worth && !$_.isObject(item.worth)" class="item-worth" v-html="formatcurrency(item.worth, 0)"></div>

	<div v-if="item.difference" class="item-difference" :class="{'is-up': item.difference > 0, 'is-down': item.difference < 0}">
		<i class="fa fa-caret-up" v-if="item.difference > 0"></i>
		<i class="fa fa-caret-down" v-if="item.difference < 0"></i>
		{{ item.difference | abs | round(1, true) }}
	</div>

	<div v-if="count" class="item-count" v-fit>{{ count }}<small v-if="countText">{{ countText }}</small></div>

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['item', 'subtext', 'ratings', 'tags', 'user', 'playsAsSubtext', 'showPlaysText', 'isUserRating', 'count', 'countText', 'dark'],

	computed: {

		hideCount: function() {

			return this.isUserRating || false

		},

		name: function() {

			return this.$store.getters['data/games/associated'][this.item.id].name

		},

		ratingsField: function() {

			return (this.ratings) ? this.ratings : 'ratings'

		}

	}

}

</script>

<style scoped>

.item {
	width: 100%;
	min-height: 64px;
	display: flex;
	padding: 8px 15px 8px 15px;
	flex-direction: row;
	align-items: center;
}

@media only screen and (max-width: 767px) {

	.item {
		padding: 8px 8px;
		height: auto;
	}

}

.item:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.item.is-dark:nth-child(odd) {
	background-color: rgba(255, 255, 255, 0.05);
}

.item-user-avatar,
.item-avatar {
	width: 48px;
	height: 48px;
	flex-shrink: 0;
}

.item-user-avatar {
	margin-right: 8px;
}

.item-name {
	padding: 0px 8px;
	flex-grow: 1;
	min-width: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.item-name a {
	font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #333;
    overflow: hidden;
    display: block;
    flex-shrink: 0;
}

.item.is-dark .item-name a {
	color: #fff;
	font-size: 14px;
}

@media only screen and (max-width: 767px) {

	.item-name a {
		font-size: 14px;
	}

}

.item-name-subtext {
	font-size: 11px;
	color: #666;
}

.item.is-dark .item-name-subtext {
	color: rgba(255, 255, 255, 0.75);
}

.is-desktop .item-name a:hover {
	text-decoration: underline;
}

.item-rating {
	flex-shrink: 0;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 18px;
	font-weight: 400;
}

.item-count {
	height: 48px;
	width: 48px;
    flex-shrink: 0;
    font-size: 24px;
    font-weight: 400;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.item.is-dark .item-count {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.item-count {
		font-size: 18px;
	}

}

.item-count small {
	font-size: 11px;
	color: #666;
	display: block;
}

.item.is-dark .item-count small {
	color: rgba(255, 255, 255, 0.75);
}

.item-worth {
	width: 70px;
    flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
    font-size: 24px;
    line-height: 48px;
    font-weight: 400;
    color: #333;
}

.item-worth >>> span {
	font-size: 12px;
	margin-right: 2px;
}

.item.is-dark .item-worth {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.item-worth {
		font-size: 18px;
	}

}

.item-name-tags {
	display: flex;
	flex-direction: row;
	margin-top: 2px;
}

.item-name-tags-item {
	font-size: 10px;
	color: #fff;
	margin-right: 4px;
	padding: 2px 4px;
	border-radius: 4px;
	line-height: 10px;
	cursor: pointer;
	font-weight: 400;
	display: inline-block;
	cursor: initial;
	width: auto;
}

.item-name-tags-item.is-red {
	background-color: #9A3D25;
}

.item-name-tags-item.is-green {
	background-color: #2F9B68
}

.item-name-tags-item.is-blue {
	background-color: #68B1C8
}

.item-difference {
	width: 70px;
    flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
    font-size: 24px;
    line-height: 48px;
    font-weight: 400;
    color: #333;
}

.item.is-dark .item-difference {
	color: #fff;
}

.item-difference.is-up {
	color: #2FC482!important;
}

.item-difference.is-down {
	color: #df4751!important;
}

.item-difference span {
	font-size: 12px;
}

.item-difference .fa {
	font-size: 12px;
	margin-right: 2px;
}

@media only screen and (max-width: 767px) {

	.item-difference {
		font-size: 18px;
	}

}

</style>