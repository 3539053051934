<template>

<core-list-column :format="format" class="column">{{ format.title }}</core-list-column>

</template>

<script>

export default {

	props: ['format']

}

</script>

<style scoped>

.column {
	width: 48px;
	flex-shrink: 0;
	text-align: center;
}

@media only screen and (max-width: 767px) {

	.column {
		width: 32px;
	}

}

</style>