<template>

<core-content-wrapper :loading="!is.ready">

	<core-filter :loading="is.fetching" :filter="insightName" v-on:refresh="onRefresh">

		<core-filter-toggle filter="config/insights/size/expansions" on="Exclude expansions" off="Include expansions" :wide="true" :borderless="true" />

	</core-filter>

	<core-content-page v-if="is.reset && is.fetching !== 'all'">

		<core-content-page-inner v-show="is.ready" :is-grid="true">

			<com-summary :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-overview :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-compare :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-activity :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-clusters type="added" :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-clusters type="removed" :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-clusters type="refreshed" :fetching="is.fetching" :your="your" :you="you" :size="2" />
			
			<com-lifespan :fetching="is.fetching" :your="your" :you="you" :size="6" />
			
			<com-sampled :fetching="is.fetching" :your="your" :you="you" :size="3" />
			
			<com-turnover :fetching="is.fetching" :your="your" :you="you" :size="3" />
			
			<com-changes :fetching="is.fetching" :your="your" :you="you" :size="6" />

		</core-content-page-inner>

	</core-content-page>

</core-content-wrapper>

</template>

<script>

import Section from './common/Section.js'

import comChanges from './size/Changes'
import comSummary from './size/Summary'
import comCompare from './size/Compare'
import comOverview from './size/Overview'
import comActivity from './size/Activity'
import comSampled from './size/Sampled'
import comClusters from './size/Clusters'
import comTurnover from './size/Turnover'
import comLifespan from './size/Lifespan'

export default {

	mixins: [Section],

	components: {
		'com-changes': comChanges,
		'com-activity': comActivity,
		'com-compare': comCompare,
		'com-clusters': comClusters,
		'com-sampled': comSampled,
		'com-turnover': comTurnover,
		'com-summary': comSummary,
		'com-overview': comOverview,
		'com-lifespan': comLifespan
	},

	data: function() {

		return {

			insightName: 'size',

			dynamicInsights: [
				'activity.expansions',
				'activity.period',
				'lifespan.expansions',
				'lifespan.period',
				'changes.type'
			]

		}

	},

	computed: {

		configExpansions: function() {

			return this.$store.getters['filter/config/insights/size/expansions']

		}

	},
	
	watch: {

		configExpansions: function() {

			this.is.refreshing = true
			this.is.ready = false
			this.is.fetching = 'all'

			this.$store.dispatch('api/config/save', {
				data: this.$store.getters['filter/config']
			}).then(function(json) {

				this.$store.commit('session/config/set', json.config)
				this.$store.commit('filter/config/reset', json.config)

				this.onRefresh()

			}.bind(this), function() {

				this.is.fetching = false

			}.bind(this)) 

		}

	}

}

</script>