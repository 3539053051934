<template>

<core-panel>

	<template v-slot:head>

		<h1><template v-if="data.y !== false">{{ data.y + 1 }} player<template v-if="data.y">s</template></template><template v-if="data.y !== false && data.x !== false">, </template><template v-if="data.x !== false">{{ durations[data.x] }}</template></h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" />.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :subtext="subtext(item)" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'bestduration',
			games: [],
			params: ['x', 'y', 'filter'],
			durations: {
				0: '0-1 hours',
				1: '1-2 hours',
				2: '2-3 hours',
				3: '3-4 hours ',
				4: '4-5 hours',
				5: '5-6 hours'
			}
		}

	},

	methods: {	

		response: function(json) {

			this.games = json.games

		},

		subtext: function(item) {

			return 'Best at ' + item.best.toString() + ' player' + ((item.best === 1) ? '' : 's') + ' / ' + Math.floor(item.time.toString()) + ' minutes'

		}

	}

}

</script>