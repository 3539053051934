<template>
	
<core-card-foot-item>

	<div class="card-foot-date-value"><core-date :date="value" invalid="n/a" /></div>

	<core-card-foot-item-label>

		{{ text }}

	</core-card-foot-item-label>

</core-card-foot-item>

</template>

<script>

export default {

	props: ['value', 'text']

}

</script>

<style scoped>

.card-foot-date-value {
	height: 32px;
	padding: 2px 0px;
	line-height: 32px;
}

</style>
