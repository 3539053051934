<template>

<core-filter-advanced-group title="Reports" :count="count">

	<core-filter-input-text placeholder="Search reports..." :filter="value('play/reports/text')" class="text" :inset="true" />

	<core-filter-input-toggles text="Has report" :values="required.values" :icons="required.icons" :labels="required.labels" :filter="value('play/reports/required')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],
	
	data: function() {

		return {
			fields: ['play.reports.required', 'play.reports.text'],
			required: {
				labels: ['Yes', 'No', 'Either'],
				icons: ['', '', '', ''],
				values: ['yes', 'no', 0]
			}
		}
	}

}

</script>

<style scoped>

.text {
	padding: 0px!important;
}

</style>