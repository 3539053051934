import CONSTANTS from 'app/constants'
import router from 'app/router'

export default {

	namespaced: true,

	state: {

		ranking: {
			band: 0
		},

		type: 'category',
		weight: 'polar',
		year: 'version',
		calendar: 'days',
		period: CONSTANTS.INSIGHTS.PERIOD.ALLTIME,
		heatmap: CONSTANTS.INSIGHTS.HEATMAP.PLAYS

	},

	getters: {

		active: function(state) {

			return {
				'ranking.band': state.ranking.band,
				'period': state.period,
				'heatmap': state.heatmap,
				'calendar': state.calendar !== 'days',
				'weight': state.weight !== 'polar',
				'type': state.type !== 'category',
				'year': state.year !== 'version'
			}

		},

		values: function(state) {

			return {
				'ranking.band': state.ranking.band,
				'period': state.period,
				'weight': state.weight,
				'calendar': state.calendar,
				'heatmap': state.heatmap,
				'type': state.type,
				'year': state.year
			}

		},

		'ranking/band': function(state) {

			return state.ranking.band

		},

		period: function(state) {

			return state.period

		},

		heatmap: function(state) {

			return state.heatmap

		},

		calendar: function(state) {

			return state.calendar

		},

		weight: function(state) {

			return state.weight

		},

		type: function(state) {

			return state.type

		},

		year: function(state) {

			return state.year

		}

	},

	mutations: {

		reset: function(state) {

			state.ranking.band = 0
			state.type = 'category'
			state.year = 'version'
			state.calendar = 'days'
			state.period = CONSTANTS.INSIGHTS.PERIOD.ALLTIME
			state.heatmap = CONSTANTS.INSIGHTS.HEATMAP.PLAYS
			state.weight = 'polar'

		},

		'ranking/band': function(state, value) {

			state.ranking.band = parseInt(value)

		},

		period: function(state, value) {

			state.period = value

			if (state.period !== 'all' && state.calendar === 'years') state.calendar = 'days'

		},

		heatmap: function(state, value) {

			state.heatmap = value

		},

		calendar: function(state, value) {

			state.calendar = value

		},

		type: function(state, value) {

			state.type = value

		},

		weight: function(state, value) {

			state.weight = value

		},

		year: function(state, value) {

			state.year = value

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params['ranking.band']) context.state.ranking.band = parseInt(params['ranking.band'])
					if (params.period) context.state.period = params.period
					if (params['year']) context.state.year = params['year']
					if (params.calendar) context.state.calendar = params.calendar
					if (params.heatmap) context.state.heatmap = params.heatmap
					if (params['type']) context.state.type = params['type']
					if (params['weight']) context.state.weight = params['weight']

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}
			
			if (context.state.ranking.band !== 0) urlParams['ranking.band'] = context.state.ranking.band
			if (context.state.period !== 'all') urlParams.period = context.state.period
			if (context.state.heatmap !== 'plays') urlParams.heatmap = context.state.heatmap
			if (context.state.heatmap !== 'days') urlParams.calendar = context.state.calendar
			if (context.state.year !== 'version') urlParams['year'] = context.state.year
			if (context.state.weight !== 'polar') urlParams['weight'] = context.state.weight
			if (context.state.type !== 'category') urlParams['type'] = context.state.type

			router.replace({name: 'InsightsPlays', query: urlParams}).catch(function() {})

		}

	}

}