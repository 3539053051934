<template>
	
<div class="play-tags">

	<div class="play-tags-item is-incomplete" v-if="item.play.incomplete">

		<div>Incomplete</div>

	</div>

	<div class="play-tags-item is-expansion" v-if="item.game.type === 2">

		<div>Expansion</div>

	</div>

	<div class="play-tags-item is-count" v-if="item.play.count > 1">

		<div>x{{item.play.count}}</div>

	</div>

	<div class="play-tags-item is-first" v-if="item.plays.user.first">

		<div>
			<i class="fa fa-user" v-if="!isUser"></i> 
			New
		</div>

	</div>

	<div class="play-tags-item is-plays" v-if="!item.plays.user.first && item.play.count === 1">

		<div>
			<i class="fa fa-user" v-if="!isUser"></i> 
			{{ item.plays.user.increment | nth }} play
		</div>

		<div class="play-tags-item-context" v-if="item.plays.user.contextIncrement">
			<i class="fa fa-calendar"></i> 
			{{ item.plays.user.contextIncrement | nth }}
		</div>

	</div>

	<div class="play-tags-item is-plays" v-if="!item.plays.user.first && item.play.count > 1">

		<div>
			<i class="fa fa-user" v-if="!isUser"></i> 
			{{ item.plays.user.increment - item.play.count + 1 | nth }} - {{ item.plays.user.increment | nth }} plays
		</div>

		<div class="play-tags-item-context" v-if="item.plays.user.contextIncrement">
			<i class="fa fa-calendar"></i> 
			{{ item.plays.user.contextIncrement - item.play.count + 1 | nth }} - {{ item.plays.user.contextIncrement | nth }} plays
		</div>

	</div>

	<template v-if="!isUser">

		<div class="play-tags-item is-first is-group" v-if="item.plays.group.first">

			<div>
				<i class="fa fa-users"></i> 
				New
			</div>

		</div>

		<div class="play-tags-item is-plays is-group" v-if="!item.plays.group.first && item.play.count === 1">

			<div>
				<i class="fa fa-users"></i> 
				{{ item.plays.group.increment | nth }}
			</div>

			<div class="play-tags-item-context" v-if="item.plays.group.contextIncrement">
				<i class="fa fa-calendar"></i> 
				{{ item.plays.group.contextIncrement | nth }}
			</div>

		</div>

		<div class="play-tags-item is-plays is-group" v-if="!item.plays.group.first && item.play.count > 1">

			<div>
				<i class="fa fa-users"></i> 
				{{ item.plays.group.increment - item.play.count | nth }} - {{ item.plays.group.increment | nth }}
			</div>

			<div class="play-tags-item-context" v-if="item.plays.group.contextIncrement">
				<i class="fa fa-calendar"></i> 
				{{ item.plays.group.contextIncrement - item.play.count | nth }} - {{ item.plays.group.contextIncrement | nth }}
			</div>

		</div>

	</template>

	<app-tags :item="item" />

</div>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.play-tags {
	display: flex;
	flex-direction: row;
	margin-top: 2px;
}

.play-tags-item {
	font-size: 10px;
	border-radius: 4px;
	font-weight: 400;
	color: #fff;
	margin-right: 4px;
	padding: 2px 4px;
	text-align: center;
	display: flex;
	flex-direction: row;
}

.play-tags-item-context {
	border-left: 1px solid rgba(255, 255, 255, 0.5);
	background-color: rgba(0, 0, 0, 0.1);
}

.play-tags-item-context .fa {
	font-size: 9px;
}

.play-tags-item.is-expansion {
	background-color: rgba(51, 149, 181, 0.75);
}

.play-tags-item.is-incomplete {
	background-color: rgb(128, 35, 20);
}

.play-tags-item.is-count {
	background-color: #666;
}

.play-tags-item.is-first {
	background-color: #1d8acd;
}

.play-tags-item.is-plays {
	background-color: #22af81;
}

.play-tags-item.is-group {
	background-color: #999;
}

</style>
