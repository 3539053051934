<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :subtext="subtext(item)" v-bind:key="index" :dark="true" >

			<div class="status">
				<i v-if="item.added && !item.removed" class="fa fa-arrow-left-long is-added"></i>
				<i v-if="!item.added && item.removed" class="fa fa-arrow-right-long is-removed"></i>
				<i v-if="item.added && item.removed" class="fa-duotone fa-arrow-right-arrow-left fa-flip-horizontal" style="--fa-secondary-color: #e27570; --fa-primary-color: #3bce51; --fa-secondary-opacity: 1;"></i>
			</div>

		</app-game>

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'changes',
			games: []
		}

	},

	computed: {

		title: function() {

			return (this.$store.getters['filter/insights/size/changes/type'] == 'added') ? 'Recently added' : 'Recently removed'

		}

	},

	methods: {

		response: function(json) {

			this.games = json.games

		},

		subtext: function(item) {

			var parts = []
			
			if (item.added) {

				parts.push('Added ' + this.$options.filters.formatDate(item.added, this.$util.date.format('Do', 'MMMM', 'YYYY')))

			}

			if (item.removed) {

				parts.push(((item.added) ? 'removed ' : 'Removed ') + this.$options.filters.formatDate(item.removed, this.$util.date.format('Do', 'MMMM', 'YYYY')))

			}

			return parts.join('')

		}

	}

}

</script>

<style scoped>

.status {
	width: 48px;
	height: 48px;
	text-align: right;
	line-height: 48px;
	font-size: 20px;
}

.status .fa.is-added {
	color: #3bce5a;
}

.status .fa.is-removed {
	color: #e27570;
}

</style>