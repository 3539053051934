<template>

<div class="update" v-if="isActive && !closed">

	<div class="update-modal">

        <div class="update-modal-banner" :style="{backgroundImage: 'url(' + isActive.image + ')'}" />

        <div class="update-modal-text">
        
            <h1>{{ isActive.name }}</h1>

            <div class="update-modal-tags">
            
                <div class="update-modal-tags-item is-additions" v-if="isActive.data.additions">Additions <span>{{ isActive.data.additions }}</span></div>
                <div class="update-modal-tags-item is-fixes" v-if="isActive.data.fixes">Fixes <span>{{ isActive.data.fixes }}</span></div>

            </div>

            <div v-html="isActive.content" />

        </div>

        <div class="update-modal-actions">

            <a :href="isActive.url" target="_blank" class="update-modal-actions-item is-more" v-tooltip="'Opens in new tab'" v-on:click="onReadClick">Read more</a>
            <button class="update-modal-actions-item is-close" v-on:click="onCloseClick">Not now</button>

        </div>

    </div>

</div>

</template>

<script>

import cookies from 'js-cookie'

export default {

    data: function() {
    
        return {
            closed: false
        }

    },

    created: function() {

        this.closed = (cookies.get('update') == undefined || parseInt(cookies.get('update')) !== parseInt(this.isActive.id)) ? false : true

    },

    watch: {
    
        isActive: function() {

            this.closed = (cookies.get('update') == undefined || parseInt(cookies.get('update')) !== parseInt(this.isActive.id)) ? false : true
        
        }

    },

    computed: {
    
        isActive: function() {
        
            return (this.$store.getters['session/notification']) ? this.$store.getters['session/notification'] : false
        
        }
    
    },

    methods: {

        setAsRead: function() {

            cookies.set('update', this.isActive.id, {
				expires: 4
			})

            this.closed = true

        },
    
        onCloseClick: function() {
        
            this.setAsRead()

        },
    
        onReadClick: function() {
        
            this.setAsRead()

        }
    
    }

}

</script>

<style scoped>

.update {
	position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.update-modal {
    max-width: 640px;
    width: calc(100vw - 20px);
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.update-modal-banner {
    height: 200px;
    border-bottom: 1px solid #ddd;
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.is-mobile .update-modal-banner {
    height: 120px;
}

.update-modal-text {
    padding: 20px;
    max-height: calc(100vh - 300px);
    overflow: auto;
}

.is-mobile .update-modal-text {
    max-height: calc(100vh - 200px);
    padding: 10px;
}

.update-modal-text h1 {
    font-size: 24px;
    font-weight: 300;
    color: #343434;
}

.update-modal-tags {
    margin: 5px 0px 20px 0px;
    display: flex;
}

.is-mobile .update-modal-tags {
    margin: 5px 0px 15px 0px;
}

.update-modal-tags-item {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin-right: 10px;
    padding: 3px 5px;
}

.update-modal-tags-item span {
    padding-left: 5px;
    margin-left: 3px;
    border-left: 1px solid #fff;
}

.update-modal-tags-item.is-additions {
    background-color: #40b95a;
}

.update-modal-tags-item.is-fixes {
    background-color: #42a4fa;
}

.update-modal-text >>> li,
.update-modal-text >>> p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    color: #343434;
    margin-bottom: 10px;
}

.is-mobile .update-modal-text >>> li,
.is-mobile .update-modal-text >>> p {
    font-size: 14px;
    line-height: 18px;
}

.update-modal-text p:last-child {
    margin-bottom: 0px;
}

.update-modal-actions {
    border-top: 1px solid #ddd;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
}

.update-modal-actions-item {
    border-radius: 4px;
    background-color: red;
    width: 120px;
    margin: 0px 5px;
    height: 40px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    flex-direction: column;
    font-weight: 400;
    transition: transform 100ms linear;
}

.update-modal-actions-item:hover {
    transform: scale(1.05);
}

.update-modal-actions-item span {
    font-weight: 300;
    margin-top: 1px;
    font-size: 10px;
}

.update-modal-actions-item.is-more {
    background-color: #024359;
    color: #fff;
}

.update-modal-actions-item.is-close {
    background-color: #fff;
    color: #343434;
    border: 1px solid #ddd;
}

</style>