<template>
	
<div v-tooltip="tooltip" class="rating" :data-band="band" v-on:mouseenter="is.over = true" v-on:mouseleave="is.over = false">

	<slot></slot>

	<div class="rating-score" v-bind:class="{'is-clickable': (showIndividualRatings || canClick) && hasRatings}" v-on:click="onClick" v-propagation>
			
		<div class="rating-value" v-if="value !== '-' && value !== 0">{{ value | round(1) }}</div>
		<div class="rating-value" v-if="value === '-' || value === 0">-</div>

		<div v-if="count && !hideCount" class="rating-count">{{ formatCount }}</div>

	</div>

	<div v-if="showIndividualRatings && hasRatings && is.over" class="rating-users" v-bind:class="{'is-transparent': transparent}">

		<div v-for="rating in sortedRatings" v-bind:key="rating.user" class="rating-users-member">
					
			<core-avatar class="rating-users-member-avatar" :id="rating.user" type="user" tooltip="1"></core-avatar>
					
			<div class="rating-users-member-rating" :data-band="Math.floor(rating.value)">

				{{ rating.value }}

			</div>

		</div>

		<div v-on:click="onClick" v-show="count > maxUserRatings" class="rating-users-member rating-users-member-more" v-tooltip v-propagation>

			+{{count - maxUserRatings}} 

			<core-tooltip>View all</core-tooltip>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['value', 'count', 'showIndividualRatings', 'transparent', 'ratings', 'hideCount', 'canClick', 'tooltip'],

	data: function() {

		return {
			is: {
				over: false
			},
			maxUserRatings: 6
		}

	},

	computed: {

		band: function() {

			return (this.value === '-' || this.value === 0) ? '-' : Math.floor(this.value)

		},

		formatCount: function() {

			if (this.count < 1000) {

				return this.count

			} else {

				return Math.floor(this.count / 1000).toString() + 'k'

			}

		},

		hasRatings: function() {

			return this.$_.keys(this.ratings).length

		},

		sortedRatings: function() {

			var sorted = []

			this.$_.each(this.ratings, function(value, key) {

				sorted.push({
					user: key,
					value: value
				})

			})

			sorted = this.$_.sortBy(sorted, 'value')

			if (sorted.length > this.maxUserRatings) {

				sorted = sorted.slice(-6)

			}

			return sorted

		}

	},

	methods: {

		onClick: function() {

			if ((this.showIndividualRatings || this.canClick) && this.hasRatings) {

				this.$emit('more')

			}

		}

	}

}

</script>

<style scoped>

.rating {
	font-size: 18px;
}

.rating-score {
	width: 100%;
	height: 100%;
	z-index: 2;
}

.rating-score.is-clickable {
	cursor: pointer!important;
}

.rating-value {
	text-align: center;
	color: #ffffff;
	width: 100%;
	font-weight: 500;
}

.rating-count {
	display: block;
	padding: 0px 2px;
	min-width: 14px;
	height: 14px;
	font-weight: 400;
	font-size: 8px;
	text-align: center;
	color: #ffffff;
	line-height: 14px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.rating[data-band="10"] { background-color: #249563; }
.rating[data-band="9"] { background-color: #249563; }
.rating[data-band="8"] { background-color: #2FC482; }
.rating[data-band="7"] { background-color: #1d8acd; }
.rating[data-band="6"] { background-color: #5369a2; }
.rating[data-band="5"] { background-color: #5369a2; }
.rating[data-band="4"] { background-color: #df4751; }
.rating[data-band="3"] { background-color: #df4751; }
.rating[data-band="2"] { background-color: #db303b; }
.rating[data-band="1"] { background-color: #db303b; }
.rating[data-band="0"] { background-color: #db303b; }
.rating[data-band="-"] { background-color: #efefef; color: #ccc; }
.rating[data-band="-"] .rating-count { display: none; }

.rating-users {
	display: none;
	position: absolute;
	white-space: nowrap;
	background-color: #e9f3f7;
	right: 48px;
	padding-right: 7px;
	top: 0px;
	height: 48px;
	z-index: 10;
	padding-left: 32px;
	flex-direction: row;
	justify-content: flex-end;
}

.rating-users.is-transparent {
	background-color: transparent;
}

.is-desktop .rating:hover .rating-users {
	display: flex;
}

@media only screen and (max-width: 1119px) {

	.is-desktop .rating:hover .rating-users {
		display: none;
	}

}

.rating-users-member {
	margin-right: 1px;
	height: 48px;
	width: 24px;
	font-size: 0;
	vertical-align: top;
}

.rating-users-member-more {
	background-color: #999;
	color: #fff;
	font-size: 10px;
	margin-top: 24px;
	height: 24px;
	min-width: 24px;
	padding: 0px 4px;
	line-height: 24px;
	font-weight: 400;
	text-align: center;
	font-size: 12px;
	width: auto;
	cursor: pointer;
}

.is-desktop .rating-users-member-more:hover {
	background-color: #666;
}

.rating-users-member-avatar {
	display: block;
	width: 24px;
	height: 24px;
	font-size: 12px;
}

.rating-users-member-rating {
	display: block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	color: #fff;
	font-size: 12px;
	z-index: 2;
}

.rating-users-member-rating[data-band="10"] { background-color: #249563; }
.rating-users-member-rating[data-band="9"] { background-color: #249563; }
.rating-users-member-rating[data-band="8"] { background-color: #2FC482; }
.rating-users-member-rating[data-band="7"] { background-color: #1d8acd; }
.rating-users-member-rating[data-band="6"] { background-color: #5369a2; }
.rating-users-member-rating[data-band="5"] { background-color: #5369a2; }
.rating-users-member-rating[data-band="4"] { background-color: #df4751; }
.rating-users-member-rating[data-band="3"] { background-color: #df4751; }
.rating-users-member-rating[data-band="2"] { background-color: #db303b; }
.rating-users-member-rating[data-band="1"] { background-color: #db303b; }

</style>
