<template>

<div class="trend">

	<div class="trend-item" v-for="(v, index) in value" :key="index" :style="styleItem(v)"></div>

</div>

</template>

<script>

export default {

	props: ['value', 'config'],

	methods: {

		styleItem: function(value) {

			var style = {
				height: false,
				backgroundColor: false
			}

			var percent = (100 / this.config.max) * value;

			if (percent > 100) percent = 100

			style.height = percent.toString() + '%'

			this.$_.each(this.config.colors, function(hex, threshold) {

				if (threshold <= value) style.backgroundColor = hex

			})

			return style

		}

	}

}

</script>

<style scoped>

.trend {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 64px;
	padding: 0px 4px;
	justify-content: center;
	align-items: flex-end;
}

.trend-item {
	width: calc(10% - 2px);
	border-radius: 4px;
	max-width: 10px;
	margin: 0px 1px;
	height: 100%;
	min-height: 4px;
}

</style>