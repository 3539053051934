import _ from 'underscore'
import Util from 'app/util'

export default {

	namespaced: true,

	state: {

		name: 'config',
		data: Util.filter.templates.config(),
		defaultValues: Util.filter.templates.config(),
		changed: false

	},

	getters: {

		changed: function(state) {

			return Util.filter.differences(state.data, state.defaultValues, state.name)

		},

		active: function(state) {

			return {
				'sync.plays.incomplete': state.data.sync.plays.incomplete,
				'collection.nest': state.data.collection.nest,
				'collection.contains': !state.data.collection.contains,
				'collection.layout': state.data.collection.layout,
				'collection.status': state.data.collection.status,
				'collection.inventory.location.public': state.data.collection.inventory.location.public,
				'insights.heatmap.start': state.data.insights.heatmap.start !== 'sunday',
				'insights.records.start': state.data.insights.records.start !== 0,
				'insights.hindex.expansions': state.data.insights.hindex.expansions,
				'insights.size.expansions': state.data.insights.size.expansions,
				'plays.usernames': state.data.plays.usernames.length,
				'plays.layout': state.data.plays.layout,
				'other.currency': state.data.other.currency,
				'other.exchange': state.data.other.exchange,
				'other.date': state.data.other.date !== 'DD/MM/YYYY',
				'ratings.layout': state.data.ratings.layout,
				'lists.unplayed.type': state.data.lists.unplayed.type !== 'shame',
				'lists.unplayed.trade': !state.data.lists.unplayed.trade,
				'lists.unplayed.rated': !state.data.lists.unplayed.rated,
				'lists.unplayed.games': state.data.lists.unplayed.games.length
			}

		},

		values: function(state) {

			return state.data

		},

		'collection/inventory/location/public': function(state) {

			return state.data.collection.inventory.location.public

		},

		'sync/plays/incomplete': function(state) {

			return state.data.sync.plays.incomplete

		},

		'other/currency': function(state) {

			return state.data.other.currency

		},

		'other/date': function(state) {

			return state.data.other.date

		},

		'other/exchange': function(state) {

			return parseFloat(state.data.other.exchange)

		},

		'collection/nest': function(state) {

			return state.data.collection.nest

		},

		'collection/contains': function(state) {

			return state.data.collection.contains

		},

		'collection/layout': function(state) {

			return state.data.collection.layout

		},

		'collection/status': function(state) {

			return state.data.collection.status

		},

		'insights/size/expansions': function(state) {

			return state.data.insights.size.expansions

		},

		'insights/hindex/expansions': function(state) {

			return state.data.insights.hindex.expansions

		},

		'insights/heatmap/start': function(state) {

			return state.data.insights.heatmap.start

		},

		'insights/records/start': function(state) {

			return state.data.insights.records.start

		},

		'plays/usernames': function(state) {

			return state.data.plays.usernames

		},

		'plays/layout': function(state) {

			return state.data.plays.layout

		},

		'ratings/layout': function(state) {

			return state.data.ratings.layout

		},

		'lists/unplayed/type': function(state) {

			return state.data.lists.unplayed.type

		},

		'lists/unplayed/trade': function(state) {

			return state.data.lists.unplayed.trade

		},

		'lists/unplayed/rated': function(state) {

			return state.data.lists.unplayed.rated

		},

		'lists/unplayed/games': function(state) {

			return state.data.lists.unplayed.games

		}

	},

	mutations: {

		set: function(state, data) {

			state.data = Util.filter.autofill(data, 'config')

		},

		reset: function(state, defaultValues) {

			defaultValues = defaultValues || state.defaultValues

			state.defaultValues = Util.filter.autofill(defaultValues, 'config')
			state.data = Util.filter.autofill(defaultValues, 'config')

			state.changed = false

		},

		'collection/inventory/location/public': function(state, value) {
			
			state.data.collection.inventory.location.public = (value) ? 1 : 0

		},

		'sync/plays/incomplete': function(state, value) {
			
			state.data.sync.plays.incomplete = (value) ? 1 : 0

		},

		'collection/contains': function(state, value) {
			
			state.data.collection.contains = (value) ? 1 : 0

		},

		'collection/nest': function(state, value) {
			
			state.data.collection.nest = (value) ? 1 : 0

		},

		'collection/status': function(state, value) {
			
			state.data.collection.status = (value) ? 1 : 0

		},

		'collection/layout': function(state, value) {
			
			state.data.collection.layout = value

		},

		'other/currency': function(state, value) {
			
			state.data.other.currency = value

		},

		'other/date': function(state, value) {
			
			state.data.other.date = value

		},

		'other/exchange': function(state, value) {
			
			state.data.other.exchange = value

		},

		'lists/unplayed/type': function(state, value) {
			
			state.data.lists.unplayed.type = value

		},

		'lists/unplayed/trade': function(state, value) {
			
			state.data.lists.unplayed.trade = value ? 1 : 0

		},

		'lists/unplayed/rated': function(state, value) {
			
			state.data.lists.unplayed.rated = value ? 1 : 0

		},

		'lists/unplayed/games': function(state, id) {

			var remove = (_.isObject(id)) ? id.remove : false
			id = (_.isObject(id)) ? id.id : id
	
			var index = _.findIndex(state.data.lists.unplayed.games, function(value) {
	
				return Math.abs(value) === Math.abs(id)
	
			})
	
			if (index >= 0) state.data.lists.unplayed.games.splice(index, 1)
	
			if (!remove) state.data.lists.unplayed.games.push(id)

		},

		'insights/size/expansions': function(state, value) {

			state.data.insights.size.expansions = (value) ? 1 : 0

		},

		'insights/hindex/expansions': function(state, value) {

			state.data.insights.hindex.expansions = (value) ? 1 : 0

		},

		'insights/heatmap/start': function(state, value) {

			state.data.insights.heatmap.start = value

		},

		'insights/records/start': function(state, value) {

			state.data.insights.records.start = value

		},

		'plays/usernames': function(state, id) {

			var remove = (_.isObject(id)) ? id.remove : false
			id = (_.isObject(id)) ? id.id : id
	
			var index = _.findIndex(state.data.plays.usernames, function(value) {
	
				return value === id
	
			})
	
			if (index >= 0) state.data.plays.usernames.splice(index, 1)
	
			if (!remove) state.data.plays.usernames.push(id)

		},

		'plays/layout': function(state, value) {
			
			state.data.plays.layout = value

		},

		'ratings/layout': function(state, value) {
			
			state.data.ratings.layout = value

		}

	}

}