<template>

<core-filter-bar>

	<core-filter-bar-left class="filter-bar">

		<core-filter-summary :count="count" :maximum="count" :unsaved="changed && ready && $store.getters['permissions/list/edit']" noun="game" :loading="loading" title="Lists" :subtitle="list.name" icon="list-ol" />

	</core-filter-bar-left>

	<core-filter-bar-right class="filter-bar" v-if="ready">

		<com-filter-editions :loading="loading" />

		<com-config-unplayed v-on:refresh="$emit('refresh')" />

		<com-filter-options v-if="canDoSomething" />

	</core-filter-bar-right>

</core-filter-bar>

</template>

<script>

import configUnplayed from './config/Unplayed'
import filterEditions from './filter/Editions'
import filterOptions from './filter/Options'

export default {

	components: {
		'com-filter-editions': filterEditions,
		'com-filter-options': filterOptions,
		'com-config-unplayed': configUnplayed
	},

	props: ['count', 'loading', 'ready', 'maximum'],

	data: function() {

		return {
			suggestions: []
		}

	},

	computed: {

		title: function() {

			return this.list.name

		},

		changed: function() {

			return (this.$store.getters['filter/list/changed'] && this.isAutomated) || this.$store.getters['page/list/columns/changed']

		},

		isManual: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.MANUAL

		},

		isAutomated: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.AUTOMATED

		},

		isRanking: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.RANKING

		},

		isLive: function() {

			return this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.LIVE

		},

		isYearly: function() {

			return this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.YEARLY

		},

		list: function() {

			return this.$store.getters['page/list/list']

		},

		edition: function() {

			return this.$store.getters['page/list/edition']

		},

		context: function() {

			return this.$store.getters['context']

		},

		canDoSomething: function() {

			return this.$store.getters['permissions/list/create'] || this.$store.getters['permissions/list/edit'] || this.$store.getters['permissions/list/delete'] || this.$store.getters['permissions/edition/create'] || this.$store.getters['permissions/edition/edit'] || this.$store.getters['permissions/edition/delete']

		},

		filter: function() {

			return this.$store.getters['filter/list']

		}

	},

	watch: {

		context: function(newValue, oldValue) {

			if (newValue.type !== oldValue.type || newValue.name !== oldValue.name) {

				this.is.expanded.editions = false

			}

		}

	}

}

</script>