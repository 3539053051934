<template>

<div class="search" v-if="$route.meta.search">

	<div class="search-box">
		<input type="text" v-model="text" :placeholder="placeholder" class="search-box-input" />
	</div>

</div>

</template>

<script>

export default {

	data: function() {
	
		return {
			text: ''
		}

	},

	computed: {

		placeholder: function() {

			return (this.$route.meta.search) ? this.$route.meta.search.placeholder : false
		
		},

		filter: function() {

			return (this.$route.meta.search) ? this.$route.meta.search.store : false
		
		},

		value: function() {

			return (this.filter) ? this.$store.getters[this.filter] : ''

		}

	},

	created: function() {

		this.text = this.value

	},

	watch: {

		value: function(n) {

			this.model = n

		},
	
		text: function(n) {
		
			this.$store.commit(this.filter, n)
		
		}
	
	}

}

</script>

<style scoped>

.search {
	height: 64px;
	display: flex;
	align-items: center;
}

.search-box {
	border-radius: 20px;
	height: 40px;
	width: 320px;
	background-color: #1e3648;
	margin-right: 10px;
}

.is-mobile .search {
	display: none;
}

.search-box-input {
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding: 0px 20px;
	color: #fff;
	font-weight: 400;
	letter-spacing: 1px;
	font-size: 14px;
}

.search-box-input::placeholder {
	color: rgba(255, 255, 255, 0.4);
}

</style>
