<template>

<com-item name="collection.durations" :size="size" :is-empty="!active" :loading="loading" title="Durations">

	<template v-slot:about>

		<p>See how {{ your }} collection breaks down by duration.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<core-widget :is-slim="true">

			<div class="durations-list">

				<div class="durations-list-shading">
					<div class="durations-list-shading-item">0-1hr</div>
					<div class="durations-list-shading-item">1-2hr</div>
					<div class="durations-list-shading-item">2-3hr</div>
					<div class="durations-list-shading-item">3-4hr</div>
					<div class="durations-list-shading-item">4-5hr</div>
					<div class="durations-list-shading-item">5-6hr</div>
				</div>

				<div class="durations-list-item" v-on:click="onClick(index)" v-tooltip="tooltip(duration, index)" v-for="(duration, index) in durations" :key="index">
					<div :style="height(duration)"></div>
				</div>

			</div>

		</core-widget>

	</template>

	<template v-slot:buttons>

		<com-toggle :options="durationOptions" :value="durationValue" v-on:change="onDurationChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		durationOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.RANGE, text: 'By Range'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.MIN, text: 'By Minimum'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.MAX, text: 'By Maximum'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.MID, text: 'By Midpoint'})

			return options

		},

		durationValue: function() {

			return this.$store.getters['filter/insights/collection/duration']

		},

		active: function() {

			return this.durations.length && !this.loading

		},

		durations: function() {

			return this.$store.getters['page/insights/collection/durations']

		},

		max: function() {

			return this.$_.max(this.durations, function(duration) {

				return duration

			})

		}

	},

	methods: {

		onDurationChange: function(value) {

			this.$store.commit('filter/insights/collection/duration', value)

		},

		onClick: function(index) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionDuration',
				index: index
			})

		},

		tooltip: function(value, index) {

			return (index * 10).toString() + ' - ' + ((index * 10) + 9).toString() + ' minutes<small>' + value.toString() + ' game' + ((value === 1) ? '' : 's') + '</small>'

		},

		height: function(value) {

			return {
				height: ((100 / this.max) * value).toString() + '%'
			}

		}

	}

}

</script>

<style scoped>

.durations {
	padding: 0px!important;
	height: 240px;
}

.durations-list {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.durations-list-shading {
	z-index: 1;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: flex;
}

.durations-list-shading-item {
	width: calc(100% / 6);
	height: 100%;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	color: #a0c3cf;
	padding: 5px;
}

.durations-list-shading-item:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.durations-list-item {
	height: 100%;
	padding: 25px 0px;
	width: calc(100% / 36);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 2;
}

.durations-list-item:hover {
	background-color: rgba(12, 137, 178, 0.1);
}

.durations-list-item div {
	width: 50%;
	border-radius: 4px;
	min-height: 2px;
	background-color: #4881bb;
	transition: height 100ms linear;
}

</style>