<template>
	
<core-card-foot-item>

	<template v-if="isRating">

		<core-rating :value="value" class="card-foot-stat-rating" />

	</template>

	<template v-if="!isRating">

		<div class="card-foot-stat-value" v-if="!raw && (value || !invalid)" :class="{'is-big': value > 999}">
			{{ value | round(0) }}<span v-if="percent" class="card-foot-stat-percent">%</span><span v-if="subvalue" class="card-foot-stat-subvalue">({{ subvalue }})</span>
		</div>

		<div class="card-foot-stat-value" v-if="raw && (value || !invalid)" :class="{'is-big': value > 999}">
			{{ value }}<span v-if="subvalue" class="card-foot-stat-subvalue">({{ subvalue }})</span>
		</div>

	</template>

	<div class="card-foot-stat-value is-invalid" v-if="!value && invalid">n/a</div>

	<core-card-foot-item-label v-if="!plural">

		{{ text }}

	</core-card-foot-item-label>

	<core-card-foot-item-label v-if="plural">

		<plural :n="value" :t="text" />

	</core-card-foot-item-label>

</core-card-foot-item>

</template>

<script>

export default {

	props: ['value', 'text', 'raw', 'plural', 'isRating', 'invalid', 'percent', 'subvalue']

}

</script>

<style scoped>

.card-foot-stat-value {
	line-height: 32px;
	font-weight: 500;
	height: 32px;
	color: #666;
	font-size: 24px;
}

.card-foot-stat-value.is-big {
	font-size: 24px;
}

.card-foot-stat-percent {
	font-size: 18px;
}

.card-foot-stat-subvalue {
	font-size: 12px;
	font-weight: 300;
	color: #999;
	margin-left: 2px;
}

.card-foot-stat-value.is-invalid {
	color: #ccc;
    font-weight: 400;
    font-size: 14px
}

.card-foot-stat-rating {
	width: 32px;
	height: 32px;
	line-height: 32px;
}

</style>
