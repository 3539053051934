<template>

<app-page>

	<core-filter-bar>

		<core-filter-bar-left>

			<core-filter-summary title="Tips & Perks" icon="gift" />

		</core-filter-bar-left>

	</core-filter-bar>

	<core-content-page class="tipjar">

		<core-content-wrapper :loading="is.loading">

		<template v-show="!is.loading">

			<div class="box is-green" v-if="upgraded">

				<p><b>Thank you! You have already tipped and received your perks, but you are more than welcome to support geekGroup again if you wish to!</b></p>

			</div>

			<div class="box">

				<p>geekGroup is freely available to all BGG users without adverts and fully featured. With this tipjar you can show your support or appreciation for the app and also get some permanent extra perks for your account. Tips help cover server and maintenance costs.</p>

				<p>If you kindly give a one-time tip you will get the perks listed below. Tips are via PayPal and perks are instantly applied to your account. You will also receive a GeekMail confirming them. If you have any questions, please do e-mail me at <a href="mailto:phil@geekgroup.app">phil@geekgroup.app</a>.</p>

				<p>Thank you for your support!</p>

			</div>

				<ul class="tipjar-perks">
					<li class="tipjar-perks-item">
						<i class="fa fa-calendar-alt"></i>
						<h2>Daily api syncs</h2>
						Your profile will <b>automatically sync every day</b>, instead of every week.
					</li>
					<li class="tipjar-perks-item">
						<i class="fa fa-sync-alt"></i>
						<h2>Manual api syncs</h2>
						You can <b>manually re-sync</b> your data anytime by clicking the run sync button.
					</li>
					<li class="tipjar-perks-item">
						<i class="fa fa-microscope"></i>
						<h2>Unlimited deep syncs</h2>
						You can run <b>deep syncs</b> of your hidden BGG data anytime instead of once per month.
					</li>
					<li class="tipjar-perks-item">
						<i class="fa fa-pencil-alt"></i>
						<h2>Group URL</h2>
						You can give your group a <b>custom URL</b> instead of a randomly assigned one.
					</li>

			</ul>

			<div class="tipjar-input">

				<core-filter-input-range min="5" prefix="$" max="30" step="0.5" pips="5" density="10" decimals="2" manualValue="10" :tooltip="true" v-on:change="onValueChange" />

			</div>

			<app-paypal v-show="!is.loading" :notify-url="paypal.ipn" :invoice-number="invoice" v-on:payment-completed="onTipComplete" :items="paypal.items" :amount="inputs.amount" currency="USD" :button-style="paypal.style" :client="paypal.credentials" :experience="paypal.experience" env="production" class="paypal-button" />

			</template>

		</core-content-wrapper>

	</core-content-page>
		
</app-page>

</template>

<script>

var LoadingPaypal = {
	template: '<div class="paypal-loading"></div>'
}

export default {

	components: {
		'app-paypal': () => ({
			component: import('vue-paypal-checkout'),
			loading: LoadingPaypal
		})
	},

	data: function() {
	
		return {

			paypal: {

				ipn: this.$CONSTANTS.API + 'accounts/upgrade/' + this.$store.getters['session/user/id'],

				items: [],

				style: {
					color: 'blue',
					shape: 'rect',
					label: 'paypal',
					tagline: false,
					height: 40
				},

				credentials: {
					sandbox: 'AfIZu3MAuC5bVs95rcWY4BpXpeDrmbViSHTBAk7Pgiex4SaavO049RBCAz_aCvsFExpyh9Dxddi78zbr',
					production: 'AUldUXlKbHY0wTMNnLqag3alT3eiBxg29TkEqqtIJzo4GrxYVSpEnZ1XAePOcuIr4TxDx0djZcZ08lvN'
				},

				experience: {
					input_fields: {
						no_shipping: 1
					}
				}

			},

			is: {
				loading: false,
				success: false,
				error: false
			},

			inputs: {
				amount: '10.00'
			}

		}
	
	},

	computed: {

		upgraded: function() {

			return this.$store.getters['session/upgraded']

		},

		invoice: function() {

			return this.$store.getters['session/user/name'] + '/' + Date.now()

		}

	},

	methods: {

		onValueChange: function(value) {

			this.inputs.amount = value.toString()

		},

		verifyUpgrade: function() {

			this.$store.dispatch('api/session/check').then(function(json) {

				if (json.user.upgraded) {

					this.$emit('close')

				} else {

					setTimeout(this.verifyUpgrade, 2000)

				}

			}.bind(this))

		},

		onTipComplete: function() {

			this.is.loading = true

			this.verifyUpgrade()

		},

	}

}

</script>

<style scoped>

.box {
	border-radius: 4px;
	margin-bottom: 20px;
	border: 1px solid #ddd;
	background-color: #fff;
	padding: 20px;
}

.is-mobile .box {
	margin-bottom: 10px;
	padding: 10px;
}

.box.is-green {
	background-color: #7beb92;
}

.tipjar {
	padding: 10px;
}

.tipjar >>> p {
    font-size: 16px!important;
    opacity: 0.75;
    line-height: 20px;
    text-align: left;
	margin-bottom: 20px;
}

.is-mobile .tipjar >>> p {
    font-size: 14px!important;
    line-height: 18px;
}

.tipjar >>> p:last-child {
	margin-bottom: 0px;
}

.tipjar-input {
	margin-top: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.tipjar-input >>> .input {
	height: 64px;
}

.tipjar-input >>> .input-slider {
	height: 32px;
	margin: 0px 5px!important;
    width: calc(100% - 8px)!important;
}

.tipjar-input >>> .noUi-horizontal .noUi-handle {
	background-color: #4881bb!important;
	border-color: #4881bb!important;
	height: 40px!important;
}

.tipjar-input >>> .noUi-pips-horizontal {
	top: 32px;
}

.tipjar-input >>> .noUi-target {
	border-color: #ddd;
	border-radius: 4px;
}

.tipjar-input >>> .noUi-connects {
	background-color: #fff;
	border-radius: 4px;
}

.tipjar-input >>> .noUi-value-large, 
.tipjar-input >>> .noUi-value-sub {
	font-size: 14px;
	color: #333;
}

@media only screen and (max-width: 680px) {

	.tipjar-input >>> .noUi-value-large, 
	.tipjar-input >>> .noUi-value-sub {
		font-size: 12px;
	}

	.tipjar-input >>> .noUi-value-large {
		transform: translate(-100%, 50%);
	} 

}

.tipjar-input >>> .noUi-handle-lower .noUi-tooltip {
	display: block!important;
}

.tipjar-input >>> .noUi-tooltip {
	background-color: #fff;
	color: #333;
	font-size: 16px;
}

.tipjar-input >>> .noUi-tooltip:before {
	border-color: #fff transparent transparent transparent;
}

@media only screen and (max-width: 680px) {

	.tipjar-input >>> .noUi-tooltip {
		font-size: 12px;
		left: 0px;
		transform: translate(0px, -1px)!important;
	}

	.tipjar-input >>> .noUi-tooltip:before {
		left: 8px;
	}

	.tipjar-input >>> .noUi-handle[aria-valuenow="9.2"] .noUi-tooltip,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.3"] .noUi-tooltip,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.4"] .noUi-tooltip,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.5"] .noUi-tooltip,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.6"] .noUi-tooltip,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.7"] .noUi-tooltip,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.8"] .noUi-tooltip,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.9"] .noUi-tooltip,
	.tipjar-input >>> .noUi-handle[aria-valuenow="10.0"] .noUi-tooltip {
		left: auto;
		right: 0px;
	}

	.tipjar-input >>> .noUi-handle[aria-valuenow="9.2"] .noUi-tooltip:before,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.3"] .noUi-tooltip:before,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.4"] .noUi-tooltip:before,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.5"] .noUi-tooltip:before,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.6"] .noUi-tooltip:before,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.7"] .noUi-tooltip:before,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.8"] .noUi-tooltip:before,
	.tipjar-input >>> .noUi-handle[aria-valuenow="9.9"] .noUi-tooltip:before,
	.tipjar-input >>> .noUi-handle[aria-valuenow="10.0"] .noUi-tooltip:before {
		right: 3px;
		left: auto;
	}

}

.tipjar-input p {
	line-height: 38px!important;
	padding-right: 16px;
	font-size: 16px;
    font-weight: 300;
    flex-shrink: 0;
}

.paypal-button {
	display: block;
	width: 160px;
	height: 40px;
	margin: 0px auto;
	vertical-align: top;
}

.paypal-button.is-disabled {
	pointer-events: none;
	opacity: 0.4;
}

.loading {
	height: 128px;
	background-image: url(~core/assets/load-transparent.gif);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

b {
	font-weight: 500;
}

.paypal-loading {
	display: inline-block;
	width: 150px;
	height: 40px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	pointer-events: none;
	background-color: #009cde;
	border-radius: 4px;
	background-image: url(~core/assets/load-transparent.gif);
}

.tipjar-perks {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	list-style: none!important;
	border: 1px solid #ddd;
	border-radius: 4px;
    background-color: #fff;
	margin-bottom: 20px;
}

.is-mobile .tipjar-perks {
	grid-template-columns: 1fr;
}

.tipjar-perks-item {
    padding: 20px;
    font-size: 16px!important;
    opacity: 0.75;
	line-height: 20px;
	text-align: left;
	padding-left: 64px;
	border-right: 1px solid #ddd;
}

.is-mobile .tipjar-perks-item {
	border-bottom: 1px solid #ddd;
    font-size: 14px!important;
	line-height: 18px;
	border-right: 0px;
}

.tipjar-perks-item:last-child {
	border-right: 0px;
}

.tipjar-perks-item:last-child {
	border-right: 0px;
	border-bottom: 0px;
}

.tipjar-perks-item .fa {
	position: absolute;
	left: 16px;
	top: 18px;
	font-size: 32px;
	color: #4881bb;
	display: block;
}

.tipjar-perks-item h2 {
	font-size: 20px;
	display: block;
	margin-bottom: 4px;
	text-align: left;
}

.is-mobile .tipjar-perks-item h2 {
	font-size: 16px;
}

</style>