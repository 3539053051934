<template>

<div class="content-select" :class="{'is-active': active}">

	<core-simplebar class="content-select-body">

		<div class="content-select-close" v-tooltip="'Close and deselect all'" v-on:click="onCloseClick" v-propagation><i class="fa fa-times"></i></div>

		<div class="content-select-count">
			<div class="content-select-count-title">{{ active }} <plural :n="active" :t="noun" /> selected</div>
			<div class="content-select-count-text"><small>Select an action below to apply</small></div>
		</div>

		<div class="content-select-actions">

			<core-content-select-type :active="active" text="Selection" icon="check-square">

				<core-content-select-action text="Select all" name="selection/all" />
				<core-content-select-action text="Deselect all" name="selection/none" />
				<core-content-select-action text="Invert" name="selection/invert" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="Tags" icon="tags" v-if="can.tag">

				<core-dropdown-item type="title" v-if="gameTags.length">Game tags</core-dropdown-item>

				<core-dropdown-item v-for="tag in gameTags" :key="tag.id" type="tagToggle" :color="tag.colour" :loading="is.tagging === tag.id" v-on:add="onTagAdd(tag)" v-on:remove="onTagRemove(tag)">{{ tag.text }}</core-dropdown-item>

				<core-dropdown-item type="title" v-if="contextTags.length">{{ contextTitle }}</core-dropdown-item>

				<core-dropdown-item v-for="tag in contextTags" :key="tag.id" type="tagToggle" :color="tag.colour" :loading="is.tagging === tag.id" v-on:add="onTagAdd(tag)" v-on:remove="onTagRemove(tag)">{{ tag.text }}</core-dropdown-item>

				<core-content-select-action text="Create new tag" name="tags/create" :loading="true" />

				<core-content-select-action text="Clear all" name="tags/clear" :loading="true" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="Filter" icon="filter" v-if="can.filter">

				<core-content-select-action text="Only show these games" name="filter/subset" :loading="true" v-if="filterSubsetApplicable" />
				<core-content-select-action text="Always include games" name="filter/include/add" :loading="true" v-if="filterIncludeAddApplicable" />
				<core-content-select-action text="Don't always include games" name="filter/include/remove" :loading="true" v-if="filterIncludeRemoveApplicable" />
				<core-content-select-action text="Always exclude games" name="filter/exclude" :loading="true" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="List" icon="list" v-if="can.list.remove">

				<core-content-select-action text="Remove" name="list/remove" :loading="true" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="Users" icon="users" v-if="can.users.remove">

				<core-content-select-action text="Remove" name="users/remove" :loading="true" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="Export" icon="file-download" v-if="can.export">

				<core-content-select-action text="As CSV" name="export/csv" :loading="true" />
				<core-content-select-action text="To Clipboard" name="export/clipboard" :loading="true" />

			</core-content-select-type>

		</div>

	</core-simplebar>

</div>

</template>

<script>

export default {

	props: ['noun'],

	data: function() {

		return {
			is: {
				tagging: false
			}

		}

	},

	watch: {

		active: function(n) {

			if (n) this.$pubsub.$emit('panel.opened')
			if (!n) this.$pubsub.$emit('panel.closed')

		}

	},

	computed: {

		contextTitle: function() {

			return this.$CONSTANTS.SERVICE.TITLE[this.$store.getters['service/select/name']] + ' tags'

		},

		gameTags: function() {

			return this.$_.where(this.$store.getters['data/tags/list'], {
				context: 0
			})

		},

		contextTags: function() {

			return this.$_.where(this.$store.getters['data/tags/list'], {
				context: this.$CONSTANTS.SERVICE.VALUE[this.$store.getters['service/select/name']]
			})

		},

		can: function() {

			return this.$store.getters['service/select/can']

		},

		active: function() {

			return this.$store.getters['service/select/count']

		},

		filterSubsetApplicable: function() {

			return true

		},

		filterIncludeAddApplicable: function() {

			return true

		},

		filterIncludeRemoveApplicable: function() {

			return true

		}

	},

	methods: {

		onCloseClick: function() {
		
			this.$store.dispatch('service/select/selection/none')

		},

		onTagAdd: function(tag) {

			this.is.tagging = tag.id

			this.$store.dispatch('service/select/tags/add', {
				tag: tag
			}).then(function() {

				this.is.tagging = false

			}.bind(this))

		},

		onTagRemove: function(tag) {

			this.is.tagging = tag.id

			this.$store.dispatch('service/select/tags/remove', {
				tag: tag
			}).then(function() {

				this.is.tagging = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.content-select {
	position: fixed;
	right: -320px;
	top: 64px;
	width: 320px;
	bottom: 0px;
	z-index: 1001;
	transition: right 100ms linear;
	background-color: #2b5870;
	display: flex;
	flex-direction: column;
}

.content-select.is-active {
	right: 0px;
}

.content-select-body {
	width: 100%;
	height: calc(100vh - var(--vh-offset, 0px) - 64px);
}

.content-select-count {
	flex-shrink: 0;
	height: 88px;
	display: flex;
	justify-content: center;
	padding: 20px;
	flex-direction: column;
    background-color: #19455c;
	z-index: 2;
	margin-bottom: 20px;
}

.is-smaller .content-select-count {
	height: 64px;
}

.content-select-count:after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #19455c transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    left: 17px;
    bottom: -10px;
}

.content-select-count-title {
	text-align: left;
	font-size: 20px;
	color: #fff;
	font-weight: 500;
	line-height: 24px;
	padding-right: 80px;
}

.content-select-count-text {
	font-size: 14px;
	color: #fff;
	line-height: 18px;
	font-weight: 400;
}

.content-select-actions {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	flex-direction: column;
}

.content-select-close,
.content-select-back {
	position: absolute;
	right: 20px;
	top: 24px;
	height: 40px;
	width: 40px;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.25);
	color: #fff;
	line-height: 40px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	z-index: 3;
}

.is-smaller .content-select-close,
.is-smaller .content-select-back {
	top: 12px;
}

.is-desktop .content-select-close:hover,
.is-desktop .content-select-back:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

</style>
