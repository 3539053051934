<template>

<core-filter-advanced-group title="Games" :count="count">

	<core-filter-input-range text="Plays" min="0" max="100" :filter="value('plays')" :toggle="true" maxText="Any" :tooltip="true" step="1" pips="10" decimals="0" density="10" />

	<core-filter-input-range text="Min. hours played" min="0" max="100" :filter="value('threshold/hours')" :tooltip="true" step="1" pips="10" decimals="0" density="10" />

	<core-filter-input-range text="Win %" min="0" max="100" maxText="Any" :filter="value('play/winpercent')" :tooltip="true" step="1" decimals="0" density="10" pips="10" v-if="isUser" />

	<core-filter-input-range text="Users played" min="0" max="100" maxText="Any" :filter="value('play/users')" :tooltip="true" step="1" decimals="0" density="10" pips="10" v-if="!isUser" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['play.winpercent', 'play.users', 'plays', 'threshold.time']
		}
	}

}

</script>