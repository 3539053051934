<template>

<core-filter-advanced-group title="General" :count="count">

	<core-filter-sort :page="showSort" :filter="value('sort')" :inline="true" v-if="showSort" />

	<core-filter-input-toggles text="Expansions" :buttons="true" :filter="value('expansions')" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" v-if="!hideExpansion" />

	<core-filter-input-range text="Duration" subtext="(minutes)" min="0" max="360" :filter="value('duration')" step="30" decimals="0" density="10" />

	<core-filter-input-toggle text="Only durations completely within range" :filter="value('durations/absolute')" on="Yes" off="No" />

	<core-filter-input-range text="Weight" min="0" max="5" pips="0.5" :tooltip="true" :filter="value('weight')" step="0.1" decimals="1" density="10" />

	<core-filter-input-range text="Min. weight votes" min="0" max="1000" :filter="value('threshold/weight')" :tooltip="true" step="25" pips="100" decimals="0" density="10" />

	<core-filter-input-range text="Published year" min="1970" max="2030" :filter="value('year')" :tooltip="true" step="1" pips="10" decimals="0" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	props: ['hideExpansion', 'showSort'],

	data: function() {

		return {
			fields: ['expansions', 'duration', 'durations.absolute', 'weight', 'threshold.weight', 'year'],
			toggle: {
				icons: [],
				labels: ['Include', 'Exclude', 'Only'],
				values: [1, 0, 2]
			}
		}

	}

}

</script>