<template>

<core-dropdown class="input" trigger="manual" v-on:forceClose="is.expanded = false" :expanded="is.expanded" v-click-outside="onOutsideClick">

	<core-button v-on:click.native="onToggleClick" tooltip="Change period" theme="filter" icon="caret-down" class="input-button"><span v-if="!window.is.mobile && value !== 'all'">In </span><b>{{ text }}</b></core-button>
	
	<core-dropdown-list align="right" theme="filter" :caret="true">

		<core-simplebar class="input-options-scroll">

			<core-dropdown-link v-for="item in options" v-bind:key="item.key" v-on:click.native="onOptionClick(item.key)" v-bind:class="{'is-active': value === item.key}">

				{{ item.text }}

			</core-dropdown-link>

		</core-simplebar>

	</core-dropdown-list>

</core-dropdown>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	components: {
		
	},

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: [
		'page',
		'filter'
	],
	
	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		options: function() {

			var options = []

			options.push({
				key: this.$CONSTANTS.INSIGHTS.PERIOD.CURRENT,
				text: 'Last 12 months'
			})

			options.push({
				key: this.$CONSTANTS.INSIGHTS.PERIOD.ALLTIME,
				text: 'All time'
			})

			var min = parseInt(this.$moment.unix(this.$store.getters['context/date/firstPlay']).utc().format('YYYY'))
			var max = parseInt(this.$moment.utc().format('YYYY'))

			for (var i = max; i >= min; i--) {

				options.push({
					key: i,
					text: i
				})

			}

			return options

		},

		value: function() {

			return this.$store.getters['filter/insights/' + this.filter + '/period']

		},

		text: function() {

			if (this.$CONSTANTS.INSIGHTS.PERIOD_OPTIONS[this.value]) {

				return (this.window.is.mobile || this.value === 'all') ? this.$CONSTANTS.INSIGHTS.PERIOD_OPTIONS[this.value] : this.$CONSTANTS.INSIGHTS.PERIOD_OPTIONS[this.value].toLowerCase()

			} else {

				return this.value

			} 

		}

	},

	methods: {

		onToggleClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onOptionClick: function(value) {

			this.$store.commit('filter/insights/' + this.filter + '/period', value)

		}

	}

}

</script>

<style scoped>

.is-smaller .input-options-scroll,
.is-desktop .input-options-scroll {
	min-width: 120px;
	width: 100%;
	max-height: 290px;
}

</style>
