<template>

<div class="about">

    <div class="about-summary">

        <div class="about-summary-last">

            <div class="about-summary-last-value">{{ sinceValue }}</div>
            <div class="about-summary-last-text">{{ sinceContext }}<template v-if="sinceValue !== 1">s</template> ago</div>
            <div class="about-summary-last-label">Last data sync</div>

        </div>

        <div class="about-summary-info">

            <div class="about-summary-info-item">
                <div class="about-summary-info-item-text">Automated Schedule</div>
                <div class="about-summary-info-item-value" v-if="isWeekly">Weekly</div>
                <div class="about-summary-info-item-value" v-if="isDaily">Daily</div>
            </div>

            <div class="about-summary-info-item">
                <div class="about-summary-info-item-text">Next Automated Sync</div>
                <div class="about-summary-info-item-value">{{ data.summary.next }}</div>
            </div>

            <div class="about-summary-info-item">
                <div class="about-summary-info-item-text">Last Full Sync</div>
                <div class="about-summary-info-item-value">{{ data.summary.full | fromNow }}</div>
            </div>

            <div class="about-summary-info-item">
                <div class="about-summary-info-item-text">Last Deep Sync</div>
                <div class="about-summary-info-item-value" v-if="data.summary.deep">{{ data.summary.deep | fromNow }}</div>
                <div class="about-summary-info-item-value" v-if="!data.summary.deep">None</div>
            </div>

        </div>

    </div>

    <div class="about-settings">

        <div class="about-settings-item">

            <div class="about-settings-item-text">
                <div class="about-settings-item-text-title">Next Automated Sync</div>
                <div class="about-settings-item-text-description">You can tell the system to run a full re-sync when it next automatically syncs in order to re-import your historical play data. This resets to Recent after each automated sync.</div>
            </div>

            <div class="about-settings-item-input">
                <core-input-select v-model="model.full" :options="options.full" theme="light" placeholder="Select type" />
            </div>

        </div>

        <div class="about-settings-item" v-if="isWeekly">

            <div class="about-settings-item-text">
                <div class="about-settings-item-text-title">Day of Week</div>
                <div class="about-settings-item-text-description">You can select your preferred sync day. Your automated sync will be scheduled to run that day but it may run later if the server is extremely busy that day.</div>
            </div>

            <div class="about-settings-item-input">
                <core-input-select v-model="model.day" :options="options.day" theme="light" placeholder="Select day" />
            </div>
        
        </div>

        <div class="about-settings-item" v-if="isDaily">

            <div class="about-settings-item-text">
                <div class="about-settings-item-text-title">Time of Day<span>(UTC)</span></div>
                <div class="about-settings-item-text-description">You can select your preferred sync time window in UTC. Your automated sync will be scheduled to run during this window but it may run later if the server is extremely busy at that time.</div>
            </div>

            <div class="about-settings-item-input">
                <core-input-select v-model="model.time" :options="options.time" theme="light" placeholder="Select time" />
            </div>
        
        </div>

    </div>

</div>

</template>

<script>

import Vue from 'vue'

export default {

    props: ['data'],

    data: function() {
    
        return {
            model: {
                full: 0,
                time: 0,
                day: 0
            },
            options: {
                full: {
                    0: 'Recent',
                    1: 'Full'
                },
                day: {
                    0: 'Any',
                    1: 'Monday',
                    2: 'Tuesday',
                    3: 'Wednesday',
                    4: 'Thursday',
                    5: 'Friday',
                    6: 'Saturday',
                    7: 'Sunday'
                },
                time: {
                    0: 'Any',
                    1: '12:00am - 02:00am',
                    2: '02:00am - 04:00am',
                    3: '04:00am - 06:00am',
                    4: '06:00am - 08:00am',
                    5: '08:00am - 10:00am',
                    6: '10:00am - 12:00pm',
                    7: '12:00pm - 02:00pm',
                    8: '02:00pm - 04:00pm',
                    9: '04:00pm - 06:00pm',
                    10: '06:00pm - 08:00pm',
                    11: '08:00pm - 10:00pm',
                    12: '10:00pm - 12:00pm'
                }
            }
        }
    
    },

    created: function() {
    
        Vue.set(this, 'model', this.$util.copy(this.data.settings))
    
    },

    watch: {

        data: {
        
            deep: true,

            handler: function(n) {
        
                Vue.set(this, 'model', this.$util.copy(n.settings))
            
            }
        
        },

        model: {
        
            deep: true,

            handler: function(n) {
        
                this.$emit('change', n)
            
            }

        }

    },

    computed: {

        sinceSeconds: function() {
        
            return this.$moment().utc().diff(this.$moment.unix(this.data.summary.last).utc(), 'seconds') 

        },

        sinceMinutes: function() {
        
            return this.$moment().utc().diff(this.$moment.unix(this.data.summary.last).utc(), 'minutes') 

        },

        sinceHours: function() {
        
            return this.$moment().utc().diff(this.$moment.unix(this.data.summary.last).utc(), 'hours') 

        },

        sinceValue: function() {

            if (this.sinceSeconds < 60) {
            
                return this.sinceSeconds
            
            } else if (this.sinceMinutes < 60) {
            
                return this.sinceMinutes
            
            } else if (this.sinceHours < 24) {

                return this.sinceHours

            } else {
            
                return Math.floor(this.sinceHours / 24)
            
            }
        
        },

        sinceContext: function() {
        
            if (this.sinceSeconds < 60) {
            
                return 'second'
            
            } else if (this.sinceMinutes < 60) {
            
                return 'minute'
            
            } else if (this.sinceHours < 24) {

                return 'hour'

            } else {
            
                return 'day'
            
            }

        },

        timezone: function() {
        
            return (this.model.zone) ? this.model.zone : Intl.DateTimeFormat().resolvedOptions().timeZone

        },
    
        isWeekly: function() {
        
            return !this.data.daily
        
        },

        isDaily: function() {
        
            return this.data.daily
        
        }
    
    }

}

</script>

<style scoped>

.about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 1550px) {

    .about {
        grid-template-columns: 1fr;
    }

}

.is-mobile .about {
    grid-template-columns: 1fr;
}

.about-summary {
    background-color: #fff;
    display: grid;
    grid-template-columns: auto 340px;
}

.is-mobile .about-summary {
    grid-template-columns: 1fr;
}

.about-summary-last {
    padding: 20px;
    border-right: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #4881bb;
    border-radius: 4px 0px 0px 4px;
    color: #fff;
}

.is-mobile .about-summary-last {
    border-radius: 4px 4px 0px 0px;
}

.about-summary-last-value {
    font-size: 96px;
    font-weight: 500;
}

.about-summary-last-label {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    opacity: 0.85;
}

.about-summary-last-text {
    font-size: 24px;
    font-weight: 500;
}

.about-summary-info {
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #ddd;
    border-left: 0px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}

.is-mobile .about-summary-info {
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #ddd;
    border-top: 0px;
}

.about-summary-info-item {
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto;
    border-bottom: 1px solid #ddd;
}

.is-mobile .about-summary-info-item {
    grid-template-columns: 1fr;
    padding: 20px 10px;
}

.about-summary-info-item:last-child {
    border-bottom: 0px;
}

.about-summary-info-item-text {
    font-weight: 500;
    font-size: 16px;
}

.is-mobile .about-summary-info-item-text {
    text-align: center;
}

.about-summary-info-item-value {
    font-weight: 300;
    text-align: right;
    font-size: 16px;
}

.is-mobile .about-summary-info-item-value {
    text-align: center;
    margin-top: 4px;
}

.about-settings {
    border: 1px solid #ddd;
    background-color: #fff;
    display: grid;
    border-radius: 4px;
    grid-template-rows: 1fr 1fr;
}

.about-settings-item {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    color: #333;
    font-size: 16px;
    display: grid;
    grid-template-columns: auto 128px;
    grid-gap: 20px;
}

.is-mobile .about-settings-item {
    grid-template-columns: 1fr;
}

.about-settings-item:last-child {
    border-bottom: 0px;
}

.about-settings-item-text-title {
    font-weight: 500;
}

.about-settings-item-text-title span {
    font-weight: 400;
    font-style: italic;
    margin-left: 5px;
    opacity: 0.85;
}

.about-settings-item-text-description {
    font-weight: 300;
    line-height: 18px;
    margin-top: 4px;
    opacity: 0.75;
}

.about-settings-item-input {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.about-settings-item-input >>> .input {
    margin: 2px 0px;
}

</style>