<template>

<core-modal v-on:close="onClose" :loading="is.loading">

	<template v-slot:head>

		My Groups & Buddies

	</template>

	<template v-slot:default>

        <div class="groups-empty" v-if="!groups.length">You have not created or joined any groups yet.</div>

		<router-link v-for="(item, index) in groups" :key="index" :to="{name: 'Collection', params: {type: item.type, name: item.slug}}" class="group" v-tooltip="'Click to view group'">

			<div class="group-name">
                <span>{{ item.name }}</span>
                <i class="fa-solid fa-caret-right" />
            </div>

			<div class="group-metrics">
            
                <div class="group-metrics-item">
                    <span>{{ item.count.users }}</span>
                    <i class="fa-solid fa-users" />
                </div>
            
                <div class="group-metrics-item">
                    <span>{{ item.count.collection }}</span>
                    <i class="fa-solid fa-rectangle-vertical-history" />
                </div>
            
                <div class="group-metrics-item">
                    <span>{{ item.count.ratings }}</span>
                    <i class="fa-solid fa-star" />
                </div>
            
                <div class="group-metrics-item">
                    <span>{{ item.count.plays }}</span>
                    <i class="fa-solid fa-dice-three" />
                </div>
            
            </div>

		</router-link>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onCreateClick">Create Group</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				loading: false
			}

		}

	},

	computed: {

		groups: function() {

			return this.$store.getters['session/groups']

		}

	},

	methods: {

        onCreateClick: function() {
        
            this.$pubsub.$emit('open.modal', 'groupCreate')

        },

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.group {
    margin-bottom: 20px;
    display: block;
}

.groups-empty {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    font-weight: 500;
    padding: 20px 0px;
}

.group:last-child {
    margin-bottom: 0px;
}

.group-name {
    border-radius: 4px;
    padding: 10px 30px 10px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.25);
}

.group:hover .group-name {
    background-color: rgba(0, 0, 0, 0.45);
}

.group-name i {
    position: absolute;
    right: 15px;
    top: 0px;
    line-height: 36px;
}

.group-metrics {
    display: grid;
    margin-top: 4px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 4px;
}

.group-metrics-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.25);
    justify-content: center;
    padding: 10px 0px;
    border-radius: 4px;
}

.group-metrics-item i {
    font-size: 14px;
    color: #fff;
    opacity: 0.75;
}

.group-metrics-item span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 8px;
}

</style>