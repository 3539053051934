<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Edit preset

	</template>

	<template v-slot:default>

		<core-form-input-textbox :autofocus="true" maxlength="16" name="name" v-on:validate="validate" placeholder="Enter preset name" v-model="inputs.name" :error="is.error" :disabled="is.loading" :validation="validation.name"></core-form-input-textbox>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Save</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	props: ['data'],

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				name: this.data.name
			},

			validation: {
				name: {
					exists: true,
					max: 32
				}
			}

		}

	},

	methods: {

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/presets/edit', {
					id: this.data.preset,
					data: {
						name: this.inputs.name
					}
				}).then(function(json) {

					this.$store.commit('session/presets/set', json.presets)

					this.$emit('close')

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.inputs {
	display: flex;
}

@media only screen and (max-width: 767px) {

	.inputs {
		display: block;
	}

}

</style>