<template>

<core-filter-advanced :active="active" v-on:clickoutside="$emit('close')" v-propagation>

	<template v-slot:inputs>

		<core-filter-advanced-group title="Filter">

			<core-filter-input-daterange filter="tools/heatmap/date" />

			<core-filter-input-toggles filter="tools/heatmap/type" text="Plays" :values="type.values" :icons="type.icons" :labels="type.labels" />

			<core-filter-input-range text="Duration" subtext="(minutes)" min="0" max="360" filter="tools/heatmap/duration" step="30" decimals="0" density="10"></core-filter-input-range>

			<core-filter-input-range text="Count" min="1" max="10" filter="tools/heatmap/count" step="1" decimals="0" density="10"></core-filter-input-range>

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Games">

			<core-filter-input-suggest placeholder="Start typing a game..." field="name" filter="tools/heatmap/games" api="games/suggest" data="games"></core-filter-input-suggest>

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Players">

			<core-filter-input-toggle text="Show plays together only" on="Yes" off="No" filter="tools/heatmap/players/together"></core-filter-input-toggle>

			<core-filter-input-suggest placeholder="Start typing a name..." field="player_name" filter="tools/heatmap/players/required" api="players/suggest" data="players"></core-filter-input-suggest>

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Locations">

			<core-filter-input-suggest placeholder="Start typing a location..." field="location_name" filter="tools/heatmap/locations" api="locations/suggest" data="locations"></core-filter-input-suggest>

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Layout">

			<core-filter-input-toggles filter="tools/heatmap/layout/start" text="Week starts on" :values="start.values" :icons="start.icons" :labels="start.labels" />

			<core-filter-input-toggle text="Remove empty weeks at start/end" on="Yes" off="No" filter="tools/heatmap/layout/trim" />

			<core-filter-input-toggle text="Border" on="Yes" off="No" filter="tools/heatmap/layout/border" />

			<core-filter-input-toggles filter="tools/heatmap/layout/theme" text="Theme" :values="theme.values" :icons="theme.icons" :labels="theme.labels" />

			<core-filter-input-range text="Size" subtext="pixels" min="16" max="48" filter="tools/heatmap/layout/size" step="1" pips="4" :tooltip="true" decimals="0" />

			<core-filter-input-range text="Corners" subtext="pixels" min="0" max="24" filter="tools/heatmap/layout/corner" step="1" pips="2" :tooltip="true" decimals="0" />

			<core-filter-input-range text="Spacing" subtext="pixels" min="0" max="10" filter="tools/heatmap/layout/spacing" step="1" pips="1" decimals="0" />

			<core-filter-input-range text="Font Size" subtext="pixels" min="10" max="16" filter="tools/heatmap/layout/font" step="1" pips="1" decimals="0" />

			<core-filter-input-toggle text="Transparent if day empty" on="Yes" off="No" filter="tools/heatmap/layout/empty" />

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Details">

			<core-filter-input-toggle text="Months" on="Yes" off="No" filter="tools/heatmap/layout/show/months" />

			<core-filter-input-toggle text="Days of week" on="Yes" off="No" filter="tools/heatmap/layout/show/days" />

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Extras">

			<core-filter-input-toggle text="Show title" on="Yes" off="No" filter="tools/heatmap/layout/show/title" />

			<core-filter-input-text filter="tools/heatmap/layout/title"  class="title" v-if="filter.layout.show.title" />

			<core-filter-input-toggle text="Show user" on="Yes" off="No" filter="tools/heatmap/layout/show/user" />

			<core-filter-input-toggle text="Show dates" on="Yes" off="No" filter="tools/heatmap/layout/show/dates" />

			<core-filter-input-toggle text="Show geekGroup credit" on="Yes" off="No" filter="tools/heatmap/layout/show/app" />

		</core-filter-advanced-group>

	</template>

	<template v-slot:buttons>

		<core-button v-if="window.is.mobile" v-on:click.native="$emit('close')" theme="blue" fluid="yes">Close filter</core-button>

	</template>

</core-filter-advanced>

</template>

<script>

export default {

	props: ['active'],

	data: function() {

		return {
			start: {
				icons: ['', ''],
				values: ['sunday', 'monday'],
				labels: ['Sunday', 'Monday']
			},
			type: {
				values: ['plays', 'new', 'wins', 'weight'],
				icons: ['', '', '', ''],
				labels: ['All', 'New', 'Wins', 'Weight']
			},
			theme: {
				values: ['blue', 'red', 'green', 'purple'],
				icons: ['', '', ''],
				labels: ['Blue', 'Red', 'Green', 'Purple']
			}
		}

	},

	computed: {

		filter: function() {

			return this.$store.getters['filter/tools/heatmap']

		}

	},

	methods: {

		onResetClick: function() {

			this.$store.commit('filter/tools/heatmap/reset')

		}

	}

}

</script>

<style scoped>

.section {
	padding: 15px 15px 7px 15px!important;
}

.title,
.sort {
	padding-top: 0px!important;
}

</style>
