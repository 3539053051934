<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary title="Collection" icon="rectangle-vertical-history" :count="count" noun="result" nestedNoun="nested" :noNestedPlural="true" :maximum="maximum" :nested="nested" :loading="loading" />

	</core-filter-bar-left>

	<core-filter-bar-right>

		<core-filter-sort page="collection" filter="collection/sort" />

		<core-filter-layout page="collection" filter="config/collection/layout" />
		
		<core-filter-panel filter="collection" v-on:expandClick="onExpandClick" />

		<core-filter-presets type="collection" />

		<core-filter-tags v-if="can.tag && window.is.desktop" type="collection" :hideButton="window.width < 768" />

		<com-config v-on:refresh="$emit('refresh')" />
		
		<core-filter-actions type="collection">
			
			<core-dropdown-item type="link" v-if="window.width < 768" v-on:click.native.stop="$pubsub.$emit('collection.expand')">Settings</core-dropdown-item>

		</core-filter-actions>

	</core-filter-bar-right>

	<core-filter-advanced :active="is.expanded" v-on:clickoutside="onOutsideClick">

		<template v-slot:inputs>

			<core-filter-input-text filter="collection/text" :inline="true" />

			<core-filter-advanced-title>Games</core-filter-advanced-title>

			<app-filter-template-games-general filter="collection" />

			<app-filter-template-games-count filter="collection" />

			<app-filter-template-games-language filter="collection" />

			<app-filter-template-games-age filter="collection" />
			
			<app-filter-template-games-include filter="collection" />

			<app-filter-template-games-exclude filter="collection" />

			<core-filter-advanced-title>Collection</core-filter-advanced-title>

			<app-filter-template-collection-general filter="collection" />

			<app-filter-template-collection-status filter="collection" />

			<app-filter-template-collection-worth filter="collection" />
			
			<app-filter-template-collection-inventory filter="collection" />

			<app-filter-template-common-tags filter="collection" />

			<app-filter-template-collection-owners filter="collection" />
			
			<app-filter-template-collection-trades filter="collection" />

			<core-filter-advanced-title>Ratings</core-filter-advanced-title>

			<app-filter-template-collection-user filter="collection" />

			<app-filter-template-collection-group filter="collection" />

			<app-filter-template-collection-community filter="collection" />

			<app-filter-template-collection-comments filter="collection" />

			<core-filter-advanced-title>Plays</core-filter-advanced-title>

			<app-filter-template-collection-plays filter="collection" :range="true" />
			
			<core-filter-advanced-title>Other</core-filter-advanced-title>

			<app-filter-template-common-users filter="collection" />

			<app-filter-template-common-designers filter="collection" />
			
			<app-filter-template-common-artists filter="collection" />
			
			<app-filter-template-common-publishers filter="collection" />
			
			<app-filter-template-common-categories filter="collection" />
			
			<app-filter-template-common-mechanics filter="collection" />
			
			<app-filter-template-common-subdomains filter="collection" />

			<app-filter-template-common-families filter="collection" />
			
			<app-filter-template-common-results filter="collection" />

		</template>

		<template v-slot:buttons>

			<core-button v-on:click.native="onResetClick" theme="blue" fluid="yes">Reset filter</core-button>

			<core-button v-if="window.is.mobile" v-on:click.native="onOutsideClick" theme="blue" fluid="yes">Close filter</core-button>

		</template>

	</core-filter-advanced>

	<app-filter-panel-tags type="collection" v-if="can.tag" />

</core-filter-bar>

</template>

<script>

import config from './Config'

export default {

	props: ['count', 'loading', 'maximum', 'nested'],

	components: {

		'com-config': config

	},

	data: function() {

		return {

			is: {
				expanded: false
			}

		}

	},

	computed: {

		can: function() {

			return this.$store.getters['service/select/can']

		},

		filter: function() {

			return this.$store.getters['filter/collection']

		}

	},

	methods: {

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

			if (this.is.expanded) {

				this.$pubsub.$emit('filter.opened')

			}

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onResetClick: function() {

			this.$store.commit('filter/collection/reset', {
				layout: this.filter.layout
			})

		}

	}

}

</script>

<style scoped>

.filter-text {
	font-size: 12px;
    display: block;
    color: #fff;
    font-weight: 400;
    padding-bottom: 8px;
}

.filter-search-mobile {
	margin: 4px 7px 0px 7px!important;
	width: calc(100% - 16px)!important;
}

@media only screen and (max-width: 767px) {

	.filter-section-first {
		margin-top: 4px;
		border-top: 1px solid rgba(255, 255, 255, 0.1);
	}

}

</style>
