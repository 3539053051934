<template>

<div class="inbox" v-tooltip="'My notifications'" v-on:click="onClick">

    <div class="inbox-icon"><div class="inbox-count" v-if="unread" /><i class="fa-solid fa-bell"></i></div>

</div>

</template>

<script>

export default {

    computed: {
    
        unread: function() {
        
            return this.$store.getters['session/notifications']
        
        }

    },

    methods: {
    
        onClick: function(e) {

            e.stopPropagation()
        
			this.$pubsub.$emit('open.modal', 'userNotifications')
        
        }
    
    }

}

</script>

<style scoped>

.inbox {
    height: 64px;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    margin-left: 1px;
    cursor: pointer;
}

.is-mobile .inbox {
    height: 48px;
}

.inbox-count {
    background-color: #f64b4b;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.inbox-icon {
    color: rgba(255, 255, 255, 1);
    background-color: #1e3648;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
}

.is-mobile .inbox-icon {
    height: 32px;
    width: 32px;
    line-height: 32px;
}

</style>
