<template>

<core-modal v-on:close="onClose" :type="type" :icon="icon" :nopadding="true" :loading="is.loading" :hideFoot="true">

	<template v-slot:head>

		<template v-if="!is.reading">Updates</template>
		<template v-if="is.reading">{{ title }}</template>

	</template>

	<template v-slot:default>

		<div class="log" v-if="!is.reading">

			<div class="log-item" v-for="(log, index) in logs" :key="index" v-on:click="onLogClick(log.id)">

				<div class="log-item-icon">
					<i class="fa" v-bind:class="{'fa-tools': log.type === 2, 'fa-comment': log.type === 1}"></i>
				</div>

				<div class="log-item-text">
					<div class="log-item-text-name">{{ log.name }}</div>
					<div class="log-item-text-date">{{ log.date | fromNow }}</div>
				</div>

			</div>

		</div>

		<div class="log-content" v-if="is.reading" v-html="content"></div>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			logs: [],

			is: {
				reading: false,
				loading: true
			}

		}

	},

	computed: {

		type: function() {

			return (this.is.reading) ? 'text' : 'default'

		},

		icon: function() {

			return (this.is.reading) ? 'caret-left' : 'times'

		},

		title: function() {

			return this.$_.findWhere(this.logs, {
				id: this.is.reading
			}).name

		},

		content: function() {

			return this.$_.findWhere(this.logs, {
				id: this.is.reading
			}).content

		}

	},

	methods: {

		onClose: function() {

			if (this.is.reading) {

				this.is.reading = false

			} else {

				this.$emit('close')

			}

		},

		onLogClick: function(id) {

			this.is.reading = id

		}

	},

	created: function() {

		this.$store.dispatch('api/app/devlog').then(function(json) {

			this.logs = json.devlog

			this.is.loading = false

		}.bind(this), function() {

			this.is.loading = false

		}.bind(this))

	}

}

</script>

<style scoped>

.log {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.log-item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.is-desktop .log-item:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.log-item-icon {
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	color: #fff;
	text-align: center;
	flex-shrink: 0;
}

.log-item-text {
	min-height: 48px;
	padding: 10px 10px 10px 0px;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
}

.log-item-text-name {
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
	color: #fff;
}

.log-item-text-date {
	font-size: 12px;
	margin-top: 2px;
	color: rgba(255, 255, 255, 0.5);
}

.log-content {
	padding: 0px 15px 15px 15px;
}

.log-content >>> ul {
	list-style-position: outside!important;
}

.log-content >>> li {
	margin-bottom: 5px;
	margin-left: 15px;
}

.log-content >>> li b,
.log-content >>> li strong {
	display: block;
	font-size: 12px;
	font-weight: 300;
	margin-top: 2px;
	color: #a0a0a0;
	letter-spacing: 1px;
}

</style>