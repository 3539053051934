<template>

<core-panel>

	<template v-slot:head>

		<h1>Off the shelf</h1>

		<p>The games that were played in {{ name }}.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in after" :subtext="subtext(item)" :showPlaysText="true" :count="days(item)" :countText="daysText(item)" :item="item" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'after',
			after: [],
			params: ['specific']
		}

	},

	computed: {

		name: function() {
		
			if (this.data.specific === 1) return 'less than ten days'
			if (this.data.specific === 2) return 'less than 100 days'
			if (this.data.specific === 3) return 'less than one year'
			if (this.data.specific === 4) return 'more than one year'

			return ''

		}

	},

	methods: {

		response: function(json) {

			this.after = json.after

		},

		subtext: function(game) {

			return this.$options.filters.formatDate(game.played, this.$util.date.format('Do', 'MMMM', 'YYYY'))

		},

		days: function(game) {

			if (game.to) {

				return this.$options.filters.daysBetween(game.from, game.to)

			} else {

				return this.$options.filters.daysSince(game.from)

			}

		},

		daysText: function(game) {

			return (this.days(game) === 1) ? 'day' : 'days'

		}

	}

}

</script>