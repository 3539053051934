<template>

<core-panel>

	<template v-slot:head>

		<h1>Ratings</h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" /> with a rating of {{ rating }}.0.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" v-bind:key="index" :isUserRating="isUser" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'ratings',
			games: [],
			params: ['band']
		}

	},

	computed: {

		rating: function() {

			return this.data.band

		}

	},

	methods: {

		response: function(json) {

			this.games = json.games

		}

	}

}

</script>