<template>

<com-item name="worth.summary" :size="size" :loading="loading" :is-empty="!active" theme="blue" title="Worth">

	<template v-slot:about>

		<p>The value of {{ your }} entire collection.</p>

	</template>

	<template v-slot:stats>
				
		<core-widget class="summary">

			<div class="summary-total" v-if="active" v-fit v-html="formatcurrency(summary.total.current, 0)"></div>

			<div class="summary-change" v-if="active">

				<div class="summary-change-title"><span>Total trend</span></div>

				<div class="summary-change-items">

					<div class="summary-change-item" v-if="summary.total.one !== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total.one > 0, 'is-down': summary.total.one < 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total.one > 0, 'fa-caret-down': summary.total.one < 0}"></i><div v-html="formatcurrency(summary.total.one, 0)"></div></div>
						<div class="summary-change-item-text">vs 1 month ago</div>
						
					</div>

					<div class="summary-change-item" v-if="summary.total.six !== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total.six > 0, 'is-down': summary.total.six < 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total.six > 0, 'fa-caret-down': summary.total.six < 0}"></i><div v-html="formatcurrency(summary.total.six, 0)"></div></div>
						<div class="summary-change-item-text">vs 6 months ago</div>
						
					</div>

					<div class="summary-change-item" v-if="summary.total.twelve !== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.total.twelve > 0, 'is-down': summary.total.twelve < 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.total.twelve > 0, 'fa-caret-down': summary.total.twelve < 0}"></i><div v-html="formatcurrency(summary.total.twelve, 0)"></div></div>
						<div class="summary-change-item-text">vs 12 months ago</div>
												
					</div>

				</div>

			</div>

			<div class="summary-change" v-if="active">

				<div class="summary-change-title"><span>Intersection trend</span></div>

				<div class="summary-change-items">

					<div class="summary-change-item" v-if="summary.like.one !== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.like.one > 0, 'is-down': summary.like.one < 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.like.one > 0, 'fa-caret-down': summary.like.one < 0}"></i><div v-html="formatcurrency(summary.like.one, 0)"></div></div>
						<div class="summary-change-item-text">vs 1 month ago</div>
						
					</div>

					<div class="summary-change-item" v-if="summary.like.six !== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.like.six > 0, 'is-down': summary.like.six < 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.like.six > 0, 'fa-caret-down': summary.like.six < 0}"></i><div v-html="formatcurrency(summary.like.six, 0)"></div></div>
						<div class="summary-change-item-text">vs 6 months ago</div>
						
					</div>

					<div class="summary-change-item" v-if="summary.like.twelve !== null">

						<div class="summary-change-item-value" :class="{'is-up': summary.like.twelve > 0, 'is-down': summary.like.twelve < 0}" v-fit><i class="fa" :class="{'fa-caret-up': summary.like.twelve > 0, 'fa-caret-down': summary.like.twelve < 0}"></i><div v-html="formatcurrency(summary.like.twelve, 0)"></div></div>
						<div class="summary-change-item-text">vs 12 months ago</div>
												
					</div>

				</div>

			</div>
		
		</core-widget>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		summary: function() {

			return this.$store.getters['page/insights/worth/summary']

		},

		active: function() {

			return this.summary && !this.loading

		}

	}

}

</script>

<style scoped>

.summary {
	padding: 0px!important;
	border: 0px!important;
	width: 100%;
	height: 252px;
	display: flex;
	flex-direction: column;
}

.is-mobile .summary {
	height: 100%;
}

.summary-total {
	display: flex;
	margin: 5px 0px 25px 0px;
	align-items: baseline;
	justify-content: center;
	color: #fff;
	font-size: 100px;
	font-weight: 700;
	width: 100%;
	text-align: center;
}

.summary-total small {
	font-size: 12px;
	text-align: center;
	font-weight: 400;
	position: absolute;
	bottom: -16px;
	display: block;
}

.summary-total >>> span.currency {
	font-size: 48px;
	margin-right: 0px;
}

.summary-change {
	flex-shrink: 0;
	width: 100%;
	padding: 0px 10px 10px 10px;
	margin-bottom: 10px;
}

.summary-change-items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
}

.summary-change-item {
	width: 33.3%;
	display: flex;
	flex-direction: column;
}

.summary-change-item-value {
	align-items: center;
	display: flex;
	color: #fff;
	height: 64px;
	font-weight: 500;
	font-size: 28px;
	justify-content: center;
}

.summary-change-item-value.is-up {
	color: #5acf69;
}

.summary-change-item-value.is-down {
	color: #9c150e;
}

.summary-change-item-value .fa {
	font-size: 14px;
	margin-right: 6px;
}

.summary-change-item-value >>> span.currency {
	font-size: 14px;
}

.summary-change-item-value > div {
	height: 64px;
	display: flex;
	align-items: center;
}

.summary-change-item-text {
	height: 16px;
	flex-shrink: 0;
	color: #fff;
	font-size: 12px;
	text-align: center;
	font-weight: 400;
}

.summary-change-title {
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
	color: #fff;
	text-align: center;
}

.summary-change-title span {
	background-color: #4881bb;
	display: inline-block;
	padding: 0px 20px;
	position: relative;
	z-index: 2;
}

.summary-change-title:after {
	content: ' ';
	display: block;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	right: 0px;
	top: 7px;
	left: 0px;
	z-index: 1;
}

</style>