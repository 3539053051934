<template>

<core-filter-advanced-group title="Counts" :count="count">

	<core-filter-input-range text="Official" min="1" max="10" maxText="Any" :filter="value('count/official')" step="1" decimals="0" density="10" :toggle="true" />

	<core-filter-input-toggle text="Exact official matches only" on="Yes" off="No" :filter="value('count/exact')" />

	<core-filter-input-range text="Recommended or Best" min="1" max="10" maxText="Any" :filter="value('count/recommended')" step="1" decimals="0" density="10" :toggle="true" />

	<core-filter-input-range text="Max. not recommended %" min="0" minText="Any" max="50" :filter="value('threshold/notrecommended')" :tooltip="true" step="1" pips="5" decimals="0" density="10" v-if="recommending" />

	<core-filter-input-range text="Best only" min="1" max="10" maxText="Any" :filter="value('count/best')" step="1" decimals="0" density="10" :toggle="true" />

	<core-filter-input-range text="Min. votes" min="0" max="1000" :filter="value('threshold/count')" :tooltip="true" step="25" pips="100" decimals="0" density="10" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['count.exact', 'count.official', 'count.recommended', 'count.best', 'threshold.count', 'threshold.notrecommended']
		}

	},

	computed: {

		recommending: function() {

			return this.$store.getters['filter/' + this.filter + '/active']['count.recommended']

		}

	}

}

</script>