<template>

<div class="panels" v-bind:class="{'is-loading': is.loading, 'is-active': is.active}">

	<div class="panels-close" v-tooltip="'Close'" v-if="!stack.length" v-on:click="onPanelClose" v-propagation><i class="fa fa-times"></i></div>
	<div class="panels-back" v-tooltip="'Back'" v-if="stack.length" v-on:click="onPanelClose" v-propagation><i class="fa fa-caret-left"></i></div>

	<i class="panels-loading fa fa-spinner fa-spin" v-if="is.loading" />

	<template v-for="(panel, key) in panels">

		<component class="panels-content" v-bind:key="key" v-bind:is="key" v-if="is.active === key" :data="panel" v-on:ready="onReady" />

	</template>

</div>

</template>

<script>

import insightssizeturnover from './insights/size/Turnover.vue'
import insightssizelifespan from './insights/size/Lifespan.vue'
import insightssizeactivity from './insights/size/Activity.vue'
import insightssizeclusters from './insights/size/Clusters.vue'
import insightssizecluster from './insights/size/Cluster.vue'
import insightssizesampled from './insights/size/Sampled.vue'
import insightssizechanges from './insights/size/Changes.vue'

import insightsshelfafter from './insights/shelf/After.vue'
import insightsshelflongest from './insights/shelf/Longest.vue'
import insightsshelfrecent from './insights/shelf/Recent.vue'
import insightsshelfcumulative from './insights/shelf/Cumulative.vue'
import insightsshelfperson from './insights/shelf/Person.vue'

import insightscollectiondesigners from './insights/collection/Designers.vue'
import insightscollectiondesigner from './insights/collection/Designer.vue'
import insightscollectionpublishers from './insights/collection/Publishers.vue'
import insightscollectionpublisher from './insights/collection/Publisher.vue'
import insightscollectionyears from './insights/collection/Years.vue'
import insightscollectionyear from './insights/collection/Year.vue'
import insightscollectiontypes from './insights/collection/Types.vue'
import insightscollectiontype from './insights/collection/Type.vue'
import insightscollectiondependency from './insights/collection/Dependency.vue'
import insightscollectionduration from './insights/collection/Duration.vue'
import insightscollectionsuitability from './insights/collection/Suitability.vue'
import insightscollectionrarity from './insights/collection/Rarity.vue'
import insightscollectionbestduration from './insights/collection/BestDuration.vue'

import insightsratingsdesigners from './insights/ratings/Designers.vue'
import insightsratingsdesigner from './insights/ratings/Designer.vue'
import insightsratingspublishers from './insights/ratings/Publishers.vue'
import insightsratingspublisher from './insights/ratings/Publisher.vue'
import insightsratingsdisparities from './insights/ratings/Disparities.vue'
import insightsratingstypes from './insights/ratings/Types.vue'
import insightsratingstype from './insights/ratings/Type.vue'
import insightsratingsyears from './insights/ratings/Years.vue'
import insightsratingsyear from './insights/ratings/Year.vue'
import insightsratingstrending from './insights/ratings/Trending.vue'
import insightsratingsdistribution from './insights/ratings/Distribution.vue'

import insightsmembersdivisive from './insights/members/Divisive.vue'
import insightsmemberscompare from './insights/members/Compare.vue'
import insightsmemberslist from './insights/members/List.vue'
import insightsmembersmatch from './insights/members/Match.vue'

import insightsplayshindex from './insights/plays/Hindex.vue'
import insightsplaysmost from './insights/plays/Most.vue'
import insightsplaysyears from './insights/plays/Years.vue'
import insightsplaysyear from './insights/plays/Year.vue'
import insightsplaystypes from './insights/plays/Types.vue'
import insightsplaystype from './insights/plays/Type.vue'
import insightsplayslocations from './insights/plays/Locations.vue'
import insightsplayspublishers from './insights/plays/Publishers.vue'
import insightsplayspublisher from './insights/plays/Publisher.vue'
import insightsplaysdesigners from './insights/plays/Designers.vue'
import insightsplaysdesigner from './insights/plays/Designer.vue'

import insightsgameratings from './insights/game/Ratings.vue'

import insightsrecordsgames from './insights/records/Games.vue'
import insightsrecordsstreaks from './insights/records/Streaks.vue'
import insightsrecordsstats from './insights/records/Stats.vue'
import insightsrecordsusers from './insights/records/Users.vue'

import insightsworthpublishers from './insights/worth/Publishers.vue'
import insightsworthpublisher from './insights/worth/Publisher.vue'
import insightsworthdesigners from './insights/worth/Designers.vue'
import insightsworthdesigner from './insights/worth/Designer.vue'
import insightsworthvaluations from './insights/worth/Valuations.vue'
import insightsworthtrending from './insights/worth/Trending.vue'
import insightsworthplays from './insights/worth/Plays.vue'
import insightsworthratings from './insights/worth/Ratings.vue'
import insightsworthgames from './insights/worth/Games.vue'

import ratingscomment from './ratings/Comment.vue'
import playscomment from './plays/Comment.vue'

export default {

	components: {
		insightssizeturnover: insightssizeturnover,
		insightssizelifespan: insightssizelifespan,
		insightssizeactivity: insightssizeactivity,
		insightssizeclusters: insightssizeclusters,
		insightssizecluster: insightssizecluster,
		insightssizesampled: insightssizesampled,
		insightssizechanges: insightssizechanges,
		insightsshelfafter: insightsshelfafter,
		insightsshelfperson: insightsshelfperson,
		insightsshelfcumulative: insightsshelfcumulative,
		insightsshelfrecent: insightsshelfrecent,
		insightsshelflongest: insightsshelflongest,
		insightscollectiondesigners: insightscollectiondesigners,
		insightscollectiondesigner: insightscollectiondesigner,
		insightscollectionpublishers: insightscollectionpublishers,
		insightscollectionpublisher: insightscollectionpublisher,
		insightscollectionyears: insightscollectionyears,
		insightscollectionyear: insightscollectionyear,
		insightscollectiontypes: insightscollectiontypes,
		insightscollectiontype: insightscollectiontype,
		insightsratingsdesigners: insightsratingsdesigners,
		insightsratingsdesigner: insightsratingsdesigner,
		insightsratingsyears: insightsratingsyears,
		insightsratingsyear: insightsratingsyear,
		insightsratingspublishers: insightsratingspublishers,
		insightsratingspublisher: insightsratingspublisher,
		insightsratingsdisparities: insightsratingsdisparities,
		insightsratingstypes: insightsratingstypes,
		insightsratingstype: insightsratingstype,
		insightsratingstrending: insightsratingstrending,
		insightsratingsdistribution: insightsratingsdistribution,
		insightsmembersdivisive: insightsmembersdivisive,
		insightsmemberscompare: insightsmemberscompare,
		insightsmemberslist: insightsmemberslist,
		insightsmembersmatch: insightsmembersmatch,
		insightsplayshindex: insightsplayshindex,
		insightsplaysmost: insightsplaysmost,
		insightsplaystypes: insightsplaystypes,
		insightsplaystype: insightsplaystype,
		insightsplayslocations: insightsplayslocations,
		insightsplayspublishers: insightsplayspublishers,
		insightsplayspublisher: insightsplayspublisher,
		insightsplaysdesigners: insightsplaysdesigners,
		insightsplaysyears: insightsplaysyears,
		insightsplaysyear: insightsplaysyear,
		insightsplaysdesigner: insightsplaysdesigner,
		insightsgameratings: insightsgameratings,
		insightsrecordsgames: insightsrecordsgames,
		insightsrecordsstreaks: insightsrecordsstreaks,
		insightsrecordsstats: insightsrecordsstats,
		insightsrecordsusers: insightsrecordsusers,
		ratingscomment: ratingscomment,
		playscomment: playscomment,
		insightsworthpublishers: insightsworthpublishers,
		insightsworthpublisher: insightsworthpublisher,
		insightsworthdesigners: insightsworthdesigners,
		insightsworthdesigner: insightsworthdesigner,
		insightsworthvaluations: insightsworthvaluations,
		insightsworthtrending: insightsworthtrending,
		insightsworthplays: insightsworthplays,
		insightsworthratings: insightsworthratings,
		insightsworthgames: insightsworthgames,
		insightscollectiondependency: insightscollectiondependency,
		insightscollectionduration: insightscollectionduration,
		insightscollectionsuitability: insightscollectionsuitability,
		insightscollectionrarity: insightscollectionrarity,
		insightscollectionbestduration: insightscollectionbestduration
		
	},

	data: function() {

		return {

			is: {
				active: false,
				loading: false
			},

			stack: [],
			panels: {}

		}

	},

	created: function() {

		this.$_.each(this.$options.components, function(component, key) {

			this.panels[key] = false

		}.bind(this))

		this.$pubsub.$on('panel.open', this.onPanelOpen.bind(this))
		this.$pubsub.$on('panel.close', this.onPanelClose.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('panel.open', this.onPanelOpen.bind(this))
		this.$pubsub.$off('panel.close', this.onPanelClose.bind(this))

	},

	methods: {

		onPanelOpen: function(e) {

			this.$pubsub.$emit('open.dropdown')

			this.is.loading = true

			var type = (typeof e === 'string') ? e.toLowerCase() : e.type.toLowerCase()

			this.panels[type] = e

			if (e.child) {

				this.stack.push(this.is.active)

			} else {

				this.stack = []
				if (!this.is.active) this.$pubsub.$emit('panel.opened')
				
			}

			this.is.active = type

		},

		onPanelClose: function() {

			if (this.stack.length) {

				this.is.active = this.stack.pop()

			} else {

				this.$pubsub.$emit('close.dropdown')
				this.stack = []
				this.is.active = false
				this.$pubsub.$emit('panel.closed')

			}

		},

		onReady: function() {

			this.is.loading = false

		}

	}

}

</script>

<style scoped>

.panels {
	position: fixed;
	right: -320px;
	height: calc(100vh - var(--vh-offset, 0px) - 64px);
	top: 64px;
	width: 320px;
	background-color: #2b5870;
	z-index: 10000;
	display: flex;
	flex-direction: column;
	transition: right 100ms linear;
}

.is-mobile .panels {
	right: -100%;
	transition: none;
	width: 100%;
	top: 144px;
	height: calc(100vh - var(--vh-offset, 0px) - 144px);
}

.panels-loading {
	left: 50%;
	top: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
	font-size: 32px;
	color: #fff;
}

.panels.is-active {
	right: 0px;
}

.is-mobile .panels {
	width: 100%;
	right: -100%;
}

.is-mobile .panels.is-active {
	right: 0%;
}

.panels.is-loading .panels-content {
	display: none;
}

.panels-close,
.panels-back {
	position: absolute;
	right: 20px;
	top: 24px;
	height: 40px;
	width: 40px;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.25);
	color: #fff;
	line-height: 40px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	z-index: 3;
}

.is-smaller .panels-close,
.is-smaller .panels-back {
	top: 12px;
}

.is-mobile .panels-close,
.is-mobile .panels-back {
	width: 32px;
	height: 32px;
	line-height: 32px;
	right: 10px;
	top: 24px;
	margin-top: -12px;
	font-size: 16px;
}

.is-desktop .panels-close:hover,
.is-desktop .panels-back:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

</style>
