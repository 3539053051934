<template>

<core-modal v-on:close="onClose" :game="data.game">

	<template v-slot:head>

		Move Game

	</template>

	<template v-slot:default>

		<p>You can move <b>{{ name }}</b> instantly to any other position in the list by entering a position between 1 and {{ max }}.</p>

		<core-form-input-textbox :autofocus="true" :validation="validation" placeholder="Enter new position" v-model="model.position" v-on:validate="onValidated" />

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Confirm</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

	props: ['data'],

	data: function() {

		return {

			is: {
				loading: false,
				valid: false
			},

			model: {
				position: ''
			}

		}

	},

	computed: {

		validation: function() {
		
			return {
				number: true,
				min: 1,
				max: this.max
			}

		},

		max: function() {

			return this.$store.getters['page/list/games/count']
		
		},

		current: function() {
		
			return 0

		},

		name: function() {
		
			return this.$store.getters['page/list/associated/games/all'][this.data.game].game.name
		
		}

	},

	methods: {

		onValidated: function(e) {
		
			this.is.valid = e.valid

		},

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/list/order', {
					item: this.data.item,
					position: this.model.position
				}).then(function() {

					this.$pubsub.$emit('list.refresh')

					this.$emit('close')

				}.bind(this), function() {

					this.is.loading = false

				}.bind(this))
			
			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>