<template>

<com-item name="collection.years" :has-more="moreYears" v-on:more="onMoreClick" :size="size" :is-empty="!active" :loading="loading" title="Publish Year">

	<template v-slot:about>

		<p>Which years {{ your }} games were published in.</p>

		<p class="small"><template v-if="yearValue === 'version'">Where version not specified, game first published year is used. </template>Excludes expansions.</p>

		<p class="small">Click a year to view games.</p>

	</template>

	<template v-slot:stats>
	
		<div class="years">

			<div class="year-item" :data-band="band(year.count)" v-for="year in years" :key="year.year" v-on:click="onYearClick($event, year.year)">

				<div class="year-item-count">{{ year.count }}</div>
				<div class="year-item-year">{{ year.year }}</div>

			</div>

		</div>

	</template>

	<template v-slot:buttons>

		<com-toggle :options="yearOptions" :value="yearValue" v-on:change="onYearChange" />
						
	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		years: function() {

			return this.$store.getters['page/insights/collection/years']

		},

		active: function() {

			return this.years.length && !this.loading

		},

		moreYears: function() {

			return this.$store.getters['page/insights/collection/moreYears']

		},

		yearOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.YEAR.VERSION, text: 'Version'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.YEAR.FIRST, text: 'First published'})

			return options

		},

		yearValue: function() {

			return this.$store.getters['filter/insights/collection/year']

		}

	},

	methods: {

		onYearClick: function(e, year) {

			e.stopPropagation()

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionYear',
				year: year
			})

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionYears'
			})

		},

		onYearChange: function(value) {

			this.$store.commit('filter/insights/collection/year', value)

		},

		band: function(count) {

			if (count) {

				var perBand = this.$_.max(this.years, function(item) { return item.count }).count / 4
				var band = Math.ceil(count / perBand)

				return band

			} else {

				return 0

			}

		}

	}

}

</script>

<style scoped>

.years {
	display: grid;
	height: 100%;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	border-radius: 4px;
	overflow: hidden;
}

.year-item {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	cursor: pointer;
}

.year-item[data-band="0"] {
	background-color: rgba(17, 105, 135, 0);
	color: #ccc;
	pointer-events: none;
}

.year-item[data-band="0"]:nth-child(1),
.year-item[data-band="0"]:nth-child(2),
.year-item[data-band="0"]:nth-child(3),
.year-item[data-band="0"]:nth-child(4),
.year-item[data-band="0"]:nth-child(5) {
	height: 65px;
}

.year-item:nth-child(1),
.year-item:nth-child(2),
.year-item:nth-child(3),
.year-item:nth-child(4),
.year-item:nth-child(5),
.year-item:nth-child(21),
.year-item:nth-child(22),
.year-item:nth-child(23),
.year-item:nth-child(24),
.year-item:nth-child(25) {
	height: 65px;
}

.year-item[data-band="1"] {
	background-color: rgba(17, 105, 135, 0.4);
}

.year-item[data-band="2"] {
	background-color: rgba(17, 105, 135, 0.6);
}

.year-item[data-band="3"] {
	background-color: rgba(17, 105, 135, 0.8);
}

.year-item[data-band="4"] {
	background-color: rgba(17, 105, 135, 1);
}

.is-desktop .year-item:hover {
	background-color: rgba(17, 105, 135, 1);
}

.year-item-count {
	font-size: 21px;
	line-height: 24px;
	font-weight: 500;
}

.year-item-year {
	font-size: 12px;
	line-height: 14px;
	font-weight: 400;
	font-family: 'Roboto Mono', monospace;
}

</style>