<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>{{ description}}</p>

	</template>

	<template v-slot:body>

		<app-streak :hideGames="false" :item="item" :showSummary="false" :label="label" v-for="(item, index) in stats" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'stats',
			params: [
				'statType'
			],
			stats: [],
			titles: {
				day: 'Plays in a day',
				new: '1st plays in a day'
			},
			labels: {
				day: 'play',
				new: 'play'
			},
			descriptions: {
				day: 'Most plays in a single day.',
				new: 'Most first plays in a single day.'
			}
		}

	},

	computed: {

		label: function() {

			return this.labels[this.data.statType]

		},

		title: function() {

			return this.titles[this.data.statType]

		},

		description: function() {

			return this.descriptions[this.data.statType]

		}

	},

	methods: {

		response: function(json) {

			this.stats = json.stats

		}

	}

}

</script>