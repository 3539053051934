<template>

<div v-if="layout.show.user || layout.show.title || custom" class="tool-head" v-bind:class="{'has-border': border, 'is-square': square}" v-bind:style="{paddingLeft: margin}">

	<core-avatar type="context" class="tool-head-avatar" v-if="layout.show.user" />

	<div class="tool-head-name" v-if="layout.show.title || layout.show.user">
		<span v-if="layout.show.title">{{ layout.title }}</span>
		<span v-if="!layout.show.title && layout.show.user">{{ name }}</span>
		<small v-if="layout.show.title && layout.show.user">{{ name }}</small>
	</div>

	<div class="tool-head-custom">

		<slot>

		</slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['layout', 'margin', 'border', 'square', 'custom'],

	computed: {

		name: function() {

			return this.$store.getters['context/name']

		}

	}

}

</script>

<style scoped>

.tool-head {
	display: flex;
	flex-direction: row;
	height: 32px;
	margin-bottom: 4px;
}

.tool-head.is-square {
	margin-bottom: 0px;
}

.tool-head.has-border {
	border-bottom: 1px solid #eee;
	height: 36px;
	padding-bottom: 4px;
}

.tool-head.has-border.is-square {
	height: 33px;
	padding-bottom: 0px;
}

.tool-head-avatar {
	width: 32px;
	flex-shrink: 0;
	height: 32px;
	border-radius: 16px;
	margin-right: 4px;
}

.tool-head.is-square .tool-head-avatar {
	border-radius: 4px 0px 0px 0px;
	margin-left: -1px;
	margin-top: -1px;
	width: 34px;
	height: 34px;
	margin-right: 0px;
}

.tool-head-name {
	flex-grow: 1;
	font-size: 16px;
	line-height: 16px;
	color: #333;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.is-mobile .tool-head-name {
	font-size: 14px;
}

.tool-head.is-square .tool-head-name {
	padding-left: 8px;
}

.tool-head-name small {
	color: #666;
	font-weight: 300;
	line-height: 12px;
	font-size: 12px;
	display: block;
}

.tool-head-custom {
	display: flex;
	align-items: center;
}

</style>
