<template>

<core-filter-advanced-group title="Subset" :count="count">

	<core-filter-input-suggest placeholder="Start typing a game's name to add..." field="name" :filter="value('games/subset')" api="games/suggest" data="games" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['games.subset']
		}
	}

}

</script>

<style scoped>

.filter-text {
	font-size: 12px;
    display: block;
    color: #fff;
    font-weight: 400;
    padding-bottom: 8px;
}

</style>