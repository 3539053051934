<template>

<com-item name="size.changes" :size="size" :loading="loading" title="Recent Changes" :is-cards="true" :has-more="true" v-on:more="onAllClick">

	<template v-slot:about>

		<p>The games most recently added or removed from {{ your }} collection.</p>

	</template>

	<template v-slot:stats>

		<com-card :item="item" v-for="item in data" :key="item.id">

			<core-card-foot-stat :value="item.plays" text="play" :plural="true" />
				
			<core-card-foot-stat :is-rating="true" :value="item.rating" text="rating" />

		</com-card>

	</template>

	<template v-slot:buttons>

		<com-toggle :options="settings.type.options" :value="settings.type.value" v-on:change="onTypeChange" />
					
	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'
import comCard from './../common/Card'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle,
		'com-card': comCard
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'changes.type' 
 
		},

		data: function() {

			return this.$store.getters['page/insights/size/changes']

		},

		active: function() {

			return this.data && !this.loading

		},

		settings: function() {

			var settings = {}

			settings.type = {
				options: [
					{value: 'added', text: 'Recently added'},
					{value: 'removed', text: 'Recently removed'}
				],
				value: this.$store.getters['filter/insights/size/changes/type']
			}

			return settings

		}

	},

	methods: {

		onAllClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsSizeChanges'
			})

		},

		onTypeChange: function(value) {

			this.$store.commit('filter/insights/size/changes/type', value)

		}

	}

}

</script>

<style scoped>

</style>