<template>
	
<core-list-cell :format="format" type="stat" class="plays" :condensed="condensed" v-bind:class="{'is-summary': summary, 'is-clickable': !summary && !isUser}" v-on:click="onPlaysClick" v-if="!format.hideMobile || !window.is.mobile">
				
	<i class="fa fa-dice"></i>
	<span>{{ value }}<span v-if="total" class="plays-total"> ({{ total }})</span></span>

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format', 'summary', 'condensed'],

	data: function() {

		return {
			value: '',
			total: false
		}

	},

	watch: {

		item: {
			handler: function() {

				this.updateVars()

			},
			deep: true
		}

	},

	created: function() {

		this.updateVars()

	},

	methods: {

		updateVars: function() {

			if (this.format.context === 'filter') {

				if (this.item.context.user || this.item.context.users) {

					this.value = (this.isUser) ? this.item.context.user.plays : this.item.context.users.totalPlays

					if (this.format.showTotal) {

						this.total = (this.isUser) ? this.item.user.plays : this.item.users.totalPlays

					}

				} else {

					this.value = '-'

				}

			} else {

				this.value = (this.isUser) ? this.item.user.plays : this.item.users.totalPlays

			}

		},

		onPlaysClick: function() {

			if (!this.isUser) {

				this.$pubsub.$emit('expand.open', {
					id: this.item.game.id,
					section: 'plays'
				})

			}

		}

	}

}

</script>

<style scoped>

.is-desktop .plays.is-clickable:hover span {
	cursor: pointer;
}

@media only screen and (max-width: 767px) {

	.plays.is-summary {
		display: block;
		font-size: 10px;
		height: 16px;
		width: 40px!important;
		line-height: 16px;
		text-align: left;
		color: #333;
		font-weight: 400;
	}

}

.plays .fa {
	display: none;
}

@media only screen and (max-width: 767px) {

	.plays.is-summary .fa {
		display: inline-block;
		line-height: 14px;
		color: #666;
		margin-right: 4px;
	}

}

.plays-total {
	color: #666;
	font-size: 11px;
}

.plays span {
	z-index: 2;
}

</style>
