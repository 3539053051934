<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Login

	</template>

	<template v-slot:default>

		<p>Login if you have already verified your BGG username and registered a geekgroup account. This is not your BGG login.</p>

        <core-form-input-textbox name="username" v-on:validate="validate" placeholder="Enter BGG username" v-model="inputs.username" :error="is.error" :disabled="is.success || is.loading" :validation="validation.username"></core-form-input-textbox>
        <core-form-input-password name="password" v-on:validate="validate" placeholder="Enter geekgroup password" v-model="inputs.password" :error="is.error" :disabled="is.success || is.loading" :validation="validation.password" validationMessage="off" v-on:enter="onLoginClick"></core-form-input-password>

        <div class="buttons">
			<core-button v-on:click.native="onLoginClick" :loading="is.loading">Login</core-button>
			<core-button v-on:click.native="onForgotClick" theme="plain">Forgot password</core-button>
		</div>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				username: '',
                password: ''
			},

			validation: {
				username: { exists: true },
				password: { format: true }
			}

		}

	},

	methods: {

		onLoginClick: function() {

			this.is.loading = true

			this.$store.dispatch('api/session/login', {
				username: this.inputs.username,
				password: this.inputs.password
			}).then(function(json) {

				this.$router.push({
					name: 'Collection',
					params: {
						name: json.slug,
						type: this.$CONSTANTS.TYPE.USERS
					}
				}).catch(function() {
				
					this.$router.go()
				
				}.bind(this))

			}.bind(this), function(e) {

				if(e.error === 111) {
				
					this.$router.go()
				
				} else {
				
					this.is.error = true
					this.is.loading = false

				}

			}.bind(this)) 

		},

		onForgotClick: function() {

			this.$emit('close')
			this.$pubsub.$emit('open.modal', 'userForgot')

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

</style>