import PubSub from 'core/pubsub'
import vhCheck from 'vh-check'

function windowResize() {

	var height = window.innerHeight
	var width = window.innerWidth

	window.sizeData = {
		menuIndentContent: 0,
		width: width,
		height: height,
		is: {
			menu: (window.sizeData) ? window.sizeData.menu : false,
			mobile: width < 768,
			tablet: width >= 768 && width < 1024,
			smalldesktop: width >= 1024 && width < 1280,
			desktop: width >= 1280
		}
	}

	PubSub.$emit('window.resize', window.sizeData)

}

PubSub.$on('window.initiated', function() {

	vhCheck()
	windowResize()

})

var indentCount = 0

function openIndent(name) {

	indentCount++

	if (window.sizeData.width < 768) {

		window.sizeData.menuIndentContent = '0'

	} else {

		window.sizeData.menuIndentContent = '-320px'
	}

	PubSub.$emit('window.resize', window.sizeData)

	console.log('open', name, indentCount)

}

function closeIndent(name) {

	if (indentCount) indentCount--

	if (indentCount === 0) {

		window.sizeData.menuIndentContent = 0

		PubSub.$emit('window.resize', window.sizeData)
		
	}

	console.log('close', name, indentCount)

}

function openMenu() {

	if (window.sizeData.width < 768) {

		window.sizeData.menuIndentContent = '100%'

	} else {
	
		window.sizeData.menuIndentContent = '320px'

	}

	console.log('menu.open')
	
}

function closeMenu() {

	window.sizeData.menuIndentContent = 0

	console.log('menu.close')
	
}

PubSub.$on('panel.opened', openIndent)
PubSub.$on('panel.closed', closeIndent)

PubSub.$on('open.modal', openIndent)
PubSub.$on('close.modal', closeIndent)

PubSub.$on('menu.open', openMenu)
PubSub.$on('menu.close', closeMenu)

window.addEventListener('resize', windowResize)

export default {

	data: function() {
		
		return {
			window: {
				menuIndentContent: 0,
				width: 0,
				height: 0,
				is: {
					menu: false,
					mobile: false,
					tablet: false,
					desktop: false
				}
			}
		}

	},

	created: function() {

		this.window = window.sizeData

		PubSub.$on('window.resize', this.onWindowResize.bind(this))

	},

	beforeDestroy: function() {

		PubSub.$off('window.resize', this.onWindowResize.bind(this))

	},

	methods: {

		onWindowResize: function(data) {

			this.window = data

		}

	}

}