<template>

<div class="content" :class="{'is-standalone': $route.meta.isStandalone}" v-bind:style="{'left': (!$route.meta.isStandalone) ? window.menuIndentContent : 0}">

    <com-context :is-inline="true" v-if="!window.is.desktop && !$route.meta.isSession && !$route.meta.isStandalone" />
    <com-session :is-inline="true" v-if="!window.is.desktop && $route.meta.isSession && !$route.meta.isStandalone" />

    <div class="content-action" v-if="actions.length">

        <template v-for="(action, index) in actions">

            <com-action :action="action" :key="index" v-if="index === 0" />

        </template>

    </div>

    <template v-if="!actions.length">

        <core-simplebar v-if="!$route.meta.isStandalone" class="content-scroll" v-bind:style="{paddingLeft: (window.is.desktop) ? window.menuPadding : null}">

            <slot></slot>

        </core-simplebar>

        <template v-if="$route.meta.isStandalone">

            <slot></slot>

        </template>

    </template>

</div>

</template>

<script>

import comContext from './panel/Context'
import comSession from './panel/Session'
import comAction from './content/Action'

export default {

    components: {
        'com-context': comContext,
        'com-session': comSession,
        'com-action': comAction
    },

    computed: {
    
        actions: function() {

            return this.$_.filter(this.$store.getters['context/actions'], function(action) {
            
                return this.$_.contains(action.routes, this.$route.name) || this.$_.contains(action.routes, '*')

            }.bind(this))
        
        }
    
    }

}

</script>

<style scoped>

.content {
	height: calc(100vh - var(--vh-offset, 0px));
    background-color: #efefef;
	transition: left 100ms linear;
}

.is-mobile .content {
    background-color: #fff;
	transition: none;
}

.content.is-standalone {
    background-color: #082534;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-scroll {
    overflow-y: auto;
    overflow-x: hidden;
	height: calc(100vh - var(--vh-offset, 0px));
    padding: 192px 40px 40px 360px;
}

.is-smaller .content-scroll {
    padding: 148px 20px 20px 20px;
}

.is-mobile .content-scroll {
    padding: 144px 0px 0px 0px;
}

.content-action {
    overflow-y: auto;
    overflow-x: hidden;
	height: calc(100vh - var(--vh-offset, 0px));
    padding: 104px 40px 40px 360px;
    background-color: #fff;
}

.is-smaller .content-action {
    padding: 84px 20px 20px 20px;
}

.is-mobile .content-action {
    padding: 96px 0px 0px 0px;
}

</style>
