<template>

<com-item name="shelf.after" :size="size" :loading="loading" title="Off the shelf" :is-empty="!active">

	<template v-slot:about>

		<p>What happened to {{ your }} games once they came off the shelf and were played.</p>

	</template>

	<template v-slot:stats>

		<com-table :columns="['Time on Shelf', 'Games', 'Avg. Plays', 'Still Own', 'Ratings']" class="grid">

			<com-row v-for="(sample, index) in data" :key="index">

				<com-cell type="link" :value="sample.name" v-on:click="onClick(index)" />
				<com-cell type="number" :value="sample.games" />
				<com-cell type="number" :value="sample.playsAverage" />
				<com-cell type="percent" :value="sample.collectionPercent" />
				<com-cell type="rating" :value="sample.ratingAverage" :count="sample.ratingCount" />

			</com-row>

		</com-table>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comTable from './../common/Table'
import comCell from './../common/table/Cell'
import comRow from './../common/table/Row'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-table': comTable,
		'com-row': comRow,
		'com-cell': comCell
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		data: function() {

			return this.$store.getters['page/insights/shelf/after']

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		onClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsShelfAfter',
				specific: id + 1
			})

		}

	}

}

</script>

<style scoped>

.grid >>> .row {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

</style>