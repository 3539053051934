import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		permissions: {
			is: {
				owner: false,
				member: false
			},
			group: {
				add: false,
				leave: false,
				join: false,
				edit: false,
				delete: false,
				remove: false
			},
			user: {
				sync: false
			},
			list: {
				create: false,
				edit: false,
				delete: false
			},
			edition: {
				create: false,
				edit: false,
				delete: false
			}
		}

	},

	getters: {

		'is/owner': function(state) {

			return state.permissions.is.owner

		},

		'is/member': function(state) {

			return state.permissions.is.member

		},

		'is/userself': function(state) {

			return state.permissions.is.member || state.permissions.is.owner

		},

		'group/add': function(state) {

			return state.permissions.group.add

		},

		'group/leave': function(state) {

			return state.permissions.group.leave

		},

		'group/join': function(state) {

			return state.permissions.group.join

		},

		'group/edit': function(state) {

			return state.permissions.group.edit

		},

		'group/delete': function(state) {

			return state.permissions.group.delete

		},

		'group/remove': function(state) {

			return state.permissions.group.remove

		},

		'user/sync': function(state) {

			return state.permissions.user.sync

		},

		'list/create': function(state) {

			return state.permissions.list.create

		},

		'list/edit': function(state) {

			return state.permissions.list.edit

		},

		'list/delete': function(state) {

			return state.permissions.list.delete

		},

		'edition/create': function(state) {

			return state.permissions.edition.create

		},

		'edition/edit': function(state) {

			return state.permissions.edition.edit

		},

		'edition/delete': function(state) {

			return state.permissions.edition.delete

		}

	},

	mutations: {

		set: function(state, data) {

			data = _.defaults(data, {
				is: {
					owner: false,
					member: false
				},
				group: {
					add: false,
					leave: false,
					join: false,
					edit: false,
					delete: false,
					remove: false
				},
				user: {
					sync: false
				},
				list: {
					create: false,
					edit: false,
					delete: false
				},
				edition: {
					create: false,
					edit: false,
					delete: false
				}
			})

			state.permissions = data

		}

	},

	actions: {}

}