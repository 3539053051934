<template>

<div class="game">

    <core-avatar :id="game.id" type="game" class="game-avatar" :class="{'is-owned': game.own}" :tooltip="true" :tooltipSmall="'Rank ' + game.rank">

		<div class="game-avatar-hint" :data-band="ratingBand"></div>

		<core-rating class="game-avatar-rating" :value="game.rating" v-if="game.count" />

	</core-avatar>

	<div v-if="!game.id" :key="game.rank" class="game-avatar">?</div>

</div>

</template>

<script>

export default {

    props: ['game'],

    computed: {
    
        ratingBand: function() {
        
            return (this.game.rating === '-' || this.game.rating === 0) ? '-' : Math.floor(this.game.rating)
        
        }
    
    }

}

</script>

<style scoped>

.game-avatar {
	width: 100%;
	height: 100%;
	opacity: 0.2;
	text-align: center;
	font-weight: 400;
	font-size: 14px;
}

.game-avatar-hint {
	position: absolute;
	left: 0px;
	border-radius: 4px 0px 0px 0px;
	top: 0px;
	width: 12px;
	clip-path: polygon(0 0, 0% 100%, 100% 0);
	height: 12px;
}

.game-avatar-hint[data-band="10"] { background-color: #249563; }
.game-avatar-hint[data-band="9"] { background-color: #249563; }
.game-avatar-hint[data-band="8"] { background-color: #2FC482; }
.game-avatar-hint[data-band="7"] { background-color: #1d8acd; }
.game-avatar-hint[data-band="6"] { background-color: #5369a2; }
.game-avatar-hint[data-band="5"] { background-color: #5369a2; }
.game-avatar-hint[data-band="4"] { background-color: #df4751; }
.game-avatar-hint[data-band="3"] { background-color: #df4751; }
.game-avatar-hint[data-band="2"] { background-color: #db303b; }
.game-avatar-hint[data-band="1"] { background-color: #db303b; }
.game-avatar-hint[data-band="0"] { display: none; }
.game-avatar-hint[data-band="-"] { display: none; }

.game-avatar-rating {
	width: 100%;
	height: 100%;
	opacity: 0;
	text-align: center;
	font-weight: 300;
}

.game-avatar-rating >>> .rating-value {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	font-size: 14px!important;
}

.game-avatar.is-owned:hover .game-avatar-rating {
	opacity: 1;	
}

.is-desktop .game-avatar:hover,
.game-avatar.is-owned {
	opacity: 1;
}

</style>