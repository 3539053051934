<template>

<div class="scatter" :class="{'is-points': data.axis.points, 'has-x-label': data.axis.x.label, 'has-y-label': data.axis.y.label}">

	<div class="scatter-axis-x" :class="{'is-offset': data.axis.x.offset}">

		<div class="scatter-axis-item" v-on:click="onClick(false, index)" v-on:mouseenter="onEnter(false, index)" v-on:mouseleave="onLeave" :class="{'is-active': index === currentCol}" v-for="(label, index) in axis.x.labels" :key="index"><span>{{ label }}</span></div>

	</div>

	<div class="scatter-axis-label is-x" v-if="data.axis.x.label">{{ data.axis.x.label }}</div>

	<div class="scatter-axis-y" :class="{'is-offset': data.axis.y.offset}">

		<div class="scatter-axis-item" v-on:click="onClick(index, false)" v-on:mouseenter="onEnter(index, false)" v-on:mouseleave="onLeave" :class="{'is-active': index === currentRow}" v-for="(label, index) in axis.y.labels" :key="index"><span>{{ label }}</span></div>

	</div>

	<div class="scatter-axis-label is-y" v-if="data.axis.y.label"><span>{{ data.axis.y.label }}</span></div>

	<div class="scatter-data">

		<div class="scatter-data-grid">

			<div class="scatter-data-grid-row" v-for="(label, row) in axis.y.labels" :key="row">
				<div class="scatter-data-grid-column" v-on:click="onClick(row, col)" v-on:mouseenter="onEnter(row, col)" v-on:mouseleave="onLeave" :class="{'is-active': (currentCol === col && (currentRow > row || currentRow === false)) || ((currentCol > col || currentCol === false) && currentRow === row), 'is-occupied': gridOccupied(row, col)}" v-tooltip="gridTooltip(row, col)" v-for="(label, col) in axis.x.labels" :key="col"></div>
			</div>

		</div>

		<div class="scatter-data-points">

			<transition-group name="list">

				<div class="scatter-data-points-item" v-on:click="onClick(point.grid.y, point.grid.x)" v-tooltip="point.tooltip" v-for="point in points" :data-size="point.size" :key="point.id" :style="point.style">{{ point.label }}</div>

			</transition-group>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['data'],

	data: function() {

		return {
			currentRow: false,
			currentCol: false
		}

	},

	computed: {

		segmentX: function() {

			return 100 / this.axis.x.labels.length

		},

		segmentY: function() {

			return 100 / this.axis.y.labels.length

		},

		axis: function() {
		
			var axis = {
				x: {
					labels: [],
					thresholds: []
				},
				y: {
					labels: [],
					thresholds: []
				}
			}

			var min, max, i

			if (this.data.axis.x.labels) {
			
				axis.x.labels = this.data.axis.x.labels
				axis.x.thresholds = this.data.axis.x.thresholds
			
			} else {
			
				min = 0
				max = this.max.x

				max = Math.ceil(max / 10)

				for(i = min; i<= 10; i++) {
				
					axis.x.labels.push(i * max)
					axis.x.thresholds.push(i * max)
				
				}
			
			}

			if (this.data.axis.y.labels) {
			
				axis.y.labels = this.data.axis.y.labels
				axis.y.thresholds = this.data.axis.y.thresholds
			
			} else {
			
				min = 0
				max = this.max.y

				max = Math.ceil(max / 10)

				for(i = min; i<= 10; i++) {
				
					axis.y.labels.push(i * max)
					axis.y.thresholds.push(i * max)
				
				}
			
			}

			return axis

		},

		max: function() {

			var max = {
				x: 0,
				y: 0
			}
		
			this.$_.each(this.data.datasets, function(dataset) {

				this.$_.each(dataset.data, function(item) {

					if (max.x < item.x) max.x = item.x
					if (max.y < item.y) max.y = item.y

				})

			}.bind(this))

			if (this.data.axis.y.max) {
			
				max.y = this.data.axis.y.max
			
			}

			return max

		},

		points: function() {

			var points = []

			this.$_.each(this.data.datasets, function(dataset) {

				this.$_.each(dataset.data, function(item, index) {

					if (item.x && item.y) {

						var x = this.$_.findIndex(this.axis.x.thresholds, function(value) {

							return value > item.x

						})
						
						var y = this.$_.findIndex(this.axis.y.thresholds, function(value) {

							return value > item.y

						})

						if (x === -1) x = this.axis.x.thresholds.length - 1
						if (y === -1) y = this.axis.y.thresholds.length - 1

						var styleX = (this.segmentX * (x - 1)) + (this.segmentX / (this.axis.x.thresholds[x] - this.axis.x.thresholds[x - 1])) * (item.x - this.axis.x.thresholds[x - 1])
						
						var styleY = (this.segmentY * (y - 1)) + (this.segmentY / (this.axis.y.thresholds[y] - this.axis.y.thresholds[y - 1])) * (item.y - this.axis.y.thresholds[y - 1])

						if(styleX > 100) styleX = 100
						if(styleY > 100) styleY = 100

						points.push({
							id: item.id,
							size: dataset.size,
							label: (dataset.labels) ? dataset.labels[index] : '',
							tooltip: item.tooltip || item.x.toString() + ',' + item.y.toString(),
							grid: {
								x: x - 1,
								y: y - 1
							},
							style: {
								backgroundColor: (dataset.colors) ? dataset.colors[index] : false,
								left: styleX.toString() + '%',
								bottom: styleY.toString() + '%'
							}
						})

					}

				}.bind(this))

			}.bind(this))

			return points

		}

	},

	methods: {

		onClick: function(r, c) {

			this.$emit('click', {
				x: c,
				y: r
			})

		},

		onEnter: function(r, c) {

			if (this.data.axis.points) return

			this.currentRow = r
			this.currentCol = c
				
		},

		onLeave: function() {

			if (this.data.axis.points) return

			this.currentRow = false
			this.currentCol = false

		},

		gridOccupied: function(r, c) {

			if (this.data.axis.points) return

			return this.$_.filter(this.points, function(point) {

				return point.grid.x === c && point.grid.y === r

			})

		},

		gridTooltip: function(r, c) {

			if (this.data.axis.points) return

			var occupied = this.gridOccupied(r, c)

			if (occupied.length) {

				return occupied.length.toString() + ' games <small>Click to view</small>'

			} else {

				return 'No games'

			}

		}

	}

}

</script>

<style scoped>

.scatter {
	width: 100%;
	height: 100%;
	user-select: none;
	overflow: hidden;
}

.scatter-axis-y {
	width: 32px;
	position: absolute;
	left: 0px;
	bottom: 32px;
	top: 0px;
	writing-mode: vertical-rl;
	text-orientation: mixed;
	display: flex;
	flex-direction: row-reverse;
	z-index: 2;
}

.scatter.has-y-label .scatter-axis-y {
	left: 16px;
}

.scatter.has-x-label .scatter-axis-y {
	bottom: 48px;
}

.scatter-axis-x {
	height: 32px;
	position: absolute;
	left: 32px;
	bottom: 0px;
	right: 0px;
	display: flex;
	z-index: 2;
}

.scatter.has-x-label .scatter-axis-x {
	bottom: 16px;
}

.scatter.has-y-label .scatter-axis-x {
	left: 48px;
}

.scatter-axis-label {
	position: absolute;
	color: #999;
	letter-spacing: 1px;
	font-size: 11px;
	text-align: center;
	font-family: 'Roboto Mono', monospace;
    font-weight: 400;
}

.scatter-axis-label.is-x {
	bottom: 0px;
	width: 100%;
	left: 0px;
	line-height: 24px;
	color: #666;
    height: 24px;
}

.scatter-axis-label.is-y {
	left: 0px;
	width: 16px;
	top: 0px;
	height: 100%;
	white-space: nowrap;
}

.scatter-axis-label.is-y span {
	display: block;
	width: 100%;
	padding-top: 6px;
	height: 100%;
	display: flex;
	transform: rotate(-90deg);
	justify-content: center;
	align-items: center;
}

.scatter-axis-item {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 100ms linear;
}

.scatter-axis-x .scatter-axis-item {
	text-align: center;
	color: #333;
	font-size: 12px;
    font-weight: 400;
	border-top: 1px solid #ddd;
	padding: 0px 5px;
}

.scatter-axis-x.is-offset .scatter-axis-item span {
	position: absolute;
	display: block;
	width: 100%;
    transform: translateX(-50%);
}

.scatter:not(.is-points) .scatter-axis-x .scatter-axis-item:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.scatter-axis-y .scatter-axis-item {
	text-align: center;
	padding: 5px 0px;
	color: #333;
	font-size: 11px;
    font-weight: 400;
	border-right: 1px solid #ddd;
}

.scatter:not(.is-points) .scatter-axis-y .scatter-axis-item:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.scatter:not(.is-points) .scatter-axis-item:hover,
.scatter:not(.is-points) .scatter-axis-x .scatter-axis-item:hover,
.scatter:not(.is-points) .scatter-axis-y .scatter-axis-item:hover {
	background-color: rgba(12, 137, 178, 0.5);
}

.scatter-axis-x .scatter-axis-item.is-active {
	background-color: #4881bb!important;
}

.scatter-axis-y .scatter-axis-item.is-active {
	background-color: #4881bb!important;
}

.scatter-axis-y .scatter-axis-item span {
	display: block;
	width: 100%;
	height: 100%;
	display: flex;
	transform: rotate(-180deg);
	justify-content: center;
	align-items: center;
}

.scatter-axis-y.is-offset .scatter-axis-item span {
    transform: rotate(-180deg) translateY(-50%);
	margin-top: 100%;
}

.scatter-data {
	width: 100%;
	height: 100%;
	padding: 0px 0px 32px 32px;
	z-index: 3;
}

.scatter.has-y-label .scatter-data {
	padding-left: 48px;
}

.scatter.has-x-label .scatter-data {
	padding-bottom: 48px;
}

.scatter-data-grid {
	display: flex;
	flex-direction: column-reverse;
	position: absolute;
	z-index: 1;
	left: 32px;
	right: 0px;
	top: 0px;
	bottom: 32px;
}

.scatter.has-y-label .scatter-data-grid {
	left: 48px;
}

.scatter.has-x-label .scatter-data-grid {
	bottom: 48px;
}

.scatter-data-grid-row {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
}

.scatter-data-grid-column {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	cursor: pointer;
	transition: background-color 100ms linear;
}

.scatter.is-points .scatter-data-grid-row {
	border-bottom: 1px solid rgba(12, 137, 178, 0.1);
}

.scatter.is-points .scatter-data-grid-row:first-child {
	border-bottom: 0px;
}

.scatter.is-points .scatter-data-grid-column {
	border-right: 1px solid rgba(12, 137, 178, 0.1);
}

.scatter.is-points .scatter-data-grid-column:last-child {
	border-right: 0px;
}

.scatter:not(.is-points) .scatter-data-grid-row:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.scatter:not(.is-points) .scatter-data-grid-column:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.scatter-data-grid-column.is-active {
	background-color: #4881bb!important;
}

.scatter:not(.is-points) .scatter-data-grid-column:hover {
	background-color: rgba(12, 137, 178, 0.5);
}

.scatter-data-points {
	position: absolute;
	left: 32px;
	right: 0px;
	top: 0px;
	bottom: 32px;
	z-index: 2;
	pointer-events: none;
}

.scatter.has-y-label .scatter-data-points {
	left: 48px;
}

.scatter.has-x-label .scatter-data-points {
	bottom: 48px;
}

.scatter-data-points-item {
	position: absolute;
	width: 8px;
	height: 8px;
	pointer-events: auto;
	border-radius: 50%;
	background-color: rgb(33, 126, 168);
	cursor: pointer;
	transform: translate(-50%, 50%);
	mix-blend-mode: multiply;
	color: #fff;
	text-align: center;
	transition: all 100ms linear;
	opacity: 1;
}

.scatter-data-points-item[data-size="large"] {
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 12px;
	font-weight: 500;
	z-index: 1;
}

.scatter-data-points-item[data-size="large"]:hover {
	transform: translate(-50%, 50%) scale(1.25);
}

.list-enter-active, .list-leave-active {
	transition: all 100ms;
}

.list-enter, .list-leave-to {
	opacity: 0;
}

</style>