<template>

<core-filter-advanced-group title="Members" :count="count" v-if="isGroup || isBuddies">

	<core-filter-input-suggest placeholder="Start typing a name..." field="name" :filter="value('members')" api="users/suggest" data="users" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['members']
		}
	}

}

</script>