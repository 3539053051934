<template>

<core-panel>

	<template v-slot:head>

		<h1>Rating of {{ rating }}</h1>

		<p>There are <b>{{ games.length }}</b> games that have received a rating of {{ rating }}.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="item in games" :item="item" v-bind:key="item.id" :subtext="item.subtext" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'distribution',
			params: [
				'rating'
			],
			games: [],
			rating: ''
		}

	},

	methods: {

		response: function(json) {

			this.rating = json.rating
			this.games = json.games

		}

	}

}

</script>