import store from 'app/store'
import pubsub from 'core/pubsub'

export default {

	move: function(context, e) {

		e = e || {}

		e.override = e.override || {}

		return new Promise(function(resolve) {

			pubsub.$emit('open.modal', {
				id: 'listEditionMove',
				game: e.override.game,
				item: e.override.item
			})

			resolve()

		})
		
	},

	remove: function(context, e) {

		e = e || {}
		
		var index = (e.override === undefined) ? context.getters['all/games'] : [e.override.game]

		return new Promise(function(resolve) {

			// eslint-disable-next-line
			new Promise(function(internalResolve) {
		
				if (context.getters['external'] && e.override === undefined) {

					store.dispatch('api/select/index', {
						key: context.getters['key'],
						exclude: context.getters['excluded']
					}).then(function(json) {

						internalResolve(json.index)

					})

				} else {

					internalResolve(index)

				}

			}).then(function(index) {

				store.dispatch('api/list/remove', {
					games: index
				}).then(function() {

					pubsub.$emit('list.refresh')

					resolve()

				})

			})

		})

	}

}