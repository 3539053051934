<template>

<app-page>

	<router-view></router-view>

	<div v-if="is.sharing" class="share" v-on:click="this.onCloseShare">

		<div class="share-modal" v-propagation>

			<h2>Share this...</h2>

			<div class="share-modal-close" v-on:click="this.onCloseShare"><i class="fa fa-times"></i></div>

			<div class="share-buttons">

				<core-button :href="share.twitter" target="_blank" icon="twitter" :iconOnly="true" :fab="true" />
				<core-button :href="share.facebook" target="_blank" icon="facebook" :iconOnly="true" :fab="true" />
				<core-button :href="share.download" target="_blank" icon="download" :iconOnly="true" :fas="true" />

				<div class="share-buttons-url" v-on:click="onCopyClick" v-propagation>
					<span ref="url">{{ share.url }}</span>
					<small v-if="is.copied">copied!</small>
					<small v-if="!is.copied">click to copy</small>
				</div>

			</div>

		</div>

	</div>

	<div class="prompt" v-if="is.unregistered" :class="{'is-active': is.prompting, 'is-success': is.success}">

		<div class="inner">

			<div class="prompt-text" v-if="!is.success">
				<b>Is this your BGG username?</b> Why not verify your profile - it takes a few seconds and lets you do more with your data! 
				<small v-if="!window.is.mobile">Click the button and you will automatically be sent a GeekMail on BGG with a verification link.</small>
			</div>

			<div class="prompt-text" v-if="is.success">
				<b>Great, you're nearly ready!</b> Just check your GeekMail on BGG and click the link inside to verify your username.
			</div>

			<div class="prompt-button">
				<core-button theme="yellow" v-on:click.native="onVerifyClick" :loading="is.loading" v-if="!is.success">Verify</core-button>
				<core-button theme="plain" v-on:click.native="onCancelClick">{{ cancelText }}</core-button>
			</div>

		</div>

	</div>

</app-page>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				unregistered: false,
				prompting: false,
				loading: false,
				success: false,
				sharing: false,
				copied: false
			},

			share: {
				url: 'url',
				twitter: '',
				facebook: '',
				download: ''
			}

		}

	},

	computed: {

		cancelText: function() {

			return (this.is.success) ? 'Close' : 'No thanks'

		},

		context: function() {

			return this.$store.getters['context']

		},

		session: function() {

			return this.$store.getters['session/loggedin']

		}

	},

	watch: {

		context: {

			deep: true,

			handler: function() {

				this.checkPrompt()

			}

		},

		session: {

			deep: true,

			handler: function() {

				this.checkPrompt()

			}

		}

	},

	mounted: function() {

		this.checkPrompt()

		this.$pubsub.$on('sharing', function(data) {

			this.share = data
			this.is.copied = false
			this.is.sharing = true

		}.bind(this))

	},

	methods: {

		checkPrompt: function() {

			if (this.isUser && this.$store.getters['context/synchronised'] && !this.loggedin && !this.$cookies.isKey('prompt/user/' + this.$store.getters['context/name'])) {

				this.is.unregistered = !this.$store.getters['context/registered']
				this.is.prompting = true

			} else {

				this.is.unregistered = false
				this.is.prompting = false

			}

		},

		onVerifyClick: function() {

			this.is.loading = true

			this.$store.dispatch('api/account/verify', {
				name: this.$store.getters['context/name']
			}).then(function() {

				this.$cookies.set('prompt/user/' + this.$store.getters['context/name'], true)

				this.is.success = true
				this.is.loading = false

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this)) 

		},

		onCancelClick: function() {

			this.$cookies.set('prompt/user/' + this.$store.getters['context/name'], true)
			this.is.prompting = false

		},

		onCopyClick: function() {

			this.$copyText(this.share.url).then(function() {

				this.is.copied = true

			}.bind(this))

		},

		onCloseShare: function() {

			this.is.sharing = false

		}

	}

}

</script>

<style scoped>

.share {
	position: fixed;
	left: 320px;
	top: 64px;
	right: 0px;
	bottom: 0px;
	z-index: 999999;
	background-color: rgba(0, 0, 0, 0.75);
}

.share-modal {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 4px;
	padding: 15px 7px;
}

.is-mobile .share-modal {
	max-width: 300px;
}

.share-modal-close {
	position: absolute;
	right: 0px;
	top: 4px;
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	color: #333;
	font-size: 16px;
	cursor: pointer;
}

.share-modal h2 {
	color: #333;
	font-size: 18px;
	font-weight: 300;
	padding: 0px 7px 16px 7px;
}

.share-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

@media only screen and (max-width: 767px) {

	.share-buttons {
		flex-direction: column;
		justify-content: flex-start;
	}

}

.share-buttons > div,
.share-buttons > a {
	margin: 0px 7px;
}

@media only screen and (max-width: 767px) {

	.share-buttons > div,
	.share-buttons > a {
		margin: 7px 0px 0px 0px;
		width: 100%;
	}

}

.share-buttons-url {
	background-color: #4a7888;
	color: #fff;
	border-radius: 4px;
	height: 40px;
	padding: 0px 7px;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
}

.is-desktop .share-buttons-url:hover {
	background-color: #03546f;
}

@media only screen and (max-width: 767px) {

	.share-buttons-url {
		text-align: center;
	}

}

.share-buttons-url span {
	font-size: 14px;
	line-height: 18px;
	display: block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.share-buttons-url small {
	font-size: 11px;
	display: block;
	opacity: 0.75;
}

.prompt {
	position: fixed;
	bottom: -100%;
	left: 320px;
	right: 0px;
	background-color: #4881bb;
	transition: all 300ms ease-in-out;
	z-index: 1000;
}

.is-mobile .prompt {
	left: 0px;
}

.prompt.is-success {
	background-color: #5fad69;
}

.prompt.is-active {
	bottom: 0%;
}

.prompt .inner {
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 100%;
	padding: 10px 20px;
}

.is-mobile .prompt .inner {
	flex-direction: column;
}

.prompt-text {
	flex-grow: 1;
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	padding-right: 10px;
}

.is-mobile .prompt-text {
	padding: 0px 0px 10px 0px;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
}

.prompt-text small {
	display: block;
	font-size: 14px;
	line-height: 14px;
	font-weight: 300;
	margin-top: 3px;
}

.is-mobile .prompt-text small {
	font-size: 11px;
	line-height: 11px;
	margin-top: 2px;
}

.prompt-text b {
	font-weight: 500;
}

.prompt-button {
	flex-shrink: 0;
}

.prompt-button >>> .button {
	width: 120px;
}

</style>