export default {

	namespaced: true,

	state: {

		type: 'collection',
		date: {from: 0, to: 0},

		layout: {	
			title: 'My word cloud',
			rotate: 0,
			angle: 1,
			show: {
				title: 0,
				user: 0,
				app: 0
			}
		}

	},

	getters: {

		active: function(state) {

			return {
				'type': state.type !== 'collection',
				'date.from': state.date.from !== 0,
				'date.to': state.date.to !== 0
			}

		},

		values: function(state) {

			return state

		},

		filter: function(state) {

			return {
				type: state.type,
				date: state.date
			}

		},

		type: function(state) {

			return state.type

		},

		'date/from': function(state) {

			return state.date.from

		},

		'date/to': function(state) {

			return state.date.to

		},

		layout: function(state) {

			return state.layout

		},

		'layout/title': function(state) {

			return state.layout.title

		},

		'layout/rotate': function(state) {

			return state.layout.rotate

		},

		'layout/angle': function(state) {

			return state.layout.angle

		},

		'layout/show/user': function(state) {

			return state.layout.show.user

		},

		'layout/show/app': function(state) {

			return state.layout.show.app

		},

		'layout/show/title': function(state) {

			return state.layout.show.title

		}

	},

	mutations: {

		reset: function(state) {

			state.type = 'collection'
			state.date = {from: 0, to: 0}

		},

		'type': function(state, value) {

			state.type = value

		},

		'date/from': function(state, value) {

			state.date.from = value

		},

		'date/to': function(state, value) {

			state.date.to = value

		},

		'layout/title': function(state, value) {

			state.layout.title = value

		},

		'layout/rotate': function(state, value) {

			state.layout.rotate = value

		},

		'layout/angle': function(state, value) {

			state.layout.angle = value

		},

		'layout/show/user': function(state, value) {

			state.layout.show.user = value

		},

		'layout/show/app': function(state, value) {

			state.layout.show.app = value

		},

		'layout/show/title': function(state, value) {

			state.layout.show.title = value

		}

	}

}