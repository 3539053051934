<template>

<div class="faq">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.faq {
	border-top: 1px solid rgba(0, 0, 0, 0.2);
}

</style>