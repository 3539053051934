export default {

	data: function() {

		return {

			is: {
				expanded: false,
				saving: false,
				changed: false
			}

		}

	},

	computed: {

		filter: function() {

			return this.$store.getters['filter/config']

		},

		current: function() {

			return this.$store.getters['session/config']

		}

	},

	watch: {

		filter: {

			handler: function() {

				this.is.changed = this.$store.getters['filter/config/changed']

			},

			deep: true

		},

		current: {

			handler: function(n) {

				this.$store.commit('filter/config/set', n)

			},

			deep: true

		}

	},

	created: function() {

		this.$pubsub.$on(this.name + '.expand', this.onExpand.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off(this.name + '.expand', this.onExpand.bind(this))

	},

	methods: {

		onExpand: function() {

			this.$store.commit('filter/config/set', this.current)

			this.is.expanded = true

		},

		onSaveClick: function() {

			this.is.saving = true

			this.$store.dispatch('api/config/save', {
				data: this.$store.getters['filter/config']
			}).then(function(json) {

				this.$store.commit('session/config/set', json.config)
				this.$store.commit('filter/config/reset', json.config)

				this.is.saving = false
				this.is.changed = false

				this.onAfterSave(json.config)

				this.onCloseClick()

			}.bind(this), function() {

				this.is.saving = false

			}.bind(this)) 
			
		},

		onCloseClick: function() {

			this.is.expanded = false

		},

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.onCloseClick()

		}

	}

}