import Vue from 'vue'
import Vuex from 'vuex'

import BaseStore from 'core/store'
import deepmerge from 'deepmerge'

import api from './store/api'
import filter from './store/filter'
import service from './store/service'
import page from './store/page'
import permissions from './store/permissions'
import session from './store/session'

Vue.use(Vuex)

export default new Vuex.Store(deepmerge(BaseStore, {

	modules: {

		filter: filter,
		api: api,
		page: page,
		service: service,
		permissions: permissions,
		session: session
		
	},

	actions: {

		reverify: function(context, params) {

			return new Promise(function(resolve, reject) {

				params = params || {}

				params.type = context.getters['context/type']
				params.name = context.getters['context/slug']

				context.dispatch('api/' + params.type + '/verify', { name: params.name }, { root: true }).then(function(data) {

					context.commit('context/set', {
						type: params.type,
						data: data
					}, { root: true })

					resolve(data)

				}, function(error) {

					reject(error)

				})

			})
			
		},

		verify: function(context, params) {

			return new Promise(function(resolve, reject) {

				context.dispatch('api/' + params.type + '/verify', { name: params.name }, { root: true }).then(function(data) {

					context.commit('context/set', {
						type: params.type,
						data: data
					}, { root: true })

					resolve(data)

				}, function(error) {

					reject(error)

				})

			})
			
		},

		sync: function(context, params) {

			return new Promise(function(resolve, reject) {

				context.dispatch('api/' + params.type + '/sync', { name: params.name }, { root: true }).then(function(data) {

					context.commit('context/set', {
						type: params.type,
						data: data
					}, { root: true })

					resolve(data)

				}, function(error) {

					reject(error)

				})

			})
			
		}

	}

}))
