<template>

<core-dropdown-item type="title">{{ text }}</core-dropdown-item>

</template>

<script>

export default {

	props: ['text']

}

</script>
