<template>

<span>{{ text }}<span v-if="parseInt(n) !== 1">{{ suffix }}</span></span>

</template>

<script>

export default {

	props: ['n', 't'],

	computed: {

		text: function() {

			return (this.isSpecial && parseInt(this.n) !== 1) ? this.t.substr(0, this.t.length - 1) : this.t

		},

		suffix: function() {

			return (this.isSpecial) ? 'ies' : 's'

		},

		isSpecial: function() {

			return this.t.substr(-2) === 'ty' || this.t.substr(-2) === 'ry'

		}

	}

}

</script>

<style scoped>

</style>
