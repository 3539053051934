<template>

<div class="section">

	<div v-if="title" class="section-title"><span>{{ title }}</span></div>

</div>

</template>

<script>

export default {

	props: [
		'title'
	]

}

</script>

<style scoped>

.section {
	overflow: hidden;
}

.section-title {
	color: #fff;
	font-size: 16px;
	height: 8px;
	font-weight: 500;
	margin-bottom: 22px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.section-title span {
	background-color: #2b5870;
	min-width: 80px;
	padding-right: 15px;
	display: inline-block;
}

</style>
