<template>

<div class="modal-wrapper" :class="{'is-profile': isProfile, 'is-standalone': $route.meta.isStandalone}">

	<div class="modal-loading" v-if="loading && loadingText" v-html="loadingText" />
	<div class="modal-loading is-icon" v-if="loading && !loadingText"><i class="fa fa-spin fa-spinner"></i></div>
	
	<div class="modal-close" v-tooltip="'Close'" :class="{'is-overlaid': noheader}" v-on:click.stop="onClose"><i class="fa" :class="{[iconClass]: true}"></i></div>

	<div class="modal" v-show="!loading" v-bind:class="{[typeClass]: true}" v-on:click.stop="onStop" v-on:touchstart.stop="onStop">

		<core-modal-head :game="game" v-if="!noheader">
			<slot name="head"></slot>
		</core-modal-head>

		<core-modal-body :nopadding="nopadding" :type="type" :nofoot="hideFoot">
			<slot></slot>
		</core-modal-body>

		<core-modal-foot align="center" v-if="!hideFoot">
			<slot name="foot"></slot>
		</core-modal-foot>
		
	</div>

</div>

</template>

<script>

export default {

	props: ['type', 'hideFoot', 'nopadding', 'loading', 'icon', 'noheader', 'loadingText', 'game'],

	computed: {

		iconClass: function() {

			return ((this.icon || this.window.is.menu) && !this.window.is.mobile) ? 'fa-caret-left' : 'fa-times'

		},

		typeClass: function() {

			if (this.type === 'confirm') return 'type-confirm'

			return 'type-default'

		},

		canClose: function() {

			return this.type !== 'confirm'

		}

	},

	methods: {

		onClose: function() {

			this.$emit('close')

		},

		onStop: function() {}

	}

}

</script>

<style scoped>

.modal-wrapper {
	position: fixed;
	top: 0px;
	width: 320px;
	height: calc(100vh - var(--vh-offset, 0px));
	right: 0px;
	bottom: 0px;
	background-color: #2b5870;
	z-index: 5000;
}

.modal-wrapper.is-profile {
	top: 64px;
}

.is-mobile .modal-wrapper {
	width: 100%;
	right: 0px;
	top: 48px;
}

.is-mobile .modal-wrapper.is-standalone {
	top: 0px;
}

.modal-loading {
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	font-weight: 300;
	position: absolute;
	top: 50%;
	padding: 20px 15px 15px 15px;
	transform: translateY(-50%);
}

.modal-loading.is-icon {
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 32px;
	color: #fff;
}

.modal {
	display: flex;
	flex-direction: column;
	align-content: center;
	background-color: #2b5870;
	width: 100%;
	height: calc(100vh - var(--vh-offset, 0px) - 64px);
	flex-basis: 0;
}

.modal-wrapper.is-profile .modal {
	height: calc(100vh - var(--vh-offset, 0px) - 64px);
}

.is-mobile .modal {
	height: calc(100vh - var(--vh-offset, 0px) - 48px);
}

.is-mobile .modal-wrapper.is-standalone .modal {
	height: calc(100vh - var(--vh-offset, 0px));
}

.modal.type-confirm .modal-dialog {
	text-align: center;
}

.modal-close {
	position: absolute;
	right: 20px;
	top: 24px;
	height: 40px;
	width: 40px;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.25);
	color: #fff;
	line-height: 40px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	z-index: 3;
}

.is-smaller .modal-close {
	top: 12px;
}

.is-mobile .modal-close {
	right: 10px;
	height: 32px;
	width: 32px;
	top: 8px;
	line-height: 32px;
	font-size: 16px;
}

.modal-close.is-overlaid {
    background-color: #19455c;
}

.is-desktop .modal-close:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.is-desktop .modal-close.is-overlaid:hover {
    background-color: #0E232E;
}

</style>