<template>

<core-panel>

	<template v-slot:head>

		<h1>Matches</h1>

		<p>The rating comparisons between <b>{{ name(data.user_a) }}</b> and <b>{{ name(data.user_b) }}</b>.</p>

		<div class="match-users">

			<core-avatar type="user" :id="data.user_a" class="match-user is-a" />
			<core-avatar type="user" :id="data.user_b" class="match-user is-b" />

		</div>

	</template>

	<template v-slot:body>

		<app-game class="match" v-for="(match, index) in matches" :key="index" :item="{id: match.game}" :dark="true" :subtext="'Difference of ' + match.diff">

			<core-rating :value="match.user_a" class="match-rating" />

			<core-rating :value="match.user_b" class="match-rating" />

		</app-game>

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'match',
			params: [
				'user_a',
				'user_b'
			],
			matches: []
		}

	},

	methods: {

		name: function(id) {

			return this.$store.getters['data/users/associated'][id].fullname || this.$store.getters['data/users/associated'][id].name

		},

		response: function(json) {

			this.matches = json.matches

		}

	}

}

</script>

<style scoped>

.match >>> .match-rating {
	flex-shrink: 0;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 18px;
	font-weight: 400;
	margin-left: 2px;
}

.match-users {
	margin-top: -10px;
	display: flex;
	height: 30px;
	justify-content: flex-end;
}

.match-user {
	width: 48px;
	height: 48px;
	bottom: 0px;
	z-index: 1;
	margin-left: 2px;
	clip-path: polygon(100% 0, 100% 44px, 60% 44px, 50% 100%, 40% 44px, 0 44px, 0 0);
}

</style>