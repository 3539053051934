<template>
	
<core-list-expand-section  v-if="selected" class="expansions">

	<app-game-item v-for="subitem in contained" filter="collection" v-bind:key="subitem.id" :summarised="true" :item="subitem" :fields="columns" :noactions="true" />

</core-list-expand-section>

</template>

<script>

export default {

	name: 'contains',

	props: ['item', 'selected'],

	computed: {

		columns: function() {

			var columns = []

			columns.push({
				type: 'avatar'
			})

			columns.push({
				type: 'name',
				sort: 'name',
				filter: 'collection',
				labelExpansions: !this.isGroupOrBuddies,
				lastPlayed: !this.isGroupOrBuddies && !this.window.is.desktop,
				showOwners: this.isGroupOrBuddies
			})

			columns.push({
				type: 'count',
				sort: 'count',
				filter: 'collection'
			})

			columns.push({
				type: 'duration',
				sort: 'duration',
				filter: 'collection'
			})

			columns.push({
				type: 'weight',
				sort: 'weight',
				filter: 'collection'
			})

			if (this.isUser || (this.isGroup && this.isMember) || this.isBuddies) {

				columns.push({
					type: 'rating',
					context: 'user',
					hideMobile: this.window.width < 375 && !this.isUser,
					sort: 'rating.user',
					filter: 'collection'
				})

			}

			if (this.isGroup) {

				columns.push({
					type: 'rating',
					context: 'group',
					sort: 'rating.group',
					filter: 'collection'
				})

			}

			if (this.isBuddies) {

				columns.push({
					type: 'rating',
					context: 'buddies',
					sort: 'rating.group',
					filter: 'collection'
				})

			}

			columns.push({
				type: 'rating',
				context: 'bgg',
				sort: 'rating.bgg',
				filter: 'collection'
			})

			return columns

		},

		contained: function() {

			var contained = []

			this.$_.each(this.item.game.contains, function(id) {

				contained.push(this.$store.getters['page/collection/contained/associated/collection'][id])

			}.bind(this))

			return contained

		}

	}

}

</script>

<style scoped>

.expansions {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 0px!important;
}

.is-mobile .expansions {
	padding: 0px!important;
}

.expansions-section-title {
	padding: 10px 10px;
	font-size: 14px;
	font-weight: 500;
	color: #333;
	display: flex;
	align-items: center;
}

.expansions-section-title span {
	display: inline-block;
	background-color: #666;
	color: #fff;
	font-size: 14px;
	padding: 2px 4px;
	margin-left: 5px;
	border-radius: 4px;
}

.expansions-section-games {
	border: 1px solid #ddd;
	border-radius: 10px;
}

.is-mobile .expansions-section-games {
	border: 0px;
	border-radius: 0px;
}

.expansions-section-games.is-empty {
	padding: 20px;
	text-align: center;
	color: #ccc;
	font-size: 24px;
}

</style>