<template>

<core-modal v-on:close="onClose" :loading="is.loading" :nopadding="true" :loadingText="loadingText" icon="caret-left">

	<template v-slot:head>

		Deep Sync

	</template>

	<template v-slot:default>

		<core-modal-faq>

			<core-modal-faq-item question="What is a deep sync?">

				<p>A deep sync collects useful data that the BGG API doesn't make available, giving you even more data to filter and sort, as well as allowing for more accurate insights.</p>

			</core-modal-faq-item>

			<core-modal-faq-item question="Which data does it fetch?">

				<p>Currently it only fetches the acquisition date and inventory location of games in your collection. In the future more types of data will be supported.</p>

				<ul>
					<li>Date game acquired<small>Overrides the collection added date</small></li>
					<li>Inventory location<small>Allows you to filter and search by inventory location</small></li>
				</ul>

			</core-modal-faq-item>

			<core-modal-faq-item question="Why does it need my BGG password?">

				<p>The app needs to create a login cookie to access BGG as you to see the hidden data. Your password is not saved and once the deep sync is finished the login cookie is destroyed.</p>

			</core-modal-faq-item>

			<core-modal-faq-item question="How often can I run a deep sync?">

				<p>Registered users can manually run a deep sync once per calendar month, while tipjar users can run a deep sync anytime.</p>

			</core-modal-faq-item>
	
		</core-modal-faq>

		<core-modal-content>

			<p>Please enter your BGG password so geekGroup can automatically login and fetch your hidden data. Your password is not saved.</p>

			<core-form-input-password name="password" :newpassword="true" v-on:validate="validate" placeholder="Enter current password" v-model="inputs.password" errorMessage="Sorry, your deep sync failed. Please check your password is correct." :error="is.error" :disabled="is.success || is.completing" :validation="validation.password" />

		</core-modal-content>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" :disabled="!is.valid" v-on:click.native="onRunClick">Run</core-button>
		<core-button theme="plain" v-on:click.native="onClose" v-if="!window.is.mobile">Cancel</core-button>
		<core-button theme="plain" v-on:click.native="onClose" v-if="window.is.mobile">Close</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			loadingText: 'Please wait while the sync is in progress.<br/><br/>Depending on how much data is being synchronised, and how busy the server is, this process may take several minutes.<br/><br/>The page will refresh once completed.<br/><br/><b>You do not need to wait, you can close this and check your profile later.</b>',

			is: {
				loading: false,
				error: false
			},

			inputs: {
				password: ''
			},

			validation: {
				password: { 
					exists: true
				}	
			}

		}

	},

	methods: {

		onRunClick: function() {

			this.is.loading = true

			this.$store.dispatch('api/session/sync', {
				type: this.$CONSTANTS.SYNC.DEEP,
				password: this.inputs.password
			}).then(function() {

				this.$router.go()

			}.bind(this), function(e) {

				console.log(e)

				this.is.error = true
				this.is.loading = false

			}.bind(this))

		},

		onClose: function() {

			this.$emit('close')
			this.$pubsub.$emit('open.modal', 'userSync')

		}

	}

}

</script>