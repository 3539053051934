<template>

<div class="action type" v-on:mouseleave="onButtonLeave" v-click-outside="onOutsideClick">

	<core-button v-if="!window.is.desktop" :class="{'is-active': is.expanded && active}" theme="filter" v-on:click.native="onButtonClick" :icon="icon" :iconOnly="window.is.mobile" :badge="this.actions" :hideBadge="!window.is.mobile || (is.expanded && active)" :caretUp="true" :iconLeft="true" :caret="!is.expanded && !window.is.mobile" :subtext="subtext">{{ text }}</core-button>

	<div class="type-title" v-if="window.is.desktop">

		<div class="type-title-icon" v-if="icon"><i class="fa-solid fa-fw" :class="'fa-' + icon"></i></div>
		<div class="type-title-text">{{ text }}</div>

	</div>

	<core-dropdown class="action-dropdown" v-if="(is.expanded || window.is.desktop) && active">

		<slot></slot>

	</core-dropdown>

</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	props: ['text', 'icon', 'active'],

	directives: {
		clickOutside: vClickOutside.directive
	},

	data: function() {

		return {

			is: {
				expanded: false
			}

		}

	},

	computed: {

		actions: function() {

			var count = 0

			this.$_.each(this.$slots.default, function($slot) {

				if ($slot.componentOptions) {

					if ($slot.componentOptions.tag === 'core-content-select-action') {

						count++

					} else if ($slot.componentOptions.tag === 'core-dropdown-item' && $slot.componentOptions.propsData.type === 'tagToggle') {

						count++

					}

				}

			})

			return count

		},

		subtext: function() {

			return this.actions + ' action' + ((this.actions !== 1) ? 's' : '')

		}

	},

	methods: {

		onButtonClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onButtonLeave: function() {

			this.is.expanded = false

		},

		onOutsideClick: function() {

			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

.action >>> .button {
	font-weight: 500;
	border-left: 1px solid rgba(0, 0, 0, 0.2)!important;
	width: 120px;
}

.is-mobile .action >>> .button {
	width: 40px!important;
	height: 40px;
}

.is-small .action >>> .button.is-active {
	background-color: #3395B5;
}

.action >>> .button:hover {
	opacity: 1!important;
}

.action >>> .button-icon {
	margin-right: 8px;
	margin-top: -4px;
	margin-left: 0px;
}

.is-mobile .action >>> .button-icon {
	margin-right: 0px;
	margin-top: 0px;
}

.action >>> .button-content {
	display: flex;
	flex-direction: column!important;
	justify-content: center!important;
	line-height: 13px!important;
	font-size: 13px;
	align-items: flex-start!important;
}

.action >>> .button-content small {
	line-height: 10px!important;
	margin-top: 2px!important;
}

.action >>> .button-caret {
	position: absolute;
	right: 8px;
	top: 6px;
	color: rgba(255, 255, 255, 0.4);
}

.is-desktop .action >>> .button:hover .button-caret {
	color: #fff;
}

.is-small .action-dropdown {
	position: absolute;
	bottom: 40px;
	right: 0px;
	background-color: #3395B5;
	border-radius: 4px 4px 0px 0px;
	max-height: calc(100vh - var(--vh-offset, 0px) - 188px);
	overflow-y: auto;
	padding: 4px 0px;
	min-width: 120px;
	z-index: 3;
}

.is-small .action-dropdown {
	width: 100%;
	position: fixed;
	left: 0px;
	right: 0px;
	border-radius: 0px;
}

.is-small .action-dropdown:after {
	content: ' ';
    position: absolute;
    bottom: -6px;
    right: 6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #3395B5 transparent transparent transparent;
}

.is-desktop .type-title {
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.is-desktop .type-title-icon {
    font-size: 16px;
    width: 24px;
    color: #fff;
    text-align: center;
    margin-right: 10px;
}

.is-desktop .type-title-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.is-desktop .type >>> .dropdown-item {
    color: #fff;
    font-weight: 400;
    font-size: 14px!important;
    padding: 7px 20px 7px 54px!important;
    font-family: 'Roboto', sans-serif;
    opacity: 0.75;
}

.is-desktop .type >>> .dropdown-item.type-title:before,
.is-desktop .type >>> .dropdown-item.type-link:before {
	display: block;
	content: '';
	position: absolute;
	left: 30px;
	top: calc(50% - 1px);
    height: 1px;
    width: 12px;
    background-color: #708895;
    margin: 0px 5px 0px 3px;
}

.is-desktop .type >>> .dropdown-item:after {
    content: '';
    display: block;
    position: absolute;
    left: 32px;
    bottom: 50%;
    height: 32px;
    width: 1px;
    background-color: #708895;
}

.is-desktop .type >>> .dropdown-item:first-child:after {
    height: 14px;
}

.is-desktop .type >>> .dropdown-item:hover {
	opacity: 1;
    background-color: rgba(255, 255, 255, 0.05)!important;
}

</style>
