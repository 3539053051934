<template>

<div class="head">

	<com-logo />

    <div class="head-divide" />

    <com-search />
    <com-inbox v-if="loggedin" />
    <com-groups v-if="loggedin" />
    <com-account />

</div>

</template>

<script>

import comLogo from './head/Logo'
import comAccount from './head/Account'
import comInbox from './head/Inbox'
import comSearch from './head/Search'
import comGroups from './head/Groups'

export default {

    components: {
        'com-logo': comLogo,
        'com-inbox': comInbox,
        'com-groups': comGroups,
        'com-account': comAccount,
        'com-search': comSearch
    }
	
}

</script>

<style scoped>

.head {
    background-color: #082534;
    position: fixed;
    top: 0px;
    height: 64px;
    left: 0px;
    padding: 0px 0px 0px 0px;
    width: 100%;
    z-index: 10000;
    display: flex;
}

.head-divide {
    flex-grow: 1;
}

.is-mobile .head {
    height: 48px;
}

</style>
