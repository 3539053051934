<template>

<core-content-wrapper :empty="is.ready && !totalMaxResults" :noResults="is.ready && totalMaxResults && !totalResults" :loading="!is.ready">

	<core-content-empty v-show="is.ready && !totalMaxResults" title="Oh dear, no lists!" text="It seems there are no lists." />
	<core-content-empty v-show="is.ready && totalMaxResults && !totalResults" title="Oh dear, no matches!" text="Your filtering skills are too strong, try broadening the settings." />

	<com-filter :loading="is.fetching" :count="totalPseudoResults" :maximum="totalMaxResults" v-show="totalMaxResults" />

	<core-content-list v-show="is.ready && totalMaxResults && totalResults" :cards="true" :transparent="true">

		<com-list v-for="item in content" v-bind:key="item.list.id" :item="item" />

		<core-content-scrollload v-on:scrolled="onScroll" v-show="currentPage < totalPages || (is.fetching && is.scrollLoading)" />

	</core-content-list>

</core-content-wrapper>

</template>

<script>

import Content from 'core/mixin/List.js'
import comFilter from './lists/Filter.vue'
import comList from './lists/List.vue'

export default {

	mixins: [Content],

	components: {

		'com-filter': comFilter,
		'com-list': comList

	},

	data: function() {

		return {

			contentName: 'lists',
			routeName: 'Lists'

		}

	},

	computed: {

		userText: function() {

			return (this.$store.getters['permissions/is/userself'] && this.isUser) ? 'you have' : 'the user has'

		},

		pendingSync: function() {

			return this.$store.getters['context/queueSync']

		}

	}

}

</script>

<style scoped>

</style>