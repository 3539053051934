<template>

<core-config-section>

	<h2>Currency <small>Applies to entire app</small></h2>

	<p class="about">You can change the default currency used throughout the app to something other than USD. If your currency is not listed please get in touch and it will be added.</p>

	<div class="inputs">

		<core-filter-input-toggles :stacked="true" filter="config/other/currency" text="Default" :values="toggle.values" :icons="toggle.icons" :labels="toggle.labels" />

	</div>

</core-config-section>

</template>

<script>

export default {

	data: function() {
		
		return {
			toggle: {
				icons: [],
				values: [],
				labels: []
			}
		}

	},

	created: function() {

		var currencies = this.$_.sortBy(this.$CONSTANTS.CURRENCY, function(currency) {

			return currency.name

		})

		this.$_.each(currencies, function(currency) {

			this.toggle.values.push(currency.code)
			this.toggle.labels.push(currency.name + ' (' + currency.code + ' - ' + currency.sign + ')')

		}.bind(this))

	}

}

</script>