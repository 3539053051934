<template>

<div class="bar" v-bind:class="{'is-inline': inline}" v-bind:style="{'marginLeft': marginLeft}">

	<div class="inner">

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['inline'],

	computed: {
	
		marginLeft: function() {
		
			if (this.window.is.smalldesktop || this.window.is.tablet) {

				var change = parseInt(this.window.menuIndentContent)
			
				return (this.window.menuIndentContent) ? ((change < 0) ? change + 142 : change).toString() + 'px' : '142px'
			
			} else {

				return this.window.menuIndentContent	
			
			}
		
		}
	
	}

}

</script>

<style scoped>

.bar {
	width: 100%;
	position: fixed;
	left: 320px;
	top: 64px;
	height: 88px;
	width: calc(100% - 320px);
	z-index: 2000;
	background-color: #4881bb;
	transition: margin-left 100ms linear;
}

.is-smaller .bar {
	left: 0px;
	width: calc(100% - 142px);
	height: 64px;
}

.is-mobile .bar {
	left: 0px;
	top: 96px;
	height: 48px;
	width: 100%;
	transition: none;
}

.bar.is-inline {
	position: relative;
	top: 0px;
}

.bar .inner {
	display: flex;
	justify-content: space-between;
	padding: 0px;
}

</style>
