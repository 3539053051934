<template>

<core-filter-advanced :active="active" v-on:clickoutside="$emit('close')" v-propagation>

	<template v-slot:inputs>

		<core-filter-advanced-group title="Filter">

			<core-filter-input-toggles filter="tools/wordcloud/type" text="Type" :values="type.values" :icons="type.icons" :labels="type.labels" />

			<core-filter-input-daterange v-if="filter.type === 'plays'" filter="tools/wordcloud/date" />

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Layout">

			<core-filter-input-toggle text="Rotate randomly" on="Yes" off="No" filter="tools/wordcloud/layout/rotate" />
			<core-filter-input-toggle text="Right angles only" on="Yes" off="No" filter="tools/wordcloud/layout/angle" />

		</core-filter-advanced-group>

		<core-filter-advanced-group title="Extras">

			<core-filter-input-toggle text="Show title" on="Yes" off="No" filter="tools/wordcloud/layout/show/title" />

			<core-filter-input-text filter="tools/wordcloud/layout/title"  class="title" v-if="filter.layout.show.title" />

			<core-filter-input-toggle text="Show user" on="Yes" off="No" filter="tools/wordcloud/layout/show/user" />

			<core-filter-input-toggle text="Show geekGroup credit" on="Yes" off="No" filter="tools/wordcloud/layout/show/app" />

		</core-filter-advanced-group>

	</template>

	<template v-slot:buttons>

		<core-button v-if="window.is.mobile" v-on:click.native="$emit('close')" theme="blue" fluid="yes">Close filter</core-button>

	</template>

</core-filter-advanced>

</template>

<script>

export default {

	props: ['active'],

	data: function() {

		return {
			type: {
				values: ['collection', 'ratings', 'plays'],
				icons: ['', '', '', ''],
				labels: ['Collection', 'Ratings', 'Plays']
			}
		}

	},

	computed: {

		filter: function() {

			return this.$store.getters['filter/tools/wordcloud']

		}

	},

	methods: {

		onResetClick: function() {

			this.$store.commit('filter/tools/wordcloud/reset')

		}

	}

}

</script>

<style scoped>

.section {
	padding: 15px 15px 7px 15px!important;
}

.title,
.sort {
	padding-top: 0px!important;
}

</style>
