import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		save: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('config/save', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		}

	}

}