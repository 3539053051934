<template>

<core-config-section :locked="!loggedin">

	<h2>Your player name</h2>

	<p>If you don't always link your BGG account with your player data then you will need to let geekGroup know which common player names are actually you to properly calculate win/loss metrics. Start typing a name to add it to the list.</p>

	<div class="inputs">

		<core-filter-input-suggest placeholder="Start typing a username..." filter="config/plays/usernames" api="misc/suggest/play/usernames" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>