<template>

<com-item name="size.activity" :size="size" :loading="loading" title="Activity" :is-empty="!active">

	<template v-slot:about>

		<p v-if="settings.period.value === 0">{{ your | capitalise }} changes in collection size by year.</p>
		<p v-if="settings.period.value === 1">{{ your | capitalise }} changes in collection size over the past 12 months.</p>
		<p v-if="settings.period.value > 1">{{ your | capitalise }} changes in collection size during {{ settings.period.value }}.</p>

		<p class="small" v-if="!isGroup">Tracking began from day {{ you }} were first imported into geekGroup.</p>
		<p class="small" v-if="isGroup">Tracking began from day group was created.</p>

		<p class="small">Click a section to view full list of games.</p>

	</template>

	<template v-slot:buttons>

		<com-toggle :options="settings.period.options" :value="settings.period.value" v-on:change="onPeriodChange" />

	</template>

	<template v-slot:stats>

		<app-visualisation-bars :data="graph" v-on:period="onPeriodClick" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {

		return {
			graph: {
				datasets: [
					{ type: 'bar', data: [], yAxis: 'right', backgroundColor: '#3bce5a', tooltip: '{{value}} <small>Game{{value.plural}} added</small>' },
					{ type: 'bar', data: [], yAxis: 'right', backgroundColor: '#e27570', tooltip: '{{value}} <small>Game{{value.plural}} removed</small>' },
					{ type: 'point', data: [], yAxis: 'left', backgroundColor: '#217EA8', tooltip: '{{value}} {{change}} <small>Collection size</small>', lineColor: '#217EA8', shadeColor: '#d1f1ff' }
				],
				axis: {
					x: {
						labels: [],
						type: 'month',
						action: 'period',
						tooltip: 'Click to view games'
					},
					y: {
						left: {
							type: 'scale',
							min: 100,
							points: 5
						},
						right: {
							type: 'scale',
							min: 10,
							points: 5
						}
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'activity.expansions' || this.fetching === 'activity.period'

		},

		data: function() {

			return this.$store.getters['page/insights/size/activity'].data

		},

		years: function() {

			return this.$store.getters['page/insights/size/activity'].years

		},

		active: function() {

			return this.$_.keys(this.data).length && !this.loading

		},

		settings: function() {

			var settings = {}

			settings.expansions = {
				options: [
					{value: 1, text: 'With expansions'},
					{value: 0, text: 'Without expansions'}
				],
				value: this.$store.getters['filter/insights/size/activity/expansions']
			}

			var periodOptions = [
				{value: 0, text: 'All time'},
				{value: 1, text: 'Last 12 months'}
			]

			this.$_.each(this.years, function(year) {

				periodOptions.push({
					value: year,
					text: year
				})

			})

			settings.period = {
				options: periodOptions,
				value: this.$store.getters['filter/insights/size/activity/period']
			}

			return settings

		}

	},

	watch: {

		data: function() {

			this.visualise()

		}

	},

	created: function() {

		this.visualise()

	},

	methods: {

		onPeriodClick: function(index) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsSizeActivity',
				index: this.data[index].period
			})
			
		},

		onPeriodChange: function(value) {

			this.$store.commit('filter/insights/size/activity/period', value)

		},

		onExpansionsChange: function(value) {

			this.$store.commit('filter/insights/size/activity/expansions', value)

		},

		visualise: function() {

			if (this.$_.keys(this.data).length) {

				this.graph.axis.x.labels = []
				this.graph.axis.x.type = (this.settings.period.value) ? 'month' : 'year'
				
				this.graph.datasets[0].data = []
				this.graph.datasets[1].data = []
				this.graph.datasets[2].data = []

				this.$_.each(this.data, function(activity) {

					this.graph.axis.x.labels.push(activity.period)
					this.graph.datasets[0].data.push(activity.added)
					this.graph.datasets[1].data.push(activity.removed)
					this.graph.datasets[2].data.push(activity.size)

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

</style>