<template>

<core-config-section :locked="!loggedin">

	<h2>Start day of week</h2>

	<p class="about">Do your calendar weeks start on a Sunday or a Monday? Personalise the generated heatmaps.</p>

	<div class="inputs">

		<core-filter-input-toggles filter="config/insights/heatmap/start" text="Week starts on" :values="toggle.values" :icons="toggle.icons" :labels="toggle.labels" />

	</div>

</core-config-section>

</template>

<script>

export default {

	data: function() {

		return {

			toggle: {
				icons: ['', ''],
				values: ['sunday', 'monday'],
				labels: ['Sunday', 'Monday']
			}
			
		}

	}

}

</script>