<template>
	
<core-list-expand-section :empty="!plays.length" :placeholder="'No ' + type + ' have played this game yet.'" v-if="selected" class="plays">
	
	<div v-for="play in plays" v-bind:key="play.user" class="plays-item">
					
		<core-avatar class="plays-item-avatar" :id="play.user" type="user" tooltip="1"></core-avatar>
					
		<div class="plays-item-value">

			{{ play.value }}<span v-if="play.total" class="plays-item-value-total"> ({{ play.total }})</span>

		</div>

	</div>

</core-list-expand-section>

</template>

<script>

export default {

	name: 'plays',

	props: ['item', 'format', 'selected'],

	computed: {

		plays: function() {

			var context = this.item.context

			var sorted = []
			var list = (context) ? this.item.context.users.played : this.item.users.played

			this.$_.each(list, function(value, key) {

				sorted.push({
					user: key,
					value: value,
					total: (context) ? this.item.users.played[key] : false
				})

			}.bind(this))

			return this.$_.sortBy(sorted, 'value').reverse()

		},

		type: function() {

			if (this.$store.getters['context/is/group']) return 'members'

			return false

		}

	}

}

</script>

<style scoped>

.plays {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.plays-item {
	display: block;
	float: left;
	margin-right: 1px;
	margin-bottom: 1px;
	height: 32px;
	width: 80px;
	font-size: 0;
	vertical-align: top;
	display: flex;
}

.plays-item-avatar {
	display: block;
	width: 32px;
	font-size: 12px;
	height: 32px;
	flex-shrink: 0;
}

.plays-item-value {
	display: block;
	flex-grow: 1;
	height: 32px;
	line-height: 32px;
	padding-left: 8px;
	text-align: left;
	color: #333;
	font-weight: 400;
	font-size: 14px;
	z-index: 2;
}

.plays-item-value-total {
	color: #999;
	font-size: 11px;
}

</style>