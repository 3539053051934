<template>

<core-panel>

	<template v-slot:head>

		<h1>Members</h1>

		<p>Select a member to view their {{ compareType }} matches.</p>

	</template>

	<template v-slot:body>

		<app-user v-for="id in users" :item="user(id)" :notLinked="true" v-bind:key="id" v-on:click.native="onSelect(id)" :selectable="true" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'list',
			params: [
				'compareType'
			],
			users: []
		}

	},

	computed: {

		compareType: function() {

			return (this.data.compareType === 'most') ? 'best' : 'worst'

		}

	},

	methods: {

		user: function(id) {

			return this.$store.getters['data/users/associated'][id]

		},

		response: function(json) {

			this.users = json.users

		},

		onSelect: function(id) {

			this.data.onSelect(id)

		}

	}

}

</script>