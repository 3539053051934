<template>

<core-filter-advanced-group title="Always include" :count="count">

	<p>These games will always be shown even if they don't meet the current filter requirements.</p>

	<core-filter-input-suggest placeholder="Start typing a game's name to include..." field="name" :source="$store.getters['service/select/key']" :filter="value('games/include')" api="games/suggest" data="games" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['games.include']
		}
	}

}

</script>

<style scoped>

.filter-text {
	font-size: 12px;
    display: block;
    color: #fff;
    font-weight: 400;
    padding-bottom: 8px;
}

</style>