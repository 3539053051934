<template>

<div class="graph">

	<div class="graph-axis is-left" v-if="data.axis.y.left && !data.plain">

		<div class="graph-axis-item" v-for="(y, index) in yAxisLeft" :key="index" :class="{'is-long': isxLabelLong}"><div class="graph-axis-item-label">{{ y.label }}</div></div>

	</div>

	<div class="graph-axis is-left" v-if="!data.axis.y.left && !data.plain">

		<div class="graph-axis-item" v-for="(y, index) in yAxis" :key="index" :class="{'is-long': isxLabelLong}"><div class="graph-axis-item-label">{{ y.label }}</div></div>

	</div>

	<div class="graph-data">

		<div class="graph-data-shading" v-for="(shading, index) in shadings" :key="'s' + index" :style="shading"></div>

		<div class="graph-data-section" v-tooltip.bottom="datasetTooltip(datasets[0], xIndex)" v-for="(x, xIndex) in xAxis" :key="xIndex" :class="{'is-even': xIndex % 2 !== 0}">

			<div class="graph-data-section-label" :class="{'is-long': isxLabelLong}" v-if="!data.plain">
				<div class="graph-data-section-label-inner"><template v-if="!data.axis.x.hideLabel">{{ x.label }}<small v-if="x.sublabel">{{ x.sublabel}}</small></template></div>
			</div>

			<div class="graph-data-section-data" :class="{'is-stacked': data.axis.x.stacked, 'is-slim': data.axis.x.slim}">

				<template v-for="(dataset, index) in datasets">

					<div v-if="dataset.type === 'bar'" :class="{'is-highlighted': dataset.highlight === xIndex}" :key="index" class="graph-data-section-data-bar" :style="datasetStyle(dataset, xIndex)"></div>
					<div v-if="dataset.type === 'point' && dataset.data[xIndex]" :key="index" class="graph-data-section-data-point" :style="datasetStyle(dataset, xIndex)" v-tooltip="datasetTooltip(dataset, xIndex)"></div>

				</template>

			</div>

		</div>

		<div class="graph-data-line" v-for="(line, index) in lines" :key="'l' + index" :style="line"></div>

	</div>

	<div class="graph-axis is-right" v-if="data.axis.y.right">

		<div class="graph-axis-item" v-for="(y, index) in yAxisRight" :key="index" :class="{'is-long': isxLabelLong}"><div class="graph-axis-item-label">{{ y.label }}</div></div>

	</div>

</div>

</template>

<script>

export default {

	props: ['data'],

	computed: {

		isxLabelLong: function() {

			return this.data.axis.x.long

		},

		currencySign: function() {

			return this.$CONSTANTS.CURRENCY[this.$store.getters['session/config'].other.currency].sign

		},

		yAxis: function() {

			return this.getAxisY()

		},

		yAxisLeft: function() {

			return this.getAxisY('left')

		},

		yAxisRight: function() {

			return this.getAxisY('right')

		},

		xAxis: function() {

			var axis = []
			var sublabel = false
			var tooltip = false

			this.$_.each(this.data.axis.x.labels, function(label) {

				if (this.data.axis.x.type === 'month') {

					var date = this.$moment()
					var parts = (label.indexOf('.') > 0) ? label.split('.') : label.split('/')

					date.set({
						year: parseInt(parts[1]) + 2000,
						month: parseInt(parts[0]) - 1,
						date: 1,
						hour: 0,
						minute: 0,
						second: 9
					})

					label = date.format('MMMM')
					sublabel = date.format('YYYY')
					tooltip = false

				} else if (this.$_.isObject(label)) {

					sublabel = label.sublabel
					tooltip = label.tooltip
					label = label.label

				}

				axis.push({
					label: label,
					sublabel: sublabel,
					tooltip: tooltip
				})

			}.bind(this))

			return axis

		},

		datasets: function() {

			return this.data.datasets

		},

		shadings: function() {

			var shadings = []
			var chunkSize = (100 / this.xAxis.length) / 2
			var left, top

			this.$_.each(this.datasets, function(dataset) {

				var polygon = []

				if (dataset.type === 'point' && dataset.data.length) {

					this.$_.each(dataset.data, function(value, index) {

						top = 100 - this.dataAxisPercent(value, dataset.yAxis)
						left = chunkSize + (index * (chunkSize * 2))

						if (index === 0) {

							polygon.push('0% ' + top.toString() + '%')

						}

						polygon.push(left.toString() + '% ' + top.toString() + '%')

					}.bind(this))

					polygon.push('100% ' + top.toString() + '%')
					polygon.push('100% 100%')
					polygon.push('0% 100%')

					shadings.push({
						backgroundColor: dataset.shadeColor,
						clipPath: 'polygon(' + polygon.join(', ') + ')'
					})
				
				}

			}.bind(this))

			return shadings

		},

		lines: function() {

			var lines = []
			var chunkSize = (100 / this.xAxis.length) / 2
			var left, top
			var stroke = 2

			this.$_.each(this.datasets, function(dataset) {

				var polygon = []

				if (dataset.type === 'point' && dataset.data.length) {

					this.$_.each(dataset.data, function(value, index) {

						top = 100 - this.dataAxisPercent(value, dataset.yAxis)
						left = chunkSize + (index * (chunkSize * 2))

						if (index === 0) {

							polygon.push('0% ' + this.strokeAdjust(top, stroke))

						}

						polygon.push(left.toString() + '% ' + this.strokeAdjust(top, stroke))

					}.bind(this))

					polygon.push('100% ' + this.strokeAdjust(top, stroke))
					polygon.push('100% ' + this.strokeAdjust(top, stroke, true))

					var reversed = this.$_.clone(dataset.data).reverse()

					this.$_.each(reversed, function(value, index) {

						top = 100 - this.dataAxisPercent(value, dataset.yAxis)
						left = 100 - (chunkSize + (index * (chunkSize * 2)))

						if (index === 0) {

							polygon.push('0% ' + this.strokeAdjust(top, stroke, true))

						}

						polygon.push(left.toString() + '% ' + this.strokeAdjust(top, stroke, true))

					}.bind(this))

					polygon.push('0% ' + this.strokeAdjust(top, stroke, true))

					lines.push({
						backgroundColor: dataset.lineColor,
						clipPath: 'polygon(' + polygon.join(', ') + ')'
					})
				
				}

			}.bind(this))

			return lines

		}

	},

	methods: {

		getAxisYPoints: function(type) {

			var points = (type) ? this.data.axis.y[type].points : this.data.axis.y.points

			return points

		},

		getAxisYDataType: function(type) {

			return (type) ? this.data.axis.y[type].dataType : this.data.axis.y.dataType

		},

		getAxisYSize: function(type) {

			var min = (type) ? this.data.axis.y[type].min : this.data.axis.y.min
			var max = min
			var points = this.getAxisYPoints(type)
			var datasetMax

			this.$_.each(this.data.datasets, function(dataset) {

				if (dataset.yAxis === type) {

					datasetMax = this.$_.max(dataset.data)

					max = (max < datasetMax) ? datasetMax : max

				}

			}.bind(this))

			if (max < 100) max = Math.ceil(max / 10) * 10
			else if (max < 1000) max = Math.ceil(max / 100) * 100
			else if (max < 10000) max = Math.ceil(max / 1000) * 1000
			else if (max < 100000) max = Math.ceil(max / 10000) * 10000

			var size = Math.ceil(max / points) * points

			size = (size < min) ? min : size

			return size

		},

		getAxisY: function(type) {

			var axis = [{
				value: 0,
				label: '0'
			}]

			var scale = this.getAxisYSize(type)
			var dataType = this.getAxisYDataType(type)
			var points = this.getAxisYPoints(type)
			var value
			var label

			for (var i = 1; i <= points; i++) {

				value = (scale / points) * i
				
				if (value >= 1000) {

					value = (value / 1000).toString() + 'k'

				}

				label = (dataType === 'money') ? this.currencySign + value.toString() : value.toString() 

				axis.push({
					value: value,
					label: label
				})

			}

			return axis

		},

		strokeAdjust: function(value, stroke, add) {

			return 'calc(' + value.toString() + '% ' + ((add) ? '+' : '-') + ' ' + (stroke / 2).toString() + 'px)'

		},

		dataAxisPercent: function(value, axis) {

			var axisSize = this.getAxisYSize(axis)
			return Math.round((100 / axisSize) * value, 2)

		},

		datasetTooltip: function(dataset, index) {

			var value = dataset.data[index]
			var subvalue = (dataset.subvalue) ? dataset.subvalue[index] : false

			if (!value) return false

			var valueString = (dataset.dataType === 'percent') ? value.toFixed(1).toString() + '%' : value.toString()
			valueString = (dataset.dataType === 'money') ?  this.currencySign + valueString : valueString

			var tooltip = dataset.tooltip.replace('{{value}}', valueString)

			tooltip = tooltip.replace('{{value.plural}}', (value === 1) ? '' : 's')

			tooltip = tooltip.replace('{{subvalue}}', (subvalue) ? subvalue.toString() : '')

			var lastValue = (index > 0) ? value - dataset.data[index - 1] : 0

			if (lastValue === 0) {

				tooltip = tooltip.replace('{{change}}', '')

			} else {

				var change = Math.abs(lastValue)
				var changeString = (dataset.dataType === 'percent') ? change.toFixed(1).toString() + '%' : change.toString()
				changeString = (dataset.dataType === 'money') ? this.currencySign + parseFloat(changeString).toFixed(2) : changeString

				tooltip = tooltip.replace('{{change}}', '<span class="change">(<i class="fa fa-caret-' + ((lastValue > 0) ? 'up' : 'down') + '"></i>' + changeString + ')</span>')

			}

			var xlabel = this.xAxis[index]

			tooltip = tooltip.replace('{{x.label}}', xlabel.label)
			tooltip = tooltip.replace('{{x.sublabel}}', xlabel.sublabel)
			tooltip = tooltip.replace('{{x.tooltip}}', xlabel.tooltip)

			return tooltip

		},

		datasetStyle: function(dataset, index) {

			var style = {
				backgroundColor: (this.$_.isArray(dataset.backgroundColor)) ? dataset.backgroundColor[index] : dataset.backgroundColor
			}

			style[(dataset.type === 'bar') ? 'height' : 'bottom'] = this.dataAxisPercent(dataset.data[index], dataset.yAxis) + '%'

			return style

		}

	}

}

</script>

<style scoped>

.graph {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: calc(100% - 2px);
	padding: 0px 0px 0px 0px;
}

.graph-data {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	z-index: 2;
}

.graph-data-section {
	flex-grow: 1;
	flex-basis: 0;
	flex-shrink: 0;
	display: flex;
	flex-direction: column-reverse;
}

.graph-data-section-label {
	height: 24px;
	flex-shrink: 0;
	font-size: 12px;
	color: #333;
	padding-top: 2px;
	font-weight: 400;
	border-top: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.graph-data-section-label-inner {
	display: none;
}

.graph-data-section-label.is-long {
	writing-mode: vertical-lr;
	text-orientation: sideways;
	white-space: nowrap;
	height: 64px;
	font-size: 10px;
	align-items: flex-start;
}

.graph-data-section-label small {
	font-size: 10px;
	font-weight: 300;
	opacity: 0.75;
	display: block;
}

.graph-data-section-data {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	padding: 0px;
}

.graph-data-section.is-even .graph-data-section-data {
	/*background-color: rgba(12, 137, 178, 0.05);*/
}

.is-desktop .graph-data-section:hover .graph-data-section-data {
	background-color: rgba(12, 137, 178, 0.1);
}

.graph-data-section-data-bar {
	flex-grow: 1;
	flex-shrink: 0;
	margin: 0px;
	width: calc(100% - 2px);
	margin: 0px 1px;
	border-radius: 4px 4px 0px 0px;
}

.is-desktop .graph-data-section-data-bar:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.graph-data-section-data-bar.is-highlighted {
	background-color: #3bce5a!important;
}

.graph-data-section-data-point {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	left: 50%;
	transform: translate(-50%, 50%);
	position: absolute;
	transition: all 300ms ease-in-out;
}

.graph-data-section-data.is-slim .graph-data-section-data-point {
	width: 4px;
	height: 4px;
}

.graph-data-section-data-point:hover {
	width: 16px;
	height: 16px;
}

.graph-axis {
	display: flex;
	flex-direction: column-reverse;
	flex-shrink: 0;
	width: auto;
	min-width: 32px;
}

.graph-axis-item {
	flex-grow: 1;
	flex-shrink: 0;
}

.graph-axis.is-left .graph-axis-item {
	border-right: 1px solid #ddd;
}

.graph-axis.is-right .graph-axis-item {
	border-left: 1px solid #ddd;
}

.graph-axis-item:first-child {
	height: 24px;
	flex-grow: 0;
	border: 0px!important;
}

.graph-axis-item.is-long:first-child {
	height: 64px;
}

.graph-axis-item-label {
	font-size: 12px;
	font-weight: 300;
	color: #333;
	margin-top: -7px;
}

.graph-axis.is-left .graph-axis-item-label {
	text-align: right;
	padding-right: 4px;
}

.graph-axis.is-right .graph-axis-item-label {
	text-align: left;
	padding-left: 4px;
}

.graph-data-line,
.graph-data-shading {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	bottom: 24px;
}

.graph-data-line {
	z-index: 3;
	pointer-events: none;
}

</style>