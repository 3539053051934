<template>

<div class="facebook">
	<div :style="{ background: color }"></div>
	<div :style="{ background: color }"></div>
	<div :style="{ background: color }"></div>
</div>

</template>

<script>

export default {

	props: ['color']

}

</script>

<style scoped>

.facebook {
	display: block;
	position: absolute;
	left: 50%;
	top: -96px;
	margin-left: -32px;
	width: 64px;
	height: 64px;
}

.facebook div {
	display: inline-block;
	position: absolute;
	left: 6px;
	width: 13px;
	animation: facebook 1.2s ease-in-out infinite;
}

.facebook div:nth-child(1) {
	left: 6px;
	animation-delay: -0.24s;
}

.facebook div:nth-child(2) {
	left: 26px;
	animation-delay: -0.12s;
}

.facebook div:nth-child(3) {
	left: 45px;
	animation-delay: 0;
}

@keyframes facebook {
	0% {
		top: 6px;
		height: 51px;
		opacity: 1;
	}
	50%, 100% {
		top: 19px;
		height: 26px;
		opacity: 0.5;
	}
}

</style>