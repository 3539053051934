<template>
	
<div class="distribution">

	<div class="distribution-band" v-for="band in bands" :key="band.band" :style="{height: height(band.count)}" :class="{'is-active': band.count > 0}" :data-band="band.band"></div>

</div>

</template>

<script>

export default {

	props: ['distribution'],

	computed: {

		bands: function() {

			var bands = []

			for (var i = 1; i <= 10; i++) {

				bands.push({
					band: i,
					count: this.distribution[i] || 0
				})

			}

			return bands

		},

		max: function() {

			return this.$_.max(this.bands, function(band) {

				return band.count

			}).count

		}

	},

	methods: {

		height: function(count) {

			return ((100 / this.max) * count).toString() + '%'

		}

	}

}

</script>

<style scoped>

.distribution {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	align-items: center;
}

.distribution-band {
	width: 4px;
	min-height: 4px;
	opacity: 0.25;
	border-radius: 2px;
	margin: 0px 1px;
}

.distribution-band.is-active {
	opacity: 1;
}

.distribution-band[data-band="10"] { background-color: #249563; }
.distribution-band[data-band="9"] { background-color: #249563; }
.distribution-band[data-band="8"] { background-color: #2FC482; }
.distribution-band[data-band="7"] { background-color: #1d8acd; }
.distribution-band[data-band="6"] { background-color: #5369a2; }
.distribution-band[data-band="5"] { background-color: #5369a2; }
.distribution-band[data-band="4"] { background-color: #df4751; }
.distribution-band[data-band="3"] { background-color: #df4751; }
.distribution-band[data-band="2"] { background-color: #db303b; }
.distribution-band[data-band="1"] { background-color: #db303b; }

</style>
