<template>
	
<core-list-cell type="date" :format="format" :condensed="condensed">

	<core-date :date="value" invalid="n/a" />

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format', 'index', 'condensed'],

	computed: {

		value: function() {

			if (this.format.context === 'filter') {

				return (this.isUser) ? this.item.context.user[this.format.value] : this.item.context.users[this.format.value]

			} else {

				return (this.isUser) ? this.item.user[this.format.value] : this.item.users[this.format.value]

			}

		}

	}

}

</script>
