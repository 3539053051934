<template>

<core-filter-advanced-group title="Owners" :count="count" v-if="(isGroup || isBuddies) && owners.length > 1">

	<core-filter-input-range text="Min. owners" min="0" max="20" :filter="value('threshold/owners')" step="1" decimals="0" density="20" />

	<core-filter-input-suggest v-if="owners.length > 12" placeholder="Start typing a username..." field="name" :filter="value('owners')" api="users/suggest" data="users" />

	<core-filter-input-users v-if="owners.length <= 12 && dataUsers.length" text="Required owners" :filter="value('owners')" :users="owners" />

	<core-filter-input-toggle text="Min. owners within required only" on="Yes" off="No" :filter="value('ownersmin')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['threshold.owners', 'ownersmin', 'owners']
		}
	},

	computed: {

		dataUsers: function() {

			return this.$store.getters['data/users/index']

		},

		owners: function() {

			return this.$store.getters['context/users']

		}

	}

}

</script>