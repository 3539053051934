<template>
	
<div class="card-foot-stat-label">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: []

}

</script>

<style scoped>

.card-foot-stat-label >>> .fa {
	opacity: 0.5;
	font-size: 10px;
}

.card-foot-stat-label {
	margin-top: 4px;
	font-size: 12px;
	font-weight: 300;
	color: #333;
	display: block;
}

</style>
