<template>
	
<core-card-foot-item>

	<core-card-foot-item-value class="duration" :class="{'is-single': !hasMax}" v-if="min">

		<div class="duration-number">{{ min }}</div>
		<div class="duration-divide" v-show="hasMax"></div>
		<div class="duration-number" v-show="hasMax">{{ max }}</div>

	</core-card-foot-item-value>

	<core-card-foot-item-value v-if="!min" :invalid="true" />

	<core-card-foot-item-label>

		Duration

	</core-card-foot-item-label>

</core-card-foot-item>

</template>

<script>

export default {

	props: ['format', 'item', 'filter'],

	computed: {

		value: function() {

			return 0

		},

		hasMax: function() {

			return this.item.game.time_min !== this.item.game.time_max && this.item.game.time_min && this.item.game.time_max

		},

		min: function() {

			return this.item.game.time_min

		},

		max: function() {

			return this.item.game.time_max

		}

	}

}

</script>

<style scoped>

.duration {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.duration-number {
	font-size: 14px;
	line-height: 14px;
	color: #666;
	font-weight: 500;
}

.duration.is-single .duration-number {
	font-size: 24px;
}

.duration-divide {
	height: 1px;
	width: 4px;
	background-color: #666;
}

</style>
