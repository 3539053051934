<template>

<div class="group">
	
	<slot></slot>
	
</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.group {
	flex-grow: 1;
}

</style>
