<template>

<div class="avatar">

	<div class="avatar-preview" v-on:click="onAvatarClick" v-bind:style="{backgroundImage: previewImage}" v-bind:class="{'is-previewing': src && !is.loading, 'is-loading': is.loading}">

		<i class="fa fa-image"></i>

		<input type="file" ref="input" accept="image/*" class="avatar-input" />

	</div>

	<div class="avatar-buttons">

		<core-button v-on:click.native="onAvatarClick" theme="blue">Select image</core-button>

	</div>

</div>

</template>

<script>

import InputValidation from 'core/mixin/form/input/Validation.js'

export default {

	mixins: [InputValidation],

	props: [
		'name',
		'value',
		'disabled',
		'error',
		'validation'
	],

	data: function() {

		return {
			is: {
				loading: false
			},
			file: false,
			src: false,
			base64: false
		}

	},

	mounted: function() {

		var self = this

		this.$refs.input.addEventListener('change', function() {

			if (this.files.length) self.onFileSelected(this.files[0])

		})

	},

	created: function() {

		this.src = this.value

	},

	computed: {

		previewImage: function() {

			return (this.src && !this.is.loading) ? 'url(' + this.src + ')' : false

		}

	},

	methods: {

		validate: function() {},

		getBase64: function(file) {

			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => resolve(reader.result)
				reader.onerror = error => reject(error)
			})

		},

		onFileSelected: function(file) {

			this.is.loading = true

			this.file = file

			var objUrl = window.URL || window.webkitURL

			this.src = objUrl.createObjectURL(this.file)

			this.getBase64(this.file).then(function(data) {

				this.is.loading = false
				
				this.$emit('input', data)

			}.bind(this))

		},

		onAvatarClick: function() {

			this.$refs.input.click()

		}

	}

}

</script>

<style scoped>

.avatar {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	width: 100%;
	margin-bottom: 15px;
}

.avatar-preview {
	width: 91px;
	height: 91px;
	font-size: 64px;
	color: #ccc;
	text-align: center;
	line-height: 91px;
	border: 1px solid #ddd;
	cursor: pointer;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.avatar-preview.is-previewing {
	background-size: cover;
}

.avatar-preview.is-loading {
	background-image: url(~core/assets/load.gif);
}

.is-desktop .avatar-preview:hover {
	border-color: #ccc;
}

.avatar-input {
	visibility: hidden;
}

.avatar-preview.is-loading .fa,
.avatar-preview.is-previewing .fa {
	display: none;
}

.avatar-buttons {
	padding: 0px 0px 0px 15px;
}

</style>
