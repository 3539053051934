<template>

<div class="date">

	<div class="date-valid" v-if="date">

		<div class="date-year" v-if="yearFirst">{{ date | formatDate('YYYY') }}</div>
		<div class="date-day" v-if="dayFirst">{{ date | formatDate('DD') }}</div>
		<div class="date-month">{{ date | formatDate('MMM') }}</div>
		<div class="date-day" v-if="!dayFirst">{{ date | formatDate('DD') }}</div>
		<div class="date-year" v-if="!yearFirst">{{ date | formatDate('YYYY') }}</div>

	</div>

	<div class="date-invalid" v-if="!date">

		{{ invalid }}

	</div>

</div>

</template>

<script>

export default {

	props: ['date', 'invalid'],

	computed: {

		yearFirst: function() {

			return this.$store.getters['session/config'].other.date === 'YYYY/MM/DD'

		},

		dayFirst: function() {

			return this.$store.getters['session/config'].other.date === 'DD/MM/YYYY'

		}

	}

}

</script>

<style scoped>

.date-valid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: 'Roboto Mono', monospace;
	text-transform: uppercase;
	justify-content: space-between;
	width: 41px;
	/*width: 53px;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 6px;*/
}

.date-month {
	font-size: 12px;
	line-height: 12px;
	padding-right: 2px;
	font-weight: 400;
	color: #444;
}

.date-day {
	font-size: 12px;
	line-height: 12px;
	font-weight: 500;
	color: #333;
}

.date-year {
	font-size: 16px;
	line-height: 16px;
	font-weight: 300;
	color: #999;
}

.date-invalid {
	color: #ccc;
    font-weight: 400;
    font-size: 14px
}

</style>