<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ thresholds.plays[data.y] }}, {{ thresholds.days[data.x] }}</h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" />.</p>

		<p class="small">Click a section to view full list of games.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :showPlaysText="true" :subtext="subtext(item)" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'turnover',
			games: [],
			params: ['x', 'y', 'filter'],
			thresholds: {
				plays: {
					0: '0 plays',
					1: '1 play',
					2: '2 - 5 plays',
					3: '6 - 10 plays',
					4: '11+ plays'
				},
				days: {
					0: '0 - 10 days',
					1: '11 - 100 days',
					2: '101 - 500 days',
					3: '501 - 1000 days',
					4: '1001+ days'
				}
			}
		}

	},

	methods: {	

		response: function(json) {

			this.games = json.games

		},

		subtext: function(item) {

			return item.days + ' day' + ((item.days === 1) ? '' : 's')

		}

	}

}

</script>