<template>

<core-filter-advanced-group title="Types" :count="count">

	<core-filter-input-toggle text="Member Joined" :inverse="true" on="Show" off="Hide" :filter="value('hide/member/added')" v-if="isGroup" />
	
	<core-filter-input-toggle text="Member Left" :inverse="true" on="Show" off="Hide" :filter="value('hide/member/removed')" v-if="isGroup" />
	
	<core-filter-input-toggle text="Buddy Added" :inverse="true" on="Show" off="Hide" :filter="value('hide/buddy/added')" v-if="isBuddies" />

	<core-filter-input-toggle text="Buddy Removed" :inverse="true" on="Show" off="Hide" :filter="value('hide/buddy/remove')" v-if="isBuddies" />
	
	<core-filter-input-toggle text="Rating Added" :inverse="true" on="Show" off="Hide" :filter="value('hide/rating/added')" />
	
	<core-filter-input-toggle text="Rating Updated" :inverse="true" on="Show" off="Hide" :filter="value('hide/rating/updated')" />
	
	<core-filter-input-toggle text="Rating Removed" :inverse="true" on="Show" off="Hide" :filter="value('hide/rating/removed')" />
	
	<core-filter-input-toggle text="Comment Added" :inverse="true" on="Show" off="Hide" :filter="value('hide/comment/added')" />

	<core-filter-input-toggle text="Comment Updated" :inverse="true" on="Show" off="Hide" :filter="value('hide/comment/updated')" />
	
	<core-filter-input-toggle text="Comment Removed" :inverse="true" on="Show" off="Hide" :filter="value('hide/comment/removed')" />
	
	<core-filter-input-toggle text="Collection Added" :inverse="true" on="Show" off="Hide" :filter="value('hide/collection/added')" />
	
	<core-filter-input-toggle text="Collection Removed" :inverse="true" on="Show" off="Hide" :filter="value('hide/collection/removed')" />
	
	<core-filter-input-toggle text="Status Added" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/added')" />
	
	<core-filter-input-toggle text="Status Removed" :inverse="true" on="Show" off="Hide" :filter="value('hide/status/removed')" />
	
	<core-filter-input-toggle text="Tags Created" :inverse="true" on="Show" off="Hide" :filter="value('hide/tags/created')" />
	
	<core-filter-input-toggle text="Tags Deleted" :inverse="true" on="Show" off="Hide" :filter="value('hide/tags/deleted')" />
	
	<core-filter-input-toggle text="Tag Added" :inverse="true" on="Show" off="Hide" :filter="value('hide/tags/added')" />
	
	<core-filter-input-toggle text="Tag Removed" :inverse="true" on="Show" off="Hide" :filter="value('hide/tags/removed')" />
	
	<core-filter-input-toggle text="Game Played" :inverse="true" on="Show" off="Hide" :filter="value('hide/play/added')" />
	
	<core-filter-input-toggle text="h-index Updated" :inverse="true" on="Show" off="Hide" :filter="value('hide/hindex')" />
	
	<core-filter-input-toggle v-if="!isBuddies" text="List Added" :inverse="true" on="Show" off="Hide" :filter="value('hide/list/added')" />

	<core-filter-input-toggle v-if="!isBuddies" text="List Removed" :inverse="true" on="Show" off="Hide" :filter="value('hide/list/removed')" />
	
	<core-filter-input-toggle v-if="!isBuddies" text="List Edition Added" :inverse="true" on="Show" off="Hide" :filter="value('hide/edition/added')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['hide.member.added', 'hide.member.removed', 'hide.buddy.added', 'hide.buddy.removed', 'hide.rating.added', 'hide.rating.removed', 'hide.rating.updated', 'hide.collection.added', 'hide.collection.removed', 'hide.status.added', 'hide.status.removed', 'hide.play.added', 'hide.hindex', 'hide.list.added', 'hide.list.removed', 'hide.edition.added', 'hide.comment.added', 'hide.comment.updated', 'hide.comment.removed', 'hide.tags.created', 'hide.tags.deleted', 'hide.tags.added', 'hide.tags.removed']
		}
	}

}

</script>