function handler(e) {

	e.stopPropagation()

}

var supportsPassive = false

try {

	var opts = Object.defineProperty({}, 'passive', {

		get: function() {
			supportsPassive = true
			return true
		}

	})

	window.addEventListener('testPassive', null, opts)
	window.removeEventListener('testPassive', null, opts)

} catch (e) {

	console.log(e)

}

export default {

	bind: function(el) {

		el.addEventListener('click', handler.bind(el))
		el.addEventListener('mousedown', handler.bind(el))
		el.addEventListener('touchstart', handler.bind(el), supportsPassive ? { passive: true } : false)

	},

	unbind: function(el) {

		el.removeEventListener('click', handler.bind(el))
		el.removeEventListener('mousedown', handler.bind(el))
		el.removeEventListener('touchstart', handler.bind(el))

	}

}