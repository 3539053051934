<template>

<div class="row">

    <slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.row {
    display: grid;
}

.row:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

</style>