<template>

<div>

	<slot></slot>

	<core-form-input-textbox name="username" v-on:validate="validate" placeholder="Enter BGG username" v-model="inputs.username" :error="is.error" :disabled="is.success || is.loading" :validation="validation.username"></core-form-input-textbox>

	<core-form-input-password name="password" v-on:validate="validate" placeholder="Enter geekGroup password" v-model="inputs.password" :error="is.error" :disabled="is.success || is.loading" :validation="validation.password" validationMessage="off" v-on:enter="onLoginClick"></core-form-input-password>

	<core-button v-on:click.native="onLoginClick" theme="grey" :loading="is.loading">Login</core-button>
	<core-button v-on:click.native="onForgotClick" theme="plain" size="small">Forgot password</core-button>

</div>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	name: 'form-login',

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				username: '',
				password: ''
			},

			validation: {
				username: { exists: true },
				password: { format: true }
			}

		}

	},

	methods: {

		onLoginClick: function() {

			this.is.loading = true

			this.$store.dispatch('api/session/login', {
				username: this.inputs.username,
				password: this.inputs.password
			}).then(function(json) {

				if (this.$route.name === 'Home' || this.$route.name === 'About' || this.$route.name === 'Changelist') {

					this.$router.push({
						name: 'Collection',
						params: {
							name: json.slug,
							type: this.$CONSTANTS.TYPE.USERS
						}
					}).catch(function(e) { console.log(e) })

				} else {

					this.$router.go()

				}

			}.bind(this), function() {

				this.is.error = true
				this.is.loading = false

			}.bind(this)) 

		},

		onForgotClick: function() {

			this.$pubsub.$emit('open.modal', 'userForgot')

		}

	}

}

</script>

<style scoped>

</style>