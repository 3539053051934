<template>

<div class="textbox" v-bind:class="{[bkgClass]: true}">

	<div class="textbox-wrapper">

		<div v-show="prefix" class="textbox-prefix">{{ prefix }}</div>

		<textarea-autosize 
			:name="name" 
			type="text" 
			:min-height="1"
			:max-height="260"
			:placeholder="placeholder" 
			v-bind:value="value" 
			v-on:keyup.enter="$emit('enter')"
			v-on:input="onInput" 
			class="textbox-input" 
			:disabled="disabled"
			v-bind:class="{
				'is-error': error,
				'is-disabled': disabled
			}"
			autocomplete="off" 
			autocorrect="off" 
			autocapitalize="off" 
			spellcheck="false"
		/>

	</div>

	<div class="textbox-notes" v-if="notes">{{ notes }}</div>

	<div v-show="validation && validationMessage !== 'off'" class="textbox-validation">{{ validationFeedback }}</div>

</div>

</template>

<script>

import InputValidation from 'core/mixin/form/input/Validation.js'

export default {

	mixins: [InputValidation],

	props: [
		'name',
		'placeholder', 
		'value',
		'notes',
		'prefix',
		'disabled',
		'error',
		'validation',
		'validationMessage',
		'background'
	],

	computed: {

		bkgClass: function() {

			if (this.$_.contains(['light', 'dark'], this.background)) return 'bkg-' + this.background

			return 'bkg-light'

		}

	},

	methods: {

		onInput: function(value) {

			this.$emit('input', value)

		},

		validate: function(rules) {

			if (rules.exists) {

				if (this.value.trim().length === 0) {

					this.feedback.push('')

				}

			}

		}

	}

}

</script>

<style scoped>

.textbox {
	margin-bottom: 15px;
}

.textbox-wrapper {
	display: flex;
}

.textbox-input {
	line-height: 20px;
	font-size: 16px;
	font-weight: 400;
	padding: 6px 8px;
	border-radius: 4px;
	width: 100%;
	flex-grow: 1;
}

@media only screen and (max-width: 767px) {

	.textbox-input {
		font-size: 12px;
	}

}

.textbox.bkg-light .textbox-input {
	background-color: #fff;
	border: 1px solid #ddd;
	color: #333;
}

.textbox.bkg-light .textbox-input:focus {
	border: 1px solid #ccc;
}

.textbox.bkg-light.is-error .textbox-input {
	border: 1px solid #ce5f5f;
}

.textbox.bkg-light .textbox-input::placeholder {
	color: #666;
}

.textbox.bkg-dark .textbox-input {
	background-color: #3395B5;
	border: 1px solid #3395B5;
	color: #fff;
}

.textbox.bkg-dark .textbox-input:focus {
}

.textbox.bkg-dark .textbox-input.is-error {
	background-color: #ce5f5f;
	border-color: #ce5f5f;
}

.textbox.bkg-dark .textbox-input::placeholder {
	color: rgba(255, 255, 255, 0.25);
}

.textbox-input.is-disabled {
	color: #ccc!important;
}

.textbox-prefix {
	line-height: 36px;
	height: 38px;
	font-size: 14px;
	padding: 0px 8px;
	border: 1px solid #ddd;
	background-color: #eee;
	border-right: 1px solid #024359;
}

@media only screen and (max-width: 767px) {

	.textbox-prefix {
		font-size: 12px;
	}

}

.textbox-notes {
	font-size: 10px;
	margin-top: 4px;
	color: #fff;	
	padding: 0px 4px;
	font-weight: 400;
}

.textbox-validation {
	position: absolute;
	right: 4px;
	top: 4px;
	color: red;
	font-weight: 400;
	font-size: 11px;
	line-height: 30px;
	height: 30px;
	white-space: nowrap;
	text-align: right;
	padding: 0px 7px;
}

</style>
