<template>
	
<div class="name" v-bind:class="{'type-user': isUser}">

	<div class="name-bar">

		<core-gamename :game="item.game" class="name-link" />

	</div>
				
	<div v-if="version && isUser && isBase" class="name-edition">{{ version }}</div> 

</div>

</template>

<script>

export default {

	props: ['item', 'format', 'filter'],

	computed: {

		isBase: function() {

			return this.item.game.type === this.$CONSTANTS.GAME.TYPE.BASE

		},

		version: function() {

			if (this.item.game.version) {

				var version = ''

				if (this.item.game.version.localised && this.item.game.name !== this.item.game.version.localised) {

					version += this.item.game.name + ' / '

				}
				
				version += this.item.game.version.name + ((this.item.game.version.year) ? ' (' + this.item.game.version.year + ')' : '')

				return version

			} else {

				return false

			}

		}

	}

}

</script>

<style scoped>

.name {
	flex-grow: 1;
	min-height: 18px;
	min-width: 0;
	display: flex;
	flex-wrap: nowrap;
	padding-top: 10px;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
	text-align: left;
	flex-direction: column;
}

.name-bar {
	display: flex;
	flex-direction: row;
	flex-basis: 0;
	width: 100%;
}

.name-link {
	overflow: hidden;
	min-width: 0;
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	cursor: pointer;
	overflow: hidden;
	color: #333;
	font-weight: 500;
}

.name-edition {
	color: #666;
	font-size: 11px;
	margin-bottom: 2px;
	margin-top: 2px;
	line-height: 11px;
	font-weight: 400;
}

@media only screen and (max-width: 1119px) {

	.name-link { 
		font-size: 14px;
		line-height: 16px;
	}

}

.is-desktop .name-link:hover {
	text-decoration: underline;
}

</style>
