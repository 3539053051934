<template>

<a :href="href" type="button" :target="target" v-tooltip="tooltip" class="button" v-bind:class="{
	[sizeClass]: true, 
	[themeClass]: true,
	'has-caret': caret,
	'is-fluid': fluid === 'yes', 
	'is-icon-only': iconOnly,
	'has-icon': icon,
	'is-loading': loading,
	'is-success': success,
	'is-disabled': disabled,
	'has-subtext': subtext
}" v-on:click="onClick">

	<div v-if="icon && iconLeft" class="button-icon"><i v-bind:class="{[iconClass]: icon, 'fa': !fab && !fas, 'fab': fab, 'fas': fas}"></i></div>

	<div class="button-content" v-if="(hasContent || text) && !iconOnly">

		<slot></slot>

		{{ text }}

		<small v-if="subtext">{{ subtext }}</small>

	</div>

	<div v-if="badge && !hideBadge" class="button-badge">{{ badge }}</div>

	<div v-if="icon && !iconLeft" class="button-icon"><i v-bind:class="{[iconClass]: icon, 'fa': !fab && !fas, 'fab': fab, 'fas': fas}"></i></div>

	<div v-if="caret" class="button-caret"><i class="fa fa-caret-down" v-if="!caretUp"></i><i class="fa fa-caret-up" v-if="caretUp"></i></div>

</a>

</template>

<script>

export default {

	props: [
		'fab',
		'fas',
		'badge',
		'hideBadge',
		'size',
		'loading',
		'caret',
		'iconOnly',
		'iconLeft',
		'fluid',
		'theme',
		'text',
		'success',
		'disabled',
		'icon',
		'href',
		'tooltip',
		'subtext',
		'caretUp'
	],

	computed: {

		target: function() {

			return (this.href) ? '_blank' : '_self'

		},

		iconClass: function() {

			return 'fa-' + this.icon

		},

		themeClass: function() {

			return (this.theme) ? 'theme-' + this.theme : 'theme-blue'

		},

		sizeClass: function() {

			if (this.$_.contains(['normal', 'small', 'tiny'], this.size)) return 'size-' + this.size

			return 'size-normal'

		},

		hasContent: function() {

			return this.$slots.default !== undefined

		}

	},

	methods: {

		onClick: function(e) {

			if (!this.href) e.preventDefault()

		}

	}

}

</script>

<style scoped>

.button {
	height: 40px;
	padding: 0px 10px;
	border-radius: 4px;
	font-weight: 400;
	text-align: center;
	cursor: pointer;
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	user-select: none;
}

@media only screen and (max-width: 767px) {

	.button {
		padding: 0px 8px;
		min-width: 40px;
	}

}

.button.is-fluid {
	width: 100%;
}

.button.size-normal {
	font-size: 14px;
}

.button.has-subtext.size-normal {
	line-height: 14px;
}

.button.has-subtext small {
	font-size: 10px;
	display: block;
	opacity: 0.5;
}

.button-icon {
	margin-left: 5px;
	font-size: 16px;
	line-height: 40px;
}

.button.theme-filter .button-icon {
	font-size: 20px;
}

.is-mobile .button.theme-filter .button-icon {
	font-size: 16px;
}

.button-content {
	width: 100%;
}

.button.has-icon .button-content {
	padding-right: 8px;
}

@media only screen and (max-width: 767px) {

	.button .button-icon {
		margin-left: 5px;
	}

}

.button.is-icon-only .button-icon {
	margin-left: 0px;
}

.button.size-small {
	font-size: 12px;
}

.button.size-tiny {
	font-size: 12px;
	padding: 0px 6px;
	height: 24px;
	line-height: 24px;
}

.button.size-tiny .button-icon {
	font-size: 12px;
}

.button.theme-expand {
	color: #333;
	font-weight: 400;
}

.button.theme-insights {
	background-color: #4a7888;
	padding: 0px 15px;
	color: #fff;
	text-align: left;
}

.is-desktop .button.theme-insights:hover {
	background-color: #03546f;
}

.button.theme-insights .button-icon {
	position: absolute;
	right: 15px;
	line-height: 40px;
	top: 0px;
}

.button.theme-blue {
	background-color: #4881bb;
	color: #fff;
	font-size: 16px;
	font-weight: 400;
}

.is-desktop .button.theme-blue:focus,
.is-desktop .button.theme-blue:hover {
	background-color: #356ca4;
}

.button.theme-red {
	background-color: #ec3a3a;
	color: #fff;
	font-size: 16px;
    font-weight: 400;
}

.is-desktop .button.theme-red:hover {
	background-color: #d22b2b;
}

.button.theme-yellow {
	background-color: #ffc563;
	color: #082534;
}

.is-desktop .button.theme-yellow:hover {
	background-color: #dfa84b;
}

.button.theme-darkblue {
	background-color: #0a262f;
	color: #fff;
}

.is-desktop .button.theme-darkblue:hover {
	background-color: #0d2931;
}

.button.theme-grey {
	background-color: #45636d;
	color: #fff;
}

.is-desktop .button.theme-grey:hover {
	background-color: #3a565f;
}

.button.theme-plain {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
}

.button.theme-plaingrey {
	color: #333;
}

.is-desktop .button.theme-plain:hover {
	color: #fff;
	font-weight: 500;
}

.is-desktop .button.theme-plaingrey:hover {
	color: #333;
	font-weight: 500;
}

.button.theme-cell {
	color: #333;
	line-height: 32px!important;
	height: 32px;
	width: 32px;
	margin: 8px;
	text-align: center;
	border-radius: 16px;
	min-width: 32px;
}

.is-desktop .button.theme-cell:hover {
	background-color: #4a7888;
	color: #fff;
}

.button.theme-filter {
	height: 40px;
	line-height: 40px;
	min-width: 40px!important;
	color: #fff;
	border-radius: 0px;
	padding: 0px 15px;
	background-color: #316293;
	border-radius: 4px;
	margin-left: 10px;
}

.is-tablet .button.theme-filter {
	margin-left: 2px;
}

.is-mobile .button.theme-filter {
	margin-left: 4px;
	height: 32px;
	min-width: 32px!important;
	padding: 0px 5px;
}

.is-mobile .button.theme-filter.is-icon-only {
	width: 32px;
}

.button.theme-cell.is-icon-only,
.button.theme-filter.is-icon-only {
	padding: 0px;
	text-align: center;
}

.is-desktop .button.theme-filter:not(.is-disabled):hover {
	background-color: #244d76;
}

.button.theme-filter .button-content {
	height: 40px;
	line-height: 40px;
	font-size: 14px;
}

.is-desktop .button.theme-filter:not(.is-disabled):hover {
	opacity: 0.85;
}

.button.is-loading {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	pointer-events: none;
	background-image: url(~core/assets/load-transparent.gif);
}

.button.is-success {
	pointer-events: none;
	background-color: #5fad69!important;
}

.button.is-loading .button-content,
.button.is-loading .button-icon {
	visibility: hidden;
}

.button.is-disabled > * {
	opacity: 0.5;
}

.button.is-disabled {
	pointer-events: none;
}

.button-caret {
	margin-left: 8px;
}

.button.theme-plain.is-icon-only  {
	padding: 0px;
	width: 40px;
	line-height: 40px!important;
	text-align: center;
}

.button.has-caret .fa-caret-down {
	font-size: 12px!important;
}

.button.theme-plain.is-icon-only .button-icon {
	font-size: 16px;
}

.button.is-icon-only .button-caret {
	margin-left: 8px;
}

.is-mobile .button.is-icon-only .button-caret {
	margin-left: 4px;
}

.is-desktop .button.theme-plain.is-icon-only:hover .button-icon {
	color: #fff;
}

.button-badge {
	position: absolute;
    right: 1px;
    top: 2px;
    z-index: 1;
    background-color: #3395b5;
    border-radius: 4px;
    color: #fff;
    font-size: 10px;
    min-width: 14px;
    height: 14px;
    padding: 0px 2px;
    text-align: center;
    font-weight: 400;
    line-height: 14px;
}

</style>
