<template>

<core-filter-advanced-group title="Data source" :count="count">

	<core-filter-input-toggle text="My collection" on="Yes" off="No" :filter="value('source/collection')" />
	<core-filter-input-toggle text="My ratings" on="Yes" off="No" :filter="value('source/ratings')" />
	<core-filter-input-toggle text="My plays" on="Yes" off="No" :filter="value('source/plays')" />
	<core-filter-input-toggle text="Entire database" on="Yes" off="No" :filter="value('source/all')" />

	<p>Note: All lists have a maximum size of 1,000 games.</p>

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['source.collection', 'source.ratings', 'source.plays', 'source.all']
		}
	}

}

</script>