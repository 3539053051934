<template>
	
<core-avatar :type="type" :id="id" :size="size" class="card-head" :data-band="band">

	<slot></slot>

</core-avatar>

</template>

<script>

export default {

	props: ['type', 'id', 'size', 'band']

}

</script>

<style scoped>

.card-head {
	width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 4px 4px 0px 0px;
    max-height: 160px;
	background-color: #fff;
	border-bottom: 3px solid transparent;
}

.card-head[data-band="10"] { border-color: #249563; }
.card-head[data-band="9"] { border-color: #249563; }
.card-head[data-band="8"] { border-color: #2FC482; }
.card-head[data-band="7"] { border-color: #1d8acd; }
.card-head[data-band="6"] { border-color: #5369a2; }
.card-head[data-band="5"] { border-color: #5369a2; }
.card-head[data-band="4"] { border-color: #df4751; }
.card-head[data-band="3"] { border-color: #df4751; }
.card-head[data-band="2"] { border-color: #db303b; }
.card-head[data-band="1"] { border-color: #db303b; }
.card-head[data-band="0"] { border-color: #db303b; }

.card-head:before {
	content: "";
    display: block;
    padding-top: 100%;
}

</style>
