<template>

<core-filter-advanced-group title="Publishers" :count="count">

	<core-filter-input-suggest placeholder="Start typing a publisher's name..." :toggle="true" field="publisher_name" :filter="value('publishers')" api="publishers/suggest" data="publishers" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['publishers']
		}
	}

}

</script>