<template>

<core-filter-actions>

	<core-dropdown-item type="title" v-if="(canEditEdition && !isRanking) || (canEditEdition && isRanking && isRankingSetup) || canDeleteEdition">Edition / {{ edition.name }}</core-dropdown-item>
	<core-dropdown-link v-if="canEditEdition && !isRanking" v-on:click.native="onAddGamesClick">Import games</core-dropdown-link>
	<core-dropdown-link v-if="canEditEdition && !isRanking" v-on:click.native="onSearchGamesClick">Search games</core-dropdown-link>
	<core-dropdown-link v-if="isManual && canEditEdition && isSorted" v-on:click.native="onSaveOrder">Save order</core-dropdown-link>
	<core-dropdown-link v-if="canEditEdition && isRanking && isRankingSetup" v-on:click.native="onRankingClick">Setup</core-dropdown-link>
	<core-dropdown-link v-if="canDeleteEdition" v-on:click.native="onDeleteEditionClick">Delete edition</core-dropdown-link>

	<core-dropdown-item type="divide" v-if="(canEditEdition && !isRanking) || (canEditEdition && isRanking && isRankingSetup) || canDeleteEdition"></core-dropdown-item>

	<core-dropdown-link v-if="canCreateEdition" v-on:click.native="onAddEditionClick">Add new edition</core-dropdown-link>

	<core-dropdown-item type="divide" v-if="canCreateEdition"></core-dropdown-item>

	<core-dropdown-item type="title">List</core-dropdown-item>

	<core-dropdown-link v-if="canConfigureList" v-on:click.native="onFilterClick">Configure filter <span class="unsaved" v-if="changedFilter">Unsaved</span></core-dropdown-link>

	<core-dropdown-link v-if="!isRanking && canEditList" v-on:click.native="onColumnsClick">Configure columns <span class="unsaved" v-if="changedColumns">Unsaved</span></core-dropdown-link>

	<core-dropdown-link v-if="canEditList" v-on:click.native="onListEditClick">Edit list</core-dropdown-link>
	<core-dropdown-link v-if="canDeleteList" v-on:click.native="onListDeleteClick">Delete list</core-dropdown-link>

</core-filter-actions>

</template>

<script>

export default {

	data: function() {

		return {
			sorted: false
		}

	},

	computed: {

		sort: function() {

			return this.$store.getters['filter/list/sort']

		},

		isSorted: function() {

			return this.sorted

		},

		isManual: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.MANUAL

		},

		isAutomated: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.AUTOMATED

		},

		isRanking: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.RANKING

		},

		isRankingSetup: function() {

			return this.edition.ranking.status === this.$CONSTANTS.LISTS.RANKING.IMPORT

		},

		changedFilter: function() {

			return this.$store.getters['filter/list/changed'] && this.isAutomated

		},

		changedColumns: function() {

			return this.$store.getters['page/list/columns/changed']

		},

		list: function() {

			return this.$store.getters['page/list/list']

		},

		edition: function() {

			return this.$store.getters['page/list/edition']

		},

		canCreateList: function() {

			return this.$store.getters['permissions/list/create']

		},

		canEditList: function() {

			return this.$store.getters['permissions/list/edit']

		},

		canConfigureList: function() {

			return this.$store.getters['permissions/list/edit'] && this.list.type === this.$CONSTANTS.LISTS.TYPE.AUTOMATED

		},

		canDeleteList: function() {

			return this.$store.getters['permissions/list/delete']

		},

		canCreateEdition: function() {

			return this.$store.getters['permissions/edition/create']

		},

		canEditEdition: function() {

			return this.$store.getters['permissions/edition/edit']

		},

		canDeleteEdition: function() {

			return this.$store.getters['permissions/edition/delete']

		}

	},

	watch: {

		sort: function() {

			this.sorted = true

		}

	},

	methods: {

		onSaveOrder: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('list.order.save')

			this.sorted = false

		},

		onRankingClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('list.rank.expand')

		},

		onAddEditionClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('open.modal', {
				id: 'listEditionCreate',
				list: this.list
			})

		},

		onSpecialClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('list.special.unplayed.expand')
		
		},

		onDeleteEditionClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('open.modal', 'listEditionDelete')
			
		},

		onSearchGamesClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('list.search.expand')

		},

		onAddGamesClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('list.import.expand')

		},

		onFilterClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('list.automated.expand')

		},

		onColumnsClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('list.columns.expand')

		},

		onListDeleteClick: function() {

			this.$pubsub.$emit('open.modal', 'listDelete')

		},

		onListEditClick: function() {

			this.$pubsub.$emit('open.modal', 'listEdit')

		}

	}

}

</script>

<style scoped>

.unsaved {
	background-color: #ff6868;
	color: #fff;
	font-size: 12px;
	border-radius: 4px;
	padding: 2px 4px;
	font-size: 10px;
	margin-left: 10px;
}

</style>
