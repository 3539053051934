<template>

<com-item name="collection.designers" :is-list="true" :has-more="true" v-on:more="onDesignersClick" :size="size" :is-empty="!active" :loading="loading" title="Designers">

	<template v-slot:about>

		<p>Which designers have the most games in {{ your }} collection.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<app-person v-for="(item, index) in designers" :item="item" type="designer" v-bind:key="index" v-on:more="onMoreClick(item.id)" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		designers: function() {

			return this.$store.getters['page/insights/collection/designers']

		},

		active: function() {

			return this.designers.length && !this.loading

		}

	},

	methods: {

		onDesignersClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionDesigners'
			})

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionDesigner',
				id: id
			})

		}

	}

}

</script>

<style scoped>

.listing {
	width: 100%;
	padding: 0px!important;
}

</style>