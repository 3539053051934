<template>

<div class="input" :class="{'is-inline': inline, 'is-wide': wide}" :data-theme="theme">

	<div class="input-text" v-if="text">{{ text }}</div>

	<div class="input-toggle" v-on:click="onClick" v-touch:swipe="onSwipe" v-bind:class="{'is-on': value === onValueInt && !inverse || value === offValueInt && inverse, 'is-off': value === offValueInt && !inverse || value === onValueInt && inverse}">
		<div class="input-toggle-slider">
			<div class="input-toggle-slider-value">{{ on }}</div>
			<div class="input-toggle-slider-divider"></div>
			<div class="input-toggle-slider-value">{{ off }}</div>
		</div>
	</div>

</div>

</template>

<script>

export default {

	props: [
		'filter', 'text', 'on', 'off', 'inverse', 'page', 'wide', 'theme', 'session', 'onValue', 'offValue', 'inline', 'defaultValue'
	],

	computed: {

		onValueInt: function() {

			return (this.onValue !== undefined) ? parseInt(this.onValue) : 1

		},

		offValueInt: function() {

			return (this.offValue !== undefined) ? parseInt(this.offValue) : 0

		},

		value: function() {

			var value = 0

			if (this.filter) value = this.$store.getters['filter/' + this.filter] 
			if (this.page) value = this.$store.getters['page/' + this.page]
			if (this.session) value = this.$store.getters['session/' + this.session]
			if (this.defaultValue) value = this.defaultValue

			if (typeof value === 'boolean') {

				value = (value) ? 1 : 0

			}
			
			return value

		}

	},

	methods: {

		onClick: function() {

			var value = !this.value

			if (this.filter) {

				this.$store.commit('filter/' + this.filter, !this.value)

			} else if (this.page) {

				this.$store.commit('page/' + this.page, !this.value)

			} else if (this.session) {

				value = (this.value === this.onValueInt) ? this.offValueInt : this.onValueInt
				this.$store.commit('session/' + this.session, (this.value === this.onValueInt) ? this.offValueInt : this.onValueInt)

			}

			this.$emit('change', value)

		},

		onSwipe: function() {

			this.onClick()

		}

	}
}

</script>

<style scoped>

.input {
	position: relative;
	height: 24px;
	padding: 0px 0px;
	margin-bottom: 8px;
}

.input.is-inline {
	margin-bottom: inherit;
}

.input-text {
	font-size: 12px;
	display: block;
	color: #fff;
	line-height: 24px;
	font-weight: 400;
}

.input-toggle {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 24px;
	width: 64px;
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
	border: 1px solid #024359;
}

.input.is-wide .input-toggle {
	width: 100%;
}

.input.is-inline .input-toggle {
	position: relative;
	right: auto;
	top: auto;
	width: 100%;
}

.input-toggle-slider {
	position: absolute;
	left: 0px;
	top: 0px;
	height: 22px;
	width: 200%;
	display: flex;
	flex-wrap: nowrap;
	transition: all 300ms ease-in-out;
}

.input-toggle.is-off .input-toggle-slider {
	left: -100%;
}

.input-toggle.is-on .input-toggle-slider {
	left: 0px;
}

.input-toggle-slider-value {
	width: 50%;
	font-size: 12px;
	line-height: 22px;
	color: #fff;
	cursor: pointer;
	font-weight: 400;
}

.input-toggle-slider-value:first-child {
	background-color: #4a7888;
	padding: 0px 12px 0px 6px;
}

.input-toggle-slider-value:last-child {
	background-color: #4a7888;
	text-align: right;
	padding: 0px 6px 0px 12px;
}

.input-toggle-slider-divider {
	position: absolute;
	left: 50%;
	top: -1px;
	margin-left: -16px;
	width: 32px;
	height: 24px;
	border-radius: 4px;
	background-color: #024359;
	z-index: 2;
}

.input[data-theme="light"].is-stacked .input-toggle {
    border: 1px solid #ddd;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    padding-top: 0px;
}

.input[data-theme="light"].is-stacked .input-toggle-item {
    font-size: 14px;
    color: #333;
    border-radius: 0px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    line-height: 16px;
    height: 40px;
    padding: 0px 10px;
    font-weight: 400;
}

.input[data-theme="light"].is-stacked .input-toggle-item:last-child {
    border-bottom: 0px;
}

.input[data-theme="light"].is-stacked .input-toggle-item.is-active {
    background-color: #4881bb;
    color: #fff;
}

.input[data-theme="light"]:not(.is-stacked) .input-toggle-slider {
    height: 40px;
}

.input[data-theme="light"]:not(.is-stacked) .input-toggle-slider-value {
    height: 40px;
    font-size: 14px;
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    line-height: 40px;
}

.input[data-theme="light"]:not(.is-stacked) .input-toggle-slider-divider {
    height: 40px;
	top: 0px;
    background-color: #4881bb;
}

.input[data-theme="light"]:not(.is-stacked) .input-toggle {
    height: 40px;
    border: 0px;
}

.input[data-theme="light"]:not(.is-stacked) .input-toggle-item {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0px 20px;
}

.input[data-theme="light"]:not(.is-stacked) .input-toggle-item:not(.is-active) {
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
}

.input[data-theme="light"]:not(.is-stacked) .input-toggle-item.is-active {
    background-color: #4881bb;
}

</style>
