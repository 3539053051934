import router from 'app/router'

export default {

	namespaced: true,

	state: {

		ranking: {
			band: 0
		},

		suitability: {
			duration: 'max',
			count: 'any'
		},

		bestduration: {
			duration: 'count'
		},

		rarity: 'most',
		weight: 'polar',
		duration: 'range',
		year: 'version',
		type: 'category'

	},

	getters: {

		active: function(state) {

			return {
				'ranking.band': state.ranking.band,
				'suitability.duration': state.suitability.duration !== 'max',
				'suitability.count': state.suitability.count !== 'any',
				'bestduration.duration': state.bestduration.duration !== 'count',
				'rarity': state.rarity !== 'most',
				'type': state.type !== 'category',
				'duration': state.type !== 'range',
				'weight': state.weight !== 'polar',
				'year': state.year !== 'version'
			}

		},

		values: function(state) {

			return {
				'ranking.band': state.ranking.band,
				'suitability.duration': state.suitability.duration,
				'bestduration.duration': state.bestduration.duration,
				'suitability.count': state.suitability.count,
				'rarity': state.rarity,
				'type': state.type,
				'duration': state.duration,
				'weight': state.weight,
				'year': state.year
			}

		},

		'ranking/band': function(state) {

			return state.ranking.band

		},

		'bestduration/duration': function(state) {

			return state.bestduration.duration

		},

		'suitability/duration': function(state) {

			return state.suitability.duration

		},

		'suitability/count': function(state) {

			return state.suitability.count

		},

		duration: function(state) {

			return state.duration

		},

		rarity: function(state) {

			return state.rarity

		},

		type: function(state) {

			return state.type

		},

		weight: function(state) {

			return state.weight

		},

		year: function(state) {

			return state.year

		}

	},

	mutations: {

		reset: function(state) {

			state.bestduration.duration = 'count'
			state.suitability.duration = 'max'
			state.suitability.count = 'any'
			state.ranking.band = 0
			state.type = 'category'
			state.rarity = 'most'
			state.duration = 'range'
			state.year = 'version'
			state.weight = 'polar'

		},

		'ranking/band': function(state, value) {

			state.ranking.band = parseInt(value)

		},

		'bestduration/duration': function(state, value) {

			state.bestduration.duration = value

		},

		'suitability/duration': function(state, value) {

			state.suitability.duration = value

		},

		'suitability/count': function(state, value) {

			state.suitability.count = value

		},

		rarity: function(state, value) {

			state.rarity = value

		},

		type: function(state, value) {

			state.type = value

		},

		year: function(state, value) {

			state.year = value

		},

		weight: function(state, value) {

			state.weight = value

		},

		duration: function(state, value) {

			state.duration = value

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params['bestduration.duration']) context.state.bestduration.duration = params['bestduration.duration']
					if (params['suitability.duration']) context.state.suitability.duration = params['suitability.duration']
					if (params['suitability.count']) context.state.suitability.count = params['suitability.count']
					if (params['ranking.band']) context.state.ranking.band = parseInt(params['ranking.band'])
					if (params['type']) context.state.type = params['type']
					if (params['rarity']) context.state.rarity = params['rarity']
					if (params['year']) context.state.year = params['year']
					if (params['duration']) context.state.duration = params['duration']
					if (params['weight']) context.state.weight = params['weight']

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}

			if (context.state.bestduration.duration !== 'count') urlParams['bestduration.duration'] = context.state.bestduration.duration
			if (context.state.suitability.duration !== 'max') urlParams['suitability.duration'] = context.state.suitability.duration
			if (context.state.suitability.count !== 'any') urlParams['suitability.count'] = context.state.suitability.count
			if (context.state.ranking.band !== 0) urlParams['ranking.band'] = context.state.ranking.band
			if (context.state.rarity !== 'most') urlParams['tyraritype'] = context.state.rarity
			if (context.state.type !== 'category') urlParams['type'] = context.state.type
			if (context.state.duration !== 'range') urlParams['duration'] = context.state.duration
			if (context.state.year !== 'version') urlParams['year'] = context.state.year
			if (context.state.weight !== 'polar') urlParams['weight'] = context.state.weight

			router.replace({name: 'InsightsCollection', query: urlParams}).catch(function() {})

		}

	}

}