<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Change password

	</template>

	<template v-slot:default>

		<core-form-input-password :autofocus="true" name="passwordCurrent" v-on:validate="validate" placeholder="Enter current password" v-model="inputs.passwordCurrent" :error="is.error" :disabled="is.success || is.completing" :validation="validation.passwordCurrent"></core-form-input-password>

		<core-form-input-password name="passwordNew1" v-on:validate="validate" placeholder="Enter new password" v-model="inputs.passwordNew1" :error="is.error" :disabled="is.success || is.completing" :validation="validation.passwordNew1"></core-form-input-password>

		<core-form-input-password name="passwordNew2" v-on:validate="validate" placeholder="Confirm new password" v-model="inputs.passwordNew2" :match="inputs.passwordNew1" :error="is.success || is.completing || is.error" :disabled="is.completing" :validation="validation.passwordNew2"></core-form-input-password>

        <div class="buttons">
			<core-button v-on:click.native="onSaveClick" :loading="is.loading" :success="is.success" :disabled="!is.valid" theme="blue">Save changes</core-button>
		</div>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false,
				success: false,
				error: false
			},

			inputs: {
				passwordCurrent: '',
				passwordNew1: '',
				passwordNew2: ''
			},

			validation: {
				passwordCurrent: { format: true },
				passwordNew1: { format: true },
				passwordNew2: { match: true }
			}

		}

	},

	methods: {

		onSaveClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/account/settings', {
					passwordCurrent: this.inputs.passwordCurrent,
					passwordNew: this.inputs.passwordNew1
				}).then(function() {

					this.is.error = false
					this.is.success = true
					this.is.loading = false

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.buttons {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
}

</style>