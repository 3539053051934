import _ from 'underscore'
import Util from 'app/util'
import router from 'app/router'

var defaultState = Util.filter.templates.activity()

export default {

	namespaced: true,

	state: {

		data: defaultState,

		preset: 0

	},

	getters: {

		preset: function(state) {

			return state.preset

		},

		'active/count': function(state, getters) {

			var total = 0

			_.each(getters['active'], function(count, key) {

				if (key.indexOf('layout') !== 0) {

					if (typeof count === 'boolean') {

						total += (count) ? 1 : 0 

					} else if (key.indexOf('.from') || key.indexOf('.to')) {

						total += (count) ? 1 : 0 

					} else {

						total += parseInt(count)

					}
						
				}

			})

			return total

		},

		active: function(state) {

			return {
				'date.from': state.data.date.from !== 0,
				'date.to': state.data.date.to !== 0,
				'members': state.data.members.length,
				'hide.edition.added': state.data.hide.edition.added,
				'hide.list.added': state.data.hide.list.added,
				'hide.list.removed': state.data.hide.list.removed,
				'hide.member.added': state.data.hide.member.added,
				'hide.member.removed': state.data.hide.member.removed,
				'hide.buddy.added': state.data.hide.buddy.added,
				'hide.buddy.removed': state.data.hide.buddy.removed,
				'hide.rating.added': state.data.hide.rating.added,
				'hide.rating.updated': state.data.hide.rating.updated,
				'hide.rating.removed': state.data.hide.rating.removed,
				'hide.comment.added': state.data.hide.comment.added,
				'hide.comment.updated': state.data.hide.comment.updated,
				'hide.comment.removed': state.data.hide.comment.removed,
				'hide.collection.added': state.data.hide.collection.added,
				'hide.collection.removed': state.data.hide.collection.removed,
				'hide.status.added': state.data.hide.status.added,
				'hide.status.removed': state.data.hide.status.removed,
				'hide.tags.created': state.data.hide.tags.created,
				'hide.tags.deleted': state.data.hide.tags.deleted,
				'hide.tags.added': state.data.hide.tags.added,
				'hide.tags.removed': state.data.hide.tags.removed,
				'hide.play.added': state.data.hide.play.added,
				'hide.hindex': state.data.hide.hindex,
				'hide.status.own': state.data.hide.status.own,
				'hide.status.prevowned': state.data.hide.status.prevowned,
				'hide.status.fortrade': state.data.hide.status.fortrade,
				'hide.status.want': state.data.hide.status.want,
				'hide.status.wanttoplay': state.data.hide.status.wanttoplay,
				'hide.status.wanttobuy': state.data.hide.status.wanttobuy,
				'hide.status.wishlist': state.data.hide.status.wishlist,
				'hide.status.preordered': state.data.hide.status.preordered,
				'hide.status.hasparts': state.data.hide.status.hasparts,
				'hide.status.wantparts': state.data.hide.status.wantparts
			}

		},

		values: function(state) {

			return state.data

		},

		'hide/tags/created': function(state) {

			return state.data.hide.tags.created

		},

		'hide/tags/deleted': function(state) {

			return state.data.hide.tags.deleted

		},

		'hide/tags/added': function(state) {

			return state.data.hide.tags.added

		},

		'hide/tags/removed': function(state) {

			return state.data.hide.tags.removed

		},

		'hide/edition/added': function(state) {

			return state.data.hide.edition.added

		},

		'hide/list/added': function(state) {

			return state.data.hide.list.added

		},

		'hide/list/removed': function(state) {

			return state.data.hide.list.removed

		},

		'hide/member/added': function(state) {

			return state.data.hide.member.added

		},

		'hide/member/removed': function(state) {

			return state.data.hide.member.removed

		},

		'hide/buddy/added': function(state) {

			return state.data.hide.buddy.added

		},

		'hide/buddy/removed': function(state) {

			return state.data.hide.buddy.removed

		},

		'hide/rating/added': function(state) {

			return state.data.hide.rating.added

		},

		'hide/rating/updated': function(state) {

			return state.data.hide.rating.updated

		},

		'hide/rating/removed': function(state) {

			return state.data.hide.rating.removed

		},

		'hide/comment/added': function(state) {

			return state.data.hide.comment.added

		},

		'hide/comment/updated': function(state) {

			return state.data.hide.comment.updated

		},

		'hide/comment/removed': function(state) {

			return state.data.hide.comment.removed

		},

		'hide/collection/added': function(state) {

			return state.data.hide.collection.added

		},

		'hide/collection/removed': function(state) {

			return state.data.hide.collection.removed

		},

		'hide/status/added': function(state) {

			return state.data.hide.status.added

		},

		'hide/status/removed': function(state) {

			return state.data.hide.status.removed

		},

		'hide/play/added': function(state) {

			return state.data.hide.play.added

		},

		'hide/hindex': function(state) {

			return state.data.hide.hindex

		},

		'date/from': function(state) {

			return state.data.date.from

		},

		'date/to': function(state) {

			return state.data.date.to

		},

		members: function(state) {

			return state.data.members

		},

		'hide/status/own': function(state) {

			return state.data.hide.status.own

		},

		'hide/status/prevowned': function(state) {

			return state.data.hide.status.prevowned

		},

		'hide/status/fortrade': function(state) {

			return state.data.hide.status.fortrade

		},

		'hide/status/want': function(state) {

			return state.data.hide.status.want

		},

		'hide/status/wanttoplay': function(state) {

			return state.data.hide.status.wanttoplay

		},

		'hide/status/wanttobuy': function(state) {

			return state.data.hide.status.wanttobuy

		},

		'hide/status/wishlist': function(state) {

			return state.data.hide.status.wishlist

		},

		'hide/status/preordered': function(state) {

			return state.data.hide.status.preordered

		},

		'hide/status/hasparts': function(state) {

			return state.data.hide.status.hasparts

		},

		'hide/status/wantparts': function(state) {

			return state.data.hide.status.wantparts

		}

	},

	mutations: {

		set: function(state, data) {

			state.data = Util.filter.autofill(data, 'activity')

		},

		preset: function(state, id) {

			state.preset = id

		},

		reset: function(state) {

			state.data = Util.filter.autofill({}, 'activity')

			state.preset = 0

		},

		'hide/tags/added': function(state, value) {

			state.data.hide.tags.added = value

		},

		'hide/tags/removed': function(state, value) {

			state.data.hide.tags.removed = value

		},

		'hide/tags/created': function(state, value) {

			state.data.hide.tags.created = value

		},

		'hide/tags/deleted': function(state, value) {

			state.data.hide.tags.deleted = value

		},

		'hide/edition/added': function(state, value) {

			state.data.hide.edition.added = value

		},

		'hide/list/added': function(state, value) {

			state.data.hide.list.added = value

		},

		'hide/list/removed': function(state, value) {

			state.data.hide.list.removed = value

		},

		'hide/member/added': function(state, value) {

			state.data.hide.member.added = value

		},

		'hide/member/removed': function(state, value) {

			state.data.hide.member.removed = value

		},

		'hide/buddy/added': function(state, value) {

			state.data.hide.buddy.added = value

		},

		'hide/buddy/removed': function(state, value) {

			state.data.hide.buddy.removed = value

		},

		'hide/rating/added': function(state, value) {

			state.data.hide.rating.added = value

		},

		'hide/rating/updated': function(state, value) {

			state.data.hide.rating.updated = value

		},

		'hide/rating/removed': function(state, value) {

			state.data.hide.rating.removed = value

		},

		'hide/comment/added': function(state, value) {

			state.data.hide.comment.added = value

		},

		'hide/comment/updated': function(state, value) {

			state.data.hide.comment.updated = value

		},

		'hide/comment/removed': function(state, value) {

			state.data.hide.comment.removed = value

		},

		'hide/collection/added': function(state, value) {

			state.data.hide.collection.added = value

		},

		'hide/collection/removed': function(state, value) {

			state.data.hide.collection.removed = value

		},

		'hide/status/added': function(state, value) {

			state.data.hide.status.added = value

		},

		'hide/status/removed': function(state, value) {

			state.data.hide.status.removed = value

		},

		'hide/play/added': function(state, value) {

			state.data.hide.play.added = value

		},

		'hide/hindex': function(state, value) {

			state.data.hide.hindex = value

		},

		'date/from': function(state, value) {

			state.data.date.from = value

		},

		'date/to': function(state, value) {

			state.data.date.to = value

		},

		members: function(state, id) {

			if (_.contains(state.data.members, id)) {

				state.data.members.splice(state.data.members.indexOf(id), 1)

			} else {

				state.data.members.push(id)

			}

		},

		'hide/status/own': function(state, value) {

			state.data.hide.status.own = value

		},

		'hide/status/prevowned': function(state, value) {

			state.data.hide.status.prevowned = value

		},

		'hide/status/fortrade': function(state, value) {

			state.data.hide.status.fortrade = value

		},

		'hide/status/want': function(state, value) {

			state.data.hide.status.want = value

		},

		'hide/status/wanttoplay': function(state, value) {

			state.data.hide.status.wanttoplay = value

		},

		'hide/status/wanttobuy': function(state, value) {

			state.data.hide.status.wanttobuy = value

		},

		'hide/status/wishlist': function(state, value) {

			state.data.hide.status.wishlist = value

		},

		'hide/status/preordered': function(state, value) {

			state.data.hide.status.preordered = value

		},

		'hide/status/hasparts': function(state, value) {

			state.data.hide.status.hasparts = value

		},

		'hide/status/wantparts': function(state, value) {

			state.data.hide.status.wantparts = value

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params['date.from']) {

						context.state.data.date.from = parseInt(params['date.from'])

					}

					if (params['date.to']) {

						context.state.data.date.to = parseInt(params['date.to'])

					}

					_.each(['members'], function(key) {

						if (params[key]) {

							context.state.data[key] = []

							_.each(params[key].split(','), function(value) {

								context.state.data[key].push(parseInt(value))

							})
								
						}

					})

					if (params['hide.tags.added']) {

						context.state.data.hide.tags.added = parseInt(params['hide.tags.added'])

					}

					if (params['hide.tags.removed']) {

						context.state.data.hide.tags.removed = parseInt(params['hide.tags.removed'])

					}

					if (params['hide.tags.created']) {

						context.state.data.hide.tags.created = parseInt(params['hide.tags.created'])

					}

					if (params['hide.tags.deleted']) {

						context.state.data.hide.tags.deleted = parseInt(params['hide.tags.deleted'])

					}

					if (params['hide.edition.added']) {

						context.state.data.hide.edition.added = parseInt(params['hide.edition.added'])

					}

					if (params['hide.list.added']) {

						context.state.data.hide.list.added = parseInt(params['hide.list.added'])

					}

					if (params['hide.list.removed']) {

						context.state.data.hide.list.removed = parseInt(params['hide.list.removed'])

					}

					if (params['hide.member.added']) {

						context.state.data.hide.member.added = parseInt(params['hide.member.added'])

					}

					if (params['hide.member.removed']) {

						context.state.data.hide.member.removed = parseInt(params['hide.member.removed'])

					}

					if (params['hide.buddy.added']) {

						context.state.data.hide.buddy.added = parseInt(params['hide.buddy.added'])

					}

					if (params['hide.buddy.removed']) {

						context.state.data.hide.buddy.removed = parseInt(params['hide.buddy.removed'])

					}

					if (params['hide.rating.added']) {

						context.state.data.hide.rating.added = parseInt(params['hide.rating.added'])

					}

					if (params['hide.rating.updated']) {

						context.state.data.hide.rating.updated = parseInt(params['hide.rating.updated'])

					}

					if (params['hide.rating.removed']) {

						context.state.data.hide.rating.removed = parseInt(params['hide.rating.removed'])

					}

					if (params['hide.comment.added']) {

						context.state.data.hide.comment.added = parseInt(params['hide.comment.added'])

					}

					if (params['hide.comment.updated']) {

						context.state.data.hide.comment.updated = parseInt(params['hide.comment.updated'])

					}

					if (params['hide.comment.removed']) {

						context.state.data.hide.comment.removed = parseInt(params['hide.comment.removed'])

					}

					if (params['hide.collection.added']) {

						context.state.data.hide.collection.added = parseInt(params['hide.collection.added'])

					}

					if (params['hide.collection.removed']) {

						context.state.data.hide.collection.removed = parseInt(params['hide.collection.removed'])

					}

					if (params['hide.status.added']) {

						context.state.data.hide.status.added = parseInt(params['hide.status.added'])

					}

					if (params['hide.status.removed']) {

						context.state.data.hide.status.removed = parseInt(params['hide.status.removed'])

					}

					if (params['hide.play.added']) {

						context.state.data.hide.play.added = parseInt(params['hide.play.added'])

					}

					if (params['hide.hindex']) {

						context.state.data.hide.hindex = parseInt(params['hide.hindex'])

					}

					_.each(['own', 'prevowned', 'fortrade', 'want', 'wanttoplay', 'wanttobuy', 'wishlist', 'preordered', 'hasparts', 'wantparts'], function(key) {

						if (params['hide.status.' + key] !== undefined) {

							context.state.data.hide.status[key] = parseInt(params['hide.status.' + key])
								
						}

					})

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}

			if (context.state.data.date.from) {

				urlParams['date.from'] = context.state.data.date.from

			}

			if (context.state.data.date.to) {

				urlParams['date.to'] = context.state.data.date.to

			}

			_.each(['members'], function(key) {

				if (context.state.data[key].length) {

					urlParams[key] = context.state.data[key].join(',')

				}

			})

			if (context.state.data.hide.tags.added) {

				urlParams['hide.tags.added'] = 1

			}

			if (context.state.data.hide.tags.removed) {

				urlParams['hide.tags.removed'] = 1

			}

			if (context.state.data.hide.tags.created) {

				urlParams['hide.tags.created'] = 1

			}

			if (context.state.data.hide.tags.deleted) {

				urlParams['hide.tags.deleted'] = 1

			}

			if (context.state.data.hide.member.added) {

				urlParams['hide.member.added'] = 1

			}

			if (context.state.data.hide.member.removed) {

				urlParams['hide.member.removed'] = 1

			}

			if (context.state.data.hide.buddy.added) {

				urlParams['hide.buddy.added'] = 1

			}

			if (context.state.data.hide.buddy.removed) {

				urlParams['hide.buddy.removed'] = 1

			}

			if (context.state.data.hide.rating.added) {

				urlParams['hide.rating.added'] = 1

			}

			if (context.state.data.hide.rating.updated) {

				urlParams['hide.rating.updated'] = 1

			}

			if (context.state.data.hide.rating.removed) {

				urlParams['hide.rating.removed'] = 1

			}

			if (context.state.data.hide.comment.added) {

				urlParams['hide.comment.added'] = 1

			}

			if (context.state.data.hide.comment.updated) {

				urlParams['hide.comment.updated'] = 1

			}

			if (context.state.data.hide.comment.removed) {

				urlParams['hide.comment.removed'] = 1

			}

			if (context.state.data.hide.list.added) {

				urlParams['hide.list.added'] = 1

			}

			if (context.state.data.hide.list.removed) {

				urlParams['hide.list.removed'] = 1

			}

			if (context.state.data.hide.edition.added) {

				urlParams['hide.edition.added'] = 1

			}

			if (context.state.data.hide.collection.added) {

				urlParams['hide.collection.added'] = 1

			}

			if (context.state.data.hide.collection.removed) {

				urlParams['hide.collection.removed'] = 1

			}

			if (context.state.data.hide.status.added) {

				urlParams['hide.status.added'] = 1

			}

			if (context.state.data.hide.status.removed) {

				urlParams['hide.status.removed'] = 1

			}

			if (context.state.data.hide.play.added) {

				urlParams['hide.play.added'] = 1

			}

			if (context.state.data.hide.hindex) {

				urlParams['hide.hindex'] = 1

			}

			_.each(['prevowned', 'fortrade', 'want', 'wanttoplay', 'wanttobuy', 'wishlist', 'preordered', 'hasparts', 'wantparts'], function(key) {

				if (context.state.data.hide.status[key] !== 0) {

					urlParams['hide.status.' + key] = 1
								
				}

			})

			router.push({name: 'Activity', query: urlParams}).catch(function() {})

		}

	}

}