<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Manage Group

	</template>

	<template v-slot:default>

		<core-form-input-textbox :autofocus="true" maxlength="25" name="name" v-on:validate="validate" placeholder="Enter group name" v-model="inputs.name" :error="is.error" :disabled="is.loading" :validation="validation.name" />

		<core-form-input-textbox maxlength="17" :prefix="slugPrefix" name="slug" v-on:validate="validate" placeholder="Enter group url" v-model="inputs.slug" :error="is.error" :disabled="!canSlug" :validation="validation.slug" :notes="slugNotes" />

		<core-form-input-section title="Avatar" />

		<core-form-input-avatar name="avatar" v-on:validate="validate" v-model="inputs.avatar" :error="is.error" :disabled="is.loading" :validation="validation.avatar" />

		<core-form-input-togglelist title="Membership" name="membership" v-on:validate="validate" v-model="inputs.membership" :error="is.error" :disabled="is.loading" :validation="validation.membership" :options="membershipOptions" />

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Save changes</core-button>
		<core-button theme="blue" v-on:click.native="onAddClick" :disabled="is.loading">Add member</core-button>
		<core-button theme="red" v-on:click.native="onDeleteClick" :disabled="is.loading">Delete group</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				name: this.$store.getters['context'].name,
				slug: this.$store.getters['context'].slug,
				membership: this.$store.getters['context'].membership,
				avatar: this.$store.getters['context'].avatar
			},

			validation: {
				name: {
					exists: true,
					max: 24
				},
				membership: 'exists',
				slug: {
					exists: true,
					max: 16,
					alphanumeric: true
				}
			}

		}

	},

	computed: {

		canSlug: function() {

			return this.$store.getters['session/upgraded']

		},

		slugPrefix: function() {

			return 'geekgroup.app/'

		},

		slugNotes: function() {

			return (this.canSlug) ? '' : this.$CONSTANTS.LANG.TIP.URL

		},

		membershipOptions: function() {

			return this.$CONSTANTS.GROUP.MEMBERSHIP_OPTIONS

		}

	},

	methods: {

		onAddClick: function() {

			this.$pubsub.$emit('open.modal', 'groupAdd')

		},

		onDeleteClick: function() {

			this.$pubsub.$emit('open.modal', 'groupDelete')

		},

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/groups/edit', {
					name: this.$route.params.name,
					data: {
						name: this.inputs.name,
						membership: this.inputs.membership,
						avatar: this.inputs.avatar,
						slug: this.inputs.slug
					}
				}).then(function(json) {

					this.$store.commit('session/groups/add', json)

					if (json.slug !== this.$route.params.name) {

						this.$router.push({
							name: 'Collection',
							params: {
								type: this.$CONSTANTS.TYPE.GROUPS,
								name: json.slug
							}
						}).catch(function(e) { console.log(e) })

						this.$emit('close')

					} else {

						this.$store.dispatch('reverify', {
							name: json.slug
						}).then(function() {

							this.$emit('close')

						}.bind(this))

					}

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.inputs {
	display: flex;
}

@media only screen and (max-width: 767px) {

	.inputs {
		display: block;
	}

}

.inputs-left {
	flex-grow: 1;
	margin-right: 15px;
}

@media only screen and (max-width: 767px) {

	.inputs-left {
		margin: 0px;
	}

}

.inputs-right {
	width: 91px;
}

@media only screen and (max-width: 767px) {

	.inputs-right {
		margin: 0px auto;
	}

}

</style>