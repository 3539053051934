<template>

<core-filter-advanced-group title="Status" :count="count">

	<core-filter-input-toggles text="Own" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/own')" />
		
	<core-filter-input-toggles text="Previously Owned" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/prevowned')" />
			
	<core-filter-input-toggles text="For Trade" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/fortrade')" />
			
	<core-filter-input-toggles text="Want" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/want')" />
			
	<core-filter-input-toggles text="Want to play" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/wanttoplay')" />
			
	<core-filter-input-toggles text="Want to buy" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/wanttobuy')" />
			
	<core-filter-input-toggles text="Wishlist" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/wishlist')" />
			
	<core-filter-input-range v-if="isWishlistIncluded" text="Priority" min="1" max="5" :inverse="true" :labels="priority" :filter="value('wishlist/priority')" step="1" pips="1" decimals="0" />

	<core-filter-input-toggles text="Pre-ordered" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/preordered')" />
			
	<core-filter-input-toggles text="Has parts" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/hasparts')" />
			
	<core-filter-input-toggles text="Wants parts" :icons="toggle.icons" :labels="toggle.labels" :values="toggle.values" :filter="value('status/wantparts')" />

	<core-filter-input-toggle text="Must match all those included" on="Yes" off="No" :filter="value('status/user')" v-if="isUser" />
	
	<core-filter-input-toggle text="User must match all those included" on="Yes" off="No" :filter="value('status/user')" v-if="isGroup || isBuddies" />
	
	<core-filter-input-toggle text="Game must match all those included" on="Yes" off="No" :filter="value('status/game')" v-if="isGroup || isBuddies" />
	
	<core-filter-input-toggle text="Merge duplicated games" on="Yes" off="No" :filter="value('status/merge')" v-if="isUser" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {

			priority: {
				5: 'Don\'t',
				4: 'Maybe',
				3: 'Like',
				2: 'Love',
				1: 'Must'
			},

			fields: ['wishlist.priority', 'status.own', 'status.prevowned', 'status.fortrade', 'status.want', 'status.wanttoplay', 'status.wanttobuy', 'status.wishlist', 'status.preordered', 'status.hasparts', 'status.wantparts', 'status.user', 'status.game', 'status.merge'],

			toggle: {

				icons: [false, 'arrows-alt-h', false],
				values: [1, 0, 2],
				labels: ['Include', '', 'Exclude']

			}

		}

	},

	computed: {

		isWishlistIncluded: function() {

			return this.values.status.wishlist === 1

		}

	}

}

</script>