<template>

<core-panel>

	<template v-slot:head>

		<h1>Most played</h1>

		<p>The full list of most played from this period.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in played" :count="playCount(item)" :item="item" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'played',
			played: []
		}

	},

	methods: {

		response: function(json) {

			this.played = json.played

		},

		playCount: function(item) {

			return (this.isUser) ? item.context.user.plays : item.context.users.totalPlays

		}

	}

}

</script>