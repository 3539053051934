<template>

<core-filter-input-text v-on:ready="onReady" :filter="filter" placeholder="Quick search..." v-if="!window.is.mobile" class="filter-text-wrapper" />

</template>

<script>

export default {

	props: [
		'filter'
	],

	data: function() {

		return {
			el: false
		}

	},

	created: function() {

		window.addEventListener('keydown', this.onShortcut.bind(this))

	},

	beforeDestroy: function() {

		window.removeEventListener('keydown', this.onShortcut.bind(this))

	},

	methods: {

		onShortcut: function(e) {

			if (e.ctrlKey && e.keyCode === 70) {

				e.preventDefault()
				this.el.focus()

			}

		},

		onReady: function(el) {

			this.el = el

		}

	}

}

</script>

<style scoped>

.filter-text-wrapper {
	width: 200px;
	padding: 0px!important;
	border-left: 1px solid #035874;
}

.filter-text-wrapper >>> input {
	border-radius: 0px!important;
	background-color: #2c84a0!important;
	height: 40px!important;
	padding: 0px 15px!important;
	font-size: 14px!important;
}

.filter-text-wrapper >>> input::placeholder {
	font-style: italic!important;
	color: rgba(255, 255, 255, 0.5)!important;
	font-size: 14px!important;
}

.filter-text-wrapper >>> input:focus {
	background-color: #56a9c3!important;
}

</style>
