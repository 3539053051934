<template>

<core-config-section :locked="!loggedin">

	<h2>For Trade</h2>

	<p>If you want to include games in your collection with a For Trade status on your shelf.</p>

	<div class="inputs">

		<core-filter-input-toggle text="Include" on="Yes" off="No" filter="config/lists/unplayed/trade" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>