<template>

<div class="input" v-bind:class="{'is-buttons': buttons, 'is-stacked': stacked}">

	<div class="input-text" v-if="text">{{ text }}</div>

	<div class="input-toggle" v-touch:swipe="onSwipe">

		<div v-for="(label, index) in labels" v-bind:key="index" class="input-toggle-item" v-bind:class="{'is-active': values[index] === value}" v-on:click="onClick(values[index])">{{ labels[index] }}<i v-if="icons[index]" v-bind:class="'fa fa-' + icons[index]" /></div>
		
	</div>

</div>

</template>

<script>

export default {

	props: [
		'filter', 'text', 'defaultValue', 'labels', 'stacked', 'values', 'icons', 'buttons', 'posneg'
	],

	computed: {

		value: function() {

			var value = 0

			if (this.filter) {

				if (this.posneg) {

					if (this.$_.contains(this.$store.getters['filter/' + this.filter], '+' + this.posneg.toString())) {

						value = 3

					} else if (this.$_.contains(this.$store.getters['filter/' + this.filter], '-' + this.posneg.toString())) {

						value = 2

					} else if (this.$_.contains(this.$store.getters['filter/' + this.filter], this.posneg.toString())) {

						value = 1

					}

				} else {

					if (this.filter) value = this.$store.getters['filter/' + this.filter] 

					if (typeof value === 'boolean') {

						value = (value) ? 1 : 0

					}

				}
				
			}

			if (this.defaultValue) value = this.defaultValue
			
			return value

		}

	},

	methods: {

		onClick: function(value) {

			if (this.filter) {

				if (this.posneg) {

					if (value === 3) value = '+' + this.posneg.toString()
					if (value === 2) value = '-' + this.posneg.toString()
					if (value === 1 || value === true) value = this.posneg.toString()

					this.$store.commit('filter/' + this.filter, {
						id: this.posneg,
						value: value
					})

				} else {

					this.$store.commit('filter/' + this.filter, value)

				}

			}

			this.$emit('change', value)

		},

		onSwipe: function(direction) {

			if (!this.buttons) {

				if (direction === 'right') {

					if (this.values[0] === this.value) this.onClick(this.values[1])
					else if (this.values[1] === this.value) this.onClick(this.values[2])

				} else if (direction === 'left') {

					if (this.values[1] === this.value) this.onClick(this.values[0])
					else if (this.values[2] === this.value) this.onClick(this.values[1])

				}

			}

		}

	}

}

</script>

<style scoped>

.input {
	position: relative;
	height: 24px;
	padding: 0px 0px;
	margin-bottom: 8px;
}

.input.is-stacked {
	height: auto;
}

.input-text {
	font-size: 12px;
	display: block;
	color: #fff;
	line-height: 24px;
	font-weight: 400;
}

.input-toggle {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 24px;
	border-radius: 4px;
	background-color: #4a7888;
	overflow: hidden;
	border: 1px solid #024359;
	display: flex;
}

.input.is-stacked .input-toggle {
	height: auto;
	position: relative;
	top: auto;
	left: auto;
	flex-direction: column;
	width: 100%;
}

.input-toggle-item {
	height: 22px;
	padding: 0px 6px;
	line-height: 22px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.75);
}

.input.is-stacked .input-toggle-item {
	width: 100%;
}

.input.is-buttons .input-toggle-item {
	width: 64px;
	text-align: center;
}

.input-toggle-item.is-active {
	background-color: #024359;
	color: #fff;
	border-radius: 4px;
}

.is-desktop .input-toggle-item:hover {
	color: #fff;
}

</style>
