<template>

<core-dropdown-level name="root" :level="level" v-on:level="$emit('level', parent)">

	<template v-if="itemCan.tag && !isNestingChild">

		<core-dropdown-item type="title">Tags</core-dropdown-item>

		<core-dropdown-item type="level" level="tag.add" v-on:level="$emit('level', 'tag.add')">Add<template v-if="hasTags"> and remove</template></core-dropdown-item>

		<core-dropdown-item type="link" v-on:click.native="onClearTagsClick" :loading="is.tagsClearing" v-if="hasTags">Clear<template v-if="hasTags > 1"> all</template></core-dropdown-item>

		<core-dropdown-item type="divide" />

	</template>

	<template v-if="itemCan.list.remove">

		<core-dropdown-item type="title">List</core-dropdown-item>

		<core-dropdown-item type="link" v-on:click.native="onActionClick('list/move', 'listMoving')">Move</core-dropdown-item>

		<core-dropdown-item type="link" v-on:click.native="onActionClick('list/remove', 'listRemoving')" :loading="is.listRemoving">Remove</core-dropdown-item>

		<core-dropdown-item type="divide" />

	</template>

	<template v-if="itemCan.users.remove && !isRemovingSelf">

		<core-dropdown-item type="link" v-on:click.native="onActionClick('users/remove', 'userRemoving')" :loading="is.userRemoving">Remove</core-dropdown-item>

		<core-dropdown-item type="divide" />

	</template>

	<template v-if="itemCan.filter && !isNestingChild">

		<core-dropdown-item type="title">Filter</core-dropdown-item>
		<core-dropdown-item type="link" v-on:click.native="onActionClick('filter/include')" v-if="!isIncluded">Always include game</core-dropdown-item>
		<core-dropdown-item type="link" v-on:click.native="onActionClick('filter/include')" v-if="isIncluded">Don't always include game</core-dropdown-item>
		<core-dropdown-item type="link" v-on:click.native="onActionClick('filter/exclude')">Always exclude game</core-dropdown-item>

		<core-dropdown-item type="divide" />

	</template>

	<template v-if="itemCan.nesting">

		<core-dropdown-item type="title">Nesting</core-dropdown-item>

		<core-dropdown-item type="level" level="nesting" v-on:level="$emit('level', 'nesting')" v-if="!isNestingParent && !isNestingChild">Nest under game</core-dropdown-item>
		<core-dropdown-item type="level" level="nesting" v-on:level="$emit('level', 'nesting')" v-if="isNestingChild">Move to another game</core-dropdown-item>
		<core-dropdown-item type="link" v-on:click.native="onActionClick('nesting/clear', 'nestingClear')" :loading="is.nestingClear" v-if="isNestingParent">Remove all nested games</core-dropdown-item>
		<core-dropdown-item type="link" v-on:click.native="onActionClick('nesting/restore', 'nestingRestore')" :loading="is.nestingRestore" v-if="!isNestingOfficial && hasNestingOfficial">Restore original nesting</core-dropdown-item>
		<core-dropdown-item type="link" v-on:click.native="onActionClick('nesting/remove', 'nestingRemove')" :loading="is.nestingRemove" v-if="isNestingChild">Remove from nesting</core-dropdown-item>

		<core-dropdown-item type="divide" v-if="itemCan.rename || itemCan.game" />

	</template>

	<core-dropdown-item type="link" v-on:click.native="onActionClick('game/rename')" v-if="itemCan.rename">Rename</core-dropdown-item>
	<core-dropdown-item type="link" v-on:click.native="onPanelClick" v-if="itemCan.game">Open game panel</core-dropdown-item>
	<core-dropdown-item type="link" v-on:click.native="onLogClick" v-if="itemCan.log">Open BGG play page</core-dropdown-item>

</core-dropdown-level>

</template>

<script>

export default {

	props: ['filter', 'item', 'can', 'level'],
	
	data: function() {

		return {
			is: {
				nestingRestore: false,
				nestingClear: false,
				nestingRemove: false,
				listRemoving: false,
				userRemoving: false,
				tagsClearing: false
			}
		}

	},

	computed: {

		isRemovingSelf: function() {

			return this.$store.getters['session/user/id'] === this.item.id

		},

		hasTags: function() {

			return this.$_.intersection(this.$store.getters['data/tags/keys'], this.item.tags).length

		},

		itemCan: function() {

			return this.can || this.$store.getters['service/select/can']

		},

		isNestingChild: function() {

			return (this.isUser && this.item.collection) ? this.item.collection.nesting.child : false

		},

		isNestingOfficial: function() {

			return (this.item.collection) ? this.item.collection.nesting.child === 1 : false

		},

		isNestingParent: function() {

			return (this.item.collection) ? this.item.collection.nesting.parent : false

		},

		hasNestingOfficial: function() {

			return (this.item.collection) ? this.item.collection.nesting.official : false

		},

		isIncluded: function() {

			return this.$_.contains(this.$store.getters['filter/' + this.filter + '/games/include'], this.item.game.id)

		},

		isPlays: function() {

			return this.$_.contains(['Plays'], this.$route.name)

		},

		isRatings: function() {

			return this.$_.contains(['Ratings'], this.$route.name)

		}

	},

	methods: {

		onLogClick: function() {

			window.open('https://boardgamegeek.com/play/details/' + this.item.id)

		},

		onActionClick: function(name, loading) {

			if (loading) this.is[loading] = true

			this.$store.dispatch('service/select/' + name, {
				override: {
					item: this.item.id,
					game: (this.item.game) ? this.item.game.id : false
				},
				allowInvert: true
			}).then(function() {

				if (loading) this.is[loading] = false

			}.bind(this))

		},

		onPanelClick: function() {

			this.$pubsub.$emit('expand.open', {
				id: (this.isRatings || this.isPlays) ? this.item.id : this.item.game.id
			})

			this.$emit('close')

		},

		onClearTagsClick: function() {

			this.is.tagsClearing = true

			this.$store.dispatch('service/select/tags/clear', {
				override: {
					item: this.item.id,
					game: this.item.game.id
				}
			}).then(function() {

				this.is.tagsClearing = false

			}.bind(this), function() {

				this.is.tagsClearing = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>