<template>
	
<div class="avatar" :class="{'is-condensed': condensed}" v-lazy:background-image="avatar" v-on:click="onAvatarClick" v-tooltip="tooltip">

	<app-avatar-include :id="item.game.id" :filter="filter" v-if="showInclude" />

</div>

</template>

<script>

export default {

	props: ['item', 'condensed', 'filter'],

	computed: {

		showInclude: function() {

			return this.$store.getters['service/select/can'].filter

		},

		tooltip: function() {

			return (this.isIncluded) ? false : 'Click to see more details'

		},

		avatar: function() {

			return this.$util.thumbnail.game.small(this.item.game)

		},

		isIncluded: function() {

			return this.$_.contains(this.$store.getters['filter/' + this.filter + '/games/include'], this.item.game.id)

		}

	},

	methods: {

		onAvatarClick: function(e) {

			if (e.shiftKey) {

				this.$util.bgg.open.game(this.item.game.id)

			} else {

				this.$pubsub.$emit('expand.open', {
					id: this.item.game.id
				})

			}

		}

	}

}

</script>

<style scoped>

.avatar {
	min-width: 48px;
	width: 48px;
	height: 48px;
	background-position: 50% 50%;
	background-size: cover;
	cursor: pointer;
}

.avatar.is-condensed {
	min-width: 24px;
	height: 24px;
	width: 24px;
	margin-left: 24px;
}

</style>
