import plays from './insights/plays'
import ratings from './insights/ratings'
import collection from './insights/collection'
import members from './insights/members'
import records from './insights/records'
import opponents from './insights/opponents'
import shelf from './insights/shelf'
import worth from './insights/worth'
import size from './insights/size'

export default {

	namespaced: true,

	modules: {
		plays: plays,
		ratings: ratings,
		collection: collection,
		members: members,
		records: records,
		opponents: opponents,
		shelf: shelf,
		worth: worth,
		size: size
	},

	state: {},

	getters: {

		size: function(state, getters) {

			return getters['size/values']

		},

		shelf: function(state, getters) {

			return getters['shelf/values']

		},
	
		worth: function(state, getters) {
	
			return getters['worth/values']
	
		},

		plays: function(state, getters) {

			return getters['plays/values']

		},

		ratings: function(state, getters) {

			return getters['ratings/values']

		},

		collection: function(state, getters) {

			return getters['collection/values']

		},

		members: function(state, getters) {

			return getters['members/values']

		},

		records: function(state, getters) {

			return getters['records/values']

		},

		opponents: function(state, getters) {

			return getters['opponents/values']

		}

	},

	mutations: {},

	actions: {}

}