<template>

<core-panel>

	<template v-slot:head>

		<h1>Locations</h1>

		<p>The locations most played at.</p>

	</template>

	<template v-slot:body>

		<app-thing v-for="(item, index) in locations" :nolink="true" :item="item" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'locations',
			locations: []
		}

	},

	methods: {

		response: function(json) {

			this.locations = json.locations

		}

	}

}

</script>