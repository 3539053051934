<template>

<div class="action">

    <div class="action-box">

        <div class="action-icon">
            <dotlottie-player src="https://lottie.host/a3d14eb0-2885-4f16-a1ae-440207319a9c/FIjntGcdjQ.json" background="transparent" speed="1" style="width: 192px; height: 192px;" loop autoplay></dotlottie-player>
        </div>

        <div class="action-title">{{ action.name }}<small>Please wait...</small></div>

        <div class="action-description">{{ action.description }}</div>

    </div>

</div>

</template>

<script>

export default {

    props: ['action'],

    data: function() {
    
        return {
            syncKey: false,
            delay: 5000
        }

    },

    created: function() {
    
        this.run()
    
    },

    methods: {
    
        run: function() {
        
            this.$store.dispatch('api/actions/run', {
                syncKey: this.syncKey,
                action: this.action.id,
                type: this.$store.getters['context/type'],
                id: this.$store.getters['context/id'],
            }).then(function(json) {

                this.$store.commit('context/set', {
                    data: json,
                    type: this.$store.getters['context/type']
                })
            
            }.bind(this), function(json) {
            
                if (json.syncKey) {
                
                    this.syncKey = json.syncKey

                    this.$_.delay(function() {
                    
                        this.run()
                    
                    }.bind(this), this.delay)
                
                }
            
            }.bind(this))
        
        }
    
    }

}

</script>

<style scoped>

.action {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.action-box {
    max-width: 640px;
    width: calc(100% - 20px);
    text-align: center;
}

.action-icon {
    height: 192px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.action-title {
    font-size: 24px;
    font-weight: 500;
    color: #082534;
    margin-bottom: 20px;
}

.action-title small {
    display: block;
    font-size: 16px;
    margin-top: 4px;
    color: #333;
    font-weight: 300;
}

.action-description {
    font-size: 16px;
    line-height: 20px;
    color: #244657;
    font-weight: 300;
    background-color: #33CCCC;
    padding: 18px 20px;
    border-radius: 4px;
}

.action-description:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -7px;
    margin-left: -8px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #33CCCC transparent;
    transform: rotate(0deg);
}

</style>
