<template>
	
<div class="card-foot-stat-value" :class="{'is-invalid': invalid}">

	<slot></slot>

	<template v-if="invalid">n/a</template>

</div>

</template>

<script>

export default {

	props: ['invalid']

}

</script>

<style scoped>

.card-foot-stat-value {
	height: 32px;
	width: 100%;
}

.card-foot-stat-value.is-invalid {
	color: #ccc;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    font-size: 14px
}

</style>
