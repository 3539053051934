<template>

<app-page class="home" v-if="is.showing">

	<div class="search">

		<com-logo :large="true" :byline="true" />

		<div class="search-input" v-bind:class="{'is-loading': is.loading, 'is-error': is.error}">

			<i v-if="is.loading" class="search-icon fa fa-spinner fa-spin" />

			<input :autofocus="'autofocus'" v-model="username" type="text" v-on:keyup.enter="onVerifySubmit" placeholder="enter a BGG username and press enter" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" />

			<div v-show="is.error" class="search-error">unrecognised username</div>

			<div class="search-login" v-if="!is.error" v-on:click="onLoginClick"><span>Already have an account? Click to login</span></div>

		</div>

	</div>

	<div class="links">
		<div class="links-item"><span v-on:click="onPageClick('appAbout', $event)">about</span></div>
		<div class="links-item"><span v-on:click="onPageClick('appPrivacy', $event)">privacy</span></div>
		<div class="links-item"><span v-on:click="onPageClick('appTerms', $event)">terms</span></div>
	</div>

	<div class="bgg">
		<small>raw data from</small>
		<a href="https://www.boardgamegeek.com" target="_blank">boardgamegeek.com</a>
	</div>
		
</app-page>

</template>

<script>

import comLogo from '../page/head/Logo'

export default {

	components: {
		'com-logo': comLogo
	},

	data: function() {

		return {
			is: {
				showing: false,
				loading: false,
				error: false
			},
			username: ''
		}

	},

	created: function() {
	
		if (this.loggedin) {
		
			this.$router.push({
				name: 'Collection',
				params: {
					name: this.$store.getters['session'].name ,
					type: this.$CONSTANTS.TYPE.USERS
				}
			})

		} else {

			this.is.showing = true
		
		}
	
	},

	methods: {

		onLoginClick: function(e) {
		
			e.stopPropagation()

			this.$pubsub.$emit('open.modal', 'userLogin')

		},

		onPageClick: function(name, e) {

			e.stopPropagation()

			this.$pubsub.$emit('open.modal', name)

		},

		onButtonClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('switch.expand')

		},

		onVerifySubmit: function() {

			if (this.username.trim()) {

				this.is.loading = true
				this.is.error = false

				this.$store.dispatch('api/account/exists', {
					name: this.username
				}).then(function(data) {

					this.$router.push({
						name: 'Collection',
						params: {
							name: data.name,
							type: this.$CONSTANTS.TYPE.USERS
						}
					}).catch(function(e) { console.log(e) })

				}.bind(this), function() {

					this.is.loading = false
					this.is.error = true

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.search {
	width: 600px;
	padding-bottom: 56px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 767px) {

	.search {
		width: 100%;
	}

}

.search-input {
	height: 48px;
	width: 400px;
	margin: 0px auto;
	background-color: #fff;
	border-radius: 4px;
	margin-top: 25px;
	margin-bottom: 15px;
	transition: background-color 100ms linear;
}

.is-mobile .search-input {
	height: 40px;
	width: 100%;
	max-width: 300px;
}

.search-input input {
	color: #333;
	width: 100%;
	text-align: center;
	font-weight: 400;
	height: 48px;
	line-height: 48px;
	font-size: 18px;
	z-index: 1;
	transition: all 100ms linear;
}

@media only screen and (max-width: 767px) {

	.search-input input {
		height: 40px;
		line-height: 40px;
		font-size: 16px;
	}

}

.search-input input::placeholder {
	color: #999;
	font-weight: 300;
}

.search-input.is-loading input {
	display: none;
}

.search-input.is-error {
	padding: 0px 15px;
	background-color: #ff6666;
}

.search-input.is-error input {
	color: #fff;
	padding-bottom: 12px;
}

.search-input.is-error input::placeholder {
	color: #fff;
}

.search-icon {
	position: absolute;
	left: calc(50% - 12px);
	top: 0px;
	width: 24px;
	text-align: center;
	line-height: 48px;
	color: #ffc563;
	font-size: 24px;
}

.is-mobile .search-icon {
	line-height: 40px;
}

.search-error {
	font-size: 12px!important;
	color: #fff;
	width: 100%;
	display: block;
	text-align: center;
	position: absolute;
	left: 0px;
	bottom: 7px;
	z-index: 2;
}

.search > p {
	width: 100%;
	text-align: center;
	color: #fff;
	font-weight: 300;
	font-size: 14px;
	opacity: 0.7;
}

.search-button {
	padding: 0px!important;
	color: #fff!important;
}

.bgg {
	position: absolute;
	right: 10px;
	bottom: 10px;
	color: #fff;
	font-weight: 400;
	font-size: 14px;
	text-align: right;
	padding-right: 36px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAmCAMAAADtLHsqAAAAhFBMVEUAAAD/UQD/UQD/UAD/VwD/UQD/UQD/UQD/UQD/UQD/UAD/WAD/UQD/UQD/UAD/UQD/UgD/UAD/UgD/UQD/UQD/UAD/UQD/UAD/UAD/UQD/UAD/UQD/UQD/UAD/UQD/UQD/UAD/UAD/UAD/UAD/UAD/TwD/RwD/UQD/UQD/UQD/UQD/UQDHSOVYAAAAK3RSTlMAj+5vBcP6rUMoFAjnyYJeLyIb+eTi2M+5o6KblIp+d2RcVU40LQz36dWxGXtUPwAAAN9JREFUOMt1k9cWgjAQRBNARATEjvRu2f//P5MAShnu49wTmF0IwxxTHeZ29ibztMzbQ2AS0WMhrFAjRTPNd8mVeoLJC3SX/li/3Cg/ZxrhDsLnDk3Ry2grDuh3QkhlEoSLx12xktvYYFUJFWLlC+Vh1QqVQ6OptUF1V58Gj6WWtDKW5ILHEhw1pBJhGocgMWM3WsFinDAXg+0Ik66u92kLVUClfrY9LF9LBYu4ysAihRSwiGP06rBQnoxRkfE1iWcqkiEocsu6M/MiL14P2aTIeVvtZ6IrsslthvC4v8i+Ki9rOlCl3nQAAAAASUVORK5CYII=');
	background-position: 100% 50%;
	background-repeat: no-repeat;
	background-size: auto 32px;
}

.bgg small {
	opacity: 0.5;
	display: block;
}

.is-mobile .bgg {
	font-size: 12px;
	right: 5px;
	padding-right: 26px;
	background-size: auto 26px;
}

.links {
	position: absolute;
	left: 5px;
	bottom: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
}

.links-item {
	color: #ffffff;
	font-weight: 400;
	font-size: 14px;
	padding: 0px 15px;
	flex-shrink: 0;
}

.is-mobile .links-item {
	font-size: 12px;
	padding: 0px 5px;
}

.bgg a,
.links-item span {
	cursor: pointer;
}

.bgg a:hover,
.links-item span:hover {
	text-decoration: underline;
}

.home-inner {
	position: absolute;
	bottom: 0px;
}

.search-login {
	color: #fff;
	font-size: 16px;
	margin-top: 10px;
	line-height: 40px;
	text-align: center;
	font-weight: 400;
	opacity: 0.8;
	cursor: pointer;
}

.is-mobile .search-login {
	font-size: 14px;
}

.is-desktop .search-login span {
	border-bottom: 1px dashed transparent;
}

.is-desktop .search-login:hover span {
	border-bottom: 1px dashed #fff;
}

</style>