<template>

<core-dropdown class="input" trigger="manual" v-on:forceClose="is.expanded = false" :expanded="is.expanded" v-click-outside="onOutsideClick">

	<core-button v-on:click.native="onToggleClick" tooltip="Manage layout" theme="filter" :caret="true" class="input-button" :class="{'has-prefix': prefix}"><template v-if="!window.is.mobile">{{ prefix }}</template><span>{{ text }}</span></core-button>
	
	<core-dropdown-list class="input-options" theme="filter" :caret="true" align="left" type="button">

		<core-dropdown-item v-for="layout in layouts" :icon="layout.icon" :key="layout.value" type="action" v-bind:class="{'is-active': value === layout.value}" v-on:click.native="onOptionClick(layout.value)">{{ layout.name }}</core-dropdown-item>

		<slot></slot>

	</core-dropdown-list>

</core-dropdown>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: [
		'filter', 'page', 'on', 'off', 'prefix'
	],
	
	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		text: function() {
		
			return (this.value === 1) ? this.on : this.off

		},

		layouts: function() {

			return [
				{ name: this.off, value: 0 },
				{ name: this.on, value: 1 }
			]

		},

		icon: function() {

			return this.$_.findWhere(this.layouts, {
				value: this.value
			}).icon

		},

		value: function() {

			return this.$store.getters['filter/' + this.filter]

		}

	},

	methods: {

		onToggleClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onOptionClick: function(value) {

			this.$store.commit('filter/' + this.filter, value)

			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

.input-button.has-prefix >>> span {
	font-weight: 500;
	margin-left: 4px;
}

</style>
