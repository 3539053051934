import API from 'core/api'
import CONSTANTS from 'app/constants'
import _ from 'underscore'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		generic: function(context, data) {

			data = data || {}
			data.params = data.params || {}

			return new Promise(function(resolve, reject) {

				API.request(data.url, data.params, true).then(function(json) {

					context.commit('data/users/add', json.users, { root: true })

					// first page, replace users
					if (json.page === 1) {

						context.commit('page/users/set', json.users, { root: true })

					// otherwise paginate
					} else {

						context.commit('page/users/append', json.users, { root: true })

					}

					context.commit('ready', null, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})		

		},

		groups: function(context, params) {

			params.filter = context.rootGetters['filter/users']

			return context.dispatch('generic', {
				params: params,
				url: 'groups/users'
			})	
			
		},

		buddies: function(context, params) {

			params.filter = context.rootGetters['filter/users']

			return context.dispatch('generic', {
				params: params,
				url: 'buddies/users'
			})	
			
		},

		verify: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('users/verify', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		suggest: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('users/suggest', params).then(function(json) {

					context.commit('data/users/add', json.suggestions, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		sync: function(context, params) {

			return new Promise(function(resolve, reject) {

				function call(syncKey) {

					if(syncKey) params.syncKey = syncKey

					API.request('users/sync', params).then(function(json) {

						resolve(json)

					}, function(json) {

						if (json.error=== CONSTANTS.STATUS.ERROR.SYNC.INPROGRESS) {

							_.delay(function() { call(json.syncKey) }, 10000)

						} else {

							reject(json.error)

						}

					})

				}

				call()

			})

		}

	}

}