<template>
	
<div class="card-body" :class="{'is-foot': foot, 'is-padded': padded, 'is-grow': grow}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['foot', 'padded', 'grow']

}

</script>

<style scoped>

.card-body {
	padding: 10px 0px 0px 0px;
	border: 1px solid #ddd;
	border-width: 0px 1px 0px 1px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #fff;
}

.card.is-selected .card-body {
	background-color: #d4f2ff;
}

.card-body.is-grow {
	flex-grow: 1;
}

.card-body.is-foot {
	border-width: 0px 1px 1px 1px;
	border-radius: 0px 0px 4px 4px;
}

.card-body.is-padded {
	padding: 0px 10px 10px 10px;
}

</style>
