<template>

<com-item name="plays.calendar" :size="size" :loading="loading" :is-empty="!active" title="Calendar">

	<template v-slot:stats>

		<app-visualisation-bars :data="graph" v-if="active" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="typeOptions" :value="typeValue" v-on:change="onTypeChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'your', 'you', 'size', 'period'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {
	
		return {
			graph: {
				datasets: [
					{ type: 'bar', data: [], backgroundColor: '#4881bb', tooltip: '{{value}} play{{value.plural}} <small>{{x.label}} {{x.sublabel}}</small>' }
				],
				axis: {
					x: {
						labels: []
					},
					y: {
						min: 10,
						points: 10
					}
				}
			}
		}
	
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'calendar' 

		},

		data: function() {

			return this.$store.getters['page/insights/plays/calendar']

		},

		active: function() {

			return this.data.length && !this.loading

		},

		isYearly: function() {

			return this.period !== 'all'

		},

		typeOptions: function() {

			var options = []

			options.push({value: 'days', text: 'By Day of Week'})
			options.push({value: 'months', text: 'By Month'})
			if (!this.isYearly) options.push({value: 'years', text: 'By Year'})

			return options

		},

		typeValue: function() {

			return this.$store.getters['filter/insights/plays/calendar']

		},

	},

	watch: {

		data: function() {

			this.visualise()

		}

	},

	created: function() {
	
		this.visualise()
	
	},

	methods: {

		onTypeChange: function(value) {

			this.$store.commit('filter/insights/plays/calendar', value)

		},
	
		visualise: function() {
		
			if (this.$_.keys(this.data).length) {

				this.graph.datasets[0].data = []
				this.graph.axis.x.labels = []

				if (this.typeValue === 'days') {
				
					this.graph.axis.x.labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				
				} else if (this.typeValue === 'months') {
				
					this.graph.axis.x.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
				
				} else if (this.typeValue === 'years') {
				
					var min = parseInt(this.$moment.unix(this.$store.getters['context/date/firstPlay']).utc().format('YYYY'))

					var max = parseInt(this.$moment.utc().format('YYYY'))

					for (var i = min; i <= max; i++) {

						this.graph.axis.x.labels.push(i)

					}
				
				}

				this.$_.each(this.data, function(value) {
				
					this.graph.datasets[0].data.push(value)

				}.bind(this))

			}
		
		}
	
	}

}

</script>

<style scoped>

</style>