<template>

<div class="dropdown-list" v-bind:class="{'align-left': align === 'left', 'align-right': align === 'right', 'is-caret': caret, 'is-levels': levels, [themeClass]: theme, 'is-expanded': expanded}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['expanded', 'align', 'caret', 'theme', 'levels'],

	computed: {

		themeClass: function() {

			return 'theme-' + this.theme

		}

	}
	
}

</script>

<style scoped>

.dropdown-list {
	position: absolute;
	top: 56px;
	background-color: #316293;
	padding: 6px 0px;
	min-width: 200px;
	z-index: 100;
	display: none;
	white-space: nowrap;
	transition: all 300ms ease-in-out;
}

.dropdown-list.is-levels {
	width: 220px;
}

.dropdown-list.is-expanded {
	display: block;
}

.dropdown-list.theme-cell {
	right: 0px;
	top: 100%;
}

.dropdown-list.theme-filter {
	right: -1px;
	width: auto;
	top: 48px;
	border-radius: 4px;
	padding: 4px 0px;
	background-color: #316293;
	z-index: 2000;
}

.dropdown-list.theme-head {
	right: -1px;
	width: auto;
	top: 70px;
	border-radius: 4px;
	padding: 4px 0px;
	background-color: #082534;
	z-index: 2000;
}

.is-mobile .dropdown-list.theme-head {
	top: 48px;
}

.dropdown-list.theme-expand {
	right: -1px;
	width: auto;
	top: 40px;
	border-radius: 0px 0px 4px 4px;
	padding: 4px 0px;
	background-color: #fff;
	z-index: 2000;
	text-align: center;
	color: #333;
}

.dropdown-list.theme-expand  >>> .dropdown-item.type-action {
	color: #333;
	text-align: center!important;
}

.is-mobile .dropdown-list.theme-filter {
	top: 144px;
	padding-top: 4px;
	border-radius: 0px;
}

.is-mobile .dropdown-list.theme-expand {
	top: 145px;
	padding-top: 4px;
	right: 0px;
	width: 100%;
}

.is-mobile .dropdown-list:not(.theme-cell) {
	position: fixed;
	left: 0px;
	bottom: 0px;
	z-index: 1000;
	overflow-y: scroll;
}

.dropdown-list.is-caret:after {
	content: ' ';
	position: absolute;
	top: -6px;
	right: 14px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 6px 6px 6px;
	border-color: transparent transparent #316293 transparent;
}

.dropdown-list.theme-head.is-caret:after {
	right: 34px;
	border-color: transparent transparent #082534 transparent;
}

.dropdown-list.theme-cell.is-caret:after {
	right: 18px;
}

.dropdown.align-left {
	left: 0px;
}

.dropdown.align-right {
	right: 0px;
}

@media only screen and (max-width: 767px) {

	.dropdown-list.theme-filter >>> .dropdown-link,
	.dropdown-list.theme-filter >>> .dropdown-item {
		text-align: center;
	}

}

.dropdown-list > .dropdown-item.type-divide:first-child,
.dropdown-list > .dropdown-item.type-divide:last-child {
	display: none;
}

</style>
