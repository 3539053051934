<template>

<div class="bar-center">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.bar-center {
	display: flex;
	justify-content: center;
}

</style>
