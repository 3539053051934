<template>

<core-list-row class="list-row" :id="item.id" :showInfo="!isUser && window.width <= 860">

	<template v-slot:fields>

		<core-list-cell type="avatar" :format="{}">

			<core-avatar type="game" :id="item.game.id" class="list-avatar">

				<app-avatar-include :id="item.game.id" filter="ratings" />

			</core-avatar>

		</core-list-cell>

		<core-list-cell type="name" class="list-name" :format="{filter: 'ratings', sort: 'game.name'}">

			<div class="list-name-game">
				<app-item-change :date="false" :revisions="item.revisions" :showDate="false" :showChange="true" class="list-name-game-tag" v-if="isUser" /> 
				<div class="list-name-game-date" v-if="window.width <= 480 && isUser">{{ ratingDate }}</div>
				<core-gamename :game="item.game" class="list-name-game-name" :id="item.id" />
			</div>

			<div class="list-name-comment-wrapper" v-if="item.comment && !window.is.mobile">

				<div class="list-name-comment" ref="comment" v-html="commentPreview" v-bind:class="{'is-long': longComment}" v-on:click="onMoreClick($event)"></div>

				<div class="list-name-comment-fade" v-if="longComment" v-on:click="onMoreClick($event)"></div>

			</div>

			<div class="list-name-comment-info" v-if="longComment && !window.is.mobile">

				<span class="list-name-comment-info-more" v-on:click="onMoreClick($event)" v-if="longComment">read more</span>

			</div>

			<app-tags :item="item" class="list-name-tags" />

		</core-list-cell>

		<core-list-cell type="custom" class="list-recent" v-if="!isUser && window.width > 860" :format="{filter: 'ratings', sort: 'rating.date'}">

			<app-item-recent v-for="rating in recent" :key="rating.user" :rating="rating" :game="item.game" :ready="ready" />

		</core-list-cell>

		<core-list-cell type="date" v-if="window.width > 480 && isUser" :format="{filter: 'ratings', sort: 'rating.date'}">

			<core-date :date="item.rating.date" invalid="Initial sync" />

		</core-list-cell>

		<core-list-cell type="stat" v-if="window.width > 480" :format="{filter: 'ratings', sort: 'plays'}">

			{{ item.users.totalPlays }}

		</core-list-cell>

		<core-list-cell type="stat" v-if="window.width > 600 && isUser" :format="{filter: 'ratings', sort: 'rating.gap'}">

			<app-item-disparity :value="item.rating.rating" :average="item.game.rating_avg" class="list-disparity" />

		</core-list-cell>

		<core-list-cell type="stat" v-if="window.width > 480 && !isUser" :format="{filter: 'ratings', sort: 'group.divisive'}">

			<core-divisive :score="item.users.divisiveness" :count="item.users.totalRatings" class="list-divisive" />

		</core-list-cell>

		<core-list-cell type="stat" v-if="window.width > 380" :format="{filter: 'ratings', sort: 'rating.revisions'}">

			<template v-if="isUser">

				<app-item-history :history="history" class="list-history" v-if="history.length > 1 || window.width > 48" />

			</template>

			<app-item-history :history="history" :isTrend="true" class="list-history" v-if="(history.length > 1 || window.width > 480) && !isUser" />

		</core-list-cell>

		<core-list-cell type="rating" class="list-rating-cell" v-if="isUser || hasMe" :format="{filter: 'ratings', sort: 'rating.user'}">

			<core-rating :value="item.user.rating_avg" class="list-rating"  />

		</core-list-cell>

		<core-list-cell type="rating" class="list-rating-cell" v-if="!isUser" :format="{filter: 'ratings', sort: 'rating.group'}">

			<core-rating :value="item.group.rating_avg" :ratings="item.users.rated" :showIndividualRatings="true" :canClick="true" :count="item.group.rating_count" class="list-rating" v-on:more="onViewAllClick" />

		</core-list-cell>

		<core-list-cell-actions :item="item" filter="ratings" />

	</template>

	<template v-slot:info>

		<div class="list-info-comment">

			<app-item-recent v-for="rating in recent" :key="rating.user" :rating="rating" :game="item.game" :ready="ready" />

			<div class="list-name-comment-wrapper" v-if="item.comment">

				<div class="list-name-comment" ref="comment" v-html="commentPreview" v-bind:class="{'is-long': longComment}" v-on:click="onMoreClick($event)"></div>

				<div class="list-name-comment-fade" v-if="longComment" v-on:click="onMoreClick($event)"></div>

			</div>

			<div class="list-name-comment-info" v-if="item.comment">

				<span class="list-name-comment-info-more" v-on:click="onMoreClick($event)" v-if="longComment">read more</span>

			</div>

		</div>

	</template>

</core-list-row>

</template>

<script>

import itemHistory from './item/History'
import itemDisparity from './item/Disparity'
import itemChange from './item/Change'
import itemRecent from './item/Recent'

export default {

	props: ['item', 'ready'],

	components: {
		'app-item-history': itemHistory,
		'app-item-disparity': itemDisparity,
		'app-item-change': itemChange,
		'app-item-recent': itemRecent
	},

	data: function() {

		return {

			longComment: false

		}

	},

	watch: {

		ready: function(n) {

			if (n) this.checkComment()

		},

		window: {

			handler: function(n, o) {

				if (n.width !== o.width) {

					this.checkComment()

				}

			},

			deep: true

		}

	},

	mounted: function() {

		this.checkComment()

	},

	computed: {

		history: function() {

			if (this.isUser && this.item.revisions) {

				return (this.item.revisions) ? this.item.revisions.history : []

			} else {

				return this.item.trend || []

			}

		},

		recent: function() {

			return (this.item.recent) ? this.item.recent.slice(0, 1) : []

		},

		commentPreview: function() {

			return (this.item.comment) ? this.item.comment.text.replace(/^\s+|\s+$/g, '') : ''

		},

		commentDate: function() {

			return this.$moment.unix(this.item.comment.date).utc().format(this.$util.date.format('Do', 'MMMM', 'YYYY'))

		},

		ratingDate: function() {

			return this.$moment.unix(this.item.recent[0].date).utc().format(this.$util.date.format('Do', 'MMMM', 'YYYY'))

		}

	},

	methods: {

		checkComment: function() {

			if (this.item.comment) {

				this.$nextTick(function() {
					if (this.$refs.comment) this.longComment = this.$refs.comment.offsetHeight > 48
				}.bind(this))

			}

		},

onMoreClick: function(e) {

			e.stopPropagation()

			this.$pubsub.$emit('panel.open', {
				type: 'ratingsComment',
				game: this.item.game,
				rating: this.item.rating,
				comment: this.item.comment
			})

		},

		onViewAllClick: function(e) {

			if (e) e.stopPropagation()

			this.$pubsub.$emit('expand.open', {
				id: this.item.game.id,
				section: 'ratings'
			})

		}

	}

}

</script>

<style scoped>

.list-avatar {
	width: 48px;
	height: 48px;
}

.list-name {
	min-height: 48px;
}

.list-name-game {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	max-width: 100%;
}

.is-mobile .list-name-game {
	flex-direction: column;
}

.list-name-game-tag {
	flex-shrink: 0;
	margin-top: 0px!important;
	margin-bottom: 1px;
	order: 1;
}

.is-mobile .list-name-game-tag {
	order: 4;
	margin-top: 3px!important;
	margin-bottom: 0px;
}

.list-name-game-tag >>> .change-tag {
	padding: 0px 4px!important;
	line-height: 16px!important;
}

.list-name-game-name {
	flex-grow: 1;
	overflow: hidden;
	min-width: 0;
	display: block;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	overflow: hidden;
	color: #333;
	font-weight: 500;
	order: 2;
}

.is-desktop .list-name-game-name:hover {
	text-decoration: underline;
}

.is-smaller .list-name-game-name,
.is-mobile .list-name-game-name {
	font-size: 14px;
}

.is-mobile .list-name-game-name {
	white-space: normal;
	overflow: visible;
}

.list-name-game-date {
	font-size: 11px;
	margin-top: 2px;
	color: #666;
	order: 3;
}

.list-name-tags {
	margin-top: 2px;
}

.list-info-comment {
	padding-bottom: 8px;
}

.list-name-comment {
	font-size: 14px;
	color: #666;
	line-height: 16px;
	font-weight: 300;
}

.list-name-comment-wrapper {
	max-height: 54px;
	width: 100%;
	overflow: hidden;
}

.list-name-comment >>> br {
	display: none;
}

.list-name-comment-fade {
	position: absolute; 
	bottom: 0; 
	left: 0;
	width: 100%; 
	text-align: center; 
	margin: 0; 
	padding: 16px 0; 
	background-image: linear-gradient(to bottom, transparent, #fff);
}

.is-desktop .list-row:hover .list-name-comment-fade {
	background-image: linear-gradient(to bottom, transparent, #e9f3f7);
}

.list-name-comment-info {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.list-name-comment-info-date {
	font-size: 11px;
	font-weight: 300;
	color: #999;
	flex-grow: 1;
}

.list-name-comment-info-more {
	flex-grow: 0;
	font-size: 12px;
	color: #4881bb;
	font-weight: 400;
	cursor: pointer;
}

.is-desktop .list-name-comment-info-more:hover {
	text-decoration: underline;
}

.list-rating {
	width: 48px;
	height: 48px;
	line-height: 48px;
}

.list-history {
	height: 48px;
}

.list-disparity {
	line-height: 48px;
	font-size: 20px!important;
	height: 48px;
}

.list-info-stats {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	padding: 7px 0px;
	justify-content: spaced-evenly;
}

.list-info-stats-item {
	font-size: 32px;
	text-align: center;
	font-weight: 500;
	color: #666;
	flex-shrink: 0;
	width: 25%;
	padding: 8px 0px;
}

.list-info-stats-item.is-up {
	color: #2FC482;
}

.list-info-stats-item.is-down {
	color: #df4751;
}

.list-info-stats-item span {
	font-size: 18px;
}

.list-info-stats-item > small {
	margin-top: 4px;
	font-size: 12px;
	font-weight: 300;
	color: #333;
	display: block;
}

.list-info-stats-item-history-wrapper {
	height: 32px;
	width: 100%;
}

.list-info-stats-item-history {
	height: 32px;
}

.list-info-stats-item-disparity {
	line-height: 32px;
}

.list-info-stats-date {
	height: 32px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.list-info-stats-date.is-empty {
	align-items: center;
	color: #999;
	font-weight: 400;
	font-size: 14px;
}

.list-info-stats-date-day {
	font-size: 14px;
	color: #333;
	margin-right: 2px;
}

.list-info-stats-date-month {
	font-size: 14px;
	text-transform: uppercase;
	color: #999;
}

.list-info-stats-date-year {
	font-size: 19px;
	width: 100%;
}

.list-divisive {
	font-size: 14px!important;
	font-weight: 400;
}

.list-recent {
	width: 380px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media only screen and (max-width: 1119px) {

	.list-recent {
		width: 320px;
	}

}

@media only screen and (max-width: 960px) {

	.list-recent {
		width: 260px;
	}

}

</style>