import _ from 'underscore'
import Vue from 'vue'

export default {

	namespaced: true,

	modules: {},

	state: {

		data: []

	},

	getters: {

		list: function(state) {

			return _.sortBy(state.data, 'text')

		},

		associated: function(state) {

			var associated = {}

			_.each(state.data, function(item) {

				associated[item.id] = item

			})

			return associated

		},

		keys: function(state) {

			return _.pluck(state.data, 'id')

		}

	},

	mutations: {

		add: function(state, items) {

			_.each(items, function(item) {

				if (!_.contains(_.pluck(state.data, 'id'), item.id)) {

					state.data.push(item)

				}

			})

		},

		update: function(state, items) {

			_.each(items, function(item) {

				var index = _.findIndex(state.data, function(tag) {

					return tag.id === item.id

				})

				if (index !== false) Vue.set(state.data, index, item)

			})

		},

		remove: function(state, items) {

			_.each(items, function(item) {

				var index = _.findIndex(state.data, function(tag) {

					return tag.id === item.id

				})

				if (index !== false) state.data.splice(index, 1)

			})

		},

		set: function(state, items) {

			state.data = items

		}

	},

	actions: {}

}