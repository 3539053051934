<template>

<core-panel>

	<template v-slot:head>

		<h1>Recently played</h1>

		<p>The games that have been most recently removed from the shelf.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in recent" :subtext="subtext(item.date)" :count="item.days" :countText="(item.days === 1) ? 'day' : 'days'" :item="item" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'recent',
			recent: []
		}

	},

	methods: {

		response: function(json) {

			this.recent = json.recent

		},

		subtext: function(date) {

			return 'Played on ' + this.$options.filters.formatDate(date, this.$util.date.format('Do', 'MMMM', 'YYYY'))

		}

	}

}

</script>