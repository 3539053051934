<template>

<div class="group">
	
	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.group {
	flex-shrink: 0;
	padding: 5px 20px;
}

@media only screen and (max-width: 767px) {

	.group {
		padding: 5px 8px;
	}

}

.group >>> h2 {
	font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 15px;
}

.group >>> h2 small {
	display: block;
	font-size: 10px;
    font-weight: 400;
    opacity: 0.75;
    color: #fff;
    margin-top: 3px;
}

.group >>> p {
	font-size: 12px;
	color: #fff;
	line-height: 16px;
	margin-bottom: 15px;
	font-weight: 400;
}

</style>
