<template>
	
<core-list-cell type="stat" :format="format" class="plays" :condensed="condensed" v-bind:class="{'is-summary': summary, 'is-clickable': !summary && !isUser}" v-if="!format.hideMobile || !window.is.mobile">
				
	<span>{{ value }}<span v-if="total" class="plays-total"> ({{ total }})</span></span>

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'format', 'summary', 'condensed'],

	data: function() {

		return {
			value: '',
			total: false
		}

	},

	watch: {

		item: {
			handler: function() {

				this.updateVars()

			},
			deep: true
		}

	},

	created: function() {

		this.updateVars()

	},

	methods: {

		updateVars: function() {

			var value, total

			if (this.format.context === 'filter') {

				if (this.item.context.user || this.item.context.users) {

					value = (this.isUser) ? this.item.context.user.duration : this.item.context.users.totalDuration

					if (this.format.showTotal) {

						total = (this.isUser) ? this.item.user.duration : this.item.users.totalDuration

					}

				} else {

					value = '-'

				}

			} else {

				value = (this.isUser) ? this.item.user.duration : this.item.users.totalDuration

			}

			if (value !== '-' && value > 0) {

				this.value = Math.ceil(value / 60)

			} else {

				this.value = '-'

			}

			if (total) {

				this.total = Math.ceil(total / 60)

			} else {

				this.total = total

			}

		}

	}

}

</script>

<style scoped>

.is-desktop .plays.is-clickable:hover span {
	cursor: pointer;
}

@media only screen and (max-width: 767px) {

	.plays.is-summary {
		display: block;
		font-size: 10px;
		height: 16px;
		width: 54px;
		line-height: 16px;
		text-align: left;
		color: #333;
		font-weight: 400;
	}

}

.plays .fa {
	display: none;
}

@media only screen and (max-width: 767px) {

	.plays.is-summary .fa {
		display: inline-block;
		line-height: 14px;
		color: #666;
		margin-right: 4px;
	}

}

.plays-total {
	color: #666;
	font-size: 11px;
}

</style>
