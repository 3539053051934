<template>

<div class="tool">

	<app-unsupported v-if="!supported" />

	<core-filter subtitle="Playgrid" :sharing="is.sharing" :downloading="is.downloading" :loading="is.fetching" v-if="supported" v-on:share="onShareClick" v-on:download="onDownloadClick" />

	<div class="grid" v-bind:class="{'is-saving': is.fetching, 'has-margin': layout.spacing > 0}" ref="wrapper" v-show="supported && is.ready">

		<div class="grid-status" v-if="is.fetching || !is.ready"></div>

		<div class="grid-canvas" v-bind:style="{padding: margin}" ref="canvas">

			<app-head :layout="layout" :margin="margin" />

			<div v-if="playCount === 0 && !is.fetching && is.ready" class="grid-empty">No plays found.<br/>Try changing your filter settings.</div>

			<template v-for="row in size">

			<div v-bind:key="row" class="grid-row" v-if="(playCount > ((row - 1) * size) && playCount) || is.fetching">

				<template v-for="col in size">

				<app-cell v-bind:key="col" :item="plays[((row - 1) * size) + (col - 1)]" v-if="plays[((row - 1) * size) + (col - 1)]" :layout="layout" />

				</template>

			</div>

			</template>

			<app-foot :layout="layout" />

		</div>

	</div>

</div>

</template>

<script>

import Unsupported from './common/Unsupported'
import Tool from './common/Tool.js'

import Head from './common/Head'
import Foot from './common/Foot'

import Filter from './playgrid/Filter'
import Cell from './playgrid/Cell'

import panzoom from 'panzoom'

export default {

	mixins: [Tool],

	components: {

		'core-filter': Filter,
		'app-cell': Cell,
		'app-unsupported': Unsupported,
		'app-head': Head,
		'app-foot': Foot

	},

	data: function() {

		return {

			panSetup: false,
			grid: 0,
			playCount: 0,
			plays: [],
			toolName: 'playgrid'

		}

	},

	computed: {

		layout: function() {

			return this.$store.getters['filter/tools/playgrid/layout']

		},

		margin: function() {

			return (this.layout.spacing / 2).toString() + 'px'

		},

		count: function() {

			return this.layout.grid * this.layout.grid

		},

		size: function() {

			var size = this.grid

			if (this.grid === 10 && this.playCount) {

				size = Math.ceil(Math.sqrt(this.playCount))

			} 

			return size

		},

		supported: function() {

			return this.is.supported.imageExport

		}

	},

	created: function() {

		this.grid = this.layout.grid

	},

	methods: {

		fetched: function(json) {

			var plays = []

			for (var i = 0; i < this.count; i++) {

				plays[i] = (json.plays[i]) ? json.plays[i] : false

			}

			this.playCount = json.plays.length

			this.plays = plays

			this.grid = this.layout.grid

			if (!this.panSetup) {

				panzoom(this.$refs.wrapper, {
					smoothScroll: false,
					maxZoom: 1,
					minZoom: 0.25,
					beforeWheel: function() {
						return true
					},
					filterKey: function() {
						return true
					}
				})

				this.panSetup = false

			}

		}

	}

}

</script>

<style scoped>

.tool {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	height: calc(100vh - 232px);
}

.grid {
	border: 1px dashed #ccc;
	padding: 4px;
	background-color: #fff;
}

.grid.has-margin {
	padding: 0px;
}

.grid-status {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #024359;
	background-image: url(~core/assets/load.gif);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	z-index: 2;
}

.grid-empty {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	color: #999;
	padding: 32px;
	text-align: center;
	max-width: 256px;
}

.grid-canvas {
	background-color: #fff;
	transition: opacity 300ms ease-in-out;
	min-height: 64px;
}

.grid.is-saving .grid-canvas {
	opacity: 0.1;
}

.grid-row {
	display: flex;
	flex-direction: row;
}

</style>