import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		save: function(context, data) {

			return new Promise(function(resolve, reject) {

				API.request('share/save', data, true).then(function(json) {

					context.commit('ready', null, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})		

		}

	}

}