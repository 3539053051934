<template>

<com-item name="size.turnover" :size="size" :loading="loading" title="Turnover">

	<template v-slot:about>

		<h1>Turnover</h1>

		<p>How quickly {{ you }} turnover games in {{ your }} collection.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-heatmap :data="graph" v-on:click="onClick" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			graph: {
				datasets: [
					{ data: [] }
				],
				axis: {
					y: {
						labels: ['0', '1', '2 - 5', '6 - 10', '11+'],
						sublabels: ['plays', 'play', 'plays', 'plays', 'plays'],
						thresholds: [0, 1, 2, 6, 11]
					},
					x: {
						labels: ['0 - 10', '11 - 100', '101 - 500', '501 - 1000', '1001+'],
						sublabels: ['days', 'days', 'days', 'days', 'days'],
						thresholds: [0, 11, 101, 501, 1001]
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/size/turnover']

		},

		active: function() {

			return this.data && !this.loading

		}

	},

	created: function() {

		this.visualise()

	},

	watch: {

		data: function() {

			this.visualise()

		}

	},

	methods: {

		visualise: function() {

			this.graph.datasets[0].data = []

			this.$_.each(this.data, function(item) {

				this.graph.datasets[0].data.push({
					id: item.g,
					x: item.d, 
					y: item.p
				})

			}.bind(this))

		},

		onClick: function(e) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsSizeTurnover',
				x: e.x,
				y: e.y,
				filter: this.$store.getters['filter/insights/size']
			})

		}

	}

}

</script>

<style scoped>

</style>