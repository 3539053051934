<template>
	
<div class="info">
				
	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.info {
	width: 100%;
}

</style>
