<template>
	
<a :href="$util.bgg.link.game(game.id)" target="_blank" v-on:click="onNameClick" v-tooltip.top-start="'Click to see more details<small>Ctrl / Shift click for BGG page</small>'">{{ name }}</a>

</template>

<script>

export default {

	props: ['id', 'game'],

	computed: {

		name: function() {

			if (this.game.version && !this.game.rename) {

				return (this.game.version.localised) ? this.game.version.localised : this.game.name

			} else {

				return this.game.name

			}

		}

	},

	methods: {

		onNameClick: function(e) {

			if (!e.shiftKey && !e.ctrlKey) {

				e.preventDefault()

				this.$pubsub.$emit('expand.open', {
					id: this.id || this.game.id
				})

			}

		}

	}

}

</script>


