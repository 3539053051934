<template>

<div class="progress" ref="progress" :class="{'is-dark': dark}">

	<div class="progress-value">{{ percent | round }}</div>

</div>

</template>

<script>

import ProgressBar from 'progressbar.js'

export default {

	props: ['percent', 'dark'],

	mounted: function() {

		this.progress = new ProgressBar.Circle(this.$refs.progress, {
			strokeWidth: 6,
			color: '#4a7888',
			duration: 2000,
			easing: 'easeInOut',
			svgStyle: {
				position: 'absolute',
				left: '0px',
				top: '0px'
			}
		})

		var percent = (1 / 100) * this.percent

		percent = (percent > 100) ? 100 : percent

		this.progress.animate(percent)

	}

}

</script>

<style scoped>

.progress {
	width: 48px;
	height: 48px;
	flex-shrink: 0;
}

.progress-value {
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	line-height: 42px;
	margin: 4px;
	background-color: #4881bb;
	border-radius: 24px;
	text-align: center;
	width: 40px;
	height: 40px;
}

</style>
