<template>

<div>

	<slot></slot>

	<core-form-input-textbox name="verifyname" v-on:validate="validate" placeholder="Enter BGG username" v-model="inputs.verifyname" :error="is.error" :disabled="is.success || is.loading" :validation="validation.verifyname" v-on:enter="onVerifyClick"></core-form-input-textbox>

	<core-button v-on:click.native="onVerifyClick" theme="grey" :loading="is.loading" :success="is.success" :disabled="!is.valid">{{ buttonText }}</core-button>

</div>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false,
				success: false,
				error: false
			},

			inputs: {
				verifyname: ''
			},

			validation: {
				verifyname: { exists: true }
			}
			
		}

	},

	computed: {

		buttonText: function() {

			return (this.is.success) ? 'GeekMail sent' : 'Verify'

		}

	},

	methods: {

		onVerifyClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/account/verify', {
					name: this.inputs.verifyname
				}).then(function() {

					this.is.error = false
					this.is.success = true
					this.is.loading = false

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		}

	}

}

</script>

<style scoped>

</style>