<template>

<div class="disparity" v-bind:class="{'is-up': disparity > 0, 'is-down': disparity < 0}">

	<span v-if="disparity < 0">-</span><span v-if="disparity > 0">+</span>{{ disparity | abs | round(1, true) }}

</div>

</template>

<script>

export default {

	props: ['value', 'average'],

	computed: {

		disparity: function() {

			return this.value - this.average

		}

	}

}

</script>

<style scoped>

.disparity {
	font-size: 32px;
	text-align: center;
	font-weight: 500;
	color: #666;
}

.disparity.is-up {
	color: #2FC482;
}

.disparity.is-down {
	color: #df4751;
}

.disparity span {
	font-size: 18px;
	line-height: 18px;
}

</style>