import Util from 'app/util'
import Vue from 'vue'

export default {

	namespaced: true,

	modules: {},

	state: {

		data: {
			config: {}
		}

	},

	getters: {

		all: function(state) {

			return Util.filter.autofill(state.data.config, 'config')

		}

	},

	mutations: {

		set: function(state, value) {

			Vue.set(state.data, 'config', value)

		}

	}

}