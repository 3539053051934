<template>

<com-item name="plays.publishers" :is-list="true" :has-more="true" v-on:more="onPublishersClick" :size="size" :is-empty="!active" :loading="loading" title="Publishers">

	<template v-slot:about>

		<p>Which publishers have the most plays.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<app-person v-for="(item, index) in publishers" :item="item" type="publisher" v-bind:key="index" v-on:more="onMoreClick(item.id)" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		publishers: function() {

			return this.$store.getters['page/insights/plays/publishers']

		},

		active: function() {

			return this.publishers.length && !this.loading

		}

	},

	methods: {

		onPublishersClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysPublishers'
			})

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysPublisher',
				id: id
			})

		}

	}

}

</script>

<style scoped>

</style>