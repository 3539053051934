import columns from './list/columns'
import _ from 'underscore'

export default {

	namespaced: true,

	modules: {
		columns: columns
	},

	state: {

		data: {
			list: false,
			edition: false,
			editions: [],
			highlights: [],
			stats: [],
			games: []
		}

	},

	getters: {

		columns: function(state, getters) {

			return getters['columns/values']

		},

		'games/count': function(state) {

			return state.data.games.length

		},

		edition: function(state) {

			return state.data.edition

		},

		editions: function(state) {

			return state.data.editions

		},

		highlights: function(state) {

			return state.data.highlights

		},

		stats: function(state) {

			return state.data.stats

		},

		list: function(state) {

			return state.data.list

		},

		games: function(state) {

			return state.data.games

		},

		'games/associated': function(state) {

			var associated = {}

			_.each(state.data.games, function(item) {

				associated[item.id] = item

			})

			return associated

		},

		'associated/games': function(state) {

			var associated = {}

			_.each(state.data.games, function(item) {

				associated[item.game.id] = item

			})

			return associated

		},

		'associated/games/all': function(state) {

			var associated = {}

			_.each(state.data.games, function(item) {

				associated[item.game.id] = item

			})

			return associated

		},

		'associated/raw': function(state) {

			var associated = {}

			_.each(state.data.games, function(item) {

				associated[item.id] = item

			})

			return associated

		},

		'associated': function(state) {

			var associated = {}

			_.each(state.data.games, function(item) {

				associated[item.id] = item

			})

			return associated

		},

		'associated/all': function(state) {

			var associated = {}

			_.each(state.data.games, function(item) {

				associated[item.id] = item

			})

			return associated

		}

	},

	mutations: {

		'games/rename': function(state, data) {

			_.each(state.data.games, function(item) {

				if (item.game.id === data.id) {

					item.game.name = data.name

				}

			})

		},

		reset: function(state) {

			state.data = {
				list: false,
				edition: false,
				games: [],
				editions: []
			}

		},

		'list/set': function(state, data) {

			state.data.list = data

		},
		
		'edition/set': function(state, data) {

			state.data.edition = data

		},
		
		'highlights/set': function(state, data) {

			state.data.highlights = data

		},
		
		'stats/set': function(state, data) {

			state.data.stats = data

		},
		
		'editions/set': function(state, data) {

			state.data.editions = data

		},

		'games/set': function(state, data) {

			state.data.games = data

		},

		'games/append': function(state, items) {

			var list = []

			_.each(state.data.games, function(item) {

				list.push(item)

			})

			_.each(items, function(item) {

				list.push(item)

			})

			state.data.games = list

		},

		'games/push': function(state, item) {

			state.data.games.push(item)

		}

	},

	actions: {}

}