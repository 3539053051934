<template>
	
<div class="card-head-ratings">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.card-head-ratings {
	position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
}

</style>
