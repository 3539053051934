<template>

<core-panel>

	<template v-slot:head>

		<h1>Trending</h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" /> trending {{ direction }} {{ period }}.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" v-bind:key="index" :isUserRating="isUser" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'trending',
			games: []
		}

	},

	computed: {

		period: function() {

			if (this.$store.getters['filter/insights/worth/trending/period'] === 'owned') {

				return 'since owned'

			} else {

				return 'over the past ' + this.$store.getters['filter/insights/worth/trending/period'].toString() + ' months'

			}

		},

		direction: function() {

			return (this.$store.getters['filter/insights/worth/trending/direction'] === 'up') ? 'up' : 'down'

		}

	},

	methods: {

		response: function(json) {

			this.games = json.trending

		}

	}

}

</script>