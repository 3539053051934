<template>

<com-item name="ratings.plays" :size="size" :loading="loading" title="Getting Played">

	<template v-slot:about>

		<p>{{ your | capitalise }} average plays per game for each rating band.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-scatter :data="graph" v-on:click="onClick" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			graph: {
				datasets: [
					{ 
						data: [],
						labels: ['1','2','3','4','5','6','7','8','9','10'],
						colors: [
							'#db303b',
							'#db303b',
							'#df4751',
							'#df4751',
							'#5369a2',
							'#5369a2',
							'#1d8acd',
							'#2FC482',
							'#249563',
							'#249563'
						],
						size: 'large'
					}
				],
				axis: {
					points: true,
					x: {
						label: 'Games',
						offset: true
					},
					y: {
						label: 'Avg. Plays',
						offset: true
					}
				}
			}
		}
	
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/plays']

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	created: function() {
	
		this.visualise()	

	},

	methods: {

		onClick: function() {
		
			//

		},
	
		visualise: function() {
		
			this.graph.datasets[0].data = []

			this.$_.each(this.data, function(item) {

				this.graph.datasets[0].data.push({
					id: item.rating,
					x: item.games, 
					y: item.avg,
					tooltip: 'Rating Band: ' + item.rating + ' | Games: ' + item.games + ' | Average Plays: ' + item.avg
				})

			}.bind(this))
		
		}
	
	}

}

</script>

<style scoped>

</style>