
<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary icon="tools" title="Tools" subtitle="Heatmap" :loading="loading" />

	</core-filter-bar-left>

	<core-filter-bar-right>

		<core-button :disabled="sharing || downloading" v-on:click.native="onLayoutClick" tooltip="Customise" v-propagation icon="pencil-ruler" theme="filter" :iconOnly="true" />

		<core-button :loading="sharing" :disabled="loading || downloading" v-on:click.native="$emit('share')" tooltip="Share as image" v-propagation icon="share-alt" theme="filter" :iconOnly="true" />

		<core-button :loading="downloading" :disabled="loading || sharing" v-on:click.native="$emit('download')" tooltip="Download as image" v-propagation icon="download" theme="filter" :iconOnly="true" />

	</core-filter-bar-right>

	<app-layout :active="is.expanded" v-on:close="onClose" />

</core-filter-bar>

</template>

<script>

import Layout from './filter/Layout'

export default {

	props: ['loading', 'sharing', 'downloading'],

	components: {
		'app-layout': Layout
	},

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('tools.heatmap.customise', this.onLayoutClick.bind(this))
		this.$pubsub.$on('tools.heatmap.sharing', this.onClose.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('tools.heatmap.customise', this.onLayoutClick.bind(this))
		this.$pubsub.$off('tools.heatmap.sharing', this.onClose.bind(this))

	},

	methods: {

		onLayoutClick: function() {

			this.is.expanded = !this.is.expanded

			if (this.is.expanded) this.$pubsub.$emit('filter.opened')

		},

		onClose: function() {

			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

</style>
