<template>

<div class="bar-left">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.bar-left {
	display: flex;
	flex-grow: 1;
	justify-content: flex-start;
}

</style>
