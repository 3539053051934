import _ from 'underscore'

export default {

	rename: function(state, data) {

		_.each(state.data, function(item) {

			if (item.game.id === data.id) {

				item.game.name = data.name

			}

		})

	}

}