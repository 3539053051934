<template>

<core-filter-advanced :active="is.expanded" v-on:clickoutside="onOutsideClick" class="automated">

	<template v-slot:inputs>

		<core-filter-advanced-title>Setup</core-filter-advanced-title>

		<app-filter-template-common-source filter="list" />

		<core-filter-advanced-title>Games</core-filter-advanced-title>
	
		<app-filter-template-games-general filter="list" showSort="collection" />

		<app-filter-template-games-count filter="list" />

		<app-filter-template-games-language filter="list" />

		<app-filter-template-games-age filter="list" />

		<app-filter-template-games-include filter="list" />

		<app-filter-template-games-exclude filter="list" />

		<core-filter-advanced-title>Collection</core-filter-advanced-title>

		<app-filter-template-collection-general filter="list" />

		<app-filter-template-collection-status filter="list" v-if="isSourceCollection" />

		<app-filter-template-collection-owners filter="list" v-if="isSourceCollection" />

		<core-filter-advanced-title>Ratings</core-filter-advanced-title>

		<app-filter-template-collection-user filter="list" />
			
		<app-filter-template-collection-group filter="list" />

		<app-filter-template-collection-community filter="list" />
			
		<app-filter-template-collection-comments filter="list" />

		<core-filter-advanced-title>Plays</core-filter-advanced-title>

		<app-filter-template-collection-plays filter="list" :edition="!isLive" :frequency="list.frequency" />
			
		<core-filter-advanced-title>Other</core-filter-advanced-title>
			
		<app-filter-template-common-designers filter="list" />
			
		<app-filter-template-common-artists filter="list" />
			
		<app-filter-template-common-publishers filter="list" />
			
		<app-filter-template-common-categories filter="list" />
			
		<app-filter-template-common-mechanics filter="list" />

		<app-filter-template-common-subdomains filter="list" />

		<app-filter-template-common-families filter="list" />

		<app-filter-template-common-results filter="list" :hideRandom="true" />

	</template>

	<template v-slot:buttons>
	
		<core-button :loading="is.saving" v-on:click.native="onSaveClick" theme="blue" :disabled="!changed" fluid="yes">Save changes</core-button>

		<core-button v-on:click.native="onResetClick" theme="blue" :disabled="!changed" fluid="yes">Reset changes</core-button>
		
		<core-button v-if="window.is.mobile" v-on:click.native="onOutsideClick" theme="blue" fluid="yes">Close filter</core-button>

	</template>

</core-filter-advanced>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				expanded: false,
				saving: false
			}

		}

	},

	computed: {

		totalFilterCount: function() {

			return this.$store.getters['filter/list/count']

		},

		isSourceCollection: function() {

			return this.$store.getters['filter/list/source/collection']

		},

		list: function() {

			return this.$store.getters['page/list/list']

		},

		filter: function() {

			return this.$store.getters['filter/list']

		},

		changed: function() {

			return this.$store.getters['filter/list/changed']

		},

		isLive: function() {

			return this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.LIVE

		}

	},

	created: function() {

		this.$pubsub.$on('list.automated.expand', this.onListAutomatedExpand.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('list.automated.expand', this.onListAutomatedExpand.bind(this))

	},

	methods: {

		onListAutomatedExpand: function() {

			this.is.expanded = true

		},

		onSaveClick: function() {

			this.is.saving = true

			this.$store.dispatch('api/list/filter', {
				filter: this.filter
			}).then(function() {

				this.$pubsub.$emit('list.filter.saved')

				this.is.expanded = false
				this.is.saving = false

			}.bind(this), function() {

				this.is.expanded = false
				this.is.saving = false

			}.bind(this))
			
		},

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onResetClick: function() {

			this.$store.commit('filter/list/reset', this.list.filter)

		}

	}

}

</script>

<style scoped>

.section {
	padding: 15px 15px 7px 15px!important;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.section:last-child {
	border-bottom: 0px;
}

</style>