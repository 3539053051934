<template>
	
	<core-rating v-if="average > 0" :band="band" :count="count" :transparent="true" :ratings="item.users.rated" :showIndividualRatings="false" :canClick="format.context === 'group'" :value="average" :hideCount="format.context === 'user'" :tooltip="tooltip" v-on:more="onRatingsClick" class="rating"></core-rating>

</template>

<script>

export default {

	props: ['item', 'format'],

	computed: {

		tooltip: function() {

			if (this.format.context === 'user') return (this.$store.getters['context/is/group']) ? 'Your rating' : 'User rating'
			if (this.format.context === 'bgg') return 'BGG rating'
			if (this.format.context === 'group') return 'Group rating'
			if (this.format.context === 'buddies') return 'Buddies rating'

			return false

		},

		band: function() {

			if (this.format.context === 'user') return this.item.user.rating_band
			if (this.format.context === 'bgg') return this.item.game.rating_band
			if (this.format.context === 'group') return this.item.group.rating_band
			if (this.format.context === 'buddies') return this.item.group.rating_band

			return false

		},

		count: function() {

			if (this.format.context === 'bgg') return this.item.game.rating_count
			if (this.format.context === 'group') return this.item.group.rating_count
			if (this.format.context === 'buddies') return this.item.group.rating_count

			return 0

		},

		average: function() {

			if (this.format.context === 'user') return this.item.user.rating_avg
			if (this.format.context === 'bgg') return this.item.game.rating_avg
			if (this.format.context === 'group') return this.item.group.rating_avg
			if (this.format.context === 'buddies') return this.item.group.rating_avg

			return false
			
		}

	},

	methods: {

		onRatingsClick: function() {

			if (this.format.context === 'group') {
				
				this.$pubsub.$emit('expand.open', {
					id: this.item.game.id,
					section: 'ratings'
				})

				this.$emit('ratings')

			}

		}

	}

}

</script>

<style scoped>

.rating {
	width: 48px;
	height: 48px;
	line-height: 48px;
	margin-left: 2px;
	font-size: 0;
	flex-shrink: 0;
	cursor: initial;
	color: #fff;
    font-size: 18px;
    font-weight: 500;
}

</style>
