<template>

<core-modal class="settings" v-on:close="onClose" icon="caret-left" :nopadding="true">

	<template v-slot:head>

		Settings

	</template>

	<template v-slot:default>

		<p class="settings-intro">You can manage all your profile settings from this panel. Settings are grouped into the relevant profile sections and may appear in more than one section.</p>

		<app-config-group title="Sync" count="1">

			<app-config-template-sync-plays-incomplete />

		</app-config-group>

		<app-config-group title="Collection" count="5">

			<app-config-template-collection-nest />
			<app-config-template-collection-contains />
			<app-config-template-collection-status />
			<app-config-template-collection-inventory-location-public />
			<app-config-template-other-currency />

		</app-config-group>

		<app-config-group title="Plays" count="3">

			<app-config-template-plays-usernames />
			<app-config-template-sync-plays-incomplete />
			<app-config-template-insights-hindex-expansions  />

		</app-config-group>

		<app-config-group :title="titleShelf" count="3">

			<app-config-template-list-unplayed-type />
			<app-config-template-list-unplayed-rated />
			<app-config-template-list-unplayed-trade />
			<app-config-template-list-unplayed-exclude />

		</app-config-group>

		<app-config-group title="Insights / Collection / Worth" count="1">

			<app-config-template-other-currency />

		</app-config-group>

		<app-config-group title="Insights / Plays" count="3">

			<app-config-template-sync-plays-incomplete />
			<app-config-template-insights-hindex-expansions  />
			<app-config-template-insights-heatmap-start />

		</app-config-group>

		<app-config-group title="Insights / Streaks & Records" count="2">

			<app-config-template-insights-records-start />
			<app-config-template-plays-usernames />

		</app-config-group>

		<app-config-group title="Other" count="1">

			<app-config-template-other-dateformat />

		</app-config-group>

	</template>

	<template v-slot:foot>

		<core-button v-on:click.native="onSaveClick" :disabled="!is.changed" :loading="is.loading" theme="blue">Save & apply</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				loading: false,
				changed: false
			}

		}

	},

	computed: {

		titleShelf: function() {

			return (this.current.lists.unplayed.type === 'shame') ? 'Lists / Shelf of Shame' : 'Lists / Shelf of Opportunity'

		},

		filter: function() {

			return this.$store.getters['filter/config']

		},

		current: function() {

			return this.$store.getters['session/config']

		}

	},

	watch: {

		filter: {

			handler: function() {

				this.is.changed = this.$store.getters['filter/config/changed']

			},

			deep: true

		},

		current: {

			handler: function(n) {

				this.$store.commit('filter/config/set', n)

			},

			deep: true

		}

	},

	methods: {

		onSaveClick: function() {

			this.is.loading = true

			this.$store.dispatch('api/config/save', {
				data: this.$store.getters['filter/config']
			}).then(function(json) {

				this.$store.commit('session/config/set', json.config)
				this.$store.commit('filter/config/reset', json.config)

				this.is.loading = false
				this.is.changed = false

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this)) 

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.settings-intro {
	font-weight: 400!important;
	padding: 0px 15px 15px 15px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

</style>