<template>
	
<core-list-head :fixed="fixed">

	<core-list-column type="name" :format="{filter: 'ratings', sort: 'game.name'}" class="column-game">Game</core-list-column>
	<core-list-column type="custom" :format="{filter: 'ratings', sort: 'rating.date'}" v-if="!isUser && window.width > 860" class="list-recent">Most recent rating</core-list-column>
	<core-list-column type="date" :format="{filter: 'ratings', sort: 'rating.date'}" v-if="window.width > 480 && isUser">Rated</core-list-column>
	<core-list-column type="stat" :format="{filter: 'ratings', sort: 'plays'}" v-if="window.width > 480">Plays</core-list-column>
	<core-list-column type="stat" :format="{filter: 'ratings', sort: 'rating.gap'}" v-if="window.width > 600 && isUser">vs BGG</core-list-column>
	<core-list-column type="stat" :format="{filter: 'ratings', sort: 'group.divisive'}" v-if="!isUser && window.width > 480">Divisiveness</core-list-column>
	<core-list-column type="stat" :format="{filter: 'ratings', sort: 'rating.revisions'}" v-if="window.width > 480">{{ historyName }}</core-list-column>
	<core-list-column type="rating" :format="{filter: 'ratings', sort: 'rating.user'}" v-if="isUser || hasMe">{{ userRatingName }}</core-list-column>
	<core-list-column type="rating" :format="{filter: 'ratings', sort: 'rating.group'}" v-if="!isUser">{{ groupRatingName }}</core-list-column>
	<core-list-column-actions />

</core-list-head>

</template>

<script>

export default {

	props: ['fixed'],

	computed: {

		userRatingName: function() {

			return (this.isSelf || this.hasMe) ? 'You' : 'User'

		},

		groupRatingName: function() {

			return (this.isGroup) ? 'Group' : 'Buddies'

		},

		historyName: function() {

			return (this.isUser) ? 'History' : 'Trend'

		}

	}

}

</script>

<style scoped>

.column-game {
	margin-left: 48px!important;
	padding-left: 8px!important;
}

.is-mobile .column-game {
	margin-left: 0px!important;
	padding-left: 0px!important;
}

.list-recent {
	width: 380px;
}

@media only screen and (max-width: 1119px) {

	.list-recent {
		width: 320px;
	}

}

@media only screen and (max-width: 960px) {

	.list-recent {
		width: 260px;
	}

}

</style>