<template>
	
<div class="column" :sortIndex="(sortingCount > 1 || format.sort !== 'name' || isSortedReverse) ? isSorted : 0" v-tooltip="tooltip" v-on:click="onClick" v-bind:class="{'is-sortable': format.filter, 'is-sorted': isSorted, [typeClass]: type}" v-if="!format.hideMobile || !window.is.mobile">
				
	<span><slot></slot></span>

	<i v-if="format.icon" :class="'fa fa-' + format.icon"></i>

	<div class="column-index" v-if="isSorted && sortingCount > 1">{{ isSorted }}</div>

	<i v-if="format.filter || format.sorted" class="column-sort fa" v-bind:class="{'fa-caret-down': isSortedReverse || format.sortReverse, 'fa-caret-up': (!isSortedReverse && !format.sorted) || (!format.sortReverse && format.sorted)}" v-show="isSorted || format.sorted"></i>

</div>

</template>

<script>

export default {

	props: ['format', 'type'],

	computed: {

		sortingCount: function() {
		
			return this.$util.sortcount((this.format.filter) ? this.$store.getters['filter/' + this.format.filter] : false)

		},

		tooltip: function() {
		
			return (this.format.sort) ? 'Click to sort<small>Shift-click to stack sort</small>' : false

		},

		isSorted: function() {

			return this.$util.sorted(this.format.sort, (this.format.filter) ? this.$store.getters['filter/' + this.format.filter] : false) 

		},

		isSortedReverse: function() {

			return this.$util.sorted(this.format.sort, (this.format.filter) ? this.$store.getters['filter/' + this.format.filter] : false, true) 

		},

		typeClass: function() {

			return (this.type) ? 'type-' + this.type : false

		}

	},

	methods: {

		onClick: function(e) {

			if (this.format.filter) this.$store.commit('filter/' + this.format.filter + '/sort', {
				value: this.format.sort,
				stack: e.shiftKey
			})

		}

	}

}

</script>

<style scoped>

.column {
	font-size: 12px;
	height: 32px;
	line-height: 12px;
	color: #333;
	font-weight: 500;
	display: flex;
	align-items: center;
	user-select: none;
}

.column.is-sortable {
	cursor: pointer;
}

.is-desktop .column.is-sortable.is-sorted[sortIndex="0"]:hover,
.is-desktop .column.is-sortable:not(.is-sorted):hover {
	background-color: #eee;
}

.column.type-stat {
	width: 80px;
	justify-content: center;
	flex-shrink: 0;
}

.is-mobile .column.type-stat {
	width: 64px;
}

.column.type-rating {
	width: 64px;
	flex-shrink: 0;
	justify-content: center;
	padding: 0px 8px;
}

.is-mobile .column.type-rating {
	margin-left: 1px;
	width: 48px;
	padding: 0px;
}

.column.type-date {
	width: 80px;
	flex-shrink: 0;
	justify-content: center;
}

.is-mobile .column.type-date {
	width: 64px;
}

.column.type-name {
	flex-grow: 1;
	padding-left: 8px;
}

.column.type-avatar {
	width: 48px;
	flex-shrink: 0;
}

.column.type-actions {
	width: 48px;
}

.column-sort {
	position: absolute;
	right: 4px;
	top: 50%;
	font-size: 16px;
	margin-top: -6px;
}

.column-sort.fa-caret-down {
	margin-top: -8px;
}

.column .fa-language {
	font-size: 22px;
}

.column-index {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.25);
	border-radius: 0px;
	left: -6px;
	border-radius: 4px;
	top: -6px;
	position: absolute;
	font-size: 12px;
	font-weight: 500;
	width: 16px;
	height: 16px;
	text-align: center;
	line-height: 16px;
}

.column:not([sortIndex="0"]) {
	color: #fff;
}

.column[sortIndex="1"] {
	background-color: #61c7ff;
}

.column[sortIndex="1"] .column-index {
	background-color: #3a7fa6;
}

.column[sortIndex="2"] {
	background-color: #8be356;
}

.column[sortIndex="2"] .column-index {
	background-color: #5fa336;
}

.column[sortIndex="3"] {
	background-color: #ed5353;
}

.column[sortIndex="3"] .column-index {
	background-color: #b53333;
}

.column[sortIndex="4"] {
	background-color: #ed53df;
}

.column[sortIndex="4"] .column-index {
	background-color: #c135b4;
}

.column[sortIndex="5"] {
	background-color: #ff9d3b;
}

.column[sortIndex="5"] .column-index {
	background-color: #dc7e20;
}

</style>
