<template>

<core-filter-bar>

	<core-filter-bar-left>

		<core-filter-summary title="Plays" icon="dice-three" :count="count" noun="result" :maximum="maximum" :loading="loading"></core-filter-summary>

	</core-filter-bar-left>

	<core-filter-bar-right>

		<core-filter-toggle page="plays" filter="plays/play/group" prefix="View by" on="Game" off="Play" />

		<core-filter-sort page="plays" filter="plays/sort" />

		<core-filter-layout page="plays" filter="config/plays/layout" />

		<core-filter-panel filter="plays" v-on:expandClick="onExpandClick" />

		<core-filter-presets type="plays" :hideButton="window.width < 400" />

		<com-config v-on:refresh="$emit('refresh')" />
		
		<core-filter-actions type="plays">

			<core-dropdown-item type="link" v-if="window.width < 400" v-on:click.native.stop="$pubsub.$emit('presets.open')">Presets</core-dropdown-item>
			<core-dropdown-item type="link" v-if="window.width < 400" v-on:click.native.stop="$pubsub.$emit('plays.expand')">Settings</core-dropdown-item>

		</core-filter-actions>

	</core-filter-bar-right>

	<core-filter-advanced :active="is.expanded" v-on:clickoutside="onOutsideClick">

		<template v-slot:inputs>

			<core-filter-input-text filter="collection/text" :inline="true" />

			<core-filter-advanced-title>Plays</core-filter-advanced-title>

			<app-filter-template-plays-general filter="plays" :grouped="filter.play.group" />

			<app-filter-template-plays-plays filter="plays" v-if="filter.play.group" />

			<app-filter-template-plays-players filter="plays" />

			<app-filter-template-plays-locations filter="plays" />
			
			<app-filter-template-plays-reports filter="plays" />

			<app-filter-template-common-tags filter="plays" />

			<core-filter-advanced-title>Games</core-filter-advanced-title>

			<app-filter-template-games-general filter="plays" :hideExpansion="true" />

			<app-filter-template-games-subset filter="plays" />

			<app-filter-template-games-include filter="plays" />

			<app-filter-template-games-exclude filter="plays" />

			<core-filter-advanced-title>Collection</core-filter-advanced-title>

			<app-filter-template-collection-general filter="plays" />

			<core-filter-advanced-title>Ratings</core-filter-advanced-title>

			<app-filter-template-collection-user filter="plays" />

			<app-filter-template-collection-group filter="plays" />

			<app-filter-template-collection-community filter="plays" />

			<app-filter-template-collection-comments filter="plays" />

			<core-filter-advanced-title>Other</core-filter-advanced-title>

			<app-filter-template-common-users filter="plays" />

			<app-filter-template-common-designers filter="plays" />
			
			<app-filter-template-common-artists filter="plays" />
			
			<app-filter-template-common-publishers filter="plays" />
			
			<app-filter-template-common-categories filter="plays" />
			
			<app-filter-template-common-mechanics filter="plays" />
			
			<app-filter-template-common-subdomains filter="plays" />

			<app-filter-template-common-families filter="plays" />

		</template>

		<template v-slot:buttons>
	
			<core-button v-on:click.native="onResetClick" theme="blue" fluid="yes">Reset filter</core-button>

			<core-button v-if="window.is.mobile" v-on:click.native="onOutsideClick" theme="blue" fluid="yes">Close filter</core-button>

		</template>

	</core-filter-advanced>

	<app-filter-panel-tags type="plays" />

</core-filter-bar>

</template>

<script>

import config from './Config'

export default {

	props: ['count', 'loading', 'gameMode', 'maximum'],

	components: {

		'com-config': config

	},

	data: function() {

		return {

			is: {
				expanded: false
			}

		}

	},

	computed: {

		noun: function() {

			return (this.gameMode) ? 'game' : 'play'

		},

		filter: function() {

			return this.$store.getters['filter/plays']

		}

	},

	methods: {

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onResetClick: function() {

			this.$store.commit('filter/plays/reset', {
				layout: this.filter.layout,
				play: {
					group: this.filter.play.group
				}
			})

		}

	}

}

</script>

<style scoped>

.filter-search-mobile {
	margin: 4px 8px 0px 8px!important;
	width: calc(100% - 16px)!important;
}

</style>
