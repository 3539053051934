<template>

<core-config-section :locked="!loggedin">

	<h2>Inventory Location <small>Applies to your collection</small></h2>

	<p>If you want your inventory locations to be seen by other people viewing your collection you can make it public.</p>

	<div class="inputs">

		<core-filter-input-toggle text="Make public" on="Yes" off="No" filter="config/collection/inventory/location/public" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>