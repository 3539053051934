<template>
	
<div class="drag drag-handle" v-tooltip="'Drag and drop to re-order'">

	<i class="fa fa-sort"></i>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.drag {
	width: 24px;
	min-width: 24px;
	height: 48px;
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	color: #ccc;
	font-size: 24px;
	flex-shrink: 0;
	flex-direction: column;
}

.is-desktop .drag:hover {
	color: #333;
}

</style>
