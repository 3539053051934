<template>

<com-item name="records.overview" :size="size" :is-overview="3" :loading="loading" :is-empty="!active" title="Overview">

	<template v-slot:stats>

		<com-overview label="Longest win streak" :value-label="data.streaks.win.text" type="number" :value="data.streaks.win.value" :link="playsLink(data.streaks.win.plays)" tooltip="Click to view plays" />

		<com-overview label="Longest loss streak" :value-label="data.streaks.loss.text" type="number" :value="data.streaks.loss.value" :link="playsLink(data.streaks.loss.plays)" tooltip="Click to view plays" />

		<com-overview label="Longest play streak" :value-label="data.streaks.play.text" type="number" :value="data.streaks.play.value" :link="playsLink(data.streaks.play.plays)" tooltip="Click to view plays" />

		<com-overview label="Longest play drought" :value-label="data.streaks.drought.text" type="number" :value="data.streaks.drought.value" :link="playsLink(data.streaks.drought.plays)" tooltip="Click to view plays" />

		<com-overview label="Win Percentage" type="percent" :value="data.stats.overall.value" />

		<com-overview label="Most plays in a day" :value-label="data.stats.day.text" type="number" :value="data.stats.day.value" :link="playsLink(data.stats.day.plays)" tooltip="Click to view plays" />

		<com-overview label="Most 1st plays in day" :value-label="data.stats.new.text" type="number" :value="data.stats.new.value" :link="playsLink(data.stats.new.plays)" tooltip="Click to view plays" />

	</template>

</com-item>

</template>

<script>

import comOverview from './../common/Overview'
import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-overview': comOverview
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/records/summary']

		},

		active: function() {

			return true

		}

	},

	methods: {

		playsLink: function(plays) {

			return {
				name: 'Plays',
				query: {
					sortReverse: 0,
					'play.subset': plays.join(',')
				}
			}

		}

	}

}

</script>

<style scoped>

</style>