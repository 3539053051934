<template>
	
<core-card-foot-stat :value="value" text="Weight" :raw="true" :invalid="value === 0" />

</template>

<script>

import wNumb from 'wnumb'

export default {

	props: ['format', 'item', 'filter'],

	computed: {

		value: function() {

			return wNumb({
				'decimals': 2
			}).to(this.item.game.weight_avg)

		}

	}

}

</script>

<style scoped>

</style>
