<template>

<core-filter-advanced-group :title="title" :count="count" v-if="isGroup || isBuddies">

	<core-filter-input-range text="Min. votes" min="0" max="20" :tooltip="true" :filter="value('threshold/group')" step="1" pips="2" decimals="0" density="20" />

	<core-filter-input-range text="Rating" min="0" max="10" :tooltip="true" :filter="value('rating/group')" step="0.1" pips="1" decimals="1" density="9" :toggle="true" />

	<core-filter-input-range text="BGG disparity" :min="-10" :max="10" :tooltip="true" :filter="value('disparity/group/community')" decimals="0" step="1" pips="2" density="10" :toggle="true" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['threshold.group', 'rating.group', 'disparity.group.community']
		}
	},

	computed: {

		title: function() {

			return ((this.$store.getters['context/is/group']) ? 'Group' : 'Buddy') + ' ratings'

		}

	}

}

</script>