<template>

<div class="content-scrollload" v-observe-visibility="onVisibilityChange">

	<i class="content-scrollload-icon fa fa-spinner fa-spin"></i>

	<slot></slot>

</div>

</template>

<script>

export default {

	methods: {
	
		onVisibilityChange: function(visible) {
		
			if (visible) this.$emit('scrolled')
		
		}
	
	}

}

</script>

<style scoped>

.content-scrollload {
	height: 40px;
	line-height: 48px;
	width: 100%;
	text-align: center;
}

.is-cards .content-scrollload {
	height: 24px;
	margin-top: 20px;
	line-height: 24px;
	grid-column: span 3;
}

.is-tablet .content-scrollload {
	grid-column: span 2;
}

.is-mobile .content-scrollload {
	grid-column: span 1;
}

.content-scrollload-icon {
	font-size: 24px;
	color: #ffc563;
}

</style>
