<template>

<core-panel>

	<template v-slot:head>

		<h1>Gathering dust</h1>

		<p>The games that have been sitting on the shelf for the longest.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in longest" :subtext="subtext(item)" :tags="tags(item)" :count="days(item)" :countText="daysText(item)" :item="item" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'longest',
			longest: [],
			params: ['longest']
		}

	},

	methods: {

		response: function(json) {

			this.longest = json.longest

		},

		subtext: function(game) {

			if (this.data.longest === this.$CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL) {

				if (!game.to && !game.played) {

					return this.$options.filters.formatDate(game.from, this.$util.date.format('Do', 'MMMM', 'YYYY'))

				} else {

					return [
						this.$options.filters.formatDate(game.from, this.$util.date.format('Do', 'MMM', 'YYYY')),
						'-',
						this.$options.filters.formatDate((game.to) ? game.to : game.played, this.$util.date.format('Do', 'MMM', 'YYYY'))
					].join(' ')

				}

			} else {

				return 'Since ' + this.$options.filters.formatDate(game.from, this.$util.date.format('Do', 'MMMM', 'YYYY'))

			}

		},

		days: function(game) {

			if (this.data.longest === this.$CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL) {
				
				if (game.to) {

					return this.$options.filters.daysBetween(game.from, game.to)

				} else {

					return this.$options.filters.daysSince(game.from)

				}

			} else {

				return this.$options.filters.daysSince(game.from)

			}

		},

		daysText: function(game) {

			return (this.days(game) === 1) ? 'day' : 'days'

		},

		tags: function(game) {

			var tags = []

			if (this.data.longest === this.$CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL) {

				if (game.played) {

					tags.push({
						blue: true,
						text: 'Played'
					})

				} else if (game.to) {

					tags.push({
						red: true,
						text: 'Removed'
					})

				} else {

					tags.push({
						green: true,
						text: 'Current'
					})

				}

			} else {

				tags = false

			}

			return tags

		}

	}

}

</script>