<template>

<com-item name="worth.games" :is-cards="true" :size="size" :empty-cards="empty" :has-more="true" v-on:more="onMoreClick" :is-empty="!active" :loading="loading" title="Games">

	<template v-slot:about>

		<p v-if="gamesValue === $CONSTANTS.INSIGHTS.WORTH.GAMES.MOST">{{ your | capitalise }} most valuable games.</p>
		<p v-if="gamesValue === $CONSTANTS.INSIGHTS.WORTH.GAMES.LEAST">{{ your | capitalise }} least valuable games.</p>

	</template>

	<template v-slot:stats>

		<com-card :item="item" v-for="item in games" :key="item.g" :id="item.g" :rating="item.r" :name="$store.getters['data/games/associated'][item.g].name" :count="item.p" count-noun="play">

			<div class="games-item-content-stats-plays" v-html="formatcurrency(item.w, decimals)"></div>

		</com-card>

	</template>

	<template v-slot:buttons>

		<com-toggle :options="gamesOptions" :value="gamesValue" v-on:change="onGamesChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'
import comCard from './../common/Card'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle,
		'com-card': comCard
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'games'

		},

		gamesOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.GAMES.MOST, text: 'Most valuable'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.GAMES.LEAST, text: 'Least valuable'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.GAMES.HIGH, text: 'Highest per play'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.GAMES.LOW, text: 'Lowest per play'})

			return options

		},

		gamesValue: function() {

			return this.$store.getters['filter/insights/worth/games']

		},

		isPerPlay: function() {

			return this.$store.getters['filter/insights/worth/games'] === 'lowest' || this.$store.getters['filter/insights/worth/games'] === 'highest'

		},

		decimals: function() {

			return (this.isPerPlay) ? 2 : 0

		},

		games: function() {

			return this.$store.getters['page/insights/worth/games']

		},

		empty: function() {

			var empty = []

			for (var i = this.games.length; i < 5; i++) {

				empty.push(true)

			}

			return empty.length

		},

		active: function() {

			return this.games.length && !this.loading

		}

	},

	methods: {
	
		onGamesChange: function(value) {

			this.$store.commit('filter/insights/worth/games', value)

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthGames',
				filter: this.$store.getters['filter/insights/worth']
			})

		}

	}

}

</script>

<style scoped>

.games-item-content-stats-plays {
	font-size: 32px;
	padding-left: 10px;
	color: #333;
	font-weight: 500;
}

.games-item-content-stats-plays >>> span.currency {
	font-size: 18px;
	margin-right: 1px;
	opacity: 0.75;
}

.games-item-content-stats-plays small {
	font-size: 16px;
	font-weight: 400;
	opacity: 0.5;
}

</style>