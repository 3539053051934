<template>

<core-filter-config v-on:expand="onExpandClick" v-on:close="onCloseClick" v-on:save="onSaveClick" v-if="active" :state="is" :loginRequired="true" :selfOnly="true">

	<app-config-template-list-unplayed-type />

	<app-config-template-list-unplayed-rated />

	<app-config-template-list-unplayed-trade />

	<app-config-template-list-unplayed-exclude />

</core-filter-config>

</template>

<script>

import Config from 'core/mixin/Config.js'

export default {

	mixins: [Config],

	data: function() {

		return {

			name: 'insights.shelf'
			
		}

	},

	computed: {

		active: function() {

			return this.$route.name === 'InsightsShelf' 

		}

	},

	methods: {

		onAfterSave: function() {

			this.$emit('refresh')

		}

	}

}

</script>