<template>

<div class="title">
	
	<span><slot></slot></span>
	
</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
	padding: 10px 20px;
}

.title span {
    background-color: #2b5870;
    display: inline-block;
    z-index: 2;
    position: relative;
	min-width: 80px;
	padding-right: 15px;
}

.title:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    left: 20px;
    right: 20px;
    top: 50%;
    z-index: 1;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.is-mobile .title {
	padding-left: 8px;
}

</style>
