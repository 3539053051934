import BaseUtil from 'core/util'
import deepmerge from 'deepmerge'
import store from './store'

export default deepmerge(BaseUtil, {

	sortcount: function(filter) {

		if(!filter) return 0
	
		var sorts = filter.sort

		sorts = (sorts === false) ? [] : sorts.split(',')

		return sorts.length
	
	},
	
	copy: function(value) {

        return JSON.parse(JSON.stringify(value || '{}'))
        
    },

	sorted: function(name, filter, reverseOnly) {

		if(!filter) return 0

		var sorts = filter.sort
		var sortReversed = filter.sortReverse
	
		sorts = (sorts === false) ? [] : sorts.split(',')
		reverseOnly = reverseOnly || false

		var reverseIndex = sorts.indexOf('-' + name)
		var normalIndex = sorts.indexOf(name)

		if (reverseIndex >= 0) return reverseIndex + 1
		if (sorts.length === 1 && sortReversed && sorts[0] === name) return 1
		if (normalIndex >= 0 && !reverseOnly) return normalIndex + 1

		return 0

	},

	date: {
		format: function(dayFormat, monthFormat, yearFormat) {

			dayFormat = dayFormat || ''
			monthFormat = monthFormat || ''
			yearFormat = yearFormat || ''

			var format = store.getters['session/config'].other.date

			format = format.replace(/\//g, ' ')
			format = format.replace('DD', dayFormat)
			format = format.replace('MM', monthFormat)
			format = format.replace('YYYY', yearFormat)

			return format.trim()

		}
	},

	bgg: {

		open: {

			game: function(id) {

				window.open('https://www.boardgamegeek.com/boardgame/' + id.toString())

			}

		},

		link: {

			game: function(id) {

				return 'https://www.boardgamegeek.com/boardgame/' + id.toString()

			}
			
		}

	},

	filter: {

		templates: {

			base: function(data) {

				var filled = {}

				data = data || {}

				if (data.commented === 1) data.commented = 'yes'

				var expansions = data.expansions || 1
				expansions = (data.expansions === 0 || data.expansions === false) ? 0 : expansions

				filled.text = data.text || ''
				filled.owners = data.owners || []
				filled.users = data.users || []
				filled.expansions = expansions
				filled.ownersmin = data.ownersmin || 0
				filled.unplayed = data.unplayed || 0
				filled.soloplayed = data.soloplayed || 0
				filled.unrated = data.unrated || 0
				filled.commented = data.commented || 0

				filled.limit = data.limit || 0
				filled.random = data.random || 0

				data.source = data.source || {}

				filled.source = {
					all: data.source.all || false,
					collection: data.source.collection || false,
					plays: data.source.plays || false,
					ratings: data.source.ratings || false,
					list: data.source.list || false,
					other: data.source.other || []
				}

				data.play = data.play || {}
				data.play.players = data.play.players || {}
				data.play.reports = data.play.reports || {}

				filled.play = {
					status: data.play.status || 0,
					last: data.play.last || 0,
					subset: data.play.subset || [],
					group: data.play.group || 0,
					first: data.play.first || 0,
					nolocation: data.play.nolocation || 0,
					locations: data.play.locations || [],
					count: data.play.count || {from: 1, to: 10},
					reports: {
						required: data.play.reports.required || 0,
						text: data.play.reports.text || ''
					},
					players: {
						required: data.play.players.required || [],
						together: data.play.players.together || 0
					},
					users: {
						from: 0,
						to: 100
					},
					winpercent: {
						from: 0,
						to: 100
					},
					duration: data.play.duration || {from: 0, to: 360}
				}

				data.wishlist = data.wishlist || {}

				filled.wishlist = {
					priority: data.wishlist.priority || {from: 1, to: 5}
				}

				data.status = data.status || {}

				filled.status = {
					merge: data.status.merge || 0,
					user: data.status.user || 0, 
					game: data.status.game || 0, 
					own: (data.status.own !== undefined) ? data.status.own : 1,
					prevowned: data.status.prevowned || 0,
					fortrade: data.status.fortrade || 0,
					want: data.status.want || 0,
					wanttoplay: data.status.wanttoplay || 0,
					wanttobuy: data.status.wanttobuy || 0,
					wishlist: data.status.wishlist || 0,
					preordered: data.status.preordered || 0,
					hasparts: data.status.hasparts || 0,
					wantparts: data.status.wantparts || 0
				}

				data.trade = data.trade || {}

				filled.trade = {
					type: data.trade.type || 0,
					status: data.trade.status || 'want'
				}

				data.count = data.count || {}

				filled.count = {
					exact: data.count.exact || 0,
					official: data.count.official || {from: 1, to: 10},
					recommended: data.count.recommended || {from: 1, to: 10},
					best: data.count.best || {from: 1, to: 10}
				}

				data.rating = data.rating || {}

				filled.rating = {
					user: data.rating.user || {from: 0, to: 10},
					group: data.rating.group || {from: 0, to: 10},
					community: data.rating.community || {from: 0, to: 10}
				}

				data.disparity = data.disparity || {}

				filled.disparity = {}

				data.disparity.user = data.disparity.user || {}

				filled.disparity.user = {
					group: data.disparity.user.group || {from: -10, to: 10},
					community: data.disparity.user.community || {from: -10, to: 10},
				}

				data.disparity.group = data.disparity.group || {}

				filled.disparity.group = {
					community: data.disparity.group.community || {from: -10, to: 10},
				}

				data.comment = data.comment || {}

				filled.comment = {
					text: data.comment.text || ''
				}
				
				filled.comments = data.comments || {from: 0, to: 50}

				filled.year = data.year || {from: 1970, to: 2030}
				filled.weight = data.weight || {from: 0, to: 5}
				filled.duration = data.duration || {from: 0, to: 360}
				filled.plays = data.plays || {from: 0, to: 100}
				filled.rank = data.rank || {from: 0, to: 1000}

				data.durations = data.durations || {}

				filled.durations = {
					absolute: data.durations.absolute || 0
				}

				data.games = data.games || {}

				filled.games = {
					subset: data.games.subset || [],
					include: data.games.include || [],
					exclude: data.games.exclude || []
				}

				data.played = data.played || {}

				filled.played = {
					type: data.played.type || 'sometime',
					period: data.played.period || 'custom',
					range: data.played.range || {from: 0, to: 0},
					first: data.played.first || {from: false, to: false, edition: false},
					last: data.played.last || {from: false, to: false, edition: false},
					sometime: data.played.sometime || {from: false, to: false, edition: false}
				}

				data.inventory = data.inventory || {}
				data.inventory.location = data.inventory.location || {}

				filled.inventory = {
					location: {
						text: data.inventory.location.text || '',
						exists: data.inventory.location.exists || 0
					}
				}

				data.collection = data.collection || {}
				data.collection.exclude = data.collection.exclude || {}

				filled.collection = {
					added: data.collection.added || {from: 0, to: 0},
					exclude: {
						own: data.collection.exclude.own || 0,
						prevowned: data.collection.exclude.prevowned || 0
					}
				}

				data.rated = data.rated || {}

				data.rated.date = data.rated.date || {}

				filled.rated = {
					divisive: data.rated.divisive || {from: 0, to: 2},
					date: {
						group: data.rated.date.group || {from: 0, to: 0},
						user: data.rated.date.user || {from: 0, to: 0}
					}
				}

				data.threshold = data.threshold || {}

				filled.threshold = {
					group: (data.threshold.group !== undefined) ? data.threshold.group : 0,
					community: data.threshold.community || 0,
					plays: data.threshold.plays || 0,
					owners: data.threshold.owners || 0,
					weight: data.threshold.weight || 0,
					count: data.threshold.count || 0,
					language: data.threshold.language || 0,
					hours: data.threshold.hours || 0,
					age: data.threshold.age || 0,
					notrecommended: data.threshold.notrecommended || 0
				}

				data.worth = data.worth || {}

				filled.worth = {
					value: data.worth.value || {from: 0, to: 200},
					play: data.worth.play || {from: 0, to: 200}
				}

				data.age = data.age || {}

				filled.age = {
					official: data.age.official || {from: 2, to: 21},
					community: data.age.community || {from: 2, to: 21}
				}

				data.language = data.language || {}

				filled.language = {
					dependency: data.language.dependency || {from: 1, to: 5}
				}
				
				filled.designers = data.designers || []
				filled.publishers = data.publishers || []
				filled.artists = data.artists || []
				filled.mechanics = data.mechanics || []
				filled.subdomains = data.subdomains || []
				filled.categories = data.categories || []
				filled.families = data.families || []
				filled.languages = data.languages || []
				filled.sort = data.sort || 'name'
				filled.sortReverse = data.sortReverse || 0

				filled.tags = data.tags || []

				return filled

			},

			ratings: function(data) {

				data = data || {}
				
				var filled = this.base(data)

				filled.sort = data.sort || 'rating.date'
				filled.sortReverse = (data.sortReverse === 0 || data.sortReverse === false) ? 0 : 1

				return filled

			},

			plays: function(data) {

				data = data || {}
				
				var filled = this.base(data)
				
				filled.sort = data.sort || 'play.date'
				filled.sortReverse = (data.sortReverse === 0 || data.sortReverse === false) ? 0 : 1

				return filled

			},

			collection: function(data) {

				return this.base(data)

			},

			list: function(data) {

				return this.base(data)

			},

			users: function(data) {

				var filled = {}

				data = data || {}

				filled.text = data.text || ''
				filled.sort = data.sort || 'firstname'
				filled.sortReverse = data.sortReverse || 0

				return filled

			},

			lists: function(data) {

				var filled = {}

				data = data || {}

				filled.text = data.text || ''
				
				filled.sort = data.sort || 'name'
				filled.sortReverse = data.sortReverse || 0

				return filled

			},

			activity: function(data) {

				var filled = {}

				data = data || {}

				filled.date = data.date || {from: 0, to: 0}
				filled.members = data.members || []

				data.hide = data.hide || {}
				filled.hide = {}

				data.hide.status = data.hide.status || {}

				filled.hide.status = {
					added: data.hide.status.added || 0,
					removed: data.hide.status.removed || 0,
					own: data.hide.status.own || 0,
					prevowned: data.hide.status.prevowned || 0,
					fortrade: data.hide.status.fortrade || 0,
					want: data.hide.status.want || 0,
					wanttoplay: data.hide.status.wanttoplay || 0,
					wanttobuy: data.hide.status.wanttobuy || 0,
					wishlist: data.hide.status.wishlist || 0,
					preordered: data.hide.status.preordered || 0,
					hasparts: data.hide.status.hasparts || 0,
					wantparts: data.hide.status.wantparts || 0
				}

				data.hide.list = data.hide.list || {}

				filled.hide.list = {
					added: data.hide.list.added || 0,
					removed: data.hide.list.removed || 0
				}

				data.hide.edition = data.hide.edition || {}

				filled.hide.edition = {
					added: data.hide.edition.added || 0
				}

				data.hide.member = data.hide.member || {}

				filled.hide.member = {
					added: data.hide.member.added || 0,
					removed: data.hide.member.removed || 0
				}

				data.hide.buddy = data.hide.buddy || {}

				filled.hide.buddy = {
					added: data.hide.buddy.added || 0,
					removed: data.hide.buddy.removed || 0
				}

				data.hide.tags = data.hide.tags || {}

				filled.hide.tags = {
					created: data.hide.tags.created || 0,
					deleted: data.hide.tags.deleted || 0,
					added: data.hide.tags.added || 0,
					removed: data.hide.tags.removed || 0
				}

				data.hide.rating = data.hide.rating || {}

				filled.hide.rating = {
					added: data.hide.rating.added || 0,
					removed: data.hide.rating.removed || 0,
					updated: data.hide.rating.updated || 0
				}

				data.hide.comment = data.hide.comment || {}

				filled.hide.comment = {
					added: data.hide.comment.added || 0,
					removed: data.hide.comment.removed || 0,
					updated: data.hide.comment.updated || 0
				}

				data.hide.collection = data.hide.collection || {}

				filled.hide.collection = {
					added: data.hide.collection.added || 0,
					removed: data.hide.collection.removed || 0
				}

				data.hide.play = data.hide.play || {}

				filled.hide.play = {
					added: data.hide.play.added || 0
				}

				filled.hide.hindex = data.hide.hindex || 0

				return filled

			},

			'config': function(data) {

				var filled = {}

				data = data || {}

				data.sync = data.sync || {}
				data.sync.plays = data.sync.plays || {}

				filled.sync = data.sync
				
				filled.sync.plays = {
					incomplete: data.sync.plays.incomplete || 0
				}

				data.plays = data.plays || {}

				filled.plays = {
					layout: data.plays.layout || 0,
					usernames: data.plays.usernames || []
				}

				data.collection = data.collection || {}
				data.collection.inventory = data.collection.inventory || {}
				data.collection.inventory.location = data.collection.inventory.location || {}

				filled.collection = {
					contains: data.collection.contains || 0,
					layout: data.collection.layout || 0,
					status: data.collection.status || 0,
					nest: data.collection.nest || 0,
					inventory: {
						location: {
							public: data.collection.inventory.location.public || 0
						}
					}
				}

				data.other = data.other || {}

				filled.other = {
					currency: data.other.currency || 0,
					date: data.other.date || 'DD/MM/YYYY',
					exchange: data.other.exchange || 1
				}
				
				data.ratings = data.ratings || {}

				filled.ratings = {
					layout: data.ratings.layout || 0
				}

				data.insights = data.insights || {}

				data.insights.records = data.insights.records || {}
				data.insights.heatmap = data.insights.heatmap || {}

				filled.insights = {}

				filled.insights.records = {
					start: data.insights.records.start || 0
				}

				filled.insights.heatmap = {
					start: data.insights.heatmap.start || 'sunday'
				}

				data.insights.hindex = data.insights.hindex || {}

				filled.insights.hindex = {
					expansions: data.insights.hindex.expansions || 0
				}

				data.insights.size = data.insights.size || {}

				filled.insights.size = {
					expansions: data.insights.size.expansions || 0
				}

				data.lists = data.lists || {}
				data.lists.unplayed = data.lists.unplayed || {}

				filled.lists = data.lists

				filled.lists.unplayed = {
					type: data.lists.unplayed.type || 'shame',
					trade: (data.lists.unplayed.trade === undefined) ? 1 : data.lists.unplayed.trade,
					rated: (data.lists.unplayed.rated === undefined) ? 1 : data.lists.unplayed.rated,
					games: data.lists.unplayed.games || []
				}

				return filled

			}

		}

	}

})