<template>
	
<core-list-expand-section :empty="!parts.length" :placeholder="'No ' + type + ' have or want any parts.'" class="parts" v-if="selected">

	<div class="parts-item" v-for="(part, index) in parts" v-bind:key="index">

		<core-avatar v-if="!isUser" class="parts-item-avatar" type="user" :id="part.user" tooltip="1" />

		<div class="parts-item-comment">
			<p v-html="part.text"></p> <div class="parts-item-status" v-bind:class="{'is-hasparts': part.type === 9, 'is-wantparts': part.type === 10}">{{ status(part.type) }}</div>
		</div>

	</div>
	
</core-list-expand-section>

</template>

<script>

export default {

	name: 'parts',

	props: ['item', 'format', 'selected'],

	computed: {

		parts: function() {

			return this.item.users.parts

		},

		type: function() {

			if (this.$store.getters['context/is/group']) return 'members'
			if (this.$store.getters['context/is/buddies']) return 'buddies'

			return false

		}

	},

	methods: {

		status: function(type) {

			if (type === this.$CONSTANTS.GAME.STATUS.ID.HASPARTS) return 'Has parts'
			if (type === this.$CONSTANTS.GAME.STATUS.ID.WANTPARTS) return 'Want parts'

		}

	}

}

</script>

<style scoped>

.parts {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.parts-item {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	padding: 9px 9px 0px 9px;
	margin-bottom: 9px;
	border-top: 1px solid #eee;
}

.parts-item:first-child {
	border-top: 0px;
}

.parts-item-avatar {
	width: 48px;
	height: 48px;
	font-size: 12px;
	margin-right: 8px;
	flex-shrink: 0;
}

.parts-item-comment {
	flex-grow: 1;
	font-size: 14px;
	line-height: 16px;
	color: #333;
	font-weight: 300;
	min-height: 48px;
}

.parts-item-comment p {
	margin-bottom: 8px;
	min-height: 23px;
}

.parts-item-status {
	display: inline-block;
	font-size: 10px;
	color: #fff;
	margin-right: 4px;
	padding: 2px 4px;
	border-radius: 4px;
	line-height: 10px;
	font-weight: 400;
	cursor: initial;
}

.parts-item-status.is-hasparts {
	background-color: rgba(4, 108, 143, 0.5);
}

.parts-item-status.is-wantparts {
	background-color: rgba(4, 143, 85, 0.5);
}

</style>
