<template>

<core-modal v-on:close="onClose" :loading="is.loading" :loadingText="loadingText">

	<template v-slot:head>

		Run Sync

	</template>

	<template v-slot:default>

		<p v-if="upgraded">You have full access to all sync types with no limits. Thank you for using the tipjar.</p>

		<p v-if="!upgraded">You have <b>weekly automated api syncs</b> and an optional <b>monthly manual deep sync</b>.</p>

		<p v-if="!upgraded">If you would like daily automated api syncs and to be able to run syncs anytime you want, please see the Tipjar & Perks section.</p>

		<div class="tipjar-perks">

			<div class="tipjar-perks-item">

				<h2>Recent API Sync</h2>

				<p>Updates your profile with changes to your collection, ratings, and any new plays dated in the previous 4 weeks.</p>

				<core-button theme="blue" :disabled="is.loading" :loading="is.loading === $CONSTANTS.SYNC.STANDARD" v-if="upgraded" v-on:click.native="onStandardClick">Run Recent</core-button>
				<core-button theme="blue" :disabled="true" v-if="!upgraded">Unavailable</core-button>

			</div>

			<div class="tipjar-perks-item">

				<h2>Full API Re-sync</h2>

				<p>Re-imports all profile data including your full play history. Useful for when you've added or deleted historical play data.</p>

				<core-button theme="blue" :disabled="is.loading" :loading="is.loading === $CONSTANTS.SYNC.FULL" v-if="upgraded" v-on:click.native="onFullClick">Run Full</core-button>
				<core-button theme="blue" :disabled="true" v-if="!upgraded">Unavailable</core-button>

			</div>

			<div class="tipjar-perks-item">

				<h2>Deep Sync</h2>

				<p>Uses your BGG login credentials to pull hidden data that the API does not share such your game acquisition dates.</p>

				<core-button theme="blue" :disabled="is.loading" :loading="is.loading === $CONSTANTS.SYNC.FULL" v-if="canDeepSync" v-on:click.native="onDeepClick">Run Deep</core-button>
				<core-button theme="blue" :disabled="true" v-if="!canDeepSync">Unavailable</core-button>

			</div>

		</div>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			loadingText: 'Please wait while the sync is in progress.<br/><br/>Depending on how much data is being synchronised, and how busy the server is, this process may take several minutes.<br/><br/>The page will refresh once completed.<br/><br/><b>You do not need to wait, you can close this and check your profile later.</b>',

			is: {
				loading: false
			}

		}

	},

	computed: {

		upgraded: function() {

			return this.$store.getters['session/upgraded']

		},

		session: function() {

			return this.$store.getters['session']

		},

		canDeepSync: function() {

			return this.upgraded || !this.$moment.utc().isSame(this.$moment.unix(this.session.date.syncDeep).utc(), 'month')

		}

	},

	methods: {

		onStandardClick: function() {

			this.sync(this.$CONSTANTS.SYNC.STANDARD)

		},

		onFullClick: function() {

			this.sync(this.$CONSTANTS.SYNC.FULL)

		},

		onDeepClick: function() {

			this.$emit('close')
			this.$pubsub.$emit('open.modal', 'userDeep')

		},

		onTipjarClick: function() {

			this.$emit('close')
			this.$pubsub.$emit('open.modal', 'userTipjar')

		},

		onClose: function() {

			this.$emit('close')

		},

		sync: function(type) {

			this.is.loading = type
			
			this.$store.dispatch('api/session/sync', {
				type: type
			}).then(function() {

				this.$router.go()

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.tipjar-perks {
	display: flex;
	flex-direction: column;
}

.tipjar-perks-item {
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
}

.tipjar-perks-item.is-disabled {
	opacity: 0.25;
}

.tipjar-perks-item h2 {
	font-size: 16px;
	font-weight: 500;
	display: block;
	margin-bottom: 4px;
	text-align: left;
	color: #fff!important;
}

.tipjar-perks-item p {
    font-size: 16px!important;
    line-height: 20px!important;
    color: rgba(255, 255, 255, 0.75)!important;
	text-align: left;
	flex-grow: 1;
	margin-bottom: 20px;
}

.tipjar-perks-item:last-child {
	margin-right: 0px;
}

</style>