<template>

<com-item name="plays.duration" :size="size" :is-empty="!active" :loading="loading" title="Duration">

	<template v-slot:about>

		<p>How long were {{ your }} plays in minutes {{ periodText }}.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-bars :data="graph" v-if="active" />
		
	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			limit: 6,
			graph: {
				datasets: [
					{ type: 'bar', data: [], backgroundColor: '#4881bb', tooltip: '{{value}} play{{value.plural}} <small>{{x.label}} {{x.sublabel}}</small>' }
				],
				axis: {
					x: {
						labels: []
					},
					y: {
						min: 10,
						points: 10
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		data: function() {

			return this.$store.getters['page/insights/plays/duration']

		},

		active: function() {

			return this.$_.keys(this.data).length && !this.loading

		}

	},

	created: function() {
	
		this.visualise()

	},

	watch: {

		data: function() {

			this.visualise()

		}

	},

	methods: {

		visualise: function() {

			if (this.$_.keys(this.data).length) {

				this.graph.datasets[0].data = []
				this.graph.axis.x.labels = []

				var min = 1
				var max = this.$_.max(this.$_.keys(this.data))
				var count

				max = (max < this.limit) ? this.limit : max

				for (var i = min; i <= max; i++) {

					if (i >= this.limit) {

						if (this.graph.datasets[0].data.length === this.limit) {

							count = this.graph.datasets[0].data[this.limit - 1]

						} else {

							count = 0

						}

						count += this.data[i] || 0

						if (this.graph.datasets[0].data.length === this.limit) {

							this.graph.datasets[0].data[this.limit - 1] = count

						} else {

							this.graph.datasets[0].data.push(count)

						}

						if (this.graph.axis.x.labels.length === this.limit - 1) {

							this.graph.axis.x.labels.push({
								label: (max > this.limit) ? (this.limit * 30).toString() + '+' : (this.limit * 30).toString(),
								sublabel: 'Minutes'
							})

						}

					} else {

						this.graph.datasets[0].data.push(this.data[i] || 0)

						this.graph.axis.x.labels.push({
							label: (i * 30).toString(),
							sublabel: 'Minutes'
						})

					}

				}

			}

		}

	}

}

</script>

<style scoped>

</style>