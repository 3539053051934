<template>

<div class="item" v-bind:class="{'is-selectable': selectable, 'is-dark': dark}">

	<core-avatar type="user" :id="item.id" class="item-avatar" />

	<div class="item-name">

		<router-link v-if="!notLinked" :to="{name: 'Collection', params: {type: 'users', name: item.name}}" class="item-name-name">{{ name }}</router-link>

		<div v-if="notLinked" class="item-name-name">{{ name }}</div>

		<div class="item-name-subtext" v-if="username">{{ username }}</div>

		<div class="item-name-subtext" v-if="subtext">{{ subtext }}</div>

	</div>

	<slot></slot>

	<core-rating v-if="rating" :value="rating" class="item-rating" />

	<div v-if="selectable" class="item-selectable"><i class="fa fa-caret-right"></i></div>

</div>

</template>

<script>

export default {

	props: ['item', 'subtext', 'notLinked', 'selectable', 'rating', 'dark'],

	computed: {

		name: function() {

			return this.item.fullname || this.item.name

		},

		username: function() {

			return (this.item.fullname) ? this.item.name : false

		}

	}

}

</script>

<style scoped>

.item {
	width: 100%;
	height: 64px;
	display: flex;
	padding: 8px 15px 8px 15px;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.item {
		padding: 8px 8px;
		height: auto;
	}

}

.item:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.item.is-dark:nth-child(odd) {
	background-color: rgba(255, 255, 255, 0.05);
}

.is-desktop .item.is-selectable:hover {
	cursor: pointer;
	background-color: #4881bb;
}

.is-desktop .item.is-dark.is-selectable:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.item-avatar {
	width: 48px;
	height: 48px;
	flex-shrink: 0;
}

.item-name {
	padding: 0px 8px;
	flex-grow: 1;
	min-width: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.item-name-name {
	font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #333;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.item.is-dark .item-name-name {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.item-name-name {
		font-size: 14px;
	}

}

.is-desktop .item.is-selectable:hover .item-name-name {
	color: #fff;
}

.item-name-subtext {
	font-size: 11px;
	color: #666;
}

.item.is-dark .item-name-subtext {
	color: rgba(255, 255, 255, 0.75);
}

.is-desktop .item.is-selectable:hover .item-name-subtext {
	color: #fff;
}

.is-desktop .item-name a:hover {
	text-decoration: underline;
}

.item-selectable {
	line-height: 64px;
	width: 64px;
	text-align: center;
	position: absolute;
	right: 0px;
	top: 0px;
	font-size: 32px;
	opacity: 0.75;
}

.item.is-dark .item-selectable {
	color: #fff;
}

.is-desktop .item.is-selectable:hover .item-selectable {
	color: #fff;
	opacity: 1;
}

.item-rating {
	flex-shrink: 0;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 18px;
	font-weight: 400;
}

</style>