<template>

<router-link :to="{name: 'List', params: {list: item.list.slug}}" class="list">

	<div class="list-head">

		<h1>
			<i class="fa" v-bind:class="{[iconClass]: true}" v-tooltip="tooltip"></i> 
			{{ item.list.name }}
		</h1>

		<span class="list-label" v-if="!isLive">{{ currentPeriod }}<small v-if="currentSubperiod"> / {{currentSubperiod}}</small></span>

	</div>

	<div class="list-body">

		<p>{{ item.list.description }}</p>

	</div>

	<div class="list-stats" v-if="item.edition">

		<div class="list-stats-summary" v-if="item.edition">
			{{ item.edition.count.games }} <plural t="game" :n="item.edition.count.games" />
		</div>

		<div class="list-stats-archive" v-if="!isLive">
			<span v-if="dateRange">{{ dateRange }}</span> 
			({{ item.list.count.editions }} <plural t="edition" :n="item.list.count.editions" /><span v-if="isOwner && item.list.count.progress">, {{ item.list.count.progress }} in progress</span>) 
		</div>

	</div>

	<div class="list-foot" v-if="item.edition.count.games">

		<core-avatar v-for="game in item.games" v-bind:key="game" type="game" :id="game" tooltip="1"></core-avatar>

		<div class="list-foot-more" v-if="item.edition.count.games > 7">+{{ item.edition.count.games - 6}}</div>

	</div>

</router-link>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		tooltip: function() {

			return this.$CONSTANTS.LISTS.TYPE_NAME[this.item.list.type]

		},

		iconClass: function() {

			return this.$CONSTANTS.LISTS.TYPE_ICON[this.item.list.type]

		},

		dateUpdate: function() {

			return this.$moment.unix(this.item.list.date.update).utc()

		},

		dateCreate: function() {

			return this.$moment.unix(this.item.list.date.create).utc()

		},

		dateStart: function() {

			return this.$moment.unix(this.item.list.date.start).utc()

		},
		
		currentPeriod: function() {

			if (this.item.list.ranked && this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.YEARLY) {

				if (this.dateStart.format('YYYY') !== this.dateUpdate.format('YYYY')) {

					return this.dateStart.format('YYYY') + ' - ' + this.dateUpdate.format('YYYY')

				} else {

					return this.dateUpdate.format('YYYY')

				}

			}

			if (this.item.list.ranked && this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.YEARLY) return this.dateUpdate.format('YYYY')
			if (this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.LIVE) return 'Current'
			if (this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.MONTHLY) return this.dateUpdate.format('MMMM')
			if (this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.YEARLY) return this.dateUpdate.format('YYYY')
			if (this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.ADHOC) return this.dateUpdate.format('DD MMMM')

			return false

		},

		currentSubperiod: function() {

			if (this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.MONTHLY) return this.dateUpdate.format('YYYY')
			if (this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.ADHOC) return this.dateUpdate.format('YYYY')

			return false

		},

		dateRange: function() {

			if (this.item.list.frequency !== this.$CONSTANTS.LISTS.FREQUENCY.LIVE) {

				var startYear = this.dateStart.format('YYYY')
				var endYear = this.dateUpdate.format('YYYY')

				if (startYear !== endYear) {

					return startYear + ' - ' + endYear

				} else {

					return startYear 

				}

			}

			return false

		},

		isLive: function() {

			return this.item.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.LIVE

		},

		isRanked: function() {

			return this.item.list.ranked

		}

	}

}

</script>

<style scoped>

.list {
	cursor: pointer;
	display: flex;
	background-color: #fff;
	height: 193px;
	flex-direction: column;
	border: 1px solid #ddd;
	border-radius: 4px;
}

@media only screen and (max-width: 759px) {

	.list {
		height: auto;
	}

}

.is-desktop .list:hover {
	border: 1px solid #ddd;
}

.list-head {
	height: 40px;
	display: flex;
	flex-direction: row;
	margin-top: -1px;
}

.list-head h1 {
	padding: 12px 18px;
	font-size: 16px;
	line-height: 16px;
	color: #333;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex-grow: 1;
}

@media only screen and (max-width: 767px) {

	.list-head h1 {
		padding: 12px 8px;
	}

}

.list-head h1 .fa {
	color: #ccc;
	margin-right: 4px;
}

.list-label {
	flex-shrink: 0;
	background-color: #4881bb;
	color: #fff;
	font-size: 14px;
	margin-right: 18px;
	font-weight: 400;
	line-height: 40px;
	padding: 0px 8px;
	border-radius: 0px 0px 4px 4px;
}

@media only screen and (max-width: 767px) {

	.list-label {
		margin-right: 8px;
	}

}

.list-label small {
	opacity: 0.4;
}

.list-body {
	flex-grow: 1;
	padding: 15px 18px;
	padding-bottom: 0px;
}

@media only screen and (max-width: 759px) {

	.list-body {
		padding-bottom: 15px;
	}

}

@media only screen and (max-width: 767px) {

	.list-body {
		padding: 8px 8px 15px 8px;
	}

}

.list-body p {
	font-size: 14px;
    font-weight: 300;
	line-height: 16px;
	height: 48px;
	overflow: hidden;
	color: #333;
}

.list-body p:before {
	content:'';
	width: 100%;
	height: 16px; 
	position: absolute;
	left: 0px;
	bottom: 0px;
	background: linear-gradient(transparent 0px, white);
}

.list-stats {
	padding: 0px 18px 15px 18px;
	font-size: 11px;
	color: #666;
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.list-stats {
		padding: 0px 8px 8px 8px;
	}

}

.list-stats-archive {
	flex-grow: 1;
	text-align: right;
}

.list-foot {
	display: flex;
	padding: 15px 18px;
	padding-top: 0px;
}

@media only screen and (max-width: 767px) {

	.list-foot {
		padding: 8px;
	}

}

.list-foot > div {
	flex-shrink: 0;
	width: 44px;
	height: 44px;
	margin-right: 1px;
}

.list-foot > div:last-child {
	margin-right: 0px;
}

.list-foot-more {
	line-height: 44px;
	text-align: center;
	color: #fff;
	background-color: #666;
	font-size: 14px;
	font-weight: 400;
}

@media only screen and (max-width: 767px) {

	.list-foot > div {
		width: 40px;
		height: 40px;
	}

	.list-foot-more {
		line-height: 40px;
	}

}

</style>