<template>

<core-simplebar class="body" :class="{'nopadding': nopadding, 'is-text': type === 'text', 'nofoot': nofoot}">

	<slot></slot>

</core-simplebar>

</template>

<script>

export default {

	props: ['nopadding', 'type', 'nofoot']

}

</script>

<style scoped>

.body {
	flex-grow: 1;
	flex-basis: 0;
	padding: 20px;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.body >>> .simplebar-wrapper {
	flex-grow: 1;
	flex-basis: 0;
	width: 320px;
}

.is-mobile .body >>> .simplebar-wrapper {
	width: calc(100% + 20px);
}

.is-mobile .body {
	padding: 20px 10px 10px 10px;
}

.body.nopadding {
	padding: 0px!important;
}

.body >>> .simplebar-scrollbar {
	background-color: rgba(255, 255, 255, 0.33)!important;
}

.body.is-text >>> *:last-child,
.body >>> .simplebar-content > *:last-child {
	margin-bottom: 0px;
}

.body.is-text >>> h2,
.body >>> .simplebar-content > h2 {
	margin-top: 20px;
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 20px;
	text-align: left;
}

.body.is-text >>> p,
.body >>> .simplebar-content > p {
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 20px;
	text-align: left;
}

.body.is-text >>> ul,
.body >>> .simplebar-content > ul {
	margin-bottom: 20px;
	margin-left: 20px;
	list-style: square;
	list-style-position: outside;
}

.body.is-text >>> li,
.body >>> .simplebar-content li {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 5px;
	line-height: 20px;
	color: #fff;
}

.body.is-text >>> li small,
.body >>> .simplebar-content li small {
	font-size: 12px;
    display: block;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.75);
}

.body.is-text >>> table {
	width: 100%!important;
	margin-bottom: 20px;
}

.body.is-text >>> td {
	width: auto!important;
	padding: 0px 4px 20px 0px;
}

.body.is-text >>> a,
.body >>> .simplebar-content > p a {
	color: #35b8ec;
}

.is-desktop .body.is-text >>> a:hover,
.body >>> .simplebar-content > p a:hover {
	text-decoration: underline;
}

</style>