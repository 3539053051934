import Vue from 'vue'

export default {

	namespaced: true,

	state: {

		data: false,
		type: false

	},

	getters: {

		'is/user': function(state) {

			return state.type === 'users'

		},

		'is/group': function(state) {

			return state.type === 'groups'

		},

		'is/buddies': function(state) {

			return state.type === 'buddies'

		},

		current: function(state) {

			return state.data

		},

		country: function(state) {

			return (state.data) ? state.data.country : false

		},

		slug: function(state) {

			return (state.data) ? state.data.slug : false

		},

		id: function(state) {

			return (state.data) ? state.data.id : false

		},

		registered: function(state) {

			return (state.data) ? state.data.registered : false

		},

		name: function(state) {

			return (state.data) ? state.data.name : false

		},

		threshold: function(state) {

			return (state.data) ? state.data.threshold : 1

		},

		type: function(state) {

			return state.type

		},

		'count/ratings': function(state) {

			return (state.data) ? state.data.count.ratings : 0

		},

		'count/plays': function(state) {

			return (state.data) ? state.data.count.plays : 0

		},

		'count/games': function(state) {

			return (state.data) ? state.data.count.games : 0

		},

		'count/lists': function(state) {

			return (state.data) ? state.data.count.lists : 0

		},

		'count/users': function(state) {

			return (state.data) ? ((state.data.count.users) ? state.data.count.users : 0) : 0

		},

		'date/firstPlay': function(state) {

			return (state.data) ? ((state.data.date.firstPlay) ? state.data.date.firstPlay : 0) : 0

		},

		'date/sync': function(state) {

			return (state.data) ? ((state.data.date.sync) ? state.data.date.sync : 0) : 0

		},

		'date/sync/next': function(state) {

			return (state.data) ? ((state.data.date.nextSync) ? state.data.date.nextSync : 0) : 0

		},

		synchronised: function(state) {

			return !state.data.requireSync

		},

		queueSync: function(state) {

			return (state.data.queueSync) ? state.data.queueSync : 0

		},

		users: function(state) {

			return (state.data) ? ((state.data.users) ? state.data.users : []) : []

		},

		actions: function(state) {

			return (state.data.actions) ? state.data.actions : []

		}

	},

	mutations: {

		set: function(state, params) {

			Vue.set(state, 'data', params.data)
			state.type = params.type

		}

	},

	actions: {}

}