<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Forgot password

	</template>

	<template v-slot:default>

		<p>Enter your username to receive a GeekMail on BGG containing a reset URL for your password.</p>

		<core-form-input-textbox :autofocus="true" name="username" v-on:validate="validate" placeholder="Enter BGG username" v-model="inputs.username" :error="is.error" :disabled="is.success || is.loading" :validation="validation.username"></core-form-input-textbox>

	</template>

	<template v-slot:foot>

		<core-button v-on:click.native="onResetClick" :loading="is.loading" :success="is.success" :disabled="!is.valid" theme="blue">{{ buttonText }}</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false,
				success: false,
				error: false
			},

			inputs: {
				username: ''
			},

			validation: {
				username: { exists: true }
			}

		}

	},

	computed: {

		buttonText: function() {

			return (this.is.success) ? 'GeekMail sent' : 'Confirm'

		}

	},

	methods: {

		onResetClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/account/forgot', {
					name: this.inputs.username
				}).then(function() {

					this.is.error = false
					this.is.success = true
					this.is.loading = false

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>