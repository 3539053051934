<template>

<com-item name="collection.bestduration" :size="size" :is-empty="!active" :loading="loading" title="Best Count vs Duration">

	<template v-slot:about>

		<p>See how {{ your }} collection breaks down by weight.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<core-widget class="bestduration-graph" :scroll="true">

			<app-visualisation-heatmap :data="graph" v-on:click="onClick" />
		
		</core-widget>
		
	</template>

	<template v-slot:buttons>

		<com-toggle :options="durationOptions" :value="durationValue" v-on:change="onDurationChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {

		return {
			graph: {
				datasets: [
					{ data: [] }
				],
				axis: {
					x: {
						labels: ['0-1hr', '1-2hr', '2-3hr', '3-4hr', '4-5hr', '5-6hr'],
						thresholds: [0, 60, 120, 180, 240, 300, 360]
					},
					y: {
						labels: ['1p', '2p', '3p', '4p', '5p', '6p'],
						thresholds: [1, 2, 3, 4, 5, 6]
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		data: function() {

			return this.$store.getters['page/insights/collection/bestduration']

		},

		active: function() {

			return this.data.length && !this.loading

		},

		durationOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.MAX, text: 'Maximum Duration'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.MID, text: 'Midpoint Duration'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.COUNT, text: 'Count Duration'})

			return options

		},

		durationValue: function() {

			return this.$store.getters['filter/insights/collection/bestduration/duration']

		},

	},

	watch: {

		data: function() {

			this.visualise()

		}

	},

	created: function() {
	
		this.visualise()

	},

	methods: {

		visualise: function() {

			this.graph.datasets[0].data = []

			this.$_.each(this.data, function(item) {

				this.graph.datasets[0].data.push({
					id: item.g,
					x: item.t, 
					y: item.b,
					tooltip: item.n + '<small>' + item.b + ' / ' + item.t + ' minutes</small>'
				})

			}.bind(this))

		},

		onClick: function(e) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionBestDuration',
				x: e.x,
				y: e.y,
				filter: this.$store.getters['filter/insights/collection']
			})

		},

		onDurationChange: function(value) {

			this.$store.commit('filter/insights/collection/bestduration/duration', value)

		}

	}

}

</script>

<style scoped>

.bestduration-graph {
	width: 100%;
	height: 100%;
	height: 320px;
}

</style>