<template>

<div class="panel">

	<div class="panel-head" :class="{'is-custom': customHead}">

		<div class="panel-head-title"><slot name="head"></slot></div>

	</div>

	<div class="panel-body-empty" v-if="!hasContent">

		<slot name="empty"></slot>

	</div>

	<core-simplebar class="panel-body" v-if="hasContent">

		<div class="panel-body-content">

			<slot name="body"></slot>

		</div>

	</core-simplebar>

</div>

</template>

<script>

export default {

	props: ['customHead'],

	computed: {

		hasContent: function() {

			if (this.$slots.body) {

				var exists = false

				this.$_.each(this.$slots.body, function(button) {

					if (button.tag) exists = true

				})

				if (exists) return true

			}

			return false

		}

	}

}

</script>

<style scoped>

.panel {
	position: absolute;
	left: 0px;
	top: 0px;
	height: calc(100vh - var(--vh-offset, 0px) - 64px);
	width: 100%;
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.is-mobile .panel {
	height: calc(100vh - var(--vh-offset, 0px) - 144px);
}

.panel-head {
	z-index: 2;
	flex-shrink: 0;
	height: 88px;
	display: flex;
	align-items: center;
	padding: 20px;
    background-color: #19455c;
}

.is-smaller .panel-head {
	height: 64px;
}

.is-small .panel-head {
	min-height: 48px;
	height: auto;
	padding: 10px;
}

.panel-head:after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #19455c transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    left: 17px;
    bottom: -10px;
}

.panel-head-title {
	text-align: left;
	font-size: 20px;
	color: #fff;
	font-weight: 500;
	line-height: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 80px;
}

.is-mobile .panel-head-title {
	font-size: 16px;
	padding-right: 48px;
	line-height: 20px;
}

.panel-head-title >>> h1 {
	font-weight: 500;
}

.panel-head-title >>> p {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
}

.panel-head.is-custom {
	padding: 0px;
	margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {

	.panel-head.is-custom {
		margin-bottom: 8px;
	}

}

.panel-body {
	flex-grow: 1;
	width: 100%;
	display: flex;
	overflow: auto;
}

@media only screen and (max-width: 767px) {

	.panel-body {
		width: 100%;
	}

}

.panel-body >>> .simplebar-scrollbar {
	background-color: rgba(255, 255, 255, 0.33)!important;
}

.panel-body-empty {
	padding: 20px;
	padding-top: 0px;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	color: #fff;
}

</style>
