<template>

<div class="foot">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.foot {
	padding: 10px 15px;
	display: grid;
	grid-gap: 10px;
	flex-shrink: 0;
	z-index: 2;
}

</style>