<template>
	
<div class="ratings">

	<div v-if="count > max" class="ratings-more" v-propagation v-tooltip="'View all'" v-on:click="$emit('more')">

		+{{count - max}}

	</div>
		
	<div v-for="(game, index) in games" v-bind:key="index" class="ratings-item">

		<core-avatar type="game" :id="game.id" tooltip="1" :tooltipSmall="game.tooltip" class="ratings-item-avatar" />

		<core-rating v-if="game.rating" :value="game.rating" class="ratings-item-rating" />

		<div v-if="game.worth" :value="game.worth" class="ratings-item-worth" v-html="formatcurrency(game.worth, 0, true)" />

		<div v-if="game.count" class="ratings-item-count" :class="{'is-green': game.colour === 'green', 'is-blue': game.colour === 'blue', 'is-red': game.colour === 'red'}">{{ game.count }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['games', 'max', 'count']

}

</script>

<style scoped>

.ratings {
	display: flex;
	flex-direction: row;
	padding-right: 8px;
	justify-content: flex-end;
}

.ratings-item {
	margin-right: 1px;
}

.ratings-item-avatar {
	width: 24px;
	height: 24px;
}

.ratings-item-rating {
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-size: 12px!important;
}

.ratings-item-worth {
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-size: 12px!important;
	text-align: center;
	background-color: #4881bb;
	color: #fff;
	font-weight: 400;
}

.ratings-item-count {
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-size: 10px!important;
	background-color: #4881bb;
	color: #fff;
	font-weight: 400;
	text-align: center;
}

.ratings-item-count.is-green {
	background-color: #2F9B68;
}

.ratings-item-count.is-blue {
	background-color: #68B1C8;
}

.ratings-item-count.is-red {
	background-color: #9A3D25;
}

.ratings-more {
	background-color: #999;
	color: #fff;
	font-size: 12px;
	margin-top: 24px;
	margin-right: 1px;
	height: 24px;
	min-width: 24px;
	padding: 0px 4px;
	line-height: 24px;
	font-weight: 400;
	text-align: center;
	cursor: pointer;
}

.is-desktop .ratings-more:hover {
	background-color: #666;
}

</style>
