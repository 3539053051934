<template>

<core-panel>

	<template v-slot:head>

		<h1>Designers</h1>

		<p v-if="filterValue === 'total'">The designers with the highest total value of games. Excludes expansions.</p>
		<p v-if="filterValue === 'average'">The designers with the highest average value of games. Minimum two games. Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-person v-for="(item, index) in designers" :hideCount="true" :item="item" type="designer" v-bind:key="index" v-on:more="onMoreClick" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'designers',
			designers: []
		}

	},

	computed: {

		filterValue: function() {

			return (this.data.filter) ? this.data.filter.designers : 'total'

		}

	},

	methods: {

		response: function(json) {

			this.designers = json.designers

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthDesigner',
				id: id,
				child: true
			})

		}

	}

}

</script>