<template>
	
<div class="about">

	<div class="about-edition" v-if="editionName">

		{{ editionName }}

	</div>
	
		<div class="about-text" :class="{'has-edition': editionName}">

			<div class="about-text-description" v-if="list.description" v-html="description"></div>

			<div class="about-text-tags">
				<div>{{ listType }}</div>
				<div>{{ listFrequency }}</div>
			</div>

		</div>

		<div class="about-stats" v-if="!window.is.mobile && stats.length">

			<div class="about-stats-item" v-for="(stat, index) in stats" v-bind:key="index">

				<div class="about-stats-item-value">{{ stat.value }}</div>
				<div class="about-stats-item-text">{{ stat.text }}</div>

			</div>

		</div>

		<div class="about-highlights" v-if="!window.is.mobile && highlights.length">

			<div class="about-highlights-item" v-for="(highlight, index) in highlights" v-bind:key="index">
				
				<core-avatar type="game" :id="highlight.id" class="about-highlights-item-avatar">
				</core-avatar>

				<div class="about-highlights-item-text">
					<div class="about-highlights-item-text-rank">
						<span v-if="highlight.value < 10">0</span>{{ highlight.value }}
						<div v-if="highlight.movement" class="about-highlights-item-text-movement">
							<i class="fa" v-bind:class="{'fa-caret-up': highlight.movement > 0, 'fa-caret-down': highlight.movement < 0}"></i>
							{{ highlight.movement | abs }}
						</div>
					</div>
					<div class="about-highlights-item-text-value">{{ gameName(highlight.id) }}</div>
					<div class="about-highlights-item-text-category">{{ highlight.type }}</div>
			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['loading'],

	computed: {

		changed: function() {

			return ((this.$store.getters['filter/list/changed'] && this.isAutomated) || this.$store.getters['page/list/columns/changed']) && this.canDoSomething

		},

		editionName: function() {
		
			return this.isLive ? false : this.edition.name

		},

		description: function() {

			return this.$options.filters.nl2br(this.list.description)

		},

		isAutomated: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.AUTOMATED

		},

		isLive: function() {

			return this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.LIVE

		},

		listType: function() {

			return this.$CONSTANTS.LISTS.TYPE_SHORTNAME[this.list.type]

		},

		listFrequency: function() {

			return this.$CONSTANTS.LISTS.FREQUENCY_NAME[this.list.frequency]

		},

		canDoSomething: function() {

			return this.$store.getters['permissions/list/create'] || this.$store.getters['permissions/list/edit'] || this.$store.getters['permissions/list/delete'] || this.$store.getters['permissions/edition/create'] || this.$store.getters['permissions/edition/edit'] || this.$store.getters['permissions/edition/delete']

		},

		highlights: function() {

			var maxHighlights = (this.window.is.desktop) ? 2 : 1

			return this.$store.getters['page/list/highlights'].slice(0, maxHighlights)

		},

		stats: function() {

			var stats = this.$store.getters['page/list/stats'] || []

			if(!this.$_.isArray(stats)) {
			
				stats = [stats]
			
			}
			
			return stats

		},

		list: function() {

			return this.$store.getters['page/list/list']

		},

		edition: function() {

			return this.$store.getters['page/list/edition']

		}

	},

	methods: {

		gameName: function(id) {

			return this.$store.getters['data/games/associated'][id].name

		},

		onRankingClick: function() {

			this.$pubsub.$emit('list.rank.expand')

		},

		onAddEditionClick: function() {

			this.$store.dispatch('api/list/edition/create').then(function(json) {

				this.$router.push({
					name: 'Edition',
					params: {
						list: this.list.slug,
						edition: json.slug
					}
				}).catch(function(e) { console.log(e) })

			}.bind(this))

		},

		onDeleteEditionClick: function() {

			this.$pubsub.$emit('open.modal', 'listEditionDelete')
			
		},

		onSearchGamesClick: function() {

			this.$pubsub.$emit('list.search.expand')

		},

		onAddGamesClick: function() {

			this.$pubsub.$emit('list.import.expand')

		},

		onFilterClick: function() {

			this.$pubsub.$emit('list.automated.expand')

		},

		onColumnsClick: function() {

			this.$pubsub.$emit('list.columns.expand')

		},

		onToggleClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onListDeleteClick: function() {

			this.$pubsub.$emit('open.modal', 'listDelete')

		},

		onListEditClick: function() {

			this.$pubsub.$emit('open.modal', 'listEdit')

		}

	}

}

</script>

<style scoped>

.about {
	z-index: 2;
	margin-bottom: 20px;
	display: flex;
}

.about-text {
	flex-grow: 1;
	color: #333;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 15px;
	border-radius: 4px;
}

.about-text.has-edition {
	border-radius: 0px 4px 4px 0px;
}

.about-text-title {
	font-size: 24px;
	line-height: 24px;
	font-weight: 500;
	margin-bottom: 2px;
}

.about-text-title small {
	opacity: 0.5;
	margin-left: 3px;
	font-size: 14px;
}

.about-text-description {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.about-text-tags {
	padding-top: 7px;
}

.about-text-tags {
	display: flex;
	flex-direction: row;
}

.about-text-tags > div {
	background-color: #37576f;
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	margin-right: 4px;
	padding: 4px 8px;
	border-radius: 4px;
}

.about-stats {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
}

.about-stats-item {
	text-align: center;
	color: #333;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #fff;
	border: 1px solid #ddd;
	margin-left: 20px;
	padding: 15px;
	border-radius: 4px;
}

.about-stats-item-value {
	font-size: 32px;
	font-weight: 500;
}

.about-stats-item-text {
	font-size: 11px;
	font-weight: 400;
	opacity: 0.7;
}

.about-highlights {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
}

.about-highlights-item {
	margin-left: 20px;
	flex-shrink: 0;
	flex-grow: 0;
	width: 300px;
	display: flex;
	flex-direction: row;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.about-highlights-item-avatar {
	width: 80px;
	height: 100%;
	flex-shrink: 0;
	background-color: #fff;
	border-radius: 4px 0px 0px 4px;
}

.about-highlights-item-text {
	padding: 15px;
	color: #333;
	font-size: 12px;
	flex-grow: 1;
	font-weight: 400;
	display: flex;
	max-width: calc(100% - 64px);
	flex-direction: column;
	justify-content: center;
}

.about-highlights-item-text-rank {
	color: #333;
	font-size: 15px;
	font-weight: 500;
}

.about-highlights-item-text-rank span {
	opacity: 0.7;
}

.about-highlights-item-text-movement {
	color: #333;
	font-size: 11px;
	font-weight: 500;
	display: inline-block;
}

.about-highlights-item-text-value {
	font-size: 14px;
	overflow: hidden;
	min-width: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 1px 0px;
}

.about-highlights-item-text-category {
	display: block;
	font-size: 11px;
	line-height: 14px;
	opacity: 0.7;
	margin-top: 2px;
}

.about-edition {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background-color: #4881bb;
	color: #fff;
	font-size: 48px;
    font-weight: 500;
	border-radius: 4px 0px 0px 4px;
}

.about-options {
	display: flex;
	height: 64px;
	flex-direction: column;
	margin-left: 8px;
}

</style>
