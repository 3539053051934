<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Create List

	</template>

	<template v-slot:default>

		<div class="inputs">

			<core-form-input-textbox :autofocus="true" maxlength="32" name="name" v-on:validate="validate" placeholder="Enter name" v-model="inputs.name" :error="is.error" :disabled="is.loading" :validation="validation.name" />

			<core-form-input-textarea name="description" v-on:validate="validate" placeholder="Enter description" v-model="inputs.description" :error="is.error" :disabled="is.loading" :validation="validation.description" />

			<core-form-input-togglelist title="Type" name="type" v-on:validate="validate" v-model="inputs.type" :error="is.error" :disabled="is.loading" :validation="validation.type" :options="typeOptions" />

			<core-form-input-togglelist title="Updates" v-show="inputs.type === 1" name="frequency" v-on:validate="validate" v-model="inputs.frequency" :error="is.error" :disabled="is.loading" :validation="validation.frequency" :options="frequencyOptions" />

			<core-form-input-togglelist title="Positions" v-show="(inputs.type === 1 && inputs.frequency !== 3) || inputs.type === 2" name="ranked" v-on:validate="validate" v-model="inputs.ranked" :error="is.error" :disabled="is.loading" :validation="validation.ranked" :options="rankingOptions" />

		</div>

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Confirm</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				name: '',
				description: '',
				type: false,
				frequency: 1,
				ranked: 1
			},

			validation: {
				name: {
					exists: true,
					max: 32
				},
				description: {
					exists: true
				},
				type: {
					exists: true
				},
				frequency: {
					exists: true
				},
				ranked: {
					exists: true
				}
			}

		}

	},

	computed: {

		typeOptions: function() {

			if (this.$store.getters['context/is/user']) {

				return [
					{value: 1, title: 'Automated', text: 'You will define a filter that automatically generates the list of games. The list can then be set to archive automatically in the future too.'},
					{value: 2, title: 'Manual', text: 'You will add and order games by hand in the list. You can then later update it whenever you want to.'}
				]

			} else {

				return [
					{value: 1, title: 'Automated', text: 'You will define a filter that automatically generates the list. The list can then be set to update automatically in the future too.'},
					{value: 2, title: 'Manual', text: 'You will add and order games by hand in the list. You can then later update it whenever you want to.'}
				]

			}

		},

		frequencyOptions: function() {

			return [
				{value: 1, title: 'Monthly', text: 'The list is automatically updated and archived every month.'},
				{value: 2, title: 'Yearly', text: 'The list is automatically updated and archived every year.'},
				{value: 3, title: 'Live', text: 'The list is live and is not archived when it updates.'}
			]

		},

		rankingOptions: function() {

			return [
				{value: 1, title: 'Tracked', text: 'The games in your list are ranked (e.g. most plays) so you want to track and show their positional changes for each update.'},
				{value: 0, title: 'Not tracked', text: 'The games are not ordered in a meaningful way (e.g. alphabetical) so there is no need to track and show positional changes for each update.'}
			]

		}

	},

	methods: {

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/list/create', {
					data: {
						name: this.inputs.name,
						description: this.inputs.description,
						type: this.inputs.type,
						frequency: this.inputs.frequency,
						ranked: this.inputs.ranked
					}
				}).then(function(json) {

					this.$router.push({
						name: 'List',
						params: {
							list: json.slug
						}
					}).catch(function(e) { console.log(e) })

					this.$emit('close')

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>