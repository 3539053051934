<template>

<com-item :name="'records.distribution.' + type" :size="size" :loading="loading" :is-empty="!active" :title="title[type]">

	<template v-slot:stats>

		<app-visualisation-scatter :data="graph" v-if="active" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'type'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			title: {
				counts: 'Count win %',
				weights: 'Weight win %'
			},
			graph: {
				datasets: [
					{ 
						data: [],
						labels: [],
						colors: [],
						size: 'large'
					}
				],
				axis: {
					points: true,
					x: {
						label: 'Plays',
						offset: true
					},
					y: {
						label: 'Win %',
						offset: true,
						max: 100
					}
				}
			}
		}

	},

	created: function() {
	
		this.visualise()	

	},

	computed: {

		typeLabel: function() {

			if (this.type === 'weights') return 'Weight'
			if (this.type === 'counts') return 'Players'

			return false

		},

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/records/distribution'][this.type]

		},

		active: function() {

			return this.$_.keys(this.data).length && !this.loading

		}

	},

	methods: {
	
		visualise: function() {
		
			this.graph.datasets[0].colors = []
			this.graph.datasets[0].labels = []
			this.graph.datasets[0].data = []

			var winPercent

			this.$_.each(this.data, function(item, index) {

				this.graph.datasets[0].colors.push('#4881bb')
				this.graph.datasets[0].labels.push(item.count)

				winPercent = (item.plays) ? Math.floor((100 / item.plays) * item.wins) : 0

				this.graph.datasets[0].data.push({
					id: index,
					x: item.plays,
					y: winPercent,
					tooltip: this.typeLabel + ': ' + item.count + ' | Plays: ' + item.plays + ' | Win %: ' + winPercent + ')'
				})

			}.bind(this))

		}
	
	}

}

</script>

<style scoped>

</style>