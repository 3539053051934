import Vue from 'vue'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: {}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		lifespan: function(state) {

			return state.data.lifespan || []

		},

		activity: function(state) {

			return state.data.activity || []

		},

		hotness: function(state) {

			return state.data.hotness || []

		},

		sampled: function(state) {

			return state.data.sampled || []

		},

		changes: function(state) {

			return state.data.changes || []

		},

		clusters: function(state) {

			return state.data.clusters || []

		},

		turnover: function(state) {

			return state.data.turnover || []

		},

		trends: function(state) {

			return state.data.trends || []

		},

		summary: function(state) {

			return state.data.summary || false

		},

		overview: function(state) {

			return state.data.overview || {}

		},

		compare: function(state) {

			return state.data.compare || {}

		}

	},

	mutations: {

		reset: function(state) {

			state.data = {}

		},

		set: function(state, data) {

			_.each(data, function(value, key) {

				Vue.set(state.data, key, value)

			})

		}

	},

	actions: {}

}