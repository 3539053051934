<template>

<core-filter-config v-on:expand="onExpandClick" v-on:close="onCloseClick" v-on:save="onSaveClick" v-if="active" :state="is" :loginRequired="true" :selfOnly="true">

	<app-config-template-list-unplayed-type />
	
	<app-config-template-list-unplayed-rated />

	<app-config-template-list-unplayed-trade />

	<app-config-template-list-unplayed-exclude />

</core-filter-config>

</template>

<script>

import Config from 'core/mixin/Config.js'

export default {

	mixins: [Config],

	data: function() {

		return {

			name: 'list.special.unplayed'
			
		}

	},

	computed: {

		list: function() {

			return this.$store.getters['page/list/list']

		},

		edition: function() {

			return this.$store.getters['page/list/edition']

		},

		active: function() {

			return this.list.template === this.$CONSTANTS.LISTS.TEMPLATE.UNPLAYED

		}

	},

	methods: {

		onAfterSave: function(config) {

			this.$router.replace({ 
				name: 'List', 
				params: { 
					list: (config.lists.unplayed.type === 'shame') ? 'shelf-of-shame' : 'shelf-of-opportunity' 
				} 
			}).catch(function(e) { console.log(e) })

			this.$emit('refresh')

		}

	}

}

</script>