export default {

	props: ['data'],

	data: function() {

		return {

			is: {
				ready: false
			}

		}

	},

	watch: {

		data: function(n) {

			if (n) this.fetch()

		}

	},

	created: function() {

		this.fetch()

	},

	methods: {

		fetch: function() {

			if (!this.static) {

				var params = {
					name: this.$route.params.name,
					detail: this.detail,
					section: this.$route.meta.section,
					id: (this.data) ? this.data.id : false
				}

				if (this.params) {

					this.$_.each(this.params, function(param) {

						params[param] = this.data[param] || false

					}.bind(this))

				}

				this.$store.dispatch('api/insights/' + this.$route.params.type + '/detail', params).then(function(json) {

					this.response(json.insights)

					this.is.ready = true	

					this.$emit('ready')

				}.bind(this), function() {

					this.$emit('close')

				}.bind(this))

			} else {

				this.is.ready = true

				this.$emit('ready')

			}

		}

	}

}