<template>

<com-item name="worth.publishers" :is-list="true" :has-more="true" v-on:more="onPublishersClick" :size="size" :is-empty="!active" :loading="loading" title="Publishers">

	<template v-slot:about>

		<p>Which publishers have the highest worth in {{ your }} collection.</p>

		<p class="small"><template v-if="publishersValue === $CONSTANTS.INSIGHTS.WORTH.PUBLISHERS.AVERAGE">Minimum of two games. </template></p>

	</template>

	<template v-slot:stats>
	
		<app-person v-for="(item, index) in publishers" :hideCount="true" :item="item" type="publisher" v-bind:key="index" v-on:more="onMoreClick(item.id)" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="publishersOptions" :value="publishersValue" v-on:change="onPublishersChange" />
						
	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		publishersOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.PUBLISHERS.TOTAL, text: 'Total value'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.PUBLISHERS.AVERAGE, text: 'Average value'})

			return options

		},

		publishersValue: function() {

			return this.$store.getters['filter/insights/worth/publishers']

		},

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'publishers'

		},

		publishers: function() {

			return this.$store.getters['page/insights/worth/publishers']

		},

		active: function() {

			return this.publishers.length && !this.loading

		}

	},

	methods: {

		onPublishersChange: function(value) {

			this.$store.commit('filter/insights/worth/publishers', value)

		},

		onPublishersClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthPublishers',
				filter: this.$store.getters['filter/insights/worth']
			})

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthPublisher',
				id: id
			})

		}

	}

}

</script>

<style scoped>

</style>