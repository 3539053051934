<template>

<core-list-row class="list-row" :id="item.id">

	<template v-slot:fields>

		<core-list-cell type="avatar" :format="{}">

			<core-avatar type="game" :id="item.game.id" class="list-avatar">

				<app-avatar-include :id="item.game.id" filter="plays" />

			</core-avatar>

		</core-list-cell>

		<core-list-cell type="name" class="list-name" :format="{filter: 'plays', sort: 'game.name'}">

			<div class="list-name-game">
				<core-gamename :game="item.game" :id="item.id" class="list-name-game-name" />
			</div>

			<app-tags :item="item" class="list-name-tags" />

			<div class="list-name-date" v-if="window.width < 400 && isUser">
				Last played on {{ contextual.user.play_last | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}
			</div>

			<div class="list-name-date" v-if="window.width < 400 && !isUser">
				Last played on {{ contextual.users.play_last | formatDate($util.date.format('Do', 'MMMM', 'YYYY')) }}
			</div>

		</core-list-cell>

		<core-list-cell type="stat" :format="{filter: 'plays', sort: 'plays'}">

			<template v-if="isUser">

				<template v-if="item.context">

					{{ item.plays.user.context }} <small class="list-small">({{ item.plays.user.all }})</small>

				</template>

				<template v-if="!item.context">

					{{ item.plays.user.all }}

				</template>

			</template>

			<template v-if="!isUser">

				<template v-if="item.context">

					{{ item.plays.group.context }} <small class="list-small">({{ item.plays.group.all }})</small>

				</template>

				<template v-if="!item.context">

					{{ item.plays.group.all }}

				</template>

			</template>

		</core-list-cell>

		<core-list-cell class="list-counts" v-if="window.width >= 460" :format="{}">

			<app-game-counts :item="item" :inline="true" />

		</core-list-cell>

		<core-list-cell type="stat" v-if="!isUser" :format="{filter: 'plays', sort: 'plays.users'}">

			{{ contextual.users.played | hashCount }}

		</core-list-cell>

		<core-list-cell type="stat" :empty="winPercent === false" v-if="isUser" :format="{filter: 'plays', sort: 'plays.winpercent'}">

			<core-progress :percent="winPercent" v-if="winPercent !== false" class="list-progress" />

		</core-list-cell>

		<core-list-cell type="date" v-if="window.width >= 400" :format="{filter: 'plays', sort: 'play.date'}">

			<core-date :date="contextual.user.play_last" v-if="isUser" />
			<core-date :date="contextual.users.play_last" v-if="!isUser" />

		</core-list-cell>

		<core-list-cell type="stat" :format="{filter: 'plays', sort: 'time'}">

			<template v-if="isUser">

				<template v-if="item.context">

					{{ item.plays.user.duration.context | hours }} <small class="list-small">({{ item.plays.user.duration.all | hours }})</small>

				</template>

				<template v-if="!item.context">

					{{ item.plays.user.duration.all | hours }}

				</template>

			</template>

			<template v-if="!isUser">

				<template v-if="item.context">

					{{ item.plays.group.duration.context | hours }} <small class="list-small">({{ item.plays.group.duration.all | hours }})</small>

				</template>

				<template v-if="!item.context">

					{{ item.plays.group.duration.all | hours }}

				</template>

			</template>

		</core-list-cell>

		<core-list-cell-actions :item="item" filter="plays" />

	</template>

</core-list-row>

</template>

<script>

import gameCounts from './game/Counts'

export default {

	props: ['item', 'ready'],

	components: {
		'app-game-counts': gameCounts
	},

	data: function() {

		return {

			contextual: false,
			winPercent: 0

		}

	},

	watch: {

		item: {

			deep: true,

			handler: function() {

				this.onChange()

			}

		}

	},

	created: function() {

		this.onChange()

	},

	methods: {

		onChange: function() {

			this.contextual = (this.item.context) ? this.item.context : this.item

			if (this.isUser) {

				if (this.item.record.results) {

					this.winPercent = (100 / this.item.record.results) * this.item.record.wins

				} else {

					this.winPercent = false

				}

			}

		}

	}

}

</script>

<style scoped>

.list-avatar {
	width: 48px;
	height: 48px;
}

.list-name {
	min-height: 48px;
}

.list-name-game {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	max-width: 100%;
}

.is-mobile .list-name-game {
	flex-direction: column;
}

.list-name-game-name {
	flex-grow: 1;
	overflow: hidden;
	min-width: 0;
	display: block;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	line-height: 16px;
	cursor: pointer;
	overflow: hidden;
	color: #333;
	font-weight: 500;
	order: 2;
}

.is-desktop .list-name-game-name:hover {
	text-decoration: underline;
}

.is-smaller .list-name-game-name,
.is-mobile .list-name-game-name {
	font-size: 14px;
}

.is-mobile .list-name-game-name {
	white-space: normal;
	overflow: visible;
}

.list-name-tags {
	margin-top: 2px;
}

.list-name-date {
	font-size: 11px;
	margin-top: 2px;
	color: #666;
}

.list-counts {
	width: 73px;
	padding: 0px 10px;
}

.list-small {
	font-size: 11px;
	color: #666;
	margin-left: 4px;
}

.list-progress {
	width: 32px!important;
	height: 32px!important;
}

.list-progress >>> .progress-value {
	width: 24px!important;
	height: 24px!important;
	line-height: 26px!important;
	font-size: 11px!important;
}

</style>