<template>

<core-filter-advanced-group title="Age" :count="count">

	<core-filter-input-range text="Official minimum age" min="2" max="21" maxText="21+" :filter="value('age/official')" :tooltip="true" step="1" pips="2" decimals="0" density="10" />

	<core-filter-input-range text="Community minimum age" min="2" max="21" maxText="21+" :filter="value('age/community')" :tooltip="true" step="1" pips="2" decimals="0" density="10" />

	<core-filter-input-range text="Min. votes" min="0" max="100" :filter="value('threshold/age')" :tooltip="true" step="1" pips="10" decimals="0" density="10" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	props: [],

	data: function() {

		return {
			fields: ['age.official', 'age.community', 'threshold.age']
		}

	}

}

</script>