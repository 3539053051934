import collection from './page/collection'
import users from './page/users'
import activity from './page/activity'
import plays from './page/plays'
import insights from './page/insights'
import lists from './page/lists'
import list from './page/list'
import ratings from './page/ratings'

export default {

	namespaced: true,

	modules: {
		collection: collection,
		activity: activity,
		users: users,
		plays: plays,
		insights: insights,
		lists: lists,
		list: list,
		ratings: ratings
	},

	state: {},

	getters: {

		collection: function(state, getters) {

			return getters['collection/list']

		},

		users: function(state, getters) {

			return getters['users/list']

		},

		ratings: function(state, getters) {

			return getters['ratings/list']

		},

		activity: function(state, getters) {

			return getters['activity/list']

		},

		plays: function(state, getters) {

			return getters['plays/list']

		},

		insights: function(state, getters) {

			return getters['insights/all']

		},

		lists: function(state, getters) {

			return getters['lists/list']

		},

		list: function(state, getters) {

			return getters['list/games']

		}

	},

	mutations: {},

	actions: {}

}