<template>

<router-link :to="{name: 'Home'}" class="logo" :class="{'is-large': large}">

    <div class="logo-icon"></div>
    <div class="logo-text">geek<span>group</span><div class="logo-byline" v-if="byline">rocket <span>fuel</span> for bgg collections</div></div>

</router-link>

</template>

<script>

export default {

    props: ['large', 'byline']

}

</script>

<style scoped>

.logo {
    display: flex;
    align-items: center;
    height: 64px;
    margin-left: 20px;
    width: 300px;
}

.is-mobile .logo {
    margin-left: 10px;
    height: 48px;
}

.logo.is-large {
    height: auto;
    flex-direction: column;
    margin-left: 0px;
    justify-content: center;
    width: 480px;
}

.is-mobile .logo.is-large {
    width: 100%;
}

.logo-icon {
    width: 48px;
    height: 48px;
    background-size: auto 44px;
    background-position: calc(50% - 1px) calc(50% + 1px);
    background-repeat: no-repeat;
    background-image: url(~@/monty/assets/logo.svg);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.is-mobile .logo-icon {
    width: 32px;
    height: 32px;
    background-size: auto 28px;
}

.logo.is-large .logo-icon {
    width: 192px;
    height: 192px;
    background-position: calc(50% - 4px) calc(50% + 4px);
    background-size: contain;
    margin-bottom: 10px;
}

.is-mobile .logo.is-large .logo-icon {
    width: 156px;
    height: 156px;
}

.logo-text {
    font-size: 24px;
    line-height: 44px;
    height: 48px;
    margin-left: 20px;
    letter-spacing: 1.5px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-family: 'Barlow', sans-serif;
}

.is-mobile .logo-text {
    margin-left: 10px;
    font-size: 20px;
}

.logo.is-large .logo-text {
    font-size: 48px;
    height: auto;
    margin-left: 20px;
    padding-right: 20px;
    letter-spacing: 3px;
}

.logo-text span {
    color: #ffc563;
}

.logo-byline {
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
    letter-spacing: 2px;
    color: #dedede;
}

.logo-byline span {
    font-style: italic;
    color: #ffc563;
}

</style>
