<template>
	
<div class="players" v-if="players.length" :class="{'is-inline': inline}">

	<div class="players-item" v-for="(player, index) in players" :key="index">
		
		<core-avatar type="user" :id="player.id" class="players-item-avatar" tooltip="1" :tooltipSmall="player.score" v-if="player.id">

			<div class="players-item-color" v-if="player.color">
				<div :data-color="color" v-for="color in colors(player.color)" :key="color" />
			</div>

		</core-avatar>

		<div class="players-item-avatar is-guest" v-tooltip="tooltip(player)" v-if="!player.id">

			<i class="fa fa-user"></i>

			<div class="players-item-color" v-if="player.color">
				<div :data-color="color" v-for="color in colors(player.color)" :key="color" />
			</div>

		</div>

		<div class="players-item-name" v-if="player.id && !inline">
			
			<span><a :href="player.link" target="_blank">{{ player.primary }}</a></span>
			<small v-if="player.secondary">{{ player.secondary }}</small>

		</div>

		<div class="players-item-name" v-if="!player.id && !inline">
			
			{{ player.name }}

		</div>

		<div class="players-item-log" v-if="player.id === item.play.user && !isUser" v-tooltip="'Logged by'">
			
			<i class="fa fa-user-edit" v-if="!inline"></i>
			<i class="fa fa-pen" v-if="inline"></i>

		</div>

		<div class="players-item-win" v-if="player.win" v-tooltip="'Winner'">
			
			<i class="fa fa-crown"></i>

		</div>

		<div class="players-item-score" v-if="player.score && !inline">

			{{ player.score }}

		</div>

	</div>
		
</div>

</template>

<script>

export default {

	props: ['item', 'inline'],

	data: function() {

		return {
			players: []
		}

	},

	created: function() {

		var players = []

		if (!this.item.players.length) {

			if (!this.isUser) {

				var data = this.$store.getters['data/users'][this.item.play.user]

				players.push({
					id: this.item.play.user,
					win: false,
					score: false,
					color: false,
					primary: data.fullname || data.name,
					secondary: (data.fullname) ? data.name : false,
					link: this.$util.url.user(data.name)
				})

			}

		} else {

			var hasScores = false
			var hasWinner = false

			this.$_.each(this.item.players, function(player) {

				var data = (player.id) ? this.$store.getters['data/users'][player.id] : {}

				players.push({
					id: player.id,
					sortname: (player.id) ? data.sortname : player.name.toLowerCase(),
					name: player.name,
					win: player.win,
					score: player.score,
					color: player.color.toLowerCase(),
					primary: data.fullname || data.name,
					secondary: (data.fullname) ? data.name : false,
					link: this.$util.url.user(data.name)
				})

				if (player.score) hasScores = true
				if (player.win) hasWinner = true

			}.bind(this))

			// first sort alphabetically
			players = this.$_.sortBy(players, 'sortname')

			// if there are scores, sort by score
			if (hasScores) {

				players = this.$_.sortBy(players.reverse(), 'score').reverse()

			}

			// has no scores but does have winner, put them top
			if (hasWinner) {

				players = this.$_.sortBy(players.reverse(), 'win').reverse()

			}

		}

		this.players = players

	},

	methods: {

		colors: function(color) {
		
			return color.split(/[/,]+/)

		},

		tooltip: function(player) {

			var html = []

			html.push(player.name)

			if (player.score) html.push('<small>' + player.score + '</small>')

			return html.join('')

		}

	}

}

</script>

<style scoped>

.players {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-wrap: wrap;
	border-top: 1px solid #ddd;
}

.players.is-inline {
	border-top: 0px;
	flex-direction: row;
}

.players-item {
	display: flex;
	flex-direction: row;
	padding: 4px 10px;
}

.players.is-inline .players-item {
	padding: 8px 0px;
	margin-right: 4px;
}

.players-item:nth-child(even) {
	background-color: rgba(12, 137, 178, 0.05);
}

.players.is-inline .players-item:nth-child(even) {
	background-color: transparent;
}

.players-item-avatar {
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	margin-right: 10px;
}

.players.is-inline .players-item-avatar {
	margin-right: 0px;
}

.players-item-avatar.is-guest {
	line-height: 32px;
	text-align: center;
	background-color: #ccc;
	color: #999;
}

.players-item-name {
	font-size: 14px;
	font-weight: 400;
	color: #333;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.is-desktop .players-item-name a:hover {
	text-decoration: underline;
}

.players-item-name small {
	font-size: 11px;
	font-weight: 300;
	color: #666;
	margin-top: 2px;
	display: block;
}

.players-item-score {
	font-size: 14px;
	font-weight: 500;
	line-height: 32px;
	padding-left: 10px;
	flex-shrink: 0;
}

.players-item-win {
	flex-shrink: 0;
	line-height: 32px;
	font-size: 14px;
	margin-right: 2px;
	margin-left: 5px;
	color: gold;
}

.players.is-inline .players-item-win {
	position: absolute;
	top: -5px;
	width: 32px;
	text-align: center;
	left: 0px;
	font-size: 10px;
	margin: 0px;
	line-height: 14px;
}

.players-item-log {
	flex-shrink: 0;
	line-height: 32px;
	font-size: 14px;
	margin-left: 5px;
	color: #ccc;
}

.players.is-inline .players-item-log {
	position: absolute;
	bottom: -6px;
	font-size: 10px;
	width: 32px;
	text-align: center;
	left: 0px;
	margin: 0px;
	line-height: 14px;
}

.players-item-color {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	position: absolute;
	right: -1px;
	bottom: -1px;
	overflow: hidden;
	border: 1px solid #fff;
	background-color: transparent;
	display: flex;
}

.players-item-color div {
	height: 14px;
	flex-grow: 1;
	flex-shrink: 0;
}
	
.players-item-color div[data-color="black"] 	{ background-color: black; }
.players-item-color div[data-color="blue"] 		{ background-color: blue; }
.players-item-color div[data-color="brown"] 	{ background-color: brown; }
.players-item-color div[data-color="gold"] 		{ background-color: gold; }
.players-item-color div[data-color="gray"] 		{ background-color: gray; }
.players-item-color div[data-color="green"] 	{ background-color: green; }
.players-item-color div[data-color="orange"] 	{ background-color: orange; }
.players-item-color div[data-color="pink"] 		{ background-color: pink; }
.players-item-color div[data-color="purple"] 	{ background-color: purple; }
.players-item-color div[data-color="white"] 	{ border: 1px solid rgba(0, 0, 0, 0.5); background-color: white; }
.players-item-color div[data-color="red"] 		{ background-color: red; }
.players-item-color div[data-color="yellow"] 	{ background-color: yellow; }

</style>
