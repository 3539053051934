<template>

<core-filter-advanced-group title="General" :count="count">

	<core-filter-input-daterange :filter="value('date')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['date.from', 'date.to']
		}
	}

}

</script>