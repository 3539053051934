<template>

<div class="content-empty">

	<h2 v-if="title">{{ title }}</h2>

	<p v-if="text">{{ text }}</p>

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['title', 'text']

}

</script>

<style scoped>

.content-empty {
	text-align: center;
	color: #fff;
	padding-bottom: 56px;
}

.content-empty h2 {
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 15px;
	color: #999;
}

.content-empty p {
	font-size: 16px;
	padding: 0px 16px;
	margin-bottom: 15px;
	color: #666;
}

</style>
