<template>

<div class="item" :class="{'is-dark': dark}">

	<div class="item-name">

		<div class="item-name-wrapper" :class="{'is-dead': nolink}" v-on:click.stop="onMoreClick(item.id)">{{ item.name }}</div>

	</div>

	<core-ratings v-if="item.games" :games="item.games" :max="3" :count="gamesCount(item)" class="item-games" v-on:more="onMoreClick(item.id)" />

	<core-rating v-if="item.rating" :value="item.rating" :count="item.count" class="item-rating" />

	<div v-if="item.count && !item.rating" class="item-count">{{ item.count }}</div>

</div>

</template>

<script>

export default {

	props: ['item', 'gamesCountProperty', 'nolink', 'dark'],

	methods: {

		onMoreClick: function(id) {

			this.$emit('more', id)

		},

		gamesCount: function(item) {

			var field = (item.gamesCount) ? 'gamesCount' : (this.gamesCountProperty || 'count')

			return item[field]

		}

	}
	
}

</script>

<style scoped>

.item {
	width: 100%;
	height: 64px;
	display: flex;
	padding: 8px 15px 8px 15px;
	flex-direction: row;
}

@media only screen and (max-width: 767px) {

	.item {
		padding: 8px 8px;
		height: auto;
	}

}

.item:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.item.is-dark:nth-child(odd) {
	background-color: rgba(255, 255, 255, 0.05);
}

.item-name {
	padding: 0px;
	flex-grow: 1;
	min-width: 0;
}

.item-name-wrapper {
	font-size: 16px;
    line-height: 16px;
    line-height: 48px;
    font-weight: 400;
    color: #333;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.item.is-dark .item-name-wrapper {
	color: #fff;
}

.is-desktop .item-name-wrapper:not(.is-dead):hover {
	text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 767px) {

	.item-name a {
		font-size: 14px;
	}

}

.item-count {
	width: 48px;
    flex-shrink: 0;
    text-align: right;
    font-size: 24px;
    line-height: 48px;
    font-weight: 400;
    color: #333;
}

.item.is-dark .item-count {
	color: #fff;
}

@media only screen and (max-width: 767px) {

	.item-count {
		font-size: 18px;
	}

}

.item-games {
	flex-shrink: 0;
	width: 128px;
}

.item-rating {
	flex-shrink: 0;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 18px;
	font-weight: 400;
}

</style>