<template>

<com-item name="plays.loctions" :is-list="true" :has-more="true" v-on:more="onMoreClick" :size="size" :is-empty="!active" :loading="loading" title="Locations">

	<template v-slot:about>

		<p>Where did {{ your }} play their games in {{ periodText }}.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<app-thing v-for="(item, index) in locations" :nolink="true" :item="item" v-bind:key="index" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		locations: function() {

			return this.$store.getters['page/insights/plays/locations']

		},

		active: function() {

			return this.locations.length && !this.loading

		}

	},

	methods: {

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysLocations'
			})

		}

	}

}

</script>

<style scoped>

</style>