<template>
	
<core-list-expand-section :empty="!item.players.length" placeholder="No players have been logged." v-if="selected" class="opponents">

	<vue-good-table :columns="columns" :rows="players" styleClass="vgt-table striped condensed" :sort-options="{ enabled: true, initialSortBy: {field: 'name', type: 'asc'} }" />

</core-list-expand-section>

</template>

<script>

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

export default {

	name: 'opponents',

	props: ['item', 'format', 'selected'],

	components: {
		VueGoodTable
	},

	data: function() {

		return {
			columns: [
				{ label: 'Player', field: 'name' },
				{ label: 'Plays', field: 'plays', type: 'number', width: '80px' },
				{ label: 'Results', field: 'results', type: 'number', width: '80px' },
				{ label: 'Wins', field: 'wins', type: 'number', width: '80px' },
				{ label: 'Win %', field: 'percent', type: 'number', width: '80px' }
			]
		}

	},

	computed: {

		players: function() {

			var players = []

			this.$_.each(this.item.players, function(player) {

				player.percent = (player.results) ? ((100 / player.results) * player.wins).toFixed(1) : ''

				players.push(player)

			})

			return players

		}

	}

}

</script>

<style scoped>

.opponents {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 0px!important;
}

.is-mobile .opponents {
	padding: 0px!important;
}

.vgt-wrap {
	width: 100%;
}

.vgt-wrap >>> table {
	border: 0px!important;
}

.vgt-wrap >>> thead th {
	font-weight: 500!important;
	font-size: 14px!important;
	padding: 5px 2px!important;
	line-height: 22px!important;
	border-bottom: 1px solid #eee!important;
	background: #fff!important;
	color: #333!important;
	text-align: center!important;
}

.vgt-wrap >>> thead th:hover {
	background: #eee!important;
}

.vgt-wrap >>> tbody td {
	font-weight: 400!important;
	font-size: 14px!important;
	padding: 5px 2px!important;
	line-height: 22px!important;
	border: 0px!important;
	color: #333!important;
	text-align: center!important;
}

.vgt-wrap >>> thead th:first-child,
.vgt-wrap >>> tbody td:first-child {
	text-align: left!important;
}

.is-mobile .vgt-wrap >>> thead th:first-child,
.is-mobile .vgt-wrap >>> tbody td:first-child {
	padding-left: 8px!important;
	min-width: 160px!important;
}

.is-mobile .vgt-wrap >>> thead th:last-child,
.is-mobile .vgt-wrap >>> tbody td:last-child {
	padding-right: 8px!important;
}

</style>