import _ from 'underscore'
import Util from 'app/util'
import router from 'app/router'

var defaultState = Util.filter.templates.users()

export default {

	namespaced: true,

	state: {

		data: defaultState,

		preset: 0

	},

	getters: {

		preset: function(state) {

			return state.preset

		},

		'active/count': function(state, getters) {

			var total = 0

			_.each(getters['active'], function(count, key) {

				if (key.indexOf('layout') !== 0) {

					if (typeof count === 'boolean') {

						total += (count) ? 1 : 0 

					} else {

						total += parseInt(count)

					}
					
				}

			})

			return total

		},

		active: function(state) {

			return {
				'text': state.data.text.trim()
			}

		},

		values: function(state) {

			return state.data

		},

		text: function(state) {

			return state.data.text

		},

		sort: function(state) {

			return state.data.sort

		},

		sortReverse: function(state) {

			return state.data.sortReverse

		}

	},

	mutations: {

		set: function(state, data) {

			state.data = Util.filter.autofill(data, 'users')

		},

		preset: function(state, id) {

			state.preset = id

		},

		reset: function(state) {

			state.data = Util.filter.autofill({}, 'users')

			state.preset = 0

		},

		sort: function(state, id) {

			var reverse = false

			if (typeof id === 'object') {

				var parts = state.data.sort.split(',')

				if (id.stack) {

					if (state.data.sortReverse && parts.length === 1) {
					
						parts[0] = '-' + parts[0]
					
					}

					if (_.contains(parts, id.value)) {

						parts[parts.indexOf(id.value)] = '-' + id.value
					
					} else if (_.contains(parts, '-' + id.value)) {

						parts[parts.indexOf('-' + id.value)] = id.value
					
					} else {

						parts.push((id.reverse) ? '-' + id.value : id.value)
					
					}

					state.data.sort = parts.join(',')

				} else {

					if (id.reverse !== undefined) {
					
						reverse = id.reverse

					} else if (state.data.sort === id.value && !state.data.sortReverse) {
					
						reverse = true
					
					} 

					state.data.sort = (reverse) ? '-' + id.value : id.value
				
				}

			} else {

				if(state.data.sort === id) reverse = !state.data.sortReverse
				state.data.sort = (reverse) ? '-' + id : id

			}

			state.data.sortReverse = false

		},

		text: function(state, text) {

			state.data.text = text

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params.text) context.state.data.text = params.text

					if (params.sort) {

						context.state.data.sort = params.sort

					}

					if (params.sortReverse) {

						context.state.data.sortReverse = parseInt(params.sortReverse) === 1

					}

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}

			if (context.state.data.text) {

				urlParams.text = context.state.data.text

			}

			if (context.state.data.sort !== 'firstname') {

				urlParams['sort'] = context.state.data.sort 

			}

			if (context.state.data.sortReverse) {

				urlParams['sortReverse'] = 1

			}

			router.push({name: 'Users', query: urlParams}).catch(function() {})

		}

	}

}