<template>

<div class="content-wrapper" v-bind:class="{'is-empty': empty, 'is-no-results': noResults}">

	<slot></slot>

	<div class="content-wrapper-loading" v-if="loading"><i class="fa fa-spin fa-spinner"></i></div>

</div>

</template>

<script>

export default {

	props: ['empty', 'noResults', 'loading']

}

</script>

<style scoped>

.content-wrapper {
	width: 100%;
}

.content-wrapper-loading {
	position: fixed;
	left: calc(50% + 160px);
	top: 50%;
	transform: translate(-50%, -50%);
	color: #ffc563;
	font-size: 48px;
}

.is-small .content-wrapper-loading {
	left: 50%;
}

</style>
