<template>

<com-item name="shelf.overview" :is-overview="(window.is.mobile) ? '3x4' : 4" :size="size" :loading="loading" :is-empty="!active" title="Overview">

	<template v-slot:about>

		<p>A summary of {{ your }} shelf.</p>

		<p class="small">Note: All shelf data is tracked and calculated by geekGroup, so only games on {{ your }} shelf since {{ you }} started using this app are represented.</p>

	</template>

	<template v-slot:stats>

		<com-overview label="Current Shelf Size" type="number" :value="overview.currentSize" />
		
		<com-overview label="Highest Shelf Size" :sublabel="overview.highestSize.month" type="number" :value="overview.highestSize.value" v-if="overview.highestSize" />
		
		<com-overview label="Lowest Shelf Size" :sublabel="overview.lowestSize.month" type="number" :value="overview.lowestSize.value" v-if="overview.lowestSize" />
		
		<com-overview label="Avg. Shelf Size" type="number" :value="overview.averageSize" v-if="overview.averageSize" />

		<com-overview label="Current Collection %" type="percent" :value="overview.currentPercent" />

		<com-overview label="Highest Collection %" :sublabel="overview.highestPercent.month" type="percent" :value="overview.highestPercent.value" v-if="overview.highestPercent" />

		<com-overview label="Lowest Collection %" :sublabel="overview.lowestPercent.month" type="percent" :value="overview.lowestPercent.value" v-if="overview.lowestPercent" />

		<com-overview label="Avg. Collection %" type="percent" :value="overview.averagePercent" v-if="overview.averagePercent" />

		<com-overview label="Total Cumulative Days" type="number" :value="overview.totalCurrent" />

		<com-overview label="Avg. Days on Current Shelf" type="number" :value="overview.averageCurrent" />

		<com-overview label="Avg. Days on All-time Shelf" type="number" :value="overview.averageAll" />

		<com-overview label="Unplayed When Removed" type="number" :value="overview.unplayed" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comOverview from './../common/Overview'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-overview': comOverview
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		overview: function() {

			return this.$store.getters['page/insights/shelf/overview']

		},

		active: function() {

			return this.overview && !this.loading

		}

	}

}

</script>

<style scoped>

</style>