<template>

<div class="tab" v-on:click.stop="$emit('click')" v-bind:class="{'is-active': selected}">

	<span class="tab-text">{{ text }}</span> 
	<span class="tab-count" v-if="count || subcount">{{ count }}<template v-if="subcount"> / {{ subcount }}</template></span>

</div>

</template>

<script>

export default {

	props: ['text', 'value', 'count', 'subcount', 'selected']

}

</script>

<style scoped>

.tab {
	height: 32px;
	min-width: 32px;
	line-height: 28px;
	font-weight: 400;
	font-size: 14px;
	user-select: none;
	border: 1px solid transparent;
	padding: 0px 8px;
	cursor: pointer;
	flex-shrink: 0;
	color: #666;
	white-space: nowrap;
	display: flex;
	align-items: center;
	border-radius: 4px;
}

@media only screen and (max-width: 767px) {

	.tab {
		padding: 0px 4px;
	}

}

.tab-count {
	background-color: #999;
	border-radius: 4px;
	color: #fff;
	font-size: 11px;
	line-height: 14px;
	margin-left: 5px;
	padding: 2px 4px;
}

@media only screen and (max-width: 767px) {

	.tab-count {
		margin-left: 1px;
		padding: 2px 4px;
	}

}

@media only screen and (max-width: 379px) {

	.tab-count {
		display: none;
	}

}

.is-desktop .tab:hover .tab-text {
	color: #333;
}

.is-desktop .tab:hover .tab-count {
	background-color: #666;
}

.tab.is-active {
	cursor: inherit;
	color: #000;
}

.tab.is-active .tab-count {
	background-color: #666;
}

.is-desktop .tab.is-active:hover .tab-text {
	text-decoration: none;
	color: #000;
}

</style>