<template>

<div class="togglelist" v-bind:class="{'is-disabled': disabled}">

	<div v-if="title" class="togglelist-title"><span>{{ title }}</span></div>

	<div class="togglelist-item" v-on:click="onOptionClick(item.value)" v-bind:key="item.value" v-bind:class="{'is-selected': selected === item.value}" v-for="item in options">

		<div class="togglelist-item-check"><i class="fa fa-check"></i></div>
		<div class="togglelist-item-text">
			<b>{{ item.title }}</b>
			<small>{{ item.text }}</small>
		</div>

	</div>

</div>

</template>

<script>

import InputValidation from 'core/mixin/form/input/Validation.js'

export default {

	mixins: [InputValidation],

	props: [
		'title',
		'name',
		'value',
		'disabled',
		'error',
		'validation',
		'background',
		'options'
	],

	data: function() {

		return {

			selected: false

		}

	},

	created: function() {

		this.selected = this.value

	},

	methods: {

		onOptionClick: function(value) {

			this.selected = value
			this.$emit('input', value)

		},

		validate: function(rules) {

			if (rules.exists) {

				if (this.value === false) {

					this.feedback.push('required')

				}

			}

		}

	}

}

</script>

<style scoped>

.togglelist {
	margin-bottom: 15px;
	overflow: hidden;
}

.togglelist.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.togglelist-title {
	color: #fff;
	font-size: 16px;
	height: 8px;
	font-weight: 500;
	margin-bottom: 22px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.togglelist-title span {
	background-color: #2b5870;
	min-width: 80px;
	padding-right: 15px;
	display: inline-block;
}

.togglelist-item {
	display: flex;
	cursor: pointer;
	align-items: flex-start;
	margin-bottom: 7px;
}

.togglelist-item:last-child {
	border-bottom: 10px;
}

.togglelist-item-check {
	width: 40px;
	min-width: 40px;
	height: 40px;
	border-radius: 20px;
	text-align: center;
	line-height: 40px;
	font-size: 20px;
	color: #024359;
	background-color: rgba(255, 255, 255, 0.25);
}

.togglelist-item .togglelist-item-check .fa {
	opacity: 0;
}

.is-desktop .togglelist-item:hover .togglelist-item-check .fa,
.togglelist:not(.is-disabled) .togglelist-item.is-selected .togglelist-item-check .fa {
	opacity: 1;
}

.togglelist:not(.is-disabled) .togglelist-item.is-selected .togglelist-item-check {
	color: #024359;
	background-color: #fff;
}

.togglelist-item-text {
	min-height: 40px;
	padding: 3px 15px;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
}

.togglelist-item-text small {
	font-size: 14px;
	line-height: 18px;
	margin-top: 7px;
	min-height: 32px;
	font-weight: 400;
	display: block;
	color: #fff;
}

</style>
