<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>{{ description}} Excludes expansions.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in divisive" :item="item"  ratings="userRatings" v-bind:key="index" :subtext="subtext(item)" v-on:ratings="onRatingsClick(item.id)" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'divisive',
			params: [
				'compareType'
			],
			divisive: []
		}

	},

	computed: {

		title: function() {

			return (this.data.compareType === 'most') ? 'Most divisive' : 'Least divisive'

		},

		description: function() {

			return (this.data.compareType === 'most') ? 'The games that cause the biggest split in opinion between members.' : 'The games that bring the most consensus between members.'

		}

	},

	methods: {

		onRatingsClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsGameRatings',
				id: id,
				child: true
			})

		},

		response: function(json) {

			this.divisive = json.divisive

		},

		subtext: function(item) {

			return 'Divisive score of ' + item.diff.toFixed(2)

		}

	}

}

</script>