<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ titles[data.index] }}</h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" />.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :subtext="subtext(item)" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'sampled',
			games: [],
			params: ['index'],
			titles: {
				0: '0 plays',
				1: '1 play',
				2: '2 play',
				3: '3+ plays'
			}
		}

	},

	methods: {	

		response: function(json) {

			this.games = json.games

		},

		subtext: function(item) {

			var parts = []
			
			parts.push('Added ' + this.$options.filters.formatDate(item.added, this.$util.date.format('Do', 'MMMM', 'YYYY')))

			if (item.removed) {

				parts.push(' and removed ' + this.$options.filters.formatDate(item.removed, this.$util.date.format('Do', 'MMMM', 'YYYY')))

			}

			return parts.join('')

		}

	}

}

</script>

<style scoped>

.status {
	width: 48px;
	height: 48px;
	text-align: right;
	line-height: 48px;
	font-size: 20px;
}

.status .fa.is-added {
	color: #3bce5a;
}

.status .fa.is-removed {
	color: #e27570;
}

</style>