<template>

<div class="input">

	<span class="input-text">{{ text }} <small v-if="subtext">{{ subtext }}</small></span>

	<core-avatar v-on:click.native="onClick(user)" :overrideClick="true" v-for="user in users" v-bind:key="user" type="user" :id="user" class="input-avatar" tooltip="1" v-bind:class="{'is-active': isActive(user)}"></core-avatar>

</div>

</template>

<script>

export default {

	props: [
		'filter', 'users', 'text', 'subtext'
	],

	computed: {

		value: function() {

			return this.$store.getters['filter/' + this.filter]

		}

	},

	methods: {

		onClick: function(id) {

			if (this.isActive(id)) {

				this.$store.commit('filter/' + this.filter, {
					id: id,
					remove: true
				})

			} else {

				this.$store.commit('filter/' + this.filter, id)

			}

		},

		isActive: function(id) {

			return this.$_.contains(this.value, id)

		}

	}

}

</script>

<style scoped>

.input {
	position: relative;
	padding: 0px 0px;
	margin-bottom: 8px;
	display: flex;
	flex-wrap: wrap;
}

.input-text {
	font-size: 12px;
	display: block;
	color: #fff;
	font-weight: 400;
	width: 100%;
	padding-bottom: 8px;
}

.input-text small {
	font-size: 10px;
	opacity: 0.75;
	font-weight: 300;
}

.input-avatar {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	margin: 0px 8px 8px 0px;
	opacity: 0.5;
}

.is-desktop .input-avatar:hover,
.input-avatar.is-active {
	opacity: 1;
}

</style>
