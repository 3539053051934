<template>

<core-filter-advanced-group title="Results" :count="count">

	<core-filter-input-range text="Limit games" :tooltip="true" min="0" max="100" :filter="value('limit')" pips="10" step="1" decimals="0" density="10" />

	<core-filter-input-toggle v-if="!hideRandom" text="Randomise selection" on="Yes" off="No" :filter="value('random')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	props: ['hideRandom'],

	data: function() {

		return {
			fields: ['limit', 'random']
		}
	}

}

</script>