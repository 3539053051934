<template>

<div class="inner content-page-inner" :class="{'is-grid': isGrid}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['isGrid']

}

</script>

<style scoped>

.content-page-inner {
	padding: 0px;
	margin: 0px;
	max-width: 100%;
}

.content-page-inner.is-grid {
	display: grid;
	grid-gap: 40px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.is-smaller .content-page-inner.is-grid {
	grid-gap: 20px;
}

.is-tablet .content-page-inner.is-grid {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.is-mobile .content-page-inner.is-grid {
	grid-template-columns: 1fr 1fr;
	padding: 10px;
	grid-gap: 20px;
}

</style>
