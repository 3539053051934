<template>

<core-modal v-on:close="onClose" :nopadding="true" :loading="is.loading">

	<template v-slot:head>

		My Notifications

	</template>

	<template v-slot:default>

		<div class="notification" v-for="notification in notifications" :key="notification.id" v-tooltip="'Click to read more'" v-on:click="onNotificationClick(notification)">

			<div class="notification-icon"><i :class="icons[notification.type]" /></div>

			<div class="notification-info">

				<div class="notification-info-title">{{ notification.name }}</div>

				<div class="notification-info-date">{{ notification.date | fromNow }}</div>

			</div>

		</div>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {
	
		return {
			is: {
				loading: true
			},
			icons: {
				1: 'fa-solid fa-megaphone',
				2: 'fa-solid fa-rotate'
			}
		}
	},

	created: function() {
	
		if (this.notifications.length) {
		
			this.is.loading = false

		} else {
		
			this.load()
		
		}
	
	},

	computed: {
	
		notifications: function() {
		
			return this.$store.getters['api/app/notifications']
		
		}
	
	},

	methods: {

		load: function() {
		
			this.$store.dispatch('api/app/notifications').then(function(json) {
			
				this.$store.commit('api/app/notifications', json.notifications)

				this.$store.commit('session/notifications', 0)

				this.is.loading = false
			
			}.bind(this))
		
		},

		onNotificationClick: function(notification) {
		
			this.$emit('close')

			this.$pubsub.$emit('open.modal', {
				id: 'userNotification',
				notification: notification
			})

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

.notification {
	display: grid;
	grid-template-columns: 40px auto;
	grid-gap: 15px;
	padding: 20px 15px;
	cursor: pointer;
}

.notification:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.1);
}

.notification:hover {
	background-color: rgba(0, 0, 0, 0.15);
}

.notification-icon {
	width: 40px;
	height: 40px;
	color: #fff;
	font-size: 16px;
	line-height: 40px;
	text-align: center;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.1);
}

.notification-info {
	height: 40px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.notification-info-title {
	font-size: 16px;
	font-weight: 500;
	color: #fff;
}

.notification-info-date {
	font-size: 14px;
	color: #fff;
	margin-top: 2px;
}

</style>