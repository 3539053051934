<template>

<com-item name="plays.mostplayed" :is-cards="true" :size="size" :empty-cards="empty" :has-more="true" v-on:more="onMoreClick" :is-empty="!active" :loading="loading" title="Most Played">

	<template v-slot:about>

		<p>{{ your | capitalise }} most played games {{ periodText }}.</p>

	</template>

	<template v-slot:stats>

		<com-card :item="item" v-for="item in games" :key="item.g" :id="item.g" :rating="item.r" :name="$store.getters['data/games/associated'][item.g].name" :date="item.d" :metric="item.p" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comCard from './../common/Card'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-card': comCard
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		games: function() {

			return this.$store.getters['page/insights/plays/played']

		},

		empty: function() {

			var empty = []

			for (var i = this.games.length; i < 5; i++) {

				empty.push(true)

			}

			return empty.length

		},

		active: function() {

			return this.games.length && !this.loading

		}

	},

	methods: {

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsPlaysMost'
			})

		}

	}

}

</script>

<style scoped>

</style>