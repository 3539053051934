import CONSTANTS from 'app/constants'
import router from 'app/router'

export default {

	namespaced: true,

	state: {

		longest: CONSTANTS.INSIGHTS.SHELF.LONGEST.CURRENT,
		cumulative: {
			who: CONSTANTS.INSIGHTS.SHELF.CUMULATIVE.DESIGNERS,
			period: CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL
		}

	},

	getters: {

		active: function(state) {

			return {
				'longest': state.longest !== CONSTANTS.INSIGHTS.SHELF.LONGEST.CURRENT,
				'cumulative.who': state.cumulative.who !== CONSTANTS.INSIGHTS.SHELF.CUMULATIVE.DESIGNERS,
				'cumulative.period': state.cumulative.period !== CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL
			}

		},

		values: function(state) {

			return {
				'longest': state.longest,
				'cumulative.who': state.cumulative.who,
				'cumulative.period': state.cumulative.period
			}

		},

		longest: function(state) {

			return state.longest

		},

		'cumulative/who': function(state) {

			return state.cumulative.who

		},

		'cumulative/period': function(state) {

			return state.cumulative.period

		}

	},

	mutations: {

		reset: function(state) {

			state.longest = CONSTANTS.INSIGHTS.SHELF.LONGEST.CURRENT
			state.cumulative.who = CONSTANTS.INSIGHTS.SHELF.CUMULATIVE.DESIGNERS
			state.cumulative.period = CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL

		},

		longest: function(state, value) {

			state.longest = value

		},

		'cumulative/who': function(state, value) {

			state.cumulative.who = value

		},

		'cumulative/period': function(state, value) {

			state.cumulative.period = value

		}

	},

	actions: {

		analyse: function(context, params) {

			return new Promise(function(resolve) {

				context.commit('reset')

				if (params !== undefined) {

					if (params.longest) context.state.longest = params.longest
					if (params['cumulative.who']) context.state.cumulative.who = params['cumulative.who']
					if (params['cumulative.period']) context.state.cumulative.period = params['cumulative.period']

				}

				resolve()

			})

		},

		route: function(context) {

			var urlParams = {}

			if (context.state.longest !== CONSTANTS.INSIGHTS.SHELF.LONGEST.CURRENT) urlParams.longest = context.state.longest
			if (context.state.cumulative.who !== CONSTANTS.INSIGHTS.SHELF.CUMULATIVE.DESIGNERS) urlParams['cumulative.who'] = context.state.cumulative.who
			if (context.state.cumulative.period !== CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL) urlParams['cumulative.period'] = context.state.cumulative.period

			router.replace({name: 'InsightsShelf', query: urlParams}).catch(function() {})

		}

	}

}