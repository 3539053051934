<template>

<div class="section" v-bind:class="{'is-empty': empty}">

	<div class="section-empty" v-if="empty">{{ placeholder }}</div>

	<core-simplebar class="section-scroll" v-if="!empty">
		
		<slot></slot>

	</core-simplebar>

</div>

</template>

<script>

export default {

	props: ['empty', 'placeholder']

}

</script>

<style scoped>

.section {
	display: flex;
	padding: 8px 0px;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: row;
}

.is-mobile .section {
	height: auto;
	flex-grow: 1;
	padding: 8px;
}

.section.is-empty {
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.section-empty {
	font-size: 14px;
	color: #666;
	font-weight: 300;
}

.section-scroll {
	max-height: 257px;
	width: 100%;
}

.is-mobile .section-scroll {
	min-height: 0;
	max-height: 100%;
	flex-grow: 1;
	flex-basis: 0;
	height: auto;
}

</style>