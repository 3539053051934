<template>

<core-filter-advanced-group title="Players" :count="count">

	<core-filter-input-toggle text="Only plays involving all players below" on="Yes" off="No" :filter="value('play/players/together')" />

	<core-filter-input-suggest placeholder="Start typing a name..." field="player_name" subtitle="player_username" :filter="value('play/players/required')" api="players/suggest" data="players" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['play.players.together', 'play.players.required']
		}
	}

}

</script>