<template>

<com-item :name="'records.win.' + type" :has-more="true" v-on:more="onMoreClick" :size="size" :is-list="true" :loading="loading" :is-empty="!active" :title="title[type]">

	<template v-slot:stats>

		<app-game v-for="(item, index) in data" :subtext="subtext(item.user, item.total)" :item="item" :user="!isUser" v-bind:key="index">

			<core-progress :percent="item.ratio" />

		</app-game>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'type'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			title: {
				most: 'Highest win %',
				least: 'Lowest win %'
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/records/games'][this.type]

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		subtext: function(user, total) {

			return ((this.isUser) ? this.$store.getters['data/users'][user].name + ', ' : '') + total + ' play' + ((total !== 1) ? 's' : '')

		},

		onMoreClick: function() {
		
			this.$pubsub.$emit('panel.open', {
				type: 'insightsRecordsGames',
				compareType: this.type
			})
		
		}

	}

}

</script>

<style scoped>

</style>