<template>

<div class="tag">

	<div class="tag-colour" :style="{backgroundColor: tag.colour}"></div>
	<div class="tag-text">{{ tag.text }}</div>
	<div class="tag-edit" v-tooltip="'Edit tag'" v-on:click="onEditClick"><i class="fa fa-pencil-alt"></i></div>
	<div class="tag-delete" v-tooltip="'Delete tag'" v-on:click="onDeleteClick"><i class="fa fa-minus-circle"></i></div>

</div>

</template>

<script>

export default {

	props: ['tag'],

	methods: {

		onEditClick: function() {

			this.$pubsub.$emit('open.modal', {
				id: 'tagEdit',
				tag: this.tag.id
			})

		},

		onDeleteClick: function() {

			this.$pubsub.$emit('open.modal', {
				id: 'tagDelete',
				tag: this.tag.id
			})

		}

	}

}

</script>

<style scoped>

.tag {
	display: flex;
	flex-direction: row;
	height: 32px;
	align-items: center;
	padding: 0px 10px 0px 15px;
}

.is-desktop .tag:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.tag-colour {
	width: 16px;
	height: 16px;
	flex-shrink: 0;
	border-radius: 4px;
}

.tag-text {
	font-size: 14px;
	margin-left: 16px;
	color: #fff;
	flex-grow: 1;
	line-height: 32px;
	font-weight: 400;
}

.tag-edit,
.tag-delete {
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	line-height: 32px;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	color: rgba(255, 255, 255, 0.5);
}

.is-desktop .tag-edit:hover,
.is-desktop .tag-delete:hover {
	color: #fff;
}

</style>
