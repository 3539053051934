import data from './store/data'
import util from './store/util'
import context from './store/context'
import session from './store/session'

export default {
	
	modules: {

		// app must define 
		api: {},
		page: {},
		filter: {},
		permissions: {},

		// generic than can be overriden
		data: data,
		util: util,
		context: context,
		session: session
		
	},

	state: {

		isReady: false

	},

	getters: {

		isReady: function(state) {

			return state.isReady

		},

		loggedin: function(state, getters) {

			return getters['session/loggedin']

		},

		session: function(state, getters) {

			return getters['session/user']

		},

		context: function(state, getters) {

			return getters['context/current']

		}

	},

	mutations: {

		ready: function(state) {

			state.isReady = true

		}

	}

}