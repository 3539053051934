<template>

<core-config-section :locked="!loggedin">

	<h2>Personalise</h2>

	<p class="about">Are you a glass half full or empty kind of person? Personalise your list name to match your outlook on unplayed games.</p>

	<div class="inputs">

		<core-filter-input-toggles filter="config/lists/unplayed/type" text="Shelf of" :values="toggle.values" :icons="toggle.icons" :labels="toggle.labels" />

	</div>

</core-config-section>

</template>

<script>

export default {

	data: function() {

		return {

			toggle: {
				icons: ['', ''],
				values: ['shame', 'opportunity'],
				labels: ['Shame', 'Opportunity']
			}
			
		}

	}

}

</script>