<template>

<div class="content-select" :class="{'is-active': active}" v-bind:style="{'marginLeft': window.menuIndentContent}">

	<div class="inner">

		<div class="content-select-count">
			{{ active }} <plural :n="active" :t="noun" /> selected
		</div>

		<div class="content-select-actions">

			<core-content-select-type :active="active" text="Selection" icon="check-square">

				<core-content-select-action text="Select all" name="selection/all" />
				<core-content-select-action text="Deselect all" name="selection/none" />
				<core-content-select-action text="Invert" name="selection/invert" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="Tags" icon="tags" v-if="can.tag">

				<core-dropdown-item type="title" v-if="gameTags.length">Game tags</core-dropdown-item>

				<core-dropdown-item v-for="tag in gameTags" :key="tag.id" type="tagToggle" :color="tag.colour" :loading="is.tagging === tag.id" v-on:add="onTagAdd(tag)" v-on:remove="onTagRemove(tag)">{{ tag.text }}</core-dropdown-item>

				<core-dropdown-item type="title" v-if="contextTags.length">{{ contextTitle }}</core-dropdown-item>

				<core-dropdown-item v-for="tag in contextTags" :key="tag.id" type="tagToggle" :color="tag.colour" :loading="is.tagging === tag.id" v-on:add="onTagAdd(tag)" v-on:remove="onTagRemove(tag)">{{ tag.text }}</core-dropdown-item>

				<core-dropdown-item type="divide" v-if="gameTags.length || contextTags.length" />

				<core-content-select-action text="Create new tag" name="tags/create" :loading="true" />

				<core-content-select-divide />

				<core-content-select-action text="Clear all" name="tags/clear" :loading="true" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="Filter" icon="filter" v-if="can.filter">

				<core-content-select-action text="Only show these games" name="filter/subset" :loading="true" v-if="filterSubsetApplicable" />
				<core-content-select-action text="Always include games" name="filter/include/add" :loading="true" v-if="filterIncludeAddApplicable" />
				<core-content-select-action text="Don't always include games" name="filter/include/remove" :loading="true" v-if="filterIncludeRemoveApplicable" />
				<core-content-select-action text="Always exclude games" name="filter/exclude" :loading="true" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="List" icon="list" v-if="can.list.remove">

				<core-content-select-action text="Remove" name="list/remove" :loading="true" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="Users" icon="users" v-if="can.users.remove">

				<core-content-select-action text="Remove" name="users/remove" :loading="true" />

			</core-content-select-type>

			<core-content-select-type :active="active" text="Export" icon="file-download" v-if="can.export">

				<core-content-select-action text="As CSV" name="export/csv" :loading="true" />
				<core-content-select-action text="To Clipboard" name="export/clipboard" :loading="true" />

			</core-content-select-type>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['noun'],

	data: function() {

		return {
			is: {
				tagging: false
			}

		}

	},

	computed: {

		contextTitle: function() {

			return this.$CONSTANTS.SERVICE.TITLE[this.$store.getters['service/select/name']] + ' tags'

		},

		gameTags: function() {

			return this.$_.where(this.$store.getters['data/tags/list'], {
				context: 0
			})

		},

		contextTags: function() {

			return this.$_.where(this.$store.getters['data/tags/list'], {
				context: this.$CONSTANTS.SERVICE.VALUE[this.$store.getters['service/select/name']]
			})

		},

		can: function() {

			return this.$store.getters['service/select/can']

		},

		active: function() {

			return this.$store.getters['service/select/count']

		},

		filterSubsetApplicable: function() {

			return true

		},

		filterIncludeAddApplicable: function() {

			return true

		},

		filterIncludeRemoveApplicable: function() {

			return true

		}

	},

	methods: {

		onTagAdd: function(tag) {

			this.is.tagging = tag.id

			this.$store.dispatch('service/select/tags/add', {
				tag: tag
			}).then(function() {

				this.is.tagging = false

			}.bind(this))

		},

		onTagRemove: function(tag) {

			this.is.tagging = tag.id

			this.$store.dispatch('service/select/tags/remove', {
				tag: tag
			}).then(function() {

				this.is.tagging = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.content-select {
	position: fixed;
	bottom: -40px;
	height: 40px;
	left: 0px;
	width: 100%;
	background-color: #046C8F;
	transition: bottom 300ms ease-in-out, margin-left 100ms linear;
	z-index: 2;
}

.content-select.is-active {
	bottom: 0px;
}

.content-select .inner {
	display: flex;
	justify-content: space-between;
	padding: 0px 7px;
}

.is-mobile .content-select .inner {
	padding-right: 0px;
}

.content-select-count {
	font-size: 16px;
	font-weight: 400;
	line-height: 40px;
	flex-shrink: 1;
	color: #fff;
}

.is-mobile .content-select-count {
	font-size: 12px;
}

.content-select-actions {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
}

</style>
