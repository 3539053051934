<template>

<core-filter-advanced :active="is.expanded" v-on:clickoutside="onOutsideClick" class="columns">

	<template v-slot:inputs>

		<core-filter-advanced-section>
			
			<h1>General</h1>

			<core-filter-input-toggle text="Position" on="Yes" off="No" page="list/columns/rank" />
			<core-filter-input-toggle text="Avatar" on="Yes" off="No" page="list/columns/avatar" />
			<core-filter-input-toggle text="Name" on="Yes" off="No" page="list/columns/name" />
			<core-filter-input-toggle text="Player count" on="Yes" off="No" page="list/columns/count" />
			<core-filter-input-toggle text="Duration" on="Yes" off="No" page="list/columns/duration" />
			<core-filter-input-toggle text="Weight" on="Yes" off="No" page="list/columns/weight" />
			<core-filter-input-toggle text="Language dependency" on="Yes" off="No" page="list/columns/language" />
			<core-filter-input-toggle text="BGG Rank" on="Yes" off="No" page="list/columns/bggrank" />
			<core-filter-input-toggle text="Owners" on="Yes" off="No" page="list/columns/owners" v-if="!isUser" />

			<h1>Plays</h1>

			<core-filter-input-toggle text="All time" on="Yes" off="No" page="list/columns/plays/total" />
			<core-filter-input-toggle :text="playPeriod('This ' + period)" on="Yes" off="No" v-if="!isLive && period" page="list/columns/plays/filter" />
			<core-filter-input-toggle text="Total hours" on="Yes" off="No" page="list/columns/plays/hours" />

			<h1 v-if="isUser">Dates</h1>

			<core-filter-input-toggle v-if="isUser" text="First played" on="Yes" off="No" page="list/columns/date/play/first/any" />
			<core-filter-input-toggle v-if="isUser && period" :text="playPeriod('First play this ' + period)" on="Yes" off="No" page="list/columns/date/play/first/filter" />
			<core-filter-input-toggle v-if="isUser" text="Last played" on="Yes" off="No" page="list/columns/date/play/last/any" />
			<core-filter-input-toggle v-if="isUser && period" :text="playPeriod('Last play this ' + period)" on="Yes" off="No" page="list/columns/date/play/last/filter" />

			<h1>Ratings</h1>

			<core-filter-input-toggle text="You" on="Yes" off="No" v-if="!isGroup" page="list/columns/rating/user" />
			<core-filter-input-toggle text="Group" on="Yes" off="No" v-if="isGroup" page="list/columns/rating/group" />
			<core-filter-input-toggle text="BGG" on="Yes" off="No" page="list/columns/rating/bgg" />

		</core-filter-advanced-section>

	</template>

	<template v-slot:buttons>
	
		<core-button :loading="is.saving" v-on:click.native="onSaveClick" theme="blue" :disabled="!changed" fluid="yes">Save changes</core-button>

		<core-button v-on:click.native="onResetClick" theme="blue" :disabled="!changed" fluid="yes">Reset changes</core-button>

		<core-button v-if="window.is.mobile" v-on:click.native="onOutsideClick" theme="blue" fluid="yes">Close</core-button>

	</template>

</core-filter-advanced>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				expanded: false,
				saving: false
			}

		}

	},

	computed: {

		periodly: function() {

			if (this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.MONTHLY) return 'Monthly'
			if (this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.YEARLY) return 'Yearly'
			return false

		},

		period: function() {

			if (this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.MONTHLY) return 'month'
			if (this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.YEARLY) return 'year'
			return false

		},

		columns: function() {

			return this.$store.getters['page/list/columns']

		},

		changed: function() {

			return this.$store.getters['page/list/columns/changed']

		},

		list: function() {

			return this.$store.getters['page/list/list']

		},

		isLive: function() {

			return this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.LIVE

		},

		filter: function() {

			return this.$store.getters['page/list/list/filter']

		}

	},

	created: function() {

		this.$pubsub.$on('list.columns.expand', this.onListColumnsExpand.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('list.columns.expand', this.onListColumnsExpand.bind(this))

	},

	methods: {

		onListColumnsExpand: function() {

			this.is.expanded = true

		},

		playPeriod: function(text) {

			return text

		},

		onSaveClick: function() {

			this.is.saving = true

			this.$store.dispatch('api/list/columns', {
				columns: this.columns
			}).then(function(json) {

				this.$pubsub.$emit('list.columns.saved')

				this.is.expanded = false
				this.is.saving = false

				this.$store.commit('page/list/columns/reset', json.columns)

			}.bind(this), function() {

				this.is.expanded = false
				this.is.saving = false

			}.bind(this))

		},

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onResetClick: function() {

			this.$store.commit('page/list/columns/reset', this.list.columns)

		}

	}

}

</script>

<style scoped>

.columns {
	bottom: 0px;
}

@media only screen and (max-width: 767px) {

	.columns {
		top: 95px!important;
	}

}

.columns h1 {
	color: #fff;
	font-size: 16px;
	padding-bottom: 8px;
	padding-top: 8px;
}

</style>