<template>

<div class="flag" v-tooltip="name" v-show="id" v-bind:style="{backgroundImage: flag}">

</div>

</template>

<script>

export default {

	props: ['id'],
	
	computed: {

		name: function() {

			return this.$CONSTANTS.COUNTRY[this.id]

		},

		flag: function() {

			return 'url(' + this.$util.thumbnail.flag(this.id) + ')'

		}

	}

}

</script>

<style scoped>

.flag {
	background-size: contain;
}

</style>
