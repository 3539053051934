<template>

<core-content-wrapper :empty="is.ready && !totalResults" :loading="!is.ready">

	<core-content-empty v-show="is.ready && !totalResults && isOwner && !isBuddies && !isTemplate">

		<h2>Oh dear, no games!</h2>

		<p v-if="isManual">What are you waiting for? Start adding some games!</p>
		<p v-if="isAutomated">Have you configured your filter yet?</p>

		<core-button v-on:click.native="onStartClick" v-if="isManual && canDoSomething">Import by filter</core-button>
		<core-button v-on:click.native="onSearchClick" v-if="isManual && canDoSomething">Search by name</core-button>
		<core-button v-on:click.native="onStartClick" v-if="!isManual && canDoSomething">Get started</core-button>

	</core-content-empty>

	<core-content-empty v-show="is.ready && !totalResults && (!isOwner || isBuddies || isTemplate)">

		<h2>Oh dear, no games!</h2>

		<p>It seems there are no games in this list.</p>

	</core-content-empty>

	<com-filter :loading="is.fetching" :count="totalPseudoResults" :maximum="totalMaxResults" :ready="is.ready" v-on:refresh="onRefresh" />

	<com-filter-automated-filter v-if="canDoSomething && isAutomated" />

	<com-filter-manual-import v-if="canDoSomething && isManual" />
	<com-filter-manual-suggestions v-if="canDoSomething && isManual" />
	<com-filter-manual-search v-if="canDoSomething && isManual" />

	<com-filter-columns v-if="canDoSomething" v-bind:class="{'is-top': !is.scrolledPastAbout}" />

	<com-about ref="about" :loading="is.fetching" v-if="is.ready" />

	<app-game-head :fixed="is.fixedHead" v-show="is.ready && totalResults" :columns="columns" :offset="scrollLeft" />

	<core-content-list :fixed="is.fixedHead" v-on:fixed="onFixedChange" v-show="is.ready && totalResults" class="lists-list" :flush="true">

		<div class="inner list-list-inner" ref="inner">

			<com-draggable v-model="order" :disabled="!isManual || is.fetching || !isOwner" handle=".drag-handle" v-on:update="onOrderChange">

				<app-game-item v-for="(item, index) in games" v-bind:key="item.game.id" :index="index" filter="list" :format="format" :draggable="isManual && !is.fetching && isOwner" :scrollable="true" :item="item" :fields="columns" />

			</com-draggable>

			<core-content-scrollload v-on:scrolled="onScroll" v-show="currentPage < totalPages || (is.fetching && !is.refreshing && is.scrollLoading)" />

		</div>

	</core-content-list>

	<core-content-select noun="game" />

</core-content-wrapper>

</template>

<script>

import comDraggable from 'vuedraggable'
import comFilter from './list/Filter'
import comAbout from './list/About'

import filterColumns from './list/filter/Columns'

import filterAutomatedFilter from './list/filter/automated/Filter'

import filterManualImport from './list/filter/manual/Import'
import filterManualSuggestions from './list/filter/manual/Suggestions'
import filterManualSearch from './list/filter/manual/Search'

export default {

	components: {
		'com-filter': comFilter,
		'com-draggable': comDraggable,
		'com-about': comAbout,
		'com-filter-columns': filterColumns,
		'com-filter-automated-filter': filterAutomatedFilter,
		'com-filter-manual-import': filterManualImport,
		'com-filter-manual-suggestions': filterManualSuggestions,
		'com-filter-manual-search': filterManualSearch
	},

	data: function() {

		return {

			is: {
				refreshing: false,
				ready: false,
				fixedHead: false,
				fetching: false,
				filterChanged: false,
				scrollLoading: false,
				scrolledPastAbout: false
			},

			scrollLeft: 0,

			order: [],

			searchKey: '',
			totalResults: 0,
			totalPseudoResults: 0,
			totalMaxResults: 0,
			totalPages: 0,
			currentPage: 1,

			contentName: 'list',
			routeName: 'List'

		}

	},

	computed: {

		list: function() {

			return this.$store.getters['page/list/list']

		},

		edition: function() {

			return this.$store.getters['page/list/edition']

		},

		isManual: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.MANUAL

		},

		isTemplate: function() {

			return this.list.template

		},

		isAutomated: function() {

			return this.list.type === this.$CONSTANTS.LISTS.TYPE.AUTOMATED

		},

		games: function() {

			return this.$store.getters['page/list/games']

		},

		columns: function() {

			if (this.list.template) {

				var columns = []

				this.$_.each(this.list.columns, function(column) {

					if (column.title) {

						column.title = column.title.replace('{{YEAR}}', this.$moment.unix(this.edition.date).utc().format('Y'))

					}

					columns.push(column)

				}.bind(this))

				return columns

			} else {

				return this.$store.getters['page/list/columns']

			}

		},

		filter: function() {

			return this.$store.getters['filter/list']

		},

		canDoSomething: function() {

			return this.$store.getters['permissions/list/create'] || this.$store.getters['permissions/list/edit'] || this.$store.getters['permissions/list/delete'] || this.$store.getters['permissions/edition/create'] || this.$store.getters['permissions/edition/edit'] || this.$store.getters['permissions/edition/delete']

		},

		format: function() {

			return {
				expand: {
					plays: {
						context: this.$store.getters['filter/list/active']['played.sometime'] || this.$store.getters['filter/list/active']['played.first'] || this.$store.getters['filter/list/active']['played.last']
					}
				}
			}

		}

	},
	
	beforeRouteEnter: function(to, from, next) {

		if (to.params.name === from.params.name && to.params.type === from.params.type && to.params.list === from.params.list && to.params.edition !== from.params.edition) {

			next(function(vm) {

				vm.fetch()

			})

		} else {

			next()

		}

	},

	beforeRouteUpdate: function(to, from, next) {

		next()	

		this.reset()

	},

	watch: {

		filter: {

			handler: function() {

				if (this.isAutomated || this.isManual) {

					if (this.$store.getters['filter/list/changed'] || this.is.filterChanged) {

						this.currentPage = 1
						this.is.filterChanged = this.$store.getters['filter/list/changed']
						this.fetch()

					}

				}

			},
			deep: true

		}

	},

	created: function() {

		this.$pubsub.$emit('unready')
		
		this.fetch()

		this.$pubsub.$on('list.filter.saved', this.onFilterSaved)
		this.$pubsub.$on('list.refresh', this.onRefresh)
		this.$pubsub.$on('list.order.save', this.onOrderSave)

	},

	beforeDestroy: function() {

		this.$pubsub.$off('list.filter.saved', this.onFilterSaved)
		this.$pubsub.$off('list.refresh', this.onRefresh)
		this.$pubsub.$off('list.order.save', this.onOrderSave)

	},

	methods: {

		onFixedChange: function(value) {
		
			this.is.fixedHead = value

		},

		onOrderChange: function() {

			this.$store.commit('page/list/games/set', this.order)

			this.onOrderSave()

		},

		onOrderSave: function() {

			var order = []

			this.$_.each(this.order, function(item) {

				order.push(item.game.id)

			})

			this.is.fetching = true

			this.$store.dispatch('api/list/order', {
				order: order
			}).then(function() {

				this.is.refreshing = true
				this.fetch()

			}.bind(this), function() {

				this.is.fetching = false

			}.bind(this))

		},

		onRefresh: function() {

			this.is.refreshing = true
			this.fetch()

		},

		onFilterSaved: function() {

			if (this.isAutomated) {

				this.is.filterChanged = false
				this.fetch()
				
			}

		},

		onSearchClick: function(e) {

			e.stopPropagation()
			
			this.$pubsub.$emit('list.search.expand')

		},

		onStartClick: function(e) {

			e.stopPropagation()

			if (this.isAutomated) {

				this.$pubsub.$emit('list.automated.expand')

			} else if (this.isManual) {

				this.$pubsub.$emit('list.import.expand')

			} 

		},

		reset: function() {

			this.currentPage = 1
			this.searchKey = false

			this.fetch()

		},

		fetch: function(type, name, list, edition) {

			type = type || this.$route.params.type
			name = name || this.$route.params.name
			list = list || this.$route.params.list
			edition = edition || this.$route.params.edition

			this.is.fetching = true

			this.$store.dispatch('api/list/' + type, {
				name: name,
				key: (this.currentPage === 1 || this.is.refreshing) ? false : this.searchKey,
				list: list,
				edition: edition,
				page: this.currentPage,
				pagesInclusive: this.is.refreshing,
				filter: (this.is.filterChanged) ? this.filter : false
			}).then(function(json) {

				if (!this.is.filterChanged && !this.is.refreshing && this.currentPage === 1) {

					document.body.scrollTop = document.documentElement.scrollTop = 0
					this.is.scrolledPastAbout = false

					if (this.isManual) {

						this.$store.commit('filter/list/reset', json.list.filter)

					} else if (this.isAutomated) {

						this.$store.commit('filter/list/reset', json.list.filter)

					}

				}

				if (!this.is.refreshing && !this.is.filterChanged) {

					this.$store.commit('page/list/columns/reset', json.list.columns)

				}

				this.order = this.$store.getters['page/list/games']

				this.searchKey = json.key
				this.totalResults = json.total
				this.totalPseudoResults = json.totalPseudo
				this.totalMaxResults = json.totalMax
				this.totalPages = json.pages

				if (this.currentPage === 1) {

					this.$store.dispatch('service/select/init', {
						key: this.searchKey,
						name: 'list',
						count: this.totalResults,
						pages: this.totalPages,
						can: {
							export: true,
							filter: this.$store.getters['permissions/list/edit'] && this.isAutomated,
							tag: false,
							nesting: false,
							game: true,
							rename: this.isOwner,
							list: {
								remove: this.isManual && this.isOwner && !this.isBuddies
							},
							users: {
								remove: false
							}
						}
					})

				} else {

					this.$store.commit('service/select/page', this.currentPage)

				}
				
				this.is.ready = true
				this.is.fetching = false
				this.is.refreshing = false
				this.is.scrollLoading = false

				this.$pubsub.$emit('ready')

				this.$pubsub.$emit('list.refreshed')

			}.bind(this), function() {

				this.is.fetching = false
				this.is.refreshing = false

			}.bind(this))

		},

		onScroll: function() {

			if (!this.is.fetching && this.currentPage < this.totalPages && this.$route.name === this.routeName && !this.is.scrollLoading) {

				this.currentPage++

				this.is.scrollLoading = true

				this.fetch()

			}

		}

	}

}

</script>

<style scoped>

.list-list-inner {
	padding: 0px 0px;
}

</style>