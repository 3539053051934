<template>

<div class="head" v-bind:class="{'is-inline': inline, 'is-offset': offset, 'is-fixed': fixed}" :style="{marginLeft: indent}">

	<div class="head-inner inner">

		<template v-for="(column, index) in columns">

			<app-game-head-column-rank v-if="column.type === 'rank'" :format="column" v-bind:key="index" />
			<app-game-head-column-avatar v-if="column.type === 'avatar'" :format="column" v-bind:key="index" />
			<app-game-head-column-name v-if="column.type === 'name'" :format="column" v-bind:key="index" />
			<app-game-head-column-rating v-if="column.type === 'rating'" :format="column" v-bind:key="index" />
			<app-game-head-column-plays v-if="column.type === 'plays'" :format="column" v-bind:key="index" />
			<app-game-head-column-weight v-if="column.type === 'weight'" :format="column" v-bind:key="index" />
			<app-game-head-column-duration v-if="column.type === 'duration'" :format="column" v-bind:key="index" />
			<app-game-head-column-count v-if="column.type === 'count'" :format="column" v-bind:key="index" />
			<app-game-head-column-date v-if="column.type === 'date'" :format="column" v-bind:key="index" />
			<app-game-head-column-bggrank v-if="column.type === 'bggrank'" :format="column" v-bind:key="index" />
			<app-game-head-column-worth v-if="column.type === 'worth'" :format="column" v-bind:key="index" />
			<app-game-head-column-owners v-if="column.type === 'owners'" :format="column" v-bind:key="index" />
			<app-game-head-column-hours v-if="column.type === 'hours'" :format="column" v-bind:key="index" />
			<app-game-head-column-published v-if="column.type === 'published'" :format="column" v-bind:key="index" />
			<core-list-column v-if="column.type === 'language'" type="stat" :format="column" v-bind:key="index" />

		</template>

		<core-list-column-actions />

	</div>

</div>

</template>

<script>

export default {

	props: ['columns', 'fixed', 'inline', 'offset'],

	computed: {
	
		indent: function() {
		
			return (this.fixed) ? this.window.menuIndentContent : '0px'
		
		}
	
	}

}

</script>

<style scoped>

.head {
	background-color: #fff;
	border-radius: 4px 4px 0px 0px;
	border: 1px solid #ddd;
	border-bottom: 0px;
	transition: margin-left 100ms linear;
}

.head.is-fixed {
	position: fixed;
	top: 152px;
	left: 360px;
	width: calc(100% - 400px);
	z-index: 1000;
	border-radius: 0px;
	border-left-color: #fff;
	border-right-color: #fff;
	border-top: 0px;
	border-bottom: 1px solid #ddd;
}

.is-smaller .head.is-fixed {
	top: 128px;
	left: 0px;
	width: calc(100% - 20px);
}

.is-mobile .head.is-fixed {
	top: 144px;
	left: 0px;
	width: 100%;
}

.head.is-fixed:before {
	content: '';
	position: absolute;
	display: block;
	height: 32px;
	left: -360px;
	right: -40px;
	background-color: #fff;
	border-bottom: 1px solid #ddd;
	z-index: 1;
}

.is-smaller .head.is-fixed:before {
	right: -20px;
	left: -340px;
}

.is-mobile .head.is-fixed:before {
	display: none;
}

.head-offset {
	position: absolute;
	top: -32px;
	height: 32px;
	background-color: red;
	width: 32px;
}

@media only screen and (max-width: 640px) {

	.head.is-offset {
		display: inline-flex;
		width: auto;
		min-width: 100%;
	}

}

.head.is-mobile {
	top: 136px;
}

.head.is-inline {
	position: relative;
	top: auto;
	left: auto;
	z-index: 1;
}

.head-inner {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	padding: 0px 7px;
	height: 32px;
	z-index: 2;
}

@media only screen and (max-width: 1119px) {

	.head-inner {
		padding: 0px 7px;
	}

}

</style>