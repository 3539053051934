<template>

<com-item name="ratings.disparities" :is-list="true" :has-more="true" v-on:more="onMoreClick" :size="size" :is-empty="!active" :loading="loading" title="Disparities">

	<template v-slot:about>

		<p>Which of {{ your }} ratings have the biggest difference with the BGG community.</p>

		<p class="small"><span v-if="isGroup">Minimum of {{ threshold }} <plural t="rating" :v="threshold" /> to qualify. </span>Excludes expansions.</p>

	</template>

	<template v-slot:stats>
	
		<app-game v-for="(item, index) in data" :item="{id: item.game.id}" v-bind:key="index" :subtext="subtext(item)">

			<core-rating :value="item.group.rating_avg" :count="item.group.rating_count" :ratings="item.users.rated" v-on:more="onMoreClick(item.game.id)" class="item-rating" :showIndividualRatings="isGroup" :hideCount="!isGroup" :transparent="true" />
		
			<app-game-item-field-rating :item="item" :format="{context: 'bgg'}"  />

		</app-game>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/disparities']

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		subtext: function(item) {

			var difference = 0

			if (this.$store.getters['context/is/user']) {

				difference = Math.abs(item.user.rating_avg - item.game.rating_avg).toFixed(1)

			} else {

				difference = Math.abs(item.group.rating_avg - item.game.rating_avg).toFixed(1)

			}

			return 'Rating disparity of ' + difference

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsDisparities'
			})

		}
	
	}

}

</script>

<style scoped>

.rarity {
	width: 100%;
	padding: 0px!important;
}

</style>