<template>

<com-item name="size.clusters" :size="size" :loading="loading" :title="title[type]" :is-empty="!data.length" :has-more="total > data.length" v-on:more="onAllClick">

	<template v-slot:about>

		<template v-if="type === 'added'">

			<p>When {{ you }} added a number of games over a short time period.</p>

		</template>

		<template v-if="type === 'removed'">

			<p>When {{ you }} removed a number of games over a short time period.</p>

		</template>

		<template v-if="type === 'refreshed'">

			<p>When {{ you }} both added and removed a number of games over a short time period.</p>

		</template>

	</template>

	<template v-slot:stats>

		<com-cluster :item="item" :type="type" v-for="(item, index) in data" v-bind:key="index" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comCluster from './clusters/Cluster'

export default {

	props: ['fetching', 'your', 'you', 'type', 'size'],

	components: {
		'com-item': comItem,
		'com-cluster': comCluster
	},

	data: function() {
	
		return {
			title: {
				added: 'Spurts',
				removed: 'Culls',
				refreshed: 'Refreshes'
			}
		}
	
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/size/clusters'][this.type].clusters

		},

		total: function() {

			return this.$store.getters['page/insights/size/clusters'][this.type].total

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		onAllClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsSizeClusters',
				clusterType: this.type
			})

		}

	}

}

</script>

<style scoped>

</style>