<template>
	
<core-list-cell :format="format" type="stat" class="weight" :condensed="condensed" v-bind:class="{'is-summary': summary}">

	<div class="weight-wrapper">	
		<i class="fa fa-weight-hanging"></i>
		<span v-show="value > 0">{{ value }}</span>
		<span v-show="value == 0">-</span>
	</div>

</core-list-cell>

</template>

<script>

import wNumb from 'wnumb'

export default {

	props: ['item', 'summary', 'format', 'condensed'],

	computed: {

		value: function() {

			return wNumb({
				'decimals': 2
			}).to(this.item.game.weight_avg)

		}

	}

}

</script>

<style scoped>

.weight-wrapper {
	z-index: 1;
}

@media only screen and (max-width: 767px) {

	.weight:not(.is-summary) {
		display: none!important;
	}

	.weight.is-summary {
		font-size: 10px;
		height: 16px;
		width: 48px;
		line-height: 16px;
		text-align: left;
		color: #333;
		font-weight: 400;
	}

}

.weight .fa {
	display: none;
}

@media only screen and (max-width: 767px) {

	.weight.is-summary .fa {
		display: inline-block;
		line-height: 14px;
		color: #666;
		margin-right: 4px;
	}

}
</style>
