<template>

<core-modal v-on:close="onClose" :nopadding="true" :loading="is.loading">

	<template v-slot:head>

		About your worth

	</template>

	<template v-slot:default>

		<core-modal-content>

			<div v-html="content"></div>

		</core-modal-content>

		<core-modal-faq v-if="questions.length">

			<core-modal-faq-item v-for="(question, index) in questions" :key="index" :question="question.question">

				<div v-html="question.answer"></div>

			</core-modal-faq-item>

		</core-modal-faq>

	</template>

	<template v-slot:foot>

		<core-button theme="plain" v-on:click.native="onClose" v-if="window.is.mobile">Close</core-button>

	</template>

</core-modal>

</template>

<script>

export default {

	data: function() {

		return {

			content: '',
			questions: [],

			is: {
				loading: true
			}

		}

	},

	created: function() {

		this.$store.dispatch('api/app/help', {
			name: 'insights_worth'
		}).then(function(json) {

			this.content = json.body
			this.questions = json.questions

			this.is.loading = false

		}.bind(this), function() {

			this.$emit('close')

		}.bind(this))

	},

	methods: {

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>