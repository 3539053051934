import API from 'core/api'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		generic: function(context, data) {

			data = data || {}
			data.params = data.params || {}

			return new Promise(function(resolve, reject) {

				API.request(data.url, data.params, true).then(function(json) {

					context.dispatch('filter/activity/route', null, { root: true })

					// add reference data
					context.commit('data/users/add', json.data.users, { root: true })
					context.commit('data/games/add', json.data.games, { root: true })
				
					// first page, replace activity
					if (json.page === 1) {

						context.commit('page/activity/set', json.activity, { root: true })

					// otherwise paginate
					} else {

						context.commit('page/activity/append', json.activity, { root: true })

					}

					context.commit('ready', null, { root: true })

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})		

		},

		delete: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('activity/delete', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})
			
		},

		users: function(context, params) {

			params.filter = context.rootGetters['filter/activity']

			return context.dispatch('generic', {
				params: params,
				url: 'users/activity'
			})	
			
		},

		groups: function(context, params) {

			params.filter = context.rootGetters['filter/activity']

			return context.dispatch('generic', {
				params: params,
				url: 'groups/activity'
			})	
			
		},

		buddies: function(context, params) {

			params.filter = context.rootGetters['filter/activity']

			return context.dispatch('generic', {
				params: params,
				url: 'buddies/activity'
			})	
			
		}

	}

}