<template>
	
<div class="owners" v-if="!isUser && format.showOwners && statuses === 1">

	<app-game-item-field-name-owners :format="format" :item="item" />

</div>

</template>

<script>

export default {

	props: ['format', 'item', 'filter'],

	computed: {

		statuses: function() {

			return this.$store.getters['filter/' + this.filter + '/status/active'].length

		}

	}

}

</script>

<style scoped>

.owners {
	
}

</style>
