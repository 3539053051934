<template>

<com-item name="worth.overview" :size="size" :is-empty="!active" :loading="loading" :is-overview="3" title="Overview">

	<template v-slot:about>

		<p>A summary of {{ your }} collection worth.</p>

	</template>

	<template v-slot:stats>

		<com-overview label="% of Collection Valued" type="percent" :value="data.percentValued" />

		<com-overview label="Avg. Game Value" type="number" :value="data.avgGame" :is-currency="true" />

		<com-overview label="Avg. Expansion Value" type="number" :value="data.avgExpansion" :is-currency="true" />

		<com-overview label="Increased by >= 10%" sublabel="Since owned" type="number" :value="data.increased" caret="up" theme="green" />

		<com-overview label="Decreased by >= 10%" sublabel="Since owned" type="number" :value="data.decreased" caret="down" theme="red" />

		<com-overview label="Avg. Value Change" type="number" :value="data.avgDiff" :is-currency="true" :auto-caret="true" :auto-theme="true" />

		<com-overview :label="'Valued over ' + formatcurrency(50, 0)" type="number" :value="data.over50" />

		<com-overview :label="'Valued over ' + formatcurrency(100, 0)" type="number" :value="data.over100" />

		<com-overview label="Avg.Play Value" type="number" :value="data.avgPlay" :is-currency="true" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comOverview from './../common/Overview'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-overview': comOverview
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/worth/overview']

		},

		active: function() {

			return this.data && !this.loading

		}

	},

	methods: {

		lifetime: function(from, to) {

			to = to || false

			if (to === false) {

				from = this.$moment().subtract(from, 'seconds')
				to = this.$moment()

			} else {

				from = this.$moment.unix(from)
				to = this.$moment.unix(to)

			}

			var years = to.diff(from, 'years')

			to.subtract(years, 'years')

			var months = to.diff(from, 'months')

			to.subtract(months, 'months')

			var days = to.diff(from, 'days')

			var range = []

			if (years) range.push(years.toString() + '[y]')
			if (months) range.push(months.toString() + '[m]')
			if (days && years === 0) range.push(days.toString() + '[d]')

			return range.join(' ')

		}

	}

}

</script>

<style scoped>

</style>