<template>
	
<div class="card-foot-item">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: []

}

</script>

<style scoped>

.card-foot-item {
	text-align: center;
	flex-shrink: 0;
	flex-basis: 0;
	flex-grow: 1;
	padding: 8px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

</style>
