<template>

<com-item name="worth.designers" :is-list="true" :has-more="true" v-on:more="onDesignersClick" :size="size" :is-empty="!active" :loading="loading" title="Designers">

	<template v-slot:about>

		<p>Which designers have the highest worth in {{ your }} collection.</p>

		<p class="small"><template v-if="designersValue === $CONSTANTS.INSIGHTS.WORTH.DESIGNERS.AVERAGE">Minimum of two games. </template></p>

	</template>

	<template v-slot:stats>

		<app-person v-for="(item, index) in designers" :hideCount="true" :item="item" type="designer" v-bind:key="index" v-on:more="onMoreClick(item.id)" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="designersOptions" :value="designersValue" v-on:change="onDesignersChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	computed: {

		designersOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.DESIGNERS.TOTAL, text: 'Total value'})
			options.push({value: this.$CONSTANTS.INSIGHTS.WORTH.DESIGNERS.AVERAGE, text: 'Average value'})

			return options

		},

		designersValue: function() {

			return this.$store.getters['filter/insights/worth/designers']

		},

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'designers'

		},

		designers: function() {

			return this.$store.getters['page/insights/worth/designers']

		},

		active: function() {

			return this.designers.length && !this.loading

		}

	},

	methods: {

		onDesignersChange: function(value) {

			this.$store.commit('filter/insights/worth/designers', value)

		},

		onDesignersClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthDesigners',
				filter: this.$store.getters['filter/insights/worth']
			})

		},

		onMoreClick: function(id) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsWorthDesigner',
				id: id
			})

		}

	}

}

</script>

<style scoped>

</style>