import Item from './Item'
import Row from './Row'
import Filter from './Filter'

export default {

	props: ['fetching'],

	components: {

		'app-item': Item,
		'app-row': Row,
		'core-filter': Filter

	},

	data: function() {

		return {

			is: {
				ready: false,
				fetching: 'all',
				refreshing: false,
				reset: false
			},

			currentFilter: false,
			insightFetch: false

		}

	},

	computed: {

		your: function() {

			if (this.isUser) {

				return (this.$store.getters['permissions/is/userself']) ? 'your' : 'their'

			} else {

				return 'the group\'s'

			}
		},

		you: function() {

			if (this.isUser) {

				return (this.$store.getters['permissions/is/userself']) ? 'you' : 'they'

			} else {

				return 'the group\'s'

			}

		},

		period: function() {

			return this.$store.getters['filter/insights/' + this.insightName + '/period']

		},

		periodText: function() {

			if (this.period === this.$CONSTANTS.INSIGHTS.PERIOD.CURRENT) {

				return 'over the past 12 months'

			} else if (this.period === this.$CONSTANTS.INSIGHTS.PERIOD.ALLTIME) {

				return 'since ' + ((this.$store.getters['permissions/is/userself']) ? 'you' : 'they') + ' started logging plays'

			} else {

				return 'in ' + this.period

			}

		},

		filter: function() {

			return this.$store.getters['filter/insights/' + this.insightName]

		},

		context: function() {

			return this.$store.getters['context']

		},

		threshold: function() {

			return this.$store.getters['context/threshold']

		}

	},

	beforeRouteUpdate: function(to, from, next) {

		if (to.params.name !== from.params.name || to.params.type !== from.params.type) {

			this.reset(to).then(function() {

				this.is.filterChanged = true

				next()	

				this.fetch()

			}.bind(this))

		} else {

			next()

		}

		if (to.fullPath !== from.fullPath) {

			// eslint-disable-next-line
			ga('set', 'page', to.fullPath)

			// eslint-disable-next-line
			ga('send', 'pageview')

		}

	},

	watch: {

		filter: {

			handler: function(newValue) {

				if (this.is.ready) {

					this.insightFetch = false
					this.is.filterChanged = false

					if (this.currentFilter.period !== newValue.period) {

						this.is.filterChanged = true

					} else {

						this.$_.each(this.dynamicInsights, function(insight) {

							if (this.currentFilter[insight] !== newValue[insight]) this.insightFetch = insight

						}.bind(this))

					}

					this.fetch()

				}

			},
			deep: true

		}

	},

	mounted: function() {
		
		this.$pubsub.$emit('unready')
					
		this.$store.commit('page/insights/' + this.insightName + '/reset')

		this.is.reset = true

		this.reset(this.$route).then(function() {

			this.fetch()

		}.bind(this))

	},

	methods: {

		onRefresh: function() {

			this.is.refreshing = true
			this.fetch()

		},

		reset: function(to) {

			this.is.ready = false

			return new Promise(function(resolve) {

				this.$store.dispatch('filter/insights/' + this.insightName + '/analyse', to.query).then(function() {

					resolve() 

				})

			}.bind(this))

		},

		fetch: function(type, name) {

			type = type || this.$route.params.type
			name = name || this.$route.params.name

			if (this.is.filterChanged || !this.is.ready || this.is.refreshing) {

				this.is.fetching = 'all'
				this.$store.commit('page/insights/' + this.insightName + '/reset')

			} else {

				this.is.fetching = this.insightFetch

			}

			this.$store.dispatch('api/insights/' + type, {
				name: name,
				section: this.insightName,
				insight: this.insightFetch
			}).then(function() {

				this.currentFilter = this.$_.clone(this.$store.getters['filter/insights/' + this.insightName])
				this.is.ready = true
				this.is.fetching = false
				this.is.refreshing = false

				this.$pubsub.$emit('ready')

			}.bind(this))

		}

	}

}