<template>

<core-panel>

	<template v-slot:head>

		<h1>h-index games</h1>

		<p>The games that make up the h-index score of <b>{{ score }}</b>.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in hindex" :item="item" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'hindex',
			hindex: [],
			score: 0
		}

	},

	methods: {

		response: function(json) {

			this.hindex = json.hindex
			this.score = json.score

		}

	}

}

</script>