<template>

<com-item name="ratings.weights" :size="size" :loading="loading" title="Weights">

	<template v-slot:about>

		<p>See how {{ your }} ratings breaks down by weight.</p>

		<p class="small">Excludes expansions.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-segments :data="polar" v-if="isPolar" />
		<app-visualisation-bars :data="bars" v-if="!isPolar" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="weightOptions" :value="weightValue" v-on:change="onWeightChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {
	
		return {
			bands: {
				0: '#db303b',
				1: '#db303b',
				2: '#db303b',
				3: '#df4751',
				4: '#df4751',
				5: '#5369a2',
				6: '#5369a2',
				7: '#1d8acd',
				8: '#2FC482',
				9: '#249563',
				10: '#249563'
			},
			polar: {
				areas: [
					{title: 'Light', subtitle: '1.0 - 2.0'},
					{title: 'Light-Medium', subtitle: '2.0 - 3.0'},
					{title: 'Medium', subtitle: '3.0 - 4.0'},
					{title: 'Heavy', subtitle: '4.0 - 5.0'}
				],
				clickable: false,
				size: 16,
				shades: [
					'rgba(17, 105, 135, 0.2)',
					'rgba(17, 105, 135, 0.4)',
					'rgba(17, 105, 135, 0.6)',
					'rgba(17, 105, 135, 0.8)',
					'rgba(17, 105, 135, 1)'
				],
				segments: [],
				dataset: {
					data: [],
					backgroundColor: []
				}
			},
			bars: {
				datasets: [
					{ type: 'bar', data: [], subvalue: [], backgroundColor: '#4881bb', tooltip: '{{value}} rating{{value.plural}} averaging {{subvalue}} <small>Weight between {{x.tooltip}}</small>' }
				],
				axis: {
					x: {
						labels: [],
						long: true
					},
					y: {
						min: 10,
						points: 10
					}
				}
			}
		}
	
	},

	computed: {

		weightOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.GRAPH.POLAR, text: 'Polar chart'})
			options.push({value: this.$CONSTANTS.INSIGHTS.GRAPH.BAR, text: 'Bar chart'})

			return options

		},

		weightValue: function() {

			return this.$store.getters['filter/insights/ratings/weight']

		},

		isPolar: function() {

			return this.$store.getters['filter/insights/ratings/weight'] === 'polar'

		},

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/weights']

		},

		active: function() {

			return this.data.counts.length && !this.loading

		}

	},

	watch: {

		data: function() {

			this.visualise()

		}

	},

	created: function() {

		var range

		for (var i=1; i <= 16; i++) {

			if (i == 16) {

				range = '{{value}} rating{{value.plural}} averaging ' + ((this.data.bands[i - 1]) ? this.data.bands[i - 1].toString() : '0') + ' with a weight of ' + (((i - 1) * 0.25) + 1).toFixed(2) + ' and higher'

			} else {

				range = '{{value}} rating{{value.plural}} averaging ' + ((this.data.bands[i - 1]) ? this.data.bands[i - 1].toString() : '0') + ' with a weight between ' + (((i - 1) * 0.25) + 1).toFixed(2) + ' and ' + (((i - 1) * 0.25) + 1 + 0.24).toFixed(2)

			}

			this.polar.segments.push({
				label: range
			})

			range = (((i - 1) * 0.25) + 1).toFixed(2) 

			this.bars.axis.x.labels.push({
				label: range + ' - ' + (((i - 1) * 0.25) + 1.24).toFixed(2) ,
				tooltip: range + ' and ' + (((i - 1) * 0.25) + 1.24).toFixed(2)
			})

		}
	
		this.visualise()	

	},

	methods: {

		onWeightChange: function(value) {

			this.$store.commit('filter/insights/ratings/weight', value)

		},

		visualise: function() {

			var backgroundColor = []

			this.$_.each(this.data.bands, function(band) {

				backgroundColor.push(this.bands[Math.floor(band)])

			}.bind(this))

			this.polar.dataset.data = this.data.counts
			this.polar.dataset.subvalue = this.data.bands
			this.polar.dataset.backgroundColor = backgroundColor

			this.bars.datasets[0].data = this.data.counts
			this.bars.datasets[0].subvalue = this.data.bands
			this.bars.datasets[0].backgroundColor = backgroundColor

		}
	
	}

}

</script>

<style scoped>

</style>