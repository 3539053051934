<template>

<core-list-column :format="format" class="column">{{ format.title }}</core-list-column>

</template>

<script>

export default {

	props: ['format']

}

</script>

<style scoped>

.column {
	width: 80px;
	flex-shrink: 0;
	text-align: center;
	justify-content: center;
}

</style>