<template>

<div class="band" :class="{'is-active': isSelected}" v-on:click="$emit('click')">

    <div class="band-name">{{ name }}</div>

    <div class="band-spread" v-if="spread && count">

        <div class="band-spread-percent" :style="{width: spreadPercent}" v-if="count">

            <template v-for="(bandCount, bandIndex) in spreadRatings">
				
                <div 
                    class="band-spread-rating"
                    :key="bandIndex" 
                    :data-band="bandIndex" 
                    :style="{width: spreadWidth(bandCount)}" 
                    v-tooltip="spreadTooltip(bandCount, bandIndex)" 
                    v-if="bandCount" 
                />
			
            </template>
		
        </div>

    </div>

    <div class="band-count" v-if="count">{{ count }}</div>

    <div class="band-rating" v-if="rating && count">
    
        <core-rating :value="rating" />
    
    </div>

    <div class="band-caret" v-if="count"><i class="fa fa-caret-right"></i></div>

</div>

</template>

<script>

export default {

    props: ['isSelected', 'count', 'index', 'rating', 'spread'],

    computed: {
    
        name: function() {
        
            return ((parseInt(this.index) * 100) + 1).toString() + ' - ' + ((parseInt(this.index) + 1) * 100).toString()
        
        },

        spreadPercent: function() {
        
			var maxSize = this.spread.max
			var percent = (100 / maxSize) * this.count

			return percent.toString() + '%'

        },

        spreadRatings: function() {

            return (this.spread.values) ? this.spread.values[this.index] : [this.count]
        
        }

    },

    methods: {

        spreadWidth: function(count) {

            var maxSize = this.count
			var percent = (100 / maxSize) * count

			return percent.toString() + '%'
        
        },

        spreadTooltip: function(count, index) {

            var html = []

			html.push(index.toString() + '.0')

			if (index < 10) html.push(' - ' + index.toString() + '.9')

			html.push('<small>' + count.toString() + this.$util.plural(this.spread.noun, count) + '</small>')

			return html.join('')
        
        }
    
    }

}

</script>

<style scoped>

.band {
	width: 100%;
	height: 30px;
	display: flex;
	padding: 0px 15px;
	flex-direction: row;
}

.band:nth-child(odd) {
	background-color: rgba(12, 137, 178, 0.05);
}

.is-desktop .band:hover {
	cursor: pointer;
}

.band-name {
	padding: 0px;
	width: 80px;
	font-size: 14px;
	flex-grow: 1;
    line-height: 30px;
    font-weight: 300;
    color: #333;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.is-desktop .band:hover .band-name,
.band.is-active .band-name {
	font-weight: 500;
}

.band-count {
	width: 32px;
    flex-shrink: 0;
    text-align: right;
    font-size: 14px;
    line-height: 30px;
    font-weight: 300;
    color: #333;
}

.band-rating > .rating {
	width: 32px;
	height: 24px;
	line-height: 24px;
	font-size: 14px!important;
	font-weight: 400;
	margin: 3px 8px;
	border-radius: 4px;
}

.band-spread {
	width: 100px;
	height: 8px;
	margin: 11px 0px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.band-spread-percent {
	border-radius: 4px;
	height: 8px;
	min-width: 8px;
	display: flex;
	flex-direction: row;
}

.band-spread-rating {
	height: 8px;
	min-width: 4px;
}

.band-spread-rating:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.band-spread-rating:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.band-spread-rating[data-band="10"] { background-color: #0d9a5c; }
.band-spread-rating[data-band="9"] { background-color: #249563; }
.band-spread-rating[data-band="8"] { background-color: #2FC482; }
.band-spread-rating[data-band="7"] { background-color: #1d8acd; }
.band-spread-rating[data-band="6"] { background-color: #5369a2; }
.band-spread-rating[data-band="5"] { background-color: #5379a2; }
.band-spread-rating[data-band="4"] { background-color: #df4751; }
.band-spread-rating[data-band="3"] { background-color: #db4f58; }
.band-spread-rating[data-band="2"] { background-color: #db303b; }
.band-spread-rating[data-band="1"] { background-color: #db3943; }
.band-spread-rating[data-band="0"] { background-color: #4881bb; }
.band-spread-rating[data-band="-"] { display: none; }

.band-caret {
	text-align: right;
	color: #333;
	font-size: 16px;
	line-height: 32px;
	opacity: 0.1;
    margin-left: 15px;
}

.is-desktop .band:hover .band-caret,
.band.is-active .band-caret {
	opacity: 1;
}

</style>