<template>

<core-dropdown class="presets" trigger="manual" v-on:forceClose="is.expanded = false" :expanded="is.expanded" v-click-outside="onOutsideClick">

	<core-button v-on:click.native="onToggleClick" theme="filter" tooltip="Manage presets" :iconOnly="true" icon="bookmark" class="presets-button" v-if="!hideButton" />
	
	<core-dropdown-list class="presets-options" :caret="true" align="right" type="button" v-propagation>

		<core-dropdown-item type="title" v-if="loggedin">Your {{ title }} presets</core-dropdown-item>

		<core-dropdown-item type="text" class="is-empty" v-if="!loggedin">You must be logged in <br/>to create and use presets.</core-dropdown-item>
		<core-dropdown-item type="text" class="is-empty" v-if="!hasPresets && loggedin">No presets saved.</core-dropdown-item>

		<core-dropdown-item type="link" v-for="item in presets" v-bind:key="item.id" v-on:click.native="onPresetClick(item.id)" v-bind:class="{'is-active': currentPreset === item.id}">{{ item.name }}</core-dropdown-item>

		<template v-if="currentPreset && loggedin">

			<core-dropdown-item type="divide"></core-dropdown-item>

			<core-dropdown-item type="title">Current preset</core-dropdown-item>

			<core-dropdown-item type="link" :loading="is.saving" :disabled="!is.changed || !isFiltering" v-on:click.native="onSaveClick">Save changes</core-dropdown-item>
			<core-dropdown-item type="link" :disabled="!is.changed" v-on:click.native="onResetChangesClick">Reset changes</core-dropdown-item>
			<core-dropdown-item type="link" :loading="is.defaulting" v-if="!isDefault" v-on:click.native.stop="onDefaultClick">Set as default</core-dropdown-item>
			<core-dropdown-item type="link" :loading="is.defaulting" v-if="isDefault" v-on:click.native="onDefaultClick">Remove as default</core-dropdown-item>
			<core-dropdown-item type="link" v-on:click.native="onEditClick">Edit name</core-dropdown-item>
			<core-dropdown-item type="link" v-on:click.native="onDeleteClick">Delete</core-dropdown-item>

		</template>

		<template v-if="loggedin">

			<core-dropdown-item type="divide"></core-dropdown-item>

			<core-dropdown-item type="title">Current filter</core-dropdown-item>

			<core-dropdown-item type="link" :disabled="!isFiltering" v-on:click.native="onCreateClick">Save as new preset</core-dropdown-item>
			<core-dropdown-item type="link" :disabled="!isFiltering" v-on:click.native="onResetClick">Reset</core-dropdown-item>

		</template>

	</core-dropdown-list>

</core-dropdown>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	props: ['type', 'hideButton'],

	directives: {
		clickOutside: vClickOutside.directive
	},

	data: function() {

		return {

			is: {
				expanded: false,
				changed: false,
				saving: false,
				defaulting: false
			}

		}

	},

	created: function() {

		this.$pubsub.$on('presets.open', this.onToggleClick.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('presets.open', this.onToggleClick.bind(this))

	},

	computed: {

		title: function() {

			return this.$CONSTANTS.PRESET.TITLE[this.$CONSTANTS.PRESET.ID[this.type]].toLowerCase()

		},

		hasPresets: function() {

			return this.presets.length

		},

		currentPreset: function() {

			return this.$store.getters['filter/' + this.type + '/preset']

		},

		preset: function() {

			if (!this.currentPreset) return {}

			return this.$store.getters['session/presets/associated'][this.currentPreset]

		},

		presets: function() {

			return this.$store.getters['session/presets/' + this.type]

		},

		filter: function() {

			return this.$store.getters['filter/' + this.type]

		},

		isDefault: function() {

			return (this.currentPreset) ? this.preset.default : false

		},

		isFiltering: function() {

			return this.$_.reduce(this.$store.getters['filter/' + this.type + '/active'], function(a, b) { return a + ((b) ? 1 : 0) }, 0)

		}

	},

	watch: {

		currentPreset: function(n) {

			if (n) {

				this.$store.commit('filter/' + this.type + '/set', this.preset.filter)

			}

		},

		filter: {

			handler: function(n) {

				if (this.currentPreset) {

					this.is.changed = !this.$util.filter.compare(n, this.preset.filter, this.type)

				} 

			},

			deep: true

		}

	},

	methods: {

		onToggleClick: function() {

			this.is.expanded = !this.is.expanded

		},

		onOutsideClick: function() {

			this.is.expanded = false

		},

		onPresetClick: function(id) {

			this.$store.commit('filter/' + this.type + '/preset', id)

		},

		onSaveClick: function() {

			if (this.currentPreset) {

				this.is.saving = true

				this.$store.dispatch('api/presets/save', {
					id: this.currentPreset,
					data: {
						filter: this.filter
					}
				}).then(function(json) {

					this.$store.commit('session/presets/set', json.presets)
					this.is.changed = false
					this.is.saving = false

				}.bind(this), function() {

					this.is.saving = false

				}.bind(this)) 

			}

		},

		onResetChangesClick: function() {

			if (this.currentPreset) {

				this.$store.commit('filter/' + this.type + '/set', this.preset.filter)

			}

		},

		onDefaultClick: function() {

			if (this.currentPreset) {

				this.is.defaulting = true

				this.$store.dispatch('api/presets/default', {
					id: this.currentPreset
				}).then(function(json) {

					this.$store.commit('session/presets/set', json.presets)
					this.is.defaulting = false

				}.bind(this), function() {

					this.is.defaulting = false

				}.bind(this)) 

			}

		},

		onEditClick: function() {

			if (this.currentPreset) {

				this.$pubsub.$emit('open.modal', {
					id: 'presetEdit',
					preset: this.currentPreset,
					name: this.preset.name,
					type: this.$CONSTANTS.PRESET.ID[this.type]
				})

			}

		},

		onDeleteClick: function() {

			if (this.currentPreset) {

				this.$pubsub.$emit('open.modal', {
					id: 'presetDelete',
					preset: this.currentPreset,
					type: this.$CONSTANTS.PRESET.ID[this.type]
				})

			}

		},

		onResetClick: function() {

			this.$store.commit('filter/' + this.type + '/reset')

		},

		onCreateClick: function() {

			this.$pubsub.$emit('open.modal', {
				id: 'presetCreate',
				type: this.$CONSTANTS.PRESET.ID[this.type]
			})

		}

	}

}

</script>

<style scoped>

.presets-button {
	margin-top: 0px;
	padding: 0px 8px!important;
	margin-left: 0px;
	min-width: auto!important;
}

.presets-options {
	right: -1px;
	width: auto;
	top: 48px!important;
	border-radius: 4px;
	padding: 4px 0px;
	z-index: 20000!important;
}

.is-mobile .presets-options {
	top: 144px!important;
}

.presets-options:before {
	display: none;
}

.is-active {
	font-weight: 700;
}

</style>
