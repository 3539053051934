<template>
	
<div class="stats" v-if="location || duration">
				
	<div class="stats-item is-location"><template v-if="location"><i class="fa fa-map-marker"></i> {{ location }}</template></div>
	<div class="stats-item" v-if="duration"><i class="fa fa-clock"></i> <span>{{ duration }}</span></div>
	<div class="stats-item" v-if="count && (duration || location)"><i class="fa fa-users"></i> <span>{{ count }}</span></div>

</div>

</template>

<script>

export default {

	props: ['item'],

	computed: {

		duration: function() {

			return (this.item.play.length) ? this.item.play.length : false

		},

		count: function() {

			return (this.item.players.length) ? this.item.players.length : false

		},

		location: function() {

			return (this.item.play.location) ? this.item.play.location : false

		}

	}

}

</script>

<style scoped>

.stats {
	display: flex;
	flex-direction: row;
	padding: 0px 10px 10px 10px;
}

.stats-item {
	flex-shrink: 0;
	flex-basis: 0;
	font-size: 12px;
	color: #333;
	font-weight: 400;
	white-space: nowrap;
	padding-left: 8px;
}

.stats-item.is-location {
	flex-grow: 1;
	min-width: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding-left: 0px;
}

.stats-item .fa {
	color: #333;
	font-size: 12px;
	margin-right: 4px;
}

</style>
