<template>

<core-list-column :format="format" type="rating" class="column">{{ text }}</core-list-column>

</template>

<script>

export default {

	props: ['format'],

	computed: {

		sort: function() {

			return 'rating.' + this.format.context

		},

		text: function() {

			if (this.format.title) return this.format.title
			if (this.format.context === 'group') return 'Group'
			if (this.format.context === 'buddies') return 'Buddies'
			if (this.format.context === 'bgg') return 'BGG'
			if (this.format.context === 'user') return (this.$store.getters['permissions/is/owner']) ? 'You' : 'User'

			return false

		}

	}

}

</script>