import 'vue-toast-notification/dist/theme-sugar.css'

// extend vue behaviour
import Vue from 'vue'
import Vuex from 'vuex'

import VueToast from 'vue-toast-notification'
import VueObserveVisibility from 'vue-observe-visibility'
import Vue2TouchEvents from 'vue2-touch-events'

// bootstrap core and app itselef
import 'core/bootstrap' 
import 'app/bootstrap' 

// import third party libraries to pass to components
import moment from 'moment'
import _ from 'underscore'

// import local libraries to pass to components
import App from 'app/App' 
import store from 'app/store' 
import router from 'app/router' 
import util from 'app/util' 
import CONSTANTS from 'app/constants'
import pubsub from 'core/pubsub'
import api from 'core/api'

/*
import SimpleBar from 'simplebar'

SimpleBar.removeObserver()
*/

Vue.config.productionTip = false
Vue.config.performance = true

Vue.use(Vuex)
Vue.use(VueToast)
Vue.use(Vue2TouchEvents)
Vue.use(VueObserveVisibility)

CONSTANTS.API = 'https://api.geekgroup.app/api/' 
CONSTANTS.ASSETS = 'https://img.geekgroup.app' 
CONSTANTS.VERSION.BUILD = 55
CONSTANTS.VERSION.DATE = 1

// auto-register all common components
var requireComponentGlobal = require.context('core/components', true, /[A-Z]\w+\.(vue|js)$/)
var requireComponentLocal = require.context('app/components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'core' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		(componentName === 'core-plural') ? 'plural' : componentName,
		componentConfig.default
	)

})

requireComponentLocal.keys().forEach(function(filename) {

	var componentConfig = requireComponentLocal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	store,
	CONSTANTS,
	util,
	moment,
	api,
	_,
	pubsub,
	render: h => h(App),
	created: function() {

		pubsub.$emit('window.initiated')

	}
}).$mount('#app')
