<template>

<core-config-section :locked="!loggedin">

	<h2>Exclude games</h2>

	<p class="about">You can exclude specific games from appearing in this list, for example if you have played them already but you did not log the play.</p>

	<div class="inputs">

		<core-filter-input-suggest placeholder="Start typing a game's name to exclude..." field="name" source="collection" filter="config/lists/unplayed/games" api="games/suggest" data="games" />

	</div>

</core-config-section>

</template>

<script>

export default {

}

</script>