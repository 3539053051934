<template>

<core-panel>

	<template v-slot:head>

		<h1><template v-if="data.y !== false">{{ weights[data.y] }}</template><template v-if="data.y !== false && data.x !== false">, </template><template v-if="data.x !== false">{{ durations[data.x] }}</template></h1>

		<p>There are <b>{{ games.length }}</b> <plural t="game" :n="games.length" /> for {{ counts[data.filter['suitability.count']] }}.</p>

	</template>

	<template v-slot:body>

		<app-game v-for="(item, index) in games" :item="item" :subtext="subtext(item)" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'suitability',
			games: [],
			params: ['x', 'y', 'filter'],
			weights: {
				0: 'Light',
				1: 'Light-Medium',
				2: 'Medium',
				3: 'Heavy'
			},
			durations: {
				0: '0-1 hours',
				1: '1-2 hours',
				2: '2-3 hours',
				3: '3-4 hours ',
				4: '4-5 hours',
				5: '5-6 hours'
			},
			counts: {
				'any': 'any count',
				1: 'one player',
				2: 'two players',
				3: 'three players',
				4: 'four players',
				5: 'five players',
				6: 'six players'
			}
		}

	},

	methods: {	

		response: function(json) {

			this.games = json.games

		},

		subtext: function(item) {

			return item.weight.toString() + ' / ' + Math.floor(item.time.toString()) + ' minutes'

		}

	}

}

</script>