<template>

<com-item name="ratings.year" :has-more="moreYears" v-on:more="onMoreClick" :size="size" :is-empty="!active" :loading="loading" title="Publish Year">

	<template v-slot:about>

		<p>Which publish years were {{ your }} most highly rated.</p>

		<p class="small">Excludes expansions.</p>

		<p class="small">Click a year to view games.</p>

	</template>

	<template v-slot:stats>
	
		<div class="years">

			<div class="years-item" v-tooltip="'Click to view games'" :data-rating="ratingBand(year.rating, year.count)" :data-band="band(year.count)" v-for="year in data" :key="year.year" v-on:click="onYearClick($event, year.year)">

				<div class="years-item-bubble">
					<div class="years-item-bubble-rating" v-if="year.count">{{ year.rating / year.count | round(1) }}</div>
					<div class="years-item-bubble-year">{{ year.year }}</div>
				</div>

			</div>

		</div>

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	computed: {

		loading: function() {

			return this.fetching === 'all'

		},

		data: function() {

			return this.$store.getters['page/insights/ratings/years']

		},
		
		moreYears: function() {

			return this.$store.getters['page/insights/ratings/moreYears']

		},

		active: function() {

			return this.data.length && !this.loading

		}

	},

	methods: {

		ratingBand: function(rating, count) {

			return (count) ? Math.floor(rating / count) : 0

		},

		band: function(count) {

			if (count) {

				var perBand = this.$_.max(this.data, function(item) { return item.count }).count / 4
				var band = Math.ceil(count / perBand)

				return band

			} else {

				return 0

			}

		},

		onYearClick: function(e, value) {
		
			e.stopPropagation()

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsYear',
				year: value
			})

		},

		onMoreClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsRatingsYears'
			})

		}
	
	}

}

</script>

<style scoped>

.years {
	display: grid;
	height: 100%;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	border-radius: 4px;
	overflow: hidden;
}

.years-item {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	cursor: pointer;
	position: relative;
}

.years-item-bubble {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 24px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	transition: transform 100ms linear;
}

.years-item-bubble-year {
	font-family: 'Roboto Mono', monospace;
	font-size: 16px;
	font-weight: 400;
}

.years-item-bubble-rating {
	font-size: 21px;
	line-height: 24px;
	font-weight: 500;
	display: none;
}

.years-item:hover .years-item-bubble-year {
	display: none;
}

.years-item:hover .years-item-bubble-rating {
	display: block;
}

.years-item[data-rating="10"] .years-item-bubble { background-color: #249563; }
.years-item[data-rating="9"] .years-item-bubble { background-color: #249563; }
.years-item[data-rating="8"] .years-item-bubble { background-color: #2FC482; }
.years-item[data-rating="7"] .years-item-bubble  { background-color: #1d8acd; }
.years-item[data-rating="6"] .years-item-bubble { background-color: #5369a2; }
.years-item[data-rating="5"] .years-item-bubble { background-color: #5369a2; }
.years-item[data-rating="4"] .years-item-bubble { background-color: #df4751; }
.years-item[data-rating="3"] .years-item-bubble { background-color: #df4751; }
.years-item[data-rating="2"] .years-item-bubble { background-color: #db303b; }
.years-item[data-rating="1"] .years-item-bubble { background-color: #db303b; }

.years-item[data-band="0"] .years-item-bubble {
	background-color: transparent;
	color: #ccc;
}

.years-item[data-band="0"] {
	pointer-events: none;
}

</style>