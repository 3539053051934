<template>
	
<transition name="modal">

	<com-game-rename :data="modal.gameRename" v-on:close="onClose('gameRename')" v-if="modal.gameRename" />

	<com-activity-delete :data="modal.activityDelete" v-on:close="onClose('activityDelete')" v-if="modal.activityDelete" />

	<com-app-about :data="modal.appAbout" v-on:close="onClose('appAbout')" v-if="modal.appAbout" />
	<com-app-log :data="modal.appLog" v-on:close="onClose('appLog')" v-if="modal.appLog" />
	<com-app-terms :data="modal.appTerms" v-on:close="onClose('appTerms')" v-if="modal.appTerms" />
	<com-app-syncs :data="modal.appSyncs" v-on:close="onClose('appSyncs')" v-if="modal.appSyncs" />
	<com-app-privacy :data="modal.appPrivacy" v-on:close="onClose('appPrivacy')" v-if="modal.appPrivacy" />

	<com-tag-create :data="modal.tagCreate" v-on:close="onClose('tagCreate')" v-if="modal.tagCreate && loggedin" />
	<com-tag-edit :data="modal.tagEdit" v-on:close="onClose('tagEdit')" v-if="modal.tagEdit && loggedin" />
	<com-tag-delete :data="modal.tagDelete" v-on:close="onClose('tagDelete')" v-if="modal.tagDelete && loggedin" />

	<com-group-create v-on:close="onClose('groupCreate')" v-if="modal.groupCreate && loggedin" />
	<com-group-add v-on:close="onClose('groupAdd')" v-if="modal.groupAdd && loggedin" />
	<com-group-leave v-on:close="onClose('groupLeave')" v-if="modal.groupLeave && loggedin" />
	<com-group-remove :data="modal.groupRemove" v-on:close="onClose('groupRemove')" v-if="modal.groupRemove && loggedin" />
	<com-group-join v-on:close="onClose('groupJoin')" v-if="modal.groupJoin" />
	<com-group-edit v-on:close="onClose('groupEdit')" v-if="modal.groupEdit && loggedin" />
	<com-group-delete v-on:close="onClose('groupDelete')" v-if="modal.groupDelete && loggedin" />
			
	<com-user-notifications v-on:close="onClose('userNotifications')" v-if="modal.userNotifications && loggedin" />
	<com-user-notification :data="modal.userNotification" v-on:close="onClose('userNotification')" v-if="modal.userNotification && loggedin" />
	<com-user-sync v-on:close="onClose('userSync')" v-if="modal.userSync && loggedin" />
	<com-user-deep v-on:close="onClose('userDeep')" v-if="modal.userDeep && loggedin" />
	<com-user-login v-on:close="onClose('userLogin')" v-if="modal.userLogin && !loggedin" />
	<com-user-register v-on:close="onClose('userRegister')" v-if="modal.userRegister && !loggedin" />
	<com-user-forgot v-on:close="onClose('userForgot')" v-if="modal.userForgot && !loggedin" />
	<com-user-password v-on:close="onClose('userPassword')" v-if="modal.userPassword && loggedin" />
	<com-user-tipjar v-on:close="onClose('userTipjar')" v-if="modal.userTipjar && loggedin" />
	<com-user-settings v-on:close="onClose('userSettings')" v-if="modal.userSettings && loggedin" />
	<com-user-groups v-on:close="onClose('userGroups')" v-if="modal.userGroups && loggedin" />
			
	<com-list-create v-on:close="onClose('listCreate')" v-if="modal.listCreate && loggedin" />
	<com-list-delete v-on:close="onClose('listDelete')" v-if="modal.listDelete && loggedin" />
	<com-list-edit v-on:close="onClose('listEdit')" v-if="modal.listEdit && loggedin" />
	<com-list-edition-move :data="modal.listEditionMove" v-on:close="onClose('listEditionMove')" v-if="modal.listEditionMove && loggedin" />
	<com-list-edition-create :data="modal.listEditionCreate" v-on:close="onClose('listEditionCreate')" v-if="modal.listEditionCreate && loggedin" />
	<com-list-edition-delete v-on:close="onClose('listEditionDelete')" v-if="modal.listEditionDelete && loggedin" />

	<com-preset-create :data="modal.presetCreate" v-on:close="onClose('presetCreate')" v-if="modal.presetCreate && loggedin" />
	<com-preset-edit :data="modal.presetEdit" v-on:close="onClose('presetEdit')" v-if="modal.presetEdit && loggedin" />
	<com-preset-delete :data="modal.presetDelete" v-on:close="onClose('presetDelete')" v-if="modal.presetDelete && loggedin" />

	<com-about-sync v-on:close="onClose('aboutSync')" v-if="modal.aboutSync" />

	<com-help-worth :data="modal.helpWorth" v-on:close="onClose('helpWorth')" v-if="modal.helpWorth" />
	<com-help-shelf :data="modal.helpShelf" v-on:close="onClose('helpShelf')" v-if="modal.helpShelf" />

</transition>

</template>

<script>

import activityDelete from './activity/Delete'

import gameRename from './game/Rename'

import appAbout from './app/About'
import appLog from './app/Log'
import appTerms from './app/Terms'
import appPrivacy from './app/Privacy'
import appSyncs from './app/Syncs'

import tagCreate from './tag/Create'
import tagEdit from './tag/Edit'
import tagDelete from './tag/Delete'

import groupCreate from './group/Create'
import groupAdd from './group/Add'
import groupLeave from './group/Leave'
import groupJoin from './group/Join'
import groupEdit from './group/Edit'
import groupDelete from './group/Delete'
import groupRemove from './group/Remove'

import userNotifications from './user/Notifications'
import userNotification from './user/Notification'
import userSync from './user/Sync'
import userDeep from './user/Deep'
import userLogin from './user/Login'
import userRegister from './user/Register'
import userForgot from './user/Forgot'
import userPassword from './user/Password'
import userTipjar from './user/Tipjar'
import userSettings from './user/Settings'
import userGroups from './user/Groups'

import listCreate from './list/Create'
import listDelete from './list/Delete'
import listEdit from './list/Edit'
import listEditionCreate from './list/edition/Create'
import listEditionDelete from './list/edition/Delete'
import listEditionMove from './list/edition/Move'

import presetCreate from './preset/Create'
import presetEdit from './preset/Edit'
import presetDelete from './preset/Delete'

import aboutSync from './about/Sync'

import helpWorth from './help/Worth'
import helpShelf from './help/Shelf'

export default {

	components: {
		'com-activity-delete': activityDelete,
		'com-game-rename': gameRename,
		'com-app-about': appAbout,
		'com-app-log': appLog,
		'com-app-terms': appTerms,
		'com-app-privacy': appPrivacy,
		'com-app-syncs': appSyncs,
		'com-tag-create': tagCreate,
		'com-tag-edit': tagEdit,
		'com-tag-delete': tagDelete,
		'com-group-create': groupCreate,
		'com-group-add': groupAdd,
		'com-group-leave': groupLeave,
		'com-group-join': groupJoin,
		'com-group-edit': groupEdit,
		'com-group-delete': groupDelete,
		'com-group-remove': groupRemove,
		'com-user-notifications': userNotifications,
		'com-user-notification': userNotification,
		'com-user-sync': userSync,
		'com-user-deep': userDeep,
		'com-user-login': userLogin,
		'com-user-register': userRegister,
		'com-user-forgot': userForgot,
		'com-user-password': userPassword,
		'com-user-settings': userSettings,
		'com-user-tipjar': userTipjar,
		'com-user-groups': userGroups,
		'com-list-create': listCreate,
		'com-list-delete': listDelete,
		'com-list-edit': listEdit,
		'com-list-edition-move': listEditionMove,
		'com-list-edition-create': listEditionCreate,
		'com-list-edition-delete': listEditionDelete,
		'com-preset-create': presetCreate,
		'com-preset-edit': presetEdit,
		'com-preset-delete': presetDelete,
		'com-about-sync': aboutSync,
		'com-help-worth': helpWorth,
		'com-help-shelf': helpShelf
	},

	data: function() {

		return {

			modal: {
				activityDelete: false,
				gameRename: false,
				appAbout: false,
				appLog: false,
				appSyncs: false,
				appTerms: false,
				appPrivacy: false,
				tagCreate: false,
				tagEdit: false,
				tagDelete: false,
				groupCreate: false,
				groupAdd: false,
				groupLeave: false,
				groupJoin: false,
				groupEdit: false,
				groupDelete: false,
				groupRemove: false,
				userSync: false,
				userNotifications: false,
				userNotification: false,
				userDeep: false,
				userForgot: false,
				userLogin: false,
				userRegister: false,
				userPassword: false,
				userTipjar: false,
				userSettings: false,
				userGroups: false,
				listCreate: false,
				listDelete: false,
				listEdit: false,
				listEditionCreate: false,
				listEditionMove: false,
				listEditionDelete: false,
				presetCreate: false,
				presetEdit: false,
				presetDelete: false,
				aboutSync: false,
				helpWorth: false,
				helpShelf: false
			}

		}

	},

	created: function() {

		this.$pubsub.$emit('panel.opened')

		this.$pubsub.$on('open.modal', function(e) {

			if (e.root) {

				this.$_.each(this.modal, function(modal, key) {

					if (this.modal[key]) this.onClose(key)

				}.bind(this))

			} else {
			
				this.$_.each(this.modal, function(value, key) {

					if (this.modal[key]) {

						this.onClose(key)

					}

				}.bind(this))

			}

			var id = (typeof e === 'string') ? e : e.id

			this.modal[id] = e

		}.bind(this))

		this.$pubsub.$on('close.modals', function() {

			this.$_.each(this.modal, function(value, key) {

				if (this.modal[key]) {

					this.onClose(key)

				}

			}.bind(this))

		}.bind(this))

	},

	methods: {

		onClose: function(id) {

			this.modal[id] = false
			this.$pubsub.$emit('close.modal')

		}

	}

}

</script>

<style>

.is-desktop .modal-enter-active,
.is-smaller .modal-enter-active {
	animation: menu-slide 100ms linear;
}

.is-desktop .modal-leave-active,
.is-smaller .modal-leave-active {
	animation: menu-slide 100ms reverse;
}

@keyframes menu-slide {
	from {
		transform: translateX(376px);
	}
	to {
		transform: translateX(0px);
	}
}
</style>