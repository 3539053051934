<template>
	
<core-list-cell type="stat" :format="format" class="duration" :condensed="condensed" v-bind:class="{'is-summary': summary}">
				
	<i class="fa fa-clock"></i>
	<span v-show="!item.game.time_min">-</span>
	<span v-show="item.game.time_min">{{ item.game.time_min }}</span>
	<span v-show="item.game.time_min !== item.game.time_max && item.game.time_min && item.game.time_max"><span class="divide">-</span>{{ item.game.time_max }}</span>

</core-list-cell>

</template>

<script>

export default {

	props: ['item', 'summary', 'format', 'condensed']

}

</script>

<style scoped>

@media only screen and (max-width: 767px) {

	.duration:not(.is-summary) {
		display: none!important;
	}

	.duration.is-summary {
		font-size: 10px;
		height: 16px;
		width: 64px;
		line-height: 16px;
		text-align: left;
		color: #333;
		font-weight: 400;
	}

}

.duration span.divide {
	margin: 0px 4px;
}

.duration .fa {
	display: none;
}

@media only screen and (max-width: 767px) {

	.duration.is-summary .fa {
		display: inline-block;
		line-height: 14px;
		color: #666;
		margin-right: 4px;
	}

}

</style>
