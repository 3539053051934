<template>

<div class="faq-item" :class="{'is-expanded': is.expanded}">

	<div class="faq-item-question" v-on:click="onClick">{{ question }}</div>

	<div class="faq-item-answer"><slot></slot></div>

</div>

</template>

<script>

export default {

	props: ['question'],

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('faq.expand', this.onExpand.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('faq.expand', this.onExpand.bind(this))

	},

	methods: {	

		onExpand: function(uuid) {

			if (uuid !== this._uid) this.is.expanded = false

		},

		onClick: function() {

			this.is.expanded = !this.is.expanded

			this.$pubsub.$emit('faq.expand', this._uid)

		}

	}

}

</script>

<style scoped>

.faq-item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.faq-item.is-expanded {
	background-color: rgba(0, 0, 0, 0.2);
}

.is-desktop .faq-item:not(.is-expanded) .faq-item-question:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.faq-item-question {
	padding: 15px;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	cursor: pointer;
}

.faq-item-answer {
	padding: 15px;
	display: none;
}

.faq-item.is-expanded .faq-item-answer {
	display: block;
	padding-top: 0px;
}

.faq-item-answer >>> ul {
	list-style-type: disc;
	list-position: outside;
	margin-bottom: 15px;
}

.faq-item-answer >>> p {
	margin-bottom: 15px;
}

.faq-item-answer >>> li {
	margin-left: 18px;
}

.faq-item-answer >>> li,
.faq-item-answer >>> p {
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
}

.faq-item-answer >>> li small {
	font-size: 14px!important;
}

.faq-item-answer >>> *:last-child {
	margin-bottom: 0px;
}

</style>