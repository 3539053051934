<template>

<com-item name="size.changes" :size="size" :loading="loading" title="Gathering Dust" :is-cards="true" :has-more="true" v-on:more="onLongestClick">

	<template v-slot:about>

		<p>{{ your | capitalise }} games that have been sitting on the shelf for the longest time.</p>

	</template>

	<template v-slot:stats>

		<com-card :item="item" :date="item.from" :date-to="item.to" date-prefix="Since" v-for="item in data" :key="item.id" :tag="tagText(item)" :tagTheme="tagTheme(item)">

			<core-card-foot-stat :value="days(item)" text="day" :plural="true" />

		</com-card>

	</template>

	<template v-slot:buttons>

		<com-toggle :options="longestOptions" :value="longestValue" v-on:change="onLongestChange" />
					
	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'
import comCard from './../common/Card'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle,
		'com-card': comCard
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'longest'
 
		},

		data: function() {

			return this.$store.getters['page/insights/shelf/longest']

		},

		type: function() {

			return this.$store.getters['filter/insights/shelf/longest']

		},

		active: function() {

			return this.data.length && !this.loading

		},

		longestOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.SHELF.LONGEST.CURRENT, text: 'Current'})
			options.push({value: this.$CONSTANTS.INSIGHTS.SHELF.LONGEST.ALL, text: 'All time'})

			return options

		},

		longestValue: function() {

			return this.$store.getters['filter/insights/shelf/longest']

		}

	},

	methods: {

		days: function(item) {
		
			if (this.longestValue === 'current') {
			
				return this.$filter('daysSince', item.from)
			
			} else {
				
				if (item.to) {
				
					return this.$filter('daysBetween', item.from, item.to)
				
				} else {
				
					return this.$filter('daysSince', item.from)
				
				}

			}

		},

		tagText: function(item) {

			if (this.longestValue === 'all') {
			
				if (!item.to && !item.played) return 'Current'
				if (item.to && !item.played) return 'Removed'
				return 'Played'
			
			} else {
			
				return false
			
			}
		
		},

		tagTheme: function(item) {

			if (this.longestValue === 'all') {
			
				if (!item.to && !item.played) return 'green'
				if (item.to && !item.played) return 'red'
				return 'blue'
			
			} else {
			
				return false
			
			}
		
		},

		onLongestChange: function(value) {

			this.$store.commit('filter/insights/shelf/longest', value)

		},

		onLongestClick: function() {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsShelfLongest',
				longest: this.$store.getters['filter/insights/shelf/longest']
			})

		}

	}

}

</script>

<style scoped>

</style>