<template>

<app-page>

	<core-filter-bar>

        <core-filter-bar-left>

            <core-filter-summary title="Settings" icon="cog" />

        </core-filter-bar-left>

        <core-filter-bar-right>

            <core-button v-on:click.native="onUndoClick" :disabled="!is.changed || is.saving" tooltip="Click to undo changes" theme="filter" text="Undo" icon="rotate-left" />
            <core-button v-on:click.native="onSaveClick" :loading="is.saving" :disabled="!is.changed" tooltip="Click to save changes" theme="filter" text="Save" icon="floppy-disk" />

        </core-filter-bar-right>

    </core-filter-bar>

	<core-content-page class="settings">

		<app-config-group title="Sync" count="1">

			<app-config-template-sync-plays-incomplete />

		</app-config-group>

		<app-config-group title="Collection" count="5">

			<app-config-template-collection-nest />
			<app-config-template-collection-contains />
			<app-config-template-collection-status />
			<app-config-template-collection-inventory-location-public />
			<app-config-template-other-currency />

		</app-config-group>

		<app-config-group title="Plays" count="3">

			<app-config-template-plays-usernames />
			<app-config-template-sync-plays-incomplete />
			<app-config-template-insights-hindex-expansions  />

		</app-config-group>

		<app-config-group :title="titleShelf" count="3">

			<app-config-template-list-unplayed-type />
			<app-config-template-list-unplayed-rated />
			<app-config-template-list-unplayed-trade />
			<app-config-template-list-unplayed-exclude />

		</app-config-group>

		<app-config-group title="Insights / Collection / Worth" count="1">

			<app-config-template-other-currency />

		</app-config-group>

		<app-config-group title="Insights / Plays" count="3">

			<app-config-template-sync-plays-incomplete />
			<app-config-template-insights-hindex-expansions  />
			<app-config-template-insights-heatmap-start />

		</app-config-group>

		<app-config-group title="Insights / Streaks & Records" count="2">

			<app-config-template-insights-records-start />
			<app-config-template-plays-usernames />

		</app-config-group>

		<app-config-group title="Other" count="1">

			<app-config-template-other-dateformat />

		</app-config-group>

    </core-content-page>

</app-page>

</template>

<script>

export default {

	data: function() {

		return {

			is: {
				saving: false,
				changed: false
			}

		}

	},

    computed: {

        titleShelf: function() {

			return (this.current.lists.unplayed.type === 'shame') ? 'Lists / Shelf of Shame' : 'Lists / Shelf of Opportunity'

		},

		filter: function() {

			return this.$store.getters['filter/config']

		},

		current: function() {

			return this.$store.getters['session/config']

		}
    
    },

	watch: {

		filter: {

			handler: function() {

				this.is.changed = this.$store.getters['filter/config/changed']

			},

			deep: true

		},

		current: {

			handler: function(n) {

				this.$store.commit('filter/config/set', n)

			},

			deep: true

		}

	},

    methods: {

        onUndoClick: function() {
        
				this.$store.commit('filter/config/reset', this.$store.getters['session/config'])
        
        },
    
        onSaveClick: function() {

            if (this.is.saving) return false

			this.is.saving = true

			this.$store.dispatch('api/config/save', {
				data: this.$store.getters['filter/config']
			}).then(function(json) {

				this.$store.commit('session/config/set', json.config)
				this.$store.commit('filter/config/reset', json.config)

				this.is.saving = false
				this.is.changed = false

                this.$notify({
                    message: 'You have successfully saved your settings.'
                })

			}.bind(this), function() {

				this.is.saving = false

			}.bind(this)) 

		},
    
    }

}

</script>

<style scoped>

.is-mobile .settings {
    padding: 10px;
}

.settings >>> .group-title {
    background-color: #fff;
    color: #333;
    border-radius: 4px;
    font-size: 16px;
    height: 40px;
    line-height: 38px;
    font-weight: 500;
    padding: 0px 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
}

.is-mobile .settings >>> .group-title {
    margin-bottom: 10px;
    padding: 0px 10px;
}

.settings >>> .group.is-expanded .group-title {
    margin-bottom: 0px;
    color: #333;
    border-radius: 4px 4px 0px 0px;
}

.settings >>> .group.is-expanded .group-title,
.settings >>> .group-title:hover {
    background-color: #4881bb!important;
    border-color: #4881bb;
    color: #fff!important;
}

.settings >>> .group-title-count {
    background-color: #4881bb;
    color: #fff;
    font-size: 14px;
    height: 24px;
    min-width: 24px;
    line-height: 22px;
    text-align: center;
}

.settings >>> .group-title:hover .group-title-count {
    background-color: #fff;
    color: #333;
}

.settings >>> .group-chevron {
	color: #4881bb;
	line-height: 40px;
}

.settings >>> .group.is-expanded .group-chevron,
.settings >>> .group-title:hover .group-chevron {
	color: #fff;
}

.settings >>> .group-inputs {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: 0px;
    border-radius: 0px 0px 4px 4px;
    margin-bottom: 20px;
}

.is-mobile .settings >>> .group-inputs {
    margin-bottom: 10px;
}

.settings >>> .group.is-expanded .group.section {
    padding: 20px!important;
    border-bottom: 1px solid #ddd;
}

.settings >>> .group.is-expanded .group.section:first-child {
    padding: 20px!important;
}

.is-mobile .settings >>> .group.is-expanded .group.section {
    padding: 10px!important;
}

.is-mobile .settings >>> .group.is-expanded .group.section:first-child {
    padding: 10px!important;
}

.settings >>> .group.is-expanded .group.section:last-child {
    border-bottom: 0px;
}

.settings >>> .input {
    margin-bottom: 0px;
}

.settings >>> .input-text {
    color: #333;
    font-size: 14px;
}

.settings >>> .group h2 {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.settings >>> .group h2 small {
    color: #777;
    opacity: 1;
    font-size: 14px;
}

.settings >>> .group p {
    color: #333;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
}

.settings >>> .input {
    height: auto;
}

.settings >>> .input-text {
    margin: 0px;
    height: 40px;
    line-height: 40px;
}

.settings >>> .input-text input {
    color: #333;
    background-color: #efefef;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
}

.settings >>> .input-text input::placeholder {
    color: #888;
}

.settings >>> .input-selected {
    border: 1px solid #ddd;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 4px;
    padding-top: 0px;
}

.settings >>> .input-selected-item {
    color: #333;
    border-bottom: 1px solid #ddd;
    padding: 0px 10px;
    height: 40px;
    border-radius: 0px;
}

.settings >>> .input-selected-item:last-child {
    border-bottom: 0px;
}

.settings >>> .input-selected-item-text {
    font-size: 14px;
}

.settings >>> .input.is-stacked .input-toggle {
    border: 1px solid #ddd;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    padding-top: 0px;
}

.settings >>> .input.is-stacked .input-toggle-item {
    font-size: 14px;
    color: #333;
    border-radius: 0px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    line-height: 16px;
    height: 40px;
    padding: 0px 10px;
    font-weight: 400;
}

.settings >>> .input.is-stacked .input-toggle-item:last-child {
    border-bottom: 0px;
}

.settings >>> .input.is-stacked .input-toggle-item.is-active {
    background-color: #4881bb;
    color: #fff;
}

.settings >>> .input:not(.is-stacked) .input-toggle-slider {
    height: 40px;
    width: 128px;
}

.settings >>> .input:not(.is-stacked) .input-toggle-slider-value {
    height: 40px;
    font-size: 14px;
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    line-height: 40px;
}

.settings >>> .input:not(.is-stacked) .input-toggle-slider-divider {
    height: 40px;
    background-color: #4881bb;
}

.settings >>> .input:not(.is-stacked) .input-toggle {
    height: 40px;
    border: 0px;
}

.settings >>> .input:not(.is-stacked) .input-toggle-item {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0px 20px;
}

.settings >>> .input:not(.is-stacked) .input-toggle-item:not(.is-active) {
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
}

.settings >>> .input:not(.is-stacked) .input-toggle-item.is-active {
    background-color: #4881bb;
}

.settings >>> .input-date {
    font-size: 14px;
}

.settings >>> .datepicker-input {
    font-size: 14px;
    height: 40px;
    color: #333;
    background-color: #efefef;
    padding: 0px 10px;
}

.settings >>> .datepicker-input::placeholder {
    color: #888;
}

.settings >>> .input-inputs {
    flex-grow: 1;
}

</style>