<template>

<div v-click-outside="onOutsideClick">

	<core-button v-on:click.native="onButtonClick" tooltip="Manage related settings" theme="filter" icon="cogs" :iconOnly="true" v-if="!hideButton" />

	<core-config-panel :state="state" v-on:close="$emit('close')" v-on:save="$emit('save')" :loginRequired="loginRequired" :selfOnly="selfOnly">

		<slot></slot>

	</core-config-panel>

</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {

	directives: {
		clickOutside: vClickOutside.directive
	},

	props: ['state', 'loginRequired', 'selfOnly', 'hideButton'],

	methods: {

		onButtonClick: function() {

			this.$emit('expand')

		},

		onOutsideClick: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>
