<template>

<core-filter-advanced title="Settings" v-on:clickoutside="$emit('close')" :active="state.expanded">

	<template v-slot:inputs>

		<div class="login" v-if="!loggedin && loginRequired"><i class="fa fa-exclamation-circle"></i> If this is your profile, you must be logged in to manage these settings.</div>

		<div class="login" v-if="!loggedin && !loginRequired"><i class="fa fa-exclamation-circle"></i> Your setting changes will not be permanent unless you are logged in.</div>

		<div class="login" v-if="loggedin && selfOnly && !isSelf"><i class="fa fa-exclamation-circle"></i> These settings apply to your own profile and not the user you're currently viewing.</div>

		<slot></slot>

	</template>

	<template v-slot:buttons>
	
		<core-button v-on:click.native="$emit('save')" :disabled="!state.changed" :loading="state.saving" theme="blue" fluid="yes" v-if="loggedin || (!loggedin && !loginRequired)">{{ applyText }}</core-button>

		<core-button :disabled="!state.changed" v-on:click.native="$emit('close')" theme="blue" fluid="yes" v-if="loggedin || (!loggedin && !loginRequired)">Cancel changes</core-button>

		<core-button v-if="(window.is.mobile && !state.changed) || (!loggedin && loginRequired)" v-on:click.native="$emit('close')" theme="blue" fluid="yes">Close settings</core-button>

	</template>

</core-filter-advanced>

</template>

<script>

export default {

	props: ['state', 'loginRequired', 'selfOnly'],

	computed: {

		applyText: function() {

			return (this.loggedin) ? 'Save & apply changes' : 'Apply changes'

		}

	}

}

</script>

<style scoped>

.login {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-weight: 400;
    padding: 20px;
    display: block;
    width: 100%;
    background-color: #19455c;
    padding-left: 72px;
}

.login .fa {
	font-size: 32px;
	position: absolute;
	left: 20px;
	top: 20px;
}

</style>