import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: []

	},

	getters: {

		count: function(state) {

			return state.data.length

		},

		list: function(state) {

			return state.data

		},

		associated: function(state) {

			var associated = {}

			_.each(state.data, function(item) {

				associated[item.id] = item

			})

			return associated

		}

	},

	mutations: {

		reset: function(state) {

			state.data = []

		},

		set: function(state, data) {

			state.data = data

		},

		push: function(state, item) {

			state.data.push(item)

		}

	},

	actions: {}

}