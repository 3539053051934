<template>

<router-link :to="{name: route}" class="item" :class="{'is-first': first, 'is-session': $route.meta.isSession}" :data-indent="indent" :data-gap="gap">

    <div class="item-icon" v-if="icon"><i class="fa-solid fa-fw" :class="'fa-' + icon"></i></div>
    <div class="item-indent" v-if="indent"></div>
    <div class="item-text">{{ text }}</div>
    <div class="item-count" v-if="count">{{ count }}</div>

</router-link>

</template>

<script>

export default {

    props: ['text', 'count', 'gap', 'route', 'icon', 'first', 'indent']

}

</script>

<style scoped>

.item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.is-mobile .item {
    padding: 10px;
}

.is-minimised .item {
    padding: 0px;
}

.item:not(.router-link-active):hover {
    background-color: rgba(255, 255, 255, 0.05);
    cursor: pointer;
}

.item[data-indent="1"] {
    padding: 7px 20px 7px 54px;
}

.item[data-indent="2"] {
    padding: 7px 20px 7px 74px;
}

.is-mobile .item[data-indent="1"] {
    padding: 7px 20px 7px 44px;
}

.is-mobile .item[data-indent="2"] {
    padding: 7px 20px 7px 64px;
}

.item-icon {
    font-size: 16px;
    width: 24px;
    color: #fff;
    text-align: center;
    margin-right: 10px;
}

.is-minimised .item-icon {
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin: 0px;
}

.item.router-link-active .item-icon {
    color: #f6c86c;
}

.item-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.is-minimised .item-text {
    display: none;
}

.item.router-link-active .item-text {
    color: #f6c86c;
}

.item[data-indent="1"] .item-text,
.item[data-indent="2"] .item-text {
    font-size: 16px;
}

.item[data-indent="1"]:not(.router-link-active) .item-text,
.item[data-indent="2"]:not(.router-link-active) .item-text {
    opacity: 0.75;
}

.item[data-indent="1"]:hover .item-text,
.item[data-indent="2"]:hover .item-text {
    opacity: 1;
}

.item-count {
    background-color: #26445b;
    border-radius: 4px;
    color: #fff;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -10px;
    height: 20px;
    font-weight: 400;
    min-width: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    padding: 0px 5px;
}

.is-mobile .item-count {
    right: 10px;
}

.is-minimised .item-count {
    display: none;
}

.item:not(.router-link-active):hover .item-count {
    background-color: #fff;
    color: #587fb8;
}

.item.router-link-active .item-count {
    background-color: #f6c86c;
    color: #012b39;
}

.item-indent {
    height: 1px;
    width: 12px;
    background-color: #708895;
    margin: 0px 5px 0px 3px;
}

.item-indent:before {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    bottom: 1px;
    height: 32px;
    width: 1px;
    background-color: #708895;
}

.item[data-gap="0"] .item-indent:before {
    height: 16px;
}

.item[data-gap="4"] .item-indent:before {
    height: 128px;
}

.item.is-first .item-indent:before {
    height: 14px;
}

</style>
