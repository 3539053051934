import _ from 'underscore'

var COLORS = [
	'#55efc4',
	'#81ecec',
	'#74b9ff',
	'#a29bfe',
	'#00b894',
	'#00cec9',
	'#0984e3',
	'#6c5ce7',
	'#ff7675',
	'#fd79a8',
	'#fdcb6e',
	'#e17055',
	'#d63031',
	'#e84393'
]

export default {

	namespaced: true,

	state: {

		data: {
			users: [],
			groups: [],
			initial: {}
		}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		users: function(state) {

			return state.data.users

		},

		groups: function(state) {

			return state.data.groups

		}

	},

	mutations: {

		assign: function(state, data) {

			if (!state.data[data.type][data.name]) {

				if (!state.data.initial[data.initial]) state.data.initial[data.initial] = []

				var usedColors = state.data.initial[data.initial]

				state.data[data.type][data.name] = _.sample(_.difference(COLORS, usedColors))

				state.data.initial[data.initial].push(state.data[data.type][data.name])

			}

		}

	},

	actions: {}

}