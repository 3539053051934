function fitText(el) {

	var parent = el.parentElement

	console.log(el.offsetWidth, el.scrollWidth, parent.offsetWidth)

	if (el.offsetWidth < el.scrollWidth) {

		el.style.transform = 'scale(' + (((1 / el.scrollWidth) * el.offsetWidth) - 0.1) + ')'

	} else if (el.offsetWidth > parent.offsetWidth) {

		el.style.transform = 'translate(-50%, -50%) scale(' + (((1 / el.offsetWidth) * parent.offsetWidth) - 0.1) + ')'

	} else if(el.style.transform) {

		el.style.transform = null

	}

}

export default {

	inserted: function(el) {

		fitText(el)

	},

	update: function(el) {

		fitText(el)

	},

	componentUpdated: function(el) {

		fitText(el)

	}

}