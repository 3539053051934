<template>

<div class="editions">

	<core-button v-on:click.native="onPreviousClick" theme="filter" :disabled="!hasPrevious" class="filter-bar-button filter-bar-button-caret is-left" icon="caret-left" :iconOnly="true" v-if="!isLive && !window.is.mobile"></core-button>

	<core-dropdown class="filter-edition" trigger="manual" v-on:forceClose="is.expanded.editions = false" :expanded="is.expanded.editions" v-click-outside="onOutsideClick" v-if="!isLive">

		<core-button type="filter" theme="filter" class="filter-edition-button filter-bar-button" :caret="true" v-on:click.native="onEditionsClick" v-if="!window.is.mobile">{{ name }}</core-button>

		<core-button type="filter" class="filter-bar-button" theme="filter" icon="calendar" size="small" :iconOnly="true" caret="yes" v-on:click.native="onEditionsClick" v-if="window.is.mobile" />
			
		<core-dropdown-list class="filter-edition-list filter-edition-list-slim" v-if="isYearly" theme="filter" :caret="true">
				
			<core-dropdown-link v-on:click.native="onEditionClick(year)" v-for="year in years" v-bind:key="year" :active="year === edition.slug">{{ year }}</core-dropdown-link>

		</core-dropdown-list>
			
		<core-dropdown-list class="filter-edition-list" v-if="!isYearly" grouped="true" theme="filter" :caret="true">
				
			<div class="filter-edition-list-group" v-for="group in editionsGrouped" v-bind:key="group.year">

				<core-dropdown-item type="title">{{ group.year }}</core-dropdown-item>

				<core-dropdown-link v-on:click.native="onEditionClick(item.slug)" v-for="item in group.editions" v-bind:key="item.slug" :active="item.slug === edition.slug">{{ item.name }}</core-dropdown-link>

			</div>

		</core-dropdown-list>

	</core-dropdown>

	<core-button v-on:click.native="onNextClick" theme="filter" :disabled="!hasNext" class="filter-bar-button filter-bar-button-caret is-right" icon="caret-right" :iconOnly="true" v-if="!isLive && !window.is.mobile"></core-button>

</div>

</template>

<script>

import vClickOutside from 'v-click-outside'

export default {
	
	directives: {
		clickOutside: vClickOutside.directive
	},

	data: function() {

		return {
			is: {
				expanded: {
					editions: false
				}
			}
		}

	},

	computed: {

		isLive: function() {

			return this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.LIVE

		},
		
		isYearly: function() {

			return this.list.frequency === this.$CONSTANTS.LISTS.FREQUENCY.YEARLY

		},

		list: function() {

			return this.$store.getters['page/list/list']

		},

		context: function() {

			return this.$store.getters['context']

		},

		edition: function() {

			return this.$store.getters['page/list/edition']

		},

		editions: function() {

			return this.$store.getters['page/list/editions']

		},

		name: function() {

			return this.edition.name

		},

		editionsGrouped: function() {

			var grouped = []

			var currentGroup = {
				year: false,
				editions: []
			}

			this.$_.each(this.editions, function(edition) {

				if (this.$moment.unix(edition.date).utc().format('YYYY') !== currentGroup.year) {

					if (currentGroup.editions.length) {

						grouped.push({
							year: currentGroup.year,
							editions: this.$_.clone(currentGroup.editions)
						})

					}

					currentGroup = {
						year: this.$moment.unix(edition.date).utc().format('YYYY'),
						editions: []
					}

				}

				edition.name = edition.name.replace(currentGroup.year, '').trim()

				currentGroup.editions.push(edition)

			}.bind(this))

			grouped.push(currentGroup)

			return grouped

		},

		years: function() {

			var years = []

			this.$_.each(this.editions, function(edition) {

				years.push(edition.name)

			})

			return years.reverse()

		},

		hasPrevious: function() {

			return this.$_.filter(this.editions, function(edition) {

				return edition.date < this.edition.date

			}.bind(this)).length

		},

		hasNext: function() {

			return !this.edition.current

		}

	},

	watch: {

		context: function(newValue, oldValue) {

			if (newValue.type !== oldValue.type || newValue.name !== oldValue.name) {

				this.is.expanded.editions = false

			}

		}

	},

	methods: {

		onEditionsClick: function() {

			this.is.expanded.editions = !this.is.expanded.editions

		},

		onEditionClick: function(slug) {

			this.$router.push({
				name: 'Edition',
				params: {
					list: this.list.slug,
					edition: slug
				}
			}).catch(function(e) { console.log(e) })

		},

		onOutsideClick: function() {

			this.is.expanded.editions = false

		},

		onPreviousClick: function() {

			if (this.hasPrevious) {

				var previous = this.$_.last(this.$_.filter(this.editions, function(edition) {

					return edition.date < this.edition.date

				}.bind(this)))

				this.$router.push({
					name: 'Edition',
					params: {
						list: this.list.slug,
						edition: previous.slug
					}
				}).catch(function(e) { console.log(e) })

			}

		},

		onNextClick: function() {

			if (this.hasNext) {

				var previous = this.$_.first(this.$_.filter(this.editions, function(edition) {

					return edition.date > this.edition.date

				}.bind(this)))

				this.$router.push({
					name: 'Edition',
					params: {
						list: this.list.slug,
						edition: previous.slug
					}
				}).catch(function(e) { console.log(e) })

			}

		}

	}

}

</script>

<style scoped>

.editions {
	display: flex;
	flex-direction: row;
	margin-left: 15px;
}

.filter-bar-button {
	margin-left: 0px!important;
	border-radius: 0px!important;
}

.filter-bar-button.is-left {
	border-radius: 4px 0px 0px 4px!important;
}

.filter-bar-button.is-right {
	border-radius: 0px 4px 4px 0px!important;
}

.filter-edition-button {
	margin: 0px 1px!important;
}

</style>