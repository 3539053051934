<template>

<core-panel>

	<template v-slot:head>

		<h1>{{ title }}</h1>

		<p>{{ description}}</p>

	</template>

	<template v-slot:body>

		<app-streak v-on:more="onMoreClick(item.index)" :item="{ games: item.games, count: item.total, date: { first: item.start, last: item.end}}" :nolink="true" label="game" v-for="(item, index) in clusters" v-bind:key="index" :dark="true" />

	</template>

</core-panel>

</template>

<script>

import Panel from './../../common/Panel'

export default {

	mixins: [Panel],

	data: function() {

		return {
			detail: 'clusters',
			params: [
				'clusterType'
			],
			clusters: [],
			titles: {
				added: 'Spurts',
				removed: 'Culls',
				refreshed: 'Refreshes'
			},
			descriptions: {
				added: 'When you added a number of games over a short time period.',
				removed: 'When you removed a number of games over a short time period.',
				refreshed: 'When you both added and removed a number of games over a short time period.'
			}
		}

	},

	computed: {

		title: function() {

			return this.titles[this.data.clusterType]

		},

		description: function() {

			return this.descriptions[this.data.clusterType]

		}

	},

	methods: {

		onMoreClick: function(index) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsSizeCluster',
				index: index,
				child: true
			})

		},

		response: function(json) {

			this.clusters = json.clusters

		}

	}

}

</script>