<template>

<core-filter-config v-on:expand="onExpandClick" v-on:close="onCloseClick" v-on:save="onSaveClick" v-if="active" :state="is" :loginRequired="true" :selfOnly="true">

	<app-config-template-insights-records-start />

	<app-config-template-plays-usernames />

	<app-config-template-other-dateformat />

</core-filter-config>

</template>

<script>

import Config from 'core/mixin/Config.js'

export default {

	mixins: [Config],

	data: function() {

		return {

			name: 'insights.records'
			
		}

	},

	computed: {

		active: function() {

			return this.$route.name === 'InsightsRecords' 

		}

	},

	methods: {

		onAfterSave: function() {

			this.$emit('refresh')

		}

	}

}

</script>