<template>

<com-item name="worth.history" :size="size" :is-empty="!active" :loading="loading" title="Worth">

	<template v-slot:about>

		<p>{{ your | capitalise }} changes in total worth over the past 12 months.</p>

		<p class="small">Tracking began from day {{ you }} were first imported into geekGroup.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-bars class="graph" :data="graph" />
		
	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem
	},

	data: function() {

		return {
			graph: {
				datasets: [
					{ type: 'point', data: [], yAxis: 'left', dataType: 'money', backgroundColor: '#217EA8', tooltip: '{{value}} {{change}} <small>Total worth</small>', lineColor: '#217EA8', shadeColor: '#d1f1ff' },
					{ type: 'point', data: [], yAxis: 'right', dataType: 'money', backgroundColor: '#ffa621', tooltip: '{{value}} {{change}} <small>Average worth</small>', lineColor: '#ffa621', shadeColor: '#ffc773' }
				],
				axis: {
					x: {
						labels: [],
						type: 'month'
					},
					y: {
						left: {
							type: 'scale',
							dataType: 'money',
							min: 1000,
							points: 5
						},
						right: {
							type: 'scale',
							dataType: 'money',
							min: 100,
							points: 5
						}
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'history'

		},

		history: function() {

			return this.$store.getters['page/insights/worth/history']

		},

		active: function() {

			return this.$_.keys(this.history).length && !this.loading

		},
		
		currencyType: function() {

			return this.$store.getters['session/config'].other.currency

		}

	},

	watch: {

		currencyType: function() {

			this.visualise()

		},

		history: function() {

			this.visualise()

		}

	},

	created: function() {

		this.visualise()

	},

	methods: {

		visualise: function() {

			if (this.$_.keys(this.history).length) {

				this.graph.axis.x.labels = []
				this.graph.datasets[0].data = []
				this.graph.datasets[1].data = []

				this.$_.each(this.history, function(history) {

					this.graph.axis.x.labels.push(history.month)
					this.graph.datasets[0].data.push(this.formatcurrency(history.total, 2, true))
					this.graph.datasets[1].data.push(this.formatcurrency(history.average, 2, true))

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

</style>