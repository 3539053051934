<template>

<com-item name="members.size" :size="size" :loading="loading" :is-empty="!active" title="Activity">

	<template v-slot:stats>

		<app-visualisation-bars :data="graph" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size', 'type'],

	components: {
		'com-item': comItem
	},

	data: function() {
	
		return {
			graph: {
				datasets: [
					{ type: 'bar', data: [], yAxis: 'right', backgroundColor: '#3bce5a', tooltip: '{{value}} <small>User{{value.plural}} added</small>' },
					{ type: 'bar', data: [], yAxis: 'right', backgroundColor: '#e27570', tooltip: '{{value}} <small>User{{value.plural}} removed</small>' },
					{ type: 'point', data: [], yAxis: 'left', backgroundColor: '#217EA8', tooltip: '{{value}} {{change}} <small>Group size</small>', lineColor: '#217EA8', shadeColor: '#d1f1ff' }
				],
				axis: {
					x: {
						labels: [],
						type: 'month'
					},
					y: {
						left: {
							type: 'scale',
							min: 10,
							points: 5
						},
						right: {
							type: 'scale',
							min: 10,
							points: 5
						}
					}
				}
			}
		}

	},

	created: function() {
	
		this.visualise()	

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		data: function() {

			return this.$store.getters['page/insights/members/activity']

		},

		active: function() {

			return this.$_.keys(this.data).length && !this.loading

		}

	},

	methods: {
	
		visualise: function() {

			if (this.$_.keys(this.data).length) {

				this.graph.axis.x.labels = []
				this.graph.datasets[0].data = []
				this.graph.datasets[1].data = []
				this.graph.datasets[2].data = []

				for (var i = 0; i < 12; i++) {

					this.graph.axis.x.labels.push(this.data[i].month)
					this.graph.datasets[0].data.push(this.data[i].added)
					this.graph.datasets[1].data.push(this.data[i].removed)
					this.graph.datasets[2].data.push(this.data[i].size)

				}

			}
		
		}
	
	}

}

</script>

<style scoped>

</style>