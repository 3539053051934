<template>

<com-item name="collection.weightduration" :size="size" :is-empty="!active" :loading="loading" title="Weight vs Duration">

	<template v-slot:about>

		<p>Which games best align for available time and desired weight.</p>

		<p class="small">Click on a segment to view list of games within.</p>

	</template>

	<template v-slot:stats>

		<app-visualisation-scatter :data="graph" v-on:click="onClick" />

	</template>

	<template v-slot:buttons>

		<com-toggle :options="durationOptions" :value="durationValue" v-on:change="onDurationChange" />
		
		<com-toggle :options="countOptions" :value="countValue" v-on:change="onCountChange" />

	</template>

</com-item>

</template>

<script>

import comItem from './../common/Item'
import comToggle from './../common/Toggle'

export default {

	props: ['fetching', 'you', 'your', 'size'],

	components: {
		'com-item': comItem,
		'com-toggle': comToggle
	},

	data: function() {

		return {
			graph: {
				datasets: [
					{ data: [] }
				],
				axis: {
					x: {
						labels: ['0-1hr', '1-2hr', '2-3hr', '3-4hr', '4-5hr', '5-6hr'],
						thresholds: [0, 60, 120, 180, 240, 300, 360]
					},
					y: {
						labels: ['Light', 'Light - Medium', 'Medium', 'Heavy'],
						thresholds: [1, 2, 3, 4, 5]
					}
				}
			}
		}

	},

	computed: {

		loading: function() {

			return this.fetching === 'all' || this.fetching === 'suitability.duration' || this.fetching === 'suitability.count'

		},

		data: function() {

			return this.$store.getters['page/insights/collection/suitability']

		},

		active: function() {

			return this.data.length && !this.loading

		},

		countOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.ANY, text: 'Any count'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.P1, text: 'Solo'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.P2, text: '2 players'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.P3, text: '3 players'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.P4, text: '4 players'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.P5, text: '5 players'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.P6, text: '6 players'})

			return options

		},

		countValue: function() {

			return this.$store.getters['filter/insights/collection/suitability/count']

		},

		durationOptions: function() {

			var options = []

			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.MAX, text: 'Maximum Duration'})
			options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.MID, text: 'Midpoint Duration'})
			if (this.suitabilityCountValue !== this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.ANY) options.push({value: this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.COUNT, text: 'Count Duration'})

			return options

		},

		durationValue: function() {

			return this.$store.getters['filter/insights/collection/suitability/duration']

		},

	},

	watch: {

		data: function() {

			this.visualise()

		}

	},

	created: function() {
	
		this.visualise()

	},

	methods: {

		onCountChange: function(value) {

			this.$store.commit('filter/insights/collection/suitability/count', value)

			if (value === this.$CONSTANTS.INSIGHTS.COLLECTION.COUNT.ANY) this.onDurationChange(this.$CONSTANTS.INSIGHTS.COLLECTION.DURATION.MAX)

		},

		onDurationChange: function(value) {

			this.$store.commit('filter/insights/collection/suitability/duration', value)

		},

		visualise: function() {

			this.graph.datasets[0].data = []

			this.$_.each(this.data, function(item) {

				this.graph.datasets[0].data.push({
					id: item.g,
					x: item.t, 
					y: item.w,
					tooltip: item.n + '<small>' + item.w + ' / ' + item.t + ' minutes</small>'
				})

			}.bind(this))

		},

		onClick: function(e) {

			this.$pubsub.$emit('panel.open', {
				type: 'insightsCollectionSuitability',
				x: e.x,
				y: e.y,
				filter: this.$store.getters['filter/insights/collection']
			})

		}

	}

}

</script>

<style scoped>

.suitability-graph {
	width: 100%;
	min-height: 320px;
	height: 100%;
}

</style>