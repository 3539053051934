<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Create Tag

	</template>

	<template v-slot:default>

		<core-form-input-textbox :autofocus="true" maxlength="16" name="text" v-on:validate="validate" placeholder="Enter tag" v-model="inputs.text" notes="Maximum of 16 characters." :error="is.error" :disabled="is.loading" :validation="validation.text" />

		<core-form-input-colourpicker title="Style as" name="colour" v-on:validate="validate" v-model="inputs.colour" :error="is.error" :disabled="is.loading" :validation="validation.colour" />

		<core-form-input-togglelist title="Seen by" name="public" v-on:validate="validate" v-model="inputs.public" :error="is.error" :disabled="is.loading" :validation="validation.public" :options="publicOptions" />

		<core-form-input-togglelist title="Attaches to" name="context" v-on:validate="validate" v-model="inputs.context" :error="is.error" :disabled="is.loading" :validation="validation.context" :options="contextOptions" />

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Confirm</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	props: ['data'],
	
	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				text: '',
				public: 1,
				context: 0,
				colour: '#134677'
			},

			validation: {
				text: {
					exists: true,
					max: 16
				}
			}

		}

	},

	computed: {

		publicOptions: function() {

			return [{
				value: 1,
				title: 'Everyone',
				text: 'Anyone viewing your profile can see this tag and filter with it.'
			}, {
				value: 0,
				title: 'Only me',
				text: 'Only you can see this tag and filter with it.'
			}]

		},

		contextOptions: function() {

			var options = [{
				value: this.$CONSTANTS.TAG.CONTEXT.GAME,
				title: 'Game',
				text: 'The tag is associated with the game and will be shown whenever the game appears in your profile.'
			}]

			if (this.data.filter === 'collection') {

				options.push({
					value: this.$CONSTANTS.TAG.CONTEXT.COLLECTION,
					title: 'Collection',
					text: 'The tag is associated with this specific collection entry and will not appear elsewhere.'
				})

			} else if (this.data.filter === 'ratings') {

				options.push({
					value: this.$CONSTANTS.TAG.CONTEXT.RATING,
					title: 'Rating',
					text: 'The tag is associated with this specific rating entry and will not appear elsewhere.'
				})

			} else if (this.data.filter === 'plays') {

				options.push({
					value: this.$CONSTANTS.TAG.CONTEXT.PLAY,
					title: 'Play',
					text: 'The tag is associated with this specific play entry and will not appear elsewhere.'
				})

			}

			return options

		}

	},

	methods: {

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/tags/create', {
					data: {
						text: this.inputs.text,
						public: this.inputs.public,
						context: this.inputs.context,
						colour: this.inputs.colour
					}
				}).then(function(tag) {

					this.$store.commit('data/tags/add', [tag])

					if (this.data.gameIndex && this.data.itemIndex) {

						this.add(tag)

					} else {

						this.$emit('close')

					}

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		add: function(tag) {

			this.$store.dispatch('api/tags/add', {
				tag: tag.id,
				index: (tag.context === this.$CONSTANTS.TAG.CONTEXT.GAME) ? this.data.gameIndex : this.data.itemIndex
			}).then(function(json) {

				this.$store.commit('page/' + this.data.filter + '/tags/add', {
					index: json.index,
					context: tag.context,
					tag: tag.id
				})

				this.$emit('close')

			}.bind(this), function() {

				this.$emit('close')

			}.bind(this)) 

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>