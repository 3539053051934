<template>

<core-dropdown-item type="link" v-on:click.native="onClick" :loading="is.loading">{{ text }}</core-dropdown-item>

</template>

<script>

export default {

	name: 'selectAction',

	props: ['text', 'name', 'loading'],

	data: function() {

		return {

			is: {
				loading: false
			}

		}

	},

	methods: {

		onClick: function() {

			if (this.loading) this.is.loading = true

			this.$store.dispatch('service/select/' + this.name).then(function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>
