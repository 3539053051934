<template>

<com-item name="size.overview" :size="size" :loading="loading" :is-overview="3" title="Overview">

	<template v-slot:about>

		<p>The summary of {{ your }} collection size journey.</p>

	</template>

	<template v-slot:stats>

		<com-overview theme="green" type="number" :value="overview.added" label="Games Added" sublabel="All time" />
			
		<com-overview theme="red" type="number" :value="overview.removed" label="Games Removed" sublabel="All time" />
			
		<com-overview type="number" :value="overview.biggest.value" label="Biggest Size" :sublabel="overview.biggest.date | formatDate('MMMM YYYY')" />
			
		<com-overview theme="green" caret="up" type="number" :empty="!overview.increase.value" :value="overview.increase.value" label="Biggest Increase" :sublabel="overview.increase.date | formatDate('MMMM YYYY')" />
			
		<com-overview theme="red" caret="down" type="number" :empty="!overview.decrease.value" :value="overview.decrease.value" label="Biggest Decrease" :sublabel="overview.decrease.date | formatDate('MMMM YYYY')" />
			
		<com-overview caret="fa fa-refresh" type="number" :empty="!overview.refresh.value" :value="overview.refresh.value" label="Biggest Refresh" :sublabel="overview.refresh.date | formatDate('MMMM YYYY')" />
			
		<com-overview type="template" :value="lifetime(overview.lifetime.average)" label="Avg. Lifetime" :sublabel="true" />
			
		<com-overview type="template" :value="lifetime(overview.lifetime.longest.added, overview.lifetime.longest.removed)" label="Longest Lifetime" :sublabel="overview.lifetime.longest.game" />
			
		<com-overview type="template" :empty="!overview.lifetime.shortest" :value="lifetime(overview.lifetime.shortest.added, overview.lifetime.shortest.removed)" label="Shortest Lifetime" :sublabel="overview.lifetime.shortest.game" />

	</template>

</com-item>

</template>

<script>

import comOverview from './../common/Overview'
import comItem from './../common/Item'

export default {

	props: ['fetching', 'your', 'you', 'size'],

	components: {
		'com-item': comItem,
		'com-overview': comOverview
	},

	computed: {

		loading: function() {

			return this.fetching === 'all' 

		},

		overview: function() {

			return this.$store.getters['page/insights/size/overview']

		},

		active: function() {

			return this.overview && !this.loading

		}

	},

	methods: {

		lifetime: function(from, to) {

			to = to || false

			if (to === false) {

				from = this.$moment().subtract(from, 'seconds')
				to = this.$moment()

			} else {

				from = this.$moment.unix(from)
				to = this.$moment.unix(to)

			}

			var years = to.diff(from, 'years')

			to.subtract(years, 'years')

			var months = to.diff(from, 'months')

			to.subtract(months, 'months')

			var days = to.diff(from, 'days')

			var range = []

			if (years) range.push(years.toString() + '[y]')
			if (months) range.push(months.toString() + '[m]')
			if (days && years === 0) range.push(days.toString() + '[d]')

			return range.join(' ')

		}

	}

}

</script>

<style scoped>

</style>