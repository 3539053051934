<template>

<core-filter-config v-on:expand="onExpandClick" v-on:close="onCloseClick" v-on:save="onSaveClick" :state="is" :hideButton="window.width < 768">

	<app-config-template-collection-nest />

	<app-config-template-collection-contains />

	<app-config-template-collection-status />

	<app-config-template-collection-inventory-location-public />

	<app-config-template-other-currency />

</core-filter-config>

</template>

<script>

import Config from 'core/mixin/Config.js'

export default {

	mixins: [Config],

	data: function() {

		return {

			name: 'collection'
			
		}

	},

	methods: {

		onAfterSave: function() {

			this.$emit('refresh')

		}

	}

}

</script>