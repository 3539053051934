import Vue from 'vue'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		data: {}

	},

	getters: {

		all: function(state) {

			return state.data

		},

		recent: function(state) {

			return state.data.recent || []

		},

		after: function(state) {

			return state.data.after || []

		},

		longest: function(state) {

			return state.data.longest || []

		},

		overview: function(state) {

			return state.data.overview || []

		},

		activity: function(state) {

			return state.data.activity || []

		},

		cumulative: function(state) {

			return state.data.cumulative || []

		}

	},

	mutations: {

		reset: function(state) {

			state.data = {}

		},

		set: function(state, data) {

			_.each(data, function(value, key) {

				Vue.set(state.data, key, value)

			})

		}

	},

	actions: {}

}