<template>

<core-modal v-on:close="onClose">

	<template v-slot:head>

		Edit Tag

	</template>

	<template v-slot:default>

		<core-form-input-textbox :autofocus="true" maxlength="16" name="text" v-on:validate="validate" placeholder="Enter tag" v-model="inputs.text" notes="Maximum of 16 characters." :error="is.error" :disabled="is.loading" :validation="validation.text" />

		<core-form-input-colourpicker title="Style as" name="colour" v-on:validate="validate" v-model="inputs.colour" :error="is.error" :disabled="is.loading" :validation="validation.colour" />

		<core-form-input-togglelist title="Seen by" name="public" v-on:validate="validate" v-model="inputs.public" :error="is.error" :disabled="is.loading" :validation="validation.public" :options="publicOptions" />

	</template>

	<template v-slot:foot>

		<core-button theme="blue" v-on:click.native="onConfirmClick" :loading="is.loading" :disabled="!is.valid">Save</core-button>
		<core-button theme="plain" v-on:click.native="onClose">Cancel</core-button>

	</template>

</core-modal>

</template>

<script>

import FormValidation from 'core/mixin/form/Validation.js'

export default {

	props: ['data'],
	
	mixins: [FormValidation],

	data: function() {

		return {

			is: {
				loading: false
			},

			inputs: {
				text: '',
				public: 1,
				colour: '#134677'
			},

			validation: {
				text: {
					exists: true,
					max: 16
				}
			}

		}

	},

	created: function() {

		this.inputs.text = this.tag.text
		this.inputs.public = this.tag.public
		this.inputs.colour = this.tag.colour

	},

	computed: {

		tag: function() {

			return this.$store.getters['data/tags'][this.data.tag]

		},

		publicOptions: function() {

			return [{
				value: 1,
				title: 'Everyone',
				text: 'Anyone viewing your profile can see this tag and filter with it.'
			}, {
				value: 0,
				title: 'Only me',
				text: 'Only you can see this tag and filter with it.'
			}]

		}

	},

	methods: {

		onConfirmClick: function() {

			if (this.is.valid) {

				this.is.loading = true

				this.$store.dispatch('api/tags/update', {
					tag: this.data.tag,
					data: {
						text: this.inputs.text,
						public: this.inputs.public,
						colour: this.inputs.colour
					}
				}).then(function(tag) {

					this.$store.commit('data/tags/update', [tag])

					this.$emit('close')

				}.bind(this), function() {

					this.is.error = true
					this.is.loading = false

				}.bind(this)) 

			}

		},

		onClose: function() {

			this.$emit('close')

		}

	}

}

</script>

<style scoped>

</style>