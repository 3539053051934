import API from 'core/api'
import _ from 'underscore'
import CONSTANTS from '../../constants'

export default {

	namespaced: true,

	modules: {},

	state: {},

	getters: {},

	mutations: {},

	actions: {

		verify: function(context, params) {

			return new Promise(function(resolve, reject) {

				API.request('buddies/verify', params).then(function(json) {

					resolve(json)

				}, function(json) {

					reject(json)

				})

			})

		},

		sync: function(context, params) {

			return new Promise(function(resolve, reject) {

				function call(syncKey) {

					if(syncKey) params.syncKey = syncKey

					API.request('buddies/sync', params).then(function(json) {

						resolve(json)

					}, function(json) {

						if (json.error=== CONSTANTS.STATUS.ERROR.SYNC.INPROGRESS) {

							_.delay(function() { call(json.syncKey) }, 10000)

						} else {

							reject(json.error)

						}

					})

				}

				call()

			})

		}

	}

}