<template>

<core-filter-advanced-group title="General" :count="count">

	<core-filter-input-daterange text="Added to collection" subtext="Only games added after the profile was first synchronised have had their date tracked." :filter="value('collection/added')" />

	<core-filter-input-toggle v-if="!isCollection" text="Exclude if in collection" on="Yes" off="No" :filter="value('collection/exclude/own')" />

	<core-filter-input-toggle v-if="!isCollection" text="Exclude if previously owned" on="Yes" off="No" :filter="value('collection/exclude/prevowned')" />

</core-filter-advanced-group>

</template>

<script>

import Group from 'core/mixin/filter/Group.js'

export default {

	mixins: [Group],

	data: function() {

		return {
			fields: ['collection.added', 'collection.exclude.own', 'collection.exclude.prevowned']
		}

	},

	computed: {

		isCollection: function() {

			return this.$route.name === 'Collection'

		}

	}

}

</script>